import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "react-select";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Button from "components/common/CustomButtons/Button.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  displayError,
  getString,
  displaySuccess,
  getToday,
  currencyFormat,
  currencyValue,
} from "assets/js/utils/utils.js";

import $ from "jquery";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import TextField from "@material-ui/core/TextField";
import SaveButton from "../CustomComponents/SaveButton";
import { getLocale } from "../../../assets/js/utils/utils";

class TransferBalance extends React.Component {
  state = {
    back: false,
    banks: [],
    callerName: this.props.callerName,
    callerState: this.props.callerState,
    callerProps: this.props.callerProps,
    showBalance: false,
    showDestBalance: false,
    transferData: {
      fromAccount: "",
      fromAccountId: "",
      toAccount: "",
      toAccountId: "",
      date: "",
      amount: "",
      refNo: "",
    },
    balances: {
      fromAccount: "",
      toAccount: "",
    },
    senderId: "",
    recipientId: "",
    cashBookData: null,
  };

  submit = () => {
    let data = this.state.transferData;

    if (data.toAccount === data.fromAccount) {
      displayError(getString().same_acc_err);
    } else {
      let balance = currencyValue(
        this.state.balances.fromAccount.balance.cents,
        false
      );
      if (data.amount > balance) {
        displayError(getString().err_amount);
        document.getElementById("amount").focus();
        return;
      }
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <p>
                {currencyFormat(data.amount) +
                  getString().will_be_sent +
                  data.fromAccount +
                  getString()._to +
                  data.toAccount}
              </p>
              <button onClick={onClose}>{getString().cancel}</button>
              <button
                onClick={() => {
                  this.sendData();
                  onClose();
                  this.clearFields();
                }}
              >
                {getString().yes}
              </button>
            </div>
          );
        },
      });
    }
  };

  sendData = () => {
    let fromAccount = this.state.transferData.fromAccount;
    let toAccount = this.state.transferData.toAccount;
    let amount = this.state.transferData.amount;
    let date = this.state.transferData.date;
    let refNo = this.state.transferData.refNo;
    let account_number = this.state.balances.fromAccount.number;

    let accountsIds = this.state.balances;

    let senderPrevBal = currencyValue(
      this.state.balances.fromAccount.balance,
      false
    );
    let senderPrevLocBal = currencyValue(
      this.state.balances.fromAccount.local_balance,
      false
    );
    let newSenderBal = senderPrevBal - amount;
    let newSenderLocBal = senderPrevLocBal - amount;
    let _data = { newBal: newSenderBal, newLocBal: newSenderLocBal };

    let _this = this;
    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/cashbook/transfer_balances",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        from_account: fromAccount,
        date: date,
        to_account: toAccount,
        amount: amount,
        account_number: account_number,
        reference_number: refNo,
        to_acc_number: accountsIds.toAccount.number,
        payments: amount,
      },
      success: function(result) {
        let lang = getLocale();
        if (lang === "en-GB" || lang === "en-US") {
          displaySuccess(
            `${amount} has been removed from ${fromAccount} on ${date}`
          );
        } else {
          displaySuccess(getString().trans_success);
        }

        if (_this.props.updateMe) {
          _this.props.updateMe(_data);
        }
        _this.close();
      },
      error: function(req, status, error) {
        console.log(error.status, req.responseText);
        displayError(req.responseText);
      },
    });
  };

  clearFields = () => {
    let transferDetails = this.state.transferData;
    transferDetails.fromAccount = "";
    transferDetails.toAccount = "";
    transferDetails.amount = "";
    transferDetails.refNo = "";
    transferDetails.date = getToday();

    this.setState({
      transferData: transferDetails,
      showBalance: false,
      showDestBalance: false,
    });
  };

  updateSrc = (event) => {
    if (event.status === "blocked") {
      displayError(getString().disabled_account_trans);
      this.setState({
        showBalance: false,
      });
      return;
    } else {
      let transferDetails = this.state.transferData;
      transferDetails.fromAccount = event.label;
      transferDetails.fromAccountId = event.value;
      if (this.state.transferData.toAccount === event.label) {
        displayError(getString().same_acc_err);
        return;
      }
      this.getAccountBalance(event.value, "source");
      this.setState({
        transferData: transferDetails,
        showBalance: true,
        senderId: event.value,
      });
    }
  };

  updateDest = (event) => {
    if (event.status === "blocked") {
      displayError(getString().disabled_account_trans);
      this.setState({
        showDestBalance: false,
      });
      return;
    } else {
      let transferDetails = this.state.transferData;
      transferDetails.toAccount = event.label;
      transferDetails.toAccountId = event.value;
      if (this.state.transferData.fromAccount === event.label) {
        displayError(getString().same_acc_err);
        return;
      }
      this.getAccountBalance(event.value, "receiver");
      this.setState({
        transferData: transferDetails,
        showDestBalance: true,
        recipientId: event.value,
      });
    }
  };

  handleChange = (event) => {
    let transferDetails = this.state.transferData;
    transferDetails[event.target.name] = event.target.value;
    this.setState({
      transferData: transferDetails,
    });
  };

  getAccountBalance = (id, caller) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/cashbook/bank_account/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let _balances = _this.state.balances;
        if (caller === "source") {
          _balances.fromAccount = result;
        } else {
          _balances.toAccount = result;
        }
        _this.setState({
          balances: _balances,
        });
      },
    });
  };

  accountOptions = () => {
    let the_data = [];
    let filter = "status: 'unblocked'";

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/cashbook/bank_account",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        attrs: ["name", "id", "status"],
        order: [{ by: "desc", attr: "name" }],
        model: "Afrijula::Cashbook::BankAccount",
        filter,
      },

      success: function(data) {
        $.map(data, function(data) {
          the_data.push({
            label: data.name,
            value: data._id.$oid,
            status: data.status,
          });
        });
        this.setState({
          banks: the_data,
        });
      }.bind(this),
    });
  };

  close = () => {
    this.setState({
      back: true,
    });
  };

  componentDidMount() {
    let transferDetails = this.state.transferData;
    transferDetails.date = getToday();
    this.setState({
      transferData: transferDetails,
    });

    if (this.props.fromAccount) {
      let fromAccount = this.props.fromAccount;
      let transferDetails = this.state.transferData;
      transferDetails.fromAccount = fromAccount.name;
      transferDetails.fromAccountId = fromAccount._id.$oid;

      let accountsIds = this.state.balances;
      accountsIds.fromAccount = fromAccount;

      this.setState({
        transferData: transferDetails,
        balances: accountsIds,
      });
    }
    this.accountOptions();
  }

  render() {
    const { classes } = this.props;

    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let caller_state = this.state.callerState;
      caller_state.transerBalance = false;
      return (
        <ChildComponent
          state={caller_state}
          returningProps={this.state.callerProps}
        />
      );
    } else {
      return (
        <div style={{ marginTop: -40 }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <h4>{getString().transfer_balance}</h4>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    {!this.props.fromAccount ? (
                      <GridItem
                        xs={12}
                        sm={6}
                        md={4}
                        style={{ marginTop: -10 }}
                      >
                        <Card style={{ zIndex: 6000 }}>
                          <Select
                            options={this.state.banks}
                            defaultOptions
                            placeholder={getString().select_from_acc}
                            defaultValue={this.state.transferData.fromAccount}
                            onChange={(event) => {
                              this.updateSrc(event);
                            }}
                          />
                        </Card>
                        {this.state.showBalance ? (
                          <span>
                            {getString().current_bal +
                              currencyFormat(
                                this.state.balances.fromAccount.balance
                              )}
                          </span>
                        ) : null}
                      </GridItem>
                    ) : (
                      <div>
                        <span>
                          {getString().account +
                            ": " +
                            this.props.fromAccount.name}
                        </span>
                        <br />
                        <span>
                          {getString().current_bal +
                            currencyFormat(this.props.fromAccount.balance)}
                        </span>
                      </div>
                    )}
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="date"
                        name="date"
                        label={getString().date}
                        className={classes.textField}
                        value={this.state.transferData.date}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        type="date"
                        inputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="amount"
                        name="amount"
                        label={getString().amount}
                        className={classes.textField}
                        value={this.state.transferData.amount}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        inputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="refNo"
                        name="refNo"
                        label={getString().refNo}
                        className={classes.textField}
                        value={this.state.transferData.refNo}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        inputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <br />
                      <Select
                        options={this.state.banks}
                        placeholder={getString().select_to_acc}
                        defaultValue={this.state.transferData.toAccount}
                        onChange={(event) => {
                          this.updateDest(event);
                        }}
                      />
                      {this.state.showDestBalance ? (
                        <span>
                          {getString().current_bal +
                            currencyFormat(
                              this.state.balances.toAccount.balance
                            )}
                        </span>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      xs={12}
                      sm={6}
                      md={2}
                      color="danger"
                      onClick={this.close.bind(this)}
                      style={{
                        backgroundColor: "#f44336",
                        margin: ".3125rem 1px",
                        minWidth: "auto",
                        minHeight: "auto",
                        fontSize: "12px",
                        color: "#ffffff",
                        padding: "12px 30px",
                        borderRadius: "3px",
                        verticalAlign: "middle",
                      }}
                    >
                      {getString().cancel}
                    </Button>
                    <SaveButton method={this.submit} title={getString().save} />
                  </GridItem>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

TransferBalance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(TransferBalance);
