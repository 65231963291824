import React from "react";
import cookies from "react-cookies";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import baseUrl from "assets/js/config/const.js";
import MaskedInput from "react-text-mask";

import {
  displayError,
  displaySuccess,
  getString,
} from "assets/js/utils/utils.js";

import $ from "jquery";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import ResetPasswordPhone from "../../common/Auth/ResetPasswordPhone";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "+",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

const CodeMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
CodeMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class SignIn extends React.Component {
  state = { smsCode: false, chooseAccounts: false, accounts: [], phone: "" };

  cancel = () => {
    this.props.history.push({ pathname: "/landing" });
  };

  getUserType = (userType) => {
    let names = userType.split("::");
    names.splice(1, names.length - 2, names[names.length - 1]);
    return names;
  };
  validation = (e) => {
    e.preventDefault();
    let user_email = document.getElementById("email").value;
    let user_pswd = document.getElementById("password").value;
    let phone_number = document.getElementById("phone_number").value.trim();
    let _this = this;
    //sbensouda: I am adding another security layer. Not that react has huge security
    //risk if not deployed with HTTPS. the key and token are sent in flight making man
    //in middle attach very likely.
    let r = Math.random().toString(36);
    cookies.save("User-Key", r);
    $.ajax({
      method: "POST",
      url: baseUrl + "/account/authenticate_v2/authenticate",
      headers: { UserKey: r },
      data: {
        email: user_email,
        password: user_pswd,
        phone_number: phone_number,
        influence: "afrijula",
        r: r,
      },
      dataType: "json",
      success: function(result) {
        if (result.many === true) {
          _this.chooseThenApply(result);
        } else {
          _this.applyUser(result, user_email);
        }
      },
      error: function(jqXHR, textStatus, errorThrown) {
        displayError(jqXHR.responseText);
      },
    });
  };

  switchTo = (orgId, profileId) => {
    let _this = this;
    $.ajax({
      method: "Get",
      url: baseUrl + "/account/account/" + orgId + "/switch",
      dataType: "json",
      data: { profile_id: profileId },
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.applyUser(result);
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  chooseThenApply = (result, user_email) => {
    let _this = this;
    const user_type = this.getUserType(result.user_type)[2];
    this.setCookies(result, user_type);
    $.ajax({
      method: "GET",
      url: baseUrl + "/account/account",
      dataType: "json",
      data: { all_mine: true, influence: "afrijula" },
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ accounts: result, chooseAccounts: true });
      },
    });
  };

  viewAccounts = () => {
    const { classes } = this.props;
    return (
      <div>
        <Table>
          <TableBody>
            {this.state.accounts.map((account) => {
              return (
                <TableRow>
                  <TableCell>
                    <strong>{account.name}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{account.status}</strong>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        this.switchTo(account.id.$oid, account.profile_id.$oid);
                      }}
                    >
                      Choose
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  setCookies = (result, user_type) => {
    let expires = new Date();
    expires = new Date(expires.getTime() + (result.age - 30) * 1000);
    cookies.save("token", result.auth_token, { path: "/", expires: expires });
    cookies.save("profile", result.profile, { path: "/", expires: expires });
    cookies.save("profileId", result.profile_id, {
      path: "/",
      expires: expires,
    });
    cookies.save("orgId", result.org.id, { path: "/", expires: expires });
    cookies.save("orgName", result.org.name, { path: "/", expires: expires });
    cookies.save("orgContact", result.org.contact, {
      path: "/",
      expires: expires,
    });
    cookies.save("orgEmail", result.org.email, { path: "/", expires: expires });
    cookies.save("orgAddress", result.org.address, {
      path: "/",
      expires: expires,
    });
    cookies.save("packages", result.org.packages, {
      path: "/",
      expires: expires,
    });
    cookies.save("gps", result.org.gps, { path: "/", expires: expires });
    cookies.save("defaultCurrency", result.org.settings.default_currency, {
      path: "/",
      expires: expires,
    });
    cookies.save("onlineShopping", result.org.settings.e_commerce, {
      path: "/",
      expires: expires,
    });
    cookies.save("tax_percentage", result.org.settings.tax_percentage, {
      path: "/",
      expires: expires,
    });
    cookies.save("printer_type", result.org.settings.printer_type, {
      path: "/",
      expires: expires,
    });
    cookies.save("cashbookActivated", result.org.settings.cashbook_activated, {
      path: "/",
      expires: expires,
    });
    cookies.save(
      "automateReconciliation",
      result.org.settings.automate_reconciliation,
      { path: "/", expires: expires }
    );
    cookies.save(
      "accept_shop_terms",
      result.org.settings.e_accept ? true : false,
      { path: "/" }
    );
    cookies.save("user_type", user_type);
  };

  applyUser = (result, user_email) => {
    const user_type = this.getUserType(result.user_type)[2];
    this.setCookies(result, user_type);

    if (this.props.closeRodal) {
      this.props.closeRodal();
    } else {
      this.props.history.push({
        pathname: "/dashboard",

        state: {
          user: {
            username: user_email,
            user_type: user_type,
            package: result.package,
          },
          mobileOpen: false,
        },
      });
    }
  };
  resetPwd = (e) => {
    e.preventDefault();
    let user_email = document.getElementById("email").value;
    let phone_number = document.getElementById("phone_number").value;

    if (
      (user_email === null || user_email === "") &&
      (phone_number === null || phone_number.trim().length <= 10)
    ) {
      displayError(
        "Please indicate email or phone number to recover your password"
      );
      return;
    }
    // temporary stopping the execution of code to show reset UI
    // return;
    let _this = this;
    //sbensouda: I am adding another security layer. Not that react has huge security
    //risk if not deployed with HTTPS. the key and token are sent in flight making man
    //in middle attach very likely.
    let r = Math.random().toString(36);
    cookies.save("User-Key", r);
    $.ajax({
      method: "POST",
      url: baseUrl + "/account/authenticate_v2/reset",
      headers: { UserKey: r },
      data: {
        email: user_email,
        phone_number: phone_number.trim(),
        influence: "afrijula",
        r: r,
        stage: "1",
      },
      dataType: "json",
      success: function(result) {
        displaySuccess(result.success);
        if (result.with_code) {
          console.log("Your code : " + result.with_code);
          console.log("Phone: " + _this.state.phone);
          _this.setState({ smsCode: true });
        }
      },
      error: function(jqXHR, textStatus, errorThrown) {
        displayError(errorThrown + ": " + jqXHR.responseText);
      },
    });
  };

  componentDidMount() {}
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        {this.state.smsCode ? (
          <ResetPasswordPhone phone={this.state.phone} />
        ) : (
          // <Paper className={classes.paper}>
          //   <Avatar className={classes.avatar}>
          //     <LockOutlinedIcon />
          //   </Avatar>
          //   <Typography component="h1" variant="h5">
          //     Enter Code
          //   </Typography>
          //   <form className={classes.form}>
          //     <FormControl margin="normal" required fullWidth>
          //       <InputLabel htmlFor="phone_code">{getString().code}</InputLabel>
          //       <Input
          //         id="phone_code"
          //         name="phone_code"
          //         autoFocus
          //         inputComponent={CodeMaskCustom}
          //         defaultValue="     "
          //       />
          //     </FormControl>
          //   </form>
          // </Paper>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{getString().email}</InputLabel>
                <Input id="email" name="email" autoFocus />
              </FormControl>
              <strong>
                <h5>OR</h5>
              </strong>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="phone_number">
                  {getString().phone_number}
                </InputLabel>
                <Input
                  id="phone_number"
                  name="phone_number"
                  autoFocus
                  inputComponent={TextMaskCustom}
                  defaultValue="+(220)"
                  onChange={(e) => {
                    this.setState({ phone: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">
                  {getString().password}
                </InputLabel>
                <Input name="password" type="password" id="password" />
              </FormControl>

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />

              <a style={{ cursor: "pointer" }} onClick={this.resetPwd}>
                forgot password?{" "}
              </a>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.validation}
              >
                Sign in
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="danger"
                className={classes.submit}
                onClick={this.cancel}
              >
                {getString().cancel}
              </Button>
            </form>
          </Paper>
        )}
        {this.state.chooseAccounts ? (
          <Dialog
            open={this.state.chooseAccounts}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {getString().accounts}
            </DialogTitle>
            <DialogContent>{this.viewAccounts()}</DialogContent>
          </Dialog>
        ) : null}
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
