import React from "react";
import PropTypes from "prop-types";


// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import Select from 'react-select';
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Button from "components/common/CustomButtons/Button.jsx"
import {getString} from  "assets/js/utils/utils.js";

import { bugs, website, server } from "variables/general.jsx";

import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import {displayError,displaySuccess} from "assets/js/utils/utils.js";

import $ from "jquery";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import HelpUtil from "../Utils/HelpUtil";


class StockTransfer extends React.Component {



    state = {
        value: 0,

        locations: [],
        sourceLocation: null,
        destLocation: null,

        show_details: false,
        back: false,

        dataSending: {
            type: '',
            customer: '',
            date: '',
            subtotal: '',
            total: '',
            discount: '',
            description: '',
            taxamount: '',
            currency: ''
        },
        data: [],

        receiveddata: [],


        location_id: '',
        stocktransferdata: [],
        theAlert: false,
        open: false,
        trnsValue: 0,
        exactTrnsValue: 0,

        callerName: this.props.callerName,
        callerState: this.props.callerState,
        callerProps: this.props.callerProps
    };


    selectedRow = (event,rowData) => {

        if(!this.state.destLocation){
            alert("You must specify a destination");
            return;
        }
        let transferqty = parseInt(prompt("Enter amount to transfer in "+rowData.wholesale_metric));
        if(!transferqty){
            return;
        }
        let currentData = this.state.receiveddata;

        //code to see if the product already exist so as to avoid duplication
        let exist = false;
        let index = 0;
        for(;index<currentData.length;index++){
            if(currentData[index].id === rowData.id){
                 exist = true;
                 break;
            }
        }

        let source = this.state.data;
        let i = 0;
        for(;i<source.length;i++){
            if(source[i].id === rowData.id){
                if((source[i].quantity - transferqty) < 0){
                    alert("You do not have enough stock?");
                    return;
                }else{
                    source[i].quantity -=transferqty;
                }

                break;
            }
        }

        if(exist){
            currentData[index].quantity += transferqty;
            if(!currentData[index].difference)
                currentData[index].difference = 0;
            currentData[index].difference += transferqty;
            if(currentData[index].unit_cost == 0){
                source[i].unit_cost = currentData[index].unit_cost
            }
        }else {
            currentData.push({id:rowData.id, name:rowData.name, unit_cost:source[i].unit_cost, number:source[i].number,
                quantity:transferqty, difference:transferqty,category: rowData.category});
        }

        this.setState({data: source});
        this.setState({receiveddata: currentData});
    };

    getTransferedStock = () => {
        if(!this.state.sourceLocation || !this.state.destLocation){
            alert("You must specify a valid source or destination");
            return;
        }
        if(!this.state.receiveddata || this.state.receiveddata.length === 0){
            alert("You have not transfered any stock");
            return;
        }
        let data = [];
        this.state.receiveddata.forEach((item)=>{
            if(item.difference && item.difference > 0)
                data.push(item);
        });
        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/stocks/transfers",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",

            data: {source:this.state.sourceLocation, dest:this.state.destLocation, items:data},

            success: function (data){

                data = _this.state.receiveddata.map((item)=>{
                    item.difference =null;
                    return item;
                });
                _this.setState({receiveddata:data});
                displaySuccess('stock transfered');
            },
            error: function (error) {
               displayError(error.responseText);
            }
        });
    };



    deletedRow = (rowsDeleted)=>{
        let index = rowsDeleted.data[0].dataIndex;
        let elem = this.state.data[index];

        let arr = this.state.data.slice();
        let rem = arr.splice(index,1);

      this.setState({data: arr});
    };

    deletedRow2 = (rowsDeleted)=>{
        let index = rowsDeleted.data[0].dataIndex;
        let elem = this.state.receiveddata[index];

        let arr = this.state.receiveddata.slice();
        let rem = arr.splice(index,1);

      this.setState({receiveddata: arr});

    };

    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <p>Are you sure you want to transfer stock?</p>
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={() => {
                            this.getTransferedStock();
                            onClose()
                        }}>Save</button>
                    </div>
                )
            }
        })
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () =>{
        this.setState({open: false});
        this.setState({trnsValue: document.getElementById("transferqty").value});
    };

    loadLocations = (page) => {
        let the_data = [];
        page |= 1;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"filter": ":location.gte => Date.today","attrs":["name","id"], "page":page, "size":10,
                "order": [{"by":"desc","attr":"name"}],"model":"Afrijula::Locations::Location"},

            success: function(data){
                $.map(data,function (data) {
                    the_data.push({label:data.name, value:data._id.$oid});
                });
                this.setState({
                    locations: the_data,
                    selectedLocaton: data[0]._id.$oid
                })
            }.bind(this)
        });
    };
    reloadStock = (opt,loc,table) => {
        //we want to set this by force as we need it in the next function.
        this.state[loc] = opt.value;
        this.loadSrcTable(loc,table);
    };
    loadSrcTable = (loc,table) => {
        let the_data = [];

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location/stocks?id="+this.state[loc],
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["name","quantity","id"],
                "order": [{"by":"desc","attr":"name"},{"by":"asc","attr":"quantity"}],"model":"Afrijula::Stocks::Stock"},

            success: function(data){
                $.map(data,function (da) {
                    the_data.push({name: da.name, id: da.stock_id.$oid, quantity:da.quantity, wholesale_metric: da.wholesale_metric, unit_cost:da.unit_cost,number:da.number,category: da.category});
                });
                let hash = {};
                hash[table] = the_data;
                this.setState(hash)
            }.bind(this)
        });
    };

// Correct version of ComponentDidMount
    componentDidMount() {
        //sbensouda: do not load the table. The loading of the location will do this through a call back
        //You cannot do it from here because you do not know the location
        this.loadLocations();
    };

    render() {
        const {classes} = this.props;
        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.transferstock = false;
            return <ChildComponent state = {caller_state} returningProps = {this.state.callerProps} />
        }

        if(this.state.open){
            return this.askQuantity();
        }
        else{
            const header = [
                {
                    field: "name",
                    title:getString().table_name,
                },
                {
                    field: "quantity",
                    title: getString().quantity,
                }
            ];
            const receivedheader = [
                {title: getString().table_name, field:'name'},{title: getString().received_qty, field:'quantity'}
            ];

            return (
                <div style={{marginTop:-40}}>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={9}>

                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            <Button xs={12} sm={12} md={2} color="danger"
                                                    onClick={()=>{this.setState({back: true})}}
                                                    style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", float:"right",
                                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}>{getString().back}</Button>
                                        </GridItem>
                                    </GridContainer>

                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <GridContainer>
                                <GridItem xs={2} sm={12} md={12} style={{zIndex: 10}} >
                                    <Select options={this.state.locations} placeholder={getString().select_source}
                                            defaultValue={this.state.sourceLocation} onChange = {(event) => {this.reloadStock(event,'sourceLocation','data')}}
                                    /><HelpUtil reference={'inventory_transfering_source_dest'}/>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardHeader color="warning">
                                            <h3 className={classes.cardTitleWhite}>{getString().source}</h3>
                                        </CardHeader>

                                        <Table
                                            data={this.state.data}
                                            columns={header}
                                            options={{
                                                selection:false,
                                                sorting: true,
                                                showTitle: false,
                                                debounceInterval: 1000,
                                                selected: true}}
                                            onRowClick = {this.selectedRow}
                                            onRowsDelete = {this.deletedRow}
                                        />
                                        <CardFooter>
                                            {/*TODO we need a field here for received by and this can be doen later*/}
                                            {/*<b>{getString().received_by}: </b>*/}
                                        </CardFooter>

                                    </Card>
                                </GridItem>
                            </GridContainer>

                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} style={{zIndex: 10}} >
                                    <Select options={this.state.locations} placeholder={getString().select_destination}
                                            defaultValue={this.state.destLocation} onChange = {(event) => {this.reloadStock(event,'destLocation','receiveddata')}} />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardHeader color="success">
                                            <h3 className={classes.cardTitleWhite}>{getString().destination}</h3>
                                        </CardHeader>

                                        <Table
                                            data={this.state.receiveddata}
                                            columns={receivedheader}
                                            onRowsDelete = {this.deletedRow2}
                                            options={{
                                                selection:false,
                                                sorting: true,
                                                showTitle:false,
                                                columnsButton:false,
                                                selected: true}}
                                        />
                                        <CardFooter>
                                            <Button xs={12} sm={12} md={2} color="success" onClick={this.submit}
                                                    style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}>
                                                {getString().save}</Button>
                                            <Button xs={12} sm={12} md={2} color="danger"
                                                    style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                                    onClick={()=>{this.setState({back:true})}}>{getString().cancel}</Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
        }

}

StockTransfer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(StockTransfer);
