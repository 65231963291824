import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import {getString, currencyFormat,execPrint,dateFormat} from "assets/js/utils/utils.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DetailsTable from '@material-ui/core/Table';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class TransactionDetails extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    state = this.props.state || {

        back: false,

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        transactionData: this.props.transactionData

    };

    close = () => {
        this.setState({back: true})
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    printTransaction = () => {
        execPrint(document.getElementById("printableView"));
    };

    render() {
        const {classes} = this.getProps();

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state = {this.state.callerState} returningProps = {this.state.callerProps} />

        }
        else{

            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button onClick={this.printTransaction.bind(this)} xs={12} sm={12} md={2} color="success"
                                    style={{
                                        backgroundColor: "#4caf50",
                                        margin: ".3125rem 1px",
                                        minWidth: "auto",
                                        minHeight: "auto",
                                        fontSize: "12px",
                                        color: "#ffffff",
                                        padding: "5px 20px",
                                        borderRadius: "2px",
                                        verticalAlign: "middle"
                                    }}
                            >{getString().to_print}</Button>
                            <Button color="danger" onClick={() => {this.setState({back: true})}}
                                    style={{backgroundColor: "#f44336",margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight:"auto",
                                        padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }}
                            >{getString().back}
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{getString().details}</h4>
                                </CardHeader>
                                <CardBody>
                                    <div style={{"overflow-x":"auto"}} id={'printableView'}>
                                        <DetailsTable style={styles.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell  colSpan={2} style={{fontSize: 15+'px'}}>{getString().type+": "+ this.state.transactionData._type.substring(20)}</TableCell>
                                                    <TableCell  colSpan={2} style={{fontSize: 15+'px'}}>{getString().date+": "+ dateFormat(this.state.transactionData.date)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell  colSpan={2} style={{fontSize: 15+'px'}}>{getString().acc_name+": "+ this.state.transactionData.account_name}</TableCell>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().acc_number+": "+ this.state.transactionData.account_number}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().amount+": "+ currencyFormat(this.state.transactionData.amount)}</TableCell>
                                                    <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().refNo+": "+ this.state.transactionData.reference_number}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().prev_balance+": "+ currencyFormat(this.state.transactionData.prev_balance)}</TableCell>
                                                    <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().prev_local_balance+": "+ currencyFormat(this.state.transactionData.prev_local_balance)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().new_balance+": "+ currencyFormat(this.state.transactionData.new_balance)}</TableCell>
                                                    <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().new_local_balance+": "+ currencyFormat(this.state.transactionData.new_local_balance)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </DetailsTable>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
               </div>
            );
        }
    }
}


export default withStyles(styles)(TransactionDetails);