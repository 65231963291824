import React from "react";
import TextField from "@material-ui/core/TextField";

import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import GridItem from "components/common/Grid/GridItem.jsx";

import Button from "components/common/CustomButtons/Button.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";

import MaterialTable from "material-table";
import {
  dateFormat,
  currencyFormat,
  getToday,
  getString,
} from "assets/js/utils/utils";
import GridContainer from "../../common/Grid/GridContainer";
import SaveButton from "../CustomComponents/SaveButton";
import HelpUtil from "../Utils/HelpUtil";
import Select from "react-select";


const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: '40px',
    padding: '0 6px'
  }),
  control: (base, state) => ({
    ...base,

    height: '44px',
  })
};
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
});

class InvoiceButton extends React.Component {
  state = {
    paymentType: [
      {
        value: "fixed",
        label: getString().fixed,
      },
      {
        value: "percentage",
        label: getString().percentage,
      },
    ],

    numberInstallments: [
      {
        value: "year",
        label: getString().annually,
      },
      {
        value: "day",
        label: getString().daily,
      },
      {
        value: "week",
        label: getString().weekly,
      },
      {
        value: "month",
        label: getString().monthly,
      },
      {
        value: "3 month",
        label: getString().quarterly,
      },
    ],

    instHeader: [
      {
        title: getString().amount,
        field: "amount",
        render: (rowData) => currencyFormat(rowData.amount),
      },
      {
        title: getString().date,
        field: "date",
        editComponent: (props, A) => {
          return (
            <input
              type="date"
              defaultValue={getToday()}
              onChange={(e, v) => {
                props.onChange(e.target.value);
              }}
              value={props.value}
            />
          );
        },
        render: (rowData) => dateFormat(rowData.date),
      },
    ],

    backToSell: false,
    varButton: false,
    fixedButton: false,
    inVoice: false,
    initial_deposit: 0,
    number_installments: 0,
    instBalance: null,
    inst_type: "fixed",
    pay_type: "fixed",
    inst_period: "month",
    installment_date: Date.now(),
    installments: [],
    payment_option: "cash",
    bank: "",
    bank_no: "",
    cheque_no: "",
    made_by: "",
  };

  close = () => {
    this.setState({ backToSell: true });
  };

  getTotal = () => {
    return this.props.getTotal();
  };

  getInvoiceAmount = (e) => {
    this.setState({[e.target.name]: e.target.value});
};

getPay_type = (e) => {
    console.log(e);
    this.setState({ pay_type : e.value });
  };

  getInst_Period= (e) => {
    console.log(e);
    this.setState({ inst_period: e.value });
  };

  paymentPlan = () => {
    return {
      initial_deposit: this.state.initial_deposit,
      installment_type: this.state.inst_type,
      payment_type: this.state.pay_type,
      installments:
        this.state.inst_type === "fixed"
          ? [
              {
                count: this.state.number_installments,
                period: this.state.inst_period,
                date: this.state.installment_date,
              },
            ]
          : this.state.installments,
    };
  };

  saveInvoice = () => {
    if (this.state.inst_type == "variable") {
      let total = this.props.getTotal();
      let balance =
        this.state.pay_type == "fixed"
          ? total - this.state.initial_deposit
          : (this.state.initial_deposit / 100.0) * total;
      let inst_balance = 0;
      for (let i = 0; i < this.state.installments.length; i++) {
        inst_balance += parseFloat(this.state.installments[i].amount);
      }
      if (inst_balance != balance) {
        alert(
          "The total installments plus the deposit must add up to " +
            currencyFormat(balance)
        );
        return;
      }
    }

    this.props.saveSale(
      this.state.payment_option,
      null,
      null,
      this.paymentPlan(),
      "invoice",
      () => {
        this.setState({
          inst_type: "fixed",
          pay_tye: "fixed",
          installments: [],
          initial_deposit: 0,
          number_installments: 0,
          inst_period: "week",
        });
      },
      null,
      this.state.bank,
      this.state.payment_option === "cheque"
        ? this.state.cheque_no
        : this.state.bank_no,
      this.state.made_by
    );
  };
  getBalance = () => {
    let total = this.getTotal();
    let balance =
      this.state.pay_type == "fixed"
        ? total - this.state.initial_deposit
        : total - (this.state.initial_deposit / 100.0) * total;
    if (this.state.inst_type == "variable") {
      let inst_balance = 0;
      for (let i = 0; i < this.state.installments.length; i++) {
        inst_balance += parseFloat(this.state.installments[i].amount);
      }
      return currencyFormat(balance - inst_balance);
    } else {
      return currencyFormat(
        this.state.number_installments && this.state.number_installments > 0
          ? 0.0
          : balance
      );
    }
  };
  handlePaymentOptions = (e) => {
    let paymentOption = e.target.value;
    this.setState({
      payment_option: paymentOption,
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    this.setState({
      installment_date: getToday(),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <form style={{ marginTop: 20 }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridItem xs={12} sm={12} md={12}>
                <h5>
                  {getString().balance_due}: {this.getBalance()}
                </h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4>
                  {getString().initial_deposit}
                  <HelpUtil reference={"sell_invoice_initial_deposit"} />
                </h4>

                <RadioGroup
                  style={{
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                  }}
                  aria-label="Enable Notification"
                  name="payment_options"
                  className={classes.group}
                  value={this.state.payment_option}
                  onChange={(e) => this.handlePaymentOptions(e)}
                  row={true}
                >
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label={getString().cash_payment}
                  />
                  <FormControlLabel
                    value="cheque"
                    control={<Radio />}
                    label={getString().cheque_payment}
                  />
                  <FormControlLabel
                    value="bank"
                    control={<Radio />}
                    label={getString().bank_payment}
                  />
                </RadioGroup>
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} lg={2}>
                  <TextField
                    id="initial_deposit"
                    label={getString().amount}
                    name="initial_deposit"
                    value={this.state.initial_deposit}
                    className={classes.textField}
                    onChange={(e) => {
                      this.getInvoiceAmount(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3} lg={2}>

                  <div>
                    <span style={{margin: 5}}>Deposit Type</span>
                    <Select
                      defaultValue={this.state.pay_type}
                      onChange={(e) => {
                        this.getPay_type(e);
                      }}
                      options={this.state.paymentType}
                      styles={customStyles}

                    />
                  </div>

                </GridItem>
                <GridItem xs={12} sm={3} md={3} lg={2}>
                  <TextField
                    id="made_by"
                    name="made_by"
                    label={getString().made_by}
                    className={classes.textField}
                    value={this.state.made_by}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                {this.state.payment_option === "cheque" ||
                this.state.payment_option === "bank" ? (
                  <GridItem xs={12} sm={3} md={3} lg={2}>
                    <TextField
                      id="bank"
                      name="bank"
                      className={classes.textField}
                      label={getString().bank_name}
                      value={this.state.bank}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                ) : null}
                {this.state.payment_option === "cheque" ? (
                  <GridItem xs={12} sm={3} md={3} lg={2}>
                    <TextField
                      id="cheque_no"
                      name="cheque_no"
                      className={classes.textField}
                      label={getString().cheque_no}
                      value={this.state.cheque_no}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                ) : null}
                {this.state.payment_option === "bank" ? (
                  <GridItem xs={12} sm={3} md={3} lg={2}>
                    <TextField
                      id="bank_no"
                      name="bank_no"
                      className={classes.textField}
                      label={getString().bank_no}
                      value={this.state.bank_no}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
            </GridItem>
          </GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <b>{getString().payment_plan}</b>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                arial-label="Installment Type"
                onChange={this.handleChange}
                className={classes.group}
                value={this.state.inst_type}
                row={true}
                name="inst_type"
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  labelPlacement="start"
                  label={getString().fixed}
                  value="fixed"
                  onChange={(e) => {
                    this.setState({ inst_type: "fixed" });
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  labelPlacement="start"
                  label={getString().variable}
                  value="variable"
                  onChange={(e) => {
                    this.setState({ inst_type: "variable" });
                  }}
                />
              </RadioGroup>
            </FormControl>
          </GridItem>
          {this.state.inst_type == "fixed" ? (
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
              <GridItem xs={12} sm={3} md={3} lg={2}>
              <TextField
                id="number_installments"
                label={getString().number_installments}
                name="number_installments"
                className={classes.textField}
                value={this.state.number_installments}
                onChange={(e) => {
                  this.getInvoiceAmount(e);
                }}
                margin="normal"
                type="number"
                variant="outlined"
              />
              </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={2}>

              <div>
                <span style={{ margin: 5 }}>Installment period</span>
                <br />
                <Select
                      defaultValue={this.state.inst_period}
                      onChange={(e) => {
                        this.getInst_Period(e);
                      }}
                      options={this.state.numberInstallments}
                      styles={customStyles}

                    />
              </div>
              </GridItem>

              <GridItem xs={12} sm={3} md={3} lg={2}>

              <TextField
                id="installment_date"
                name="installment_date"
                className={classes.textField}
                label={getString().start_on}
                value={this.state.installment_date}
                onChange={(e) => {
                  this.getInvoiceAmount(e);
                }}
                margin="normal"
                type="date"
                variant="outlined"
              />
              </GridItem>
              </GridContainer>
            </GridItem>
          ) : (
            <GridItem xs={12} sm={12} md={12}>
              <MaterialTable
                title="Installments"
                columns={this.state.instHeader}
                data={this.state.installments}
                options={{
                  search: false,
                  paging: false,
                  showEmptyDataSourceMessage: false,
                }}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          const data = this.state.installments;
                          newData.index = data.length;

                          if (!newData.date) newData.date = getToday();
                          data.push(newData);
                          this.setState({ installments: data }, () =>
                            resolve()
                          );
                        }
                        resolve();
                      }, 10);
                    }),

                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          const data = this.state.installments;
                          const index = oldData.index;
                          newData.index = index;
                          data[index] = newData;
                          this.setState({ installments: data }, () =>
                            resolve()
                          );
                        }
                        resolve();
                      }, 10);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          const the_data = this.state.installments;
                          let index = oldData.index;

                          the_data.splice(index, 1);

                          for (; index < the_data.length; index++) {
                            the_data[index].index = index;
                          }
                          this.setState({ opening_balance: the_data }, () =>
                            resolve()
                          );
                        }
                        resolve();
                      }, 10);
                    }),
                }}
              />
            </GridItem>
          )}

          <Button
            xs={12}
            sm={12}
            md={4}
            color="danger"
            onClick={this.props.closeInvoice}
          >
            {getString().back}
          </Button>
          {/*TODO we need to support deposit payment for invoice to different payment options*/}
          <SaveButton method={this.saveInvoice} title={getString().pay} />
        </form>
      </div>
    );
  }
}
export default withStyles(styles)(InvoiceButton);
