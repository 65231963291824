import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import {displayError, displaySuccess} from "../../../assets/js/utils/utils";
import cookies from "react-cookies";
import $ from "jquery";
import baseUrl from "../../../assets/js/config/const";


class SaveButton extends React.Component {
    state = {
        disable: false,
        uploadedFile: ''
    };

    handleOnChange = (event) => {
        let file = event.target.files[0];
        this.setState({
            uploadedFile: file.name
        });
        this.saveFile(file);
    };

    saveFile = (file) => {
        let formData = new FormData();
        formData.append('requirement', file);
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/loans/loan_documents",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            data: formData,
            contentType: false,
            processData: false,
            success: function (result) {
                displaySuccess("File uploaded!");
                this.props.setUploadedFile(this.props.requiredDoc, result.reference, file.name);
            }.bind(this),
            error: function (error) {
                displayError(error.responseText);
            }
        })
    };

    removeUpload = () => {
        this.setState({
            uploadedFile: ""
        });
        this.props.removeUpload(this.props.requiredDoc);
    };

    render() {
        return (
            <div>
                <input style={{display: "none"}} onChange={this.handleOnChange} accept="image/*,.pdf"
                       id={this.props.requiredDoc} type="file"/>
                <label htmlFor={this.props.requiredDoc}>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <CloudUploadIcon/>
                    </IconButton>
                </label>
                <span>
                    {this.state.uploadedFile || this.props.uploadedFile}
                    {this.state.uploadedFile === '' && (!this.props.uploadedFile || this.props.uploadedFile === '')
                        ? ""
                        : <IconButton onClick={this.removeUpload} color="Secondary">
                            <CloseIcon/>
                        </IconButton>
                    }

                </span>

            </div>
        );
    }
}

export default SaveButton;
