import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import Typography from "@material-ui/core/Typography/Typography";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input/Input";
import Button from "@material-ui/core/Button/Button";
import {
  currencies,
  displayError,
  displaySuccess,
  getString,
} from "assets/js/utils/utils.js";
const Country = require("country-js");

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  progress: {
    margin: theme.spacing.unit * 2,
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },

  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class ResetPwd extends React.Component {
  state = {
    confirm_password: null,
    new_password: null,
    url_data: {},
  };

  resetAndLogin = () => {
    let _this = this;
    let email = this.state.url_data.acc_add;
    let new_pwd = this.state.new_password;
    let confirm_pwd = this.state.confirm_password;
    if (new_pwd !== confirm_pwd) {
      displayError(
        "Password mismatch! please make sure both passwords are the same."
      );
      return;
    }

    _this.props.type
      ? $.ajax({
          method: "POST",
          url: baseUrl + "/account/authenticate_v2/reset/",
          dataType: "json",
          data: {
            phone_number: email.trim(),
            token: _this.props.token,
            new_pwd: new_pwd,
            confirm_pwd: confirm_pwd,
            stage: "2",
          },
          success: function(data) {
            displaySuccess(data.success);
            setTimeout(function() {
              window.location.reload();
            }, 3000);
          },
        })
      : $.ajax({
          method: "POST",
          url: baseUrl + "/account/authenticate_v2/reset/",
          dataType: "json",
          data: {
            email: email,
            new_pwd: new_pwd,
            confirm_pwd: confirm_pwd,
            stage: "2",
          },
          success: function(data) {
            displaySuccess(data.success);
            setTimeout(function() {
              window.location.reload();
            }, 3000);
          },
        });
  };

  componentDidMount() {
    let data = null;
    if (this.props.phone) {
      data = { acc_add: this.props.phone.trim() };
    } else {
      data = JSON.parse(
        '{"' +
          decodeURI(
            this.props.location.search
              .replace(/\?/, "")
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      );
    }

    this.setState({ url_data: data });
  }

  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <CssBaseline />
          <Typography
            component="h1"
            variant="h5"
            style={{
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <b>{getString().complete_reset}</b> <br />
            for
            <br />
            <b>{this.state.url_data.acc_add}</b>
          </Typography>
          <form className={classes.form} autoComplete="off">
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="new_password">
                {getString().new_password}
              </InputLabel>
              <Input
                id="new_password"
                type="password"
                name="new_password"
                autoComplete="new_password"
                autoFocus
                onChange={(e) => {
                  this.setState({ new_password: e.target.value });
                }}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="confirm_password">
                {getString().confirm_password}
              </InputLabel>
              <Input
                id="confirm_password"
                type="password"
                name="confirm_password"
                autoComplete="confirm_password"
                autoFocus
                onChange={(e) => {
                  this.setState({ confirm_password: e.target.value });
                }}
              />
            </FormControl>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.resetAndLogin}
            >
              {getString().reset}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

ResetPwd.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPwd);
