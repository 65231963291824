import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import $ from "jquery";
import cookies from "react-cookies";

import baseUrl from "assets/js/config/const.js";
import { currencyFormat, dateFormat,compileQuery,showReportUtility,getString} from "assets/js/utils/utils.js";
import ShowDetails from "components/afrijula/AllDetails/ReceiptTemplate.jsx"



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    cardCategory:{
        color: "#000",
        margin: "0px",
        fontSize: "14px",
        fontWeight: "bolder",
        paddingTop: "4px",
    }

});

class ReturnedSales extends React.Component {
    constructor(props){
        super(props);

        this.tableRef = React.createRef();
    }

   

    state = this.props.state || {

        salesData: null,
        header:  [
            {title: getString().type, field:'tran_type',defaultGroupOrder: 0,cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().description, field:'description', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().sale_date, field:'date',render: rowData => dateFormat(rowData.date), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().returned_date, field:'updated_at',render: rowData => dateFormat(rowData.updated_at.slice(0,10)), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().total, field: 'total',render: rowData => currencyFormat(rowData.total), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}}
        ],
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    rowSelect = (event, colData)  => {
        let id = colData._id.$oid;

        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/sales/sales/"+id,
            data:{filter:'installments'},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                if(_this.props.updateMe)
                    _this.props.updateMe(false);
                _this.setState({salesData: result});
            }
        });

    };

    componentDidMount() {
        const props = this.getProps();

        if(props.updateMe)
            props.updateMe(true);
    };

    loadSalesTable = (query,resolve,reject) => {
        let enhanceSearch = [{model: 'Afrijula::Sales::Customer',assoc:'customer_id'}];

        let url = compileQuery(query, (baseUrl + "/afrijula/sales/sales"),
            ["date","sub_total","total","tran_type","pricing","id","description","total_tax","discount","discount_type","updated_at","reversed"],
            [{"by":"desc","attr":"date"}],"Afrijula::Sales::Sale", null, "reversed: {'$eq':true}",
            enhanceSearch);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    render() {
        const {classes} = this.getProps();

        if(this.state.salesData){
            let data = this.state.salesData;
            this.state.salesData = null;
            return <ShowDetails callerProps={this.getProps()} callerState = {this.state} salesData={data} callerName={ReturnedSales} />;
        }
        else{
            return (
                <div className={classes.root}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <Table
                                    tableRef={this.tableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadSalesTable(query,resolve,reject);
                                    })}
                                    columns={this.state.header}
                                    onRowClick={this.rowSelect}
                                    options={{
                                        exportButton: false,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:10,rowStyle:{height:'8px'}
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        },
                                    ]}
                                />
                            </Card>

                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

ReturnedSales.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReturnedSales);