import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import baseUrl from "assets/js/config/const.js";

import Table from "material-table";
import $ from "jquery";
import cookies from "react-cookies";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {displayError,displaySuccess,getString} from "assets/js/utils/utils.js";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class Add_Location extends React.Component {

    constructor(props) {
        super(props);

            this.isValidated = this.isValidated.bind(this);

    }

    isValidated =() =>  {
        return false; // make it return false. just in case. lol
    };

 

    state = {
        defaults:[
            {
                value: 'yes',
                label: getString().yes,
            },
            {
                value: 'no',
                label: getString().no,
            },
        ],
        location_name: '',
        phone: '',
        default_location: '',
        locationData: [],
        header:  [
            {title: getString().name ,field: "name"},
            {title:  getString().address ,field:"address"},
            {title: getString().is_default ,field: "is_default",render: rowData => {return rowData.is_default == true ? 'yes': 'no'}},
        ],
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    saveLocation = () =>{
        let location_name = this.state.location_name;
        let address = this.state.address;
        let _default = this.state.default_location == 'yes' ? true : false;
        let id = this.state.location_id;
        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/locations/location/"+(id || ''),
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"location_name":location_name, "address": address, "is_default": _default},

            success: function (result) {

                _this.setState({
                    location_name: '',
                    address:'',
                    default_location: 'no',
                    showForm: false,
                });
                displaySuccess("location added successfully");
                _this.loadLocations();

            },
            error: function(error){
                alert(error.responseText);
            }
        });
    };

    loadLocations = () => {
        let the_data = [];
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["id","name","address","is_default"],
                "order": [{"by":"asc","attr":"name"}],"model":"Afrijula::Locations::Location"},
            success: function (result) {

                this.setState({locationData:result});
            }.bind(this)
        });
    };

    componentDidMount() {
        this.loadLocations();
    }

    render() {
        const { classes } = this.props;


        return (

            <div>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{getString().add_location}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            autoFocus
                                            id="location_name"
                                            label={getString().store_name}
                                            name="location_name"
                                            className={classes.textField}
                                            value={this.state.location_name}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="address"
                                            label={getString().address}
                                            name="address"
                                            className={classes.textField}
                                            value={this.state.address}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>

                                        <TextField
                                            id="default_location"
                                            name="default_location"
                                            select
                                            label={getString().default}
                                            className={classes.textField}
                                            value={this.state.default_location}
                                            onChange={e => {this.handleChange(e)}}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            helperText={getString().default_chooser}
                                        >
                                            {this.state.defaults.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <Button color="success" onClick={this.saveLocation} >{getString().add_more}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                            <Table
                                title="Location Lists"
                                data = {this.state.locationData}
                                columns = {this.state.header}

                            />

                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

Add_Location.propTypes = {
    classes: PropTypes.object.isRequired,

};

export default withStyles(styles)(Add_Location);
