import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";

import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import countryList from 'react-select-country-list';
import Select, {Async} from "react-select";
import {onlyLetters,validateEmail,phonenumber,displayError,displaySuccess,getString} from "assets/js/utils/utils.js";
import SaveButton from "../CustomComponents/SaveButton";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});
const countries = countryList().getData();

class AddingSupplier extends React.Component {




    state = {
        id: null,
        backToSupplier: false,

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        supplierData: null,
    };

    getDefaultSupplier = () =>{
        this.state.supplierData = (this.props.supplierData) ? {
            name: this.props.supplierData.name,
            phone_number: this.props.supplierData.phone_number,
            contact_person: this.props.supplierData.contact_person,
            email: this.props.supplierData.email,
            shipping_address: this.props.supplierData.shipping_address,
            billing_address: this.props.supplierData.billing_address,
            country: this.props.supplierData.country,
            _id: this.props.supplierData._id
        } : {
            name:'',
            phone_number: '',
            contact_person: '',
            email: '',
            shipping_address: '',
            billing_address: '',
            country: '',
        };

    };

    handleChange = (e) => {
        let supplier = this.state.supplierData;
        supplier[e.target.name] = e.target.value;

        this.setState({
            supplierData: supplier
        });
    };
    
    newSupplier = () => {
        let supplier = {name: this.state.supplierData.name.trim(), contact_person: this.state.supplierData.contact_person.trim(),
            billing_address: this.state.supplierData.billing_address.trim(), email: this.state.supplierData.email.trim(),
            phone_number: this.state.supplierData.phone_number.trim(), shipping_address: this.state.supplierData.shipping_address.trim(),
            country: this.state.supplierData.country.trim()};

        let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;
        if(supplier.name === "" || !reg.test(supplier.name)){
           displayError("Please enter valid supplier name");
            document.getElementById("name").focus();
            return;
        }
        if( supplier.billing_address ===""){
           displayError("Please enter billing address");
            document.getElementById("billing_address").focus();
            return;
        }
        if( supplier.phone_number ==="" || !phonenumber(supplier.phone_number)){
           displayError("Please enter telephone number");
            document.getElementById("phone_number").focus();
            return;
        }
        if( supplier.email !=="" && !validateEmail(supplier.email)){
           displayError("Please enter a valid email address");
            document.getElementById("email").focus();
            return;
        }
        if(supplier.contact_person ==="" || !reg.test(supplier.contact_person)){
            displayError("Please enter contact person's name");
            document.getElementById("contact_person").focus();
            return;
        }

        let id = (this.props.editSupplier) ? this.state.supplierData._id.$oid : this.state.id;

        let _this = this;
        let _data = _this.state.supplierData;
        let _method = (this.props.editSupplier) ? "PUT" : "POST";
        return $.ajax({
            method: _method,
            url: baseUrl + "/afrijula/suppliers/supplier/"+(id || ''),
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: supplier,

            success: function (result) {
                if(_this.props && _this.props.updateSupplier){
                    _this.props.updateSupplier(result);
                }
                if(this.props.updateMe)
                    _this.props.updateMe(_data);
                displaySuccess("Successfully added new supplier.");
                _this.close();
            }.bind(this),
            error: function(error){
                displayError(error.responseText);
            }
        });
        if(this.props.closeInvoice){this.props.closeInvoice()}
    };

    back = () => {
        this.setState({backToSupplier: true});
    };

    close = () => {
        this.setState({
            id:null,
            backToSupplier:true
        });
        if(this.props.closeInvoice){this.props.closeInvoice()}
        if(this.props.closeEdit){this.props.closeEdit()}
    };

    updateCountry = (val) => {
        let supplier_country = this.state.supplierData;
        supplier_country.country = val;
        this.setState({supplierData: supplier_country});
    };

    componentWillMount() {
        this.getDefaultSupplier();
    }

    render() {
        const { classes } = this.props;
        if(this.state.backToSupplier){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.addsupplier = false;
            return <ChildComponent state = {caller_state} returningProps = {this.state.callerProps} />
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                {(this.props.editSupplier) ? <h4 >{getString().edit}</h4> : <h4 >{getString().add_supplier}</h4>}
                            </CardHeader>

                            <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label={getString()._name}
                                                className={classes.textField}
                                                value={this.state.supplierData.name}
                                                onChange={(e) => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="billing_address"
                                                name="billing_address"
                                                label={getString().billing_addr}
                                                className={classes.textField}
                                                value={this.state.supplierData.billing_address}
                                                onChange={(e) => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="phone_number"
                                                name="phone_number"
                                                label={getString().telephone}
                                                className={classes.textField}
                                                value={this.state.supplierData.phone_number}
                                                onChange={(e) => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                id="email"
                                                name="email"
                                                label={getString().email}
                                                className={classes.textField}
                                                value={this.state.supplierData.email}
                                                onChange={(e) => {this.handleChange(e)}}
                                                type="email"
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                id="contact_person"
                                                name="contact_person"
                                                label={getString().contact_person}
                                                className={classes.textField}
                                                value={this.state.supplierData.contact_person}
                                                onChange={(e) => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                id="shipping_address"
                                                name="shipping_address"
                                                label={getString().shipping_addr}
                                                className={classes.textField}
                                                value={this.state.supplierData.shipping_address}
                                                onChange={(e) => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4} style = {{marginLeft: 9+'px',marginTop: 10+'px'}} >
                                            <Select options={countries} placeholder = "Country" name="country" id="country"
                                            onChange={opt => {this.updateCountry(opt.label)}} />
                                        </GridItem>
                                    </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button xs={12} sm={6} md={2} color="danger" onClick={this.close.bind(this)}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    >{getString().cancel}</Button>
                                    <SaveButton method={this.newSupplier} title={getString().save} />
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

AddingSupplier.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddingSupplier);
