import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { withStyles } from '@material-ui/core/styles';
import Rodal from "rodal";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-select";
import {getString,setLocale,getLocale} from "assets/js/utils/utils.js";



// @material-ui/icons

// core components





const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    cardCategory:{
        color: "#000",
        margin: "0px",
        fontSize: "14px",
        fontWeight: "bolder",
        paddingTop: "4px",
    }
});


class LocaleSelector extends React.Component {

    constructor(props) {
        super(props);

    }
    state = {

        defaults_locale:[
            {
                value: 'en-GB',
                label: 'English UK',
            },
            {
                value: 'en-US',
                label: 'English US',
            },
            {
                value: 'fr',
                label: 'French',
            },
        ],


    };

      render() {
        const {classes} = this.props;


        return (
            <div className={classes.root} >

                <Rodal visible={this.props.selectLocale} onClose={this.props.close.bind(this)}  height="140">
                    <p style={{textAlign:"center", marginBottom:10,color:"#000",fontSize:18}}>{getString().setLocale}</p>
                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth>
                            <Select options={this.state.defaults_locale} placeholder={"locale"} name="locale" id="locale"
                                    defaultValue={getLocale()} onChange={opt => {setLocale(opt.value); this.props.close()}}  />
                        </FormControl>

                    </form>
                </Rodal>

            </div>
        );

    }
}

LocaleSelector.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LocaleSelector);
