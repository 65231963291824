import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Link from '@material-ui/core/Link';
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Table from "material-table";
import CardFooter from "components/common/Card/CardFooter.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import Select from "react-select";
import countryList from 'react-select-country-list';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Image from "components/afrijula/Utils/ImageUtil.jsx";
import {dateFormat, displaySuccess, applyPackaging, displayError, getString} from "assets/js/utils/utils.js";
import Rodal from 'rodal';

//import css
import 'rodal/lib/rodal.css';
import Checkbox from "@material-ui/core/Checkbox";
import HelpUtil from "../Utils/HelpUtil";


const Country = require('country-js');


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

const currencies = countryList().getData();



const countries = countryList().getData();

const   _locales = [
        {
            value: 'en-US',
            label: 'en',
        },
        {
            value: 'fr-FR',
            label: 'fr',
        },
        {
            value: 'ca-ES',
            label: 'ca',
        },
    ];

class General_Info extends React.Component {
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.locTableRef = React.createRef();
    };

    state = {

        printerType:  [
            {
                value: 'thermal',
                label: getString().thermal_printer,
            },
            {
                value: 'a4 paper',
                label: getString().large,
            },
        ],

        locales: [
            {
                value: 'en-US',
                label: 'en',
            },
            {
                value: 'fr-FR',
                label: 'fr',
            },
            {
                value: 'ca-ES',
                label: 'ca',
            },
        ],
        defaults:[
            {
                value: 'yes',
                label: getString().yes,
            },
            {
                value: 'no',
                label: getString().no,
            },
        ],

        tax_percentage: null,
        company_name: '',
        phone: '',
        address: '',
        email: '',
        country: '',
        location_id: null,
        enable_notifications: true,
        editPwd: false,
        invoice_notice_frequency: {ontime: 10, overdue: 5},

        location_name: '',
        manager: '',

        return_to_genInfo: false,
        locale: 'en',
        default_locale: null,
        default_currency: 'GMD',
        enable_stock: '',
        stock_level: '',
        default_location: 'no',
        location: '',
        settings_id: '',
        account_id: '',
        printerValue: 'thermal',
        invoicing: '',

        new_pwd: '',
        current_pwd: '',
        verify_pwd: '',
        add_location: false,
        countryName: 'Gambia (GMD)',

        organisation: {
            company_name: '',
            address: '',
            country: 'Gambia',
            phone: '',
            email: '',

        },

        details: {
            locale: '',
            enable_notifications: true,
            stock_level: '',
            default_currency: 'GMD',
        },

        the_location:{
            name: '',
            default_location: '',
        },

        default: '',
        showForm: false,
        editLocation: false,
        locationData: [],
        locationHeader:  [
            {title: getString().store_name,field:'name', cellStyle:{padding:'4px 12px'}, headerStyle:{padding:'4px 12px'}},
            {title: getString().address,field:'address', cellStyle:{padding:'4px 12px'}, headerStyle:{padding:'4px 12px'}},
            {title: getString().default,field:'is_default', cellStyle:{padding:'4px 12px'}, headerStyle:{padding:'4px 12px'}},
        ],

        tokenTableHeader:  [
            { title: getString().token, field: 'token',render: rowData => this.truncateToken(rowData.token,35), cellStyle:{padding:'4px 12px'}, headerStyle:{padding:'4px 12px'}},
            { title: getString().status, field: 'status', cellStyle:{padding:'4px 12px'}, headerStyle:{padding:'4px 12px'}},
            { title: getString().last_used_date, field: 'last_seen',render: rowData => dateFormat(rowData.last_seen), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},

        ],
        tokens: [],
        automateReconciliation: true,

    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    add_location = () => {
        this.setState({add_location:true});
    };

    handleAddLocation = () => {
        this.setState({
            showForm: true,
        });
    };

    tableChange = () => {
        this.loadLocations();
    };

    loadTableData = (page) => {

        let the_data = [];
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/account/-1",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["company_name","address","country","contact_number",'contact_email','_id']},

            success: function (result) {
                this.setState({
                    company_name: result.name,
                    address: result.address,
                    country: result.country,
                    email: result.contact_email,
                    phone: result.contact_number,
                    account_id: result._id.$oid
                });
            }.bind(this)
        });

        $.ajax({
            method: "GET",
            url: baseUrl + "/account/settings/-1",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["locale","package","term","term_charge","balance","max_users",'default_currency','_id', 'invoice_notice_frequency',
                    'low_stock_level','enable_notifications','automate_reconciliation','printer_type','additional_invoice_info','tax_percentage']},

            success: function (result) {
                let temp = null;
                for(let i=0;i<_this.state.locales.length;i++){
                    if(_this.state.locales[i].label === result.locale){
                        temp = _this.state.locales[i];
                        break;
                    }
                }
                let curr = Country.search(result.default_currency);
                this.setState({
                    package: result.package,
                    default_locale: result.locale,
                    term: result.term,
                    term_charge: result.term_charge,
                    balance: result.balance,
                    invoicing: result.organization.additional_invoice_info,
                    max_users: result.max_users,
                    default_currency: result.default_currency,
                    tax_percentage: result.tax_percentage,
                    settings_id: result._id.$oid,
                    stock_level: result.low_stock_level,
                    enable_notifications: result.enable_notifications === "true",
                    automateReconciliation: result.automate_reconciliation,
                    printerValue: result.printer_type === "a4" ? "a4 paper" : result.printer_type,
                    countryName: curr[0].name+'('+curr[0].currency.currencyCode+')',
                    invoice_notice_frequency: result.invoice_notice_frequency || _this.state.invoice_notice_frequency
                });

            }.bind(this)
        });
        this.loadLocations();
    };

    loadLocations = () => {
        let the_data = [];
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["name","_id","is_default","address"],
                "order": [{"by":"asc","attr":"name"}],"model":"Afrijula::Locations::Location"},
            success: function (result) {
                $.map(result,function (data) {
                    let arr = {name: data.name, address: data.address, is_default: data.is_default == true ? 'yes' : 'no', _id: data._id.$oid}

                    the_data.push(arr);
                });
                let defaultExist = false;
                result.map(loc =>{
                    if(loc.is_default === true){
                        defaultExist = true;
                    }
                });
                if(defaultExist === false){
                    displayError(getString().noDefaultLocation);
                }
                this.setState({locationData:the_data});
            }.bind(this)
        });
    };

    saveOrganisation = () => {
        this.state.organisation.company_name = this.state.company_name;
        this.state.organisation.address = this.state.address;
        this.state.organisation.phone = this.state.phone;
        this.state.organisation.email = this.state.email;
        this.state.organisation.country = this.state.country;
        let id = this.state.account_id;
        let _this = this;

        $.ajax({
            method: "PUT",
            url: baseUrl+"/account/account/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},

            data:{"company_name": this.state.organisation.company_name, "country": this.state.organisation.country,
                "address": this.state.organisation.address, "contact_phone": this.state.organisation.phone,
                "contact_email": this.state.organisation.email},

            success: function (result) {
                displaySuccess(result.success);
            }.bind(this)
        });
    };

    handleCurrency = value => {
        let curr = Country.search(value['value']);

        let data;
        if(curr && curr[0]){
             data = curr[0].currency.currencyCode;
             let _name = curr[0].name+ '('+data+')';
            this.setState({
                default_currency: data,
                countryName: _name,

            });
        }
    };

    saveSettings = () => {
        let _this = this;
        let locale = this.state.locale;
        let stock_level = this.state.stock_level;
        let enable_notifications = this.state.enable_notifications;
        let default_currency =  this.state.default_currency;
        let printerValue = this.state.printerValue;
        let tax_percentage = this.state.tax_percentage;
        let invoicing = this.state.invoicing;
        let automateReconciliation = this.state.automateReconciliation;
        let id = this.state.settings_id;
        let invoice_notice_frequency = this.state.invoice_notice_frequency;
        //let userID = this.
        $.ajax({
            method: "PUT",
            url: baseUrl+"/account/settings/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"locale": locale, "enable_notifications": enable_notifications, invoicing: invoicing,
                "low_stock_level": stock_level,"default_currency":default_currency,"printer_type":printerValue,
                "tax_percentage": tax_percentage,"automate_reconciliation": automateReconciliation, invoice_notice_frequency: invoice_notice_frequency},

            success: function (result) {
                displaySuccess(result.success);
                cookies.save('tax_percentage',tax_percentage);
                cookies.save('printer_type',printerValue);
            }.bind(this)
        });
    };

    handleNotification = (e)=>{
        let enable_notif = (e.target.value === 'yes' )? true : false;
        this.setState({
            enable_notifications: enable_notif,
        });
    };

    updateCountry = (name, val) => {
        let _organisation = this.state.organisation;
        _organisation.country = val;
        this.setState({organisation: _organisation});
    };

    saveLocation = () =>{
        let location_name = this.state.location_name;
        let address = this.state.address;
        let is_default = this.state.default_location;
        let id = this.state.location_id;
        let _this = this;
        $.ajax({
            method: id == null ? "POST" : "PUT",
            url: baseUrl + "/afrijula/locations/location/"+(id || ''),
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"location_name":location_name, "address": address, "is_default": is_default},
            success: function (result) {
                _this.setState({
                    location_name: null,
                    address:null,
                    location_id: null,
                    default_location: null,
                    showForm: false,
                    editLocation: false
                });
                _this.tableChange('changePage');
                displaySuccess("location added successfully");
            },
            error: function(error){
                displayError(error.responseText);
            }
        });
    };

    deleteLocation =(rowData) => {
        const data = rowData;

        if(data.is_default == 'yes'){
            displayError("You cannot delete a default location. Please change the default location first!");
            return
        }

        if(window.confirm("Location will be deleted!")){
            const _this = this;
            const id = data._id;
            $.ajax({
                method:'DELETE',
                url : baseUrl + '/afrijula/locations/location/'+id,
                dataType:'json',
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                success: function(result){
                    _this.tableChange('changePage');
                    displaySuccess("Removed location")
                },error: function(error){
                    displayError(error.responseText);
                }
            })
        }
    };

    addToken = () => {
        let _this = this;

        $.ajax({
            method: "POST",
            url: baseUrl + "/account/tokens",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                let _tokens = _this.state.tokens;
                _tokens.push(result);
                _this.setState({tokens: _tokens});
                displaySuccess("created token");
                let tokens = _this.state.tokens;
                tokens.push(result)
                _this.setState({tokens: tokens});
            },
        });
    };

    getTokens = () =>{
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/tokens",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            success: function (data) {
                this.setState({
                    tokens: data,
                })
            }.bind(this)
        });
    };

    deleteToken = (data) =>{
        let _this = this;
        let id = data._id.$oid;
        $.ajax({
            method: "DELETE",
            url: baseUrl + "/account/tokens/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            success: function (data) {
                let _tokens = _this.state.tokens;
                _tokens[data.tableData.id].status = "inactive";
                _this.setState({
                    tokens: _tokens
                });
                displaySuccess("Token successfully deleted");
            }.bind(this),
            error: function (e) {
                displayError("Token could not be deleted");
            }
        });

    };

    copyToken = (data) =>{
        let tokenField = document.createElement('textarea');
        document.body.appendChild(tokenField);
        tokenField.value = data.token;
        tokenField.select();
        document.execCommand("copy");
        document.body.removeChild(tokenField);
    };

    truncateToken = (token,len) =>{
        if(token.length <= len){
            return token;
        }else {
            return token.slice(0,len)+"...";
        }
    };

    rowSelect = (colData, cellMeta)  => {
        let id = this.state.locationData[cellMeta.dataIndex][3];
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({location_name: result.name, address: result.address, default_location: result.is_default});
                _this.setState({showForm: true});
            },
        });

    };

    editLocation = (row) => {
       this.setState({editLocation: true,location_id: row._id,location_name: row.name,address:row.address,default_location:row.is_default});

    };

    renderForm = () => {
        const { classes } = this.props;
        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{getString().add_location}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    <GridItem xs={12} sm={6} md={4}>
                                        <TextField
                                            autoFocus
                                            id="location_name"
                                            label={getString().store_name}
                                            name="location_name"
                                            className={classes.textField}
                                            value={this.state.location_name}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <TextField
                                            id="address"
                                            label={getString().address}
                                            name="address"
                                            className={classes.textField}
                                            value={this.state.address}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <TextField
                                            id="default_location"
                                            name="default_location"
                                            select
                                            label={getString().default}
                                            className={classes.textField}
                                            value={this.state.default_location}
                                            onChange={e => {this.handleChange(e)}}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            helperText={getString().default_chooser}
                                        >
                                            {this.state.defaults.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <Button color="success" onClick={this.saveLocation} >{getString().save}</Button>
                                <Button color="error" onClick={()=>{this.setState({showForm:false,editLocation:false})}} >{getString().cancel}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>);
    };

    onChange = (opt) =>{
        this.setState({locale: opt.label})
    };

    handleInvNotice = (opt) => {
        let d = this.state.invoice_notice_frequency;
        d[opt.target.name] = opt.target.value;
        this.setState({invoice_notice_frequency: d})
    };

    onChangePrinter = (opt) =>{
        this.setState({printerValue: opt.value})
    };

    componentDidMount() {
        this.loadTableData();
        this.getTokens();
        let printer = cookies.load('printer_type');
        this.setState({
            printerValue: printer
        });
    };

    closePwdChange = () => {
        this.setState({editPwd: false, current_pwd: '',new_pwd:'', verify_pwd: ''});
    };

    savePwd = () => {
        const _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/authenticate_v2/change",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {new_pwd: this.state.new_pwd,current_pwd: this.state.current_pwd, verify_pwd: this.state.verify_pwd},
            success: function (result) {
                displaySuccess(result.success);
                _this.closePwdChange();
            },error: function(resp){
                displayError(resp.responseText);
            }
        });
    }

    showPassword = () => {
        const { classes } = this.props;

        return (this.state.editPwd) ?
            (
                <div>
                    <Rodal height={350} visible={this.state.editPwd} showMask={false} onClose={this.closePwdChange.bind(this)}>
                        <div>
                            <TextField
                                autoFocus
                                id="current_pwd"
                                label={getString().current_pwd}
                                name="current_pwd"
                                type="password"
                                className={classes.textField}
                                value={this.state.current_pwd}
                                onChange={e => {this.handleChange(e)}}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                autoFocus
                                id="new_pwd"
                                label={getString().new_password}
                                name="new_pwd"
                                type="password"
                                className={classes.textField}
                                value={this.state.new_pwd}
                                onChange={e => {this.handleChange(e)}}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                autoFocus
                                id="verify_pwd"
                                label={getString().verify_new_pwd}
                                name="verify_pwd"
                                type="password"
                                className={classes.textField}
                                value={this.state.verify_pwd}
                                onChange={e => {this.handleChange(e)}}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <Button color="primary" onClick={this.savePwd.bind(this)}>{getString().save}</Button></Rodal>
                </div>
            ) : <div/>
    };

    render() {
        const { classes } = this.props;

        return (

            <div>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <h4 className={classes.cardTitleWhite}>{getString().org_info}</h4>
                        <GridContainer>
                            <GridItem GridItem xs={12} sm={8} md={9}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    autoFocus
                                                    id="comapny_name"
                                                    label={getString().business_name}
                                                    name="company_name"
                                                    className={classes.textField}
                                                    value={this.state.company_name}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                />

                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    id="address"
                                                    label={getString().address}
                                                    name="address"
                                                    className={classes.textField}
                                                    value={this.state.address}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4} style = {{marginTop: 13+'px'}}>
                                                <span>{getString().country}</span>
                                                <Select options={countries} placeholder = {this.state.organisation.country} name="country" id="country"
                                                        defaultValue ={this.state.organisation.country}  onChange={opt => {this.updateCountry('country',opt.label)}} />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    id="phone"
                                                    label={getString().telephone}
                                                    name="phone"
                                                    className={classes.textField}
                                                    value={this.state.phone}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={7}>
                                                <TextField
                                                    id="email"
                                                    label={getString().email}
                                                    name="email"
                                                    className={classes.textField}
                                                    value={this.state.email}
                                                    onChange={e => {this.handleChange(e)}}
                                                    type="email"
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                    <CardFooter>
                                        <Button color="success" onClick={this.saveOrganisation} >{getString().save_settings}</Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem GridItem xs={12} sm={4} md={3}>
                                <Card>
                                    <CardBody>
                                        <Image  style={{height: "1px", width: "5px"}} model={"Afrijula::Account"}
                                               label={getString().upload_logo} upSize={100}
                                               id={this.state.account_id} callerName={"General_Info"} tag="image"/>

                                        <Link color="danger" style={{cursor: 'pointer',textDecoration:'none'}} onClick={() => {this.setState({editPwd: !this.state.editPwd})}}>
                                            {getString().changePswd}
                                        </Link>
                                        {this.showPassword()}
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader>
                                <h4 className={classes.cardTitleWhite}>{getString().tokens}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button color="success"
                                                style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                    minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                                   onClick={this.addToken} disabled={applyPackaging('api')}>{getString().add}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={12} >
                                        <Card>
                                            <Table
                                                tableRef={this.tableRef}
                                                data={this.state.tokens}
                                                columns={this.state.tokenTableHeader}
                                                options={{
                                                    paging:false,
                                                    showTitle:false,
                                                    toolbar: false,
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                        onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                                    },
                                                    {
                                                        icon: 'delete',
                                                        tooltip: 'Delete Token',
                                                        onClick: (event, rowData) => {
                                                            this.deleteToken(rowData);
                                                        }
                                                    },
                                                    {
                                                        icon: 'file_copy',
                                                        tooltip: 'Copy Token',
                                                        onClick: (event, rowData) => {
                                                            this.copyToken(rowData);
                                                        }
                                                    },
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{getString().settings}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>{getString().locale}</span>
                                        <Select options={this.state.locales} placeholder={this.state.locale} name="locale" id="locale"
                                               defaultValue={this.state.locale} onChange={opt => {this.onChange(opt)}}  />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} style={{marginTop: -14+'px'}}>
                                        <TextField
                                            autoFocus
                                            id="stock_level"
                                            label={getString().low_stock_threshold}
                                            name="stock_level"
                                            className={classes.textField}
                                            value={this.state.stock_level}
                                            onChange={e => {this.handleChange(e)}}
                                            type="number"
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} style={{marginTop: -15+'px'}}>
                                        <h4>{getString().enable_notification}</h4>

                                        <RadioGroup
                                            aria-label="Enable Notification"
                                            name="enable_notifications"
                                            className={classes.group}
                                            value={this.state.enable_notifications ? "yes" : "no"}
                                            onChange={e => this.handleNotification(e)}
                                            row
                                        >
                                            <FormControlLabel value="no" control={<Radio />} label="no" />
                                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                        </RadioGroup>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <span>{getString().currency}</span>
                                        <Select options={currencies} placeholder={this.state.countryName} name="currency" id="currency"
                                                value={this.state.default_currency} onChange={this.handleCurrency}  />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>{getString().printer}</span>
                                        <Select options={this.state.printerType} placeholder={this.state.printerValue} name="printer" id="printer"
                                                defaultValue={this.state.printerValue} onChange={opt => {this.onChangePrinter(opt)}}  />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>{getString().vat}</span>
                                        <TextField
                                            name="tax_percentage"
                                            id="tax_percentage"
                                            className={classes.textField}
                                            value={this.state.tax_percentage}
                                            onChange={e => {this.handleChange(e)}}
                                            type="number"
                                            margin="normal"
                                            style={{marginTop: 1+"px"}}
                                            variant="outlined"/>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.automateReconciliation}
                                                    color="primary"
                                                    onChange={(e) => {this.setState({automateReconciliation: !this.state.automateReconciliation})}}
                                                    value={this.state.automateReconciliation}/>}
                                            label={getString().automateRecon}
                                        /><HelpUtil reference={'settings_automate_account_reconciliation'}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>{getString().invoice_info}</div>
                                        <TextField
                                            multiline={true}
                                            rows={3}
                                            name="invoicing"
                                            id="invoicing"
                                            className={classes.textField}
                                            value={this.state.invoicing}
                                            onChange={e => {this.handleChange(e)}}
                                            type="text"
                                            margin="normal"
                                            style={{marginTop: 1+"px"}}
                                            variant="outlined"/>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <span>{getString().invoice_notice_frequency}</span>
                                            <div>
                                                <div><span>{getString().overdue}</span><TextField name="overdue" id="overdue" className={classes.textField} value={this.state.invoice_notice_frequency.overdue}
                                                                            onChange={e => {this.handleInvNotice(e)}} type="number" margin="normal"/></div>
                                                <div><span>{getString().ontime}</span><TextField name="ontime" id="ontime" className={classes.textField} value={this.state.invoice_notice_frequency.ontime}
                                                                            onChange={e => {this.handleInvNotice(e)}} type="number" margin="normal"/></div>
                                            </div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="success" onClick={this.saveSettings} >{getString().save_settings}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button color="primary" onClick={this.handleAddLocation} >
                            <i className="material-icons">my_location</i> {getString().add_location}</Button><HelpUtil reference={'settings_new_location'}/>
                    </GridItem>
                </GridContainer>
                { this.state.showForm || this.state.editLocation ? this.renderForm() : <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h3 className={classes.cardTitleWhite}>{getString().location}</h3>
                            </CardHeader>
                            <Table
                                data = {this.state.locationData}
                                columns = {this.state.locationHeader}
                                tableRef={this.locTableRef}
                                options={{
                                    search: false,
                                    serverSide: true,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    viewColumns: false,
                                    selectableRows: false,
                                    pagination: false
                                }}
                                actions={[

                                    {
                                        icon: 'delete',
                                        tooltip: 'Delete Location',
                                        onClick: (event, rowData) => {
                                            this.deleteLocation(rowData);
                                        }
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Location',
                                        onClick: (event, rowData) => {
                                            this.editLocation(rowData);
                                        }
                                    },
                                ]}
                            />
                        </Card>
                    </GridItem>
                </GridContainer> }

            </div>
        );
    }
}

General_Info.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(General_Info);
