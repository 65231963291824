import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Dialog from '@material-ui/core/Dialog';
import NewStock from "components/afrijula/AddForm/AddStock.jsx";


const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit,
        outline: 'none',
    },
});

class SimpleModal extends React.Component {
    state = {
        open: false,
        newstock: false,

        newsupplier: {
            name: '',
            phone: '',
            product: '',
            date:''
        },
    };

    backtonew = () =>{
        this.setState({newstock: true})
    };

    newCustomer = () =>{

        let name = document.getElementById("suppliername").value;
        let phone = document.getElementById("phone").value;
        let product = document.getElementById("product").value;
        let date = document.getElementById("date").value;

        alert("The new supplier's details "+"\n"+name+"\n"+phone+"\n"+product+"\n"+date);
        this.backtonew();
    };

    newdate = () => {
         new Date();
};
    render() {
        const { classes } = this.props;
        if(this.state.newstock) {
            return <NewStock/>
        }
        else{
        return (
            <div style={{width:100}}>
                <GridItem xs={12} sm={12} md={12} >
                    <Dialog
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.props.open}
                        onClose={this.props.onClose}
                    >
                        <Card>
                            <CardHeader color={"info"}>
                                <h4>Add Supplier</h4>
                            </CardHeader>
                            <CardBody>
                                <form autoComplete="on">
                                    <GridContainer>

                                        <GridItem xs={12} sm={12} md={4}>
                                            <input type=" text" name="supplierName" id="suppliername" placeholder="Name"
                                                   autoComplete="on" required/>

                                        </GridItem>
                                    </GridContainer>
                                    <br/>
                                    <GridContainer>

                                        <GridItem xs={12} sm={12} md={4}>
                                            <input type="text" name="phone" id="phone" placeholder="Phone"
                                                   autoComplete="on"/>

                                        </GridItem>
                                    </GridContainer>
                                    <br/>
                                    <GridContainer>

                                        <GridItem xs={12} sm={12} md={4}>
                                            <input type="text" name="product" id="product" placeholder="Product"
                                                   autoComplete="on"/>

                                        </GridItem>
                                    </GridContainer>
                                    <br/>
                                    <GridContainer>

                                        <GridItem xs={12} sm={12} md={4}>
                                            <input type="date" name="date" id="date" autoComplete="on" value={this.newdate}/>

                                        </GridItem>
                                    </GridContainer>
                                </form>

                            </CardBody>



                                <GridItem xs={12} sm={12} md={12}>
                                    <Button xs={12} sm={12} md={2} color="success" onClick={this.newCustomer}>Save</Button>
                                    <Button xs={12} sm={12} md={2} color="danger" onClick={this.backtonew} onClose = {this.state.open}>Cancel</Button>
                                </GridItem>

                        </Card>

                    </Dialog>
                </GridItem>
            </div>
        );
        }
    }
}

SimpleModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;