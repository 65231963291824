import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Table from "material-table";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import {currencyFormat, getString, numberFormat, showReportUtility} from "assets/js/utils/utils.js";


import {completedTasksChart, dailySalesChart, emailsSubscriptionChart} from "variables/charts.jsx";
import baseUrl from "assets/js/config/const.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import Tasks from "components/common/Tasks/Tasks";
import AsyncSelect from "react-select/async";
import Rodal from "rodal";
import HelpUtil from "../Utils/HelpUtil";
import TextFields from "../../common/CustomInput/Form";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";


class Reports extends React.Component {


    state = {
        value: 0,
        expenseLines: [],
        reportEvents: [],
        tasksIndexes: [],
        rawData: [],
        revenueLines: [],
        tilesData: {
            tot_customers: '',
            tot_due_invoice: '',
            tot_daily_revenue: '',
            tot_daily_invoice: '',
        },
        pageList: [],

        anchorEl: null,
        chartData: {
            dailySales: JSON.parse(JSON.stringify(dailySalesChart)),
            prevDailySales: JSON.parse(JSON.stringify(dailySalesChart)),
            monthlyExpenses: JSON.parse(JSON.stringify(emailsSubscriptionChart)),
            stockSales: JSON.parse(JSON.stringify(completedTasksChart))
        },
        //helputil be added only this way through helplet
// helplet: <HelpUtil reference={'reports_bank_account_balances'}/>
        newReportsData: [
            {name: getString().aging_receivables, tag: 'aging_debtors'},
            {name: getString().monthly_product_sales, tag: 'product_sales_month' },
            {name: getString().cashbook_rpt, tag: 'cash_flow'},
            {name: getString().cash_flow_rpt, tag: 'cashbook_report'},
            {name: getString().stock_sales_by_loc, tag: 'sales_by_location'},
            {name: getString().bank_acc_bals, tag: 'account_balances'},
            {name: getString().active_contracts, tag: 'due_contracts'},
        ],
        newReportsDataAction: [
            {icon: <HelpUtil reference={'reports_aging_receivables'}/>},
            {icon: <HelpUtil reference={'reports_product_sales_by_month'}/> },
            {icon: <HelpUtil reference={'reports_cashbook_report'}/>},
            {icon: <HelpUtil reference={'reports_cashbook_report'}/>},
            {icon: <HelpUtil reference={'reports_stock_sales_by_location'}/>},
            {icon: <HelpUtil reference={'reports_bank_account_balances'}/>},
            {icon: <HelpUtil reference={'reports_active_contracts'}/>},
        ],
        location: {},
        chooseLocation: false

    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    getRepName = (data) => {
        let name = '';
        let p = data['meta_data']['report']['url'][0].split('/');
        name = p[p.length - 1].replace(/_/g, ' ');
        return name;
    };

    loadTasks = () => {
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/report_schedules",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (data) {
                let i = 0;
                let tasksIndexes = [];
                let map = data.map(d => {
                    tasksIndexes.push(i++);
                    return {name: _this.getRepName(d), next: d.next_run};
                });
                _this.setState({
                    rawData: data.map((d) => {
                        return d._id;
                    }), reportEvents: map, tasksIndexes: tasksIndexes
                });


            }.bind(this)
        });
    };

    editTask = (index) => {
        let id = this.state.rawData[index].$oid;

    };

    removeTask = (index) => {
        let _this = this;
        let id = this.state.rawData[index].$oid;

        $.ajax({
            method: "DELETE",
            url: baseUrl + "/report_schedules/" + id,
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (data) {
                let i = 0;
                let tasksIndexes = [];
                let dd = this.state.reportEvents;
                dd.splice(index, 1);
                let map = dd.map(d => {
                    tasksIndexes.push(i++);
                    return _this.getRepName(d);
                });
                _this.setState({reportEvents: map, tasksIndexes: tasksIndexes});
            }.bind(this),
            error: function (error) {
                alert(error.responseText);
            }
        });
    };

    loadTilesData = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/dashboard",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            data: {
                count: ['daily_invoice', 'due_invoice', 'customers', 'daily_revenue'],
                chart: [{'sales': ['month', 'day']}, {'prev_sales': ['month', 'day']}, {'expense': ['month', 'week']}, {'stock_sales': ['month', 'week']}],
                list: [{'high_expense': [5]}, {'revenue': [5]}]
            },
            success: function (data) {
                let temp = _this.state.tilesData;
                let chart = _this.state.chartData;

                temp.tot_customers = data.counts.customers;
                temp.tot_daily_invoice = data.counts.daily_invoice;
                temp.tot_daily_revenue = data.counts.daily_revenue;
                temp.tot_due_invoice = data.counts.due_invoice;

                chart.stockSales.data = data.charts.stock_sales;
                chart.stockSales.options.max = data.charts.stock_sales.max;
                chart.monthlyExpenses.data = data.charts.expense;
                chart.dailySales.data = data.charts.sales;
                chart.dailySales.options.max = parseInt(data.charts.sales.max);
                chart.prevDailySales.data = data.charts.prev_sales;
                chart.prevDailySales.options.max = data.charts.prev_sales.max;

                this.setState({
                    tilesData: temp,
                    chartData: chart,
                    expenseLines: data.lists.high_expense,
                    revenueLines: data.lists.revenue
                });

            }.bind(this)
        });
    };


    showReport = (name, baseUrl, parts, params) => {
        showReportUtility(name, baseUrl, parts, cookies, params);
    };


    componentDidMount = () => {
        this.loadTilesData();
        this.loadTasks();
    };

    getCurrentSales = (classes) => {
        return <Card chart>
            <CardHeader color="success">
                <ChartistGraph
                    className="ct-chart"
                    data={this.state.chartData.dailySales.data}
                    type="Line"
                    options={this.state.chartData.dailySales.options}
                    listener={this.state.chartData.dailySales.animation}
                />
            </CardHeader>
            <CardBody>
                <h4 className={classes.cardTitle}>{"Current Month Sales"}</h4>
            </CardBody>
            <CardFooter chart>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let end_date = new Date();
                        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());
                        let params = {
                            organization_id: cookies.load('orgId'),
                            org_name: cookies.load('orgName'),
                            start_date: start_date,
                            end_date: end_date
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Monthly Sales', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=daily_sales_report&dynamic=true', inject: inject},
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }]);
                    }}>{getString().details}</Button><HelpUtil reference={'reports_current_month_sales'}/>

                </div>
            </CardFooter>
        </Card>;
    };

    getPreviousSales = (classes) => {
        return <Card chart>
            <CardHeader color="rose">
                <ChartistGraph
                    className="ct-chart"
                    data={this.state.chartData.prevDailySales.data}
                    type="Line"
                    options={() => {
                        let opts = this.state.chartData.prevDailySales.options;
                        opts.high = this.state.chartData.prevDailySales.data.max;
                        return opts;
                    }}
                    listener={this.state.chartData.prevDailySales.animation}
                />
            </CardHeader>
            <CardBody>
                <h4 className={classes.cardTitle}>{"Previous Month Sales"}</h4>
            </CardBody>
            <CardFooter chart>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let cDate = new Date();
                        let end_date = new Date(cDate.getFullYear(), cDate.getMonth() - 1, cDate.getDate());
                        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 2, end_date.getDate());
                        let params = {
                            organization_id: cookies.load('orgId'),
                            org_name: cookies.load('orgName'),
                            start_date: start_date,
                            end_date: end_date
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Previous Monthly Sales', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=daily_sales_report&dynamic=true', inject: inject},
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }])
                    }}>{getString().details}</Button><HelpUtil reference={'reports_previous_month_sales'}/>

                </div>
            </CardFooter>
        </Card>;
    };

    getSchedules = (classes) => {
        return <Card>
            <CardHeader color="info">
                <h5>{getString().schedule_reports}</h5>
            </CardHeader>
            <CardBody stats>
                <Tasks disableChecked={true} checkedIndexes={[]} tasksIndexes={this.state.tasksIndexes}
                       tasks={this.state.reportEvents} editTask={this.editTask.bind(this)}
                       removeTask={this.removeTask.bind(this)}/>
            </CardBody>
        </Card>;
    };

    getNewReportList = () => {
        return <Card>
            <CardHeader color="success">
                <h5>{getString().other_reports}</h5>
            </CardHeader>
            <Table
                columns={[
                    {title: '', field: 'name',cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                    {title: '', field: 'tag', hidden: true},
                    {title: '', field: 'helplet',cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                ]}
                data={this.state.newReportsData}
                onRowClick={this.displayReport}
                options={{
                    columnsButton: true,
                    sorting: true,
                    toolbar: false,
                    paging: false,
                    cellStyle: {padding: '2px'},
                    headerStyle: {padding: '2px'}
                }}
            />
        </Card>
    };

    locationsOptions = (name) => {
        const url = baseUrl + '/afrijula/locations/location?attrs=["name"]&model=Afrijula::Locations::Location&unique=name&match=' + name;
        return fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for (; i < json.length; i++) {
                    opts[i] = {label: json[i], value: json[i]};
                }
                let val = opts[0];
                this.setState({location: val});
                return opts;
            })
            .catch(err => {

            });
    };
    handleSelectValue = (opt, attr) => {
        let val = {value: opt.value, label: opt.label};
        this.setState({[attr]: val})
    };

    getLocation = () => {
        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <span>{getString().choose_location}</span>
                    <AsyncSelect loadOptions={this.locationsOptions} value={this.state.location} defaultOptions
                                 name="location" id="location"
                                 onChange={(opt, evt) => {
                               this.handleSelectValue(opt, 'location')
                           }}/>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <Button xs={12} sm={12} md={12} color="success" onClick={this.stockSalesByLocation.bind(this)}
                            style={{
                                backgroundColor: "#4caf50",
                                margin: ".3125rem 1px",
                                minWidth: "auto",
                                minHeight: "auto",
                                marginTop: 18,
                                fontSize: "12px",
                                color: "#ffffff",
                                padding: "12px 30px",
                                borderRadius: "3px",
                                verticalAlign: "middle"
                            }}
                    >{getString().load_report}
                    </Button>
                </GridItem>
            </GridContainer>
        );
    };

    stockSalesByLocation = () => {
        let end_date = new Date();
        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());
        let params = {
            location: this.state.location.value,
            start_date: start_date,
            end_date: end_date,
            organization_id: cookies.load('orgId'),
        };
        let inject = {images: [{name: 'image', attr: 'image', id: cookies.load('orgId'), model: 'Organization'}]};
        this.showReport('Stock Sales by Location', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
            {params: params, sub: '&report=stock_sales_by_locationFinal&dynamic=true', inject: inject},
            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                type: 'date',
                default: end_date,
                label: 'To',
                name: 'end_date'
            }]);
        this.closeLocation();
    };

    setLocation = () => {
        this.setState({
            chooseLocation: true
        })
    };

    closeLocation = () => {
        this.setState({
            chooseLocation: false
        })
    };

    getAdditionalReport = (tag) => {
        let report = '';
        let reportName = '';
        switch (tag) {
            case 'aging_debtors':
                report = 'aging_debtors';
                reportName = 'Aging Debtors';
                break;
            case 'product_sales_month':
                report = 'product_sales_per_month';
                reportName = 'Monthly Product Sales';
                break;
            case 'cashbook_report':
                report = 'cashbook_report';
                reportName = 'Cashbook Report';
                break;
            case 'cash_flow':
                report = 'cash_flow_report';
                reportName = 'Cash Flow';
                break;
            case 'sales_by_location':
                this.setLocation();
                break;
            case 'account_balances':
                report = 'bank_account_balancesjrxml';
                reportName = 'Account Balances';
                break;
            case 'due_contracts':
                report = 'due_contracts';
                reportName = 'Contracts Due';
                break;
            default:
                report = '';
                reportName = '';
        }


        if (tag !== 'sales_by_location') {
            let end_date = new Date();
            let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());

            let params = {
                start_date: start_date,
                end_date: end_date,
                organization_id: cookies.load('orgId'),
                org_name: cookies.load('orgName')
            };
            let dateParams = (tag === 'due_contracts') ? null : [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                type: 'date',
                default: end_date,
                label: 'To',
                name: 'end_date'
            }];
            let inject = {images: [{name: 'image', attr: 'image', id: cookies.load('orgId'), model: 'Organization'}]};
            this.showReport(reportName, baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                {params: params, sub: '&report=' + report + '&dynamic=true', inject: inject},dateParams)
        }
    };

    displayReport = (event, data) => {
        this.getAdditionalReport(data.tag);
    };

    getExpenseReport = (classes) => {
        return <Card chart>
            <CardHeader color="warning">
                <ChartistGraph
                    className="ct-chart"
                    data={this.state.chartData.monthlyExpenses.data}
                    type="Bar"
                    options={this.state.chartData.monthlyExpenses.options}
                    responsiveOptions={this.state.chartData.monthlyExpenses.responsiveOptions}
                    listener={this.state.chartData.monthlyExpenses.animation}
                />
            </CardHeader>
            <CardBody>
                <h4 className={classes.cardTitle}>{getString().expense_report}</h4>
                <p className={classes.cardCategory}>
                    {getString().record_monthly_expense}
                </p>
            </CardBody>
            <CardFooter chart>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let day = new Date();
                        let end_date = new Date(day.getFullYear(), day.getMonth(), 1);
                        let start_date = new Date(day.getFullYear(), day.getMonth() - 1, 1);
                        let params = {
                            organization_id: cookies.load('orgId'),
                            org_name: cookies.load('orgName'),
                            start_date: start_date,
                            end_date: end_date
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Daily Expense Report', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=expense_report&dynamic=true', inject: inject},
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }])
                    }}>{getString().details}</Button><HelpUtil reference={'reports_record_monthly_expense'}/>

                </div>
            </CardFooter>
        </Card>;
    };

    getExpenseLines = (classes) => {
        return <Card style={{marginTop: 0}}>
            <CardHeader color="info" stats icon className={'smallTitle'}>
                <h4 className={classes.cardTitle}>{getString().profit_loss}</h4>
                <Table
                    data={this.state.revenueLines}
                    columns={[{field: 'category', cellStyle: {padding: '4px'}, headerStyle: {padding: '4px'}},
                        {
                            field: 'amount',
                            render: rowData => currencyFormat(rowData.amount),
                            cellStyle: {padding: '4px', minWidth: '50px'},
                            headerStyle: {padding: '4px'}
                        }]}
                    title={<h6 style={{fontSize: "11px"}}>{getString().revenue}</h6>}
                    options={{header: false, pageSize: 5, paging: false, search: false}}
                />
                <Table
                    data={this.state.expenseLines}
                    columns={[{field: 'category', cellStyle: {padding: '4px'}, headerStyle: {padding: '4px'}},
                        {
                            field: 'amount',
                            render: rowData => currencyFormat(rowData.amount),
                            cellStyle: {padding: '4px', minWidth: '50px'},
                            headerStyle: {padding: '4px'}
                        }]}
                    title={<h6 style={{fontSize: "11px"}}>{getString().high_expenses}</h6>}
                    options={{header: false, pageSize: 5, paging: false, search: false}}
                />
            </CardHeader>
            <CardFooter chart>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let end_date = new Date();
                        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());
                        let params = {
                            start_date: start_date,
                            end_date: end_date,
                            organization_id: cookies.load('orgId')
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Income Vs. Expense', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=income_vs_expense&dynamic=true', inject: inject},
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }])
                    }}>{getString().details}</Button><HelpUtil reference={'reports_profit_loss'}/>
                </div>
            </CardFooter>
        </Card>;
    };

    getStockReport = (classes) => {
        return <Card chart>
            <CardHeader color="danger">
                <ChartistGraph
                    className="ct-chart"
                    data={this.state.chartData.stockSales.data}
                    type="Line"
                    options={this.state.chartData.stockSales.options}
                    listener={this.state.chartData.stockSales.data.animation}
                />
            </CardHeader>
            <CardBody>
                <h4 className={classes.cardTitle}>{getString().best_selling} {getString().stock_report}</h4>
            </CardBody>
            <CardFooter chart>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let end_date = new Date();
                        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());
                        let params = {
                            organization_id: cookies.load('orgId'),
                            org_name: cookies.load('orgName'),
                            start_date: start_date,
                            end_date: end_date
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Stock Turnover Report', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {
                                params: params,
                                sub: '&report=stock_turnover_report&dynamic=true&injects=',
                                inject: inject
                            },
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }])
                    }}>{getString().details}</Button><HelpUtil reference={'reports_best_selling_stock'}/>
                </div>
            </CardFooter>
        </Card>;
    };

    getCustomerReport = (classes) => {
        return <Card>
            <CardHeader color="warning" stats icon>
                <CardIcon color="warning" className="small-icon">
                    <Icon>people</Icon>
                </CardIcon>
            </CardHeader>
            <CardBody>
                <p className={classes.cardCategory}>{getString().customers}</p>
                <h5 className={classes.cardTitle}>
                    {numberFormat(this.state.tilesData.tot_customers)}
                </h5>
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let params = {organization_id: cookies.load('orgId'), org_name: cookies.load('orgName')};
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Customer Analysis', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=customer_turnover_report&dynamic=true', inject: inject},
                            [])
                    }}>{getString().details}</Button>
                </div>
            </CardFooter>
        </Card>
    };

    getDailyRevenue = (classes) => {
        return <Card>
            <CardHeader color="success" stats icon>
                <CardIcon color="success" className="small-icon">
                    <Icon>money</Icon>
                </CardIcon>

            </CardHeader>
            <CardBody>
                <p className={classes.cardCategory}>{getString().daily_revenue}</p>
                <h4 className={classes.cardTitle}>{currencyFormat(this.state.tilesData.tot_daily_revenue)}</h4>
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let end_date = new Date();
                        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());
                        let params = {
                            start_date: start_date,
                            end_date: end_date,
                            organization_id: cookies.load('orgId'),
                            org_name: cookies.load('orgName')
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Cash Received', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=cash_received_report&dynamic=true', inject: inject},
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }])
                    }}>{getString().details}</Button>
                </div>
            </CardFooter>
        </Card>
    };

    getTotalInvoices = (classes) => {
        return <Card>
            <CardHeader color="danger" stats icon>
                <CardIcon color="danger" className="small-icon">
                    <Icon>check</Icon>
                </CardIcon>

            </CardHeader>
            <CardBody>
                <p className={classes.cardCategory}>{getString().daily_invoice}</p>
                <h4 className={classes.cardTitle}>{currencyFormat(this.state.tilesData.tot_daily_invoice)}</h4>
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let cDate = new Date();
                        let end_date = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate());
                        let start_date = new Date(end_date.getFullYear(), end_date.getMonth() - 1, end_date.getDate());
                        let params = {
                            organization_id: cookies.load('orgId'),
                            org_name: cookies.load('orgName'),
                            start_date: start_date,
                            end_date: end_date
                        };
                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        this.showReport('Previous Monthly Sales', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=daily_sales_report&dynamic=true', inject: inject},
                            [{type: 'date', default: start_date, label: "From", name: 'start_date'}, {
                                type: 'date',
                                default: end_date,
                                label: 'To',
                                name: 'end_date'
                            }])
                    }}>{getString().details}</Button>
                </div>
            </CardFooter>
        </Card>;
    };

    getDueInvoices = (classes) => {
        return <Card>
            <CardHeader color="info" stats icon>
                <CardIcon color="info" className="small-icon">
                    <Icon>store</Icon>
                </CardIcon>
            </CardHeader>
            <CardBody>
                <p className={classes.cardCategory}>{getString().due_invoices}</p>
                <h4 className={classes.cardTitle}>{currencyFormat(this.state.tilesData.tot_due_invoice)}</h4>
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <Button color={"primary"} size={"sm"} onClick={() => {
                        let params = {organization_id: cookies.load("orgId"), org_name: cookies.load('orgName')};

                        let inject = {
                            images: [{
                                name: 'image',
                                attr: 'image',
                                id: cookies.load('orgId'),
                                model: 'Organization'
                            }]
                        };
                        showReportUtility('Over Due Invoices', baseUrl + '/reports/1?url=/afrijula/*&profile=' + cookies.load("profileId"),
                            {params: params, sub: '&report=due_invoices_report&dynamic=true', inject: inject}, cookies,
                            []);
                    }}>{getString().details}</Button>
                </div>
            </CardFooter>
        </Card>;
    };

    render() {

        const {classes} = this.props;

        if (cookies.load('user_type') === 'Cashier') {
            return (<div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        {this.getDailyRevenue(classes)}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        {this.getDueInvoices(classes)}
                    </GridItem>
                </GridContainer>
            </div>);
        } else if (cookies.load('user_type') === 'StoreManager') {
            return (<GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    {this.getStockReport(classes)}
                </GridItem>
            </GridContainer>);

        } else {
            return (
                <div>
                    <GridContainer>
                        <GridItem sm={12} md={4}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {this.getCurrentSales(classes)}
                                </GridItem>
                                <GridItem xs={12}>
                                    {this.getPreviousSales(classes)}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem sm={12} md={4}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {this.getExpenseLines(classes)}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem sm={12} md={4}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {this.getSchedules(classes)}
                                    {this.getNewReportList()}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            {this.getExpenseReport(classes)}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            {this.getStockReport(classes)}
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={6} md={3}>
                            {this.getCustomerReport(classes)}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                            {this.getDailyRevenue(classes)}
                        </GridItem>


                        <GridItem xs={12} sm={6} md={3}>
                            {this.getTotalInvoices(classes)}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                            {this.getDueInvoices(classes)}
                        </GridItem>
                    </GridContainer>
                    <Rodal visible={this.state.chooseLocation} height={120} width={550} showMask={false}
                           onClose={this.closeLocation.bind(this)}>
                        {(this.state.chooseLocation) ? this.getLocation() : null}
                    </Rodal>
                </div>
            );
        }
    }


}

Reports.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Reports);
