import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'

export default class Wizard_Complete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: false,
            onClose: this.props.onClose
        };
    }
redirect= () => {
    this.props.onClose()
}
    componentDidMount() {
     setTimeout(this.redirect,5000);

    }

    componentWillUnmount() {}

    // not required as this component has no forms or user entry
    // isValidated() {}

    render() {

        // if(this.state.complete){
        //     this.props.onClose();
        //    // return(<Redirect to='/login'/>)
        // }
        return (

            <div>

                <h3>Thank you For Completing the Setup Wizard</h3>

            </div>
        )
    }
}