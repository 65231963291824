import  React from 'react';
import {getLocale} from  "assets/js/utils/utils.js";
import cookies from "react-cookies";
import {withStyles} from "@material-ui/core";
import baseUrl from "assets/js/config/const.js";
import { Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import $ from "jquery";
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class HelpUtil extends React.Component{
  

    state={
        view: false,
        tooltip: '',
        message: '',
        audio: ''
    };

    getLocale = () => {
        return cookies.load('locale') || 'en-GB';
    }


    componentDidMount (){
        if(this.props.reference){
            let _this = this;

            $.ajax({
                method: "GET",
                url: baseUrl + "/guide/1",
                data:{lang:this.getLocale(), app: 'afrijula',help: this.props.reference},
                dataType: "json",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                success: function (result) {
                    _this.setState({message: result.message, tooltip: result.tooltip, audio: result.audio});
                }
            });
        }
    }

    listen = (lang)=>{
        let audio = new Audio('https://assets.lecket.gm/helplets/audio/'+this.props.reference+'_'+lang+'.mp3');
        audio.play();
    }

    render() {
        const { classes } = this.props;

        return (
            <span>
                <Tooltip title={this.state.tooltip}>
                    <IconButton aria-label="help" label={this.state.tooltip} onClick={()=>{this.setState({view:true});}}>
                        <Icon style={{color: 'green'}}>help</Icon>
                    </IconButton>
                </Tooltip>
                {(this.state.view) ?
                    <Dialog
                        open={this.state.view}
                        onClose={()=>{this.setState({view:false});}}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    ><DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{this.state.message}</div>
                            <Tooltip title={'listen in mandinka'}>
                                <IconButton onClick={() => {this.listen('mandinka')}}>
                                    <Icon style={{color:'red'}}>hearing</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'listen in wolof'}>
                                <IconButton onClick={() => {this.listen('wolof')}}>
                                    <Icon style={{color:'blue'}}>hearing</Icon>
                                </IconButton>
                            </Tooltip>
                        </DialogContentText>
                    </DialogContent> </Dialog>: null}
            </span>
        )
    }
}

export default withStyles(styles)(HelpUtil);
