import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";

import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import {Country, currencies, displayError, displaySuccess, getString, getToday} from "assets/js/utils/utils.js";
import Select from "react-select";
import SaveButton from "../CustomComponents/SaveButton";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class BankAccount extends React.Component {

    state = {
        back: false,
        accountData: null,

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,

        editAccount: this.props.editAccount,
        countryName: null,
    };

    getDefaultAccount = () => {
        this.state.accountData = (this.props.accountData) ? {
            name: this.props.accountData.name,
            accNumber: this.props.accountData.accNumber,
            bank: this.props.accountData.bank,
            balance: this.props.accountData.balance,
            local_balance: this.props.accountData.local_balance,
            date: this.props.accountData.date,
            _id: this.props.accountData._id
        } : {
            name: '',
            accNumber: '',
            bank: '',
            currency: '',
            balance: 0,
            local_balance: 0,
            date: ''
        };
    };

    handleChange = (e) => {
        let account = this.state.accountData;
        account[e.target.name] = e.target.value;
        if (e.target.name === "balance") {
            account.local_balance = e.target.value;
        }
        this.setState({
            accountData: account
        });
    };

    newAccount = () => {
        let accountData = this.state.accountData;
        let account_name = accountData.name;
        let account_number = accountData.accNumber;
        let bank = accountData.bank;
        let currency = accountData.currency;
        let balance = parseFloat(accountData.balance);
        let local_balance = parseFloat(accountData.local_balance);
        let date = accountData.date;

        let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;

        if (account_name === "" || !reg.test(account_name)) {
            displayError(getString().err_acc_name);
            document.getElementById("name").focus();
            return;
        }
        if (account_number === "") {
            displayError(getString().err_acc_number);
            document.getElementById("accNumber").focus();
            return;
        }
        if (bank === "" || !reg.test(bank)) {
            displayError(getString().err_bank_name);
            document.getElementById("bank").focus();
            return;
        }

        let _this = this;
        return $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/cashbook/bank_account",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                "account_name": account_name, "account_number": account_number, "bank": bank, "currency": currency,
                "balance": balance, "local_balance": local_balance, "opening_balance_date": date
            },
            success: function (result) {
                if (this.props.updateSelectField) {
                    this.props.updateSelectField(result.account);
                    this.props.closeExpenseRodal();
                }
                if (this.props.updateList) {
                    this.props.updateList();
                    this.props.closeCashbookRodal();
                }
                displaySuccess(getString().err_acc_success);
                _this.closeAddingAccount();
            }.bind(this),
            error: function (response) {
                {
                    displayError(response.responseText);
                }
            }
        });

    };

    closeAddingAccount = () => {
        if (this.props.closeEdit) {
            this.props.closeEdit();
        }
        if (this.props.closeExpenseRodal) {
            this.props.closeExpenseRodal();
        }
        if (this.props.closeCashbookRodal) {
            this.props.closeCashbookRodal();
        } else {
            this.setState({back: true});
        }
    };

    handleCurrency = value => {
        let account = this.state.accountData;
        let curr = Country.search(value['value']);
        if (curr && curr[0]) {
            account.currency = curr[0].currency.currencyCode;
            let _name = curr[0].name + '(' + account.currency + ')';
            this.setState({
                accountData: account,
                countryName: _name,
            });
        }
    };

    getCurrency = () => {
        let defaultCurrency = cookies.load('defaultCurrency');
        let account = this.state.accountData;

        account.currency = defaultCurrency;
        let curr = Country.search(defaultCurrency);
        let _countryName = curr[0].name + ' (' + defaultCurrency + ')';
        this.setState({
            accountData: account,
            countryName: _countryName
        });
    };

    componentDidMount() {
        let account = this.state.accountData;
        account.date = getToday();
        if (this.props.bankName) {
            account.bank = this.props.bankName
        }
        this.setState({
            accountData: account,
        });
    }

    componentWillMount() {
        this.getDefaultAccount();
        this.getCurrency();
    }

    render() {
        const {classes} = this.props;

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.addBankAccount = false;
            return <ChildComponent state={caller_state} returningProps={this.state.callerProps}
                                   newCustomerData={this.state.accountData}/>
        }

        return (
            <div xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem>
                        <Card>
                            <CardHeader color="primary">
                                <h4>{getString().add_bank_acc}</h4>
                            </CardHeader>
                            <CardBody>
                                <form className={classes.container} autoComplete="off">
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label={getString().acc_name}
                                                className={classes.textField}
                                                value={this.state.accountData.name}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="accNumber"
                                                name="accNumber"
                                                label={getString().acc_number}
                                                className={classes.textField}
                                                value={this.state.accountData.accNumber}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                id="bank"
                                                name="bank"
                                                label={getString().bank}
                                                className={classes.textField}
                                                value={this.state.accountData.bank}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <span>{getString().currency}</span>
                                            <Select options={currencies} placeholder={this.state.countryName}
                                                    name="currency" id="currency"
                                                    value={this.state.accountData.currency}
                                                    onChange={this.handleCurrency}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                id="balance"
                                                name="balance"
                                                label={getString().balance}
                                                className={classes.textField}
                                                value={this.state.accountData.balance}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4}>
                                            <TextField
                                                id="local_balance"
                                                name="local_balance"
                                                label={getString().local_balance}
                                                className={classes.textField}
                                                value={this.state.accountData.local_balance}
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{
                                                    readonly: true
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={4} style={{marginTop: "-15px"}}>
                                            <TextField
                                                required
                                                id="date"
                                                name="date"
                                                inputProps={{style: {padding: "2px", height: "3em"}}}
                                                value={this.state.accountData.date}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                margin="normal"
                                                type="date"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </form>

                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button xs={12} sm={6} md={2} color="danger" onClick={this.closeAddingAccount}
                                            style={{
                                                backgroundColor: "#f44336",
                                                margin: ".3125rem 1px",
                                                minWidth: "auto",
                                                minHeight: "auto",
                                                fontSize: "12px",
                                                color: "#ffffff",
                                                padding: "12px 30px",
                                                borderRadius: "3px",
                                                verticalAlign: "middle"
                                            }}>{getString().cancel}</Button>
                                    <SaveButton method={this.newAccount} title={getString().save} />
                                </GridItem>
                            </CardFooter>

                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

BankAccount.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BankAccount);