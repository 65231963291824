import React, { Component } from 'react'
import cookies from "react-cookies";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import {currencyFormat,validateEmail,showReportUtility,getToday,getString,displaySuccess,displayError,} from "assets/js/utils/utils.js";
import NewCustomerProductDetails from 'components/afrijula/Contracts/NewCustomerProductDetails';
import EnterProducts from "components/afrijula/Contracts/EnterProducts"
import ContractQustonnaire from 'components/afrijula/Contracts/ContractQustonnaire.jsx';



class NewContract extends Component {
    constructor(props) {
        super(props);

   this.state= {
   //NewCustomerProductDetails
    backToContractsUI:false,
    contractName: this.props.callerName,
    value: 'fixed',
    selectProductsUI: false,
    QustonnaireUI: false,
    NewCustomerProductsDetailsUI:(this.props.setCustomerDetails !== null)?this.props.setCustomerDetails:false,
    customerId:'',
    customerName:'',
    customerEmail:'',
    customerPhone:'',
    customerAddress:'',
    taxPercentage: cookies.load('tax_percentage'),
    tax:0.0,
    date: '',
   //Enter Products
   saleItems:[],
   cashAmount: 0,
   discountAmount: 0.0,
   discountSymbol: 'GMD',
   discount: 0,
   discountType: 'fixed',

   total: 0.00,
   taxDue: 0.00,
   subTotal: 0.00,
   change: 0.00,

   taxType: 'percentage', //can also be "fixed amount"
   //ContractQustionnaire
   inVoice: false,
   initial_deposit: 0,
   number_installments:0,
   instBalance:null,
   inst_type:'fixed',
   pay_type:'fixed',
   inst_period: "month",
   installment_date: Date.now(),
   installments:[],
   toPrintData: [],
   }
};
componentDidMount() {
    this.setState({
        date: getToday()
    });
}
back = () => {
    this.setState({
        backToContractsUI:true
    });
};
handleNext = (nextStep,saleItems,change) => {
    if(nextStep == 'selectProductUI'){
        this.setState({ selectProductsUI:true });
    }else if(nextStep == 'QustonnaireUI'){
        this.setState({ QustonnaireUI:true,saleItems,change});
    }
}
handleBack = (prevStep) => {
  if(prevStep == 'backToContractsUI'){
      this.setState({
          backToContractsUI:true,
          QustonnaireUI: false,
      });
  }else if(prevStep == 'NewCustomerProductsDetailsUI'){
        this.setState({
            NewCustomerProductsDetailsUI:true,
            selectProductsUI:false
        });
  }else if(prevStep = 'EnterProducts'){
    this.setState({
        selectProductsUI:true,
        NewCustomerProductsDetailsUI:false,
        QustonnaireUI: false,
    });
  }
}
updateSalesVals=(data,tax,total,subTotal,dis)=>{
     this.setState({saleItems: data, taxDue: tax,
                     total:total,subTotal:subTotal,discountAmount:dis});
}
handleCustomer = (opt) =>{
    if(opt !== null){
        if(opt.__isNew__){
            this.setState({
                customerId: opt.value,
                customerName: opt.label,
                customerAddress: '',
                customerEmail: '',
                customerPhone: ''
            })
        }else{
            this.setState({
                customerId: opt.value,
                customerName: opt.label,
                customerAddress: opt.address,
                customerEmail: opt.email,
                customerPhone: opt.phone_number
            })
        }
    }else{
        this.setState({
            customerId: '',
            customerName: '',
            customerAddress: '',
            customerEmail: '',
            customerPhone: '',
        })
    }
};
handleDiscountChange =(event) =>{
    this.setState({discountType: event.target.value});
   if(event.target.value === "fixed")
       this.setState({discountSymbol: "GMD", discountType: 'fixed'});
   else
       this.setState({discountSymbol: "%", discountType: 'percentage'})
};
handleChange = (e) => {
    let tax = 0;
    let discount = 0;
    let change=false;
    if(e.target.name === "customerAddress"){
        this.setState({
            customerAddress: e.target.value
        });
    }else if(e.target.name === "customerPhone"){
        this.setState({
            customerPhone: e.target.value
        });
    }else if(e.target.name === "customerEmail"){
        this.setState({
            customerEmail: e.target.value
        });
    }else if(e.target.name === "discount"){
        discount = Number(e.target.value);
        if(this.state.discountSymbol === '%' && discount === 100){
            alert(`${getString().discount_100}`);
            return ;
        }
        this.setState({
            discount: e.target.value
        });
        change = true;
    }else if(e.target.name === "taxPercentage"){
        discount = this.state.discount;
        let t = Number(e.target.value);
        if(t > 0 ) {
            tax = (t / 100) * (this.state.subTotal);
        }
        this.setState({
            taxPercentage: e.target.value
        });
        change = true;
    }else if (this.state.discountType === "percentage") {
             change=true;
    }else {
         this.setState({
                [e.target.name]: e.target.value
            });
    }
    if(change)
        this.updateSales(this.state.saleItems,discount);
};
    updateSales =(data,discount) => {
    let subTotal = 0;
     for(let i = 0; i < data.length; i++){
         //if(!data[i].billable === true) {
             subTotal += data[i].total;
         //}
     }
     let  tax = ((parseFloat(this.state.taxPercentage) / 100) * (subTotal));
     let total =(Number(subTotal)  + Number(tax));
     let dis = this.state.discountType === 'fixed' ? discount : ((parseFloat(discount)/100 )* total);
     total = total - dis;
     this.setState({saleItems: data, taxDue: tax,
                     total:total,subTotal:subTotal,discountAmount:dis});
 };

 createContract = (charge,bill,term,length,flushValue,autorenew) => {
    this.saveSale('cash',null,this.paymentPlan(),"contract",
    () => {return {cycle: charge, period: bill, term: term,
      duration: length, flush: flushValue,auto_renew:(autorenew == 'Yes') ? true : false,
      discount_type: this.state.discountType, discount_amount: this.state.discountAmount}});
 }
 paymentPlan = () =>{
    return {initial_deposit: this.state.initial_deposit,
            installment_type:this.state.inst_type,
            payment_type:this.state.pay_type,
            installments:(this.state.inst_type === 'fixed') ? [{count:this.state.number_installments,
                            period: this.state.inst_period, date: this.state.installment_date}] : this.state.installments};
  };

 viewInvoice = (invoiceId) => {
    let inject = {images: [{name: 'image', attr:'image', id: cookies.load('orgId'), model: 'Organization'}],
        values: [{name:'org_address', attr:'address', id: cookies.load('orgId'), model:'Organization'},
            {name:'org_contact', attr:'contact_email', id: cookies.load('orgId'), model:'Organization'},
            {name:'additional_invoice_info', attr:'additional_invoice_info', id: cookies.load('orgId'), model:'Organization'}]};
    let params = {organization_id: cookies.load('orgId'), org_name: cookies.load('orgName'), date: new Date(), invoice_id: invoiceId,
        org_address: cookies.load('orgAddress'), org_contact: cookies.load('orgContact')};
    showReportUtility('Invoice', baseUrl + '/reports/1?url=/afrijula/*&profile='+cookies.load("profileId"),
        {params: params, sub: '&report=Invoice&dynamic=true', inject: inject}, cookies,
        []);

  };

 saveSale = (payment_type,paid_amount,paymentPlan,type,contract,bank,bank_no,paid_by) =>{

    let _this = this;
    let customer_name = this.state.customerName;
    //let _tax = this.state.tax;
    let _tax = this.state.taxDue;
    let sub_total = this.state.subTotal;
    //let sub_total = 0;
    let discount_type = this.state.discountType;
    let _discount = discount_type === "fixed" ? this.state.discount : currencyFormat((this.state.discount/100)*sub_total);

    let pricing = 'whole sale';
    let tran_type = type;
    let items = [];
    let customer_address = this.state.customerAddress;
    let customer_email = this.state.customerEmail;
    let customer_phone_number = this.state.customerPhone;
    // let _total = total;
    let _total = 0;
    let sale_date = this.state.date;

    let description = [];
    for(let i = 0; i < this.state.saleItems.length; i++){
        items.push({name: this.state.saleItems[i].item,
                    quantity: this.state.saleItems[i].quantity,
                    number: this.state.saleItems[i].number,
                    category: this.state.saleItems[i].category,
                    billable: this.state.saleItems[i].billable,
                    unit_price: this.state.saleItems[i].unit_price,
                    type: this.state.saleItems[i].type,
                    total: this.state.saleItems[i].total});
        description.push(this.state.saleItems[i].item)
    }
    description = description.join(',');

    let data = this.state.toPrintData.slice();
    let _amount = this.state.cashAmount;
    let charges = {payment_method: payment_type,"tax":_tax,"discount":_discount,"total": _total,"subtotal":sub_total,"name":customer_name,
        "customer_email": customer_email,customer_phone:customer_phone_number,amount:_amount};
    data = items;
       $.ajax({
            method:"POST",
            url: baseUrl + "/afrijula/sales/sales",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"description": description,"payment_type":payment_type, "sub_total": sub_total,"total_amount": _total,"customer_email": customer_email,
                "customer_address":customer_address,"phone_number":customer_phone_number,"customer_name": customer_name,"tax": _tax, tax_percentage: this.state.taxPercentage,
                "discount":_discount,"pricing":pricing ,tran_type: tran_type,"sale_date": sale_date, contract: (contract) ? contract() : null,
                "sales_items":items,payment_plan: paymentPlan, "payment_amount":paid_amount,"discount_type": discount_type,"bank": bank,"bank_number":bank_no, "paid_by": paid_by},

            success: function (result) {

                let items = _this.state.saleItems;
                items.splice(0,items.length);

                if(type=='contract')
                    _this.viewInvoice(result.invoice_id);

                _this.handleBack('backToContractsUI');
                displaySuccess(getString().sale_success);
            },  error: function(resp){
               displayError(resp.responseText);
           }
        })
  };

    render() {

        if(this.state.backToContractsUI){
            let ContractsUIComponent = this.props.callerName;
            return <ContractsUIComponent state={this.props.callerState} returningProps={this.props.callerProps}/>
        }else if(this.state.QustonnaireUI){
            return (<ContractQustonnaire  callerProps ={this.props} callerState = {this.state} handleNext={this.handleNext.bind(this)} handleBack={this.handleBack.bind(this)}
            createContract={this.createContract.bind(this)}/>);
        }
        else if(this.state.selectProductsUI){
            return (<EnterProducts callerState = {this.state} updateSalesVals={this.updateSalesVals.bind(this)} handleNext={this.handleNext.bind(this)}
            handleBack={this.handleBack.bind(this)} handleChange={this.handleChange.bind(this)} updateSales={this.updateSales.bind(this)} handleDiscountChange={this.handleDiscountChange.bind(this)}/>);
        }else if(this.state.NewCustomerProductsDetailsUI){
            return (
                    <NewCustomerProductDetails callerState={this.state} handleChange={this.handleChange.bind(this)} handleCustomer={this.handleCustomer} handleNext={this.handleNext.bind(this)} handleBack={this.handleBack.bind(this)} />
            )
        }
    }
}

export default NewContract
