    import React from 'react';
    import PropTypes from 'prop-types';
    import withStyles from '@material-ui/core/styles/withStyles';
    import frStrings from  "assets/js/locales_fr.js";
    import {getString} from  "assets/js/utils/utils.js";
    import $ from "jquery";
    import baseUrl from "assets/js/config/const.js";


    import GridItem from "components/common/Grid/GridItem.jsx";
    import GridContainer from "components/common/Grid/GridContainer.jsx";
    import Card from "components/common/Card/Card.jsx";
    import CardHeader from "components/common/Card/CardHeader.jsx";
    import CardBody from "components/common/Card/CardBody.jsx";
    import TextField from "@material-ui/core/TextField";
    import Select from "react-select";
    import countryList from "react-select-country-list";


    const Country = require('country-js');
    const styles = theme => ({

        main: {
            width: 'auto',
            display: 'block', // Fix IE 11 issue.
            marginLeft: theme.spacing.unit * 3,
            marginRight: theme.spacing.unit * 3,
            [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        progress: {

            margin: theme.spacing.unit*2,
            width: '100%'
        },
        paper: {
            marginTop: theme.spacing.unit * 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        },

    });
    const countries = countryList().getData();
    const currencies = countryList().getData();


    class Activation extends React.Component{

        constructor(props){
            super(props);
            this.isValidated = this.isValidated.bind(this);
        }

      

        state = {

            setCurrent: false,
            packages:[
                { label: "Basic", value: "basic"},
                { label: "Standard", value: "standard" },
                { label: "Premium", value: "premium" },
                { label: "Premium Plus", value: "premium_plus" },],
            defaults:[
                {
                    value: 'yes',
                    label: getString().yes,
                },
                {
                    value: 'no',
                    label: getString().no,
                },
            ],

            organization:  this.props.wizardData,
        };

        handleChange = (e) => {
            let _organization = this.state.organization;
            _organization[e.target.name] = e.target.value;
            this.props.updateState({
                organization: _organization
            });
        };

        updateCountry = (name, val) => {
            let _organization = this.state.organization;
            _organization.country = val;
            this.props.updateState({organization: _organization});
        };


        isValidated =() =>  {
            return false; // make it return false. just in case. lol
        };

        componentDidMount () {
             //this.sendActivation();
        }

        render(){
            const { classes } = this.props;
                return (<div >
                    <GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader >
                                    <h4 className={classes.cardTitleWhite}> {getString().complete}</h4>
                                </CardHeader>
                                <CardBody>

                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4} style = {{marginTop: 13+'px'}}>
                                                <span>{getString().packages}</span>

                                                <Select style={{marginTop: 50}} options={this.state.packages} defaultValue={this.state.packages[0]} onChange = {opt => {this.setState({package: opt.value})}}  />

                                            </GridItem>

                                        <GridItem xs={12} sm={12} md={4} style = {{marginTop: 13+'px'}}>
                                            <span>{getString().country}</span>
                                            <Select options={countries} placeholder = {this.state.organization.country} name="country" id="country"
                                                    defaultValue ={this.state.organization.country}  onChange={opt => {this.updateCountry('country',opt.label)}} />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                id="phone"
                                                label={getString().telephone}
                                                name="phone"
                                                className={classes.textField}
                                                value={this.state.organization.phone}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>

                                    </GridContainer>


            </CardBody>
            </Card>
                        </GridItem>
                    </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader >
                                        <h4 className={classes.cardTitleWhite}>{getString().settings}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>

                                            <GridItem xs={12} sm={4} md={4} style={{marginTop: -14+'px'}}>
                                                <TextField
                                                    autoFocus
                                                    id="stock_level"
                                                    label={getString().stock_level}
                                                    name="stock_level"
                                                    className={classes.textField}
                                                    value={this.state.organization.stock_level}
                                                    onChange={e => {this.handleChange(e)}}
                                                    type="number"
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} sm={4} md={4}>
                                                <span>{getString().printer}</span>
                                                <Select options={this.state.printerType} placeholder={this.state.organization.printerValue} name="printer" id="printer"
                                                        defaultValue={this.state.organization.printerValue} onChange={opt => {this.onChangePrinter(opt)}}  />
                                            </GridItem>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <span>{getString().vat}</span>
                                                <TextField
                                                    name="tax_percentage"
                                                    id="tax_percentage"
                                                    className={classes.textField}
                                                    value={this.state.tax_percentage}
                                                    onChange={e => {this.handleChange(e)}}
                                                    type="number"
                                                    margin="normal"
                                                    style={{marginTop: 1+"px"}}
                                                    variant="outlined"/>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>

                                </Card>
                            </GridItem>
                        </GridContainer>
                    </GridContainer>
                </div>)

            }



    }

    Activation.propTypes = {
        classes: PropTypes.object.isRequired,
        errors: PropTypes.object,
        validate: PropTypes.func,
        isValid: PropTypes.func,
        handleValidation: PropTypes.func,
        getValidationMessages: PropTypes.func,
        clearValidations: PropTypes.func,
        getStore: PropTypes.func,
        updateStore: PropTypes.func
    };

    export default withStyles(styles)(Activation);