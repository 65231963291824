import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import FourOOne from "../../common/Templates/DisabledUI/401";
import $ from "jquery";
import {
  numberFormat,
  currencyFormat,
  getString,
  compileQuery,
  showReportUtility,
  canSeeDash,
  dateFormat,
} from "assets/js/utils/utils.js";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import AddDetails from "components/afrijula/Ecom/AddDetails.jsx";
import Settlements from "components/afrijula/Ecom/Settlements.jsx";
import { displaySuccess, displayError } from "../../../assets/js/utils/utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { dailySalesChart } from "variables/charts.jsx";
import ChartistGraph from "react-chartist";
import Link from "@material-ui/core/Link/Link";
import ShopTerms from "components/afrijula/Privacy_Policy_&_Terms/ShopTerms";
import MapContainer from "components/afrijula/Utils/MapContainer";
import Image from "components/afrijula/Utils/ImageUtil.jsx";
import HelpUtil from "../Utils/HelpUtil";
import ChangeLocale from "components/common/Locale/LocaleSelector.jsx";
import WindowSizeListener from "react-window-size-listener";
import axios from "axios";

const styles = (theme) => ({
  root: {
    hook: theme.breakpoints.down("sm"),
  },
});

class Shopping extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.tableSyncRef = React.createRef();
  }

  state = this.props.state || {
    showErrors: false,
    lastPage: 1,
    pageSize: 20,
    inProgress: [],
    inPending: [],
    confirm: false,
    time: 10,
    times: {
      10: "5 to 10 minutes",
      20: "10 to 20 minutes",
      30: "20 to 30 minutes",
      45: "30 to 45 minutes",
      60: "1 hour",
      120: "2 hours",
      1440: "1 day",
      4320: "2 to 3 days",
      7200: "1 week",
    },
    askConfirm: false,
    orderId: "",
    allOrders: [],
    loadedItems: "",
    showSettlements: false,
    canSync: false,
    shopActive: cookies.load("accept_shop_terms") === "true" ? true : false,
    acceptTerms: false,
    showTerms: false,
    updateLocation: false,
    tilesData: {
      online_sales: "",
      unsettled_amount: "",
      performance_data: JSON.parse(JSON.stringify(dailySalesChart)),
    },
    gps: { long: "", lat: "" },
    winSize: window.innerWidth,
    selectLocale: false,
    isUserInGambia: true,
  };

  onChangeLocale = (opt) => {
    this.setState({ selectLocale: true });
  };

  closeLocale = () => {
    window.location.reload();
  };

  loadData = (query, resolve, reject) => {
    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;
    if (query.search === undefined) query.search = "";

    fetch(
      baseUrl +
        "/afrijula/shop/sync?page=" +
        query.page +
        "&size=" +
        query.pageSize +
        "&unsynced=" +
        query.unsynced +
        "&search=" +
        query.search,
      {
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  loadTilesData = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: {
        count: ["online_sales", "unsettled_amount"],
        chart: [{ onlines_sales_performance: ["month", "week"] }],
      },
      success: function(result) {
        let temp = _this.state.tilesData;
        temp.performance_data.data = result.charts.onlines_sales_performance;
        temp.online_sales = result.counts.online_sales;
        temp.unsettled_amount = result.counts.unsettled_amount;
        _this.setState({ tilesData: temp });
      }.bind(this),
    });
  };

  completeSync = (accept, item, callback) => {
    let _this = this;
    $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/shop/sync/" + (item || 1),
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { enable: true, accept: accept, sync: item !== undefined },
      success: function(result) {
        cookies.save("onlinehopping", "enabled", { path: "/" });
        if (callback) callback();
        displaySuccess(result.success);
      }.bind(this),
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  retryAction = (orderId) => {
    let _this = this;
    $.ajax({
      method: "put",
      url: baseUrl + "/afrijula/shop/order_sync/" + orderId,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { time: this.state.time, comments: "", status: "retry" },
      success: function(result) {
        displaySuccess(result.success);
        _this.loadOrders();
      }.bind(this),
      error: function(error) {
        _this.loadOrders();
        displayError(error.responseText);
      },
    });
  };

  rejectOrder = (orderId) => {
    if (window.confirm("Are you sure?")) {
      let _this = this;
      $.ajax({
        method: "DELETE",
        url: baseUrl + "/afrijula/shop/order_sync/" + orderId,
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        dataType: "json",
        data: { enable: true },
        success: function(result) {
          displaySuccess(result.success);
          _this.loadOrders();
        }.bind(this),
        error: function(error) {
          _this.loadOrders();
          displayError(error.responseText);
        },
      });
    }
  };

  handleClose = (time) => {
    this.setState({ time: time, askConfirm: true, confirm: false });
  };

  confirmConfirm = () => {
    let _this = this;

    return $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/shop/order_sync/" + this.state.orderId,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { time: this.state.time, comments: "", status: "process" },
      success: function(result) {
        displaySuccess(result.success);
        _this.setState({ askConfirm: false, orderId: "" });
        _this.loadOrders();
      }.bind(this),
      error: function(error) {
        _this.loadOrders();
        _this.setState({ askConfirm: false, orderId: "" });
        displayError(error.responseText);
      },
    });
  };

  cancelConfirm = () => {
    this.setState({ askConfirm: false, confirm: true });
  };

  getTime = () => {
    return this.state.times[this.state.time];
  };

  loadOrders = (status, filters) => {
    let _this = this;
    if (status === undefined) status = "pending,processing,hanging";
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/shop/order_sync/?status=" + status,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { enable: true, filters: filters },
      success: function(result) {
        let progress = _this.state.inProgress;
        let pending = _this.state.inPending;
        let everything = this.state.allOrders;
        while (progress.length > 0) {
          progress.pop();
        }
        while (pending.length > 0) {
          pending.pop();
        }
        while (everything.length > 0) {
          everything.pop();
        }
        result.forEach((item) => {
          if (
            item.merchant_status === "pending" ||
            item.merchant_status === "hanging"
          )
            pending.push(item);
          else if (item.merchant_status === "processing") progress.push(item);
          else everything.push(item);
        });
        _this.setState({
          inProgress: progress,
          inPending: pending,
          allOrders: everything,
        });
      }.bind(this),
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  loadProduct = (id, type) => {
    var _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/products/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        const props = _this.getProps();
        if (props.updateMe) props.updateMe(false);
        result.type = type;
        _this.setState({ shoppableData: result });
      },
    });
  };

  acceptOrder = (orderId) => {
    this.setState({ confirm: true, orderId: orderId });
  };

  loadStock = (id, type) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/stocks/stock/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        const props = _this.getProps();
        if (props.updateMe) props.updateMe(false);
        result.type = type;
        _this.setState({ shoppableData: result });
      },
    });
  };

  rowSelect = (event, colData) => {
    const id = colData.id.$oid;

    if (colData._type === "Afrijula::Sales::Product") {
      this.loadProduct(id, colData._type);
    } else {
      this.loadStock(id, colData._type);
    }
  };

  initSync = () => {
    this.setState({ loadSync: true });
  };
  syncStore = () => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/shop/sync/1",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { ready: true },
      success: function(result) {
        if (result.available > 0) {
          _this.completeSync();
          displaySuccess(result.available + " items are being synced to shop");
        }
        if (result.status === "ready") {
          _this.setState({ showErrors: false });
        } else {
          _this.setState({ showErrors: true });
        }
      }.bind(this),
    });
  };

  determineCanSync = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/shop/sync/1",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { dirty: true },
      success: function(result) {
        if (result.status === "dirty") {
          _this.setState({ canSync: true });
        } else {
          _this.setState({ canSync: false });
        }
      }.bind(this),
    });
  };

  componentDidMount() {
    // check location of user
    this.checkLocationBasedOnIp().then((response) => {
      if (!response) {
        displayError(
          "You are not in the gambia. You cannot access this feature."
        );
        this.setState({ isUserInGambia: false });
      }
    });

    if (this.state.shopActive === true) {
      this.loadOrders();
      this.loadTilesData();
      this.determineCanSync();
      let cgps = cookies.load("gps");
      let gps = this.state.gps;
      gps.lat = cgps.latitude;
      gps.long = cgps.longitude;
      this.setState({ gps: gps });
    } else {
      let _this = this;
      navigator.geolocation.getCurrentPosition(function(position) {
        let gps = _this.state.gps;
        gps.lat = position.coords.latitude;
        gps.long = position.coords.longitude;
        _this.setState({ gps: gps });
      });
    }
  }

  orderReady = (orderId) => {
    let _this = this;

    return $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/shop/order_sync/" + orderId,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { status: "ready" },
      success: function(result) {
        displaySuccess(result.success);
        _this.loadOrders();
        _this.setState({ invoice: result.invoice });
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  showTerms = () => {
    this.setState({ showTerms: true });
  };

  acceptTerms = (e) => {
    let terms = true;

    this.setState({ acceptTerms: terms });
    if (this.state.gps.long === "" && this.state.gps.lat === "") {
      alert(
        "We must set the GPS cordinates for the shopping to work. We need this data to allow us to collect orders from your and deliver"
      );
      return;
    }
    if (terms === true) {
      if (
        window.confirm(
          "Are you sure you have the right GPS. Please confirm on map and use mobile phone for better accuracy"
        )
      ) {
        this.updateLocation(this.state.gps.lat, this.state.gps.long, true);
      }
    }
  };

  updateNewLocation = (lat, long) => {
    let gps = this.state.gps;
    gps.lat = lat;
    gps.long = long;
    this.setState({ gps: gps });
    this.updateLocation(lat, long, false);
    this.setState({ updateLocation: false });
  };

  checkLocationBasedOnIp = async () => {
    try {
      const response = await axios.get(
        "http://api.ipstack.com/check?access_key=03850d3f4a533b4636f2ae5bb4e6bb14"
      );
      if (response.data) {
        return response.data.country_name === "Gambia" ? true : false;
      } else {
        return true; // explanation below
      }
    } catch (err) {
      /* returning true because the current response count is set to 5000 because it is free. So if we run the check and we have exceeded the maximun number of request, we won't know which country the user is requesting from
      */
      return true;
    }
  };

  updateLocation = (lat, long, activate) => {
    var _this = this;
    $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/afrijula/1",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { gps: true, long: long, lat: lat },
      success: function(result) {
        if (activate) {
          cookies.save("accept_shop_terms", "true", { path: "/" });
          _this.setState({ shopActive: true });
          _this.completeSync(true);
        }
        displaySuccess(result);
        cookies.save("gps", { latitude: lat, longitude: long }, { path: "/" });
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  render() {
    const { classes } = this.getProps();

    // check if the current accessor is in The Gambia
    if (!this.state.isUserInGambia) {
      return <FourOOne text="E-Shop is unavailable in your current location" />;
    } else {
      if (this.state.shopActive === false) {
        return (
          <div>
            <div>
              <Checkbox
                checked={this.state.acceptTerms}
                color="primary"
                onChange={this.acceptTerms.bind(this)}
                value={this.state.acceptTerms}
                style={{ display: "inline-block" }}
              />
              <p style={{ display: "inline" }}>
                I agree to the InSIST Global Ltd{" "}
                <Link onClick={this.showTerms}>Terms and conditions</Link> for
                using Afrijula Shop.
              </p>
              <p>
                Please choose you current business location before agreeing to
                terms and conditions so we can find you to deliver your goods.
              </p>
            </div>
            <div style={{ display: this.state.showTerms ? "block" : "none" }}>
              <ShopTerms />
            </div>
            <div>
              <MapContainer
                lat={this.state.gps.lat}
                long={this.state.gps.long}
              />
            </div>
          </div>
        );
      } else if (this.state.showSettlements === true) {
        this.state.showSettlements = false;
        return (
          <Settlements
            callerProps={this.getProps()}
            callerState={this.state}
            callerName={Shopping}
            classes={classes}
          />
        );
      } else if (this.state.shoppableData) {
        let data = this.state.shoppableData;
        this.state.shoppableData = null;
        this.state.recalledPage = this.state.lastPage;
        this.state.recalledPageSize = this.state.lastSize;
        return (
          <AddDetails
            callerProps={this.getProps()}
            callerState={this.state}
            objectType={data.type}
            shoppableData={data}
            callerName={Shopping}
          />
        );
      } else if (this.state.updateLocation) {
        return (
          <MapContainer
            lat={this.state.gps.lat}
            long={this.state.gps.long}
            choose={true}
            updateCallback={this.updateNewLocation.bind(this)}
          />
        );
      } else {
        return (
          <WindowSizeListener
            onResize={(size) => {
              this.setState({ winSize: size.windowWidth });
            }}
          >
            <div>
              {this.state.winSize >= 800 ? (
                <div>
                  <span
                    style={{
                      flexDirection: "row",
                      width: "fit-content",
                      float: "right",
                      textAlign: "right",
                      marginRight: "35px",
                      marginBottom: "10px",
                      backgroundColor: "#057995",
                      border: "1px solid #057995",
                      borderRadius: "4px",
                      padding: "6px",
                    }}
                    className={classes.buttonLink}
                  >
                    <a
                      href={"https://afrijula.shop/page?name=term-services"}
                      target="_blank"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      Terms and Conditions
                    </a>
                    <ChangeLocale
                      selectLocale={this.state.selectLocale}
                      close={this.closeLocale.bind(this)}
                    />
                  </span>
                </div>
              ) : (
                <span
                  className={classes.buttonLink}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#154c79",
                    border: "none",
                    color: "white !important",
                    padding: "5px 10px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "12px",
                    border: "1px solid #154c79",
                    borderRadius: "4px",
                    width: "fit-content",
                    float: "right",
                    textAlign: "right",
                    marginRight: "35px",
                    marginBottom: "10px",
                  }}
                >
                  <a
                    href={"https://afrijula.shop/page?name=term-services"}
                    target="_blank"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    Terms &amp; Conditions
                  </a>
                </span>
              )}
              <br />
              <br />
              {canSeeDash() ? (
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Card>
                          <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                              <Icon>people</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {currencyFormat(
                                this.state.tilesData.online_sales
                              )}
                            </p>
                            <h4 className={classes.cardTitle}>Total Sales</h4>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <span
                                onClick={() => {}}
                                style={{ cursor: "pointer", color: "#800080" }}
                              >
                                {"online sales report"}
                              </span>
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                      <GridItem xs={12}>
                        <Card>
                          <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                              <Icon>money</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {currencyFormat(
                                this.state.tilesData.unsettled_amount
                              )}
                            </p>
                            <h4 className={classes.cardTitle}>
                              {"Unsettled Payments"}
                            </h4>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <span
                                onClick={() => {}}
                                style={{ cursor: "pointer", color: "#800080" }}
                              >
                                {getString().details}
                              </span>
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    <Card>
                      <CardHeader color="danger">
                        <ChartistGraph
                          className="ct-chart"
                          data={this.state.tilesData.performance_data.data}
                          type="Line"
                          options={() => {
                            let opts = this.state.tilesData.performance_data
                              .options;
                            opts.high = this.state.tilesData.performance_data.data.max;
                            return opts;
                          }}
                          listener={
                            this.state.tilesData.performance_data.animation
                          }
                        />
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardTitle}>{"Online Sales"}</h4>
                      </CardBody>
                      <CardFooter stats style={{ marginTop: -10 }}>
                        <div className={classes.stats}>
                          <span
                            onClick={() => {}}
                            style={{ cursor: "pointer", color: "#800080" }}
                          >
                            {"Details"}
                          </span>
                        </div>
                      </CardFooter>
                      <span>
                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ updateLocation: true });
                          }}
                        >
                          {"Update my location"}
                        </Link>
                        <HelpUtil reference={"e_shop_update_location"} />
                      </span>
                    </Card>
                  </GridItem>
                </GridContainer>
              ) : null}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: -25 }}>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <div>
                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ showSettlements: true });
                          }}
                        >
                          {getString().review_settlements}
                        </Link>
                        <HelpUtil reference={"e_shop_settlements"} />
                      </div>
                      {this.state.canSync ? (
                        <div>
                          <Link
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.initSync();
                            }}
                          >
                            {getString().update_shop}
                          </Link>
                          <HelpUtil reference={"e_shop_update_shop"} />
                        </div>
                      ) : (
                        <div />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <Button
                        color="info"
                        onClick={() => {
                          this.loadOrders();
                        }}
                        justIcon={true}
                        style={{
                          backgroundColor: "#00acc1",
                          margin: ".3125rem 1px",
                          minWidth: "auto",
                          minHeight: "auto",
                          fontSize: "12px",
                          color: "#ffffff",
                          padding: "12px 30px",
                          borderRadius: "3px",
                          verticalAlign: "middle",
                        }}
                      >
                        <i className="material-icons">sync</i>
                        {getString().sync}
                      </Button>
                      <Button
                        color="info"
                        onClick={() => {
                          this.loadOrders("ready,processing");
                          this.setState({ loadedItems: "Ready Orders" });
                        }}
                        style={{
                          backgroundColor: "#00acc1",
                          margin: ".3125rem 1px",
                          minWidth: "auto",
                          minHeight: "auto",
                          fontSize: "12px",
                          color: "#ffffff",
                          padding: "12px 30px",
                          borderRadius: "3px",
                          verticalAlign: "middle",
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {"Awaiting Pickup"}
                      </Button>
                      <Button
                        color="info"
                        onClick={() => {
                          this.loadOrders("picked up,processing");
                          this.setState({ loadedItems: "Picked up Orders" });
                        }}
                        style={{
                          backgroundColor: "#00acc1",
                          margin: ".3125rem 1px",
                          minWidth: "auto",
                          minHeight: "auto",
                          fontSize: "12px",
                          color: "#ffffff",
                          padding: "12px 30px",
                          borderRadius: "3px",
                          verticalAlign: "middle",
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {"Picked up"}
                      </Button>
                      <Button
                        color="info"
                        onClick={() => {
                          this.loadOrders(
                            "delivered,processing",
                            "{payment_settled: false}"
                          );
                          this.setState({ loadedItems: "Delivered Orders" });
                        }}
                        style={{
                          backgroundColor: "#00acc1",
                          margin: ".3125rem 1px",
                          minWidth: "auto",
                          minHeight: "auto",
                          fontSize: "12px",
                          color: "#ffffff",
                          padding: "12px 30px",
                          borderRadius: "3px",
                          verticalAlign: "middle",
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {"Delivered"}
                      </Button>
                      <Button
                        color="info"
                        onClick={() => {
                          this.loadOrders(
                            "cancelled,processing",
                            "{date_added: {'$gte': 1.week.ago}}"
                          );
                          this.setState({ loadedItems: "Cancelled Orders" });
                        }}
                        style={{
                          backgroundColor: "#00acc1",
                          margin: ".3125rem 1px",
                          minWidth: "auto",
                          minHeight: "auto",
                          fontSize: "12px",
                          color: "#ffffff",
                          padding: "12px 30px",
                          borderRadius: "3px",
                          verticalAlign: "middle",
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {"Cancelled"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {this.state.showErrors ? (
                <GridContainer style={{ marginTop: -10 }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <Table
                        tableRef={this.tableRef}
                        data={(query) =>
                          new Promise((resolve, reject) => {
                            let page = this.recallPage();
                            if (page) {
                              query.page = page;
                            }
                            query.unsynced = false;
                            this.loadData(query, resolve, reject);
                          })
                        }
                        columns={[
                          {
                            title: getString().product,
                            field: "name",
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                          },
                          {
                            title: getString().type,
                            field: "_type",
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                            render: (rowData) => {
                              return rowData._type.split("::").pop();
                            },
                          },
                          {
                            title: getString().errors,
                            field: "errors",
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                          },
                        ]}
                        onRowClick={this.rowSelect}
                        title={
                          <p>
                            Complete information for the <br />
                            items below, to display on the shop.
                          </p>
                        }
                        options={{
                          exportButton: false,
                          filtering: false,
                          grouping: false,
                          sorting: false,
                          debounceInterval: 1000,
                          search: false,
                          selection: true,
                          showTitle: true,
                          pageSize: this.state.recalledPageSize || 15,
                        }}
                        actions={[]}
                      />
                    </Card>
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer>
                  <GridItem xs={12} md={7}>
                    {this.state.inPending.length > 0 ? (
                      <Card>
                        <CardHeader stats icon>
                          <CardIcon color="danger">
                            <Icon>event</Icon>
                          </CardIcon>
                          <Typography
                            variant="h6"
                            className={classes.title}
                            style={{ color: "black" }}
                          >
                            New Orders
                          </Typography>
                        </CardHeader>
                        <CardBody>
                          <List>
                            {this.state.inPending.map((order) => {
                              return (
                                <ListItem
                                  style={{
                                    padding: 5,
                                    borderRadius: 5,
                                    margin: 1,
                                    background:
                                      order.merchant_status === "hanging"
                                        ? "linear-gradient(60deg, #ffa726, #fb8c00)"
                                        : "white",
                                  }}
                                >
                                  <ListItemText
                                    primary={
                                      "Customer: (" + order.customer_name + ")"
                                    }
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="div"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          {order.products.map((item) => {
                                            return (
                                              <div
                                                className={classes.smallTitle}
                                              >
                                                <span
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Item: {item.product_name}
                                                </span>
                                                <span
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Qty: {item.quantity}
                                                </span>
                                                <span
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Price: (
                                                  {currencyFormat(
                                                    item.product_price
                                                  )}
                                                  )
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </Typography>
                                        <div>
                                          {" "}
                                          Status: {order.merchant_status}
                                        </div>
                                        <div>
                                          {" "}
                                          Date:{" "}
                                          {dateFormat(order.date_purchased)}
                                        </div>
                                        {order.merchant_status === "hanging" ? (
                                          <div>
                                            Error
                                            <p>{order.sync_error}</p>
                                          </div>
                                        ) : (
                                          <div>
                                            Comments
                                            <p>{order.comments}</p>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    }
                                  />
                                  {order.merchant_status === "pending" ? (
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        edge="end"
                                        aria-label="accept"
                                        onClick={() => {
                                          this.acceptOrder(order._id.$oid);
                                        }}
                                      >
                                        <CheckIcon />
                                      </IconButton>
                                      <IconButton
                                        edge="end"
                                        aria-label="reject"
                                        onClick={() => {
                                          this.rejectOrder(order._id.$oid);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  ) : (
                                    <ListItemSecondaryAction>
                                      <Button
                                        edge="end"
                                        aria-label="accept"
                                        onClick={() => {
                                          this.retryAction(order._id.$oid);
                                        }}
                                      >
                                        retry
                                      </Button>
                                    </ListItemSecondaryAction>
                                  )}
                                </ListItem>
                              );
                            })}
                          </List>
                        </CardBody>
                      </Card>
                    ) : (
                      <Card>
                        <CardHeader stats icon>
                          <CardIcon color="danger">
                            <Icon>event</Icon>
                          </CardIcon>
                          <Typography
                            variant="h6"
                            className={classes.title}
                            style={{ color: "black" }}
                          >
                            {this.state.loadedItems}
                          </Typography>
                        </CardHeader>
                        <CardBody>
                          <List>
                            {this.state.allOrders.map((order) => {
                              return (
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      "Customer: (" + order.customer_name + ")"
                                    }
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="div"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          {order.products.map((item) => {
                                            return (
                                              <div
                                                className={classes.smallTitle}
                                              >
                                                <span
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Item: {item.product_name}
                                                </span>
                                                <span
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Qty: {item.quantity}
                                                </span>
                                                <span
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Price: (
                                                  {currencyFormat(
                                                    item.product_price
                                                  )}
                                                  )
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </Typography>
                                        <div>
                                          {" "}
                                          Status: {order.merchant_status}
                                        </div>
                                        <div>
                                          {" "}
                                          Date:{" "}
                                          {dateFormat(order.date_purchased)}
                                        </div>
                                        <div>
                                          Comments
                                          <p>{order.comments}</p>
                                        </div>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemSecondaryAction />
                                </ListItem>
                              );
                            })}
                          </List>
                        </CardBody>
                      </Card>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={5}>
                    <Card>
                      <CardHeader stats icon>
                        <Typography
                          variant="h7"
                          className={classes.smallTitle}
                          style={{ color: "black" }}
                        >
                          Orders you are processing
                        </Typography>
                        <CardIcon color="success">
                          <Icon>event_busy</Icon>
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <List>
                          {this.state.inProgress.map((order) => {
                            return (
                              <ListItem>
                                <ListItemText
                                  primary={"Customer: " + order.customer_name}
                                  style={
                                    order.processing_duration &&
                                    new Date(
                                      Date.parse(order.processing_duration)
                                    ) < new Date()
                                      ? {
                                          background:
                                            "linear-gradient(60deg, #ffa726, #fb8c00)",
                                        }
                                      : {}
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                        component="div"
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                        {order.products.map((item) => {
                                          return (
                                            <div>
                                              <span style={{ marginRight: 5 }}>
                                                {item.product_name}
                                              </span>
                                              <span style={{ marginRight: 5 }}>
                                                {item.quantity}
                                              </span>
                                              <span style={{ marginRight: 5 }}>
                                                (
                                                {currencyFormat(
                                                  item.product_price
                                                )}
                                                )
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </Typography>
                                      <div>
                                        {" "}
                                        Status: {order.merchant_status}
                                      </div>
                                      <div>
                                        {" "}
                                        Date: {dateFormat(order.date_purchased)}
                                      </div>
                                      <div>
                                        {" "}
                                        Due:{" "}
                                        {dateFormat(
                                          order.processing_duration,
                                          true
                                        )}
                                      </div>
                                      <div>
                                        Comments
                                        <p>{order.comments}</p>
                                      </div>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="accept"
                                    onClick={() => {
                                      this.orderReady(order._id.$oid);
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              )}
              <Dialog
                open={this.state.confirm}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Confirm Order"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please specify how long order will take. Note that if you
                    consistently fail to make orders it will reduce your ranking
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div>
                    <Button
                      autoFocus
                      onClick={() => {
                        this.handleClose(10);
                      }}
                      color="primary"
                    >
                      5 to 10 Minutes
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(20);
                      }}
                      color="primary"
                      autoFocus
                    >
                      10 to 20 Minutes
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(30);
                      }}
                      color="primary"
                      autoFocus
                    >
                      20 to 30 Minutes
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(45);
                      }}
                      color="primary"
                      autoFocus
                    >
                      30 to 45 Minutes
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(60);
                      }}
                      color="primary"
                      autoFocus
                    >
                      1 hour
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(120);
                      }}
                      color="primary"
                      autoFocus
                    >
                      2 hours
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(1440);
                      }}
                      color="primary"
                      autoFocus
                    >
                      1 day
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(4320);
                      }}
                      color="primary"
                      autoFocus
                    >
                      2 to 3 days
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleClose(7200);
                      }}
                      color="primary"
                      autoFocus
                    >
                      1 week
                    </Button>
                    <Button
                      onClick={() => {
                        this.rejectOrder(this.state.orderId);
                      }}
                      color="warning"
                      autoFocus
                    >
                      {getString().reject}
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({ confirm: false });
                      }}
                      color="error"
                      autoFocus
                    >
                      {getString().close}
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.askConfirm}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Confirm Order"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {"Are you sure this will take " + this.getTime() + "?"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={() => {
                      this.cancelConfirm();
                    }}
                    color="warning"
                  >
                    {getString().cancel}
                  </Button>
                  <Button
                    autoFocus
                    onClick={() => {
                      this.confirmConfirm();
                    }}
                    color="success"
                  >
                    {getString().confirm}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.loadSync}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Items to send to shop"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {this.state.loadSync ? (
                      <Table
                        tableRef={this.tableSyncRef}
                        data={(query) =>
                          new Promise((resolve, reject) => {
                            let page = this.recallPage();
                            if (page) {
                              query.page = page;
                            }
                            query.unsynced = true;
                            this.loadData(query, resolve, reject);
                          })
                        }
                        columns={[
                          {
                            title: getString().product,
                            field: "name",
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                          },
                          {
                            title: getString().last_updated,
                            field: "updated_at",
                            render: (rowData) => dateFormat(rowData.updated_at),
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                          },
                          {
                            title: getString().last_sent,
                            field: "synced_on",
                            render: (rowData) => dateFormat(rowData.synced_on),
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                          },
                          {
                            title: getString().image,
                            field: "_type",
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                            render: (rowData) => {
                              return (
                                <Image
                                  model={"Afrijula::Stocks::Stock"}
                                  id={rowData.id.$oid}
                                  style={{ height: 1 + "px", weight: 5 + "px" }}
                                  upSize={500}
                                  viewOnly={true}
                                  tag="picture"
                                />
                              );
                            },
                            filter: false,
                          },
                          {
                            title: getString().errors,
                            field: "errors",
                            cellStyle: { padding: "2px" },
                            headerStyle: { padding: "2px" },
                            filter: false,
                            render: (rowData) => {
                              return rowData.syncable === true ? (
                                <Button
                                  onClick={() => {
                                    this.completeSync(
                                      false,
                                      rowData.id.$oid,
                                      () => {
                                        this.tableSyncRef.current &&
                                          this.tableSyncRef.current.onQueryChange();
                                      }
                                    );
                                  }}
                                  color="success"
                                >
                                  {getString().send}
                                </Button>
                              ) : (
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    this.rowSelect(null, rowData);
                                  }}
                                >
                                  {getString().update}
                                </Button>
                              );
                            },
                          },
                        ]}
                        options={{
                          exportButton: false,
                          filtering: false,
                          grouping: false,
                          sorting: true,
                          debounceInterval: 1000,
                          search: true,
                          selection: false,
                          showTitle: false,
                          pageSize: this.state.recalledPageSize || 15,
                        }}
                        actions={[
                          {
                            icon: "send all",
                            tooltip: "send all items to shop",
                            isFreeAction: true,
                            onClick: () => {
                              this.syncStore();
                              this.setState({ loadSync: false });
                            },
                          },
                          {
                            icon: "refresh",
                            tooltip: "Refresh",
                            isFreeAction: true,
                            onClick: () =>
                              this.tableSyncRef.current &&
                              this.tableSyncRef.current.onQueryChange(),
                          },
                        ]}
                      />
                    ) : null}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={() => {
                      this.setState({ loadSync: false });
                    }}
                    color="error"
                  >
                    {getString().close}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </WindowSizeListener>
        );
      }
    }
  }
}

Shopping.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle, styles)(Shopping);
