import  React from 'react';
import Image from 'material-ui-image';
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {getString, displayError, displaySuccess} from  "assets/js/utils/utils.js";
import cookies from "react-cookies";
import Rodal from "rodal";
import {withStyles} from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

class ImageUtil extends React.Component{


    state={
        uploadPicture: false,
        picture: Math.random(),
        model: this.props.model,
        anchorEl: false
    };

    getPicture = () => {
        return baseUrl+'/picture/'+this.props.id+'?tag='+this.props.tag+'&model='+this.state.model+'&r='+this.state.picture;
    };


    uploadImage = () =>{

        let file    = document.querySelector('input[type=file]').files[0];
        let fileReader = new FileReader();
        let _this = this;
        fileReader.addEventListener('load',function () {

            $.ajax({
                method: "POST",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                data: {id: _this.props.id, model: _this.state.model, name: file.name,
                    upload: fileReader.result.slice(22), tag:_this.props.tag, size: _this.props.upSize},
                url: baseUrl+"/picture",

                success: function(res){
                    _this.setState({uploadPicture: false,picture: res.reference});
                },error: function(resp){
                    return  displayError(resp.responseText);
                }
            });
        },false);

        if(file){
            fileReader.readAsDataURL(file);
        }
    };

    deleteImage = () =>{

        let _this = this;
        if (window.confirm('Are you sure you want to delete image')){

            $.ajax({
                method: "DELETE",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                data: {model: _this.state.model,
                    tag:_this.props.tag},
                url: baseUrl+"/picture/"+_this.props.id,

                success: function(res){
                    _this.handleClose();
                    _this.setState({uploadPicture: false,picture: 'none'});
                    displaySuccess("Image removed successfully");
                },error: function(resp){
                    return  displayError(resp.responseText);
                }
            });
        };

    };


    closeUpload = () =>{
        this.setState({
            uploadPicture: false
        })
    };

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: false});
    };


    render() {
        const { classes } = this.props;
        let callerName = this.props.callerName;


        return (
            <Card style={{marginBottom: 5, marginTop: 2}}>
                <CardBody>
                <div>
                    {(this.props.viewOnly === true)? null : <GridItem xs={12} style={{position: 'relative',height:45}}>
                        <Button link={true} size={'sm'} disabled={this.props.disabled} color="info" onClick={this.handleClick}
                                style={{backgroundColor: "#00acc1",margin: ".3125rem 0px", minWidth: "auto", minHeight:"auto",
                                    padding: "5px 5px",borderRadius: "3px", color:"#ffffff",left:'auto', position:'absolute',right:0}}
                        ><i className="material-icons">settings</i>
                        </Button>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                        >
                            <StyledMenuItem  onClick={() =>{this.setState({uploadPicture: true,anchorEl:null})}}>
                                <ListItemIcon>
                                    <UploadIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="upload" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={()=>{this.deleteImage(); this.setState({anchoreEl:null})}}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="delete" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </GridItem>}
                </div>
                <div>

                        <Image
                            src= {this.getPicture()} style={{height:this.props.height || "100%", width: this.props.width ||"100%"}}
                        />
                </div>
                {(this.state.uploadPicture === false) ? null :
                    <Rodal visible={this.state.uploadPicture} height={320} width={400} showMask={false} onClose={this.closeUpload.bind(this)}>
                        <div>
                            <h2>{getString().picture}</h2>
                            <form autoComplete="on" id="formImage">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridItem xs={12} sm={6} md={12}>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                id="photo"
                                                name="photo"
                                                multiple
                                                type="file"
                                                data-max-file-size="1MB"
                                            />
                                            <Button link={true} variant="contained" component="span" className={classes.button}
                                                    style={{backgroundColor: "#9c27b0",margin: ".3125rem 1px", minWidth: "auto", minHeight:"auto",
                                                        padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }} onClick={this.uploadImage.bind(this)}>
                                                {getString().upload_pic}
                                            </Button>
                                        </GridItem>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </div>
                    </Rodal>}
                </CardBody>
            </Card>
        )
    }
}

export default withStyles(styles)(ImageUtil);
