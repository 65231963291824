import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import frStrings from  "assets/js/locales_fr.js";
import enStrings from  "assets/js/locales_en.js";
import countryList from 'react-select-country-list';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {displayError, displaySuccess,getString} from "../../../assets/js/utils/utils";
import baseURL from "../../../assets/js/config/const";
import cookies from "react-cookies";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class Import_Export extends React.Component {




    state = {
        type: ''
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };


    componentDidMount() {
    };

    // specify upload params and url for your files
    getUploadParams = ({ meta }) => { return { url: baseURL + "/afrijula/afrijula/import",headers: {"Authorization": "token " + cookies.load("token"),
            "UserProfile": cookies.load("profile"),
            "UserKey": cookies.load('User-Key')},
        fields: {type: this.state.type} } }

    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => {
        if(status === 'done')
            displaySuccess("Successfully uploaded file");
        else if(status === 'uploading')
            displaySuccess("Attempting upload");
        else if(status == 'error_upload')
            displayError("Upload failed for file "+meta.name)

    }

    // receives array of files that are done uploading when submit button is clicked
    handleSubmit = (files) => {
        let message = "Successfully imported the following files:\n"
        files.forEach(f => message + f.meta.name)
        displaySuccess(message);
    }

    render() {
        const { classes } = this.props;

        return (

            <div>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem GridItem xs={12} sm={12} md={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <RadioGroup
                                                    row
                                                    aria-label={getString().sellable}
                                                    name="sellable"
                                                    className={classes.group}
                                                    value={this.state.type}
                                                    onChange={e => {
                                                        this.setState({type: e.target.value});
                                                    }}
                                                >
                                                    <FormControlLabel value="stock" control={<Radio />} label={getString().stock} style={{display:'inline'}}/>
                                                    <FormControlLabel value="sales" control={<Radio />} style={{display:'inline'}} label={getString().sales}/>
                                                </RadioGroup>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Dropzone
                                                    getUploadParams={this.getUploadParams.bind(this)}
                                                    onChangeStatus={this.handleChangeStatus.bind(this)}
                                                    onSubmit={this.handleSubmit.bind(this)}
                                                    accept="*"

                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>

                                </Card>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>

            </div>
        );
    }
}

Import_Export.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Import_Export);
