import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import {numberFormat, currencyFormat, dateFormat,compileQuery,displayError,displaySuccess,getString} from "assets/js/utils/utils.js";
import Account_Billing from "components/afrijula/Settings/Account_Billing.jsx";

import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import VoucherPay from "components/afrijula/Pay/VoucherPay.jsx";
import CardProcessing from "components/afrijula/Pay/CardProcessing.jsx";



const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class PayBill extends React.Component {



    state = {

        loadBill: false,
        amount: this.props.amount,
        currency: this.props.currency,
        currentPackage: this.props.package,
        paymentMethod: 'voucher',
        paymentData: {}
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    cancelPayment = () =>{

        this.setState({currentPackage: ''});
        this.setState({amount: ''});

        this.setState({back_acc_bill: true});
    };

    payBill = () => {
        //TODO we need to create a new component to handle this enter thing once we give it the data
        let data = this.validateData();
        if(data){
            this.setState({loadBill:true})
        }
    };

    closePay = () => {
        this.setState({loadBill: false});
        this.cancelPayment();
    };

    loadBillPay =() => {
        if(this.state.loadBill){
            return <CardProcessing amount={this.state.amount} currency={this.state.currency} method={this.state.paymentMethod} getData={this.validateData.bind(this)} onClose={() => this.closePay()} />
        }else{
            return null;
        }
    }

    updatePaymentData = (attr, value, type) => {
        let data = this.state.paymentData;
        data[attr] = value
        this.setState({paymentData: data, paymentMethod: type});
    }

    getPaymentData = (attr) => {
        return this.state.paymentData[attr]
    }

    loadPayment =() => {
        if (this.state.paymentMethod === 'card') {
            //return <GlobalPay setValue={this.updatePaymentData.bind(this)} getValue={this.getPaymentData.bind(this)}/>
            return "Unsupport payment method. Coming soon!"
        } else if (this.state.paymentMethod == 'mobile') {
            return <div><h5>Unsupported payment method. Coming soon!</h5></div>
        } else if (this.state.paymentMethod == 'kodo') {
            return <div><h5>Unsupported payment method. Coming soon!</h5></div>
        } else if (this.state.paymentMethod == 'voucher'){
            return <VoucherPay setValue={this.updatePaymentData.bind(this)} getValue={this.getPaymentData.bind(this)}/>
        }else{
            return <div><h5>Unsupported payment method. Please choose another payment method</h5></div>
        }
    }

    validateData = () => {
        if(this.state.paymentMethod == 'voucher')
            return this.validateVoucher();
        else if(this.state.paymentMethod=='card'){
            return this.validateCard();
        }else{
            displayError("You did not chose valid payment method");
            return null;
        }

    }

    validateVoucher = () => {
        if(this.state.amount > this.props.amount){
            displayError("You may not pay more than "+this.props.amount)
            this.setState({amount: this.props.amount})
            return null;
        }
        let data = this.state.paymentData;
        return data;
    }

    validateCard = () => {
        if(this.state.amount > this.props.amount){
            displayError("You may not pay more than "+this.props.amount)
            this.setState({amount: this.props.amount})
            return null;
        }
        let data = this.state.paymentData
    //    validate date
        if (!data.expiryMonth || data.expiryMonth <= 0 || data.expiryMonth > 12){
            displayError("You must enter a valid expiry date")
            return null;
        }
        if(!data.expiryYear || data.expiryYear < new Date().getFullYear()){
            displayError("You must enter a valid expiry year")
            return null;
        }

    //    validate card holder name
        if(!data.holderName || data.holderName.length == 0){
            displayError("You must enter a valid name")
            return null;
        }
    //    validate street address
        if(!data.streetAddress || !data.cityAddress || !data.countryAddress){
            displayError("You must enter a valid address")
            return null;
        }

    //    validate CVV
        if(!data.cardCode || !/^\d{3}$/.test(data.cardCode.trim())){
            displayError("You must enter valid 3 digit CVV code on back of card")
            return null;
        }

        if(!data.cardNumber ||  !/^\d{16}$/.test(data.cardNumber.trim())){
            displayError("You must enter valid card number")
            return null;
        }
        return data;
    }

    render() {
        const { classes } = this.props;

        if(this.state.back_acc_bill){
            return <Account_Billing />;
        }

        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{getString().pay_bill}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={9} sm={6} md={6} lg={4}>
                                        <TextField
                                            required
                                            id="amountDue"
                                            name="amountDue"
                                            label={getString().amount_to_pay}
                                            className={classes.textField}
                                            value={this.state.amount}
                                            onChange={(e) => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoFocus
                                        />
                                    </GridItem>
                                    <GridItem xs={3} xm={3} md={3}>
                                        <TextField
                                            required
                                            id="currency"
                                            name="currency"
                                            label={getString().currency}
                                            className={classes.textField}
                                            disabled={true}
                                            value={this.state.currency}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <RadioGroup aria-label="position" name="position" value={this.state.paymentMethod}
                                                    onChange={event => this.setState({paymentMethod: event.target.value})} row>
                                        <FormControlLabel
                                                checked={this.state.paymentMethod === 'voucher'}
                                                label={getString().voucher}
                                                value="voucher"
                                                control={<Radio color="primary"/>}
                                                name="radio-button-card"
                                        />
                                            <FormControlLabel
                                            checked={this.state.paymentMethod === 'card'}
                                            label={getString().bank_credit_card}
                                            value="card"
                                            control={<Radio color="primary"/>}
                                            name="radio-button-card"
                                        />
                                        <FormControlLabel
                                            checked={this.state.paymentMethod === 'mobile'}
                                            label={getString().mobile_money}
                                            value="mobile"
                                            control={<Radio color="primary"/>}
                                            name="radio-button-mobile"
                                        />
                                        <FormControlLabel
                                            checked={this.state.paymentMethod === 'kodo'}
                                            label={getString().kodo_wallet}
                                            control={<Radio color="primary"/>}
                                            value="kodo"
                                            name="radio-button-kodo"
                                        />
                                        </RadioGroup>
                                    </GridItem>
                                <GridItem xs={12}>
                                    {this.loadPayment()}
                                </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={4}>
                                    <Button xs={12} sm={12} md={4} color="danger" onClick={this.cancelPayment}>{getString().cancel}</Button>
                                    <Button xs={12} sm={12} md={4} color="success" onClick={this.payBill} >{getString().pay}</Button>
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.loadBillPay()}
            </div>
        );
    }
}

PayBill.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PayBill);
