import React from "react";
import PropTypes from "prop-types";
import cookies from "react-cookies";
// @material-ui/core
import { withStyles } from "@material-ui/core/styles";
import jwt from "jsonwebtoken";

// @material-ui/icons

// core components

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardCategory: {
    color: "#000",
    margin: "0px",
    fontSize: "14px",
    fontWeight: "bolder",
    paddingTop: "4px",
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.divRef = React.createRef();

    this.initDashboard();
  }

  initDashboard = () => {
    var METABASE_SITE_URL = "https://analytics.lecket.gm";
    var METABASE_SECRET_KEY =
      "6eb957ffa2ebc936913f76af11032d5874274bc5a038fe83e04472106d3e4ce8";

    var payload = {
      resource: { dashboard: 1 },
      params: {
        organization_id: cookies.load("orgId"),
      },
    };
    var token = jwt.sign(payload, METABASE_SECRET_KEY);

    this.state.iframeUrl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#bordered=true&titled=false";
  };

  state = this.props.state || {
    iframeUrl: null,
  };

  getFrameHeight = () => {
    return this.divRef.current != null
      ? this.divRef.current.parentNode.parentNode.offsetHeight
      : 800;
  };

  componentDidMount = () => {};

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  render() {
    const { classes } = this.getProps();

    return (
      <div
        className={classes.root}
        ref={this.divRef}
        style={{ height: this.getFrameHeight() }}
      >
        <iframe
          id={"dashFrame"}
          onLoad={() => {}}
          src={this.state.iframeUrl}
          frameBorder="0"
          width="100%"
          height="100%"
          allowTransparency
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
