import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import HeaderLinks from "./HeaderLinks.jsx";
import Button from "components/common/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import Rodal from "rodal";
import Login from "components/afrijula/Login/LogIn.jsx";
import cookies from "react-cookies";
import { getString } from "assets/js/utils/utils.js";
import { MenuRounded } from "@material-ui/icons";

import WindowSizeListener from "react-window-size-listener";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: false,
      winSize: window.innerWidth,
    };

    this.checkSession = this.checkSession.bind(this);
  }

  closeLocale = () => {
    window.location.reload();
  };

  makeBrand = () => {
    var name;
    this.props.routes.map((prop, key) => {
      if (prop.path === this.props.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });
    return name;
  };

  hideLogin = () => {
    this.setState({
      showLogin: false,
    });
  };

  checkSession = () => {
    setInterval(() => {
      if (cookies.load("token") === undefined) {
        this.setState({
          showLogin: true,
        });
      }
    }, 30000);
  };

  componentDidMount() {
    this.checkSession();
  }

  render() {
    const { classes, color } = this.props;
    const appBarClasses = classNames({
      [" " + classes[color]]: color,
    });

    return (
      <WindowSizeListener
        onResize={(size) => {
          this.setState({ winSize: size.windowWidth });
        }}
      >
        <div>
          <div style={{ marginBottom: "50px" }}>
            <AppBar elevation={0} className={classes.appBar + appBarClasses}>
              <Toolbar variant={"dense"} className={classes.container}>
                {/*<Hidden xsUp implementation="css">*/}
                <HeaderLinks />
                {/*</Hidden>*/}
                <Hidden mdUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.props.handleDrawerToggle}
                    style={{
                      flexDirection: "row",
                      width: "fit-content",
                      position: "absolute",
                      right: 0,
                      top: -2.5,
                      marginRight: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <MenuRounded />
                    {/* <Menu /> */}
                  </IconButton>
                </Hidden>
              </Toolbar>
              <Rodal
                visible={this.state.showLogin}
                width={450}
                height={555}
                showMask={false}
                onClose={this.hideLogin.bind(this)}
              >
                <div>
                  <span>{getString().session_out}</span>
                  <Login closeRodal={this.hideLogin} />
                </div>
              </Rodal>
            </AppBar>
            <br />
            <br />
            {this.state.winSize <= 800 ? (
              <>
                <br />
                <br />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </WindowSizeListener>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default withStyles(headerStyle)(Header);
