import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {displayError,displaySuccess,getString} from "assets/js/utils/utils.js";
import Select, {Async} from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import HelpUtil from "../Utils/HelpUtil";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    noPadding: {
        padding: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }


};

class OptionsLoader extends React.Component{
  

    constructor(props){
        super(props);
        this.state = {
            productId: '',
            options: [],
            possibleOpts: []
        };

    }

    optionsArray = () => {
        let array= this.props.options;
        return Object.keys(array).map(key => { return {attribute: key, 'value' : array[key]}; });
    }

    handleChange = (id, opt, val) => {
        let opts = this.state.options;
        if(!opts[id])
            opts[id] = {};
        opts[id][opt] = val;
        this.setState({options: opts});

        if(opts[id] && opts[id]['attribute'] && opts[id]['value'])
            this.props.optionsCback(opts);
    };

    addNew = () => {
        let opts = this.state.options;
        opts.push({attribute: '', value:''});
        this.setState({options: opts});
    }

    removeOld = () => {
        let opts = this.state.options;
        opts.pop();
        this.setState({options: opts});
    }

    getProps = () => {
        return this.props;
    }


    componentDidUpdate (prevProps){
        if(Object.keys(prevProps.options).length != Object.keys(this.props.options).length){
            this.state.options = this.optionsArray();
            this.setState({options: this.state.options});
        }
        if(prevProps.productId != this.props.productId){
            this.loadOptions();
        }
    }

    loadOptions = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/shop/sync/attrs",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{product_id: this.props.productId, type: this.props._type},
            success: function (data){
                while(_this.state.options.length > 0){
                    _this.removeOld();
                }

                Object.keys(data).forEach(item => _this.addNew())
                _this.setState({
                    possibleOpts: data
                });
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    getOptions = () => {
        return Object.keys(this.state.possibleOpts).map(opt => {return {label: opt, 'value': opt}});
    }

    getValues = (i) => {

        var find_similar = function(opts, attr) {
            var l = [];
            Object.keys(opts).forEach(opt => {
                if(opt == attr) {
                    opts[attr].forEach(item => l.push({label: item, value: item}))
                }
            });
            return l;
        };
        if(this.state.options[i]){
            return find_similar(this.state.possibleOpts, this.state.options[i]['attribute']);
        }else{
            return [];
        }

    }

    handleOptionChange = (opt, id) => {
        this.handleChange(id, 'attribute', opt.value);
    }

    handleOptValChange = (opt, id) => {
        this.handleChange(id, 'value', opt.value);
    }

    render() {
        const { classes } = this.getProps();

        return <div>
            <h5>{getString().options}</h5>
            <GridContainer>
                {(this.props.noChange !== true) ? <GridItem xs={12}>
                    <label>Options</label><HelpUtil reference={'inventory_options'}/>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={this.addNew.bind(this)}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={this.removeOld.bind(this)}>
                        <RemoveIcon />
                    </IconButton>
                </GridItem> : null}
                <GridItem xs={12}>
                    {
                        this.state.options.map((item, i) => {
                        return <div> <AsyncCreatableSelect
                            autoFocus defaultOptions={this.getOptions()}
                            onChange={opt => {
                                this.handleOptionChange(opt, i)
                            }}/><AsyncCreatableSelect
                            autoFocus defaultOptions={this.getValues(i)}
                            onChange={opt => {
                                this.handleOptValChange(opt, i)
                            }}/></div>
                    })}
                </GridItem>
            </GridContainer>
        </div>



    }
}


export default withStyles(styles)(OptionsLoader);
