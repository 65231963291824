import React from "react";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import {currencyFormat, displaySuccess, getString} from "assets/js/utils/utils.js";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Card from "../../common/Card/Card";
import CardHeader from "../../common/Card/CardHeader";
import Table from "material-table";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const";
import GridContainer from "../../common/Grid/GridContainer";
import PropTypes from "prop-types";
import LoanRequirements from "./LoanRequirements";
import $ from "jquery";
import {compileQuery, displayError} from "../../../assets/js/utils/utils";
import SingleLoanItem from "./SingleLoanItem";
import Button from "../../common/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import HelpUtil from "../Utils/HelpUtil";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";


class Loan extends React.Component {
    state = this.props.state || {
        callerName: this.props.callerName,
        callerState: this.props.callerState,
        enableDelete: false,
        applyLoan: false,
        selectedFSP: "",
        total_amount: 0.0,
        pendingLoans: [],
        approvedLoans: [],
        allFSPs: [],
        fsp_id: '',
        loan: null,
        fspLoans: [],
        searchData: {
            max_amount: '',
            min_amount: '',
        },
        loanId: null,
        selectedProduct: null,
    };

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

    }

    viewLoanProduct = (fsp, product_id) => {
        let url = baseUrl + "/afrijula/loans/loan_products/" + product_id;
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => response.json())
            .then(result => {
                this.setState({
                    applyLoan: true,
                    selectedFSP: fsp,
                    selectedProduct: result
                })
            })
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    loadAppliedLoans = (query, resolve, reject, status=null) => {
        let operator = status ? '$eq' : '$ne';
        let filter = "status: {'"+operator+"':'Approved'}, merchant_id: {'$eq':'" + cookies.load("orgId") + "'}";
        query.scope = false;
        let url = compileQuery(query, (baseUrl + "/afrijula/loans/loans/"),
            ["id", 'applicant', 'fsp_email', 'loan_product_id', 'fsp','merchant_id', 'repayment_frequency', 'tenor', 'name', 'status', 'amount', 'created_at'], [{"by": "asc", "attr": "created_at"}],
            "Afrijula::Loans::Loan", null, filter, null, null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadFSPs = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loans/load_all_fsp",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            success: function (result) {
                let temp = [...result.partners];
                temp.unshift({name: "All",_id:{"$oid": "All"}, selected: true});
                let _fsp_id = temp[0]._id.$oid;
                let _fsp_name = temp[0].name;
                this.setState({
                    fsp_id: _fsp_id,
                    fsp_name: _fsp_name,
                    allFSPs: temp
                });
            }.bind(this),
            error: function (error) {

            }
        });
    };

    getLoanPortfolio = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/dashboard",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {count: ['merchant_loan_portfolio'], chart: []},
            success: function (result) {
                this.setState({total_amount: result.counts.merchant_loan_portfolio.total_amount});
            }.bind(this)
        });
    };

    getSavedLoan = (loan_id) => {
        $.ajax({
            method: "GET",
            url:  baseUrl + "/afrijula/loans/loans/" + loan_id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            success: function (result) {
                this.setState({
                    applyLoan: true,
                    loan: result.loan,
                    selectedProduct: result.loan_product
                });
            }.bind(this),
            error: function (error) {
                displayError(error.responseText);
            }
        });
    };

    finishLoanApplication = (event, rowData) => {
        this.getSavedLoan(rowData._id.$oid)
    };

    // deleteLoanApplication = (event, rowData) => {
    deleteLoanApplication = () => {
        // event.preventDefault();
        let _this = this;

        $.ajax({
            method: "DELETE",
            url: baseUrl + "/afrijula/loans/loans/" + this.state.loanId, //rowData._id.$oid, this.state.loanId
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (result) {
                console.log("Success",result);
                displaySuccess("successfully removed loan item");
                _this.setState({enableDelete: false})
                _this.tableRef.current && _this.tableRef.current.onQueryChange();
                //reload table of pending/rejected loans
                // let che = query => new Promise((resolve, reject) => {
                //     _this.loadAppliedLoans(query, resolve, reject);
                // });
            },
            error: function (resp) {
                console.log("Fail",resp);
                _this.tableRef.current && _this.tableRef.current.onQueryChange();
                displayError("Sorry cannot delete loan application now, please try later"); //resp.responseText
                _this.setState({enableDelete: false});
            }
        });
    };

    getFspLoans = (fsp_id= "All") => {
        let url = baseUrl + "/afrijula/loans/loan_products/load_partner_loans?fsp_id=" + fsp_id;
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => response.json())
            .then(result => {
                this.setState({
                    fspLoans: result
                })
            })
    };

    clearSearchFields = () => {this.setState({searchData: {max_amount: '', min_amount: ''}})};

    searchLoan = () => {
        let _search = {...this.state.searchData};
        if(_search.max_amount === '' || _search.min_amount === ''){
            displayError("Please specify minimum and maximum amount");
        }else{
            let _this = this;
            $.ajax({
                method: "GET",
                url: baseUrl + "/afrijula/loans/loan_products/search_product",
                dataType: "json",
                headers: {
                    "Authorization": "token " + cookies.load("token"),
                    "UserProfile": cookies.load("profile"),
                    "UserKey": cookies.load('User-Key'),
                },
                data: {fsp_id: this.state.fsp_id === "All" ? "" : this.state.fsp_id, search_data: this.state.searchData},
                success: function (result) {
                    this.setState({fspLoans: result.search_results});
                    _this.clearSearchFields();
                }.bind(this),
                error: function (error) {

                }
            });
        }
    };

    handleChangeSearch = (e) => {
        let _search = {...this.state.searchData};
        _search[e.target.name] = e.target.value;
        this.setState({searchData: _search});
    };

    displayTile = (event, rowData) => {
        let clonedArr = [...this.state.allFSPs];
        clonedArr.forEach(fsp => fsp.selected = false);
        rowData.selected = true;
        clonedArr[rowData.tableData.id] = rowData;
        this.setState({fsp_name: rowData.name, fsp_id: rowData._id.$oid, allFSPs: clonedArr});
        this.getFspLoans(rowData._id.$oid);
    };

    componentDidMount() {
        this.getLoanPortfolio();
        this.loadFSPs();
        this.getFspLoans();
        this.setState({loan: null});
    };

    render() {
        const searchData = {...this.state.searchData};
        const {classes} = this.getProps();

        if (this.state.applyLoan) {
            return <LoanRequirements callerProps={this.getProps()} callerState={this.state} callerName={Loan}/>
        }
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <Card>
                            <CardHeader color="success">
                                <h5>Active Loans <HelpUtil reference={"active_loans"} /><span
                                    style={{float: 'right'}}>Loan Portfolio: {currencyFormat(this.state.total_amount)}</span>
                                </h5>

                            </CardHeader>
                            <Table
                                columns={[
                                    {
                                        title: 'Name',
                                        field: 'name',
                                        cellStyle: {padding: '4px'},
                                        headerStyle: {padding: '4px'}
                                    },
                                    {
                                        title: 'Amount',
                                        field: 'amount',
                                        render: rowData => currencyFormat(rowData.amount)
                                    },
                                    {title: 'FSP', field: 'fsp'}
                                ]}
                                data={query => new Promise((resolve, reject) => {
                                    this.loadAppliedLoans(query, resolve, reject, "Approved");
                                })}
                                options={{
                                    columnsButton: true,
                                    sorting: true,
                                    toolbar: false,
                                    cellStyle: {padding: '2px'},
                                    headerStyle: {padding: '2px'}
                                }}
                            />
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <Card>
                            <CardHeader color="primary">
                                <h5>Pending/Rejected Loans <HelpUtil reference={"pending_rejected_loans"} /></h5>
                            </CardHeader>
                            <Dialog
                                open={this.state.enableDelete}
                                onClose={() => this.setState({enableDelete: false})}>
                                <DialogTitle id="alert-dialog-title">
                                    {"Delete Request?"}
                                </DialogTitle>
                                <DialogContent>
                                    <p>Are you sure you want to delete loan application?</p>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => this.setState({enableDelete: false})}
                                        color="error">
                                        Cancel</Button>
                                    <Button
                                        onClick={this.deleteLoanApplication}
                                        autoFocus
                                        color="success">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Table
                                tableRef={this.tableRef}
                                columns={[
                                    {
                                        title: 'Name',
                                        field: 'name',
                                        cellStyle: {padding: '4px'},
                                        headerStyle: {padding: '4px'}
                                    },
                                    {
                                        title: 'Amount',
                                        field: 'amount',
                                        render: rowData => currencyFormat(rowData.amount)
                                    },
                                    {title: 'FSP', field: 'fsp'},
                                    {title: 'Status', field: 'status'}
                                ]}
                                data={query => new Promise((resolve, reject) => {
                                    this.loadAppliedLoans(query, resolve, reject);
                                })}
                                options={{
                                    columnsButton: true,
                                    sorting: true,
                                    toolbar: false,
                                    cellStyle: {padding: '2px'},
                                    headerStyle: {padding: '2px'},
                                    actionsColumnIndex: -1,
                                }}
                                actions={[
                                    {
                                        tooltip: getString().remove_item,
                                        icon: 'delete',
                                        iconProps: { fontSize: 'small' },
                                        style: { float: 'right' },
                                        onClick: (event, row) => {
                                            // this.deleteLoanApplication(event, row) //
                                            this.setState({
                                                loanId: row._id.$oid,
                                                enableDelete: true
                                            })
                                        },
                                    },
                                    (rowData) => {
                                        return rowData.status === "Unfinished"
                                            ? { icon: 'check', tooltip: 'Finish application for this loan', onClick: (event,rowData) => { this.finishLoanApplication(event,rowData) } }
                                            : null
                                    }
                                ]}
                                localization={{header: {actions: ''}}}
                            />
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem sm={12} md={3}>
                        <Card>
                            <Table
                                title="List of FSPs"
                                columns={[
                                    {
                                        title: '',
                                        field: 'name',
                                        cellStyle: {padding: '4px'},
                                        headerStyle: {padding: '4px'}
                                    }
                                ]}
                                data={this.state.allFSPs}
                                onRowClick={(event, rowData) => this.displayTile(rowData.fsp, rowData)}
                                options={{
                                    exportButton: false, filtering: false,
                                    debounceInterval: 1000,
                                    grouping: false, sorting: false,
                                    search: false,
                                    paging: false,
                                    showTitle: true,
                                    pageSize: 9,
                                    rowStyle: rowData => ({
                                        backgroundColor: (rowData.selected === true) ? '#DDD' : '#FFF',
                                        height: '8px'
                                    })
                                }}
                            />
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <table style={{
                                        fontFamily: "arial, sansSerif",
                                        borderCollapse: "collapse",
                                        width: "100%",
                                    }}>
                                        <tr>
                                            <td>
                                                <TextField
                                                    id="min_amount"
                                                    name="min_amount"
                                                    label="Min. Amount"
                                                    value={searchData.min_amount}
                                                    className={classes.textField}
                                                    onChange={this.handleChangeSearch}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id="max_amount"
                                                    name="max_amount"
                                                    label="Max. Amount"
                                                    value={searchData.max_amount}
                                                    className={classes.textField}
                                                    onChange={this.handleChangeSearch}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </td>
                                            <td><Button onClick={this.searchLoan}>Search</Button></td>
                                        </tr>
                                    </table>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {
                                (this.state.fspLoans.length > 0) ?
                                    this.state.fspLoans.map(loan => (
                                        <SingleLoanItem loan={loan} viewLoanProduct={this.viewLoanProduct} />
                                    )) : <p>No loan products found!</p>
                            }
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Loan.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(Loan);

