import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import AddUser from "components/afrijula/Settings/Add_User.jsx";
import Table from "material-table";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import { displayError, displaySuccess,getString} from "assets/js/utils/utils.js";
import Rodal from "rodal";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class User_Account extends React.Component {

    state = this.props.state || {
        account_lists: [],
        add_user: false,
        editUser: false,
        editUserDetails: null,
        deletedUserID:'',
        editPwd: false,
        new_pwd: null,
        current_pwd: null,
        verify_pwd: null,
        currentUser: null

    };



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    addUser = () => {
        this.setState({add_user:true});
    };

    componentDidMount() {
        this.loadUsers();
    };

    loadUsers = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/users/",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {"from_admin": false},

            success: function (result) {
                this.setState({account_lists:result});
            }.bind(this)
        });
    };

    getProps = () =>{
        return this.props || this.props.returninProps;
    };

    editUser = (rowData) =>{
        this.setState({
            editUser: true,
            editUserDetails: rowData,
        })
    };

    deleteUser = (rowData) =>{
        let response = window.confirm("Are you sure you want to delete user? this action cannot be undone!");

        if(response){
            let _this = this;
            $.ajax({
                method: "DELETE",
                url: baseUrl + "/account/users/"+rowData._id.$oid,
                dataType: "json",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},


                success: function (result) {
                    displaySuccess("User successfully deleted.");
                    _this.loadUsers();
                }
            });
        }

    };

    closePwdChange = () => {
        this.setState({
            editPwd: false,
            current_pwd: '',
            new_pwd:'',
            verify_pwd: ''
        });
    };

    savePwd = (for_account,_id) => {

        const _this = this;
        let data = {};
        if(this.state.new_pwd && this.state.current_pwd && this.state.verify_pwd){
            data.new_pwd = this.state.new_pwd;
            data.current_pwd = this.state.current_pwd;
            data.verify_pwd = this.state.verify_pwd;
        } else if(for_account){
            data.userId = _id;
            data.for_account = true;
        } else{
            displayError("Unable to change password. You must specify user name and password!");
            return;
        }
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/authenticate_v2/change",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: data,
            success: function (result) {
                displaySuccess(result.success);
                if(!_this.state.currentUser)
                    _this.closePwdChange();
            },error: function(resp){
                displayError(resp.responseText);
            }
        });
    };

    showPassword = () => {

        return (this.state.editPwd) ?
            (
                <div>
                    <Rodal height={350} visible={this.state.editPwd} showMask={false} onClose={this.closePwdChange.bind(this)}>
                        <div>
                            <TextField
                                autoFocus
                                id="current_pwd"
                                label={getString().current_pwd}
                                name="current_pwd"
                                type="password"
                                value={this.state.current_pwd}
                                onChange={e => {this.handleChange(e)}}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                autoFocus
                                id="new_pwd"
                                label={getString().new_password}
                                name="new_pwd"
                                type="password"
                                value={this.state.new_pwd}
                                onChange={e => {this.handleChange(e)}}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                autoFocus
                                id="verify_pwd"
                                label={getString().verify_new_pwd}
                                name="verify_pwd"
                                type="password"
                                value={this.state.verify_pwd}
                                onChange={e => {this.handleChange(e)}}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <Button color="primary" onClick={this.savePwd.bind(this)}>{getString().save}</Button>
                    </Rodal>
                </div>
            ) : <div/>
    };

    enable = (colData) => {
        let id = colData.user_id;
        this.statusHelper(id, colData._id.$oid,'activated');
    }

    disable = (colData) => {
        let id = colData.user_id;
        this.statusHelper(id, colData._id.$oid,'unactivated');
    }

    statusHelper = (id, pId, status) =>{
        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/users/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {"status": status, profile_id: pId},

            success: function (result) {
                displaySuccess(result.success);
                _this.loadUsers();
            },error: function(errors){
                displayError(errors.responseText);
            }
        });
    }

    editPassword = (rowData) => {
        if(rowData._id.$oid === cookies.load('profileId')){
            this.setState({
                editPwd: !this.state.editPwd
            })
        }else{
            if(window.confirm(getString().chaangeUserPswd)){

                this.savePwd(true, rowData._id.$oid);
            }
        }

    };

    render() {

        if(this.state.add_user){
            return <AddUser callerState={this.state} callerProps={this.getProps()}  callerName={User_Account}  />;
        }
        if(this.state.editUserDetails){
            return <AddUser callerState={this.state} callerProps={this.getProps()}  nopwd={true} callerName={User_Account} userData={this.state.editUserDetails}/>;
        }

        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button xs={12} sm={12} md={2} color="primary" onClick={this.addUser}
                                                style={{backgroundColor: "#9c27b0", margin: ".3125rem 1px", minWidth: "auto",
                                                    minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                        ><i className="material-icons">person_add</i>
                                            {getString().add_user}
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                             <Table
                                 title={getString().user_acc}
                                 data={this.state.account_lists}
                                 columns={[
                                     {title: getString().first_name, field: "first_name"},

                                     {title: getString().last_name, field: "last_name"},
                                     {title: getString().email , field: "email"},
                                     {title: getString().type , field: "type", render: rowData => rowData.type.substring(20)},
                                     {title: getString().status, field:'status'}
                                 ]}
                                 options={{paging:false}}
                                 actions={[

                                     {
                                         icon: 'delete',
                                         tooltip: 'Delete Account',
                                         onClick: (event, rowData) => {
                                             this.deleteUser(rowData);
                                         }
                                     },
                                     {
                                         icon: 'edit',
                                         tooltip: 'Edit Account',
                                         onClick: (event, rowData) => {
                                             this.editUser(rowData);
                                         }
                                     },
                                     {
                                         icon: 'vpn_key',
                                         tooltip: 'Edit Password',
                                         onClick: (event, rowData) => {
                                             this.editPassword(rowData);
                                         }
                                     },
                                     {
                                         icon: 'block',
                                         tooltip: 'De-activate',
                                         onClick: (event, rowData) => {
                                             this.disable(rowData);
                                         }
                                     },
                                     {
                                         icon: 'checkcircleon',
                                         tooltip: 'Activate',
                                         onClick: (event, rowData) => {
                                             this.enable(rowData);
                                         }
                                     }
                                 ]

                                 }/>

                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.showPassword()}
            </div>
        );
    }
}

User_Account.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(User_Account);
