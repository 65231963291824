import React from "react";
import "./loader.css";

const LoadingDots = () => {
  return (
    <div className="dots-loading">
      <div />
    </div>
  );
};

export default LoadingDots;
