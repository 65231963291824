import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Account_Billing from "components/afrijula/Settings/Account_Billing.jsx";
import User_Account from "components/afrijula/Settings/User_Account.jsx";
import General_info from "components/afrijula/Settings/General_Info.jsx";
import Import_Export from "components/afrijula/Settings/Import_Export.jsx";
import {getString} from  "assets/js/utils/utils.js";
import cookies from "react-cookies";

function TabContainer1(props) {
    return (
        <Typography component={General_info} style={{ padding: 8 * 3 }}/>
    );
}

function TabContainer2(props) {
    return (
        <Typography component={Account_Billing} style={{ padding: 8 * 3 }}/>
    );
}
function TabContainer3(props) {
    return (
        <Typography component={User_Account} style={{ padding: 8 * 3 }}/>
    );
}

function TabContainer4(props) {
    return (
        <Typography component={Import_Export} style={{ padding: 8 * 3 }}/>
    );
}

TabContainer1.propTypes = {
    children: PropTypes.node.isRequired,
};
TabContainer2.propTypes = {
    children: PropTypes.node.isRequired,
};
TabContainer3.propTypes = {
    children: PropTypes.node.isRequired,
};
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class Settings extends React.Component {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" style={{zIndex: 100, backgroundColor: '#9c27b0', color: '#fff' }}>
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label={getString().general_info} />
                        {(cookies.load('user_type') == 'AccountAdmin') ? <Tab label={getString().acc_bill} /> : null}
                        <Tab label={getString().user_account} />
                        <Tab label={getString().import_export} />
                    </Tabs>
                </AppBar>
                {((cookies.load('user_type') == 'AccountAdmin')) ?
                    (<div>{value === 0 && <TabContainer1/>}
                        {value === 1 && <TabContainer2/>}
                        {value === 2 && <TabContainer3/>}
                        {value === 3 && <TabContainer4/>} </div>)
                    : <div>{value === 0 && <TabContainer1/>}
                        {value === 1 && <TabContainer3/>}
                        {value === 2 && <TabContainer4/>}</div>
                }
            </div>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
