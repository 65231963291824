import React from "react";
import Rodal from "rodal";
import AsyncCreatableSelect from "react-select/async-creatable";
import Creatable from "react-select/creatable";

// import Select, {AsyncCreatable} from "react-select";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import InvButton from "components/afrijula/Sell/InvoiceButton.jsx";
import Receipt from "components/afrijula/Sales/Receipt.jsx";
import QuoteReceipt from "components/afrijula/Sales/QuoteReceipt.jsx";
import {
  applyPackaging,
  currencyFormat,
  currencyValue,
  displayError,
  displaySuccess,
  getString,
  getToday,
  numberFormat,
  showReportUtility,
} from "assets/js/utils/utils.js";
import Add_Stock_Product from "components/afrijula/Sell/Add_Stock_Product.jsx";
// include styles
import "rodal/lib/rodal.css";
import GenericPayment from "components/afrijula/AddForm/GenericPayment.jsx";
import SellTableTemplate from "components/afrijula/Material-Table/SellTableTemplate.jsx";

import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import PropTypes from "prop-types";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import HelpUtil from "components/afrijula/Utils/HelpUtil";
import WindowSizeListener from "react-window-size-listener";
import MobileReceipt from "../../common/MobileReceipt/MobileReceipt";
import CustomLoaderGif from "../../common/Loader/paying.gif";
import Axios from "axios";
import Select from "react-select";
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  formControl: {
    margin: theme.spacing.unit * 2,
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },

  productSelector: {
    padding: "12px 15px 5px !important",
  },
});

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.prodRef = React.createRef();
  }

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  stockOptions = async (inputValue) => {
    const opts = [];
    try {
      const { data } = await Axios.get(
        `${baseUrl}/afrijula/sales/sales/sellable?attrs=["id","name","unit_price","wholesale_price","category","number","wholesale_metric","sellable_units",retail_metric"]&to=name&match=${inputValue}`,
        {
          headers: {
            Authorization: "token " + cookies.load("token"),
            UserProfile: cookies.load("profile"),
            UserKey: cookies.load("User-Key"),
          },
        }
      );
      for (let i = 0; i < data.length; i++) {
        if (!data[i].billable) {
          opts.push({
            label: data[i].name,
            value: data[i]._id.$oid,
            unit_price: data[i].unit_price,
            wholesale_price: data[i].wholesale_price,
            sellable_units: data[i].sellable_units,
            number: data[i].number,
            wholesale_metric: data[i].wholesale_metric,
            category: data[i].category,
            has_options: data[i].has_options,
            retail_metric: data[i].retail_metric,
            type: data[i].type,
            billable: data[i].billable,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
    return opts;
  };

  showInvoice = () => {
    this.setState({ invoice_visible: true });
  };

  showQuote = () => {
    this.setState({ quote_visible: true });
  };

  hideInvoice = () => {
    this.setState({ invoice_visible: false });
  };

  showCash = () => {
    this.setState({
      cash_visible: true,
      cashAmount: this.state.total,
      change: 0.0,
    });
  };

  hideCash = () => {
    this.setState({ cash_visible: false });
  };

  createRow = (id, name, quantity, unit_cost, total) => {
    return { id, name, quantity, unit_cost, total };
  };

  closePrintQuote = () => {
    this.setState({
      quote_visible: false,
    });
  };

  componentDidMount() {
    this.stockOptions("").then((opts) => this.setState({ options: opts }));
    this.customerOptions("").then((customers) =>
      this.setState({ customers: customers })
    );

    this.setState({
      date: this.state.date || getToday(),
    });
    this.loadLocations();
    localStorage.setItem("payment_method", "cash");
    // get the user's data
    const userData = JSON.parse(localStorage.getItem("afrijula.insist.state"));
    this.setState({ accountType: userData.user.user_type });
    this.setState({ winSize: window.innerWidth });
    this.setState({ accountType: userData.user.user_type });
    this.setState({ payMethod: "cash" });
    this.updateSales(this.state.saleItems, this.state.discount);
  }

  viewInvoice = (invoiceId) => {
    let inject = {
      images: [
        {
          name: "image",
          attr: "image",
          id: cookies.load("orgId"),
          model: "Organization",
        },
      ],
      values: [
        {
          name: "org_address",
          attr: "address",
          id: cookies.load("orgId"),
          model: "Organization",
        },
        {
          name: "org_contact",
          attr: "contact_email",
          id: cookies.load("orgId"),
          model: "Organization",
        },
        {
          name: "additional_invoice_info",
          attr: "additional_invoice_info",
          id: cookies.load("orgId"),
          model: "Organization",
        },
      ],
    };
    let params = {
      organization_id: cookies.load("orgId"),
      org_name: cookies.load("orgName"),
      date: new Date(),
      invoice_id: invoiceId,
      org_address: cookies.load("orgAddress"),
      org_contact: cookies.load("orgContact"),
    };
    showReportUtility(
      "Invoice",
      baseUrl +
        "/reports/1?url=/afrijula/*&profile=" +
        cookies.load("profileId"),
      { params: params, sub: "&report=Invoice&dynamic=true", inject: inject },
      cookies,
      []
    );
  };

  paymentPlan = (amount) => {
    return {
      initial_deposit: amount,
      installment_type: "fixed",
      payment_type: "fixed",
      installments: [
        {
          count: 0,
          period: "month",
          date: this.state.date,
        },
      ],
    };
  };

  saveOneTime = async (
    payment_method,
    amount,
    amount_received,
    date,
    paid_by,
    bank,
    bank_no,
    callback
  ) => {
    let plan = null;
    let tran_type = "one_time";
    if (payment_method === "bank" || payment_method === "cheque") {
      plan = this.paymentPlan(amount);
      tran_type = "invoice";
    }
    this.saveSale(
      payment_method,
      amount,
      amount_received,
      plan,
      tran_type,
      callback,
      null,
      bank,
      bank_no,
      paid_by
    );
  };

  receiptPrintDetails = (result, description, sale_items, amount_received) => {
    if (result.payment) {
      this.setState({
        exitPrint: true,
        activePayment: result.payment,
        sale_id: result.sales_id.$oid.slice(result.sales_id.$oid.length - 5),
        description: description,
        sale_items: sale_items,
        amount_received: parseFloat(amount_received),
      });
    }
  };

  saveSale = (
    payment_type,
    paid_amount,
    amount_received,
    paymentPlan,
    type,
    callBack,
    contract,
    bank,
    bank_no,
    paid_by
  ) => {
    this.setState({ loading: true });
    let _this = this;
    let customer_name = this.state.customerName;
    let tax = this.state.taxDue;
    let sub_total = this.state.subTotal;
    //let sub_total_less_discount =((this.state.subTotal.cents) - (this.state.discount.cents)) / 100
    let discount_type = this.state.discountType;
    let discount = this.state.discountAmount;

    let pricing = this.state.saleType;
    let tran_type = type;
    let items = [];
    let customer_address = this.state.customerAddress;
    let customer_email = this.state.customerEmail;
    let customer_phone_number = this.state.customerPhone;
    let total = this.state.total;
    let sale_date = this.state.date;

    let description = [];
    for (let i = 0; i < this.state.saleItems.length; i++) {
      items.push({
        name: this.state.saleItems[i].item,
        quantity: this.state.saleItems[i].quantity,
        number: this.state.saleItems[i].number,
        options: this.state.saleItems[i].options,
        category: this.state.saleItems[i].category,
        billable: this.state.saleItems[i].billable,
        unit_price: this.state.saleItems[i].unit_price,
        wholesale_price: this.state.saleItems[i].wholesale_price,
        sellable_units: this.state.saleItems[i].sellable_units,
        metric_units: this.state.saleItems[i].metric_units,
        type: this.state.saleItems[i].type,
        total: this.state.saleItems[i].total,
      });
      description.push(this.state.saleItems[i].item);
    }
    description = description.join(",");
    let data = this.state.toPrintData.slice();
    let _amount = this.state.cashAmount;
    let charges = {
      payment_method: payment_type,
      tax: tax,
      discount: discount,
      total: total,
      subtotal: sub_total,
      name: customer_name,
      //"subtotal_less_discount": sub_total_less_discount,
      customer_email: customer_email,
      customer_phone: customer_phone_number,
      amount: _amount,
    };
    data = items;
    if (payment_type) {
      if (type === "one_time" && paid_amount < this.state.total) {
        displayError(getString().err_total_amount);
        return;
      } else if (type === "invoice" && !paid_amount && !paymentPlan) {
        displayError(getString().err_payment_plan);
        return;
      }
    }
    $.ajax({
      method: this.state._id ? "PUT" : "POST",
      url:
        baseUrl +
        (this.state._id
          ? "/afrijula/sales/sales/" + this.state._id.$oid
          : "/afrijula/sales/sales"),
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        description: description,
        payment_type: payment_type,
        sub_total: sub_total,
        total_amount: total,
        customer_email: customer_email,
        customer_address: customer_address,
        phone_number: customer_phone_number,
        customer_name: customer_name,
        tax: tax,
        tax_percentage: this.state.taxPercentage,
        discount: discount,
        pricing: pricing,
        tran_type: tran_type,
        sale_date: sale_date,
        contract: contract ? contract() : null,
        sales_items: items,
        payment_plan: paymentPlan,
        payment_amount: paid_amount,
        discount_type: discount_type,
        bank: bank,
        bank_number: bank_no,
        paid_by: paid_by,
      },

      success: function(result) {
        _this.setState({ loading: false });
        _this.hideCash();
        _this.hideInvoice();
        let items = _this.state.saleItems;
        items.splice(0, items.length);

        if (callBack) callBack();
        displaySuccess(getString().sale_success);
        if (type === "invoice") {
          _this.receiptPrintDetails(result, description, items);
          _this.viewInvoice(result.invoice_id);
        } else if (type === "quote") {
          this.setState({
            quote_visible: true,
            quoteItems: JSON.parse(JSON.stringify(_this.state.saleItems)),
            quoteSale: {
              tax: _this.state.taxDue,
              discountAmount: _this.state.discountAmount,
              discountType: _this.state.discountType,
              subTotal: _this.state.subTotal,
              total: _this.state.total,
              description: description,
              date: _this.state.date,
            },
          });
        } else {
          _this.receiptPrintDetails(
            result,
            description,
            items,
            amount_received
          );
        }
        this.setState({
          taxDue: 0,
          discountAmount: 0,
          customerAddress: "",
          customerEmail: "",
          customerPhone: "",
          total: 0,
          subTotal: 0,
          customerName: "",
          description: "",
          cashAmount: 0,
          toPrintData: data,
          toPrintCharges: charges,
        });
      }.bind(this),
      error: function(resp) {
        _this.setState({ loading: false });
        displayError(resp.responseText);
      },
    });
  };

  getStateFromProps = () => {
    if (this.props.salesData) {
      let data = this.props.salesData;
      return {
        payMethod: "cash",
        customerName: data.customer ? data.customer.name : "",
        taxDue: currencyValue(data.total_tax, false),
        discountType: data.discount_type,
        discountTypeSymbol:
          data.discount_type == "fixed" ? cookies.load("defaultCurrency") : "%",
        discountSymbol: cookies.load("defaultCurrency"),
        discountAmount: currencyValue(data.discount, false),
        discount: (
          (currencyValue(data.discount, false) * 100.0) /
          currencyValue(data.total, false)
        ).toFixed(1),
        saleType: data.pricing,
        customerAddress: data.customer ? data.customer.address : "",
        location: data.location ? data.customer.address : "",
        customerEmail: data.customer ? data.customer.email : "",
        customerPhone: data.customer ? data.customer.phone_number : "",
        total: Number(currencyValue(data.total, false)),
        subTotal: currencyValue(data.sub_total, false),
        taxPercentage:
          (currencyValue(data.total_tax, true) * 100.0) /
          currencyValue(data.sub_total, true),

        description: data.description,
        _id: data._id,
        saleItems: $.map(data.sales_items, (item) => {
          return {
            quantity: item.quantity,
            sellable_units: item.sellable_units,
            number: item.number,
            type: item.not_stock ? "product" : "stock",
            category: item.category,
            item: item.name,
            unit_price: Number(currencyValue(item.unit_price, false)),
            wholesale_price: Number(currencyValue(item.wholesale_price, false)),
            total: Number(currencyValue(item.total, false)),
          };
        }),
        sell_type: [
          { label: getString().retail, value: "retail" },
          { label: getString().wholesale, value: "whole sale" },
        ],

        sell_discount: [
          { label: getString().fixed, value: "fixed" },
          { label: getString().percentage, value: "percentage" },
        ],
        header: [
          {
            title: getString().product,
            field: "item",
            disableClick: true,
            editable: "never",
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },
          },
          {
            title: getString().quantity,
            field: "quantity",
            editable: "onUpdate",
            render: (rowData) => numberFormat(rowData.quantity),
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },
          },
          {
            title: getString().metric_units,
            field: "metric_units",
            hidden: true,
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },
          },
          {
            title: getString().sellable_units,
            field: "sellable_units",
            editable: "onUpdate",
            render: (rowData) => numberFormat(rowData.sellable_units),
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },

            // title: getString().sellable_units, field: 'sellable_units', hidden: true, render: rowData => {
            //     return (rowData.sellable_units) ? numberFormat(rowData.sellable_units) : ""
            // }, cellStyle: {padding: '1px'}, headerStyle: {padding: '1px'}
          },

          {
            title: getString().unit_price,
            field: "unit_price",
            disableClick: true,
            editable: "never",
            render: (rowData) => currencyFormat(rowData.unit_price),
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },
          },
          {
            title: getString().wholesale_price,
            field: "wholesale_price",
            disableClick: true,
            editable: "never",
            render: (rowData) => currencyFormat(rowData.wholesale_price),
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },
          },
          {
            title: getString().total,
            field: "total",
            disableClick: true,
            editable: "never",
            render: (rowData) => currencyFormat(rowData.total),
            cellStyle: { padding: "1px" },
            headerStyle: { padding: "1px" },
          },
        ],
        itemQuantity: 1,
        cash_visible: false,
        invoice_visible: false,
        toPrintData: [],
        toPrintCharges: {},
        sale_id: "",
        itemWithOpts: { name: "", number: "", type: "", possible_opts: [] },
        loading: false,
      };
    } else {
      return undefined;
    }
  };

  getMetric = (rowData) => {
    if (rowData.type !== "stock") {
      return "";
    }
    return "(" + rowData.metric + ")";
  };

  state = this.getStateFromProps() ||
    this.props.state || {
      payMethod: "cash",
      value: "fixed",
      bank: "",
      bank_no: "",
      cheque_no: "",
      cash_visible: false,
      invoice_visible: false,
      description: "",
      exitPrint: false,
      quote_visible: false,
      quoteSale: null,
      quoteItems: null,
      payment_option: "cash",
      products: [],
      activePayment: null,
      sell_type: [
        { label: getString().retail, value: "retail" },
        { label: getString().wholesale, value: "whole sale" },
      ],
      date: "",

      sell_discount: [
        { label: getString().fixed, value: "fixed" },
        { label: getString().percentage, value: "percentage" },
      ],

      saleItems: [],
      stockData: null,
      newData: null,
      productData: null,

      itemQuantity: 1,
      selectedItem: "",
      selectedCustomer: "",

      cashAmount: "",
      sellPrintDetails: {},
      saleType: "retail",
      discountType: "fixed",
      wholesale: false,

      total: 0.0,
      taxDue: 0.0,
      subTotal: 0.0,
      change: 0.0,
      taxPlaceholder: 0,
      printerType: cookies.load("printer_type"),
      taxPercentage: cookies.load("tax_percentage"),
      taxType: "percentage", //can also be "fixed amount"
      customerId: this.props.customer ? this.props.customer._id.$oid : "",
      customerName: this.props.customer ? this.props.customer.name : "",
      customerEmail: this.props.customer ? this.props.customer.email : "",
      customerPhone: this.props.customer
        ? this.props.customer.phone_number
        : "",
      customerAddress: this.props.customer ? this.props.customer.address : "",
      saletype: "",
      discountAmount: 0,
      discountSymbol: cookies.load("defaultCurrency"),
      discountTypeSymbol: "",
      discount: 0,

      toPrintData: [],
      toPrintCharges: {},
      editQty: false,
      currentRow: null,
      editQtyValue: 0,
      tempRowData: null,
      sendEmail: false,
      subject: "",
      recipient: "",
      emailContent: null,
      sale_id: "",
      newProduct: "",
      options: [],
      customers: [], // initialist customers
      back: false,

      itemWithOpts: { name: "", number: "", type: "", possible_opts: [] },
      selectOptions: false,

      callerName: this.props.callerName,
      callerState: this.props.callerState,
      callerProps: this.props.callerProps,
      sale_items: [],
      amount_received: "",

      paymentRodalHeight: 595,
      loading: true,
    };

  // function to search for existence of an item
  getByValue = (arr, value, metric, options) => {
    let matchOpts = function(options1, options2) {
      let keys1 = Object.keys(options1).sort();
      let keys2 = Object.keys(options2).sort();

      if (keys1.length != keys2.length) return false;

      for (let i = 0; i < keys1.length; i++) {
        if (keys1[i] !== keys2[i]) return false;
        if (options1[keys1[i]] !== options2[keys2[i]]) {
          return false;
        }
      }
      return true;
    };

    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].item === value &&
        arr[i].metric == metric &&
        matchOpts(arr[i].options, options)
      ) {
        return i;
      }
    }
    return null;
  };

  closePrint = () => {
    let items = this.state.saleItems;
    items.splice(0, items.length);

    this.setState({
      exitPrint: false,
      activePayment: null,
      saleItems: items,
    });
  };

  updateSales = (data, discount, tax) => {
    let subTotal = 0;
    for (let i = 0; i < data.length; i++) {
      subTotal += data[i].total;
    }
    if (!tax && tax !== 0) {
      tax = (parseFloat(this.state.taxPercentage) / 100.0) * subTotal;
    }
    let total = Number(subTotal) + Number(tax);
    let dis =
      this.state.discountType === "fixed"
        ? discount
        : (parseFloat(discount) / 100.0) * total;
    total = total - dis;
    this.setState({
      saleItems: data,
      taxDue: tax,
      total: total,
      subTotal: subTotal,
      discountAmount: dis,
    });
  };

  determineItem = (opt) => {
    if (opt.has_options) {
      $.ajax({
        method: "GET",
        url: baseUrl + "/afrijula/sales/sales/sellable",
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        data: { number: opt.number, type: opt.type, options_only: true },
        success: function(result) {
          opt.possible_opts = result;
          this.setState({ selectOptions: true, itemWithOpts: opt });
        }.bind(this),
      });
    } else {
      this.getItem(opt);
    }
  };

  getItemWithOpts = () => {
    let option = null;
    let opts = this.state.itemWithOpts;

    if (opts.option === null) {
      alert("Please select at least one option to proceed.");
      return;
    }
    for (let i = 0; i < opts.possible_opts.length; i++) {
      if (opts.possible_opts[i]._id.$oid == opts.option) {
        option = opts.possible_opts[i];
        break;
      }
    }
    opts.price = option.prioce;
    opts.options = option.options;
    this.getItem(opts);
  };

  // get Select Item and put it in the table - for first table that is the SellTable- V1
  getItem = (opt, fromAddForm = false) => {
    //#TODO sbensouda: we need to pass in quantity if working form. user should be able to specify quantity they want.
    //#for now the condition below take the same value in either case, however the second option should be the opt.quantity.
    //#there is not field for it at the moment in the Add_Stock_Product.jsx file
    let qty = fromAddForm ? opt.quantity : Number(this.state.itemQuantity);
    let selectedItem = opt;
    let unitprice =
      selectedItem.unit_price == null
        ? 0
        : Number(currencyValue(selectedItem.unit_price, false));
    let wholesaleprice =
      selectedItem.wholesale_price == null
        ? 0
        : Number(currencyValue(selectedItem.wholesale_price, false));
    let type = selectedItem.type;

    let sellable_units = selectedItem.sellable_units;
    let wholesale_metric = selectedItem.wholesale_metric;
    let retail_metric = selectedItem.retail_metric;

    let newData = this.state.saleItems;
    let total = this.state.total;
    let metric = "retail";
    let metric_unit;
    if (
      type === "stock" &&
      this.state.saleType === "whole sale" &&
      wholesale_metric != retail_metric
    ) {
      unitprice = unitprice * sellable_units;
      metric = this.state.saleType;
    }
    metric_unit = metric === "whole sale" ? wholesale_metric : retail_metric;
    let exist = this.getByValue(newData, (fromAddForm) ? opt.name : opt.label, metric, opt.options);

    if (newData.length === 0 || exist == null) {
      if (this.state.saleType === "whole sale")
        total = sellable_units * wholesaleprice;
      else total = qty * unitprice;

      const name = fromAddForm ? opt.name : opt.label;

      newData.push({
        item: name,
        number: selectedItem.number,
        quantity: qty,
        sellable_units: sellable_units,
        metric_units: metric_unit,
        wholesale_metric: wholesale_metric,
        unit_price: unitprice,
        wholesale_price: wholesaleprice,
        total: total,
        category: opt.category,
        options: opt.options,
        type: type,
        billable: opt.billable,
        metric: metric,
        sellingPrice: unitprice,
      });
    } else {
      if (this.state.saleType === "whole sale") {
        newData[exist].sellable_units += sellable_units;
        newData[exist].total = newData[exist].sellable_units * wholesaleprice;
      } else {
        newData[exist].quantity += qty;
        newData[exist].total = newData[exist].quantity * unitprice;
      }
    }
    let discount = this.state.discount;

    this.updateSales(this.state.saleItems, discount);
    //    after we update sale we refocus
    // this.autoFocus();
  };

  customerOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/sales/customers?attrs=["name","id","address","phone_number","email"]&model=Afrijula::Sales::Customer&to=name&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;

        for (; i < json.length; i++) {
          opts[i] = {
            label: json[i].name,
            value: json[i]._id.$oid,
            address: json[i].address,
            email: json[i].email,
            phone_number: json[i].phone_number,
          };
        }
        return opts;
      }) // my option list array?
      .catch((err) => {});
  };

  loadLocations = () => {
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/locations/location",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        attrs: ["name", "_id", "is_default", "address"],
        order: [{ by: "asc", attr: "name" }],
        model: "Afrijula::Locations::Location",
      },
      success: function(result) {
        let defaultExist = false;
        result.map((loc) => {
          if (loc.is_default === true) {
            defaultExist = true;
          }
        });
        if (defaultExist === false) {
          displayError(getString().noDefaultLocation);
        }
      }.bind(this),
    });
  };

  deleteItem = (event, row) => {
    let arr = this.state.saleItems;

    // I think this should be removed for it was throwing an error of not finding "row", since the item has already been deleted in the child component
    // let index = arr.findIndex(x => x.item === row.item);
    // arr.splice(index,1);

    this.setState({ saleItems: arr });
    let discount = this.state.discount;

    this.updateSales(this.state.saleItems, discount);
    //    after we update sale we refocus
    // this.autoFocus();
  };

  handleChange = (e) => {
    // let tax = 0;
    let tax = this.state.taxPlaceholder;
    let discount = 0;
    let change = false;

    if (e.target.name == "editQtyValue") {
      if (e.target.value < 0) {
        displayError("You may not enter negative value");
        return;
      }
    }
    if (e.target.name === "whole sale") {
      this.setState({
        wholesale: true,
      });
    }

    if (e.target.name === "cashAmount") {
      let amountEntered = e.target.value;
      let changeAmount = Number(amountEntered) - Number(this.getTotal());
      if (!amountEntered) changeAmount = 0.0;
      this.setState({ change: changeAmount, cashAmount: e.target.value });
    } else if (e.target.name === "discount") {
      discount = Number(e.target.value);
      if (discount < 0) {
        return;
      }
      this.setState({
        discount: e.target.value,
      });
      change = true;
    } else if (e.target.name === "taxPercentage") {
      discount = this.state.discount;
      let t = Number(e.target.value);
      if (t < 0) {
        return;
      }
      if (t > 0) {
        tax = (t / 100) * this.state.subTotal;
      }

      this.setState({
        taxPercentage: e.target.value,
        taxPlaceholder: tax,
      });
      change = true;
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    if (change) this.updateSales(this.state.saleItems, discount, tax);
  };

  autoFocus = () => {
    if (this.prodRef) this.prodRef.focus();
  };

  handleDiscountChange = (opt) => {
    this.setState({ discountType: opt.value });
    if (opt.value === "fixed")
      this.setState({ discountTypeSymbol: cookies.load("defaultCurrency") });
    else this.setState({ discountTypeSymbol: "%" });
  };

  addProduct = (inputValue) => {
    this.setState({
      newProduct: inputValue,
      addProduct: true,
    });
  };

  getTotal = () => {
    return this.state.total;
  };
  getSalesItems = () => {
    return this.state.saleItems;
  }

  hasBillable = () => {
    for (let i = 0; i < this.state.saleItems.length; i++) {
      if (this.state.saleItems[i].billable == true) return true;
    }
    return false;
  };

  editQuantity = (event, rowData) => {
    let _data = rowData;
    let qty = _data.quantity;

    this.setState({
      currentRow: _data,
      editQtyValue: qty,
      editQty: true,
      tempRowData: rowData,
    });
  };

  closeEditQty = (event, rowData) => {
    this.setState({
      editQty: false,
    });
  };

  upQty = (action, rowData) => {
    let newQuantity = Number(rowData.quantity) + 1;
    let newSellable = Number(this.state.saleType) + 1;
    if (this.state.saleType === "retail") this.saveQty(newQuantity, rowData);
    else this.saveQty(newSellable, rowData);
  };

  downQty = (action, rowData) => {
    let newQuantity = Number(rowData.quantity) - 1;
    let newSellable = Number(this.state.saleType) - 1;
    if (this.state.saleType === "retail") {
      if (newQuantity <= 0) return;
      this.saveQty(newQuantity, rowData);
    } else {
      if (newSellable <= 0) return;
      this.saveQty(newSellable, rowData);
    }
  };

  saveQty = (newQuantity, rowData) => {
    let items = this.state.saleItems;
    let item = items[rowData.tableData.id];
    item.quantity = newQuantity;
    item.sellable_units = newQuantity;
    if (this.state.saleType === "whole sale") {
      item.total = newQuantity * Number(item.wholesale_price);
    } else item.total = newQuantity * Number(item.unit_price);
    items[rowData.tableData.id] = item;
    this.setState({ saleItems: items });
    let discount = this.state.discount;

    this.updateSales(this.state.saleItems, discount);
    //    after we update sale we refocus
    // this.autoFocus();
  };

  saveEditQty = () => {
    let _data = this.state.currentRow;
    const _qty = this.state.editQtyValue;

    this.saveQty(_qty, _data);
    this.setState({ editQty: false });
  };

  handleCustomer = (opt) => {
    if (opt !== null) {
      this.setState({
        customerName: opt.label,
        customerAddress: opt.address,
        customerEmail: opt.email,
        customerPhone: opt.phone_number,
      });
    } else {
      this.setState({
        customerName: "",
        customerAddress: "",
        customerEmail: "",
        customerPhone: "",
      });
    }
  };

  saveProductCback = (data, type) => {
    // this.autoFocus.bind(this);
    if (data) this.getItem(data, true);
  };

  editQtyUpdate = (_data, qty, editBool, rowDataVal) => {
    this.setState({
      currentRow: _data,
      editQtyValue: qty,
      editQty: editBool,
      tempRowData: rowDataVal,
    });
  };

  notEditing = () => {
    this.setState({
      editQty: false,
    });
  };

  updateHeight = (h) => {
    this.setState({ paymentRodalHeight: h });
  };

  updateMe = (paymentOption) => {
    paymentOption === "cash" ? this.updateHeight(595) : this.updateHeight(680);
  };

  render() {
    const { classes } = this.getProps();
    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      return (
        <ChildComponent
          state={this.state.callerState}
          returningProps={this.state.callerProps}
        />
      );
    }
    if (this.state.addProduct) {
      return (
        <Add_Stock_Product
          noBack={true}
          productName={this.state.newProduct}
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Sell}
          editProduct={false}
          saveProductCback={this.saveProductCback.bind(this)}
        />
      );
    } else {
      return (
        <div style={{ marginTop: -45 }}>
          {this.props.customer ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="danger"
                  onClick={() => {
                    this.setState({ back: true });
                  }}
                  style={{
                    backgroundColor: "#f44336",
                    margin: ".3125rem 1px",
                    float: "right",
                    minWidth: "auto",
                    minHeight: "auto",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    color: "#ffffff",
                  }}
                >
                  {getString().back}
                </Button>
              </GridItem>
            </GridContainer>
          ) : null}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4>{getString().sell}</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={9} spacing={2}>
                      <form autoComplete="on">
                        <GridContainer>
                          <GridItem xs={12} sm={4} md={4}>
                            <b>{getString().type}: </b>
                            <Select
                              options={this.state.sell_type}
                              defaultValue={this.state.sell_type[0]}
                              onChange={(opt) => {
                                this.setState({ saleType: opt.value });
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <b>{getString().discount_type}: </b>
                            <div>
                              <span
                                style={{ display: "inline-block", width: 130 }}
                              >
                                <Select
                                  options={this.state.sell_discount}
                                  label={getString().discount_type}
                                  defaultValue={this.state.sell_discount[0]}
                                  onChange={(opt) => {
                                    this.handleDiscountChange(opt);
                                  }}
                                />
                              </span>
                              <span style={{ display: "inline-block" }}>
                                <HelpUtil reference={"sell_discount"} />
                              </span>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={4} md={4}>
                            <b>{getString().date}:</b>
                            <br />
                            {this.state.accountType === "Cashier" ? (
                              <TextField
                                required
                                style={{ width: "150px", margin: 0 }}
                                id="date"
                                name="date"
                                title={getString().date}
                                className={classes.textField}
                                value={this.state.date}
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                type="text"
                                margin="dense"
                                variant="outlined"
                                disabled={true}
                              />
                            ) : (
                              <TextField
                                required
                                style={{ width: "150px", margin: 0 }}
                                id="date"
                                name="date"
                                title={getString().date}
                                className={classes.textField}
                                value={this.state.date}
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                type="date"
                                margin="dense"
                                variant="outlined"
                              />
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <div className={classes.productSelector}>
                              <Card style={{ zIndex: 30, margin: 2 }}>
                                <Creatable
                                  onChange={(opt) => {
                                    this.determineItem(opt);
                                  }}
                                  onCreateOption={(e) => [this.addProduct(e)]}
                                  options={this.state.options}
                                />
                              </Card>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <br />

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <SellTableTemplate
                            Items={this.state.saleItems}
                            updateSales={this.updateSales.bind(this)}
                            upItemsAfterDelete={this.deleteItem.bind(this)}
                            saveQty={this.saveQty.bind(this)}
                            discount={this.state.discount}
                            editQtyUpdate={this.editQtyUpdate.bind(this)}
                            editing={this.notEditing}
                            type={this.state.saleType}
                          />
                        </GridItem>
                      </GridContainer>

                      <br />

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            xs={12}
                            sm={12}
                            md={4}
                            disabled={this.hasBillable()}
                            color="primary"
                            onClick={this.showCash.bind(this)}
                            style={{
                              backgroundColor: "#9c27b0",
                              margin: ".3125rem 1px",
                              minWidth: "auto",
                              minHeight: "auto",
                              fontSize: "12px",
                              color: "#ffffff",
                              padding: "12px 30px",
                              borderRadius: "3px",
                              verticalAlign: "middle",
                            }}
                          >
                            {getString().pay}
                          </Button>
                          <HelpUtil reference={"sell_pay"} />
                          <Button
                            disabled={this.hasBillable()}
                            xs={12}
                            sm={12}
                            md={4}
                            color="warning"
                            onClick={this.showInvoice.bind(this)}
                            style={{
                              backgroundColor: "#ff9800",
                              margin: ".3125rem 1px",
                              minWidth: "auto",
                              minHeight: "auto",
                              fontSize: "12px",
                              color: "#ffffff",
                              padding: "12px 30px",
                              borderRadius: "3px",
                              verticalAlign: "middle",
                            }}
                          >
                            {getString().invoice}
                          </Button>
                          <HelpUtil reference={"sell_invoice"} />
                          <Button
                            disabled={
                              applyPackaging("quotations") || this.hasBillable()
                            }
                            xs={12}
                            sm={12}
                            md={4}
                            color="success"
                            onClick={() => {
                              this.saveSale(null, null, null, null, "quote");
                            }}
                            style={{
                              backgroundColor: "#4caf50",
                              margin: ".3125rem 1px",
                              minWidth: "auto",
                              minHeight: "auto",
                              fontSize: "12px",
                              color: "#ffffff",
                              padding: "12px 30px",
                              borderRadius: "3px",
                              verticalAlign: "middle",
                            }}
                          >
                            {getString().quote}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={4}
                      md={3}
                      style={{ background: "#ccc" }}
                    >
                      <form autoComplete="on">
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <span>{getString().select_customer}</span>
                            <Creatable
                              defaultOptions
                              placeholder={getString().select_customer}
                              name="customer"
                              id="customer"
                              isClearable
                              onChange={(opt) => {
                                this.handleCustomer(opt);
                              }}
                              options={this.state.customers}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              id="adr"
                              name="customerAddress"
                              type="text"
                              value={this.state.customerAddress}
                              label={getString().address}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              id="phone"
                              name="customerPhone"
                              type="text"
                              value={this.state.customerPhone}
                              label={getString().phone}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              id="email"
                              name="customerEmail"
                              type="email"
                              value={this.state.customerEmail}
                              label={getString().email}
                              style={{ marginBottom: 10 }}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <b>{getString().sub_total}</b>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              type="text"
                              disabled={true}
                              name="subTotal"
                              id="subtotal"
                              defaultValue="0"
                              value={currencyFormat(this.state.subTotal)}
                              style={{
                                marginBottom: 3,
                                border: 0,
                                fontWeight: "bold",
                              }}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <b>{getString().tax_percentage}:</b>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              type="number"
                              name="taxPercentage"
                              inputProps={{ min: 0 }}
                              value={this.state.taxPercentage}
                              style={{
                                marginBottom: 3,
                                border: 0,
                                fontWeight: "bold",
                              }}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <b>{getString().tax_amount}:</b>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              type="number"
                              name="taxDue"
                              disabled={true}
                              value={this.state.taxDue}
                              style={{
                                marginBottom: 3,
                                border: 0,
                                fontWeight: "bold",
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <b>
                              {getString().discount +
                                " " +
                                this.state.discountTypeSymbol}
                              :
                            </b>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              type="number"
                              name="discount"
                              id="discount"
                              value={this.state.discount}
                              inputProps={{ min: "0" }}
                              style={{
                                marginBottom: 3,
                                border: 0,
                                fontWeight: "bold",
                                width: 110,
                                marginRight: 10,
                              }}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              type="number"
                              name="discountAmount"
                              disabled={true}
                              id="discount"
                              label={this.state.discountSymbol}
                              value={this.state.discountAmount}
                              style={{
                                marginBottom: 3,
                                border: 0,
                                fontWeight: "bold",
                                width: 110,
                                marginRight: 10,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <b>{getString().total}</b>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              type="text"
                              disabled={true}
                              name="total"
                              id="total"
                              defaultValue="0"
                              value={currencyFormat(this.state.total)}
                              style={{
                                marginBottom: 3,
                                border: 0,
                                fontWeight: "bold",
                              }}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {this.state.loading == true && (
            <>
              <div
                id="show-spinner"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "35%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "10000000",
                  display: "none",
                }}
              >
                <img src={CustomLoaderGif} width="100px" height="100px" />
              </div>
              <div
                style={{
                  display: "none",
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  zIndex: "10000003",
                  opacity: 0.4,
                  top: 0,
                }}
                id="cast"
              />
            </>
          )}

          <Rodal
            visible={this.state.cash_visible}
            height={550}
            width={300}
            showMask={false}
            onClose={this.hideCash.bind(this)}
          >
            <div>
              {/*caller is either from the sell page or from invoice or contract. we use this to show or hide the change field when recording payment:= specifically #cash payment*/}
              <GenericPayment
                onSave={this.saveOneTime.bind(this)}
                onClose={this.hideCash.bind(this)}
                totalAmount={this.getTotal.bind(this)}
                caller="sell"
              />
            </div>
          </Rodal>
          <Rodal
            visible={this.state.invoice_visible}
            height={600}
            width={"auto"}
            showMask={false}
            onClose={this.hideInvoice.bind(this)}
          >
            <div
              style={{
                height: "100%",
                "overflowY": "auto",
              }}
            >
              <InvButton
                getTotal={this.getTotal}
                closeInvoice={this.hideInvoice.bind(this)}
                saveSale={this.saveSale.bind(this)}
              />
            </div>
          </Rodal>
          <Rodal
            visible={this.state.quote_visible}
            height={520}
            width={"auto"}
            showMask={false}
            onClose={this.closePrintQuote.bind(this)}
            customStyles={{
              marginTop: $(document).height() === 1366 ? 95 : 60,
            }}
          >
            <div>
              {this.state.quoteSale ? (
                <QuoteReceipt
                  quoteSale={this.state.quoteSale}
                  quoteItems={this.state.quoteItems}
                  customer={{
                    name: this.state.customerName,
                    address: this.state.customerAddress,
                    email: this.state.customerEmail,
                    customer_phone: this.state.customerPhone,
                  }}
                  closePrintQuote={this.closePrintQuote.bind(this)}
                />
              ) : (
                <div />
              )}
            </div>
          </Rodal>
          {this.state.activePayment ? (
            <WindowSizeListener
              onResize={(size) => this.setState({ winSize: size.windowWidth })}
            >
              {this.state.winSize >= 801 ? (
                <div style={{ position: "fixed", zIndex: 100000 }}>
                  <Rodal
                    visible={this.state.exitPrint}
                    height={$(document).width() <= 1366 ? 780 : 620}
                    width={$(document).width() <= 1366 ? 700 : 800}
                    showMask={false}
                    onClose={this.closePrint.bind(this)}
                    customStyles={{ marginTop: 120 }}
                  >
                    <div>
                      {" "}
                      <Receipt
                        items={this.state.saleItems}
                        toPrintData = {this.state.toPrintData}
                        autoSend={true}
                        customer={{
                          name: this.state.customerName,
                          address: this.state.customerAddress,
                          email: this.state.customerEmail,
                          customer_phone: this.state.customerPhone,
                        }}
                        discount={this.state.discount}
                        total={this.state.total}
                        tax={this.state.taxDue}
                        subtotal={this.state.subTotal}
                        description={this.state.description}
                        payment={this.state.activePayment}
                        invoice={{ number: "n/a" }}
                        closePrint={this.closePrint.bind(this)}
                      />
                    </div>
                  </Rodal>
                </div>
              ) : (
                <MobileReceipt
                  items={this.state.saleItems}
                  autoSend={true}
                  customer={{
                    name: this.state.customerName,
                    address: this.state.customerAddress,
                    email: this.state.customerEmail,
                    customer_phone: this.state.customerPhone,
                  }}
                  discount={this.state.discount}
                  total={this.state.total}
                  tax={this.state.taxDue}
                  subtotal={this.state.subTotal}
                  description={this.state.description}
                  payment={this.state.activePayment}
                  invoice={{ number: "n/a" }}
                  closePrint={this.closePrint.bind(this)}
                />
              )}
            </WindowSizeListener>
          ) : (
            <div />
          )}
          <Rodal
            visible={this.state.editQty}
            showMask={false}
            onClose={this.closeEditQty.bind(this)}
          >
            <div>
              <h2>{getString().editQty}</h2>
              <form autoComplete="on">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      type="number"
                      name="editQtyValue"
                      id="editQtyValue"
                      label={getString().quantity}
                      value={this.state.editQtyValue}
                      style={{
                        border: 0,
                      }}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                  </GridItem>
                  {this.state.currentRow &&
                  this.state.currentRow.wholesale_metric &&
                  this.state.currentRow.retail_metric ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ marginTop: -15 + "px" }}
                    >
                      <RadioGroup
                        style={{
                          width: "auto",
                          height: "auto",
                          display: "flex",
                          flexWrap: "nowrap",
                          flexDirection: "row",
                          color: "info",
                        }}
                        aria-label="Enable Notification"
                        name="saleType"
                        className={classes.group}
                        value={this.state.saleType}
                        onChange={(e) => this.handleChange(e)}
                        row={true}
                      >
                        <FormControlLabel
                          value="retail"
                          control={<Radio color="primary" />}
                          label={
                            this.state.currentRow
                              ? this.state.currentRow.retail_metric
                              : ""
                          }
                        />
                        <FormControlLabel
                          value="whole sale"
                          control={<Radio color="primary" />}
                          label={
                            this.state.currentRow
                              ? this.state.currentRow.wholesale_metric
                              : ""
                          }
                        />
                      </RadioGroup>
                    </GridItem>
                  ) : null}
                  <Button color="danger" onClick={this.closeEditQty.bind(this)}>
                    {getString().cancel}
                  </Button>
                  <Button color="success" onClick={this.saveEditQty.bind(this)}>
                    {getString().save_settings}
                  </Button>
                </GridContainer>
              </form>
            </div>
          </Rodal>
          <Dialog
            open={this.state.selectOptions}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Select Options for " + this.state.itemWithOpts.label}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <RadioGroup
                  row
                  value={this.state.settlementType}
                  name="globalFilter"
                  onChange={(e) => {
                    let item = this.state.itemWithOpts;
                    item.option = e.target.value;
                  }}
                >
                  {this.state.itemWithOpts.possible_opts.map((item) => {
                    let keys = Object.keys(item.options);
                    return (
                      <div style={{ display: "block", paddingRight: "1.5rem" }}>
                        <FormControlLabel
                          style={{ marginRight: "1px" }}
                          label={""}
                          value={item._id.$oid}
                          control={<Radio />}
                        />
                        <span
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <strong>
                            {keys.map((key) => {
                              return (
                                <div>{key + ": " + item.options[key]}</div>
                              );
                            })}
                          </strong>
                        </span>
                        <span>
                          <strong>{currencyFormat(item.price)}</strong>
                        </span>
                      </div>
                    );
                  })}
                </RadioGroup>
              </DialogContentText>
              <DialogActions>
                <div>
                  <Button
                    color="danger"
                    autoFocus
                    onClick={() => {
                      this.setState({ selectOptions: false });
                    }}
                  >
                    {getString().close}
                  </Button>

                  <Button
                    autoFocus
                    onClick={() => {
                      this.getItemWithOpts();
                    }}
                    color="primary"
                  >
                    {getString().select}
                  </Button>
                </div>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
      );
    }
  }
}

Sell.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Sell);
