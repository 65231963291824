import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StepZilla from "react-stepzilla";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import {numberFormat, currencyFormat, dateFormat, compileQuery,displayError,displaySuccess,getString} from "assets/js/utils/utils.js";
import Account from "components/afrijula/Wizard/Wizard_Update_Account.jsx";
import Location from "components/afrijula/Wizard/Wizard_Location.jsx";
import Users from "components/afrijula/Wizard/Wizard_Add_Users.jsx";
import Stocks from "components/afrijula/Wizard/Wizard_New_Stock.jsx";

import Complete from "components/afrijula/Wizard/Wizard_Complete.jsx";
import 'react-stepzilla/src/css/main.css';
import Rodal from "rodal";
import cookies from "react-cookies";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class Wizard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            wizard_visible: true,
            stepsList: this.props.steps,
            steps: [],
            organization: {
                country: 'Gambia',
                phone: '',
                package: 'basic',
                tax_percentage: '',
                stock_level: '',
                printerValue: "A4"

            },
        };
        this.setupWizard();


    }


    hideWizard = () => {
        this.setState({wizard_visible:false})
    };



    setupWizard = () =>{
        let list = this.state.stepsList;
        let steps = this.state.steps;


        list.forEach((item) => {
            switch(item.name) {

                case "organization":
                        steps.push({name: "Organization",postData: this.postAccount, component: <Account  wizardData={this.state.organization} updateState={(d) => {this.setState(d);}}/>  });
                    break;
                case "location":
                        steps.push({name: "Location",component: <Location /> });
                    break;
                case "users":
                        steps.push({name: "Users", component: <Users />});
                    break;
                case "stocks":
                        steps.push({name: "Stocks", component: <Stocks />});
                    break;
            }
        });
        steps.push({name: "Complete", component: <Complete onClose={this.hideWizard} />})
        this.setState({steps: steps});
    };



    postAccount = () => {

        if(!this.state.organization.package)
            alert("You must choose a valid package");
        if(!this.state.organization.phone)
            alert("You must specify a valid phone number");
        if(!this.state.organization.country)
            alert ("You must choose a valid country");

        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/account/0",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: { "contact_number": this.state.organization.phone, package:this.state.organization.package, country:this.state.organization.country,
                tax_percentage: this.state.organization.tax_percentage,stock_level: this.state.organization.stock_level,printer_type: this.state.organization.printerValue},

            success: function (data){
                displaySuccess(data.success);

                cookies.save('tax_percentage',_this.state.organization.tax_percentage, {path:'/'});
                cookies.save('printer_type',_this.state.organization.printer_type, {path:'/'});
            }
        });
    }

    onStepChange = (step) => {
        let st = this.state.steps[step-1];
         if(st && st.postData) {
            st.postData();
        }
    }

    componentDidMount() {


    }

    render() {
        const { classes } = this.props;
        if(this.state.steps.length <= 1) {
            return null;
        }else{return (
                <div className='example'>
                    <Rodal visible={this.state.wizard_visible} height={95 + "%"} width={95 + "%"} showMask={false}
                           onClose={this.hideWizard.bind(this)}>
                        <div className='step-progress' style={{
                            marginBottom: 10,
                            border: 0,
                            width: 80 + "%",
                            marginLeft: 15 + "%",
                            'max-height': 'calc(100vh - 20px)',
                            'overflow-y': 'auto'
                        }}>
                            <h3>Afrijula Setup Wizard</h3>
                            <h5>Please complete the wizard to setup your account.</h5>

                            <StepZilla steps={this.state.steps} onStepChange={this.onStepChange}
                                       preventEnterSubmission={true} dontValidate={true}
                                       nextTextOnFinalActionStep="Complete" hocValidationAppliedTo={[0, 1, 2, 3]}/>
                        </div>
                    </Rodal>
                </div>

            );
        }
    }
}

Wizard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Wizard);
