import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {getString} from  "assets/js/utils/utils.js";
import etranzactImg from "assets/img/logos/etranzact.jpg";
import GlobalPayImg from "assets/img/logos/Globalpay logo.gif";
import ISW_newImg from "assets/img/logos/ISW_new.png";
import VisaImg from "assets/img/logos/logo_visa.gif";
import MCImg from "assets/img/logos/MC_new.png";
import VerveImg from "assets/img/logos/Verve_new.png";
import backOfCard from 'assets/img/back_of_card.jpg'
import GridContainer from "components/common/Grid/GridContainer";
import GridItem from "components/common/Grid/GridItem";
import TextField from "@material-ui/core/TextField/TextField";
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from '@material-ui/core/MenuItem';

const styles = {
    root: {

    },
    image:{width: 80, height:50}
};

class VoucherPay extends React.Component {


    state = {
        back: false,

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
    };

    handleChange = (event) => {
        this.props.setValue(event.target.name, event.target.value, 'voucher')
    };

    render() {
        const { classes } = this.props;

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let _callerState = this.state.callerState;
            return <ChildComponent state = {_callerState} returningProps = {this.state.callerProps}/>
        }else {
            return (
                <div className={classes.root}>
                    <GridContainer>
                        <GridItem xs={6} sm={9}>
                            <table>
                                <tr>
                                    <td>
                                        <TextField
                                            required
                                            id="voucher_number"
                                            name="voucherNumber"
                                            inputProps={{style:{padding:"3px",height:"2em",minWidth:"100%"}}}
                                            value={this.props.getValue('voucherNumber')}
                                            label={getString().voucher_number}
                                            onChange={(e) => {this.handleChange(e)}}
                                            margin="dense"
                                            variant="outlined"/>
                                    </td>
                                </tr>
                            </table>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

VoucherPay.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VoucherPay);
