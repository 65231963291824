import React from "react";
import "./style.css";

const FourOOne = (props) => {
  return (
    <div className="disabledUI">
      <h1 className="disabledUI__header">{props.text}</h1>
    </div>
  );
};

export default FourOOne;
