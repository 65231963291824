import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import {getString} from  "assets/js/utils/utils.js";

import Stock from "components/afrijula/Stocks/Stock.jsx";
import Product from "components/afrijula/Products/Products.jsx";

function TabContainer1(props) {
    return (
        <Typography component={Stock} style={{ padding: 8 * 3 }} updateMe={props.updateAppBar} />
    );
}

function TabContainer2(props) {
    return (
        <Typography component={Product} style={{ padding: 8 * 3 }} updateMe={props.updateAppBar} />
    );
}


TabContainer1.propTypes = {
    children: PropTypes.node.isRequired,
};
TabContainer2.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class Settings extends React.Component {

   
    state = {
        value: 0,
        appBar:true
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    updateAppBar = (toggle) => {
        this.setState({appBar:toggle});
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <div className={classes.root} style={{marginTop:'-30px' }}>
                <AppBar  position="static" style={{zIndex: 100, display: (this.state.appBar) ? 'flex' : 'none', backgroundColor: '#9c27b0', color: '#fff',marginBottom:'40px' }}>
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label={getString().inventory} />
                        <Tab label={getString().products} />
                    </Tabs>
                </AppBar>
                {value === 0 && <TabContainer1 updateAppBar={this.updateAppBar.bind(this)}></TabContainer1>}
                {value === 1 && <TabContainer2 updateAppBar={this.updateAppBar.bind(this)}></TabContainer2>}
            </div>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
