import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {
  getString,
  currencyFormat,
  dateFormat,
  compileQuery,
  displaySuccess,
  displayError,
  currencyValue,
  getToday,
} from "assets/js/utils/utils.js";
import { getLocale } from "../../../assets/js/utils/utils";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class ReconcileAccount extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    back: false,

    header: [
      {
        title: getString().date,
        field: "date",
        render: (rowData) => dateFormat(rowData.date),
      },
      { title: getString().category, field: "category", defaultGroupOrder: 0 },
      { title: getString().location, field: "location" },
      { title: getString().purpose, field: "purpose" },
      {
        title: getString().amount,
        field: "amount",
        render: (rowData) => currencyFormat(rowData.amount),
      },
    ],
    callerName: this.props.callerName,
    callerProps: this.props.callerProps,
    callerState: this.props.callerState,

    accountData: this.props.accountData,

    // this.tableRef.current.onQueryChange(); reload table after reconciling
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  close = () => {
    this.setState({ back: true });
  };

  loadData = (query, resolve, reject) => {
    let filter =
      "bank: '" + this.state.accountData.name + "',reconcile: {'$ne':true}";
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/expenses/expense",
      [
        "id",
        "name",
        "category",
        "location",
        "purpose",
        "reference_number",
        "reconcile",
        "check_number",
        "bank",
        "currency",
        "exchange_rate",
        "amount",
        "date",
      ],
      [{ by: "desc", attr: "date" }],
      "Afrijula::Expenses::Expense",
      null,
      filter,
      null
    );

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  reconcileExpense = (data) => {
    let account = this.state.accountData;
    let reconciledDate = getToday();

    data.map((expense) => {
      let amount = currencyValue(expense.amount, false);

      let withdrawnFromPrevBal = currencyValue(account.balance, false);
      let withdrawnFromPrevLocBal = currencyValue(account.local_balance, false);
      let newWithdrawnFromBal = withdrawnFromPrevBal - amount;
      let newWithdrawnFromLocBal = withdrawnFromPrevLocBal - amount;
      let _data = {
        newBal: newWithdrawnFromBal,
        newLocBal: newWithdrawnFromLocBal,
      };
      let _this = this;

      let temp = {
        reconcile: true,
        reconciled_date: reconciledDate,
        cashbook_activated: this.state.cashbookActivated,
      };

      $.ajax({
        method: "PUT",
        url: baseUrl + "/afrijula/expenses/expense/" + expense._id.$oid,
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        data: temp,

        success: function(data) {
          let lang = getLocale();
          if (lang === "en-GB" || lang == "en-US") {
            displaySuccess(
              `${amount} has been reconciled against  on ${reconciledDate}`
            );
          } else {
            displaySuccess(getString().reconcile_success);
          }
          if (this.props.updateMe) {
            this.props.updateMe(_data);
          }
          _this.close();
        }.bind(this),
        error: function(req, status, error) {
          displayError(req.responseText);
        },
      });
    });
  };

  deleteExpense = (data) => {
    let _this = this;
    if (data.length != 1) {
      displayError("You can only delete one item at a time.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this expense?")) {
      $.ajax({
        method: "DELETE",
        url: baseUrl + "/afrijula/expenses/expense/" + data[0]._id.$oid,
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(resp) {
          displaySuccess(resp.success);
          _this.tableRef.current.onQueryChange();
        },
        error: function(error) {
          displayError(error.responseText);
        },
      });
    }
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let _callerState = this.state.callerState;
      _callerState.reconcile = false;
      return (
        <ChildComponent
          state={_callerState}
          returningProps={this.state.callerProps}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9} />

                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.setState({ back: true });
                    }}
                    style={{
                      backgroundColor: "#f44336",
                      margin: ".3125rem 1px",
                      float: "right",
                      minWidth: "auto",
                      minHeight: "auto",
                      padding: "12px 30px",
                      borderRadius: "3px",
                      color: "#ffffff",
                    }}
                  >
                    {getString().back}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: -10 }}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="success">
                  <h4 className={classes.cardTitleWhite}>
                    {getString().expenses}
                  </h4>
                </CardHeader>
                <Table
                  tableRef={this.tableRef}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      this.loadData(query, resolve, reject);
                    })
                  }
                  columns={this.state.header}
                  onRowClick={this.rowSelect}
                  options={{
                    exportButton: true,
                    filtering: true,
                    grouping: true,
                    sorting: true,
                    selection: true,
                    showTitle: false,
                    pageSize: 15,
                  }}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh",
                      isFreeAction: true,
                      onClick: () =>
                        this.tableRef.current &&
                        this.tableRef.current.onQueryChange(),
                    },
                    {
                      tooltip: "Delete expense",
                      icon: "delete",
                      onClick: (evt, data) => {
                        this.deleteExpense(data);
                      },
                    },
                    {
                      tooltip: "Reconcile expense",
                      icon: "autorenew",
                      onClick: (evt, data) => {
                        this.reconcileExpense(data);
                      },
                    },
                  ]}
                />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default withStyles(styles)(ReconcileAccount);
