import React, { Component } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/css/material-dashboard-react.css?v=1.5.0";

import indexRoutes from "routes/afrijula/index.jsx";

import "assets/css/bootstrap.css";
import "assets/css/chartist.min.css";
import { setGlobalHeader } from "assets/js/utils/utils.js";

import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";



import MobileDetect from "mobile-detect";
import AndroidApp from "./components/common/AndriodApp/AndriodApp";
const hist = createBrowserHistory();
class Main extends Component {
  successToast = (message) => {
    return ToastsStore.success(message);
  };
  errorToast = (message) => {
     return ToastsStore.error(message); 
  };

  componentDidMount() {
    setGlobalHeader(this);
    // check if the duration is still valid every 5 minutes
    setInterval(() => {
      // check if there is a storage for dialogClick
      if (localStorage.getItem("closeAndroidDialog")) {
        let savedDate = localStorage.getItem("closeAndroidDialog");
        let diffTime = savedDate - new Date().getTime();
        let diffDays = diffTime / (1000 * 3600 * 24);
        // remove after 7 days
        if (diffDays > 7.00119) {
          localStorage.removeItem("closeAndroidDialog");
        }
      }
      // 50000 milliseconds ==== 5 minutes
    }, 50000);
  }

  // save the click event to local storage

  saveDialogClickToLocalStorage = () => {
    const dateToday = new Date().getTime();
    localStorage.setItem("closeAndroidDialog", dateToday);
  };

  // check if the click event has been saved to local storage
  hasSavedDialogClickToLocalStorage = () => {
    const item = localStorage.getItem("closeAndroidDialog");
    if (item) {
      return true;
    }
    return false;
  };

  render() {
    const md = new MobileDetect(window.navigator.userAgent);
    const osType = md.os();
    const isAndroid = md.is("AndroidOS");

    return (
      <>
        {osType === "AndroidOS" || isAndroid ? (
          <AndroidApp
            saveClick={this.saveDialogClickToLocalStorage}
            hasSaved={this.hasSavedDialogClickToLocalStorage}
          />
        ) : null}

        <div>
          <ToastsContainer
            store={ToastsStore}
            position={ToastsContainerPosition.TOP_RIGHT}
          />
          
          <Router history={hist}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </Router>
        </div>
      </>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById("root"));
