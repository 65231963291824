import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import {getString, currencyFormat, dateFormat,compileQuery,showReportUtility} from "assets/js/utils/utils.js";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DetailsTable from '@material-ui/core/Table';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class DepositDetails extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    state = this.props.state || {
        back: false,

        paymentsHeader:  [
            {title: getString().amount,field:'amount', render: rowData => currencyFormat(rowData.amount),cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],

        paymentsData: [],

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,

        depositData: this.props.depositData,

    };

    close = () => {
        this.setState({back: true})
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    componentDidMount() {
        if(this.props.depositData){
            let payments = this.props.depositData.payments;
            let _data = [];
            payments.map(payment =>{
                _data.push(payment);
            });
            this.setState({
                paymentsData: _data
            });
        }
    }

    render() {
        const {classes} = this.getProps();

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.depositData = null;
            return <ChildComponent state = {this.state.callerState} returningProps = {this.state.callerProps} />

        }
        else{

            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>

                                <GridItem xs={12} sm={12} md={12}>
                                    <Button color="danger" onClick={() => {this.setState({back: true})}}
                                            style={{backgroundColor: "#f44336",margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight:"auto",
                                                padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }}
                                    >{getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{getString().details}</h4>
                                </CardHeader>
                                <CardBody>
                                    <DetailsTable style={styles.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell  colSpan={2} style={{fontSize: 15+'px'}}>{getString().refNo+": "+ this.state.depositData.reference_number}</TableCell>
                                                <TableCell style={{fontSize: 15+'px'}}>{getString().acc_name+": "+this.state.depositData.account_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontSize: 15+'px'}}>{getString().acc_number+": "+ this.state.depositData.account_number}</TableCell>
                                                <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().category+": "+this.state.depositData.category}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{fontSize: 15+'px'}}>{getString().amount+": "+currencyFormat(this.state.depositData.amount)}</TableCell>
                                                <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().balance+": "+currencyFormat(this.state.depositData.balance)}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().local_balance +": "+ currencyFormat(this.state.depositData.local_balance) }</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </DetailsTable>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{marginTop:-10}}>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="success">
                                    <h4 className={classes.cardTitleWhite}>{getString().payments}</h4>

                                </CardHeader>
                                <Table
                                    tableRef={this.tableRef}
                                    data={this.state.paymentsData}
                                    columns={this.state.paymentsHeader}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        debounceInterval: 1000,
                                        selection:true,showTitle:false,
                                        pageSize:20
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}


export default withStyles(styles)(DepositDetails);