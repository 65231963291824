import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import ShowDetails from "components/afrijula/AllDetails/ReceiptTemplate.jsx"
import SellPage from "components/afrijula/Sell/NewSell.jsx";
import AddCustomer from "components/afrijula/AddForm/AddingCustomer.jsx"
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {displaySuccess, displayError, getString, currencyFormat, dateFormat,compileQuery,showReportUtility} from "assets/js/utils/utils.js";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import AsyncSelect from "react-select/async";
import CustomerNoteDetails from "./CustomerNoteDetails";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class CustomerDetails extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    state = this.props.state || {

        customerSale: false,
        back: false,

        customerData: this.props.customerData,
        data:  [],
        mergable: {number: null, customerName: null, customerId: null, customerAddress:null, customerPhone: null, customerEmail: null},
        historydetails: false,
        customerHistory: [],
        currnetCustomerDetails: this.props.rowData,
        pageSize:15,
        tag: "customerDetails",
        mergeWith: false,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        editcustomer: false

    };

    addCustomerSale = () =>{
        this.setState({customerSale: true});
    };

    close = () => {
        this.setState({back: true})
    };

    rowSelect = (event, colData)  => {
        let id = colData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/sales/sales/"+id,
            data:{filter:'installments'},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({salesData: result});
            }
        });
    };

    loadData =  (query,resolve,reject) => {
        let url = compileQuery(query, (baseUrl + "/afrijula/sales/sales"),
            ["date","customer","sub_total","total","tran_type","pricing","id","description","total_tax","discount","reversed","contract_closed","labels"],
            [{"by":"desc","attr":"date"}],"Afrijula::Sales::Sale",{"include": "{invoice: {only: [:balance]}}"},"customer_id: '"+this.state.customerData._id.$oid+"'");

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    editCustomer = () =>{
        this.setState({
            editcustomer: true
        })
    };

    closeEditCustomer = () =>{
        this.setState({
            editcustomer: false
        })
    };

    updateCustomerData = (data) =>{
        let _customer = this.state.customerData;
        _customer.name = data.name;
        _customer.address = data.address;
        _customer.phone_number = data.phone_number;
        _customer.email = data.email;
        this.setState({
            customerData: _customer
        })
    };

    completeMerge = () => {
        if(!window.confirm("Are you sure you want to proceed?")){
            return;
        }
        const mergable = this.state.mergable;
        if(mergable.customerId == null ||
            mergable.customerName == null ||
            mergable.number == null){
            displayError("Please select a valid customer to merge");
            return;
        }
        let _this = this;
        $.ajax({
            method: 'PUT',
            url: baseUrl + "/afrijula/sales/customers/"+this.state.customerData._id.$oid,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {merge: mergable.customerId},
            success: function (result) {
                displaySuccess(mergable.customerName+" successfully merged to " +_this.state.customerData.name);
                _this.clearMergable();
                _this.tableRef.current && _this.tableRef.current.onQueryChange();
            }.bind(this)
        });

    };



    customerOptions = (inputValue) => {
        let _this = this;
        const url = baseUrl + '/afrijula/sales/customers?attrs=["name","id","address","phone_number","email","number"]&model=Afrijula::Sales::Customer&size=20&search='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for(; i< json.length;i++){
                    if(json[i]._id.$oid != _this.state.customerData._id.$oid) {
                        opts[i] = {
                            label: json[i].name,
                            value: json[i]._id.$oid,
                            address: json[i].address,
                            email: json[i].email,
                            phone_number: json[i].phone_number,
                            number: json[i].number
                        };
                    }
                }
                return opts;
            }) // my option list array?
            .catch(err => {

            });
    };

    handleCustomer = (opt) =>{

        let merged = this.state.mergable;
        merged.number = opt.number;
        merged.customerName = opt.label;
        merged.customerId = opt.value;
        merged.customerAddress = opt.address;
        merged.customerEmail = opt.email;
        merged.customerPhone = opt.phone_number;

        this.setState({mergable: merged});

    };

    clearMergable =()=>{
        this.setState({mergeWith: false, mergable:{number: null, customerName: null, customerId: null,
            customerAddress: null, customerEmail: null, customerPhone: null}});
    }


    componentDidMount(){
    }

    render() {
        if(this.state.customerSale){
            let data = this.state.customerSale;
            this.state.customerSale = null;
            return (
                <SellPage callerState = {this.state} callerProps = {this.getProps()}
                          callerData = {data} callerName = {CustomerDetails}
                customer={this.state.customerData}/>
            );
        }
        if(this.state.back){

           let ChildComponent = this.state.callerName;
           return <ChildComponent state = {this.state.callerState} returningProps = {this.state.callerProps} />

        }
        if(this.state.salesData){
            let data = this.state.salesData;
            this.state.salesData = null;
            return <ShowDetails callerProps={this.getProps()} callerState = {this.state} tag={data.tran_type} salesData={data} callerName={CustomerDetails} />;
        }
        else{

            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button color="success" onClick={this.addCustomerSale.bind(this)}
                                            style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">add</i>
                                        {getString().add_customer_sale}
                                    </Button>
                                    <Button xs={12} sm={4} md={4} color="warning" onClick={this.editCustomer.bind(this)}
                                            style={{backgroundColor: "#ff9800", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">edit</i>
                                        {getString().edit}
                                    </Button>
                                    <Button xs={12} sm={4} md={4} color="danger" onClick={()=>{this.setState({mergeWith:true})}}
                                            style={{backgroundColor: "#ff9800", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">link</i>
                                        {getString().merge}
                                    </Button>
                                    <Button xs={12} sm={4} md={4} color="info" onClick={() => {
                                        let end_date = new Date();
                                        let start_date = new Date(end_date.getFullYear()-1,end_date.getMonth(),end_date.getDate());
                                        let params = {
                                            customer_id: this.state.customerData._id.$oid,
                                            start_date: start_date,
                                            end_date: end_date,
                                            org_name: cookies.load('orgName'),
                                            organization_id: cookies.load('orgId')
                                        };

                                        let inject = {images: [{name: 'image', attr:'image', id: cookies.load('orgId'), model: 'Organization'}]};
                                        showReportUtility('Customer Statement', baseUrl + '/reports/1?url=/afrijula/*&profile='+cookies.load("profileId"),
                                            {params: params, sub: '&report=customerStatement_report&dynamic=true', inject: inject}, cookies,
                                            [{type: 'date', default: start_date, label: "From",name:'start_date'},{type:'date', default:end_date, label: 'To',name:'end_date'}]);
                                    }}
                                            style={{backgroundColor: "#00acc1", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">book</i>
                                        {getString().statement}
                                    </Button>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    <Button color="danger" onClick={() => {this.setState({back: true})}}
                                            style={{backgroundColor: "#f44336",margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight:"auto",
                                                padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }}
                                    >{getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>

                    {(this.state.editcustomer) ? <AddCustomer callerProps={this.getProps()} callerState = {this.state} callerName={CustomerDetails}
                                                              updateMe={this.updateCustomerData.bind(this)} customerData={this.state.customerData} editCustomer={true} closeEdit={this.closeEditCustomer}
                        />
                        :
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <Dialog
                                        open={this.state.mergeWith}
                                        aria-labelledby="form-dialog  -title"
                                        maxWidth={'lg'}

                                    >
                                        <DialogTitle id="form-dialog-title">{getString().merge +" "+ this.state.customerData.name}</DialogTitle>
                                        <DialogContent>
                                            <AsyncSelect defaultOptions loadOptions={this.customerOptions}
                                                         placeholder={getString().select_customer}
                                                         name="merge_customer" id="merge_customer"
                                                         isClearable={true}
                                                         onChange={opt => {
                                                                this.handleCustomer(opt);
                                                            }}
                                            />
                                            <div>
                                                <div><label>{getString().number}: </label><label>{this.state.mergable.number}</label></div>
                                                <div><label>{getString().email}: </label><label>{this.state.mergable.customerEmail}</label></div>
                                                <div><label>{getString().phone}: </label><label>{this.state.mergable.customerPhone}</label></div>
                                                <div><label>{getString().address}: </label><label>{this.state.mergable.customerAddress}</label></div>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={()=>{this.clearMergable()}} color="danger">
                                                {getString().cancel}
                                            </Button>
                                            <Button onClick={() => {this.completeMerge()}} color="info">
                                                {getString().submit}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <CardHeader color="primary">
                                        <h4 >{getString().profile}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p><b>{getString().name}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p id="name"><b>{this.state.customerData.name}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p><b>{getString().address}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p id="adr"><b>{this.state.customerData.address}</b></p>

                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p><b> {getString().phone}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p id="phone"><b>{this.state.customerData.phone_number}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={5}>
                                                    <p><b>{getString().email}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={7}>
                                                    <p id="email"><b>{this.state.customerData.email}</b></p>

                                                </GridItem>
                                            </GridContainer>

                                        </form>
                                    </CardBody>

                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="primary">
                                        <h4 >{getString().account_summary}</h4>
                                    </CardHeader>

                                    <CardBody>
                                        <form>

                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p><b> {getString().balance}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p id="bal"><b>{currencyFormat(this.state.customerData.customer_balance)}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p><b>{getString().yearly_turnover}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p id="yearlyTover"><b>{currencyFormat(this.state.customerData.annual_turnover)}</b></p>

                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p><b>{getString().last_date}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <p id="date"><b>{dateFormat(this.state.customerData.last_transaction_date)}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </CardBody>

                                </Card>
                            </GridItem>
                        </GridContainer>

                    }
                    <GridContainer>
                        <GridItem xs={12} sm={8} md={9}>
                            <Card>
                                <CardHeader color="warning">
                                    <h4>{getString().purchase_history}</h4>

                                </CardHeader>
                                <Table
                                    tableRef={this.tableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadData(query,resolve,reject);
                                    })}
                                    columns={[
                                        {title: getString().type, field:'tran_type',cellStyle: {padding: '3px'},headerStyle: {padding: '3px'}, render: rowData => {return rowData.replace('_',' ')}, defaultGroupOrder: 0},
                                        {title: getString().description, field:'description',cellStyle: {padding: '3px'},headerStyle: {padding: '3px'}},
                                        {title: getString().date, field:'date',render: rowData => dateFormat(rowData.date),cellStyle: {padding: '3px'},headerStyle: {padding: '3px'}},
                                        {title: getString().total, field:'total',render: rowData => currencyFormat(rowData.total),cellStyle: {padding: '3px'},headerStyle: {padding: '3px'}},
                                        {title: getString().balance, field:'balance',render: rowData => currencyFormat(rowData.invoice ? rowData.invoice.balance : 0.0),cellStyle: {padding: '3px'},headerStyle: {padding: '3px'}},
                                        {title: getString().status,field:'reversed',render: rowData => {return (rowData.reversed == true || rowData.contract_closed == true) ? 'reversed / closed' : 'valid'},
                                            cellStyle: {padding: '3px'},headerStyle: {padding: '4px'}},
                                        {
                                            title: '', field: 'labels', render: (rowData) => {return (rowData.labels) ? rowData.labels.map((label)=>{return <div color={'#FFC300'}>{label}</div>}) : null},
                                            filtering:false,
                                            cellStyle: {padding: '2px'}, headerStyle: {padding: '4px'}
                                        }

                                    ]}
                                    onRowClick={this.rowSelect}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:10
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={3}>
                            <CustomerNoteDetails customerId={this.state.customerData._id.$oid} saleId={null}/>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}


export default withStyles(styles)(CustomerDetails);
