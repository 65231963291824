import React from "react";
import { Fade } from "react-slideshow-image";

import "assets/css/SlideShow.css";

import banner0 from "assets/img/me/ban1.jpg";
import banner1 from "assets/img/me/afrijula_gm landing banner 2.jpg";
import banner2 from "assets/img/me/afrijula_gm landing banner 3.jpg";
import { getString, getLocaleString } from "assets/js/utils/utils.js";

const fadeImages = [banner0, banner1, banner2];

const fadeProperties = {
  duration: 8000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  height: 200,
};

const Slideshow = () => {
  return (
    <Fade {...fadeProperties}>
      <div className="each-fade">
        <div className="image-container">
          <picture>
            <source media="(max-width: 744px)" srcSet={fadeImages[2]} />
            <source media="(min-width: 745px)" srcSet={fadeImages[1]} />
            <img className="image-size" src={fadeImages[0]} />
            <div>
              <span className="bottom-left">
                <b>{getString().slide1}</b>
              </span>
            </div>
          </picture>
        </div>
      </div>
      <div className="each-fade">
        <div className="image-container">
          <picture>
            <source media="(max-width: 744px)" srcSet={fadeImages[0]} />
            <source media="(min-width: 745px)" srcSet={fadeImages[1]} />
            <img className="image-size" src={fadeImages[2]} />
          </picture>
        </div>
      </div>
      <div className="each-fade">
        <div className="image-container">
          <picture>
            <source media="(max-width: 744px)" srcSet={fadeImages[3]} />
            <source media="(min-width: 745px)" srcSet={fadeImages[2]} />
            <img className="image-size" src={fadeImages[1]} />
          </picture>
        </div>
      </div>
    </Fade>
  );
};

export default Slideshow;
