import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import $ from "jquery";
import cookies from "react-cookies";

import baseUrl from "assets/js/config/const.js";
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, execPrint,
    thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";
import TextField from "@material-ui/core/TextField/TextField";



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    cardCategory:{
        color: "#000",
        margin: "0px",
        fontSize: "14px",
        fontWeight: "bolder",
        paddingTop: "4px",
    }

});

class QuoteReceipt extends React.Component {
    constructor(props){
        super(props);

        this.tableRef = React.createRef();
    }

    state = {
        customer: this.props.customer,
        email: false,
        emailRecipient: '',
        emailSubject:getString().your_receipt,
        orgEmail:cookies.load('orgEmail'),
        orgContact: cookies.load('orgContact'),
        orgAddress:cookies.load('orgAddress'),

        printerType: cookies.load("printer_type"),
        quoteSale: this.props.quoteSale,
        quoteItems: this.props.quoteItems,
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    emailReceipt = () =>{

        this.setState({
            email: true,
        })
    };

    closeSendEmail = () =>{
        this.setState({
            email: false,
        })
    };

    sendEmail = () =>{
        let _this = this;

        let _body = document.getElementById("printableView").outerHTML;
        let _subject =  this.state.emailSubject;
        let _recipient = this.state.emailRecipient;

        if( _recipient ==="" || !validateEmail(_recipient)){
            displayError(getString().valid_email);
            document.getElementById("recipient").focus();
            return;
        }

        $.ajax({
            method: "POST",
            url: baseUrl +"/mail_v2",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{to: _recipient, subject: _subject,body:_body, domain: 'afrijula.gm'},

            success: function (result) {
                displaySuccess(getString().email_succ_msg);
                _this.closeSendEmail();
            }.bind(this),  error: function(resp){
                displayError(resp.responseText);
            }
        })
    };

    print = () =>{
        (this.state.printerType === 'thermal') ?
            execPrint(document.getElementById("invoice-POS")) : execPrint(document.getElementById("printableView"));
        this.closePrint();
    };

    emailReceiptForm = (classes) =>{
        return(
            <Card>
                <CardBody>
                    <GridItem xs={12}>
                        <form className={classes.container}  autoComplete="off">
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        required
                                        id="recipient"
                                        name="recipient"
                                        label={getString().to}
                                        className={classes.textField}
                                        value={this.state.customer.customer_email}
                                        onChange={(e) => {this.setState({emailRecipient: e.target.value})}}
                                        type="email"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        required
                                        id="subject"
                                        name="subject"
                                        label={getString().subject}
                                        className={classes.textField}
                                        value={this.state.emailSubject}
                                        onChange={(e) => {this.setState({emailSubject: e.target.value})}}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                        </form>

                        <GridItem xs={12} sm={12} md={6}>
                            <Button xs={12} sm={6} md={2} color="danger" onClick={this.closeSendEmail.bind(this)}>{getString().cancel}</Button>
                            <Button xs={12} sm={6} md={2} color="success" onClick={this.sendEmail.bind(this)}>{getString().send}</Button>
                        </GridItem>
                    </GridItem>
                </CardBody>
            </Card>
        );
    };

    closePrint=()=>{
        this.props.closePrintQuote();
    };

    render() {
        const {classes} = this.getProps();

        return (
            <div>
                <GridContainer>
                    <GridItem>
                        <Button onClick={this.closePrint.bind(this)} xs={12} sm={12} md={2} color="danger"
                                style={{
                                    backgroundColor: "#f44336",
                                    margin: ".3125rem 1px",
                                    minWidth: "auto",
                                    minHeight: "auto",
                                    fontSize: "12px",
                                    color: "#ffffff",
                                    padding: "5px 20px",
                                    borderRadius: "2px",
                                    verticalAlign: "middle"
                                }}
                        >{getString().close}</Button>
                        <Button onClick={this.print.bind(this)} xs={12} sm={12} md={2} color="success"
                                style={{
                                    backgroundColor: "#4caf50",
                                    margin: ".3125rem 1px",
                                    minWidth: "auto",
                                    minHeight: "auto",
                                    fontSize: "12px",
                                    color: "#ffffff",
                                    padding: "5px 20px",
                                    borderRadius: "2px",
                                    verticalAlign: "middle"
                                }}
                        >{getString().to_print}</Button>
                        <Button onClick={this.emailReceipt.bind(this)} xs={12} sm={12} md={2} color="info"
                                style={{
                                    backgroundColor: "#00acc1",
                                    margin: ".3125rem 1px",
                                    minWidth: "auto",
                                    minHeight: "auto",
                                    fontSize: "12px",
                                    color: "#ffffff",
                                    padding: "5px 20px",
                                    borderRadius: "2px",
                                    verticalAlign: "middle"
                                }}
                        >{getString().email}</Button>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    {(this.state.email) ? this.emailReceiptForm(classes) : <div/>}
                </GridContainer>
                <div id={'printableView'}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <table style={{
                                width: "100%",
                                fontWeight: "bold",
                                margin: "10px 5px",
                                padding: "5px 10px",
                                borderCollapse: "collapse"
                            }}>
                                <tr>
                                    <td>
                                        <h2 style={{
                                            fontSize: "30px",
                                            margin: "10px 5px",
                                            textAlign: "center",
                                            textTransform: "uppercase"}}>{getString().quote}
                                        </h2>
                                    </td>

                                </tr>
                                <tr>

                                    <td>
                                        <h2 style={{
                                            fontSize: "30px",
                                            margin: "10px 5px",
                                            textAlign: "center",
                                            textTransform: "uppercase"
                                        }}>{cookies.load('orgName')}
                                        </h2>
                                        <p style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",

                                            margin: "10px 5px",
                                            textAlign: "center",
                                        }}>{this.state.orgAddress === 'null' ? "" :this.state.orgAddress}, {this.state.orgEmail === 'null' ? "" : this.state.orgEmail }, {this.state.orgContact === 'null' ? "" : this.state.orgContact }</p>

                                    </td>
                                    <td><img
                                        src={baseUrl + '/picture/' + cookies.load('orgId') + '?tag=image&model=Afrijula::Account&r=' + this.state.picture}/>
                                    </td>
                                </tr>
                            </table>

                            <table style={{
                                    width: "100%",
                                    fontWeight: "bold",
                                    margin: "15px 5px",
                                    padding: "5px 10px",
                                    borderCollapse: "collapse"
                                }}
                            >
                                <tr>
                                    <td style={{width: "40%"}}>{
                                        getString().name}: {(this.state.customer) ? this.state.customer.name : 'none'}
                                    </td>
                                    <td style={{width: "40%"}}>
                                        {getString().date}: {dateFormat(this.state.quoteSale.date)}
                                    </td>
                                </tr>
                                <tr>
                                    <td  style={{width: "40%"}}>
                                        {getString().email}: {(this.state.customer) ? this.state.customer.email : 'none'}
                                    </td>
                                </tr>
                                <tr>
                                    <td  style={{width: "40%"}}>
                                        {getString().for}: {this.state.quoteSale.description}
                                    </td>
                                </tr>
                            </table>

                            <table style={{
                                width: "100%",
                                fontWeight: "bold",
                                border: "1px solid #000",
                                margin: "10px 5px",
                                padding: "5px 10px",
                                borderCollapse: "collapse"
                            }}>
                                <tbody>
                                <tr>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "left"}}>
                                        {getString().sub_total}
                                    </td>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "right"}}>
                                        {currencyFormat(this.state.quoteSale.subTotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "left"}}>
                                        {getString().tax}
                                    </td>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "right"}}>
                                        {currencyFormat(this.state.quoteSale.tax)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "left"}}>
                                        {getString().discount}
                                    </td>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "right"}}>
                                        {currencyFormat(this.state.quoteSale.discountAmount)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "left"}}>
                                        {getString().total}
                                    </td>
                                    <td style={{fontWeight: "bold", border: "1px solid #000", textAlign: "right"}}>
                                        {currencyFormat(this.state.quoteSale.total)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <h2 style={{margin: "10px 5px", textAlign: "center", textTransform: "uppercase"}}>
                                {getString().thank_you}
                            </h2>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

QuoteReceipt.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QuoteReceipt);