import React from 'react';

export default class Terms_Condition extends React.Component {

    render() {
        return (
            <div style={{
                fontFamily: 'Helvetica Neue',
                fontWeight: 500,
                padding: 25,
                marginLeft: 150,
                marginRight: 150
            }}>
                <h2 style={{}}>Terms &amp; Conditions - Selling on afrijula shop</h2>
                <h4>In General</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    InSIST Global Ltd (“Afrijula Shop”) owns and operate this Website. This document governs your 
                    relationship with Afrijula  (“Website”). Access to and use of this Website and the products and 
                    services available through this Website (collectively, the "Services") are subject to the following 
                    terms, conditions and notices (the "Terms of Service"). By using the Services, you are agreeing to all
                    of the Terms of Service, as may be updated by us from time to time. You should check this page 
                    regularly to take notice of any changes we may have made to the Terms of Service.
                </p>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    Access to this Website is permitted on a temporary basis, and we reserve the right to withdraw or 
                    amend the Services without notice. We will not be liable if for any reason this Website is unavailable 
                    at any time or for any period. From time to time, we may restrict access to some parts or all of this Website.
                </p>
                <p data-node-id="p-39" style={{color: "#000"}}>This Website may contain links to other websites (the "Linked Sites"), which are not operated by 
                    InSIST Global Ltd. Afrijula Shop has no control over the Linked Sites and accepts no responsibility 
                    for them or for any loss or damage that may arise from your use of them. Your use of the Linked Sites 
                    will be subject to the terms of use and service contained within each such site.
                </p>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    This Website may contain links to other websites (the "Linked Sites"), which are not operated
                    by InSIST Global Ltd. Afrijula Shop has no control over the Linked Sites and accepts no responsibility
                    for them or for any loss or damage that may arise from your use of them. Your use of the Linked Sites
                    will be subject to the terms of use and service contained within each such site.
                </p>
                <h4>Privacy Policy</h4>
                <p style={{color: "#000"}}>
                    Our privacy policy, which sets out how we will use your information, can be found at [Privacy Policy Link].
                    By using this Website, you consent to the processing described therein and warrant that all data provided 
                    by you is accurate.
                </p>
                <h4>Prohibitions</h4>
                <p style={{color: "#000"}}>
                    You must not misuse this Website. You will not: commit or encourage a criminal offense; transmit or
                    distribute a virus, trojan, worm, logic bomb or any other material which is malicious, technologically
                    harmful, in breach of confidence or in any way offensive or obscene; hack into any aspect of the Service;
                    corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary
                    rights; send any unsolicited advertising or promotional material, commonly referred to as "spam";
                    or attempt to affect the performance or functionality of any computer facilities of or accessed through
                    this Website. Breaching this provision would constitute a criminal offense and InSIST Global Ltd will
                    report any such breach to the relevant law enforcement authorities and disclose your identity to them.
                </p>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses
                    or other technologically harmful material that may infect your computer equipment, computer programs,
                    data or other proprietary material due to your use of this Website or to your downloading of any
                    material posted on it, or on any website linked to it.
                </p>
                <h4>Intellectual Property, Software and Content</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>The intellectual property rights in all software and content (including photographic images) made available
                    to you on or through this Website remains the property of [Your Online Store URL] or its licensors and
                    are protected by copyright laws and treaties around the world. All such rights are reserved by InSIST Global Ltd
                    and its licensors. You may store, print and display the content supplied solely for your own personal use.
                    You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the
                    content or copies of the content supplied to you or which appears on this Website nor may you use any
                    such content in connection with any business or commercial enterprise.</p>
                <h4>Terms of Use</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    By linking your afrijula account to afrijula shop you are agreeing to sell your products on our online store.
                    You agree to make sure that you have physical stock that is equal to the amount of stock indicated on your
                    afrijula account. You agree to indemnify InSIST Global for any issue that may arrise related to purchase of
                    goods for which you cannot supply due to sock inavailability. Dispatch will be done by a 3rd party and
                    times may vary according to availability of drivers and subject to any delays resulting from postal
                    delays or force majeure for which we will not be responsible.</p>
                <p data-node-id="p-39" style={{color: "#000"}}>In order to contract with InSIST Global Ltd you must be over 18 years of age and possess a valid account
                    issued by a bank acceptable to us. InSIST Global retains the right to refuse any request made by you.
                    If request to sell is accepted you will see your items on our store. This will usually be afrijula.shop
                    or may in some cases be a third party. Where a contract is made with a third party InSIST Global is
                    not acting as either agent or principal and the contract is made between yourself and that third party
                    and will be subject to the terms of sale which they supply you. When posting your goods for purchase you
                    undertake that the details you provide to us are true and accurate, that you are an authorized seller
                    and are able to full fill all orders immediately on receipt.</p>
                <h4>Our Contract</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>When and order is made against you, you will receive  an acknowledgement via e-mail or popup alert confirming
                    receipt of an order: this email will only be an acknowledgement and will not constitute and actual purchase
                    by the buyer. A contract between us will not be formed until we send you confirmation that the you can deliver
                    the goods and you have dispatched the goods, which are received by the buyer. Only those goods listed in the
                    confirmation e-mail sent at the time of dispatch will be included in the contract formed.</p>
                <h4>Pricing and Availability</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>You are expected to ensure that all details, descriptions and prices which appear on this on afrijula.gm
                    are accurate. While we will endeavor to review all products you update, we recognize that we cannot identify
                    all errors and you are responsible for this. You undertake to honor any price give for which a buy has
                    made a purchase. You also undertake to accept any return for which the information given to describe
                    the product for which the buy nad ourselves agree does not truly represent the product sold. If you
                    do not confirm order within 12 hours of receipt it will be cancelled. You will be responsible for any
                    delivery charge if an item is returned because the description or picture does not match the item
                    delivered.</p>
                <h4>Payment</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>You are required to make a settlement claim through afrijula to receive your payment. Please note that
                    their will be a transaction charge for any settlemtns to your account. There are two forms for
                    settlement: Check which you will be required to pick up from our office; inter bank transfer.
                    Note that each of these will have a fee. You will receive an email or phone call once your check is
                    ready for pickup.</p>
                <h4>Disclaimer of Liability</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>The material displayed on this Website is provided without any guarantees, conditions or warranties as
                    to its accuracy. Unless expressly stated to the contrary to the fullest extent permitted by law
                    InSIST Global Ltd and its suppliers, content providers and advertisers hereby expressly exclude all
                    conditions, warranties and other terms which might otherwise be implied by statute, common law or the
                    law of equity and shall not be liable for any damages whatsoever, including but without limitation
                    to any direct, indirect, special, consequential, punitive or incidental damages, or damages for loss
                    of use, profits, data or other intangibles, damage to goodwill or reputation, or the cost of procurement
                    of substitute goods and services, arising out of or related to the use, inability to use, performance
                    or failures of this Website or the Linked Sites and any materials posted thereon, irrespective of whether
                    such damages were foreseeable or arise in contract, tort, equity, restitution, by statute, at common
                    law or otherwise. This does not affect InSIST Global Ltd's liability for death or personal injury
                    arising from its negligence, fraudulent misrepresentation, misrepresentation as to a fundamental matter
                    or any other liability which cannot be excluded or limited under applicable law.</p>
                <h4>Linking to this Website</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>You may link to our home page, provided you do so in a way that is fair and legal and does not damage
                    our reputation or take advantage of it, but you must not establish a link in such a way as to suggest
                    any form of association, approval or endorsement on our part where none exists. You must not establish
                    a link from any website that is not owned by you. This Website must not be framed on any other site,
                    nor may you create a link to any part of this Website other than the home page. We reserve the right
                    to withdraw linking permission without notice.</p>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    Disclaimer as to ownership of trade marks, images of personalities and third party copyright.
                    Except where expressly stated to the contrary all persons (including their names and images), third
                    party trade marks and content, services and/or locations featured on this Website are in no way
                    associated, linked or affiliated with InSIST Global Ltd and you should not rely on the
                    existence of such a connection or affiliation. Any trade marks/names featured on this Website are
                    owned by the respective trade mark owners. Where a trade mark or brand name is referred to it is
                    used solely to describe or identify the products and services and is in no way an assertion that
                    such products or services are endorsed by or connected to InSIST Global ltd.
                </p>
                <h4>Indemnity</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>
                    You agree to indemnify, defend and hold harmless InSIST Global Ltd, its directors, officers, employees,
                    consultants, agents, and affiliates, from any and all third party claims, liability, damages and/or
                    costs (including, but not limited to, legal fees) arising from your use this Website or your breach
                    of the Terms of Service.
                </p>
                <h4>Variation</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>InSIST Global Ltd shall have the right in its absolute discretion at any time and without notice to
                    amend, remove or vary the Services and/or any page of this Website.</p>
                <h4>Invalidity</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>If any part of the Terms of Service is unenforceable (including any provision in which we exclude our
                    liability to you) the enforceability of any other part of the Terms of Service will not be affected
                    all other clauses remaining in full force and effect. So far as possible where any clause/sub-clause
                    or part of a clause/sub-clause can be severed to render the remaining part valid, the clause shall
                    be interpreted accordingly. Alternatively, you agree that the clause shall be rectified and interpreted
                    in such a way that closely resembles the original meaning of the clause /sub-clause as is permitted by law.</p>
                <h4>Complaints</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>We operate a complaints handling procedure which we will be used to try to resolve disputes when they
                    first arise, please let us know if you have any complaints or comments.</p>
                <h4>Waiver</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>If you breach these conditions and we take no action, we will still be entitled to use our rights and
                    remedies in any other situation where you breach these conditions.</p>
                <h4>Entire Agreement</h4>
                <p data-node-id="p-39" style={{color: "#000"}}>The above Terms of Service constitute the entire agreement of the parties and supersede any and all
                    preceding and contemporaneous agreements between you and InSIST Global Ltd Any waiver of any provision
                    of the Terms of Service will be effective only if in writing and signed by a Director of InSIST Global Ltd.</p>
            </div>
        );
    }
}
