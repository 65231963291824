import React from "react";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import {getString,displayError,displaySuccess,validateNumberField,currencyValue} from "assets/js/utils/utils.js";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import SaveButton from "../CustomComponents/SaveButton";



const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class AddProduct extends React.Component{

    state = {

        backtoprod: false,
        noBack: this.props.noBack || false,
        noCancel:this.props.noCancel || false,

        callerName: this.props.callerName,
        callerState: this.props.callerState,
        callerProps: this.props.callerProps,
        editProduct: this.props.editProduct,
        productData: null,

    };

    getDefaulProduct = () =>{
        this.state.productData = (this.props.productData) ? {
            name: this.props.productData.name,
            number: this.props.productData.number,
            category: this.props.productData.category,
            unit_price: currencyValue(this.props.productData.unit_price,false),
            billable: this.props.productData.billable,
            _id: this.props.productData._id
        } : {
            name: '',
            number: '',
            category: '',
            unit_price: '',
            billable: false,
        };

    };

    updateProduct = () => {
        let method = "POST";
        let url = baseUrl + "/afrijula/sales/products";
        let _this = this;
        
        //sbensouda: no need for all this complexity. If you have an id then put else post.
        if(this.state.productData._id){
            method = "PUT";
            url =  baseUrl + "/afrijula/sales/products/"+this.state.productData._id.$oid;
        }

        let price = (this.state.productData.unit_price instanceof String) ?  this.state.productData.unit_price.trim() : this.state.productData.unit_price;
        if(!validateNumberField(price)) {
            displayError(getString().invalid_unit_price);
            return;
        }
        let _data = this.state.productData;
        return $.ajax({
            method: method,
            url: url,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",

            data: _data,

            success: function (data){
                {displaySuccess(getString().product_saved);}
                if(_this.props.saveProductCback) {
                      _this.props.saveProductCback(data._id.$oid, _this.state.productData);
                      _this.clearForm();
                }
                if((_this.props.closeMenuForm)){
                      _this.props.closeMenuForm();

                }
                if((_this.props.closeEdit)){
                      _this.props.closeEdit();
                }
                if(_this.props.refreshMenu) {
                      _this.props.refreshMenu();
                }
                if(_this.state.callerState.showProdParts){
                    let _showProdParts = _this.state.callerState.showProdParts;
                    _this.setState({
                        _showProdParts: false,
                    });
                    // _this.state.callerState.showProdParts = false;
                }
                else
                    _this.setState({backtoprod: true});
              },
              error: function (response) {
                  {displayError(response.responseText);}
              }
        });
    };

    generateNumber = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/sales/products/generate_number",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{generating: true},
            success: function (data){
                {displaySuccess(getString().prod_num_generated);}
                let _productData = _this.state.productData;
                _productData.number = data.number;
                _this.setState({
                    productData: _productData
                });
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    updateCategory = (name, val) => {
        let product = this.state.productData;
        product[name] = val;
        this.setState({productData: product});
    };

    handleChange = (e) => {
        let product = this.state.productData;
        product[e.target.name] = e.target.value;
        this.setState({
            productData: product
        })
    };

    categoryOptions = (inputValue) => {
        const url = baseUrl + '/afrijula/sales/products?attrs=["category"]&model=Afrijula::Sales::Product&unique=category&match='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for(; i< json.length;i++){
                    opts[i] = {label:json[i], value:json[i]};
                }
                return opts;
            }) // my option list array?
            .catch(err => {
                console.log(err);
            });
    };

    clearForm = () => {
        let _productData = this.state.productData;
        _productData.name = '';
        _productData.number = '';
        _productData.category = '';
        _productData.unit_price = '';

        this.setState({
            productData:_productData,
            backtoprod:true
        });
    };

    cancelFunct = () =>{
        if((this.props.closeMenuForm)) {
            this.props.closeMenuForm();
        }
        if((this.props.closeEdit)){
            this.props.closeEdit();
        }
        this.setState({backtoprod: true});
    };

    loadCancel = () => {
        if(this.state.noCancel === false){
            return <Button xs={12} sm={6} md={2} color="danger" onClick={this.cancelFunct.bind(this)}
                           style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                               minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
            >{getString().cancel}</Button>
        }
    };

    loadBack = () => {
        if(this.state.noBack === false){
            return <GridContainer>
                                    <GridItem xs={8}/>
                                    <GridItem xs={4} sm={4} md={4}>
                                        <Button color="danger" onClick={()=>{this.setState({backtoprod: !this.state.backtoprod})}}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", float:"right",
                                            minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                            >{getString().back}
                                         </Button>
                                    </GridItem>
                    </GridContainer>
        }
    };

    getTitle = ()=>{
        let title = "";
        if(this.state.editProduct){
            title = getString().edit;
        }
        else if(this.props.tag){
            title = getString().add_menu;
        }
        else {
            title = getString().add_product;
        }
        return title;
    };

    updateBillable = (e) =>{
        let _billable = this.state.productData;
        _billable.billable = e.target.checked;
        this.setState({billable: _billable})
    };

    componentWillMount() {
        this.getDefaulProduct();
    }

    render() {
        const { classes } = this.props;
        if(this.state.backtoprod && !this.props.tag){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.addProduct = false;

            return <ChildComponent state = {caller_state} returningProps = {this.state.callerProps} />
        }
        else{
        return (
            <div>

                {this.loadBack()}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card className={'lecketInlineItems'}>
                            <CardHeader color="info">
                                <h5>{this.getTitle()}</h5>
                            </CardHeader>

                            <CardBody>
                                <form className={classes.container}  autoComplete="off">
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label={getString().product}
                                                type="text"
                                                value={this.state.productData.name}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="number"
                                                name="number"
                                                label={getString().product_number}
                                                type="text"
                                                value={this.state.productData.number}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            <Button xs={12} sm={6} md={2} color="success" onClick={this.generateNumber.bind(this)}
                                                    style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                            >{getString().generate}</Button>
                                        </GridItem>

                                        <GridItem xs={6} sm={6} md={4}>
                                            <TextField
                                                required
                                                id="unitprice"
                                                name="unit_price"
                                                label={getString().unit_price}
                                                value={this.state.productData.unit_price}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={4}>
                                            <div style={{marginTop: 15}} >
                                                <label>{getString().category}*</label>
                                                <AsyncCreatableSelect defaultOptions loadOptions={this.categoryOptions} name="category" id="category"
                                                                      placeholder={getString().choose_category} value={{value: this.state.productData.category, label: this.state.productData.category}}
                                                                      onChange={opt => {this.updateCategory('category',opt.label)}} />
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    {(this.props.noBillable !== true) ?
                                        <GridContainer>
                                            <FormControlLabel control={<Checkbox checked={this.state.productData.billable}
                                                                                 onChange={(e) => {this.updateBillable(e)}}
                                                                                 value={this.state.productData.billable}/>}
                                                              label={getString().billable}/>



                                        </GridContainer> : null}
                                </form>

                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={12}>
                                    {this.loadCancel()}
                                    <SaveButton method={this.updateProduct} title={getString().save} />
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
        }
    }
}

AddProduct.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddProduct);
