import React, { useState } from "react";
import "./style.css";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import {
  currencyFormat,
  dateFormat,
  displayError,
  displaySuccess,
  execPrint,
  getString,
  validateEmail,
} from "../../../assets/js/utils/utils";
import GridContainer from "../Grid/GridContainer";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import TextField from "@material-ui/core/TextField/TextField";
import $ from "jquery";

function MobileReceipt(props) {
  const [printerType, setPrinterType] = useState(cookies.load("printer_type"));
  const [email, setEmail] = useState(false);
  const [emailRecipient, setEmailRecipient] = useState("");
  const [emailSubject, setEmailSubject] = useState("Your receipt");

  const closePrint = () => {
    props.closePrint();
  };

  const print = () => {
    printerType === "thermal"
      ? execPrint(document.getElementById("invoice-POS"))
      : execPrint(document.getElementById("printableView"));
    closePrint();
  };

  const emailReceipt = () => {
    setEmail(true);
  };

  const closeSendEmail = () => {
    setEmail(false);
  };

  const sendEmail = () => {
    let _this = this;

    let _body = document.getElementById("printableView").outerHTML;
    let _subject = emailSubject;
    let _recipient = emailRecipient;

    if (_recipient === "" || !validateEmail(_recipient)) {
      displayError("Please enter a valid email address");
      if (document.getElementById("recipient"))
        document.getElementById("recipient").focus();
      return;
    }

    $.ajax({
      method: "POST",
      url: baseUrl + "/mail_v2",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        to: _recipient,
        subject: _subject,
        body: _body,
        domain: "afrijula.gm",
      },

      success: function(result) {
        displaySuccess("Email successfully sent");
        closeSendEmail();
      },
      error: function(resp) {
        displayError(resp.responseText);
      },
    });
  };

  const emailReceiptForm = () => {
    return (
      <Card>
        <CardBody>
          <GridItem xs={12}>
            <form autoComplete="off">
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="recipient"
                    name="recipient"
                    label={getString().to}
                    value={props.customer.customer_email}
                    onChange={(e) => {
                      setEmailRecipient(e.target.value);
                    }}
                    type="email"
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="subject"
                    name="subject"
                    label={getString().subject}
                    value={"Your receipt"}
                    onChange={(e) => {
                      setEmailSubject(e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
            </form>

            <GridItem xs={12} sm={12} md={6}>
              <Button
                xs={12}
                sm={6}
                md={2}
                color="danger"
                onClick={closeSendEmail.bind(this)}
              >
                {getString().cancel}
              </Button>
              <Button
                xs={12}
                sm={6}
                md={2}
                color="success"
                onClick={sendEmail.bind(this)}
              >
                {getString().send}
              </Button>
            </GridItem>
          </GridItem>
        </CardBody>
      </Card>
    );
  };

  return (
    <div
      className={"modal"}
      style={{ opacity: 1, background: "white", zIndex: 9000000000 }}
    >
      <div className="invoice-box">
        <GridContainer>
          <GridItem>
            <Button
              onClick={closePrint.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="danger"
              style={{
                backgroundColor: "#f44336",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().close}
            </Button>
            <Button
              onClick={print.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="success"
              style={{
                backgroundColor: "#4caf50",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().to_print}
            </Button>
            <Button
              onClick={emailReceipt.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="info"
              style={{
                backgroundColor: "#00acc1",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().email}
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer>{email ? emailReceiptForm() : <div />}</GridContainer>
        <div id="printableView">
          {printerType === "A4" || printerType === "A4 Paper" ? (
            <table cellPadding="0" cellSpacing="0">
              <tr className="top">
                <td colSpan="2">
                  <table>
                    <tr>
                      <td className="title">
                        <img
                          src={
                            baseUrl +
                            "/picture/" +
                            cookies.load("orgId") +
                            "?tag=image&model=Afrijula::Account&r="
                          }
                          width={100}
                          height={100}
                        />
                      </td>
                      <td>
                        {cookies.load("orgName")}
                        <br />
                        {getString().receipt_no}: {props.payment.receipt_number}
                        <br />
                        Date: {dateFormat(props.payment.date)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="2">
                  <table>
                    <tr>
                      <td>
                        {cookies.load("orgAddress") === "null"
                          ? ""
                          : cookies.load("orgAddress")}
                      </td>

                      <td>
                        {cookies.load("orgName")}.<br />
                        {cookies.load("orgContact")}
                        <br />
                        {cookies.load("orgEmail")}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="details">
                <td>
                  BILL TO:
                  {""} {props.customer ? props.customer.name : "none"}
                </td>
              </tr>
              <tr className="details">
                <td>
                  {getString().received_by}:{" "}
                  {props.payment.user_name ? props.payment.user_name : "none"}
                </td>
              </tr>

              <tr className={"details"}>
                <td>
                  {getString().email}:{" "}
                  {props.customer ? props.customer.email : "none"}
                </td>
              </tr>
              <tr className="detail">
                <td>
                  {getString().paid_by}:{" "}
                  {props.payment.details
                    ? props.payment.details.paid_by
                    : "none"}
                </td>
              </tr>

              <tr className={"details"}>
                <td>
                  {getString().payment_method}: {props.payment.payment_option}
                </td>
              </tr>
              <tr className={"details"}>
                <td>
                  {getString().location} : {props.customer.address}
                </td>
              </tr>
              {props.payment.payment_option !== "cash" ? (
                <tr className={"details"}>
                  <td>
                    {getString().bank_payment}: {props.payment.details.bank}
                  </td>
                </tr>
              ) : null}
              {props.payment.payment_option !== "cash" ? (
                <tr className={"details"}>
                  <td>
                    {getString().refNo}: {props.payment.details.bank_number}
                  </td>
                </tr>
              ) : null}

              {props.items.map((item) => {
                return (
                  <div
                    style={{
                      backgroundColor: "#5cb85c",
                      borderRadius: "5px",
                      color: "#f2f2f2",
                    }}
                  >
                    <tr className={"details"}>
                      <td>
                        <b>Description</b>: {item.name}
                      </td>
                    </tr>
                    <tr className={"details"}>
                      <td>
                        <b>Quantity</b>: {item.quantity}
                      </td>
                    </tr>
                    <tr className={"details"}>
                      <td>
                        <b>Unit Price</b>: {currencyFormat(item.unit_price)}
                      </td>
                    </tr>
                    <tr className={"details"}>
                      <td>
                        <b>Total</b>: {currencyFormat(item.total)}
                      </td>
                    </tr>
                  </div>
                );
              })}
              <tr className={"details"}>
                <td>
                  <b>Subtotal</b>: {currencyFormat(props.subTotal)}
                </td>
              </tr>
              <tr className={"details"}>
                <td>
                  <b>Discount</b>: {currencyFormat(props.discount)}
                </td>
              </tr>
              <tr className={"details"}>
                <td>
                  <b>Subtotal less disct</b>:{" "}
                  {currencyFormat(props.subTotal - props.discount)}
                </td>
              </tr>
              <tr className={"details"}>
                <td>
                  <b>Tax</b>: {currencyFormat(props.tax)}
                </td>
              </tr>
              {props.payment.balance ? (
                <>
                  <tr className={"details"}>
                    <td>
                      <b>Previous Payments</b>:{" "}
                      {currencyFormat(
                        (props.total.cents -
                          (props.payment.balance.cents +
                            props.payment.amount.cents)) /
                          100
                      )}
                    </td>
                  </tr>
                  <tr className={"details"}>
                    <td>
                      <b>Total</b>:{" "}
                      {currencyFormat(
                        (props.payment.balance.cents +
                          props.payment.amount.cents) /
                          100
                      )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr className={"details"}>
                  <td>
                    <b>Total</b>: {currencyFormat(props.total)}
                  </td>
                </tr>
              )}

              <tr className={"details"}>
                <td>
                  <b>Amount Paid</b>: {currencyFormat(props.payment.amount)}
                </td>
              </tr>
              <tr className={"details"}>
                <td>
                  <b>Balance</b>:{" "}
                  {props.payment.balance
                    ? currencyFormat(props.payment.balance.cents / 100)
                    : currencyFormat(
                        props.total - props.payment.amount.cents / 100
                      )}
                </td>
              </tr>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileReceipt;
