import React from 'react';
import AsyncSelect from 'react-select/async';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {getString, displaySuccess, displayError} from "assets/js/utils/utils.js";
import $ from "jquery";
import IncomingStock from "components/afrijula/Stocks/IncomingStock.jsx";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import TextField from "@material-ui/core/TextField/TextField";
import Card from "components/common/Card/Card.jsx";

export default class UpdateStockQty extends React.Component {
    state = {
        stock_items: [],
        back: false,
        location: null,
        date: new Date().toISOString().substr(0,10),
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState

    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    saveInvoice = () => {
        let total = 0.0;

        this.state.stock_items.forEach((item) => {
            total += item.total;
        });
        if(this.state.stock_items.length === 0){
            displayError("Please enter at least on item for the invoice ");
            return;
        }


        let data = {location: this.state.location,
            sub_total: total, vat_amount: 0.0,other_tax:0.0, total: total,
            sale_date: this.state.date,
            stock_items: this.state.stock_items, no_supplier: true};

        let location = data.location;
        if(location == null || location.strip === ""){
            displayError("Please select a location");
            return;
        }

        $.ajax({
            method:"POST",
            url: baseUrl+"/afrijula/suppliers/invoice",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: data,

            success: function (result) {
                displaySuccess("Successfully saved stock quantities");
                this.setState({back:true});
                if(this.props.loadInvoice)
                    this.props.loadInvoice(result.id);
            }.bind(this),

            error: function (resp) {
                displayError(resp.responseText);
            }
        });
    };

    locationOptions = (inputValue) => {
        const url = baseUrl + '/afrijula/locations/location?attrs=["name","id"]&model=Afrijula::Locations::Location&match='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;
                for(; i< json.length;i++){
                    opts[i] = {label:json[i].name, value:json[i]._id.$oid};
                }
                return opts;
            }) // my option list array?
            .catch(err => {
                console.log(err);
            });
    };

    updateStockQty = (items) => {

        this.setState({stock_items: items});
    };

    render() {
        if(this.state.back){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState}  returningProps={this.state.callerProps}/>
        }else{
            return (<div>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                        <p><b>{getString().location}:</b>
                            <Card style={{zIndex:60, marginTop:"1px"}}>
                                <AsyncSelect defaultOptions={true}
                                             loadOptions={this.locationOptions}
                                             name="location"
                                             id="location"
                                             onChange={opt => {
                                           this.setState({location: opt.label})}
                                       }
                                             variant="outlined"
                                />
                            </Card>
                        </p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <TextField
                            required
                            id="date"
                            name="date"
                            label={getString().date}
                            type="date"
                            value={this.state.date}
                            defaultValue={this.state.date}
                            onChange={e => {this.handleChange(e)}}
                            margin="normal"
                            variant="outlined"
                        />
                    </GridItem>
                </GridContainer>

                {(this.state.location) ? (<IncomingStock callerProps = {this.getProps()} location={this.state.location}
                                                          adviseChange={this.updateStockQty.bind(this)}
                                                                   callerState = {this.state} callerName = {UpdateStockQty}/>) : <div/>}
                <GridContainer>
                    <GridItem  xs={12} sm={12} md={12}>
                        <Button xs={12} sm={12} md={4} color="danger"  onClick={() => {this.setState({back:true})}}
                                style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                                    minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                        >{getString().cancel}</Button>
                        <Button xs={12} sm={12} md={4} color="success" onClick={this.saveInvoice}
                                style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                    minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                        >{getString().save}</Button>
                    </GridItem>
                </GridContainer>
            </div>);
        }


    }
}
