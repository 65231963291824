import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select, {Async, AsyncCreatable} from 'react-select';
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import MaterialTable from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import $ from "jquery";
import {getItemByLocation,getString} from "assets/js/utils/utils.js";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import TextField from '@material-ui/core/TextField';
import OptionsLoader from "components/afrijula/Stocks/OptionsLoader.jsx";

import 'rodal/lib/rodal.css';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    noPadding: {
        padding: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }


};

class OpeningBalances extends React.Component{

    state = {

        locations: [],
        selectedLocation: null,

        opening_balances:[],
        item_location: '',
        item_quantity: 1,
        item_unit_cost:0,

        options: {}

    };

    loadLocations = () => {
        let the_data = [];

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"filter": ":location.gte => Date.today","attrs":["name","id"],
                "order": [{"by":"desc","attr":"name"}],"model":"Afrijula::Locations::Location"},

            success: function(data){
                $.map(data,function (data) {
                    the_data.push({label:data.name, value:data.name});
                });
                this.setState({
                    locations: the_data,
                    selectedLocation: data.length === 0 ? "" :data[0].name,
                })
            }.bind(this)
        });
    };

    setOptions = (options) => {
        let opts = this.state.options;
        opts = {};
        options.forEach(item => {if(item.attribute != null && opts[item.attribute] != '') opts[item.attribute] = item.value;});
        this.setState({options: opts});
    };

    addItems = () =>{
        let stock_items = this.state.opening_balances;
        let exist = getItemByLocation(stock_items,this.state.selectedLocation,this.state.options);
        let qty = Number(this.state.item_quantity);
        if(exist === null){
            let data = {quantity:qty,unit_cost: this.state.item_unit_cost,location: this.state.selectedLocation, options: this.state.options};
            stock_items.push(data);
        }else{
            stock_items[exist].quantity += qty;

        }
        this.props.updateChanges(this.state.opening_balances);
        this.setState({opening_balances: stock_items,item_quantity:0, item_unit_cost:0});
    };
    deleteItem = (event,row) => {
        let arr =this.state.opening_balances;
        let index = arr.indexOf(row);
        arr.splice(index,1);
        this.setState({opening_balances: arr});

    };

    componentDidMount() {
        this.loadLocations();

    };
    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});

    };
    handleLocationChanged = (opt) => {
        this.setState({selectedLocation:opt.value});
    };
     render() {

         return(
            <div>
                <GridContainer style={{border: "solid 1px #000"}}>
                    <GridItem sm={12} md={12} lg={12}>
                        <h3>Opening Balance</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={4}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={12}>
                                <div  style={{ marginTop: "20px", marginLeft:"10px"}}>
                                    <Select options={this.state.locations} placeholder={getString().location}
                                            defaultValue={this.state.selectedLocation} onChange = {e => {this.handleLocationChanged(e)}}  />


                                </div>
                            </GridItem>
                                <GridItem xs={12}>

                                    <OptionsLoader options={this.state.options} productId={this.state.number} optionsCback={this.setOptions.bind(this)} _type="stock"/>
                                </GridItem>
                            <GridItem xs={12} sm={4} md={4} lg={12}>
                                <TextField
                                    required
                                    id="quantity"
                                    name="item_quantity"
                                    label={getString().table_quantity}
                                    type="text"
                                    value={this.state.item_quantity}
                                    onChange={e => {this.handleChange(e)}}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>

                            <GridItem xs={12} sm={4} md={4} lg={12}>
                                <TextField
                                    required
                                    id="unit_cost"
                                    name="item_unit_cost"
                                    label={getString().unit_cost}
                                    type="text"
                                    value={this.state.item_unit_cost}
                                    onChange={e => {this.handleChange(e)}}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>


                            <GridItem sm={12} md={2} lg={12}>
                                <Button color="success" onClick={this.addItems.bind(this)}
                                        style={{backgroundColor: "#4caf50", margin: "1.3125rem 1px", minWidth: "auto",
                                            minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                >{getString().add}</Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem sm={12} md={12} lg={8} style={{marginTop: 15+'px'}}>
                        <MaterialTable
                            title="Items Per Location"
                            columns={ [
                                { title:  getString().location,field:'location'},
                                { title:  getString().quantity, field: 'quantity'},
                                { title:  getString().unit_cost, field: 'unit_cost'},
                                {
                                    title: '', field: 'options', readonly:true, render: rowData => {return (rowData.options) ? Object.keys(rowData.options).map((option)=>{return <div style={{backgroundColor:'#FFC300', borderRadius:'5px', color:'black'}}>{option}:{rowData.options[option]}</div>}) : null} ,
                                    cellStyle:{padding:'1px',width:'70px'}, headerStyle:{padding:'1px', with:'100px'}, editable:'never'
                                }
                            ]}
                            data={this.state.opening_balances}
                            options={{
                                search:false,
                                paging: false,
                                showEmptyDataSourceMessage: false
                            }}

                            actions={[
                                {   icon:'delete',
                                    iconProps: {fontSize:'small'},
                                    style:{float: 'right'},
                                    onClick:(event, row)=> {
                                        this.deleteItem(event,row);
                                    },



                                },

                            ]}


                        />

                    </GridItem>
                </GridContainer>
            </div>
        );
     }
}

export default withStyles(styles)(OpeningBalances);
