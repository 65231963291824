import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Image from "components/afrijula/Utils/ImageUtil.jsx";
import SaleDetails from "components/afrijula/AllDetails/ReceiptTemplate.jsx";
import AddDetails from "components/afrijula/Ecom/AddDetails.jsx";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import StockPurchase from "components/afrijula/AllDetails/SupplierHistory.jsx";
import EditStocks from "components/afrijula/AddForm/AddStock.jsx"
import {numberFormat,getString, currencyFormat, dateFormat,compileQuery} from "assets/js/utils/utils.js";
import Box from '@material-ui/core/Box'
import {displayError, displaySuccess} from "../../../assets/js/utils/utils";
import Link from '@material-ui/core/Link';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class StockDetails extends React.Component{

    constructor(props) {
        super(props);

        this.salesTableRef = React.createRef();
        this.purchaseTableRef = React.createRef();
        this.removalsTableRef = React.createRef();
    }

    state = this.props.state || {
        addPayment: false,
        stockInvoiceData: null,
        stockSalesData: [],
        back: false,
        addstock: false,
        stockData: this.props.stockData,
        addstockinv: false,
        showMoreImages: false,

        callerName: this.props.callerName,
        callerState: this.props.callerState,
        callerProps: this.props.callerProps,
    };

    tableChangeQuery = (baseUrl,data) => {
        $.ajax({
            method: "GET",
            url: baseUrl+"/afrijula/stocks/stock",
            dataType: "json",
            data: {"QueryData":data},

            success: function(res){

            }
        });
    };

    rowSelectSale = (event,colData)  => {
        let sale_id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl+"/afrijula/sales/sales/"+sale_id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({historySaleData: result});
            }
        });
    };

    rowSelectPurchase = (event, colData)  => {
        let invoice_id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl+"/afrijula/suppliers/invoice/"+invoice_id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({stockInvoiceData: result});
            }
        });
    };

    loadStock = (query,resolve,reject) => {
        let stock_id = this.state.stockData._id.$oid;

        let url = baseUrl + "/afrijula/stocks/stock/quantities";
        url += '?size=' + query.pageSize;
        url += '&page=' + (query.page + 1);
        url += '&new_format=true';
        url += '&id='+stock_id;
        fetch(url, {dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
            });
    };

    loadSales = (query,resolve,reject) => {
        let url = baseUrl + "/afrijula/sales/sales";
        let data = {"query": "{'not_stock':'false','$or': [{'sales_items.parts': {'$in':['"+this.state.stockData.number+"']}}," +
                "{'sales_items.number': '"+this.state.stockData.number+"'}]}","size": query.pageSize, "page":query.page+1, "new_format":true, "include":"{customer: {only: :name}}",
            "attrs":["date","description","total","tran_type","id","sub_total","discount","total_tax","pricing"],
            "order": [{"by":"desc","attr":"date"},{"by":"asc","attr":"total"}],"model":"Afrijula::Sales::Sale"};

        url += "?"+$.param(data);

        fetch(url, {dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                });
            });
    };
    
    loadPurchases = (query,resolve,reject) => {
        let stock_number = this.state.stockData.number;
        let url = compileQuery(query, (baseUrl + "/afrijula/suppliers/invoice"),
            ["due_date","number","total","balance","id"], [{"by":"desc","attr":"date"}],
            "Afrijula::Suppliers::Invoice",null,"'stock_items.number': {'$eq':'"+stock_number+"'}");


        fetch(url, {dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {

                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
            });
    };

    loadRemovals = (query,resolve,reject) => {

        let stock_number = this.state.stockData.number;

        let url = compileQuery(query, (baseUrl + "/afrijula/stocks/stock_removal"),
            ["stocks","date","id","location","description","stock_items"],
            [{"by":"asc","attr":"date"}],
            "Afrijula::Stocks::StockRemoval", null, "'stock_items.number': '"+stock_number+"'",
            null);

        fetch(url, {dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
            });
    };

    getProps = () =>{
        return this.returningProps || this.props;
    };

    updateStock = (id, stock) => {
        this.setState({stockData: {...stock}, addstock: false});
    };

    removeStock = () => {
        let _this= this;
        if(window.confirm("Are you sure you want to delete "+this.state.stockData.name)){
            $.ajax({
                method: "DELETE",
                url: baseUrl+"/afrijula/stocks/stock/"+this.state.stockData._id.$oid,
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                dataType: "json",
                success: function(res){
                    displaySuccess(res.success);
                    _this.setState({back:true});
                },error: function(error){
                    displayError(error.responseText);
                }
            });
        }
    }

    backFill = (data) => {
        let d = this.state.stockData;
        Object.assign(d, data);
        this.setState({stockData: d});
    }

    componentWillUnmount() {
        if(this.state.callerProps.updateMe){
            this.state.callerProps.updateMe(true)
        }
    }

    render() {
        if(this.state.stockInvoiceData){
            let data = this.state.stockInvoiceData;
            this.state.stockInvoiceData = null;
            return <StockPurchase invoiceData = {data} callerName={StockDetails}  callerProps = {this.getProps()} callerState = {this.state} />
        }
        if(this.state.historySaleData){
            let data = this.state.historySaleData;
            this.state.historySaleData = null;
            return <SaleDetails callerProps={this.getProps()} callerState = {this.state} salesData={data} callerName={StockDetails} />;
        }
        if(this.state.back){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState}  returningProps={this.state.callerProps}/>
        }
        if(this.state.addstock){
            return <EditStocks callerProps={this.getProps()} stockData={this.state.stockData} callerState = {this.state}
                               callerName={StockDetails}  editStock={true} saveStockCback={this.updateStock.bind(this)}/>
        }
        if(this.state.e_shop_data){
            this.state.e_shop_data = false;
            return <AddDetails callerProps = {this.getProps()} callerState = {this.state}
                               objectType='Afrijula::Stocks::Stock' backFill={this.backFill.bind(this)}
                               shoppableData = {this.state.stockData} callerName = {StockDetails} />
        }
        else{

            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={9}>
                                    <Button color="success"   style={{float: 'left'}} onClick={() =>{this.setState({addstock: true})}}
                                            style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">edit</i>
                                        {getString().edit}
                                    </Button>
                                    <Button color="warning" style={{float: 'left'}} onClick={() =>{this.removeStock()}}
                                            style={{margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px", padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">delete</i>
                                        {getString().remove}
                                    </Button>
                                    {(cookies.load('onlineShopping') == 'enabled') ? <Button color="success"   style={{float: 'left'}} onClick={() =>{this.setState({e_shop_data: true})}}
                                                  style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                      minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">e-shop</i>
                                        {getString().online_shopping}
                                    </Button> : null}

                                </GridItem>
                                <GridItem xs={3} >
                                    <Button color="danger" onClick={() =>{this.setState({back: true})}}
                                            style={{backgroundColor: "#f44336",margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight:"auto",
                                                padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }}
                                    >{getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{getString().details}</h4>
                                </CardHeader>

                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer>
                                                        <GridItem xs={6} md={4} lg={4}>
                                                            <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                                                   callerName={"StockDetails"} tag="picture" label={getString().upload_pic} upSize={900}/>
                                                        </GridItem>
                                                        <GridItem xs={6} md={4} lg={4}>
                                                            <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                                                   callerName={"StockDetails"} tag="picture_1" label={getString().upload_pic} upSize={900}/>
                                                        </GridItem>
                                                        <GridItem xs={6} md={4} lg={4}>
                                                            <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                                                   callerName={"StockDetails"} tag="picture_2" label={getString().upload_pic} upSize={900}/>
                                                        </GridItem>
                                                        <GridItem xs={6} md={4} lg={4}>
                                                            <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                                                   callerName={"StockDetails"} tag="picture_3" label={getString().upload_pic} upSize={900}/>
                                                        </GridItem>
                                                        <Link onClick={()=>{this.setState({showMoreImages:true})}} style={{cursor:'pointer'}}>{getString().more}...</Link>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString()._name}:</b> <b>{this.state.stockData.name}</b></p>
                                                </GridItem>

                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Box border={1} borderRadius={16} borderColor="grey.500" padding={1}>
                                                        <label>{getString().wholesale}</label>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <p><b>{getString().wholesale_mteric}:</b> <b>{this.state.stockData.wholesale_metric}</b></p>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <p><b>{getString().sellable_units}:</b> <b>{this.state.stockData.sellable_units}</b></p>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <p><b>{getString().wholesale_price}:</b> <b>{currencyFormat(this.state.stockData.wholesale_price)}</b></p>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </Box>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        <GridItem xs={12} sm={6} md={6}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString().category}:</b> <b>{this.state.stockData.category}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString().number}:</b> <b>{this.state.stockData.number}</b></p>
                                                </GridItem>
                                            </GridContainer>

                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString().sellable}:</b> <b>{(this.state.stockData.sellable) ? 'yes' : 'no'}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString().retail_metric}:</b> <b>{this.state.stockData.retail_metric}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h5>{getString().unit_price}</h5>
                                                    <GridContainer>
                                                        <GridItem spacing={1} xs={12}>
                                                            <GridContainer>
                                                                <GridItem xs={4}>{getString().default} </GridItem>
                                                                <GridItem xs={4}>{currencyFormat(this.state.stockData.unit_price)}</GridItem>
                                                            </GridContainer>
                                                        </GridItem>
                                                        <GridItem xs={12}><h6>Options</h6></GridItem>
                                                        {(this.state.stockData.options_prices) ? (Object.keys(this.state.stockData.options_prices)).map(key => {
                                                            let option = this.state.stockData.options_prices[key];
                                                            return <GridItem xs={12}>
                                                                <GridContainer>
                                                                    <GridItem xs={4}>{JSON.stringify(option.options).replace(/"|\[|\]|\{|\}/gi, '')}</GridItem>
                                                                    <GridItem xs={4}>{currencyFormat(option.price)}</GridItem>
                                                                </GridContainer>
                                                            </GridItem>
                                                        }) : null}
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString().unit_cost}:</b> <b>{currencyFormat(this.state.stockData.unit_cost)}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p><b>{getString().quantity}:</b> <b>{this.state.stockData.quantity}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12} lg={6}>
                                            <Card>
                                                <Table
                                                    title={getString().quantities}
                                                    data={query=>new Promise((resolve,reject)=>{
                                                        this.loadStock(query,resolve,reject);
                                                    })}
                                                    options={{
                                                        exportButton: true,
                                                        filtering: true,search:false,
                                                        sorting:true,
                                                        cellStyle:{padding:'5px'}
                                                    }}
                                                    columns={[
                                                        {title: getString().location, field:'name', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().quantity, field:'quantity',render: rowData => numberFormat(rowData.quantity), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().unit_cost, field:'unit_cost',render: rowData => currencyFormat(rowData.unit_cost), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().value, field: 'value',render: rowData => currencyFormat(rowData.value), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {
                                                            title: '', field: 'options', readonly:true, render: rowData => {return (rowData.options) ? Object.keys(rowData.options).map((option)=>{return <div style={{backgroundColor:'#FFC300', borderRadius:'5px', color:'black'}}>{option}:{rowData.options[option]}</div>}) : null} ,
                                                            cellStyle:{padding:'1px',width:'70px'}, headerStyle:{padding:'1px', with:'100px'}, editable:'never'
                                                        }
                                                    ]}
                                                />
                                            </Card>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={6}>
                                            <Card>
                                                <CardHeader color="warning">
                                                    <h5 >{getString().stock_sales}</h5>
                                                </CardHeader>
                                                <Table
                                                    tableRef={this.salesTableRef}
                                                    data={query=>new Promise((resolve,reject) => {
                                                        this.loadSales(query,resolve,reject);
                                                    })}
                                                    pageSize={10}
                                                    columns={[
                                                        {title: getString().type, field: 'tran_type', render: rowData => {return rowData.tran_type.replace('_',' ')}, cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().pricing, field:'pricing', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().description, field:'description', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().date, field:'date', render: rowData => dateFormat(rowData.date), cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().total,field:"total", render: rowData => currencyFormat(rowData.total), cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                    ]}
                                                    onRowClick={this.rowSelectSale}
                                                    title=""
                                                    options={{
                                                        exportButton: true,
                                                        filtering: true,
                                                        grouping:false,
                                                        sorting:true,
                                                        selection:true,
                                                        showTitle:false,
                                                        pageSize:8,
                                                        cellStyle:{padding:'5px'}
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'refresh',
                                                            tooltip: 'Refresh',
                                                            isFreeAction: true,
                                                            onClick: () => this.salesTableRef.current && this.salesTableRef.current.onQueryChange(),
                                                        }
                                                    ]}
                                                />
                                            </Card>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={6}>
                                            <Card>
                                                <CardHeader color="info">
                                                    <h4 >{getString().stock_purchase}</h4>
                                                </CardHeader>
                                                <Table
                                                    tableRef={this.purchaseTableRef}
                                                    data={query=>new Promise((resolve,reject) => {
                                                        this.loadPurchases(query,resolve,reject);
                                                    })}
                                                    title=""
                                                    columns={[
                                                        {title: getString().date,field:'due_date', render: rowData => dateFormat(rowData.due_date), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().inv_number, field:'number', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().total, field: 'total',render: rowData => currencyFormat(rowData.total), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().balance, field:'balance',render: rowData => currencyFormat(rowData.balance), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}}
                                                    ]}
                                                    onRowClick = {this.rowSelectPurchase}
                                                    pageSize={10}
                                                    options={{
                                                        exportButton: true,filtering: true,search:false,
                                                        sorting:true,grouping:false,selection:true,showTitle:false,
                                                        pageSize:10,
                                                        cellStyle:{padding:'5px'}
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'refresh',
                                                            tooltip: 'Refresh',
                                                            isFreeAction: true,
                                                            onClick: () => this.purchaseTableRef.current && this.purchaseTableRef.current.onQueryChange(),
                                                        }
                                                    ]}
                                                />
                                            </Card>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={6}>
                                            <Card>
                                                <CardHeader color="info">
                                                    <h4>{getString().stock_removal}</h4>
                                                </CardHeader>
                                                <Table
                                                    tableRef={this.removalsTableRef}
                                                    data={query=>new Promise((resolve,reject) => {
                                                        this.loadRemovals(query,resolve,reject);
                                                    })}
                                                    title=""
                                                    columns={[
                                                        {title: getString().description,field:'description',render: rowData => (rowData.description) ? rowData.description : getString().not_specified, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().items, field:'stock_items.name', render: rowData => {
                                                                const stock = rowData.stock_items.filter(item => {
                                                                    return item.number === this.state.stockData.number
                                                                });
                                                                return (stock.length === 0) ? '' : stock[0].name;
                                                            }, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().quantity, field:'stock_items.quantity', render: rowData => {
                                                            const stock = rowData.stock_items.filter(item =>{
                                                                    return item.number === this.state.stockData.number
                                                                });
                                                                return (stock.length ===0) ? '' : stock[0].quantity}, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().date,field:'date',  render: rowData => (rowData.date) ? dateFormat(rowData.date) : getString().not_specified, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                        {title: getString().location, field: 'location', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}}
                                                    ]}
                                                    pageSize={10}
                                                    options={{
                                                        exportButton: true,filtering: true,search:false,
                                                        sorting:true,grouping:false,selection:true,showTitle:false,
                                                        pageSize:10,
                                                        cellStyle:{padding:'5px'}
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'refresh',
                                                            tooltip: 'Refresh',
                                                            isFreeAction: true,
                                                            onClick: () => this.removalsTableRef.current && this.removalsTableRef.current.onQueryChange(),
                                                        }
                                                    ]}
                                                />
                                            </Card>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    {(this.state.showMoreImages === true) ? <Dialog
                        open={this.state.showMoreImages}
                        onClose={()=>{this.setState({showMoreImages: false})}}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">More Pictures </DialogTitle>
                        <DialogContent>
                            <GridContainer>
                                <GridItem xs={12} sm={6}><strong>(1)</strong>
                                    <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                           callerName={"StockDetails"} tag="picture_1" label={getString().upload_pic} upSize={900}/>
                                </GridItem>
                                <GridItem xs={12} sm={6}><strong>(2)</strong>
                                    <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                           callerName={"StockDetails"} tag="picture_2" label={getString().upload_pic} upSize={900}/>
                                </GridItem>
                                <GridItem xs={12} sm={6}><strong>(3)</strong>
                                    <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                           callerName={"StockDetails"} tag="picture_3" label={getString().upload_pic} upSize={900}/>
                                </GridItem>
                                <GridItem xs={12} sm={6}><strong>(4)</strong>
                                    <Image model={'Afrijula::Stocks::Stock'} id={this.state.stockData._id.$oid} style = {{height: 1+"px", weight: 5+"px"}}
                                           callerName={"StockDetails"} tag="picture_4" label={getString().upload_pic} upSize={900}/>
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{this.setState({showMoreImages: false})}} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog> : null}
                </div>
            );
        }
    }
}

export default withStyles(styles)(StockDetails);
