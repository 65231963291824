import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select, { Async } from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import MaterialTable from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import {
  getToday,
  currencyFormat,
  currencyValue,
  displaySuccess,
  displayError,
  computeCurrency,
  getString,
} from "assets/js/utils/utils.js";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import TextField from "@material-ui/core/TextField";
import CardHeader from "components/common/Card/CardHeader.jsx";
import NewStock from "components/afrijula/AddForm/AddStock.jsx";
import OptionsLoader from "components/afrijula/Stocks/OptionsLoader.jsx";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  noPadding: {
    padding: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class IncomingStock extends React.Component {
  constructor(props) {
    super(props);
    this.prodRef = React.createRef();
    this.state = {
      itemsChanged: false,
      addSuppInv: false,
      back: false,
      locations: [],

      tempName: "",

      stock_items: [],
      today: "",
      addSupplier: false,
      tag: "suplierInvoice_Stock",
      fromSupplier: this.props.fromSupplier,

      name: null,
      number: null,
      location: this.props.location,
      options: {},
      unit_cost: null,
      quantity: null,
      current_quantity: null,
      unit_price: null,
      total: null,

      clearForm: false,
      supplierData: this.props.supplierData,
      createdSupplierId: this.props.createdSupplierId,
      newProduct: null,
      addProduct: false,
    };
  }

  getItemByNumber = (arr, value, opts) => {
    var optionsMatch = (options1, options2) => {
      if (options1.length === options2.length) {
        let keys = Object.keys(options1);

        for (let i = 0; i < keys.length; i++) {
          if (options1[keys[i]] != options2[keys[i]]) return false;
        }
        return true;
      }
      return false;
    };

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].number === value && optionsMatch(opts, arr[i].options)) {
        return i;
      }
    }
    return null;
  };

  productOptions = (inputValue) => {
    let url =
      baseUrl +
      '/afrijula/stocks/stock?model=Afrijula::Stocks::Stock&attrs=["id","name","number","quantity","unit_price"]&match=' +
      inputValue;

    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;
        for (; i < json.length; i++) {
          opts[i] = {
            label: json[i].name,
            value: json[i].number,
            id: json[i]._id.$oid,
            unit_price: json[i].unit_price.cents/100,
            current_quantity: json[i].quantity
          };
        }
        return opts;
      }) // my option list array?
      .catch((err) => {
        console.log(err);
      });
  };

  rowSelect = (colData, cellMeta) => {
    var newData = this.state.historyData;
    var rowdata = this.state.data[cellMeta.dataIndex];

    let i = 0;
    for (; i < rowdata.length; i++) {
      newData[i] = rowdata[i];
    }
    this.setState({ historyData: newData });
    this.setState({ historyDetails: !this.state.historyDetails });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addItems = () => {
      let sales_items = this.state.stock_items.slice();

      if (!this.state.name || this.state.name == "") {
        displayError("You must add a valid stock item");
        return;
      }

      if (!this.state.quantity || this.state.quantity == 0) {
        displayError("You must set a valid quantity");
        return;
      }

      if (!this.state.unit_cost || this.state.unit_cost == 0) {
        displayError("You must set a valid unit cost");
        return;
      }
      let qty = Number(this.state.quantity);
      let total = computeCurrency(this.state.quantity, this.state.unit_cost, "*");
      let unit_cost = this.state.unit_cost;
      let unit_price = this.state.unit_price;
      let exist = this.getItemByNumber(
        sales_items,
        this.state.number,
        this.state.options
      );
      if (exist == null) {
        let data = {
          name: this.state.name,
          number: this.state.number,
          unit_cost: unit_cost,
          quantity: qty,
          total: total,
          options: this.state.options,
          unit_price: unit_price
        };
        sales_items.push(data);
      } else {
        sales_items[exist].quantity += qty;
        sales_items[exist].options = this.state.options;
        //the current unit cost will always overwrite the old cost
        sales_items[exist].unit_cost = unit_cost;
        sales_items[exist].unit_price = unit_price;
        sales_items[exist].number = this.state.number;
        sales_items[exist].total = sales_items[exist].quantity * unit_cost;
      }

    this.state.options = {};
      this.setState({
        stock_items: sales_items,
        unit_cost: 0,
        quantity: 0,
        options: {},
      });
      if (this.props.adviseChange) this.props.adviseChange(sales_items);
  };

  componentDidMount() {
    this.setState({
      sale_date: getToday(),
      shipping_date: getToday(),
      due_date: getToday(),
    });
  }

  autoFocus = () => {
    if (this.prodRef) this.prodRef.focus();
  };

  selecteStockChanged = (opt) => {
    this.setState({ name: opt.label, number: opt.value, current_quantity: opt.current_quantity, unit_price: opt.unit_price });
  };

  setOptions = (options) => {
    let opts = this.state.options;
    opts = {};
    options.forEach((item) => {
      if (item.attribute != null && opts[item.attribute] != "")
        opts[item.attribute] = item.value;
    });
    this.setState({ options: opts });
  };

  deleteItem = (event, row) => {
    let arr = this.state.stock_items;
    let index = arr.indexOf(row);
    arr.splice(index, 1);
    this.setState({ stock_items: arr });
  };

  addProduct = (inputValue) => {
    let _product = this.state.productName;
    _product = inputValue;
    this.setState({
      newProduct: _product,
      addProduct: true,
    });
  };

  incoming = (classes) => {
    const header = [
      {
        title: getString().product_number,
        field: "number",
        cellStyle: { padding: "1px", width: "160px" },
        headerStyle: { padding: "1px", width: "160px" },
        disablePadding: true,
      },
      {
        title: getString().table_name,
        field: "name",
        readonly: true,
        value: this.tempName,
        editable: "never",
        cellStyle: { padding: "1px", width: "160px" },
        headerStyle: { padding: "1px", width: "160px" },
        colSpan: 2,
      },
      {
        title: getString().unit_cost,
        field: "unit_cost",
        render: (rowData) => {
          if (rowData) return currencyFormat(rowData.unit_cost);
        },
        currencySetting: {
          locale: "en",
          currencyCode: "GMD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
        cellStyle: { padding: "1px", width: "100px" },
        headerStyle: { padding: "1px", width: "100px" },
      },
      {
        title: getString().table_quantity,
        field: "quantity",
        type: "numeric",
        cellStyle: { padding: "10px", width: "100px" },
        headerStyle: { padding: "10px", width: "100px" },
      },
      {
        title: getString().total,
        field: "total",
        readonly: true,
        render: (rowData) => {
          if (rowData) return currencyFormat(rowData.total);
        },
        currencySetting: {
          locale: "en",
          currencyCode: "GMD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
        cellStyle: { padding: "1px", width: "70px" },
        headerStyle: { padding: "1px", with: "100px" },
        editable: "never",
      },
      {
        title: "",
        field: "options",
        readonly: true,
        render: (rowData) => {
          return rowData.options
            ? Object.keys(rowData.options).map((option) => {
                return (
                  <div
                    style={{
                      backgroundColor: "#FFC300",
                      borderRadius: "5px",
                      color: "black",
                    }}
                  >
                    {option}:{rowData.options[option]}
                  </div>
                );
              })
            : null;
        },
        cellStyle: { padding: "1px", width: "70px" },
        headerStyle: { padding: "1px", with: "100px" },
        editable: "never",
      },
    ];

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={"lecketInlineItems"}>
              <CardHeader color="info">
                <h4>Inventory Arrivals</h4>
              </CardHeader>
              <GridContainer>
                <GridItem sm={12} md={12} lg={4}>
                  <form
                    className={classes.container}
                    autoComplete="off"
                    style={{ zIndex: 300 }}
                  >
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={4}
                        md={4}
                        lg={12}
                        style={{ zIndex: "10000" }}
                      >
                        <Card
                          className={classes.productSelector}
                          style={{
                            marginTop: "20px",
                            marginLeft: "10px",
                            zIndex: 10,
                          }}
                        >
                          <AsyncCreatableSelect
                            className={classes.productSelector}
                            style={{
                              marginTop: "20px",
                              marginLeft: "10px",
                            }}
                            autoFocus
                            defaultOptions
                            loadOptions={this.productOptions}
                            placeholder={getString().select_item}
                            ref={(c) => (this.prodRef = c)}
                            name="product"
                            id="product"
                            onChange={(opt) => {
                              this.selecteStockChanged(opt);
                            }}
                            onCreateOption={(e) => [this.addProduct(e)]}
                          />
                        </Card>
                      </GridItem>
                      <GridItem xs={12} style={{ marginLeft: "10px" }}>
                        <OptionsLoader
                          options={this.state.options}
                          productId={this.state.number}
                          optionsCback={this.setOptions.bind(this)}
                          _type="stock"
                        />
                      </GridItem>
                      {
                          this.state.current_quantity &&
                          <GridItem xs={12} sm={4} md={3} lg={12}>
                              <TextField
                                  required
                                  id="current_quantity"
                                  name="current_quantity"
                                  label="Actual Quantity"
                                  type="text"
                                  value={this.state.current_quantity}
                                  margin="normal"
                                  variant="outlined"
                                  style={{ marginLeft: "10px" }}
                                  inputProps={{
                                    readOnly: true
                                  }}
                              />
                          </GridItem>
                      }
                      <GridItem xs={12} sm={4} md={3} lg={12}>
                        <TextField
                          required
                          id="quantity"
                          name="quantity"
                          label={getString().table_quantity}
                          type="text"
                          value={this.state.quantity}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                          style={{ marginLeft: "10px" }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={4} md={3} lg={12}>
                        <TextField
                          required
                          id="unit_cost"
                          name="unit_cost"
                          label={getString().unit_cost}
                          type="text"
                          value={this.state.unit_cost}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                          style={{ marginLeft: "10px" }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={3} lg={12}>
                        <TextField
                            required
                            id="unit_price"
                            name="unit_price"
                            label={"Unit Price"}
                            type="text"
                            value={this.state.unit_price}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="normal"
                            variant="outlined"
                            style={{ marginLeft: "10px" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                      </GridItem>
                      <GridItem sm={12} md={2} lg={12}>
                        <Button
                          color="success"
                          onClick={this.addItems.bind(this)}
                          style={{
                            backgroundColor: "#4caf50",
                            margin: "1.3125rem 1px",
                            minWidth: "auto",
                            minHeight: "auto",
                            fontSize: "12px",
                            color: "#ffffff",
                            padding: "12px 30px",
                            borderRadius: "3px",
                            verticalAlign: "middle",
                            marginLeft: "10px",
                          }}
                        >
                          {getString().add}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={8}>
                  <MaterialTable
                    style={{ margin: 15 }}
                    title="New Inventory Items"
                    columns={header}
                    data={this.state.stock_items}
                    actions={[
                      {
                        icon: "delete",
                        iconProps: { fontSize: "small" },
                        style: { float: "right" },
                        onClick: (event, row) => {
                          this.deleteItem(event, row);
                        },
                      },
                    ]}
                    options={{
                      search: false,
                      paging: false,
                      showEmptyDataSourceMessage: false,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.back) {
      if (this.state.fromSupplier) {
        return this.props.parentBack();
      } else {
        let ChildComponent = this.state.callerName;
        this.props.parentBack();
        return (
          <ChildComponent
            state={this.state.callerState}
            returningProps={this.state.callerProps}
          />
        );
      }
    }
    if (this.state.addProduct) {
      return (
        <NewStock
          newStockName={this.state.newProduct}
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={IncomingStock}
          fromSupplierInvoice={true}
          editStock={false}
          noBack={true}
        />
      );
    } else {
      return this.incoming(classes);
    }
  }
}

export default withStyles(styles)(IncomingStock);
