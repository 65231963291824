import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import {displayError, removeDuplicates, displaySuccess, getString, getToday} from "assets/js/utils/utils.js";
import AsyncCreatableSelect from 'react-select/async-creatable';

import AssetCategories from "components/afrijula/Asset_Register/AssetCategories";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class AddAsset extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.props.state || {
            back: false,

            callerName: this.props.callerName,
            callerProps: this.props.callerProps,
            callerState: this.props.callerState,
            asset_items: [],
            dates: [],
            globalAssetData: {
                purchase_date: '',
                category: {},
                batch_number: '',
            },
            categoryItemsData: {
                description: '',
                life_span: 5,
                unit_cost: '',
                depreciation: 20,
                model_brand: {},
                quantity: '',
            },
            category_items: [],
            addItems: false,
        };
    };

    handleChangeGlobal = (e) =>{
        let _globalAssetData = this.state.globalAssetData;
        _globalAssetData[e.target.name] = e.target.value;
        this.setState({
            globalAssetData: _globalAssetData
        });
    };

    handleChange = (e) => {
        let depreciation = 0;
        let _assetData = this.state.assetData;
        _assetData[e.target.name] = e.target.value;

        if(e.target.name === "life_span"){
            depreciation = 100 / parseInt(e.target.value);
            _assetData['depreciation'] = isNaN(depreciation) ? 0 : depreciation;
        }
        this.setState({
            assetData: _assetData
        });
    };
    
    closeAddingAsset = () => {
        if (this.props.closeEdit) {
            this.props.closeEdit();
        } else {
            this.setState({back: true});
        }
    };

    handleSelectValue = (opt) => {
        let val = {value: opt.value, label: opt.label};
        let _globalAssetData = this.state.globalAssetData;
        _globalAssetData.category = val;
        this.setState({
            globalAssetData: _globalAssetData
        });
    };

    handleModelValue = (opt) => {
        let val = {value: opt.value, label: opt.label};
        let _categoryItemsData = this.state.categoryItemsData;
        _categoryItemsData.model_brand = val;
        this.setState({
            categoryItemsData: _categoryItemsData
        });
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    deleteAssetCategory = (event, row) => {
        let _data = [...this.state.category_items];
        _data.splice(row.tableData.id, 1);
        this.setState({
            category_items: _data
        });
    };

    addCategoryItems = () => {
        let _category_items = [...this.state.category_items];
        let _categoryItemsData = this.state.categoryItemsData;

        if (!_categoryItemsData.description || _categoryItemsData.description === '') {
            displayError(getString().err_description);
            return;
        }
        let data = {
            life_span: _categoryItemsData.life_span,
            unit_cost: _categoryItemsData.unit_cost,
            depreciation: _categoryItemsData.depreciation,
            model_brand: _categoryItemsData.model_brand.label,
            quantity: _categoryItemsData.quantity,
            description: _categoryItemsData.description,
        };

        _category_items.push(data);
        this.setState({category_items: _category_items});
        this.clearFields();
    };

    //This method makes sure that the user enters only numbers for amount and quantity
    isValid = (val) =>{
        return (isNaN(+(val)));
    };

    handleChangeCategoryData = (e) => {
        let depreciation = 0;
        let _categoryItemsData = this.state.categoryItemsData;
        let val = e.target.value;

        if(e.target.name === 'unit_cost' && this.isValid(val)){
            displayError(getString().err_unit_cost);
            return;
        }
        if(e.target.name === 'quantity' && this.isValid(val)){
            displayError(getString().err_qty);
            return;
        }
        else{
            _categoryItemsData[e.target.name] = val;

            if(e.target.name === "life_span"){
                depreciation = 100 / parseInt(e.target.value);
                _categoryItemsData['depreciation'] = isNaN(depreciation) ? 0 : depreciation;
            }
            this.setState({
                categoryItemsData: _categoryItemsData
            });
        }
    };

    categoryOptions = (category) => {
        let _this = this;
        const url = baseUrl + '/afrijula/asset_register/assets?attrs=["category"]&model=Afrijula::AssetRegister::Asset&unique=category'+ '&match=' + category;
        return fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for (; i < json.length; i++) {
                    opts[i] = {label: json[i], value: json[i]};
                }

                let arrFiltered = removeDuplicates(opts);
                let _globalAssetData = _this.state.globalAssetData;
                _globalAssetData.category = arrFiltered[0];
                _this.setState({
                    globalAssetData: _globalAssetData
                });

                return arrFiltered;
            })
            .catch(err => {

            });
    };

    nextStep = () =>{
        this.clearFields();
        if (this.state.globalAssetData.batch_number === '') {
            displayError(getString().err_asset_number);
            document.getElementById("batch_number").focus();
            return;
        }
        else{
            this.setState({
                addItems: true
            })
        }
    };

    clearFields = () => {
        let _categoryItemsData = {
            ...this.state.categoryItemsData,
            quantity: '',
            description: '',
            unit_cost: '',
        };
        this.setState({
            categoryItemsData: _categoryItemsData
        })
    };

    generateBatchNumber = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/asset_register/assets/generate_batch_number",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{generating: true},
            success: function (data){
                {displaySuccess(getString().batch_num_generated);}
                let _globalAssetData = _this.state.globalAssetData;
                _globalAssetData.batch_number = data.number;
                _this.setState({
                    globalAssetData: _globalAssetData
                });
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    componentDidMount() {
        let _globalAssetData = this.state.globalAssetData;
        _globalAssetData.purchase_date = getToday();
        this.setState({
            globalAssetData: _globalAssetData
        });
    };

    render() {
        const {classes} = this.getProps();

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.newAsset = false;
            return <ChildComponent state={caller_state} returningProps={this.state.callerProps}
                                   newAssetData={this.state.assetData}/>
        }
        if(this.state.addItems){
            return (
                <AssetCategories
                    callerProps={this.getProps()}
                    callerState={this.state}
                    callerName={AddAsset}
                    handleChangeCategoryData={this.handleChangeCategoryData}
                    addCategoryItems={this.addCategoryItems}
                    deleteAssetCategory={this.deleteAssetCategory}
                    category_items={this.state.category_items}
                    clearFields={this.clearFields}
                    handleModelValue={this.handleModelValue}
                />
            );
        }
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4>{(this.props.assetData) ? getString().edit_asset : getString().new_asset}</h4>
                            </CardHeader>
                            <CardBody>
                                <form className={classes.container} autoComplete="off">
                                    {(this.props.assetItemData) ?
                                        null
                                        :
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <span>{getString().category}</span>
                                                <AsyncCreatableSelect defaultOptions loadOptions={this.categoryOptions}
                                                                      name="category"
                                                                      id="category" value={this.state.globalAssetData.category}
                                                                      onChange={(opt, evt) => {
                                                                    this.handleSelectValue(opt)
                                                                }}/>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <TextField
                                                    required
                                                    id="batch_number"
                                                    name="batch_number"
                                                    label={getString().batch_number}
                                                    className={classes.textField}
                                                    value={this.state.globalAssetData.batch_number}
                                                    onChange={(e) => {
                                                        this.handleChangeGlobal(e)
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <Button xs={12} sm={2} md={2} color="success" onClick={this.generateBatchNumber.bind(this)}
                                                        style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",marginTop:"24px",
                                                            minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                                >{getString().generate}</Button>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <TextField
                                                    label={getString().purchase_date}
                                                    required
                                                    id="purchase_date"
                                                    name="purchase_date"
                                                    inputProps={{style: {padding: "2px", height: "3em"}}}
                                                    value={this.state.globalAssetData.purchase_date}
                                                    onChange={(e) => {
                                                        this.handleChangeGlobal(e)
                                                    }}
                                                    margin="normal"
                                                    type="date"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    }
                                </form>
                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button xs={12} sm={6} md={2} color="danger" onClick={this.closeAddingAsset}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                            }}>{getString().back}</Button>
                                    <Button xs={12} sm={6} md={2} color="success" onClick={this.nextStep.bind(this)}
                                            style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                            }}>{getString().next}</Button>
                                </GridItem>
                            </CardFooter>

                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

AddAsset.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddAsset);