import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";

import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Image from "components/afrijula/Utils/ImageUtil.jsx";
import SaleDetails from "components/afrijula/AllDetails/ReceiptTemplate.jsx";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import EditProduct from "components/afrijula/AddForm/AddProduct.jsx"
import {getString, currencyFormat, dateFormat, applyPackaging,displayError,displaySuccess} from "assets/js/utils/utils.js";
import Rodal from "rodal";
import TextField from "@material-ui/core/TextField/TextField";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class ProductDetails extends React.Component{

    constructor(props) {
        super(props);

        this.salesTableRef = React.createRef();
        this.partsTableRef = React.createRef();
        this.partNameRef = React.createRef();
    }

    state = this.props.state || {

        addPayment: false,

        prodSalesData: [],

        currentParts: {},

        showProdParts: false,

        partsHeader: [
            {title: getString().description, field: 'description', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().number, field: 'number', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().quantity, field: 'quantity', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],

        productSalesHeader:  [
            {title: getString().type, field: 'tran_type', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}, render: rowData => {return rowData.tran_type.replace('_',' ')}},
            {title: getString().pricing, field:'pricing', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().description, field:'description', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().date, field:'date', render: rowData => dateFormat(rowData.date), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().total,field:"total", render: rowData => currencyFormat(rowData.total), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],

        back: false,
        addProd: false,

        productData: this.props.productData,

        callerName: this.props.callerName,
        callerState: this.props.callerState,
        callerProps: this.props.callerProps

    };


    rowSelectPart = (event,colData) => {
        let part_id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl+"/afrijula/sales/product_parts/"+part_id,
            data: {product_id: this.state.productData._id.$oid},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                let data = _this.state.currentParts;
                data.description = result.description;
                data.number = result.number;
                data.quantity = result.quantity;
                data.model = result.model;
                data._id = result._id;
                data.estimate = result.estimate;
                _this.setState({currentParts: data, showProdParts: true});

            }
        });
    };

    rowSelectSale = (event,colData)  => {
        let sale_id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl+"/afrijula/sales/sales/"+sale_id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({historySaleData: result});
            }
        });
    };

    refreshParts = () => {
        this.partsTableRef.current && this.partsTableRef.current.onQueryChange()
    };

    loadSales = (query,resolve,reject) => {
        let url = baseUrl + "/afrijula/sales/sales";
        let data = {"query": "{'sales_items.number': '"+this.state.productData.number+"','not_stock':true}",
            "size": query.pageSize, "page":query.page+1, "new_format":true, "include":"{customer: {only: :name}}",
            "attrs":["date","description","total","tran_type","id","sub_total","discount","total_tax","pricing"],
            "order": [{"by":"desc","attr":"date"},{"by":"asc","attr":"total"}],"model":"Afrijula::Sales::Sale"};

        url += "?"+$.param(data);

        fetch(url, {dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total,
                });
            });
    };

    getProps = () =>{
        return this.returningProps || this.props;
    };

    loadComposition = (query,resolve, reject) => {
        let url = baseUrl + "/afrijula/sales/product_parts";
        let data = {id: this.state.productData._id.$oid};
        url += "?"+$.param(data);

        fetch(url, {dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.totalCount,
                });
            });
    };

    getParts = () => {

            return <Table
                tableRef={this.partsTableRef}
                data={query=>new Promise((resolve,reject) => {
                    this.loadComposition(query,resolve,reject);
                })}
                pageSize={5}
                columns={this.state.partsHeader}
                onRowClick={this.rowSelectPart}
                title={getString().composition}
                options={{
                    exportButton: false,
                    filtering: false,
                    sorting:true,
                    search: false,
                    selection:true,
                    showTitle:true,
                    pageSize:5
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: getString().refresh_table,
                        isFreeAction: true,
                        onClick: () => this.partsTableRef.current && this.partsTableRef.current.onQueryChange(),
                    },
                    {
                        tooltip: getString().remove_item,
                        icon: 'delete',
                        onClick: (evt, data) => {this.removePart(data)}
                    },
                    {
                        tooltip: getString().add_composition,
                        icon: 'add',
                        isFreeAction:true,
                        onClick: (evt, data) => {this.showParts(evt,data)}
                    }
                ]}
            />
    };

    removePart = (data) => {

        let _this = this;
        $.ajax({
            method: 'DELETE',
            url: baseUrl + "/afrijula/sales/product_parts/"+this.state.productData._id.$oid,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data: {items: data.map(item => {return item._id.$oid})},

            success: function (data){
                {displaySuccess(getString().item_removed);}
                _this.refreshParts();
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    showParts = () => {
        let parts = this.state.currentParts;
        parts._id = undefined;
        parts.description = '';
        parts.number = '';
        parts.quantity = 1;
        parts.estimate = false;
        this.setState({showProdParts: true, currentParts: parts});
    };

    handlePartChange = (e) => {
        let parts = this.state.currentParts;
        parts[e.target.name] = e.target.value;
        this.setState({currentParts: parts});
    };

    stockOptions = (inputValue) => {
        const url = baseUrl + '/afrijula/sales/sales/sellable?stockOnly=true&attrs=["id","name","unit_price","number"]&to=name&match='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(data => {
                let opts = [];
                let i = 0;
                for(; i< data.length;i++){
                    opts[i] = {label: data[i].name, value: data[i]._id.$oid, number:data[i].number, type: data[i].type};
                }
                return opts;
            })
            .catch(err => {
                console.log(err);
            });
    };

    updateProduct = (id, data) => {
        if(data){
            let d = this.state.productData;
            d.name  = data.name;
            d.category = data.category;
            d.billable = data.billable;
            d.product = data.product;
            d.product_number = data.product_number;
            d.unit_price = data.unit_price;
            this.setState({productData:d});
        }

    };

    getItem = (opt)  => {
        let part = this.state.currentParts;
        part.description = opt.label;
        part.number = opt.number || opt.description;
        part.model = opt.type;
        this.setState({currentParts: part});
    };

    viewFormParts = () => {
        return <div style={{backgroundColor: 'white', borderColor:'blue'}}>
            <h5>{getString().item}</h5>
            <p>{getString().add_product_item}</p>
            <form autoComplete="off">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <AsyncCreatableSelect autoFocus defaultOptions loadOptions={this.stockOptions}
                                              name="part_name" id="part_name"
                                              ref={c => this.partNameRef = c}
                                              placeholder={getString().select_item}
                                              value={{label: this.state.currentParts.description,
                                   value: ((this.state.currentParts._id) ? this.state.currentParts._id.$oid : '')}}
                                              onChange={opt => {this.getItem(opt)}}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            required
                            id="quantity"
                            name="quantity"
                            label={getString().quantity}
                            type="number"
                            value={this.state.currentParts.quantity}
                            onChange={e => {this.handlePartChange(e)}}
                            margin="normal"
                            variant="outlined"
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            required
                            id="estimate"
                            name="estimate"
                            label={getString().estimate}
                            type="boolean"
                            value={this.state.currentParts.estimate}
                            onChange={e => {this.handlePartChange(e)}}
                            margin="normal"
                            variant="outlined"
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button onClick={this.hideParts.bind(this)} color="danger">
                            {getString().cancel}
                        </Button>
                        <Button onClick={this.saveParts.bind(this)} color="success">
                            {getString().save}
                        </Button>
                    </GridItem>
                </GridContainer>
            </form>
        </div>;
    };

    saveParts = () => {

        let method = this.state.currentParts._id ? 'PUT' : 'POST';
        let url =  baseUrl + "/afrijula/sales/product_parts/";
        if(method === 'PUT')
            url += this.state.currentParts._id.$oid;

        let data = this.state.currentParts;
        let product_number = this.state.productData.number;

        if(data.description === ""){
            displayError(getString().select_description);
            document.getElementById('part_name').focus();
            return;
        }

        if(data.quantity === undefined || data.quantity === 0){
           displayError(getString.qtygtzero);
            document.getElementById('quantity').focus();
            return;
        }
        let _this = this;
        $.ajax({
            method: method,
            url: url,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data: {parts: [data], product_number: product_number},

            success: function (data){
                {displaySuccess(getString().added_subItem);}
                _this.hideParts();
                _this.refreshParts();
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    hideParts = () => {
        this.setState({showProdParts: false});
    };

    componentWillUnmount() {
        if(this.state.callerProps.updateMe){
            this.state.callerProps.updateMe(true)
        }
    }

    discontinue = (toggle) => {
        if(window.confirm(getString().are_you_sure+((toggle) ? getString().discontinue : getString().continue)+getString().item+"?")){
            let _this = this;
            $.ajax({
                method: 'PUT',
                url: baseUrl + "/afrijula/sales/products/"+this.state.productData._id.$oid,
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                dataType: "json",
                data: {discontinue: toggle},

                success: function (data){
                    displaySuccess(getString().changed_prod_status);
                    let pdata = _this.state.productData;
                    pdata.discontinue = toggle;
                    _this.setState({productData: pdata});
                },
                error: function (response) {
                    {displayError(response.responseText);}
                }
            });
        }
    };

    render() {
        const {classes} = this.getProps();

        if(this.state.historySaleData){
            let data = this.state.historySaleData;
            this.state.historySaleData = null;
            return <SaleDetails callerProps={this.getProps()} callerState = {this.state} salesData={data} callerName={ProductDetails} />;
        } else if(this.state.back){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState}  returningProps={this.state.callerProps}/>
        }else if(this.state.addProd){
            this.state.historySaleData = null;
            this.state.addProd = false;
            return <EditProduct callerProps={this.getProps()} callerState = {this.state}  callerName={ProductDetails}
                                productData={this.state.productData} editProduct={true} saveProductCback={this.updateProduct.bind(this)}/>
        }else{
            return (
                <div>

                    <GridContainer>
                        <GridItem xs={9} sm={9} md={9}>
                            <Button color="success"   style={{float: 'left'}} onClick={() =>{this.setState({addProd: true})}}
                                    style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                            ><i className="material-icons">edit</i>
                                {getString().edit}
                            </Button>
                            {(this.state.productData.discontinue) ?
                                <Button color="info"   style={{float: 'left'}} onClick={() =>{this.discontinue(false)}}
                                        style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                            minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                ><i className="material-icons">open</i>
                                    {getString().continue}
                                </Button> :
                            <Button color="info"   style={{float: 'left'}} onClick={() =>{this.discontinue(true)}}
                                    style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                            ><i className="material-icons">close</i>
                                {getString().discontinue}
                            </Button> }
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <Button color="danger" onClick={() =>{this.setState({back: true})}}
                                    style={{backgroundColor: "#f44336",margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight:"auto",
                                        padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }}
                            >{getString().back}
                            </Button>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{getString().details}</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6} lg={3}>
                                            <GridContainer>
                                                <GridItem xs={6} sm={6} md={6}>
                                                    <Image model={'Afrijula::Sales::Product'} id={this.state.productData._id.$oid} style={{height: "2px", width: "5px"}}
                                                           label={getString().upload_pic} callerName={"ProductDetails"} tag="picture"  upSize={900}/>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={5} lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p><b>{getString()._name}: </b><b>{this.state.productData.name}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p><b>{getString().category}: </b> <b>{this.state.productData.category}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p><b>{getString().number}: </b> <b>{this.state.productData.number}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p><b>{getString().unit_price}: </b> <b>{currencyFormat(this.state.productData.unit_price)}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p><b>{getString().billable}: </b> <b>{this.state.productData.billable ? 'yes' : 'no'}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p><b>{getString().discontinued}: </b> <b>{this.state.productData.discontinue ? 'yes' : 'no'}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={6}>
                                            {this.getParts()}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="warning">
                                    <h5 >{getString().sales}</h5>
                                </CardHeader>
                                <Table
                                    tableRef={this.salesTableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadSales(query,resolve,reject);
                                    })}
                                    pageSize={10}
                                    columns={this.state.productSalesHeader}
                                    onRowClick={this.rowSelectSale}
                                    title=""
                                    options={{
                                        exportButton: true,
                                        filtering: true,
                                        grouping:true,
                                        sorting:true,
                                        selection:true,
                                        showTitle:false,
                                        pageSize:10
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',
                                            tooltip: getString().refresh_table,
                                            isFreeAction: true,
                                            onClick: () => this.salesTableRef.current && this.salesTableRef.current.onQueryChange(),
                                        },
                                        {
                                            tooltip: getString().remove_item,
                                            icon: 'delete',
                                            onClick: (evt, data) => {this.removeItem(evt,data)}
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <Rodal visible={this.state.showProdParts} width={450} showMask={false} onClose={this.hideParts.bind(this)}>
                        {this.viewFormParts()}
                    </Rodal>
                </div>

            );
        }
    }
}

export default withStyles(styles)(ProductDetails);
