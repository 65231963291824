import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";

import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import {displaySuccess,displayError,validateEmail,phonenumber,getString} from "assets/js/utils/utils.js";
import SaveButton from "../CustomComponents/SaveButton";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class AddingCustomer extends React.Component {

    state = {
        back: false,
        customerData: null,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        editCustomer: this.props.editCustomer,

    };

    getDefaultCustomer = () =>{
        this.state.customerData = (this.props.customerData) ? {
            name: this.props.customerData.name,
            phone_number: this.props.customerData.phone_number,
            address: this.props.customerData.address,
            email: this.props.customerData.email,
            _id: this.props.customerData._id
        } : {
            name: '',
            address: '',
            email: '',
            phone_number: '',
        };

    };

    handleChange = (e) => {
        let customer = this.state.customerData;
        customer[e.target.name] = e.target.value;
        this.setState({
            customerData: customer
        });
    };

    newCustomer = () => {
        let customer_name = this.state.customerData.name;
        let customer_address = this.state.customerData.address;
        let customer_email = this.state.customerData.email;
        let phone_number = this.state.customerData.phone_number;

        let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;

        if(customer_name === "" || !reg.test(customer_name)){
            displayError(getString().err_customer_name);
            document.getElementById("name").focus();
            return;
        }
        if( customer_email !=="" && !validateEmail(customer_email)){
            displayError(getString().email_err);
            document.getElementById("email").focus();
            return;
        }
        if(phone_number !=="" && !phonenumber(phone_number)){
            displayError(getString().phone_num_err);
            document.getElementById("phone_number").focus();
            return;
        }
        let id;
        if(this.state.editCustomer){
            id = this.state.customerData._id.$oid;
        }

        let _method = (this.state.editCustomer) ? "PUT" : "POST";
        let _data = this.state.customerData;
        let _this = this;
        return $.ajax({
            method: _method,
            url: baseUrl + "/afrijula/sales/customers/"+(id || ''),
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {"customer_name": customer_name, "customer_address": customer_address, "customer_email": customer_email,"phone_number": phone_number},
            success: function (result) {
                if(this.props.updateMe)
                    this.props.updateMe(_data);
                if(_this.props.saveCustomerCback) {
                    _this.props.saveCustomerCback(result.customer._id.$oid);
                }
                displaySuccess(getString().customer_added);
                _this.closeAddingCustomer();
            }.bind(this),
            error:function (e) {
                displayError(e.responseText);
            }
        });

    };

    closeAddingCustomer = () =>{
        if(this.props.closeEdit){
            this.props.closeEdit();
        }else{
            this.setState({back: true});
        }
    };

    componentWillMount() {
        this.getDefaultCustomer();
    }

    render() {
        const { classes } = this.props;

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.addcustomer = false;
            return <ChildComponent state = {caller_state} returningProps = {this.state.callerProps} newCustomerData={this.state.customerData}/>
        }

        return (
           <div xs={12} sm={12} md={12}>
               <GridContainer>
                   <GridItem>
                       <Card>
                           <CardHeader color="primary">
                               {(this.props.editCustomer) ? <h4 >{getString().edit}</h4> : <h4 >{getString().add_customer}</h4>}
                           </CardHeader>

                           <CardBody>
                               <form className={classes.container}  autoComplete="off">
                                   <GridContainer>
                                       <GridItem xs={12} sm={6} md={4}>
                                           <TextField
                                               required
                                               id="name"
                                               name="name"
                                               label={getString().name}
                                               className={classes.textField}
                                               value={this.state.customerData.name}
                                               onChange={(e) => {this.handleChange(e)}}
                                               margin="normal"
                                               variant="outlined"
                                           />
                                       </GridItem>
                                       <GridItem xs={12} sm={6} md={4}>
                                           <TextField
                                               id="address"
                                               name="address"
                                               label={getString().address}
                                               className={classes.textField}
                                               value={this.state.customerData.address}
                                               onChange={(e) => {this.handleChange(e)}}
                                               margin="normal"
                                               variant="outlined"
                                           />
                                       </GridItem>
                                       <GridItem xs={12} sm={6} md={4}>
                                           <TextField
                                               required
                                               id="email"
                                               name="email"
                                               label={getString().email}
                                               className={classes.textField}
                                               value={this.state.customerData.email}
                                               onChange={(e) => {this.handleChange(e)}}
                                               type="email"
                                               margin="normal"
                                               variant="outlined"
                                           />
                                       </GridItem>
                                       <GridItem xs={12} sm={6} md={4}>

                                           <TextField
                                               id="phone_number"
                                               name="phone_number"
                                               label={getString().telephone}
                                               className={classes.textField}
                                               value={this.state.customerData.phone_number}
                                               onChange={(e) => {this.handleChange(e)}}
                                               margin="normal"
                                               variant="outlined"
                                           />
                                       </GridItem>
                                   </GridContainer>
                               </form>

                           </CardBody>
                           <CardFooter>
                               <GridItem xs={12} sm={12} md={6}>
                                   <Button xs={12} sm={6} md={2} color="danger" onClick={this.closeAddingCustomer}
                                           style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                                               minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}>{getString().cancel}</Button>
                                   <SaveButton method={this.newCustomer} title={getString().save} />
                               </GridItem>
                           </CardFooter>

                       </Card>
                   </GridItem>
               </GridContainer>
           </div>
        );
    }
}

AddingCustomer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddingCustomer);
