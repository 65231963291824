import React, { Component } from 'react'
import NewCustomerProductDetails from './NewCustomerProductDetails'
import ContractQustonnaire from './ContractQustonnaire'

import AsyncCreatableSelect from 'react-select/async-creatable';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";
import Add_Stock_Product from "components/afrijula/Sell/Add_Stock_Product.jsx";
import SellTableTemplate from "components/afrijula/Material-Table/SellTableTemplate.jsx";
//Model imports
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// include styles
import 'rodal/lib/rodal.css';
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);


class EnterProducts extends Component {
    constructor(props) {
        super(props);
        this.prodRef = React.createRef();

    this.state= {
        Open: false,  //model
        saleItems:this.props.callerState.saleItems,
        newData: null,
        productData: null,

        editQty: false,
        currentRow:null,
        editQtyValue:0,
        tempRowData:null,
        newProduct: '',

        cashAmount: this.props.callerState.cashAmount,
        itemQuantity: 1,
        selectedItem: '',
        discountAmount: this.props.callerState.discountAmount,
        discount: this.props.callerState.discount,

        total: this.props.callerState.total,
        change: this.props.callerState.change,
        saleType: 'retail', // has to be changed
    }
};

    upQty = (action,rowData) =>{
        let newQuantity =  Number(rowData.quantity) + 1 ;

       let items = this.state.saleItems;
        let item = items[rowData.tableData.id];
        item.quantity = newQuantity;
        item.total = newQuantity * Number(item.unit_price);
        items[rowData.tableData.id] = item;
         this.setState({saleItems: items});
        let discount =  this.state.discount;

        this.props.updateSales(this.state.saleItems,discount);
        this.autoFocus();
    };

    downQty = (action,rowData) =>{
        let newQuantity =  Number(rowData.quantity) - 1 ;
        if (newQuantity <= 0)
            return;
        let items = this.state.saleItems;
        let item = items[rowData.tableData.id];
        item.quantity = newQuantity;
        item.total = newQuantity * Number(item.unit_price);
        items[rowData.tableData.id] = item;
        this.setState({saleItems: items});
        let discount =  this.state.discount;

        this.props.updateSales(this.state.saleItems,discount);
        this.autoFocus();
    };

    deleteItem = (arr) => {
        this.setState({saleItems: arr});
        this.autoFocus();
    };

    saveEditQty = () =>{
        let _data = this.state.currentRow;
        const _type = this.state.saleType;
        const _qty = this.state.editQtyValue;

        if(!_data.wholesale_metric || !_data.sellable_units || _data.wholesale_metric == _data.retail_metric)
            displaySuccess(getString().no_wholesale_info)


        if(_type != _data.metric && _data['type'] === "stock" && _data.wholesale_metric && _data.sellable_units && _data.wholesale_metric != _data.retail_metric){
            if(_data.metric === "whole sale" && _type == 'retail'){
                _data.unit_price = _data.unit_price / _data.sellable_units;
            }else if(_data.metric === 'retail' && _type == 'whole sale'){
                _data.unit_price = _data.unit_price * _data.sellable_units;
            }
            _data.metric = _type;
        }

        _data.quantity = _qty;

        _data.total = _data.unit_price * _data.quantity;

         const data = this.state.saleItems;
         const index = data.indexOf(_data);

        data[index] =_data;
        this.setState({saleItems: data,editQty: false,}); //@ams->check->fix
        this.props.updateSales(this.state.saleItems,this.state.discountAmount);  //@ams->check->fix

    };

    updateSalesSales = (data,discount,tax) =>{
        this.props.updateSales(data,discount);
    }
     saveQty = (newQuantity,rowData) =>{
        let items = this.state.saleItems;
        let item = items[rowData.tableData.id];
        item.quantity = newQuantity;
        item.total = newQuantity * Number(item.unit_price);
        items[rowData.tableData.id] = item;
        this.setState({saleItems: items});
        let discount =  this.state.discount;

        this.props.updateSales(this.state.saleItems,discount);
        this.autoFocus();
    }

    editQtyUpdate = (_data,qty,editBool,rowDataVal) =>{
        this.setState({
            currentRow: _data,
            editQtyValue: qty,
            editQty: editBool,
            tempRowData: rowDataVal,
        });
    }

     handleChange = (e) => {
         this.props.handleChange(e);
    };

    handleDiscountChange = (event) =>{
        this.props.handleDiscountChange(event);
   };

    autoFocus = () => {
        if(this.prodRef)
            this.prodRef.focus();
    };

    addProduct = (inputValue) => {
        this.setState({
            newProduct: inputValue,
            addProduct: true
        });
    };

    stockOptions = (inputValue) => {

        const url = baseUrl + '/afrijula/sales/sales/sellable?attrs=["id","name","unit_price","category","number","wholesale_metric","sellable_units",retail_metric"]&to=name&match='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(data => {
                let opts = [];
                let i = 0;
                for(; i< data.length;i++){
                  if(data[i].billable === true){
                      opts[i] = {label: data[i].name, value: data[i]._id.$oid, unit_price: data[i].unit_price, sellable_units: data[i].sellable_units,
                        number:data[i].number, wholesale_metric: data[i].wholesale_metric,category: data[i].category,
                        retail_metric: data[i].retail_metric,type: data[i].type, billable: data[i].billable};
                  }
                }
                return opts;
            })
            .catch(err => {
                console.log(err);
            });
    };

    getByValue = (arr, value) => {
        for (let i=0; i < arr.length; i++) {
            if (arr[i].item === value) {
                return i;
            }
        }
        return null;
    };

    getItem = (opt, fromAddForm = false)  => {
        const {discount} = this.props.callerState;
        let qty =  Number(this.state.itemQuantity);
        let selectedItem = opt;
        let unitprice = selectedItem.unit_price == null ? 0 : Number(selectedItem.unit_price.cents)/ 100.0;
        let type = selectedItem.type;
         let sellable_units = selectedItem.sellable_units;
        let wholesale_metric = selectedItem.wholesale_metric;
        let retail_metric = selectedItem.retail_metric;

        let newData = this.state.saleItems;
        // let total = this.state.total;
        let total = this.props.callerState.total;
        let metric = 'retail';
        let exist = this.getByValue(newData, opt.label);
        if (newData.length === 0 || exist == null){
            if (type !== 'one-off' &&this.state.saleType === "whole sale" && wholesale_metric && sellable_units && wholesale_metric != retail_metric) {
                unitprice = sellable_units * unitprice;
                metric = this.state.saleType;
            }
            total = qty * unitprice;
            const name = (fromAddForm) ? opt.name : opt.label;
            let metric_unit = (metric === "whole sale") ? wholesale_metric : retail_metric;
            newData.push({item: name, number:selectedItem.number, quantity: qty,sellable_units: sellable_units,
                metric_units : metric_unit, wholesale_metric: wholesale_metric, unit_price: unitprice, total: total,
                category: opt.category,
                type: type, billable: opt.billable,metric: metric,sellingPrice:unitprice});

        }
        else{
            newData[exist].quantity += qty;
            newData[exist].total  =  newData[exist].quantity * unitprice;
        }

        let _discount =  discount;
        this.props.updateSales(newData,_discount);
        this.autoFocus();

    };

    getProps = () =>{
        return this.props.returningProps || this.props;
    };

    saveProductCback = (data, type) =>{
        this.autoFocus.bind(this);
        if(data)
            this.getItem(data, true);
    }

    back = () => {
        this.props.handleBack('NewCustomerProductsDetailsUI');
    };

    Qustionnaire = () => {
        if(this.validate()){
            this.props.handleNext('QustonnaireUI',this.state.saleItems,this.state.change);
        }else{
            alert(`${getString().alert_sel_one_product}`);
        }
    };

    validate = () => {
        if(this.state.saleItems.length == 0){
            return false;
        }else{
            return true;
        }
    };
    //modal
    handleClickOpen = (rowData) => {
          this.setState({
            Open:true
          })
      };
    handleClose = () => {
        this.setState({
            Open:false
          })
      };
    render() {
         const {discount,total,discountType,discountSymbol} = this.props.callerState;
        if (this.state.addProduct) {
            this.state.addProduct = false;
            return <Add_Stock_Product noBack={true} productName={this.state.newProduct} callerProps = {this.getProps()}
                                      callerState = {this.props} callerName = {EnterProducts} editProduct={false} saveProductCback={this.saveProductCback.bind(this)}/>;
        }else{
        return (
                <div style={{marginTop: -45}}>
                    <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.Open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                      {getString().Update_product}
                    </DialogTitle>
                    <DialogContent dividers>
                      <Typography gutterBottom>
                      <div>
                        <Grid container spacing={3}>
                            <Grid item xs>
                             <TextField
                                disabled
                                label= {getString().item}
                                id="outlined-basic"
                                value={this.state.tempRowData?this.state.tempRowData.item:''}
                                variant="outlined"
                             />
                            </Grid>
                            <Grid item xs>
                            <TextField
                                id={this.tempRowData?this.state.tempRowData.number:''}
                                label= {getString().quantity}
                                type="number"
                                name="quantity"
                                inputProps={{ min: "0"}}
                                value={this.state.editQtyValue}
                                onChange={(e) => {this.handleChange(e)}}
                                variant="outlined"
                                />
                            </Grid>
                            <Grid item xs>
                            </Grid>
                        </Grid>
                      </div>
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={this.handleClose} color="primary">
                        {getString().save}
                      </Button>
                    </DialogActions>
                  </Dialog>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 style={{textAlign:'center'}}>{getString().ch_products}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} spacing={2}>
                                        <GridContainer>
                                            <GridItem xs={6} sm={6} md={6} >
                                                <Card style={{zIndex:60}}>
                                                    <AsyncCreatableSelect autoFocus defaultOptions loadOptions={this.stockOptions}
                                                                          placeholder={getString().Select_a_product}
                                                                          name="category" id="category"
                                                                          ref={c => this.prodRef = c}
                                                                          onChange={opt => {
                                                                        this.getItem(opt)
                                                                    }}
                                                                          onCreateOption={e =>[this.addProduct(e)]}
                                                    />
                                                </Card>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                            <SellTableTemplate Items={this.state.saleItems} updateSales={this.updateSalesSales.bind(this)} upItemsAfterDelete={this.deleteItem.bind(this)} saveQty={this.saveQty.bind(this)}
                                                     discount={discount} editQtyUpdate={this.editQtyUpdate.bind(this)}/>
                                            </GridItem>
                                        </GridContainer>
                                        <br/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                             <form autoComplete="on">
                                            <GridContainer>
                                            <GridItem xs={2} sm={2} md={2}>
                                            </GridItem>
                                            <GridItem xs={4} sm={4} md={4}>
                                                <span><b>{getString().discount_type}</b></span>
                                                <RadioGroup row={true} aria-label="discountType" name="discountType" value={discountType} onChange={this.handleDiscountChange.bind(this)}>
                                                <FormControlLabel value="fixed" control={<Radio />} label={getString().fixed} />
                                                <FormControlLabel value="percentage" control={<Radio />} label={getString().percentage} />
                                                </RadioGroup>
                                            </GridItem>
                                                <GridItem xs={4} sm={4} md={4}>
                                                <b>{getString().discount}</b>
                                                    <TextField
                                                        type="number"
                                                        name="discount"
                                                        id="discount"
                                                        inputProps={{ min: "0"}}
                                                        value={discount}
                                                        style={{
                                                            marginBottom: 3,
                                                            border: 0,
                                                            fontWeight: "bold",
                                                            width: 110,
                                                            marginTop: 15,
                                                            marginRight: 3
                                                        }}
                                                        onChange={e => {
                                                            this.handleChange(e)
                                                        }}
                                                    />
                                                    <span> <b>{discountSymbol}</b> </span>
                                                </GridItem>
                                                <GridItem xs={2} sm={2} md={2}>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                             <form autoComplete="on">
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4} >
                                                <Button xs={4} sm={6} md={2} color="danger" onClick={this.back.bind(this)}
                                                style={{backgroundColor: "#f44336", margin: ".3125rem 1px",float: 'left', minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                               >{getString().back}</Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={4} md={4}>
                                                <b>{getString().total}</b>
                                                    <TextField
                                                        type="text"
                                                        disabled={true}
                                                        name="total"
                                                        id="total"
                                                        defaultValue="0"
                                                        value={currencyFormat(total)}
                                                        style={{marginBottom: 3, border: 0, fontWeight: "bold",marginTop: 15}}
                                                        onChange={e => {
                                                            this.handleChange(e)
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={4} md={4} >
                                                <Button xs={12} sm={4} md={2} color="success" onClick={this.Qustionnaire.bind(this)}
                                            style={{backgroundColor: "#4caf50", right: '0px', minWidth: "auto", verticalAlign:'center',float: 'right',
                                                minHeight:"auto", fontSize: "12px", borderRadius: "3px"}}
                                               >{getString().next}</Button>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </div>

        )
    }
    }
}

export default EnterProducts
