import React from "react";
import LoadingDots from "../LoadingDots";
import "./style.css";

const DisabledUI = (props) => {
  return (
    <div className="disabledUI">
      <h1 className="disabledUI__header">This feature is currently disabled</h1>
      <div className="u-center">
        <button className="disabledUI__button" onClick={props.method}>
          Activate {props.feature}
        </button>
      </div>
      {props.loading ? <LoadingDots /> : null}
      <div className="disableUI__about">
        <p>
          <b>About {props.feature}: </b>
          {props.about}
        </p>
      </div>
    </div>
  );
};

export default DisabledUI;
