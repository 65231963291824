import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import {
  displaySuccess,
  displayError,
  getString,
} from "assets/js/utils/utils.js";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import CardHeader from "components/common/Card/CardHeader.jsx";

import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography/Typography";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import AddCustomer from "../AddForm/AddingCustomer";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class CustomerNoteDetails extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      customerNotes: [],
      noteSummary: "",
      id: "",
      customerData: this.props.customerData,
      noteDetail: "",
      noteItem: {},
      // addNote: false,
      // editNote: false
    };
  }

  addCustomerNote = () => {
    this.setState({ addNote: true });
  };

  reload = () => {
    window.location.reload(1);
  };

  editCustomerNote = (item) => {
    const { text, title, _id } = item;
    let __id = _id.$oid;
    this.setState({
      editNote: true,
      noteSummary: title,
      noteDetail: text,
      id: __id,
    });
  };

  deleteCustomerNote = (data) => {
    const { _id } = data;
    let _this = this;
    let id = _id.$oid;
    let del = window.confirm("Are you sure you want to delete?");
    if (del) {
      $.ajax({
        method: "DELETE",
        url: baseUrl + "/afrijula/sales/customer_notes/" + id,
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(result) {
          if (result) {
            displaySuccess(result.success);
            _this.loadCustomerNotes();
          } else {
            displayError("Something went wrong. Cannot delete this note");
            _this.setState({
              addNote: false,
              editNote: false,
            });
          }
        },
        error: function(error) {
          displayError(error.responseText);
        },
      });
    }
  };

  completeNote = () => {
    let _this = this;
    let id;
    if (this.state.editNote) {
      id = this.state.id;
    }

    let _method = _this.state.editNote ? "PUT" : "POST";
    $.ajax({
      method: _method,
      url: baseUrl + "/afrijula/sales/customer_notes/" + (id || ""),
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        title: this.state.noteSummary,
        text: this.state.noteDetail,
        note_id: id,
        customer_id: this.props.customerId,
        sale_id: this.props.saleId,
      },

      success: function(result) {
        if (result) {
          displaySuccess(result.success);
          let data = _this.state.customerNotes;
          data.push(result.note);
          if (id) {
            _this.loadCustomerNotes();
          }
          _this.setState({
            customerNotes: data,
            addNote: false,
            editNote: false,
          });
        } else {
          displayError("Could not save note now");
          _this.setState({
            addNote: false,
            editNote: false,
          });
        }
      },

      error: function(resp) {
        displayError(resp.responseText);
      },
    });
  };

  loadCustomerNotes = () => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/customer_notes/",
      data: {
        attrs: ["id", "text", "created_by", "title", "created_at"],
        query: this.props.saleId
          ? "{sale_id: '" + this.props.saleId + "'}"
          : "{customer_id: '" + this.props.customerId + "'}",
        model: "Afrijula::Sales::CustomerNote",
      },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ customerNotes: result });
      },
    });
  };

  componentDidMount() {
    this.loadCustomerNotes();
  }

  render() {
    return (
      <div>
        <span>
          <h5 style={{ display: "inline" }}> Notes</h5>
          <Button
            block={false}
            justIcon={true}
            size={"sm"}
            color="success"
            onClick={this.addCustomerNote.bind(this)}
            style={{
              backgroundColor: "#4caf50",
              margin: ".3125rem 1px",
              minWidth: "auto",
              minHeight: "auto",
              fontSize: "12px",
              color: "#ffffff",
              padding: "12px 30px",
              borderRadius: "3px",
              verticalAlign: "middle",
            }}
          >
            <i className="material-icons">add</i>
          </Button>
        </span>

        <List style={{ height: this.props.height, overflowY: "auto" }}>
          {this.state.customerNotes.map((item) => {
            console.log({ item });
            return (
              <ListItem>
                <ListItemText
                  primary={item.title}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="div"
                        variant="body2"
                        color="textPrimary"
                      >
                        {item.text}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <Button
                  block={false}
                  justIcon={true}
                  size={"sm"}
                  color="success"
                  onClick={() => this.editCustomerNote(item)}
                  style={{
                    backgroundColor: "#4caf50",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                >
                  <i className="material-icons">edit</i>
                </Button>
                <Button
                  block={false}
                  justIcon={true}
                  size={"sm"}
                  color="success"
                  onClick={() => this.deleteCustomerNote(item)}
                  style={{
                    backgroundColor: "red",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                >
                  <i className="material-icons">delete</i>
                </Button>
              </ListItem>
            );
          })}
        </List>
        <Dialog
          open={this.state.addNote}
          onClose={() => {
            this.setState({ addNote: false, editNote: false });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="noteSummary"
              label="Summary"
              type="text"
              name="noteSummary"
              onChange={(e) => {
                this.setState({ noteSummary: e.target.value });
              }}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="noteDetail"
              label="details"
              multiline={true}
              type="text"
              name="noteDetail"
              onChange={(e) => {
                this.setState({ noteDetail: e.target.value });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ addNote: false });
              }}
              color="error"
            >
              Cancel
            </Button>
            <Button onClick={this.completeNote} color="success">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.editNote}
          onClose={() => {
            this.setState({ editNote: false });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="noteSummary"
              label="Summary"
              type="text"
              name="noteSummary"
              value={this.state.noteSummary}
              onChange={(e) => {
                this.setState({ noteSummary: e.target.value });
              }}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="noteDetail"
              label="details"
              multiline={true}
              type="text"
              name="noteDetail"
              value={this.state.noteDetail}
              onChange={(e) => {
                this.setState({ noteDetail: e.target.value });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ editNote: false });
              }}
              color="error"
            >
              Cancel
            </Button>
            <Button onClick={this.completeNote} color="success">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(CustomerNoteDetails);
