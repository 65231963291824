import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableHeader from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import countryList from 'react-select-country-list';
import SaveButton from "../CustomComponents/SaveButton";
import CardHeader from "../../common/Card/CardHeader";
import Icon from "@material-ui/core/Icon/Icon";
import {numberFormat, currencyFormat, getString, compileQuery,showReportUtility, canSeeDash, dateFormat} from "assets/js/utils/utils.js";
import MUITable from "material-table";
import {displayError, displaySuccess} from "../../../assets/js/utils/utils";
import TextField from "@material-ui/core/TextField/TextField";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Link from '@material-ui/core/Link';
import HelpUtil from "../Utils/HelpUtil";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});
const countries = countryList().getData();

class Settlements extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

    }

    state = {

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        back: false,
        tilesData:{
            unsettled_amount: ''
        },
        settlementType: '',
        chooseType: false,
        accountData: {name: '',number: '', bban: ''},
        showPendings: false,
        pendingSettlments: []
    };


    handleChange = (e) => {
        let accData = this.state.accountData;
        accData[e.target.name] = e.target.value;
        this.setState({
            accountData: accData
        });
    };
    

    back = () => {
        this.setState({back: true});
    };

    loadSettlement = () => {

        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/shop/settlements/1/info/",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            success: function (result) {
                let data = _this.state.accountData;
                data.bban = result.bban_number;
                data.name = result.account_name;
                data.number = result.account_number;
                _this.setState({accountData: data});
            }.bind(this)
        });
    };

    recallPage = () =>{
        const page = this.state.recalledPage;
        this.state.recalledPage = null;
        return page;
    };

    loadData = (query,resolve,reject) => {

        this.state.lastPage = query.page;
        this.state.lastSize = query.pageSize;

        let url = compileQuery(query, (baseUrl + "/afrijula/shop/settlements"),
            ["id","number",'total','date'], [{"by":"asc","attr":"customer_name"},{"by":"desc","attr":"order.merchant_status"}],
            "Afrijula::Shop::SettlementSale",{"include": "{order: {only: [:merchant_status,:customer_name]}}"},"'$or':[{settlement_status: {'$exists': false}}, {settlement_status: 'none'}], reversed: {'$ne':true}, _type: 'Afrijula::Shop::SettlementSale'", null, null, null);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };


    componentWillMount() {
        this.loadSettlement();
        this.loadTilesData();
    }

    loadTilesData = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/dashboard",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{count:['unsettled_amount'],chart:[]},
            success: function (result) {
                let temp = _this.state.tilesData;
                temp.unsettled_amount = result.counts.unsettled_amount;
                _this.setState({tilesData: temp});
            }.bind(this)
        });
    };

    performSettlement = (type) => {
        let table = this.tableRef.current;
        if(table && table.dataManager){
            let selected = [];
            let invalid = null
            table.dataManager.data.forEach(item => {
                if(item.tableData && item.tableData.checked && item.order.merchant_status != 'delivered' && invalid == null){
                    invalid = item.number;
                }
            });
            if(invalid != null){
                alert("We cannot settle order "+invalid+'. Not delivered.');
                return;
            }
            table.dataManager.data.forEach(item => {

               if(item.tableData && item.tableData.checked){
                   selected.push(item._id);
               }
            });
             if(selected.length == 0){
                 alert("You must select at least one sale to settle");
                 return;
             }
            if(type == undefined){
                this.setState({chooseType: true});
            }else{
                this.setState({chooseType: false});
                if(window.confirm("Please note that bank charges will apply.")){
                    this.completeSettlement(selected, type);
                }
            }
        }
    }

    completeSettlement = (ids, type) => {
        let _this = this;
        return $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/shop/settlements",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{ids: ids, type:type},
            success: function (result) {
                displaySuccess(result.success);
                _this.tableRef.current && _this.tableRef.current.onQueryChange();
                _this.pendingSettlement();
            }.bind(this),
            error: function(error){
                displayError(error.responseText)
            }
        });
    };
    pendingSettlement = () => {
        let _this = this;
        return $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/shop/settlements/pending",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            success: function (result) {
                let data = _this.state.pendingSettlments;
                while(data.length > 0){
                    data.pop();
                }
                result.forEach(item => data.push(item))
                this.setState({pendingSettlements: data, showPendings: true});
            }.bind(this),
            error: function(error){
                displayError(error.responseText)
            }
        });
    }

    openTicket = () => {

    };

    confirmSettlement = (id,resend) => {
      let _this = this;
        return $.ajax({
            method: "PUT",
            url: baseUrl + "/afrijula/shop/settlements/"+id+"/settle",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data: {resend: resend},
            success: function (result) {
                displaySuccess(result.success);
                this.setState({showPendings: false, settlementPendings: []});
                _this.loadTilesData();
            }.bind(this),
            error: function(error){
                displayError(error.responseText)
            }
        });
    };

    updateInfo = () => {
        let _this = this;
        if(this.state.accountData.name  == '' || this.state.accountData.number == '' || this.state.accountData.bban == ''){
            alert("You must specify valid values");
            return;
        }

        return $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/shop/settlements/disbursement_info",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:this.state.accountData,
            success: function (result) {
                displaySuccess(result.success);
                let data = _this.state.accountData;
                data.bban = result.data.bban_number;
                data.name = result.data.account_name;
                data.number = result.data.account_number;
                this.setState({accountData: data});
                _this.loadTilesData();
            }.bind(this),
            error: function(error){
                displayError(error.responseText)
            }
        });
    }

    render() {
        const { classes} = this.props;

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;

            return <ChildComponent state = {caller_state}
                                   returningProps = {this.state.callerProps}/>
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <GridContainer>
                                                <GridItem xs={12} md={7} >
                                                    <Card>
                                                        <CardHeader color="warning" stats icon>
                                                            <CardIcon color="warning">
                                                                <Icon>money</Icon>
                                                            </CardIcon>
                                                            <p className={classes.cardCategory}>{currencyFormat(this.state.tilesData.unsettled_amount)}</p>
                                                            <h4 className={classes.cardTitle}>
                                                                Unsettled Payments
                                                            </h4>
                                                        </CardHeader>
                                                        <CardFooter stats>
                                                            <div className={classes.stats}>
                                                                <span onClick={() => {}} style={{cursor: 'pointer', color:'#800080'}}>{getString().details}</span>
                                                            </div>
                                                        </CardFooter>
                                                    </Card>
                                                </GridItem>
                                                <GridItem xs={12} md={5}>
                                                    <h5>Disbursement Information<HelpUtil reference={'e_shop_disbursement_information'}/></h5>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow><TableCell padding="none">Account Name:</TableCell><TableCell padding="none">
                                                                <TextField
                                                                    id="account_name"
                                                                    name="name"
                                                                    className={classes.textField}
                                                                    value={this.state.accountData.name}
                                                                    onChange={(e) => {this.handleChange(e)}}
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                /></TableCell></TableRow>
                                                            <TableRow><TableCell padding="none">Account Number: </TableCell><TableCell padding="none">
                                                                <TextField
                                                                    id="account_number"
                                                                    name="number"
                                                                    className={classes.textField}
                                                                    value={this.state.accountData.number}
                                                                    onChange={(e) => {this.handleChange(e)}}
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                /></TableCell></TableRow>
                                                            <TableRow><TableCell padding="none">BBan Number:</TableCell>
                                                                <TableCell padding="none"><TextField
                                                                    id="bban_number"
                                                                    name="bban"
                                                                    lassName={classes.textField}
                                                                    value={this.state.accountData.bban}
                                                                    onChange={(e) => {this.handleChange(e)}}
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                /></TableCell></TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Link onClick={this.updateInfo.bind(this)} >{getString().update}</Link>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <GridContainer>
                                                <GridItem xs={9}>
                                                    <SaveButton method={this.performSettlement.bind(this)} title={getString().request_settlement}/><HelpUtil reference={'e_shop_request_settlement'}/>
                                                    <SaveButton method={this.pendingSettlement.bind(this)} title={getString().pending_settlement}/><HelpUtil reference={'e_shop_pending_settlements'}/>
                                                    <SaveButton method={this.openTicket.bind(this)} title={getString().query_issue}/><HelpUtil reference={'e_shop_report_issue'}/>
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    <Button style={{float:'right'}} xs={12} sm={6} md={2} color="danger" onClick={this.back.bind(this)}
                                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                                    >{getString().close}</Button>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <MUITable
                                                tableRef={this.tableRef}
                                                title={"Unpaid Orders"}
                                                data={query=>new Promise((resolve,reject) => {
                                                    let page =this.recallPage();
                                                    if(page){
                                                        query.page = page;
                                                    }
                                                    this.loadData(query,resolve,reject);
                                                })}
                                                columns={[
                                                    {title: getString().customer, field: 'order.customer_name', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                    {title: getString().date, field:'date', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}, render: rowData => dateFormat(rowData.date)},
                                                    {title: getString().number, field: 'number', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                                    {title: getString().amount, field: 'total', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'},render: rowData => currencyFormat(rowData.total)},
                                                    {title: getString().status, field: 'order.merchant_status', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},

                                                ]}
                                                onRowClick={this.rowSelect}
                                                options={{
                                                    exportButton: false,filtering: true,
                                                    grouping:false,sorting:false,
                                                    debounceInterval: 1000, searc:false,
                                                    selection:true,
                                                    pageSize: this.state.recalledPageSize || 10
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                        onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                                    }
                                                ]}
                                            />
                                        </GridItem>
                                    </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <Dialog
                    open={(this.state.chooseType)}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Choose Settlement Type"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <RadioGroup row value={this.state.settlementType} name="globalFilter" onChange={e => {
                                this.setState({settlementType: e.target.value});
                            }}>
                                <FormControlLabel label={getString().check}
                                                  value="check"
                                                  control={<Radio />} />
                                <FormControlLabel label={getString().transfer}
                                                  value="transfer"
                                                  control={<Radio />} />
                            </RadioGroup>
                        </DialogContentText>
                        <DialogActions>
                            <div>
                                <Button autoFocus onClick={()=>{this.performSettlement(this.state.settlementType);}} color="error">
                                    {getString().proceed}
                                </Button>

                            </div>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={(this.state.showPendings)}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Please confirm charges..."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Table padding="checkbox" size="small" >
                                <TableHeader>
                                    <TableRow >
                                        <TableCell>{getString().date}</TableCell>
                                        <TableCell>{getString().number}</TableCell>
                                        <TableCell>{getString().amount}</TableCell>
                                        <TableCell>{getString().charges}</TableCell>
                                        <TableCell>{getString().type}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {this.state.pendingSettlments.map(item => {return <TableRow style={{fontSize:'0.8rem !important'}}>
                                        <TableCell>{dateFormat(item.created_at)}</TableCell>
                                        <TableCell>{item.reference_number}</TableCell>
                                        <TableCell>{currencyFormat(item.amount)}</TableCell>
                                        <TableCell>{currencyFormat(item.transaction_fee)}</TableCell>
                                        <TableCell>{item.s_type}</TableCell>
                                        <TableCell>{(item.request_status == 'sent') ? 'sent to bank' : ((item.request_status == 'failed') ? <SaveButton title={getString().resend} method={()=>{this.confirmSettlement(item._id.$oid, true)}}/> : <SaveButton title={getString().confirm} method={()=>{this.confirmSettlement(item._id.$oid)}}/>)}</TableCell>
                                    </TableRow>})}
                                </TableBody>
                            </Table>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Button autoFocus onClick={()=> {this.setState({showPendings: false})}} color="error">
                                {getString().close}
                            </Button>

                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

Settlements.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settlements);
