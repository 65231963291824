import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Button from "components/common/CustomButtons/Button.jsx";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import SupplierDetails from "components/afrijula/AllDetails/SupplierDetails.jsx";
import AddSupplier from "components/afrijula/AddForm/AddingSupplier.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import {
  currencyFormat,
  getString,
  compileQuery,
  showReportUtility,
} from "assets/js/utils/utils.js";
import WindowSizeListener from "react-window-size-listener";

class Supplier extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    value: 0,

    supplierData: null,

    data: [],
    header: [
      {
        title: getString()._name,
        field: "name",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().phone,
        field: "phone_number",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().email,
        field: "email",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().contact_person,
        field: "contact_person",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().balance,
        field: "current_balance",
        render: (rowData) => currencyFormat(rowData.current_balance),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],

    error: null,
    isLoaded: false,
    page: 0,
    count: 50,
    lastPage: null,
    recalledPage: null,
    recalledPageSize: "",
    lastSize: null,

    payables: false,

    tilesData: {
      payAbles: null,
      agingDebtors: null,
    },
    addsupplier: false,
    winSize: window.innerWidth,
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/suppliers/supplier/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ supplierData: result });
      },
    });
  };

  loadData = (query, resolve, reject) => {
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/suppliers/supplier",
      [
        "name",
        "phone_number",
        "email",
        "contact_person",
        "current_balance",
        "id",
      ],
      [{ by: "asc", attr: "name" }],
      "Afrijula::Suppliers::Supplier",
      null,
      null
    );
    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  loadTilesData = () => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { count: ["payables", "aging_debts"], chart: [] },

      success: function(result) {
        let temp = _this.state.tilesData;

        temp.payAbles = result.counts.payables;
        temp.agingDebtors = result.counts.aging_debts;
        _this.setState({ tilesData: temp });
      }.bind(this),
    });
  };

  componentDidMount() {
    this.loadTilesData();
  }

  addSupplier = () => {
    this.setState({ addsupplier: true });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  showReport = (name, baseUrl, parts, params) => {
    showReportUtility(name, baseUrl, parts, cookies, params);
  };

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.addsupplier) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <AddSupplier
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Supplier}
        />
      );
    }
    if (this.state.supplierData) {
      let data = this.state.supplierData;
      this.state.supplierData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <SupplierDetails
          callerProps={this.getProps()}
          callerState={this.state}
          callerData={data}
          callerName={Supplier}
        />
      );
    } else {
      return (
        <WindowSizeListener
          onResize={(size) => {
            this.setState({ winSize: size.windowWidth });
          }}
        >
          <div style={{ marginTop: -35 }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <Icon>people</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().payables}
                    </p>
                    <h5 className={classes.cardTitle}>
                      {currencyFormat(this.state.tilesData.payAbles)}
                    </h5>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <span
                        onClick={() => {
                          let end_date = new Date();
                          let start_date = new Date(
                            end_date.getFullYear(),
                            end_date.getMonth() - 1,
                            end_date.getDate()
                          );
                          let params = {
                            start_date: start_date,
                            end_date: end_date,
                            organization_id: cookies.load("orgId"),
                            org_name: cookies.load("orgName"),
                          };
                          let inject = {
                            images: [
                              {
                                name: "image",
                                attr: "image",
                                id: cookies.load("orgId"),
                                model: "Organization",
                              },
                            ],
                          };
                          this.showReport(
                            "Aging Payables",
                            baseUrl +
                              "/reports/1?url=/afrijula/*&profile=" +
                              cookies.load("profileId"),
                            {
                              params: params,
                              sub: "&report=aging_payables_v1&dynamic=true",
                              inject: inject,
                            },
                            [
                              {
                                type: "date",
                                default: start_date,
                                label: "From",
                                name: "start_date",
                              },
                              {
                                type: "date",
                                default: end_date,
                                label: "To",
                                name: "end_date",
                              },
                            ]
                          );
                        }}
                        style={{ cursor: "pointer", color: "#800080" }}
                      >
                        {getString().details}
                      </span>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="info"
                  onClick={this.addSupplier.bind(this)}
                  style={{
                    backgroundColor: "#00acc1",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">add</i>
                  {getString().add_supplier}
                </Button>
                <Card>
                  <CardHeader color="warning">
                    <h3 className={classes.cardTitleWhite}>
                      {getString().list_suppliers}
                    </h3>
                  </CardHeader>
                  <Table
                    tableRef={this.tableRef}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let page = this.recallPage();
                        if (page) {
                          query.page = page;
                        }
                        this.loadData(query, resolve, reject);
                      })
                    }
                    columns={this.state.header}
                    onRowClick={this.rowSelect}
                    options={{
                      exportButton: false,
                      filtering: true,
                      grouping: true,
                      sorting: true,
                      selection: true,
                      showTitle: false,
                      pageSize: this.state.recalledPageSize || 15,
                      debounceInterval: 1000,
                    }}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh",
                        isFreeAction: true,
                        onClick: () =>
                          this.tableRef.current &&
                          this.tableRef.current.onQueryChange(),
                      },
                    ]}
                  />
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </WindowSizeListener>
      );
    }
  }
}

Supplier.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Supplier);
