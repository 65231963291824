import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {getString, displayError,removeDuplicates} from "assets/js/utils/utils.js";
import MaterialTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import AssetItem from "components/afrijula/Asset_Register/AssetItem";
import AsyncCreatableSelect from 'react-select/async-creatable';
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class AssetCategories extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.props.state || {
            back: false,

            callerName: this.props.callerName,
            callerProps: this.props.callerProps,
            callerState: this.props.callerState,
            showAssetsSummary: false,
        };
    };

    handleChange = (e) =>{
        this.getProps().handleChangeCategoryData(e);
    };

    addAssetItems = () => {
        let _assetData = this.getProps().callerState.assetData;

        if (_assetData.serial_number === "") {
            displayError(getString().err_serialNumber);
            document.getElementById("serial_number").focus();
            return;
        }
        let leng = this.props.callerState.asset_items.length;
        let qty = parseInt(this.props.callerState.assetData.quantity);
        if(leng === qty){
            displayError(getString().exact_qty_err);

        }else{
            this.props.addAssetItems();
        }
    };

    goBack = () =>{
        this.setState({
            back: true
        });
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    addCategoryItems = () => {
        let _categoryData = this.props.callerState.categoryItemsData;
        let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;

        if (_categoryData.description === "" || !reg.test(_categoryData.description)) {
            displayError(getString().err_asset_description);
            document.getElementById("description").focus();
            return;
        }

        if (_categoryData.unit_cost === "" || _categoryData.unit_cost === 0) {
            displayError(getString().err_unit_cost);
            document.getElementById("unit_cost").focus();
            return;
        }
        if (_categoryData.life_span === "") {
            displayError(getString().err_life_span);
            document.getElementById("life_span").focus();
            return;
        }

        if (_categoryData.quantity === "") {
            displayError(getString().err_qty);
            document.getElementById("quantity").focus();
            return;
        }
        this.getProps().addCategoryItems();
    };

    handleModelValue = (opt) => {
        this.props.handleModelValue(opt);
    };

    modelBrandOptions = (model_brand) => {
        let _this = this;
        const url = baseUrl + '/afrijula/asset_register/assets?attrs=["model_brand"]&model=Afrijula::AssetRegister::Asset&unique=model_brand' + '&match=' + model_brand;
        return fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json();
            }).then(json => {

                let opts = [];
                let i = 0;

                for (; i < json.length; i++) {
                    opts[i] = {label: json[i], value: json[i]};
                }

                let arrFiltered = removeDuplicates(opts);
                this.props.handleModelValue(arrFiltered[0]);
                return arrFiltered;
            })
            .catch(err => {

            });
    };

    deleteAssetCategory = (event, row) => {
        this.getProps().deleteAssetCategory(event, row)
    };

    showAssetsForm = () =>{
        this.setState({
            showAssetsSummary: true
        })
    };

    render() {
        const {classes} = this.getProps();
        const _fromState = this.getProps().callerState.categoryItemsData;
        const _tableData = this.getProps().category_items;

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.category_items = [...this.getProps().category_items];
            caller_state.addItems = false;
            return <ChildComponent state={caller_state} returningProps={this.state.callerProps}
                                   newAssetData={this.state.assetData}/>
        }
        if(this.state.showAssetsSummary){
            return (
                <AssetItem
                    callerProps={this.getProps()}
                    callerState={this.state}
                    callerName={AssetCategories}
                    globalAssetData={this.state.callerState.globalAssetData || this.getProps()}
                    categoryData={this.getProps().category_items}
                />
            );
        }
        else{
            return(
                <GridContainer>
                    <GridItem>
                        <Card>
                            <CardHeader color="primary">
                                <h4>{getString().asset_categories}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridItem xs={12} sm={12} md={12}>
                                    <form className={classes.container} autoComplete="off">
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    required
                                                    id="description"
                                                    name="description"
                                                    label={getString().description}
                                                    className={classes.textField}
                                                    value={_fromState.description}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <span>{getString().model_brand}</span>
                                                <AsyncCreatableSelect defaultOptions loadOptions={this.modelBrandOptions}
                                                                      name="model_brand"
                                                                      id="model_brand" value={_fromState.model_brand}
                                                                      onChange={(opt, evt) => {
                                                                    this.handleModelValue(opt)
                                                                }}/>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    required
                                                    id="unit_cost"
                                                    name="unit_cost"
                                                    label={getString().unit_cost}
                                                    className={classes.textField}
                                                    value={_fromState.unit_cost}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    required
                                                    id="quantity"
                                                    name="quantity"
                                                    label={getString().quantity}
                                                    className={classes.textField}
                                                    value={_fromState.quantity}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <TextField
                                                    required
                                                    id="life_span"
                                                    name="life_span"
                                                    label={getString().life_span+' (yrs)'}
                                                    className={classes.textField}
                                                    value={_fromState.life_span}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button xs={12} sm={6} md={2} color="success" onClick={this.addCategoryItems.bind(this)}
                                                style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                                }}>{getString().add}</Button>
                                    </GridItem>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <MaterialTable
                                        style={{margin: 15}}
                                        columns={[
                                            { title: getString().description, field: 'description', cellStyle: {padding: '1px', width: '160px'}, headerStyle: {padding: '1px', width: '160px'}},
                                            { title: getString().model_brand, field: 'model_brand', cellStyle: {padding: '1px', width: '160px'}, headerStyle: {padding: '1px', width: '160px'}},
                                            { title: getString().unit_cost, field: 'unit_cost', cellStyle: {padding: '1px', width: '160px'},headerStyle: {padding: '1px', width: '160px'}},
                                            { title: getString().quantity, field: 'quantity', cellStyle: {padding: '1px', width: '160px'}, headerStyle: {padding: '1px', width: '160px'},},
                                            { title: getString().life_span, field: 'life_span', cellStyle: {padding: '1px', width: '160px'}, headerStyle: {padding: '1px', width: '160px'}}
                                            ]}
                                        data={_tableData}
                                        actions={[
                                            {
                                                icon: 'delete',
                                                iconProps: {fontSize: 'small'},
                                                style: {float: 'right'},
                                                onClick: (event, row) => {
                                                    this.deleteAssetCategory(event, row);
                                                },
                                            },

                                        ]}
                                        localization={{header:{
                                                actions: ""
                                            }}}
                                        options={{
                                            search: false,
                                            paging: false,
                                            showEmptyDataSourceMessage: false,
                                            showTitle: false
                                        }}
                                    />

                                </GridItem>
                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button xs={12} sm={6} md={2} color="danger" onClick={this.goBack}
                                            style={{
                                                backgroundColor: "#f44336",
                                                margin: ".3125rem 1px",
                                                minWidth: "auto",
                                                minHeight: "auto",
                                                fontSize: "12px",
                                                color: "#ffffff",
                                                padding: "12px 30px",
                                                borderRadius: "3px",
                                                verticalAlign: "middle"
                                            }}>{getString().back}</Button>
                                    <Button xs={12} sm={6} md={2} color="success" onClick={this.showAssetsForm.bind(this)}
                                            style={{
                                                backgroundColor: "#4caf50",
                                                margin: ".3125rem 1px",
                                                minWidth: "auto",
                                                minHeight: "auto",
                                                fontSize: "12px",
                                                color: "#ffffff",
                                                padding: "12px 30px",
                                                borderRadius: "3px",
                                                verticalAlign: "middle"
                                            }}>{getString().next}</Button>
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

AssetCategories.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetCategories);