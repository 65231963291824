import React, { Component } from 'react'
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, thermalPrinterFunct,showReportUtility,displaySuccess,displayError,currencyValue} from "assets/js/utils/utils.js";
// include styles
import 'rodal/lib/rodal.css';
import MaterialTable from "material-table";
import Button from '../../common/CustomButtons/Button';


class SellTableTemplate extends Component {

    state = {
    }

    upQty = (action,rowData) =>{
        let newQuantity =  Number(rowData.quantity) + 1 ;
        let newSellable=Number(rowData.sellable_units) + 1 ;
        if(this.props.type==="whole sale")
            this.props.saveQty(newSellable, rowData);
        else
            this.props.saveQty(newQuantity, rowData);
    };

    downQty = (action,rowData) =>{
        let newQuantity =  Number(rowData.quantity) - 1 ;
        let newSellable=Number(rowData.sellable_units) - 1 ;
        if(this.props.type==="whole sale"){
            if (newSellable <= 0){
                return;
            }
            this.props.saveQty(newSellable,rowData);
        }else{
            if (newQuantity <= 0)
                return;
            this.props.saveQty(newQuantity,rowData);
        }
    };

    isEditing = () =>{
        if(this.props.editing)
            this.props.editing();
    };

    deleteItem = (event,row) => {
        let arr = this.props.Items;
        let index = arr.findIndex(x => x.item === row.item);
        arr.splice(index,1);
        this.props.upItemsAfterDelete(arr);
        let discount =  this.props.discount;

        this.props.updateSales(this.props.Items,discount);
        this.isEditing();
    };

    editQuantity = (event,rowData) =>{
        let _data = rowData;
        let qty =  _data.quantity;
        let sellableUnit=_data.sellable_units
        if(this.props.type==="whole sale")
            this.props.editQtyUpdate(_data,sellableUnit,true,rowData);
        else
            this.props.editQtyUpdate(_data,qty,true,rowData);
    };

    getMetric = (rowData) =>{
        if(rowData.type !== "stock"){
            return "";
        }
        return '('+rowData.metric+')';
    };

    render() {
        if(this.props.type==="whole sale"){
            this.state.header = [

                { title: getString().product, field: 'item', editable: 'never', cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
                { title: getString().quantity,field: 'quantity', editable: 'onUpdate',hidden: true,render: rowData =>{return numberFormat(rowData.quantity)+this.getMetric(rowData)}, cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
                { title: getString().metric_units, field: 'metric_units', hidden: true, cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
                { title: getString().unit_price, field: 'unit_price', editable: 'never',hidden: true, render: rowData => currencyFormat(rowData.unit_price), cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
                { title: getString().sellable_units, field: 'sellable_units', render: rowData => {return (rowData.sellable_units) ? numberFormat(rowData.sellable_units) : ""}, cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
                { title: getString().wholesale_price, field: 'wholesale_price', editable: 'never',render: rowData => currencyFormat(rowData.wholesale_price), cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
                { title: getString().total, field: 'total', editable: 'never',render: rowData => currencyFormat(rowData.total), cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            ]
        }else{
        this.state.header = [

            { title: getString().product, field: 'item', editable: 'never', cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            { title: getString().quantity,  field: 'quantity', editable: 'onUpdate',render: rowData =>{return numberFormat(rowData.quantity)+this.getMetric(rowData)}, cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            { title: getString().metric_units, field: 'metric_units', hidden: true, cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            { title: getString().unit_price, field: 'unit_price', editable: 'never',render: rowData => currencyFormat(rowData.unit_price), cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            { title: getString().sellable_units, field: 'sellable_units', hidden: true, render: rowData => {return (rowData.sellable_units) ? numberFormat(rowData.sellable_units) : ""}, cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            { title: getString().wholesale_price, field: 'wholesale_price', editable: 'never',hidden: true,render: rowData => currencyFormat(rowData.wholesale_price), cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
            { title: getString().total, field: 'total', editable: 'never',render: rowData => currencyFormat(rowData.total), cellStyle:{padding:'1px'}, headerStyle:{padding:'1px'}},
        ]
            }
        return (
            <div>
                <MaterialTable
                    data={this.props.Items}
                    columns={this.state.header}
                    onRowsDelete={this.rowsDelete}
                    onRowClick={(event, rowData)=>{this.editQuantity(event,rowData)}}
                    onClick={this.deleteItem}
                    actions={[
                        {   icon:'delete',
                            iconProps: {fontSize:'small'},
                            style:{float: 'right'},
                            onClick:(event, row)=> {
                                this.deleteItem(event,row);
                            },
                        },
                        {   icon:'add',
                            iconProps: {fontSize:'small'},
                            style:{float: 'right'},
                            onClick:(event, row)=> {
                                this.upQty(event,row);

                            },
                        },

                        {   icon:'remove',
                            iconProps: {fontSize:'small'},
                            style:{float: 'right'},
                            onClick:(event, row)=> {
                                this.downQty(event,row);
                            },
                        }
                    ]}
                    options={{
                        toolbar: false,
                        sorting: true,
                        pageSize:10,
                        pageSizeOptions: [10, 15, 25],

                    }}
                />
            </div>
        )
    }
}

export default SellTableTemplate
