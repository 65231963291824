import Receipt from "@material-ui/icons/Receipt";
import Contracts from "components/afrijula/Contracts/Contracts.jsx";
import SellPage from "components/afrijula/Sell/NewSell.jsx";
import Sales from "components/afrijula/Sales/SalesHome.jsx";
import {People} from "@material-ui/icons";
import Customer from "components/afrijula/Customer/Customer.jsx";
import Notes from "@material-ui/icons/Notes";
import {getString} from "assets/js/utils/utils.js";
import LocationOn from "@material-ui/icons/LocationOn";
import Report from "components/afrijula/Reports/Reports.jsx";
import DescriptionIcon from '@material-ui/icons/Description';

const sell = {
    path: "/sell",
    sidebarName: getString().sell,
    icon: Receipt,
    component: SellPage
};

const sales = {
    path: "/sales",
    sidebarName: getString().sales,
    icon: Notes,
    component: Sales
};

const contracts = {
    path: "/contracts",
    sidebarName: getString().contract,
    icon: DescriptionIcon,
    component: Contracts
};

const customer = {
    path: "/customer",
    sidebarName: getString().customers,
    icon: People,
    component: Customer
};

const report = {
    path: "/reports",
    sidebarName: getString().reports,
    icon: LocationOn,
    component: Report
};

const redirect =  { redirect: true, path: "/", to: "/sell", navbarName: "Redirect" };
const cashierRoutes = [];
const basic_Package = [sell,sales,customer,report,redirect];
const standard = [sell,sales,contracts,customer,report,redirect];
const premuim = [sell,sales,contracts,customer,report,redirect];
const premium_plus = [sell,sales,contracts,customer,report,redirect];

const setRoutes = (item) => {
    cashierRoutes.push(item);
};
export const determinePackageRoutes = (_package) => {

    let routes;
    switch(_package) {
        case "basic":
            routes = basic_Package;
            break;
        case "standard":
            routes = standard;
            break;
        case "premium":
            routes = premuim;
            break;
        default:
            routes = premium_plus;
    }

    cashierRoutes.splice(0,cashierRoutes.length);
    routes.forEach(setRoutes);
    return cashierRoutes;
};

export default determinePackageRoutes;
