import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import AsyncSelect  from "react-select/async";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import MaterialTable from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import $ from "jquery";
import {getToday, currencyFormat,computeCurrency,getString} from "assets/js/utils/utils.js";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import CardHeader from "components/common/Card/CardHeader.jsx";
import 'rodal/lib/rodal.css';
import TextField from '@material-ui/core/TextField';
import {displaySuccess, displayError} from "assets/js/utils/utils";
import OptionsLoader from "components/afrijula/Stocks/OptionsLoader.jsx";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    noPadding: {
        padding: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }


};

class OutgoingStock extends React.Component{
  

    constructor(props){
        super(props);
        this.state = {
            itemsChanged: false,
            back: false,
            locations: [],
            reasons: [{label:"damaged",value:"damaged"},{label:"expired",value:"expired"},{label:"other",value:"other"}],
            selectedLocation: '',
            selectedReason: '',
            options: {},

            name: '',
            number: '',
            location: '',
            unit_cost: '',
            quantity: '',
            total: '',
            date: '',
            id: null,
            metrics: [],
            selectedMetric: '',
            stock_items:  [],
            today: '',


            supplierData: this.props.supplierData,
            createdSupplierId: null,

            callerName: this.props.callerName,
            callerProps: this.props.callerProps,
            callerState: this.props.callerState

        };

    }

    productOptions = (inputValue) => {
        let url = baseUrl + '/afrijula/locations/location?attrs=["id","number","name","unit_cost","wholesale_metric","retail_metric"]&model=Afrijula::Stocks::Stock&to=name&match='+inputValue;

        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;
                for(; i< json.length;i++){
                    opts[i] = {label:json[i].name, value:json[i].number,id:json[i]._id.$oid,unit_cost:json[i].unit_cost,wholesale_metric: json[i].wholesale_metric, retail_metric: json[i].retail_metric};
                }
                return opts;
            }) // my option list array?
            .catch(err => {
                console.log(err);
            });
    };

    rowSelect = (colData, cellMeta)  => {
        var newData = this.state.historyData;
        var rowdata = this.state.data[cellMeta.dataIndex];

        let i = 0;
        for(; i< rowdata.length;i++){
            newData[i] = rowdata[i];
        }
        this.setState({historyData: newData});
        this.setState({historyDetails: !this.state.historyDetails})

    };

    updateInvoice = (name, val) => {

        this.setState({[name]:val});

    };


    loadLocations = () => {

         let the_data = [];

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"filter": ":location.gte => Date.today","attrs":["name","id"],
                "order": [{"by":"desc","attr":"name"}],"model":"Afrijula::Locations::Location"},

            success: function(data){

                $.map(data,function (data) {
                    the_data.push({label:data.name, value:data.name});
                });
                this.setState({
                    locations: the_data,
                    selectedLocation: data.length == 0 ? "" :data[0].name,
                })
            }.bind(this)
        });
    };
    handleChange = (e) => {
        this.updateInvoice(e.target.name, e.target.value);
    };

    removeItems = () => {
         let data = this.state.stock_items.map(function(_data){
            return {id: _data.id, name: _data.name, quantity: _data.quantity, metric: _data.metric, options: _data.options}
        });

         var _this = this;
        $.ajax({
            method:"POST",
            url: baseUrl+"/afrijula/stocks/stock_removal",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {"items":data, location: this.state.selectedLocation, date: this.state.date, description: this.state.selectedReason},

            success: function (result) {
                displaySuccess(result.success)
                _this.setState({
                    back:true
                });
            },
            error: function(error){
                displayError(error.responseText);
            }
        });
    };

    back = () => {
        this.setState({back: true});
    };
    getItemExist = (arr, number,metric) => {
        for (let i=0; i < arr.length; i++) {
            if (arr[i].number === number && arr[i] === metric) {
                return i;
            }
        }
        return null;
    };

    addItems = () => {
        let stock_items = this.state.stock_items;
        let qty = Number(this.state.quantity);
        let total = computeCurrency(qty, this.state.unit_cost, '*');
        let exist = this.getItemExist(stock_items,this.state.number, this.state.selectedMetric);
        if(exist == null){
        let data = {name: this.state.name,number:this.state.number,quantity:qty,
            date: this.state.date, metric: this.state.selectedMetric,
            id: this.state.id,unit_cost: this.state.unit_cost,location: this.state.selectedLocation,total: total,
            options: this.state.options};
        stock_items.push(data);
        }
        else{
            // we may need to prompt the user to confirm if they want to go ahead with the update
            stock_items[exist].quantity += qty;
            stock_items[exist].options = this.state.options;
            stock_items[exist].total = computeCurrency(stock_items[exist].quantity,this.state.unit_cost,"*");
        }
        this.setState({stock_items: stock_items,quantity:'', name: '', number: '', id:''});

    };
    componentDidMount() {
        this.setState({
            date: getToday(),

        });

        this.loadLocations();
    };

    handleStatusChange = (opt) =>{
        this.setState({selectedReason: opt.value});

    };



    selecteStockChanged = (opt) => {
        let metrics = [{label: opt.wholesale_metric, value: opt.wholesale_metric},{label:opt.retail_metric, value: opt.retail_metric}];
        this.setState({name:opt.label,number:opt.value,id:opt.id,unit_cost:opt.unit_cost, metrics: metrics, selectedMetric: metrics[0].value});

    };

    handleLocationChanged = (opt) => {
        this.setState({selectedLocation:opt.value});
    };

    setOptions = (options) => {
        let opts = this.state.options;
        opts = {};
        options.forEach(item => {if(item.attribute != null && opts[item.attribute] != '') opts[item.attribute] = item.value;});
        this.setState({options: opts});
    }

    deleteItem = (event,row) => {
        let arr = this.state.stock_items;
        let index = arr.indexOf(row);
        arr.splice(index,1);
        this.setState({stock_items: arr});

    };

    determineMetric = () => {
        if(this.state.selectedMetric && this.state.selectedMetric != ''){
            return {label: this.state.selectedMetric, value: this.state.selectedMetric}
        }
        return {}
    }

    outgoing = () => {
        const { classes } = this.props;
        const header = [
            { title:getString().product_number,field:'number', cellStyle:{padding:'1px',width:'160px'}, headerStyle:{padding:'1px',width:'160px'},disablePadding: true},
            { title: getString().product, field: 'name',readonly:true,value: this.tempName, editable:'never', cellStyle:{padding:'1px',width:'160px'}, headerStyle:{padding:'1px',width:'160px'}, colSpan:2},
            {title:getString().location,field:'location',cellStyle:{padding:'1px',width:'160px'}, headerStyle:{padding:'1px',width:'160px'},disablePadding: true},
            { title: getString().table_quantity, field: 'quantity',type:'numeric' , cellStyle:{padding:'10px',width:'100px'}, headerStyle:{padding:'10px',width:'100px'}},
            { title: getString().value, field: 'total',type:'numeric', render: rowData => currencyFormat(rowData.total) , cellStyle:{padding:'10px',width:'100px'}, headerStyle:{padding:'10px',width:'100px'}},
            {
                title: '', field: 'options', readonly:true, render: rowData => {return (rowData.options) ? Object.keys(rowData.options).map((option)=>{return <div style={{backgroundColor:'#FFC300', borderRadius:'5px', color:'black'}}>{option}:{rowData.options[option]}</div>}) : null} ,
                cellStyle:{padding:'1px',width:'70px'}, headerStyle:{padding:'1px', with:'100px'}, editable:'never'
            }
        ];
        return (
            <div>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >
                        <Card className={"lecketInlineItems"}>

                            <CardHeader color="info">
                                <h4>Inventory Removals</h4>
                            </CardHeader>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={6}>
                                    <form className={classes.container}  autoComplete="off">
                                        <GridContainer>

                                            <GridItem xs={12} sm={4} md={4} lg={3}>
                                                <TextField
                                                    required
                                                    id="date"
                                                    name="date"
                                                    label={getString().date}
                                                    type="date"
                                                    value={this.state.date}
                                                    defaultValue={this.state.date}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>

                                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                                <div  style={{ marginTop: "20px", marginLeft:"10px"}}>
                                                    <AsyncCreatableSelect
                                                        autoFocus defaultOptions={this.state.reasons}
                                                        onChange={opt => {
                                                            this.handleStatusChange(opt)
                                                        }}/>
                                                </div>

                                            </GridItem>

                                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                                <div  style={{ marginTop: "20px", marginLeft:"10px"}}>
                                                    <Select options={this.state.locations} placeholder={getString().location}
                                                            defaultValue={this.state.selectedLocation} onChange = {e => {this.handleLocationChanged(e)}}  />


                                                </div>
                                            </GridItem>
                                        </GridContainer>

                                    </form>
                                </GridItem>
                                <GridItem xs={12} sm={12} lg={7}>
                                        <GridContainer >
                                            <GridItem xs={12} md={6} lg={4}>
                                                <div className={classes.productSelector}  style={{ marginTop: "20px", marginLeft:"10px"}}>
                                                 <span style={{marginLeft:"10px"}}>{getString().product}:</span>
                                                    <AsyncSelect autoFocus defaultOptions
                                                                 loadOptions={this.productOptions}
                                                                 value={{label: this.state.name, value: this.state.id}}
                                                                 onChange={opt => {
                                                               this.selecteStockChanged(opt)
                                                           }}


                                                    />
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} md={6} lg={5}>
                                                <OptionsLoader options={this.state.options} productId={this.state.number}
                                                               optionsCback={this.setOptions.bind(this)} _type="stock" noChange={true}/>
                                            </GridItem>
                                            <GridItem xs={12} md={4}>
                                                <div  style={{ marginTop: "20px", marginLeft:"10px"}}>
                                                 <span style={{marginLeft:"10px"}}>{getString().metric}:</span>
                                                    <Select options={this.state.metrics} placeholder={getString().metrics}
                                                            value={this.determineMetric()}
                                                            onChange = {e => {this.setState({selectedMetric: e.value})}}  />
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} md={4} style={{ marginTop: "20px"}}>
                                                <TextField
                                                    required
                                                    id="quantity"
                                                    name="quantity"
                                                    label={getString().table_quantity}
                                                    type="text"
                                                    value={this.state.quantity}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem>

                                        <GridItem>
                                            <Button xs={12} md={4} color="success" onClick={this.addItems.bind(this)}
                                                    style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                            >{getString().add}</Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>

                                <MaterialTable
                                    style={{margin:15}}
                                    title="Outgoing Items"
                                    columns={header}
                                    data={this.state.stock_items}
                                    actions={[
                                        {   icon:'delete',
                                            iconProps: {fontSize:'small'},
                                            style:{float: 'right'},
                                            onClick:(event, row)=> {
                                                this.deleteItem(event,row);
                                            },



                                        },

                                    ]}
                                    options={{
                                        search:false,
                                        paging: false,
                                        showEmptyDataSourceMessage: false
                                    }}
                                />
                            </GridItem>

                            </GridContainer>
                            <GridContainer>
                                <GridItem  xs={12} sm={12} md={12}>
                                    <Button xs={12} sm={12} md={4} color="danger"  onClick={this.back}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    >{getString().cancel}</Button>
                                    <Button xs={12} sm={12} md={4} color="success" onClick={this.removeItems}
                                            style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    >{getString().save}</Button>
                                </GridItem>
                            </GridContainer>

                        </Card>

                    </GridItem>
                </GridContainer>
            </div>
        );
    };
    getProps = () =>{
        return this.props.returningProps || this.props;
    };

    componentDidUpdate(prevProps,prevState,snapshit) {

    };

    render() {
        const { classes } = this.getProps();

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.damages = false;
            return <ChildComponent state={this.state.callerState}  returningProps={this.state.callerProps}/>
        }
        else{
            return this.outgoing();
        }


    }
}


export default withStyles(styles)(OutgoingStock);
