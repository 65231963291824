// @material-ui/icons
import React from "react";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Receipt from "@material-ui/icons/Receipt";
import DescriptionIcon from '@material-ui/icons/Description';
import Notes from "@material-ui/icons/Notes";
import Book from "@material-ui/icons/Book";
import Produce from "@material-ui/icons/LocalGroceryStore"
import Setting from "@material-ui/icons/Settings";
import LocationOn from "@material-ui/icons/LocationOn";
import {MonetizationOn, People} from "@material-ui/icons";
import Paper from "@material-ui/icons/Assignment"
import Business from "@material-ui/icons/Business"
import Shop from "@material-ui/icons/Shop"
// core components/views
import DashboardPage from "components/afrijula/Dashboard/Dashboard.jsx";
import User_Settings from "components/afrijula/Settings/Settings.jsx";
import SellPage from "components/afrijula/Sell/NewSell.jsx";
import Sales from "components/afrijula/Sales/SalesHome.jsx";
import InventoryProducts from "components/afrijula/Products/InventoryProducts.jsx";
import Customer from "components/afrijula/Customer/Customer.jsx";
import Supplier from "components/afrijula/Supplier/Supplier.jsx";
import Expense from "components/afrijula/ExpenseManager/ExpenseManager.jsx";
import {getString} from "assets/js/utils/utils.js";
import CashBook from "components/afrijula/CashBook/CashBook.jsx";
import Report from "components/afrijula/Reports/Reports.jsx";
import Contracts from "components/afrijula/Contracts/Contracts.jsx";
import AssetRegister from "components/afrijula/Asset_Register/AssetSummary.jsx";
import Shopping from "components/afrijula/Ecom/Shopping.jsx"
import Loan from "components/afrijula/Loan/Loan.jsx"




const dashboard =  {
    path: "/dashboard",
    sidebarName: getString().dashboard,
    icon: Dashboard,
    component: DashboardPage
};
const sell = {
    path: "/sell",
    sidebarName: getString().sell,
    icon: Receipt,
    component: SellPage
};
const sales = {
    path: "/sales",
    sidebarName: getString().sales,
    icon: Notes,
    component: Sales
};
const contracts = {
    path: "/contracts",
    sidebarName: getString().contracts,
    icon: DescriptionIcon,
    component: Contracts
};
const inventoryproducts =  {
    path: "/products",
    sidebarName: getString().inventoryProducts,
    icon: Produce,
    component: InventoryProducts,
};
const customer =  {
    path: "/customer",
    sidebarName: getString().customers,
    icon: People,
    component: Customer
};
const supplier =  {
    path: "/supplier",
    sidebarName: getString().suppliers,
    icon: Person,
    component: Supplier
};
const expenses = {
    path: "/purchases",
    sidebarName: getString().purchases,
    icon: Book,
    component: Expense
};
const cash_book =  {
    path: "/cashbook",
    sidebarName: getString().cash_book,
    icon: Paper,
    component: CashBook,

};
const shopping = {
    path: '/e_commerce',
    sidebarName: getString().enable_shopping,
    icon: Shop,
    component: Shopping
}
const asset_register =  {
    path: "/asset_register",
    sidebarName: getString().asset_register,
    icon: Business,
    component: AssetRegister,

};
const report =  {
    path: "/reports",
    sidebarName: getString().reports,
    icon: LocationOn,
    component: Report,

};
const loans =  {
    path: "/loans",
    sidebarName: getString().loans,
    icon:MonetizationOn ,
    component: Loan,

};
const settings =  {
    path: "/settings",
    sidebarName: getString().settings,
    icon: Setting,
    component: User_Settings
};
const redirect =  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" };
const dashboardRoutes = [];
const basic_Package = [dashboard,sell,sales,contracts,inventoryproducts,expenses,cash_book,shopping,report,loans,settings,redirect];
const standard = [dashboard,sell,sales,contracts,inventoryproducts,expenses,cash_book,shopping,report,loans,settings,redirect];
const premuim = [dashboard,sell,sales,contracts,inventoryproducts,customer,cash_book,shopping,asset_register,supplier,expenses,report,loans,settings,redirect];
const premium_plus = [dashboard,sell,sales,contracts,inventoryproducts,customer,supplier,expenses,cash_book,asset_register,shopping,report,loans,settings,redirect];

const setRoutes = (item,index) => {
    dashboardRoutes.push(item);
};
export const determinePackageRoutes = (_package) => {

    let routes;
    switch(_package) {
        case "basic":
            routes = basic_Package;
            break;
        case "standard":
            routes = standard;
            break;
        case "premium":
            routes = premuim;
            break;
        default:
            routes = premium_plus;
    }

    dashboardRoutes.splice(0,dashboardRoutes.length);
    routes.forEach(setRoutes);
    return dashboardRoutes;
}






//dashboardRoutes.push(sell);
//dashboardRoutes.push(redirect);

export default determinePackageRoutes;
