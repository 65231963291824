import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {dateFormat} from "../../../assets/js/utils/utils";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    overflow: "auto",
  },
});

function createData(description, reversed_request_date, reversed_requested_by, id) {
  return { description, reversed_request_date, reversed_requested_by, id };
}

function ProposedSalesReturn(props) {
  const rows = [];
  const classes = useStyles();

  props.reversalRequestData.map((single) => {
    const data = createData(
      single.description,
      single.reversed_request_date,
      single.reversed_requested_by,
      single._id.$oid
    );
    rows.push(data);
  });

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="Proposed Sales Reversal Table"
        style={{marginTop: 15}}
      >
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Requested By</TableCell>
            <TableCell align="right">Accept</TableCell>
            <TableCell align="right">Reject</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.description}
              </TableCell>
              <TableCell align="right">{dateFormat(row.reversed_request_date)}</TableCell>
              <TableCell align="right">{row.reversed_requested_by}</TableCell>
              <TableCell align="right">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => props.acceptReversalOfSale(row.id)}
                >
                  Accept
                </button>
              </TableCell>
              <TableCell align="right">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => props.rejectReversalOfSale(row.id)}
                >
                  Reject
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProposedSalesReturn;
