import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from "@material-ui/core/Icon";
import jwt from 'jsonwebtoken';
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {displayError,displaySuccess,getString} from "assets/js/utils/utils.js";

export default class CardProcessing extends React.Component {


    state = {
        progress: 0,
        p2: false,
        p3: false,
        p4: false,
    };

    prepareAndSend = () => {
        let data = this.props.getData();
        this.setState({p2: true})
        const _this = this;
        setTimeout(() => {
            this.setState({p3: true})
            var token = jwt.sign(data, cookies.load("token"));
            // send data with encrypted token
            $.ajax({
                method: "POST",
                url: baseUrl + "/kodo/payments/pay",
                dataType: "json",
                headers: {
                    "Authorization": "token " + cookies.load("token"),
                    "UserProfile": cookies.load("profile"),
                    "UserKey": cookies.load('User-Key')
                },
                data: {payload: token, amount: this.props.amount, currency: this.props.currency, method: this.props.method},
                success: function (result) {
                    displaySuccess("Payment is being processed and receipt will be sent if successful");
                    _this.setState({p2:true});
                    _this.props.onClose();
                },
                error: function(error){
                    displayError(error.responseText);
                }
            });
        }, 2000)
    }

    componentDidMount () {
        this.prepareAndSend()
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    open={true}
                    onClose={this.props.onClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Payment in Progress... </DialogTitle>
                    <DialogContent>
                        <div >
                            <h5 style={{display:'inline-block'}}>Securing payment request</h5><span style={{display:'inline-block'}}> {(!this.state.p2) ? <CircularProgress variant="determinate" value={this.state.progress} /> : <div className="icon"><Icon >check</Icon></div>}</span>
                        </div>
                        <div style={{display: (this.state.p3) ? 'block': 'none'}}>
                            <h5 style={{display:'inline-block'}}>Sending payment request...</h5><span style={{display:'inline-block'}}>{(this.state.p3 && !this.state.p4) ? <CircularProgress variant="determinate" value={this.state.progress} /> : <div className="icon"><Icon >check</Icon></div>}</span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}