import React from "react";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Stock from "components/afrijula/Stocks/Stock.jsx";
import NewStockSupplier from "components/afrijula/AddForm/NewStockSupplier.jsx";
import {displayError,displaySuccess,getString,validateNumberField,currencyValue} from "assets/js/utils/utils.js";
import OpeningBalances from "components/afrijula/Stocks/OpeningBalances.jsx"
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import SaveButton from "../CustomComponents/SaveButton";
import HelpUtil from "../Utils/HelpUtil";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class AddStock extends React.Component{

    state = {

        locations: [],
        selectedLocation: null,
        saveButton: false,
        addsupp: false,
        open: false,
        toaddstock: true,

        stockData: null,


        backtostock: false,
        noBack: this.props.noBack || false,
        noCancel:this.props.noCancel || false,
        showWholesale: false,

        callerName: this.props.callerName,
        callerState: this.props.callerState,
        callerProps: this.props.callerProps,
        editStock: this.props.editStock,
    };

    getDefaultStock = () => {
        this.state.stockData = (this.props.stockData) ? {
            name: this.props.stockData.name,
            number: this.props.stockData.number,
            category: this.props.stockData.category,
            wholesale_metric: this.props.stockData.wholesale_metric,
            wholesale_price: this.props.stockData.wholesale_price,
            retail_metric: this.props.stockData.retail_metric,
            reorder_level: this.props.stockData.reorder_level,
            sellable: this.props.stockData.sellable,
            sellable_units: this.props.stockData.sellable_units,
            unit_price: currencyValue(this.props.stockData.unit_price,false),
            opening_balance: 0,
            opening_balances:[],
            item_location: null,
            item_quantity: 0,
            item_unit_cost:0,
            _id: this.props.stockData._id,
            options_prices: this.props.stockData.options_prices
        } : {
            name: '',
            number: '',
            category: '',
            wholesale_metric: 'pc',
            wholesale_price: '',
            retail_metric: 'pc',
            sellable: true,
            reorder_levels: '',
            sellable_units: 1,
            unit_price: '',
            opening_balance: 0,
            opening_balances:[],
            item_location: null,
            item_quantity: null,
            item_unit_cost:null,
        }
    };

    addStock = () => {
        let method = "POST";
        let url = baseUrl + "/afrijula/stocks/stock";

        let stock = this.state.stockData;
        let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 "'~?!\-]+$/;
        
        if(stock.name.trim() === "" || !reg.test(stock.name.trim())){
            displayError(getString().invalid_prodName);
            document.getElementById("name").focus();
            return;
        }

        if((stock.retail_metric != stock.wholesale_metric) && stock.wholesale_metric){
             if(!stock.sellable_units || stock.sellable_units <= 1){
                displayError(getString().invalid_sellable_unit);
                document.getElementById('sellable_units').focus();
                return;
            }

        }
        if(stock.retail_metric === "" ){
            displayError(getString().select_rmetric);
            document.getElementById("retail_metric").focus();
            return;
        }
        stock.unit_price = (stock.unit_price instanceof String) ? stock.unit_price.trim() : stock.unit_price
        if(stock.unit_price === "" || !validateNumberField(stock.unit_price)){
            displayError(getString().invalid_unit_price);
            document.getElementById("unit_price").focus();
            return;
        }
        if(stock.options_prices){
            for (var i = 0; i < stock.options_prices.length; i++) {
                let opt = stock.options_prices[i];
               if(opt.price.cents === '' || !validateNumberField(opt.price.cents)){
                   displayError("invalid price for options");
                   document.getElementById(opt.reference).focus();
                   return;
               }
            };
        }
        if(stock.category === ""){
            displayError(getString().select_cat);
            document.getElementById("category").focus();
            return;
        }
        if(stock.location === ""){
            displayError(getString().select_loc);
            return;
        }

        let _this = this;

        if(!this.state.editStock){
            stock.opening_balances.forEach(function(item){
            stock.opening_balance += parseInt(item.quantity);
            });

        }else{method = "PUT";
          url =  baseUrl + "/afrijula/stocks/stock/"+this.state.callerState.stockData._id.$oid;
        }



        return $.ajax({
              method: method,
              url: url,
              headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
              dataType: "json",

              data: stock,

              success: function (data){
                  {displaySuccess(getString().stock_saved);}
                  if(_this.props.saveStockCback) {
                      _this.props.saveStockCback(data.stock_id ? data.stock_id.$oid : null, data.stock);
                  }else {
                      _this.clearForm();
                      _this.setState({backtostock: true});
                  }
              },
              error: function (response) {
                  {displayError(response.responseText);}
              }
        });

        if(stock.picture){
            let id = this.state.stockData._id.$oid;

            $.ajax({
                method: "POST",
                url: baseUrl+"/picture/"+id+"&model=Afrijula::Stocks/Stock",
                dataType: "json",
                data: {"uploads":stock.picture},

                success: function(res){

                }
            })
        }
    };

    componentWillMount() {
        this.getDefaultStock();
        if(this.state.stockData.retail_metric != '' &&
            this.state.stockData.retail_metric != this.state.stockData.wholesale_metric){
            this.setState({showWholesale:true});
        }
    }

    validateInput = (e) => {
        if(e.target.name == 'retail_metric' && e.target.value.length > 0){
            if(/^[a-zA-Z_]+$/.test(e.target.value.replace(' ','')))
                return true;
            else{
                alert('You must specify a valid value for '+getString().retail_metric)
                return false
            }
        }
        return true;
    };

    handleOptsChange = (e) => {
        let data = this.state.stockData;
        for(var i = 0; i < data.options_prices.length; i++){
            if(data.options_prices[i].reference == e.target.name){
                data.options_prices[i].price.cents = e.target.value * 100;
                break;
            }
        }
        this.setState({stockData: data});
    };

    handleChange = (e) => {
        let data = this.state.stockData;
        if(!this.validateInput(e)){
            data[e.target.name] = ''
        }else{
            data[e.target.name] = e.target.value
            if(e.target.name === 'retail_metric' && data.sellable_units === 1){
                data.sellable_units = 1;
                data.wholesale_metric = e.target.value
                data.wholesale_price = e.target.value
            }
        }

      this.setState({
          stockData: data,
      })
    };

    categoryOptions = (inputValue) => {
        const url = baseUrl + '/afrijula/stocks/stock?attrs=["category"]&model=Afrijula::Stocks::Stock&unique=category&match='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for(; i< json.length;i++){
                    opts[i] = {label:json[i], value:json[i]};
                }
                return opts;
            }) // my option list array?
            .catch(err => {
                console.log(err);
            });
    };

    clearForm = () => {
        let data = this.state.stockData;
        data.opening_balances.splice(0,data.opening_balances.length);
        data.product = '';
        data.product_number = '';
        data.category = '';
        data.sellable = true;
        data.wholesale_metric = '';
        data.wholesale_price = 0;
        data.retail_metric = 'pc';
        data.sellable_units = 1;
        data.unit_price = 0;

        this.setState({
            stockData: data,
            Wholesale: false,
        });
    };

    onCellClick = (colData,cellMeta) =>{
        this.setState({location_quantity: colData.props.control.props.value});
    };

    updateOpeningBalances = (item) =>{
       let data = this.state.stockData;
       data.opening_balances = item;
    //       do not call set state.

    };

    componentDidMount() {

        if(this.props.newStockName){
            let stock = this.state.stockData;
            stock.name = this.props.newStockName;
            this.setState({
                stockData: stock
            })
        }
    };

    loadCancel = () => {
        if(this.state.noCancel == false){
            return <Button xs={12} sm={12} md={2} color="danger" onClick={()=>{this.setState({backtostock: true})}}
                           style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto",
                               minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
            >{getString().cancel}</Button>
        }
    };

    loadBack = () => {
        if(this.state.noBack == false){
            return <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={8}/>
                                    <GridItem xs={4} sm={4} md={4}>
                                        <Button color="danger" onClick={()=>{this.setState({backtostock: !this.state.backtostock})}}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", float:"right",
                                            minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                            >{getString().back}
                                         </Button>
                                    </GridItem>
                                </GridContainer>
                    </GridItem>
                    </GridContainer>
        }
    };

    generateNumber = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/stocks/stock/generate_number",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{generating: true},
            success: function (data){
                {displaySuccess(getString().stk_num_generated);}
                let _stockData = _this.state.stockData;
                _stockData.number = data.number;
                _this.setState({
                    stockData: _stockData
                });
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    render() {
        const { classes } = this.props;

        if(this.state.saveButton){

            return <Stock rowData = {this.state.stockData} />
        }
        if(this.state.addsupp){
            return <NewStockSupplier open = {this.state.open} onClose={this.onclose} toAddStock = {this.state.toaddstock}/>
        }
        if(this.state.backtostock){
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            if(caller_state.addstock){
                caller_state.addstock = false;
            }else{
                caller_state.addProduct = false
            }
            return <ChildComponent state = {caller_state} returningProps = {this.state.callerProps} />
        }
        else{
        return (
            <div>
                {this.loadBack()}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card className={'lecketInlineItems'}>
                            <CardHeader color="info">
                                <h4>{(this.state.editStock) ? getString().edit :getString().add_stock}</h4>
                            </CardHeader>

                            <CardBody>
                                <form className={classes.container}  autoComplete="off">
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label={getString().product}
                                                type="text"
                                                value={this.state.stockData.name}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <TextField
                                                required
                                                id="number"
                                                name="number"
                                                label={getString().product_number}
                                                type="text"
                                                value={this.state.stockData.number}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            <Button xs={12} sm={6} md={2} color="success" onClick={this.generateNumber.bind(this)}
                                                    style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                        minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                            >{getString().generate}</Button>
                                        </GridItem>

                                        <GridItem xs={12} sm={4} md={4}>
                                            <TextField
                                                required
                                                id="unit_price"
                                                name="unit_price"
                                                label={getString().unit_price}
                                                type="text"
                                                value={this.state.stockData.unit_price}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <GridContainer>
                                            {(this.state.stockData.options_prices) ? (Object.keys(this.state.stockData.options_prices)).map(key => {
                                                let option = this.state.stockData.options_prices[key];
                                                return <GridItem xs={12} sm={3} spacing={1}>
                                                    <TextField
                                                        required
                                                        id={option.reference}
                                                        name={option.reference}
                                                        label={JSON.stringify(option.options).replace(/"|\[|\]|\{|\}/gi, '')}
                                                        type="text"
                                                        size="small"
                                                        value={currencyValue(option.price, false)}
                                                        onChange={e => {this.handleOptsChange(e)}}
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </GridItem>
                                            }) : null}
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        {/*TODO we need to determine what to do regarding wholesale. We need to implement both on stock received and retail and stock transfer*/}
                                        <GridItem xs={12} sm={4} md={4}>
                                            <div>

                                            <TextField
                                                required
                                                id="retail_metric"
                                                name="retail_metric"
                                                label={getString().retail_metric}
                                                type="text"
                                                value={this.state.stockData.retail_metric}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={getString().retMetricDef}
                                                InputProps={{endAdornment: <HelpUtil reference={'inventory_metric'}/>}}
                                            />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <div style={{marginTop: 15}} >
                                                <label>{getString().category}*</label>
                                                <AsyncCreatableSelect defaultOptions loadOptions={this.categoryOptions} name="category" id="category"
                                                                      value={{label: this.state.stockData.category, value: this.state.stockData.category}}
                                                                      placeholder={getString().choose_category} onChange={opt => {
                                                    let stockData = this.state.stockData;
                                                    stockData.category = opt.value;
                                                    this.setState({stockData: stockData})}} />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <span><p>Sellable: </p></span>
                                            <RadioGroup
                                                row
                                                aria-label={getString().sellable}
                                                name="sellable"
                                                className={classes.group}
                                                value={this.state.stockData.sellable ? 'yes' : 'no'}
                                                onChange={e => {
                                                    let data = this.state.stockData;
                                                    data.sellable = (e.target.value == 'yes' ? true : false);
                                                    this.setState({stockData: data});
                                                }}
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label={getString().yes} style={{display:'inline'}}/>
                                                <FormControlLabel value="no" control={<Radio />} style={{display:'inline'}} label={getString().no}/>
                                            </RadioGroup>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <FormControlLabel control={<Checkbox checked={this.state.showWholesale}
                                                                             onChange={(e) => {this.setState({showWholesale: !this.state.showWholesale})}}
                                                                             value={this.state.showWholesale}/>}
                                                          label={getString().wholesale}/>
                                        <GridItem xs={12} sm={4} md={4} style={{display: (this.state.showWholesale) ? 'flex' : 'none'}}>
                                            <TextField
                                                id="sellable_units"
                                                name="sellable_units"
                                                label={getString().sellable_units}
                                                type="text"
                                                value={this.state.stockData.sellable_units}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} style={{display: (this.state.showWholesale) ? 'flex' : 'none'}}>
                                            <TextField
                                                id="wholesale_metric"
                                                name="wholesale_metric"
                                                label={getString().wholesale_mteric}
                                                type="text"
                                                value={this.state.stockData.wholesale_metric}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} style={{display: (this.state.showWholesale) ? 'flex' : 'none'}}>
                                            <TextField
                                                id="wholesale_price"
                                                name="wholesale_price"
                                                label={getString().wholesale_price}
                                                type="text"
                                                value={this.state.stockData.wholesale_price}
                                                onChange={e => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>

                                    </GridContainer>
                                    <GridContainer>

                                    </GridContainer>
                                    {(!this.state.editStock ) ?   <OpeningBalances updateChanges={this.updateOpeningBalances.bind(this)}/>: null}

                                </form>

                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    {this.loadCancel()}
                                    <SaveButton method={this.addStock} title={getString().save} />
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
        }
    }
}

AddStock.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddStock);
