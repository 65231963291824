import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import Typography from "@material-ui/core/Typography/Typography";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input/Input";
import Button from "@material-ui/core/Button/Button";
import Select from "react-select";
import {currencies, displayError, displaySuccess, getString, getGender, getRegions, getBusinessType, getAgeBrackets} from "assets/js/utils/utils.js";

const Country = require('country-js');

const packages = [
    {
        value: 'basic',
        label: 'Basic',
    },
    {
        value: 'standard',
        label: 'Standard',
    },
    {
        value: 'premium',
        label: 'Premium',
    },
    {
        value: 'premium_plus',
        label: 'Premium Plus',
    },
];

const gender = [
    { value: 'female', label: 'Female'},
    { value: 'male', label: 'Male' }
]
const styles = theme => ({

    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    progress: {

        margin: theme.spacing.unit * 2,
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },

    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },

});

class Activation extends React.Component {


    state = {
        sending: false,
        sent: false,
        setCurrent: true,
        userId: null,
        invalid: null,
        default_package: "Basic",
        business_name: null,
        currency: 'GMD',
        owner_gender: 'Female',
        owner_age: null,
        business_type: 'MSME',
        region: null


    };

    completeAndLogin = () => {
        let _this = this;
        let currency = this.state.currency;
        let org = this.state.business_name;
        let userId = this.state.userId;
        let _package = this.state.default_package;
        let ownerAge = this.state.owner_age;
        let ownerGender = this.state.owner_gender;
        let region = this.state.region;
        let businessType = this.state.business_type;
        $.ajax({
            method: "POST",
            url: baseUrl + "/account/settings/",
            dataType: "json",

            data: {
                "organization": org,
                "influence": 'afrijula',
                "default_currency": currency,
                id: userId,
                "package": _package,
                "owner_age": ownerAge,
                "owner_gender": ownerGender,
                "business_type": businessType,
                "region": region
            },

            success: function (data) {
                displaySuccess("Your  account activation is successful please login to start using your app.", "success");
                setTimeout(function () {
                    _this.props.history.push('/login')
                }, 5000);
            }
        });
    };
    handleCurrency = (value) => {
        let curr = Country.search(value['value']);
        // let _detail = this.state.details;
        if (curr && curr[0]) {
            let data = curr[0].currency.currencyCode; // _detail.default_currency = data;
            this.setState({
                currency: data,
                countryName: value['label'] + '(' + data + ')',
            });
        }
    };
    _handleChange = (val) => {

    };
    sendActivation = () => {
        var _this = this;
        let data = JSON.parse('{"' + decodeURI(this.props.location.search.replace(/\?/, '').replace(/&/g, "\",\"").replace(/=/g, "\":\"")) + '"}')
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/authenticate_v2/activate",
            dataType: "json",
            data: {email: data.acc_add, phone_number: data.phone, code: data.token},
            success: function (data) {


                _this.setState({setCurrent: true, sending: false, invalid: null, userId: data.user_id});
            },
            error: function () {
                alert('Unable to activate you account. Please contact support');
            }
        });

    };

    componentDidMount() {
        this.sendActivation();
    }

    render() {
        const {classes} = this.props;
        if (this.state.setCurrent) {
            return (<main className={classes.main}>


                <Paper className={classes.paper}>
                    <CssBaseline/>
                    <Typography component="h1" variant="h5">
                        {getString().complete}
                    </Typography>
                    <form className={classes.form}>

                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="business_name">{getString().business_name||"Business Name"}</InputLabel>
                            <Input id="business_name" name="business_name" autoComplete="business_name" autoFocus
                                   onChange={(e) => {
                                       this.setState({business_name: e.target.value})
                                   }}/>
                        </FormControl>


                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="currency">{getString().currency||
                            "Currency"}</InputLabel>
                            <Select options={currencies} placeholder={this.state.countryName} name="currency"
                                    id="currency"
                                    value={this.state.currency} onChange={this.handleCurrency}/>
                        </FormControl>

                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="owner_gender">{getString().owner_gender||'Gender'}</InputLabel>
                            <Select options={getGender()} placeholder={this.state.owner_gender} name="owner_gender"
                                    id="owner_gender"
                                    value={this.state.owner_gender} onChange={e => this.setState({owner_gender: e['value']})}/>
                        </FormControl>

                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="owner_age">{getString().owner_age||"Age Group"}</InputLabel>
                            <Select options={getAgeBrackets()} placeholder={this.state.owner_age} name="owner_age"
                                    id="owner_age"
                                    value={this.state.owner_age} onChange={e => this.setState({owner_age: e['value']})}/>
                        </FormControl>

                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="business_type">{getString().business_size||"Business Size"}</InputLabel>
                            <Select options={getBusinessType()} placeholder={this.state.business_type} name="business_type"
                                    id="business_type"
                                    value={this.state.business_type} onChange={e => this.setState({business_type: e['value']})}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="region">{getString().region||"Region"}</InputLabel>
                            <Select options={getRegions()} placeholder={this.state.region} name="region"
                                    id="region"
                                    value={this.state.region} onChange={e => this.setState({region: e['value']})}/>
                        </FormControl>

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.completeAndLogin}
                        >
                            {getString().activate}
                        </Button>
                    </form>
                </Paper>
            </main>)
        }
        if (this.state.invalid) {
            return <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <h5>{getString().unable_to_complete_reg}:</h5>
                    <p>{this.state.invalid}</p>
                </Paper>
            </main>
        }
        if (this.state.sending) {

            return <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <LinearProgress className={classes.progress}/>
                    <LinearProgress className={classes.progress} color="secondary"/>
                    <p>{getString().account_activation}</p>
                </Paper>
            </main>
        }
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>

                </Paper>
            </main>
        );
    }

}

Activation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Activation);
