import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";

import PayBill from "components/afrijula/Pay/PayBill.jsx";
import Divider from '@material-ui/core/Divider';
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {displayError, displaySuccess, numberFormat, currencyFormat, dateFormat, compileQuery,getString} from "assets/js/utils/utils.js";
import Table from "material-table";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import Switch from '@material-ui/core/Switch';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class Account_Billing extends React.Component {


    state = {

        last_payment: '',
        last_charge: '',
        balance: null,
        max_users: '',
        monthly_price: '',
        payment_method: '',
        package_name: '',
        charges: [],
        price: 0.0,
        payments: [],
        package: '',

        pay_bill: false,
        upgrade_version: false,

        chargeData: [],

        paymentData: [],
        packages:[],
        allPack: {name: 'all', meta: {description: 'Turn on all'}}

    };

    payBill = () => {
        this.setState({pay_bill: true});
    };

    loadSetting = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/billing/",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                _this.setState({
                    balance: result.balance, max_users: result.max_users, users: result.users,
                    last_payment: result.last_payment, price: result.pricing,
                    package: result.package, payments: result.payments, charges: result.charges,
                    credit_balance: result.credit_balance
                });
            }
        });
    };

    loadOptions = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/packages/",
            data:{localize: true},
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                let packages = _this.state.packages;
                packages.slice(0,packages.length);
                let allPack = _this.state.allPack;
                Object.keys(result).forEach(pack => {
                    if(pack === 'all')
                        allPack.meta = result[pack];
                    else
                        packages.push({name: pack, meta: result[pack]});
                });
                if(allPack.meta.status === true){
                    packages.forEach(pack => {pack.meta.status = true});
                }
                _this.setState({packages:packages, allPack: allPack});
            }
        });
    };

    toggleAllFeatures = (feature, event) => {
        if(feature.meta.status === true)
            feature.meta.status = false;
        else
            feature.meta.status = true;

        let packs = this.state.packages;
        packs.forEach(pack => {
            if(pack.meta.not_in_all !== true)
                pack.meta.status = feature.meta.status;
        });
        this.setState({packages: packs});
        this.updateFeatures(feature);
    };

    allToggled = () => {
        return (this.state.allPack.meta.status === true)
    };

    toggleFeature = (feature, event) => {
        feature.meta.status = (feature.meta.status !== true);
        if(feature.meta.status === false &&
            this.allToggled() && feature.meta.not_in_all !== true){

            let allPack = this.state.allPack;
            allPack.meta.status = false;

            let packs = this.state.packages;
            packs.forEach(item => {
                if(item.meta.not_in_all !== true && feature !== item)
                    item.meta.status = true;
            });
            this.setState({packages: packs, allPack: allPack});
            this.updateFeatures(feature,true);
        }else{
            this.updateFeatures(feature);
        }
    };

    updateFeatures = (feature, except) => {
        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/afrijula/update_package/",
            data:{name: feature.name, status: feature.meta.status, exception: except === true},
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                displaySuccess("Options updated");
                if(feature.name === 'all'){
                    _this.setState({price: result.price});
                    cookies.save('packages','["all"]', {path: '/'});
                }else{
                    let packages = _this.state.packages;
                    for(let i = 0; i < packages; i++){
                        if(packages[i].name === feature.name){
                            packages[i].meta.status = feature.status;
                            break;
                        }
                    }
                    _this.setState({packages: packages, price: result.price});
                    let active_packages = [];
                    for(var i = 0; i < packages.length; i++){
                        if(packages[i].meta.status === true)
                            active_packages.push(packages[i].name);
                    }
                    cookies.save('packages',JSON.stringify(active_packages), {path: '/'});
                }
            },error: function(error){
                displayError(error.responseText);
                _this.toggleAllFeatures(feature);
            }
        });
    };

    componentWillMount() {
        this.updateSuccess();
    };

    updateSuccess =() => {
        this.loadSetting();
        this.loadOptions();
    };

    renderListItem = (pack) =>{
        return <ListItem disabled={pack.meta.not_ready === true}>
            <ListItemText primary={<React.Fragment>
                <Typography component="span" variant="body2" color="textPrimary">
                    <span>{pack.meta.description}&#9;</span>
                    <span>{currencyFormat(pack.meta.price)}</span>
                </Typography>
            </React.Fragment>}
                          secondary={(pack.meta.every) ? <div>{'for every ' + pack.meta.every.count + ' '+pack.meta.every.metric}</div> : null}
            />
            <ListItemSecondaryAction>
                <Switch disabled={pack.meta.not_ready === true}
                        checked={pack.meta.status === true}
                        onChange={(event) => {this.toggleFeature(pack, event);} }
                        name={"toggleP"+pack}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </ListItemSecondaryAction>
        </ListItem>;
    };

    render() {

        if (this.state.pay_bill) {
            return <PayBill package={this.state.package} price={this.state.price} amount={this.state.balance ? ((this.state.balance.cents > 0) ? 0 : this.state.balance.cents/100.0*-1) : 0} currency={this.state.balance ? this.state.balance.currency_iso :'none'}/>;
        }
        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={9} sm={8}>
                                                <h4>{getString().details}</h4>
                                                            <GridContainer>
                                                                        <GridItem xs={12} sm={12} md={12}>
                                                                            <p><b>{getString().balance_due}:</b>
                                                                                <b>{currencyFormat(this.state.balance)}</b></p>
                                                                        </GridItem>
                                                                        <GridItem xs={12} sm={12} md={12}>
                                                                            <p><b>{getString().monthly_price}:</b>
                                                                                <b>{currencyFormat(this.state.price)}</b></p>
                                                                        </GridItem>
                                                                        <GridItem xs={12} sm={12} md={12}>
                                                                            <p><b> {getString().credit_balance}:</b>
                                                                            <b>{currencyFormat(this.state.credit_balance)}</b></p>
                                                                        </GridItem>
                                                                        <GridItem xs={12} sm={12} md={12}>
                                                                            <p><b>{getString().last_payment}:</b>
                                                                                <b>{dateFormat(this.state.last_payment)}</b></p>
                                                                        </GridItem>

                                                            </GridContainer>

                                            </GridItem>
                                            <GridItem xs={9} sm={3}>
                                                <Button color="primary" onClick={this.payBill}
                                                        style={{
                                                            backgroundColor: "#9c27b0",
                                                            margin: ".3125rem 1px",
                                                            minWidth: "auto",
                                                            minHeight: "auto",
                                                            fontSize: "12px",
                                                            color: "#ffffff",
                                                            padding: "12px 30px",
                                                            borderRadius: "3px",
                                                            verticalAlign: "middle"
                                                        }}
                                                ><i className="material-icons">attach_money</i>
                                                    {getString().pay}
                                                </Button>

                                            </GridItem>

                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Card>
                                    <CardHeader color="warning">
                                        <h5>{getString().charges}</h5>
                                    </CardHeader>
                                    <Table
                                        tableRef={this.tableRef}
                                        data={this.state.charges}
                                        columns={[
                                            {title: getString().date, field: 'date', render: rowData => dateFormat(rowData.date),cellStyle:{padding:'1px'}},
                                            {title: getString().total, field: 'total', render: rowData => currencyFormat(rowData.amount),cellStyle:{padding:'1px'}}
                                        ]}
                                        onRowClick={this.rowSelect}
                                        options={{
                                            exportButton: true, filtering: false,
                                            grouping: false, sorting: false,
                                            selection: false, showTitle: false,
                                            pageSize: 5
                                        }}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Card>
                                    <CardHeader color="success">
                                        <h5>{getString().payment}</h5>

                                    </CardHeader>
                                    <Table
                                        tableRef={this.tableRef}
                                        data={this.state.payments}
                                        columns={[
                                            {title: getString().date, field: 'date', render: rowData => dateFormat(rowData.date),cellStyle:{padding:'1px'}},
                                            {title: getString().total, field: 'total', render: rowData => currencyFormat(rowData.amount),cellStyle:{padding:'1px'}}
                                        ]}
                                        onRowClick={this.rowSelect}
                                        options={{
                                            exportButton: true, filtering: false,
                                            grouping: false, sorting: false,
                                            selection: false, showTitle: false,
                                            pageSize: 5
                                        }}
                                    />
                                </Card>
                            </GridItem>

                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <List dense={true}>
                            {<ListItem>
                                <ListItemText primary={<React.Fragment>
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        <span>{this.state.allPack.meta.description}&#9;</span>
                                        <span>{currencyFormat(this.state.allPack.meta.price)}</span>
                                    </Typography>
                                </React.Fragment>}
                                />
                                <ListItemSecondaryAction>
                                    <Switch
                                        checked={this.state.allPack.meta.status === true}
                                        onChange={(event) => {this.toggleAllFeatures(this.state.allPack, event);} }
                                        name={"togglePall"}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>}
                            <Divider />
                            <ListSubheader>{'Individual options'}</ListSubheader>
                            {this.state.packages.filter(pack => pack.meta.not_in_all === undefined).map(pack => {
                                return this.renderListItem(pack);
                            })}
                            <Divider/>
                            <ListSubheader>{'Variable options'}</ListSubheader>
                            {this.state.packages.filter(pack => pack.meta.not_in_all === true).map(pack => {
                                return this.renderListItem(pack);
                            })}
                        </List>
                    </GridItem>
                </GridContainer>

            </div>
        );
    }
}

Account_Billing.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account_Billing);
