import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import SupplierInvoice from "components/afrijula/AddForm/SupplierInvoice_v2.jsx";
import {numberFormat, currencyFormat, dateFormat, getToday,applyPackaging,getString,execPrint,displayError,displaySuccess} from "assets/js/utils/utils.js";
import Rodal from "rodal";
import PaySupplier from "components/afrijula/AddForm/PaySupplierInvoice.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class SupplierHistory extends React.Component{


    state = this.props.state || {

        addPayment: false,
        back: false,


        data: [],
        header:  [
            { title: getString().item, field: 'item'},
            { title: getString().quantity, field: 'quantity'},
            { title: getString().unit_price, field: 'unitprice' },
            { title: getString().total, field: 'total' },
        ],
        today: '',
        amount: '',
        sale_date: '',
        due_date:'',
        delivery_date:'',
        inv_number: '',
        location: '',
        payment_term: '',
        vat: 0.0,
        other_tax: 0.0,
        sub_total: 0.0,
        total: 0.0,
        paySupplier: false,

        editInvoice: false,

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        invoiceData: this.props.invoiceData,
        paymentData: this.props.invoiceData.payments,
        deletePayment: null,
    };

    close = () => {
        this.setState({back: true})
    };


    componentDidMount() {
        this.setState({
            sale_date: getToday(),
            delivery_date: getToday(),
            due_date: getToday(),
        });
    }

    updateCback = (bal,inv_balance) => {
        if(this.props.updateCback)
            this.props.updateCback(bal)
        let inv = this.state.invoiceData;
        inv.balance = inv_balance
        this.setState({invoiceData: inv});
    }

    createRow = (id, name, quantity, unit_cost,total_cost) => {
        return { id, name, quantity, unit_cost,total_cost};
    };

    getProps = () => {
        return this.props || this.props.returningProps;
    };

    getPayables = () => {
        let item = this.state.invoiceData;
        return [{id: item._id, number: item.number, total: item.total, balance: item.balance, amount_to_pay: item.balance}];
    }

    payInvoice = () => {
        this.setState({invoiceSum: this.state.invoiceData.balance, paySupplier: true});
    };

    updateDetailsBal = (bal) =>{
        if(this.props.updateCback)
            this.props.updateCback(bal,true)
    };

    deleteSuppPayment = (id) => {
        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/afrijula/suppliers/payment/"+id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {"status": 'removed'},

            success: function (result) {
                _this.updateDetailsBal(result.payment.amount);
                displaySuccess(getString().payment_deleted);
            },
            error: function (error) {
                displayError(error.responseText);
            }

        });
    };

    showPayments = () =>{
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Paper xs={12} sm={12} md={12}>
                        <h5>{getString().payments}</h5>
                        <Table >

                            <TableHead>
                                <TableRow>
                                    <TableCell size={"small"}>{getString().date}</TableCell>
                                    <TableCell size={"small"} align="right"><b>{getString().amount}</b></TableCell>
                                    <TableCell size={"small"} align="right"><b>{getString().payment_method}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.paymentData.map(row => (
                                    <TableRow key={row._id} style={(row.status == 'removed') ? {textDecoration: 'line-through'} : {}}>
                                        <TableCell size={"small"}>{dateFormat(row.made_on)}</TableCell>
                                        <TableCell size={"small"} align="right">{currencyFormat(row.amount)}</TableCell>
                                        <TableCell size={"small"} align="right">{row.payment_method}</TableCell>
                                        <TableCell>{<span style={{cursor:'pointer', color:'blue'}}
                                                          onClick={()=>{this.deleteSuppPayment(row._id.$oid)}}>{getString().delete}</span>}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </GridItem>
            </GridContainer>
        );
    };

    hideSupplier = () => {
        this.setState({ paySupplier: false });
    };

    render() {
        const { classes } = this.getProps();

        const row = this.state.invoiceData.stock_items.map((row, id) => this.createRow(row._id, row.name,row.quantity,row.unit_cost,row.total_cost));
        if(this.state.back){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState}  returningProps={this.state.callerProps}/>
        } else if(this.state.editInvoice){
            let data = this.state.invoiceData;
            this.state.editInvoice = false;
            return <SupplierInvoice callerProps={this.getProps()} callerState = {this.state} invoiceData={data} callerName={SupplierHistory}/>;

        }else{

            return (
                <div style={{marginTop: -30}}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={9} md={9}>
                                            <Button xs={12} sm={2} md={2} color="warning" title={getString().to_print} onClick={() => {execPrint(document.getElementById("printableView"));}}><i className="material-icons">local_printshop</i></Button>
                                            {this.state.invoiceData.no_supplier == true ? <div></div> : <Button xs={12} sm={2} md={2} color="primary" title={getString().pay}
                                                                                              onClick={this.payInvoice.bind(this)}>{getString().pay}</Button>}
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <Button color="danger" onClick={this.close} style={{float: 'right'}} >{getString().back}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>

                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} id="printableView">

                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{getString().invoice}</h4>
                                </CardHeader>
                                <GridContainer>

                                    <GridItem xs={12} sm={6} md={6}>
                                        <Card>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridItem xs={12}>
                                                        <h4>{getString().supplier_account}: {this.state.invoiceData.supplier ? this.state.invoiceData.supplier.name : 'N/A'}</h4>
                                                    </GridItem>
                                                </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>{getString().sale_date}:</b> <b>{dateFormat(this.state.invoiceData.sale_date)}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>{getString().due_date}:</b> <b>{dateFormat(this.state.invoiceData.due_date)}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>{getString().delivery_date}:</b> <b>{dateFormat(this.state.invoiceData.shipping_date)}</b></p>
                                                        </GridItem>
                                                    </GridContainer>

                                            </CardBody>

                                        </Card>
                                    </GridItem>

                                    <GridItem xs={12} sm={6} md={6}>
                                        <Card>
                                            <CardBody>
                                                <form>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>{getString().location}:</b> <b>{this.state.invoiceData.location}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>{getString().inv_number}:</b> <b>{this.state.invoiceData.number}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>

                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>{getString().payment_term}:</b> <b>{this.state.invoiceData.payment_details}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </form>
                                            </CardBody>

                                        </Card>
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Paper xs={12} sm={12} md={12}>
                                            <Table >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell size={"small"}>{getString().product}</TableCell>
                                                        <TableCell size={"small"} align="right"><b>{getString().unit_cost}</b></TableCell>
                                                        <TableCell size={"small"} align="right"><b>{getString().quantity}</b></TableCell>
                                                        <TableCell size={"small"} align="right"><b>{getString().total}</b></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.map(row => (
                                                        <TableRow key={row.id}>
                                                            <TableCell size={"small"}>{row.name}</TableCell>
                                                            <TableCell size={"small"} align="right">{currencyFormat(row.unit_cost)}</TableCell>
                                                            <TableCell size={"small"} align="right">{numberFormat(row.quantity)}</TableCell>
                                                            <TableCell size={"small"} align="right">{currencyFormat(row.total_cost)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableRow>
                                                        <TableCell size={"small"} datapan={3} />
                                                        <TableCell size={"small"} colSpan={2}>{getString().sub_total}</TableCell>
                                                        <TableCell size={"small"} align="right">{currencyFormat(this.state.invoiceData.sub_total)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell size={"small"} datapan={3} />
                                                        <TableCell size={"small"} colSpan={2}>{getString().vat}</TableCell>
                                                        <TableCell size={"small"} align="right">{currencyFormat(this.state.invoiceData.vat_amount)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell datapan={3} />
                                                        <TableCell colSpan={2}>{getString().other_tax}</TableCell>
                                                        <TableCell align="right">{currencyFormat(this.state.invoiceData.other_tax)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell datapan={3} />
                                                        <TableCell colSpan={2}>{getString().total}</TableCell>
                                                        <TableCell align="right">{currencyFormat(this.state.invoiceData.total)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell datapan={3} />
                                                        <TableCell colSpan={2}>{getString().balance}</TableCell>
                                                        <TableCell align="right">{currencyFormat(this.state.invoiceData.balance)}</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    {(this.state.paymentData.length !== 0) ? this.showPayments() : getString().no_invoice_payment}
                    <Rodal visible={this.state.paySupplier} height={600} style={{maxHeight:500, zIndex:50000}} width={450} maxWidth={450} showMask={false} onClose={()=>{this.setState({paySupplier: false})}}>
                       <div>
                        <PaySupplier  invoiceSum={this.state.invoiceSum} invoiceData={this.getPayables()}
                                      supplierName={this.state.invoiceData.supplier_name}
                                      hidePayment={this.hideSupplier.bind(this)} updateCback={this.updateCback.bind(this)}
                                      callerName = {SupplierHistory} callerProps = {this.getProps()} callerState = {this.state} />
                       </div>
                    </Rodal>
                </div>
            );
        }
    }
}


export default withStyles(styles)(SupplierHistory);
