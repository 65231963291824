import React from "react";
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import baseUrl from "assets/js/config/const.js";
import {
  displayError,
  getLocaleString,
  getString,
} from "assets/js/utils/utils.js";
import CloseIcon from "@material-ui/icons/Close";
import { Link, NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import NewAccount from "components/afrijula/Utils/NewAccount.jsx";
// core components
import Button from "components/common/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import cookies from "react-cookies";
import Wizard from "components/afrijula/Wizard/Wizard.jsx";
import $ from "jquery";
import { Detector } from "react-detect-offline";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import ChangeLocale from "components/common/Locale/LocaleSelector.jsx";
import Fab from "@material-ui/core/Fab/Fab";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Menu from "@material-ui/core/Menu";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import WindowSizeListener from "react-window-size-listener";

class HeaderLinks extends React.Component {
  state = {
    socket: null,
    openNotice: false,
    openLogout: false,
    noticeRequest: false,
    offlineMode: true,
    notificationsList: [],
    wizardList: [],
    wizard_visible: false,
    dialogHeader: null,
    dialogContent: null,
    selectLocale: false,
    popupList: [],
    showPopup: true,
    helpDialog: false,
    covidDialog: false,
    clanguage: "english",
    language: "english",
    switchAccounts: false,
    accounts: [],
    createAccount: false,
    accountData: { name: "" },
    winSize: window.innerWidth,
  };
  constructor(props) {
    super(props);
  }

  onChangeLocale = (opt) => {
    this.setState({ selectLocale: true });
  };

  closeLocale = () => {
    window.location.reload();
  };

  handleToggle = (state) => {
    this.setState(state);
  };

  viewHelp = () => {
    return (
      <div>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => {
              this.setState({ hanchorEl: event.currentTarget });
            }}
          >
            Choose Language
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.hanchorEl}
            keepMounted
            open={Boolean(this.state.hanchorEl)}
            onClose={() => {
              this.setState({ hanchorEl: null });
            }}
          >
            <MenuItem
              onClick={() => {
                this.setState({ hanchorEl: null, language: "english" });
              }}
            >
              english
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({ hanchorEl: null, language: "mandinka" });
              }}
            >
              Mandinka
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({ hanchorEl: null, language: "wolof" });
              }}
            >
              Wolof
            </MenuItem>
          </Menu>
        </div>
        {this.state.language === "english" ? (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>What to do when you sign in to Afrijula</strong>
                  <iframe
                    src="https://www.youtube.com/embed/8DISGNAofxQ"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Upload to the E-Shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/quRfQ3HSHr8"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Request a Settlement on The E-shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/X3Che3TD0&"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Upload products to the E-Shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/3pykj1TF2hg"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
        {this.state.language === "wolof" ? (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardBody>
                  <strong>What to do when you sign in to Afrijula</strong>
                  <iframe
                    src="https://www.youtube.com/embed/PNHsNQyunPk"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Upload products to the E-Shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/4rxZRc3n2_w"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Request a Settlement from the E-shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/2jNXf97-uOI"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
        {this.state.language === "mandinka" ? (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>What to do when you sign in to Afrijula</strong>
                  <iframe
                    src="https://www.youtube.com/embed/5KR_E7gE3-k"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Upload products to the E-Shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/tCY66nEwE"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>How to Request a Settlement from the E-shop</strong>
                  <iframe
                    src="https://www.youtube.com/embed/6oSVfx6f_BI"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
      </div>
    );
  };
  viewCovid = () => {
    return (
      <div>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => {
              this.setState({ canchorEl: event.currentTarget });
            }}
          >
            Choose Language
          </Button>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            href="https://covid.afrijula.gm"
            target="_blank"
          >
            COVID TRACKER
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.canchorEl}
            keepMounted
            open={Boolean(this.state.canchorEl)}
            onClose={() => {
              this.setState({ canchorEl: null });
            }}
          >
            <MenuItem
              onClick={() => {
                this.setState({ canchorEl: null, clanguage: "english" });
              }}
            >
              english
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({ canchorEl: null, clanguage: "mandinka" });
              }}
            >
              Mandinka
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({ canchorEl: null, clanguage: "wolof" });
              }}
            >
              Wolof
            </MenuItem>
          </Menu>
        </div>
        {this.state.clanguage === "english" ? (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>Physical Mitigation</strong>
                  <iframe
                    src="https://www.youtube.com/embed/tXYAwpuMxUw"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6}>
              <Card>
                <CardBody>
                  <strong>Covid 19 Misinformation</strong>
                  <iframe
                    src="https://www.youtube.com/embed/eeqYZW5iTLQ"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
        {this.state.clanguage === "wolof" ? (
          <GridContainer>
            <p>Coming Soon...</p>
          </GridContainer>
        ) : null}
        {this.state.clanguage === "mandinka" ? (
          <GridContainer>
            <p>Coming Soon...</p>
          </GridContainer>
        ) : null}
      </div>
    );
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ openNotice: false, openLogout: false });
  };

  existingMessage = (item, notifList, tag) => {
    if (notifList === undefined) return 0;
    return notifList.findIndex((existing) => {
      return existing.tag === tag;
    });
  };

  deleteMessage = (messageId) => {
    let notifs = this.state.notificationsList;
    this.state.notificationsList = notifs.filter((item) => {
      return item.messageId != messageId;
    });

    this.state.socket.send(
      JSON.stringify({ from: "client", action: "delete", messageId: messageId })
    );
    this.setState({ notificationList: notifs });
  };

  triggerNotices = () => {
    this.state.socket = new WebSocket(
      "wss://notice.lecket.gm?channelName=" + cookies.load("profileId")
    );

    //we need to frst registered
    this.state.socket.onopen = (event) => {
      this.state.socket.send(JSON.stringify({ from: "client" }));
      if (this.state.noticeRequest) return;
      this.setState({ noticeRequest: true });
      $.ajax({
        method: "GET",
        url: baseUrl + "/afrijula/afrijula/",
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(result) {},
      });
    };
    this.state.socket.onmessage = (message) => {
      let notifList = this.state.notificationsList;
      let wizardList = this.state.wizardList;
      let popupList = this.state.popupList;

      let m = JSON.parse(message.data);
      if (m == null) return;
      //message must include {message: '', metaData:'meta data'}
      if (m.status) return;
      m.data.forEach((item) => {
        if (item.type === "dialog" || item.type === "list") {
          const index = this.existingMessage(item, notifList, m.tag);
          if (index >= 0) {
            notifList[index] = {
              data: item,
              messageId: m.messageId,
              tag: m.tag,
            };
          } else {
            notifList.push({ data: item, messageId: m.messageId, tag: m.tag });
          }
        } else if (item.type === "run") {
          item.wizard.forEach((l) => {
            wizardList.push(l);
          });

          this.runWizard();
          this.deleteMessage(m.messageId);
        } else if (item.type === "popup") {
          const index = this.existingMessage(item, popupList, m.tag);
          if (index < 0) {
            popupList.push({ data: item, messageId: m.messageId, tag: m.tag });
            this.setState({ popupList: popupList, showPopup: true });
          }
        }
      });

      this.setState({ notificationsList: notifList, wizardList: wizardList });
    };
    this.state.socket.onerror = (error) => {
      setTimeout(() => {
        this.triggerNotices();
      }, 1000);
    };
    this.state.socket.onclose = (event) => {
      setTimeout(() => {
        this.triggerNotices();
      }, 1000);
    };
  };

  viewPopup = () => {
    const { classes } = this.props;
    return (
      <List>
        {this.state.popupList.map((popup) => {
          return (
            <ListItem
              onClick={() => {
                if (popup.data.link) {
                  //    TODO load page
                }
              }}
            >
              <ListItemText
                primary={popup.data.message}
                secondary={
                  popup.data.list
                    ? popup.data.list.map((item) => {
                        return (
                          <List component="div" disablePadding>
                            <ListItem
                              style={{ paddingTop: 2, paddingBottom: 2 }}
                              className={classes.nested}
                            >
                              <ListItemText primary={item} />
                            </ListItem>
                          </List>
                        );
                      })
                    : null
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  handleAdmin = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  handleAccount = () => {
    let _this = this;
    this.setState({ openNotice: false, openLogout: false });
    $.ajax({
      method: "GET",
      url: baseUrl + "/account/account",
      dataType: "json",
      data: { all_mine: true, influence: "afrijula" },
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ accounts: result, switchAccounts: true });
      },
    });
  };

  viewAccounts = () => {
    const { classes } = this.props;
    return (
      <div>
        {this.state.createAccount == true ? (
          <NewAccount
            onSave={(e) => {
              let data = this.state.accountData;
              data[e.target.name] = e.target.value;
              this.setState({ accountData: data });
            }}
          />
        ) : (
          <Table>
            <TableBody>
              {this.state.accounts.map((account) => {
                return (
                  <TableRow>
                    <TableCell>
                      <strong>{account.name}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{account.status}</strong>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          this.switchTo(
                            account.id.$oid,
                            account.profile_id.$oid
                          );
                        }}
                      >
                        Switch
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  };

  getUserType = (userType) => {
    let names = userType.split("::");
    names.splice(1, names.length - 2, names[names.length - 1]);
    return names;
  };

    applyUser = (result) => {
        console.log(result)
        let expires = new Date();
        expires = new Date(expires.getTime() + ((result.age-30)*1000));
        cookies.save('token',result.auth_token, {path:'/',expires: expires});
        cookies.save('profile',result.profile, {path:'/',expires: expires});
        cookies.save('profileId',result.profile_id, {path:'/',expires:expires});
        cookies.save('orgId',result.org.id, {path:'/',expires:expires});
        cookies.save('orgName',result.org.name, {path:'/',expires:expires});
        cookies.save('orgContact',result.org.contact, {path:'/',expires:expires});
        cookies.save('orgEmail',result.org.email, {path:'/',expires:expires});
        cookies.save('orgAddress',result.org.address, {path:'/',expires:expires});
        cookies.save('packages',result.org.packages, {path:'/',expires:expires});
        cookies.save('gps',result.org.gps, {path: '/',expires: expires});
        cookies.save('defaultCurrency',result.org.settings.default_currency, {path:'/',expires:expires});
        cookies.save('onlineShopping', result.org.settings.e_commerce, {path: '/', expires:expires});
        cookies.save('tax_percentage',result.org.settings.tax_percentage, {path:'/',expires:expires});
        cookies.save('printer_type',result.org.settings.printer_type, {path:'/',expires:expires});
        cookies.save('cashbookActivated',result.org.settings.cashbook_activated, {path:'/',expires: expires});
        cookies.save('automateReconciliation',result.org.settings.automate_reconciliation, {path:'/',expires: expires});
        cookies.save('accept_shop_terms', result.org.settings.e_accept ? true : false, {path:'/'});
        const user_type = this.getUserType(result.user_type)[2];
        cookies.save('user_type', user_type);
        window.location.href = '/dashboard';
    }

  switchTo = (orgId, profileId) => {
    let _this = this;
    $.ajax({
      method: "Get",
      url: baseUrl + "/account/account/" + orgId + "/switch",
      dataType: "json",
      data: { profile_id: profileId },
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.applyUser(result);
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  createAndSwitch = () => {
    const data = this.state.accountData;
    data.influence = "afrijula";
    let _this = this;
    $.ajax({
      method: "POST",
      url: baseUrl + "/account/account/afrijula/create_and_switch",
      dataType: "json",
      data: data,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.applyUser(result);
        _this.setState({ createAccounts: false, switchAccounts: false });
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  runWizard = () => {
    this.setState({ wizard_visible: true });
  };
  componentDidMount() {
    this.triggerNotices();
    this.setState({ winSize: window.innerWidth });
  }

  loadItem = (item) => {
    if (item.list) {
      let content = item.list;

      this.setState({ dialogContent: content, dialogHeader: item.message });
    }
  };

  viewItem = () => {
    return (
      <div>
        {this.state.dialogContent
          ? this.state.dialogContent.map((i) => {
              return <div>{i}</div>;
            })
          : ""}
      </div>
    );
  };

  closeDialog = () => {
    this.setState({ dialogContent: null, dialogHeader: null });
  };

  closePopups = () => {
    let _this = this;
    this.state.popupList.forEach((item) => {
      _this.deleteMessage(item.messageId);
    });
    this.setState({ showPopup: false });
  };

  determineMessage = (item) => {
    let message = item.message;

    return message;
  };

  enableShopping = () => {};

  shoppingEnabled = () => {
    const x = cookies.load("onlineShopping");
    return x && x == true ? true : false;
  };
  render() {
    const { classes } = this.props;
    const { openNotice, openLogout } = this.state;

    return (
      // using window size listener to listen for windowWidth change
      <WindowSizeListener
        onResize={(size) => {
          // update the state of the winSize
          this.setState({ winSize: size.windowWidth });
        }}
      >
        <div style={{ width: "100%" }}>
          <Detector
            polling={{ interval: 15000 }}
            render={({ online }) => (
              <div className={online ? "normal" : "warning"}>
                {online ? null : "You are currently offline"}
              </div>
            )}
          />
          <div className={classes.manager} style={{ width: "100%" }}>
            {/*{(window.innerWidth > 300) ? <span style={{flexDirection:'row', marginRight: '15px'}} className={classes.buttonLink}>*/}
            {/*<a  href={"http://static3.insistglobal.com/afrijula/pos.msi"} target={"_blank"} style={{fontSize: 12,fontWeight:"bold"}}>*/}
            {/*<i className="material-icons">cloud_download</i>*/}
            {/*{getString().download}*/}
            {/*</a>*/}
            {/*</span> : null}*/}

            {/* covid button */}
            {this.state.winSize >= 800 ? (
              // display on bigger screen
              <Fab
                variant="extended"
                color="primary"
                style={{ marginRight: 5 }}
                onClick={() => {
                  this.setState({ covidDialog: true });
                }}
              >
                Covid??
              </Fab>
            ) : (
              // display on mobile device
              <button
                onClick={() => {
                  this.setState({ covidDialog: true });
                }}
                style={{
                  backgroundColor: "#3f51b5",
                  color: "white",
                  padding: "5px 5px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "14px",
                  margin: "5px",
                  border: "1px solid #3f51b5",
                  borderRadius: "4px",
                }}
              >
                Covid
              </button>
            )}

            {/* help button */}

            {this.state.winSize >= 800 ? (
              // display on bigger screens
              <Fab
                variant="extended"
                color="primary"
                style={{ marginRight: 5 }}
                onClick={() => {
                  this.setState({ helpDialog: true });
                }}
              >
                Help Me
              </Fab>
            ) : (
              // display on mobile device
              <button
                onClick={() => {
                  this.setState({ helpDialog: true });
                }}
                style={{
                  backgroundColor: "#3f51b5",
                  color: "white",
                  padding: "5px 5px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "14px",
                  margin: "5px",
                  border: "1px solid #3f51b5",
                  borderRadius: "4px",
                }}
              >
                Help Me
              </button>
            )}

            {this.state.winSize >= 800 ? (
              <>
                <span
                  style={{ flexDirection: "row", width: 90 }}
                  className={classes.buttonLink}
                >
                  <a
                    href={
                      "https://drive.google.com/file/d/19i1mpXDyW6wIjIqUmqEx5uav9f6p5_NC/view?usp=sharing"
                    }
                    target="_blank"
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    User Guide
                  </a>
                  <ChangeLocale
                    selectLocale={this.state.selectLocale}
                    close={this.closeLocale.bind(this)}
                  />
                </span>
                <span
                  style={{ flexDirection: "row", width: 100 }}
                  className={classes.buttonLink}
                >
                  <a
                    href={baseUrl + "/privacy_policy_afrijula.html"}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </span>
                <span
                  style={{ flexDirection: "row", width: 90 }}
                  className={classes.buttonLink}
                >
                  <a
                    onClick={(e) => this.onChangeLocale(e)}
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-language" />
                    {getLocaleString()}
                  </a>
                  <ChangeLocale
                    selectLocale={this.state.selectLocale}
                    close={this.closeLocale.bind(this)}
                  />
                </span>
                &nbsp;
                <span
                  style={{ flexDirection: "row", width: 20 }}
                  className={classes.buttonLink}
                >
                  <Link to="/e_commerce">
                    {this.shoppingEnabled() ? (
                      <i className="material-icons">clear</i>
                    ) : (
                      <i className="material-icons">add_shopping_cart_icon</i>
                    )}
                  </Link>
                </span>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <span
                  className={classes.buttonLink}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#21130d",
                    color: "white !important",
                    padding: "3px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "12px",
                    border: "1px solid #21130d",
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                >
                  <a
                    href={
                      "https://drive.google.com/file/d/19i1mpXDyW6wIjIqUmqEx5uav9f6p5_NC/view?usp=sharing"
                    }
                    target="_blank"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    Guide
                  </a>
                </span>
                <span
                  className={classes.buttonLink}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#e0a31f",
                    color: "white !important",
                    padding: "3px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "12px",
                    border: "1px solid #e0a31f",
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                >
                  <a
                    href={baseUrl + "/privacy_policy_afrijula.html"}
                    target="_blank"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    Policy
                  </a>
                </span>
                <span
                  style={{ flexDirection: "row", width: 90, cursor: "pointer" }}
                  className={classes.buttonLink}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#2596be",
                    color: "white !important",
                    padding: "3px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "12px",
                    border: "1px solid #2596be",
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                >
                  <a
                    onClick={(e) => this.onChangeLocale(e)}
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-language" />
                    {getLocaleString()}
                  </a>
                  <ChangeLocale
                    selectLocale={this.state.selectLocale}
                    close={this.closeLocale.bind(this)}
                  />
                </span>{" "}
                &nbsp; &nbsp;
                <span
                  style={{ flexDirection: "row", width: 20, fontSize: "12px" }}
                  className={classes.buttonLink}
                >
                  <Link to="/e_commerce">
                    {this.shoppingEnabled() ? (
                      <i className="material-icons">clear</i>
                    ) : (
                      <i className="material-icons">add_shopping_cart_icon</i>
                    )}
                  </Link>
                </span>
              </div>
            )}

            <Button
              style={{
                flexDirection: "row",
                width: "fit-content",
                position: "absolute",
                right: 90,
                top: -5,
                marginRight: "10px",
                marginLeft: "15px",
              }}
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              color={this.state.winSize > 959 ? "transparent" : "white"}
              justIcon={true}
              simple={false}
              aria-owns={openNotice ? "menu-list-grow" : null}
              aria-haspopup="true"
              onClick={() => {
                this.handleToggle({ openNotice: true });
              }}
              className={classes.buttonLink}
            >
              <Notifications className={classes.icons} />
              <span className={classes.notifications}>
                {this.state.notificationsList.length}
              </span>
            </Button>
            <Poppers
              open={openNotice}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              className={classNames({ [classes.popperClose]: !openNotice })}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{
                      maxWidth: this.state.winSize - 50,
                      margin: 5,
                      wordBreak: "break-all",
                    }}
                  >
                    <ClickAwayListener
                      onClickAway={this.handleClose}
                      style={{
                        maxWidth: this.state.winSize - 50,
                        margin: 5,
                        wordBreak: "break-all",
                      }}
                    >
                      <div
                        style={{
                          margin: "10px",
                          maxWidth: this.state.winSize - 50,
                          wordBreak: "break-all",
                          overflow: "auto",
                        }}
                      >
                        <ul role="menu" style={{ listStyle: "none" }}>
                          {this.state.notificationsList.map((message) => {
                            const item = message.data;
                            if (item.message)
                              return (
                                <li
                                  onClick={(e) => {
                                    this.handleClose(e);
                                  }}
                                  style={{
                                    fontSize: `${
                                      this.state.winSize > 600 ? "15px" : "12px"
                                    }`,
                                    maxWidth: this.state.winSize,
                                    wordBreak: "break-all",
                                    margin: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  <a
                                    onClick={() => {
                                      this.loadItem(item);
                                    }}
                                  >
                                    {this.determineMessage(item)}
                                  </a>
                                  <a
                                    onClick={(e) =>
                                      this.deleteMessage(message.messageId)
                                    }
                                    style={{
                                      fontWeight: "bold",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <CloseIcon
                                      className={classes.icons}
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </a>
                                  <hr />
                                </li>
                              );
                          })}
                        </ul>
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>

            <Button
              color={this.state.winSize > 959 ? "transparent" : "white"}
              justIcon={true}
              style={{
                flexDirection: "row",
                width: "fit-content",
                position: "absolute",
                right: 45,
                top: -5,
                marginRight: "10px",
                marginLeft: "15px",
              }}
              simple={false}
              aria-label="Person"
              className={classes.buttonLink}
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              aria-owns={openLogout ? "menu-list-grow" : null}
              aria-haspopup="true"
              onClick={() => {
                this.handleToggle({ openLogout: true });
              }}
            >
              <Person
                className={classes.icons}
                style={{ textAlign: "center" }}
              />
            </Button>

            <Poppers
              open={openLogout}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              className={classNames({ [classes.popperClose]: !openLogout })}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={this.handleAccount}
                          className={classes.dropdownItem}
                        >
                          Accounts
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleAdmin}
                          className={classes.dropdownItem}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
          <div>
            {this.state.switchAccounts ? (
              <Dialog
                open={this.state.switchAccounts}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {getString().accounts}
                </DialogTitle>
                <DialogContent>{this.viewAccounts()}</DialogContent>
                <DialogActions>
                  {this.state.createAccount === false ? (
                    <div>
                      <Button
                        onClick={() => {
                          this.setState({ createAccount: true });
                        }}
                        color="success"
                      >
                        add account
                      </Button>
                      <Button
                        onClick={() => {
                          this.setState({ switchAccounts: false });
                        }}
                        color="danger"
                      >
                        Close
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => {
                          this.createAndSwitch();
                        }}
                        color="success"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          this.setState({ createAccount: false });
                        }}
                        color="danger"
                      >
                        cancel
                      </Button>
                    </div>
                  )}
                </DialogActions>
              </Dialog>
            ) : null}
            {this.state.dialogContent != null ? (
              <Dialog
                open={this.state.dialogContent != null}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {this.state.dialogHeader}
                </DialogTitle>
                <DialogContent>{this.viewItem()}</DialogContent>
                <DialogActions>
                  <Button onClick={this.closeDialog} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
            {this.state.helpDialog ? (
              <Dialog
                open={this.state.helpDialog}
                aria-lablabelledby="form-dialog-title"
              >
                <DialogContent>{this.viewHelp()}</DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({ helpDialog: false });
                    }}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
            {this.state.covidDialog ? (
              <Dialog
                open={this.state.covidDialog}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent>{this.viewCovid()}</DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({ covidDialog: false });
                    }}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
            <Dialog
              open={this.state.popupList.length > 0 && this.state.showPopup}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">{"Urgent"}</DialogTitle>
              <DialogContent>{this.viewPopup()}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.closePopups();
                  }}
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {this.state.wizard_visible ? (
            <Wizard
              steps={this.state.wizardList}
              closeWizard={this.hideWizard}
            />
          ) : null}
        </div>
      </WindowSizeListener>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
