import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Select from 'react-select';
import cookies from "react-cookies";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import {
    currencyFormat,
    currencyValue,
    displayError,
    displaySuccess,
    getString,
    getToday
} from "assets/js/utils/utils.js";
import MaterialTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import SaveButton from "../CustomComponents/SaveButton";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },


});


class PaySupplierInvoice extends React.Component {

    state = {
        supplier: null,
        product: null,
        amount: 0,
        date: null,
        total_amount: 0,
        balance: null,
        pay_amount: 0,
        payment_method: 'cash',
        payment_methods: [{label: "cash", value: "cash"}, {
            label: "bank transfer",
            value: "bank transfer"
        }, {label: 'cheque', value: "cheque"}],

        header: [

            {title: getString().inv_number, field: 'number'},
            {
                title: getString().balance,
                field: 'balance',
                render: rowData => currencyFormat(rowData.balance),
                cellStyle: {padding: '2px'}
            },
            {
                title: getString().amount_to_pay,
                field: 'amount_to_pay',
                cellStyle: {padding: '2px'},
                render: rowData => <TextField
                    id={rowData.id.$oid}
                    name="pay_amount"
                    defaultValue={Number(rowData.balance.cents) / 100.0}
                    placeholder={currencyFormat(rowData.balance)}
                    onChange={(e) => {
                        this.handleChange(e)
                    }}
                />

            }
        ],
        backToSupplier: false,
        back: false,

        dueInvData: [],
        dueInvHeader: [
            {name: getString().date},
            {name: getString().name},
            {name: getString().balance},
        ],
        suppliers: [],
        selectedSupplier: null,

        selectedInvoices: this.props.invoiceData,
        selectedInvoiceSum: this.props.invoiceSum,
        currentSupplier: this.props.currentSupplier,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        banks: [],
        fromAccount: {},
        supplierName: this.props.supplierName,
        getRefNum: false,
        refNumber: '',

        cashbookActivated: false,
    };

    updatePayment = (e) => {
        (e.value === "cheque" || e.value === "bank transfer") ?
            this.setState({payment_method: e.value, getRefNum: true})
            :
            this.setState({payment_method: e.value});
    };

    handleChange = (e) => {
        let invoices = this.state.selectedInvoices;
        if (e.target.name === "pay_amount") {
            let i = 0;
            for (; i < invoices.length; i++) {
                if (invoices[i].id.$oid === e.target.id)
                    break;
            }
            if (invoices[i]) {
                invoices[i].amount_to_pay = e.target.value * 100.0
            }
            this.setState({selectedInvoices: invoices});
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }

    };

    payInvoices = () => {
        let accountNumber = '';
        let refNumber = '';
        if (this.state.cashbookActivated === "true") {
            accountNumber = this.state.fromAccount.number;
            refNumber = this.state.refNumber;
        }

        let inv_to_pay = {
            invs: this.state.selectedInvoices.map((m) => {
                return {
                    id: m.id.$oid,
                    amount_to_pay: currencyValue(m.amount_to_pay, false)
                }
            }),
            date: this.state.date,
            payment_method: this.state.payment_method,
            from_account_number: accountNumber,
            reference_number: refNumber,
            recipient: this.state.supplierName,
        };
        let _this = this;
        return $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/suppliers/payment/",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: inv_to_pay,

            success: function (result) {
                displaySuccess(result.success);
                let bal = result.balance;
                if (_this.props.updateCback)
                    _this.props.updateCback(bal, result.inv_balance);
                _this.props.hidePayment();
            },
            error: function (error) {
                displayError(error.responseText);
            }

        });
    };

    handleRefNumber = (e) => {
        this.setState({
            refNumber: e.target.value,
        })
    };

    close = () => {
        this.setState({backToSupplier: true});
        this.setState({
            supplier: '',
            product: '',
            amount: '',
            date: '',
        });

    };

    getInvoiceSum = () => {
        let invoices = this.state.selectedInvoices;
        let invoiceSum = 0;
        invoices.forEach(function (item) {
            invoiceSum += Number(item.total.cents) / 100.0;
        });
        return currencyFormat(invoiceSum);
    };

    accountOptions = () => {

        let the_data = [];
        const filter = '"status":{"$ne":"blocked"}';
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/cashbook/bank_account",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                "attrs": ["id", "name", "number", "bank", "currency", "balance", "local_balance", "status"],
                "order": [{"by": "desc", "attr": "name"}], "model": "Afrijula::Cashbook::BankAccount", filter
            },

            success: function (data) {
                $.map(data, function (data) {
                    the_data.push({
                        label: data.name, value: data._id.$oid, status: data.status, local_balance: data.local_balance,
                        number: data.number, bank: data.bank, currency: data.currency, balance: data.balance
                    });
                });
                this.setState({
                    banks: the_data,
                })
            }.bind(this)
        });
    };

    handleAccount = (event) => {
        if (event.status === "blocked") {
            displayError(getString().disabled_account_trans);
            this.setState({
                showBalance: false,
            });

        } else {
            let _account = this.state.fromAccount;
            _account = event;
            this.setState({
                fromAccount: _account,
                showBalance: true
            });
        }
    };

    componentDidMount() {
        this.setState({
            date: getToday(),
        });
        this.accountOptions();

    }

    componentWillMount() {
        let isActive = cookies.load('cashbookActivated');
        this.setState({
            cashbookActivated: isActive,
        });
    }


    render() {
        const {classes} = this.props;

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState} returningProps={this.state.callerProps}/>
        }

        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {<h4> {getString().pay_supplier} <b>{getString().total_amount} {this.getInvoiceSum()}</b></h4>}

                        <form className={classes.form + " lecketInlineItems"} autoComplete="off">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        id="date"
                                        name="date"
                                        className={classes.textField}
                                        value={this.state.date}
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                        type="date"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                {(this.state.cashbookActivated === "true") ?
                                    <GridItem xs={12} sm={12} md={6}>
                                        <span>{getString().account}</span>
                                        <Select options={this.state.banks} defaultOptions
                                                placeholder={getString().select_from_acc}
                                                defaultValue={this.state.fromAccount} onChange={(event) => {
                                            this.handleAccount(event)
                                        }}
                                        />
                                        {(this.state.showBalance) ?
                                            <span>{getString().current_bal + currencyFormat(this.state.fromAccount.balance)}</span>
                                            : null
                                        }
                                    </GridItem>
                                    :
                                    null
                                }
                                <GridItem xs={12} sm={12} md={6}>
                                    <span>{getString().payment_method}</span>
                                    <Select
                                        id="payment_method"
                                        name="payment_method"
                                        options={[{label: "cash", value: "cash"}, {
                                            label: "bank transfer",
                                            value: "bank transfer"
                                        }, {label: 'cheque', value: "cheque"}]}
                                        defaultValue={{value: this.state.payment_method}}
                                        onChange={(e) => {
                                            this.updatePayment(e)
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                {
                                    (this.state.getRefNum) ?
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                required
                                                id="refNumber"
                                                name="refNumber"
                                                label={getString().refNo}
                                                className={classes.textField}
                                                value={this.state.refNumber}
                                                onChange={(e) => {
                                                    this.handleRefNumber(e)
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                autoFocus
                                            />
                                        </GridItem> :
                                        null
                                }
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <MaterialTable
                                                className={classes.smallTable}

                                                title="Invoices to Pay"
                                                columns={this.state.header}

                                                data={this.state.selectedInvoices}
                                                options={{
                                                    cellStyle: {padding: '2px;'},
                                                    headerStyle: {padding: '2px;'},
                                                    search: false,
                                                    paging: false,
                                                    showEmptyDataSourceMessage: false
                                                }}


                                            />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </form>
                        <CardFooter>
                            <GridItem xs={12} sm={12} md={12}>
                                <SaveButton method={this.payInvoices} title={getString().pay} />
                            </GridItem>
                        </CardFooter>
                    </GridItem>
                </GridContainer>
            </div>

        );
    }
}

PaySupplierInvoice.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaySupplierInvoice);
