import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";

import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import AsyncSelect  from "react-select/async";
import {
  getString,
  validateEmail,
  phonenumber,
  displayError,
  displaySuccess,
} from "assets/js/utils/utils.js";
import MaskedInput from "react-text-mask";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "+",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Add_User extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      first_name: "",
      last_name: "",
      phone: "",
      address: "",
      email: "",
      user_name: "",
      user_type: "cashier",
      password: "",
      add_user: false,
      user_types: [
        {
          value: "admin",
          label: "Account Admin",
        },
        {
          value: "accountant",
          label: "Accountant",
        },
        {
          value: "basic_admin",
          label: "Admin",
        },
        {
          value: "cashier",
          label: "Cashier",
        },
        {
          value: "store_manager",
          label: "Store Manager",
        },
      ],
      status: [
        {
          value: "activate",
          label: "Activate",
        },
        {
          value: "unactivate",
          label: "Unactivate",
        },
      ],

      callerState: this.props.callerState,
      callerProps: this.props.callerProps,
      callerName: this.props.callerName,
      userData: this.props.userData,
      back: false,
      userID: this.props.userData ? this.props.userData.user_id : "",
      profileId: this.props.userData ? this.props.userData._id.$oid : "",
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveProfile = () => {
    let _this = this;
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let password = this.state.password;
    let user_type = this.state.user_type;
    let phone = this.state.phone.trim();
    let email = this.state.email;
    let address = this.state.address;
    let location = this.state.location;
    let id = this.state.userID;

    let reg = /^[A-Za-z1-9\s]+$/;
    if (first_name === "" || !reg.test(first_name)) {
      displayError("Please enter first name");
      document.getElementById("first_name").focus();
      return;
    }
    if (last_name === "" || !reg.test(last_name)) {
      displayError("Please enter last name");
      document.getElementById("last_name").focus();
      return;
    }
    if (email === "" && phone === "") {
      displayError("Please enter a valid phone number or email address");
      return;
    }
    if (email !== "" && !validateEmail(email)) {
      displayError("Please enter a valid email address");
      document.getElementById("email").focus();
      return;
    }

    if (phone !== "" && !phonenumber(phone.trim())) {
      displayError("Please enter a valid phone number");
      document.getElementById("phone").focus();
      return;
    }
    if (email === "" && (password === "" && !this.props.nopwd)) {
      displayError(
        "You must enter a password to give to user when no email is given"
      );
      return;
    }
    if (password === "" && !this.props.nopwd) {
      if (
        !window.confirm(
          "No password supplied. One will be generated and sent user."
        )
      ) {
        document.getElementById("password").focus();
        return;
      }
      password = null;
    }
    if (user_type === "") {
      displayError("Please select user type");
      document.getElementById("user_type").focus();
      return;
    }

    let _method = id ? "PUT" : "POST";

    $.ajax({
      method: _method,
      url: baseUrl + "/account/users/" + (id || ""),
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        email: email,
        password: password,
        user_type: user_type,
        for_account: "true",
        phone_number: phone,
        address: address,
        first_name: first_name,
        last_name: last_name,
        influence: "afrijula",
        location: location,
        profile_id: this.state.profileId,
      },

      success: function(result) {
        if (id !== "") {
          displaySuccess("user successfully updated.");
        } else {
          displaySuccess("user successfully created.");
        }
        if (_this.props.saveCback) _this.props.saveCback.call();
        else _this.cancelAdding();
      }.bind(this),
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };
  locationsOptions = (name) => {
    const url =
      baseUrl +
      '/afrijula/locations/location?attrs=["name"]&model=Afrijula::Locations::Location&unique=name&match=' +
      name;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;

        for (; i < json.length; i++) {
          opts[i] = { label: json[i], value: json[i] };
        }
        return opts;
      })
      .catch((err) => {});
  };

  cancelAdding = () => {
    this.setState({ back: true });
  };

  mapToUserType = (type) => {
    let t = null;
    switch (type) {
      case "Cashier":
        t = "cashier";
        break;
      case "Accountant":
        t = "accountant";
        break;
      case "Admin":
        t = "basic_admin";
        break;
      case "StoreManager":
        t = "store_manager";
        break;
      case "AccountAdmin":
        t = "admin";
        break;
    }
    return t;
  };

  fillForm = () => {
    const types = this.state.userData.type.split("::");
    this.setState({
      first_name: this.state.userData.first_name,
      last_name: this.state.userData.last_name,
      email: this.state.userData.email,
      user_type: this.mapToUserType(types[types.length - 1]),
      phone: this.state.userData.phone_number,
      address: this.state.userData.address,
      location: this.state.userData.location,
    });
  };

  componentDidMount() {
    if (this.state.userData) {
      this.fillForm();
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.back && this.state.callerName) {
      let ChildComponent = this.state.callerName;
      let _state = this.state.callerState;
      if (this.props.userData) {
        _state.editUserDetails = null;
      } else {
        _state.add_user = false;
      }
      return (
        <ChildComponent
          state={this.state.callerState}
          returningProps={this.state.callerProps}
        />
      );
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={9} />
                  <GridItem xs={3}>
                    <Button
                      color="danger"
                      style={{ float: "right" }}
                      onClick={this.cancelAdding}
                    >
                      {getString().back}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <h4 className={classes.cardTitleWhite}>
                  {getString().add_user}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      autoFocus
                      id="first_name"
                      label={getString().first_name}
                      name="first_name"
                      className={classes.textField}
                      value={this.state.first_name}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      autoFocus
                      id="last_name"
                      label={getString().last_name}
                      name="last_name"
                      className={classes.textField}
                      value={this.state.last_name}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="address"
                      label={getString().address}
                      name="address"
                      className={classes.textField}
                      value={this.state.address}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      id="email"
                      label={getString().email}
                      name="email"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      type="email"
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="email">
                      {getString().telephone}
                    </InputLabel>
                    <Input
                      id="phone"
                      name="phone"
                      inputComponent={TextMaskCustom}
                      className={classes.textField}
                      value={this.state.phone}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <label>{getString().location}</label>
                    <AsyncSelect
                      loadOptions={this.locationsOptions}
                      defaultOptions
                      placeholder={getString().location}
                      name="location"
                      id="location"
                      value={{
                        label: this.state.location,
                        value: this.state.location,
                      }}
                      onChange={(opt) => {
                        this.setState({ location: opt.label });
                      }}
                    />
                  </GridItem>
                  {this.props.nopwd ? (
                    <div />
                  ) : (
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="password"
                        label={getString().password}
                        name="password"
                        className={classes.textField}
                        value={this.state.password}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        type="password"
                        margin="normal"
                        variant="outlined"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="user_type"
                      name="user_type"
                      select
                      label={getString().user_type}
                      className={classes.textField}
                      value={this.state.user_type}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      helperText={getString().select_user_type}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.user_types.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={this.saveProfile}>
                  {getString().save_settings}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Add_User.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Add_User);
