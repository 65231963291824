import React from "react";

import AfriLogo from "assets/img/newAfri.png";
import Dash from "assets/img/dashboard1.png";

import "assets/css/landing-page.css";
import Contact from "../AddForm/Contact";
import LoginPage from "components/afrijula/Login/LogIn.jsx";
import SlideShow from "components/afrijula/SlideShow/SlideShow.jsx";
import ChangeLocale from "components/common/Locale/LocaleSelector.jsx";
import { Link } from "react-router-dom";
import { getLocaleString, getString } from "assets/js/utils/utils.js";


const iconStyle = {
  fontSize: 25,
  fontWeight: "bold",
  color: "#d82027",
}

export default class Landing extends React.Component {
  state = {
    loginpage: false,
    more: false,
    selectLocale: false,
    contact: false,
    kairaba: true,
  };

  onChangeLocale = (opt) => {
    this.setState({ selectLocale: true });
  };

  close = () => {
    this.setState({ selectLocale: false });
  };

  switchMap = (val) => this.setState({ kairaba: val });

  componentDidMount() {}

  contact = () => {
    this.setState({ contact: true });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  render() {
    if (this.state.loginpage) {
      return <LoginPage />;
    } else {
      if (this.state.contact) {
        return (
          <Contact
            callerProps={this.getProps()}
            callerState={this.state}
            callerName={Landing}
          />
        );
      }
      let locationMap;
      if (this.state.kairaba) {
        locationMap = (
          <iframe
            className="location-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3880.2812424212552!2d-16.685886149159767!3d13.456753090492418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec29a36c8258bfb%3A0xa5c9b2438a9f9d00!2sInSIST%20Global!5e0!3m2!1sen!2sgm!4v1605696303290!5m2!1sen!2sgm"
          />
        );
      } else {
        locationMap = (
          <iframe
            className="location-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3881.030670596353!2d-16.734676049160274!3d13.410427990522702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDI0JzM3LjUiTiAxNsKwNDMnNTYuOSJX!5e0!3m2!1sen!2sgm!4v1606404463423!5m2!1sen!2sgm"
          />
        );
      }
      return (
        <div className="landing-page landing-page2 white herizontal">
          <nav className="navbar  navbar-top" role="navigation">
            <div className="container">
              <div className="navbar-header">
                <div id="my-content">
                  <ul
                    className="fine"
                    style={{ float: "right", marginTop: 24, marginRight: 15 }}
                  >
                    <a
                      onClick={(e) => this.onChangeLocale(e)}
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "#d82027",
                      }}
                    >
                      <i className="fa fa-language" />
                      {getLocaleString()}
                    </a>
                    <div style={{ marginTop: "20px", marginBottom: "30px" }}>
                      <a target="_blank" href="https://covid.afrijula.gm">
                        <span
                          style={{
                            borderRadius: 15,
                            color: "#d82027",
                            padding: "10px",
                          }}
                          className="card"
                        >
                          <b>COVID TRACKER</b>
                        </span>
                      </a>
                    </div>
                    <a target="_blank" href="https://afrijula.shop/">
                      <span
                        style={{
                          borderRadius: 15,
                          color: "#d82027",
                          padding: "10px",
                        }}
                        className="card"
                      >
                        <b>{getString().visit}</b>
                      </span>
                    </a>
                  </ul>
                </div>
                <a href="https://www.afrijula.gm">
                  <div className="logo-container">
                    <div className="logo">
                      <img src={AfriLogo} alt="AfriJula" />
                    </div>
                  </div>
                </a>
              </div>

              <div className="collapse navbar-collapse" id="example">
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <a
                      href="tel:7111219"
                      onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                    >
                      <p
                        style={{
                          marginRight: 25,
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#d82027",
                        }}
                        className="call-button"
                      >
                        <i
                          style={{
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "#d82027",
                          }}
                          className="fa fa-phone"
                        />{" "}
                        (+220) 711 1229
                      </p>
                    </a>
                  </li>

                  <li style={{ marginLeft: "-7px" }}>
                    <a
                      className="iconFriends"
                      target="_blank"
                      href="https://www.facebook.com/Afrijula/"
                      style={iconStyle}
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li style={{ marginLeft: "-7px" }}>
                    <a
                      className="iconFriends"
                      target="_blank"
                      href="https://www.instagram.com/afrijula1/"
                      style={iconStyle}
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li style={{ marginLeft: "-7px" }}>
                    <a
                      target="_blank"
                      className="iconFriends"
                      style={iconStyle}
                      href="https://twitter.com/Afrijula1"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li style={{ marginLeft: "-7px" }}>
                    <a
                      className="iconFriends"
                      target="_blank"
                      href="https://www.linkedin.com/company/afrijula/?viewAsMember=true"
                      style={iconStyle}
                    >
                       <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li style={{ marginLeft: "-7px", marginRight: "10px" }}>
                    <a
                      className="iconFriends"
                      target="_blank"
                      href="https://youtube.com/channel/UCQVUb46m-1r8EqVJ5SkDHyw"
                      style={iconStyle}
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.insist_global.afrijulaflutterapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                      <img
                        className="myPlayButtons"
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </li>
                </ul>
                <div className="buttons" id="example">
                  <ul className=" navbar-nav navbar-right">
                    <div className="buttons">
                      <a
                        onClick={(e) => this.onChangeLocale(e)}
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                          color: "#d82027",
                        }}
                      >
                        <i className="fa fa-language" />
                        {getLocaleString()}
                      </a>
                      <a target="_blank" href="https://covid.afrijula.gm">
                        <span
                          style={{
                            borderRadius: 15,
                            color: "#d82027",
                            padding: "10px",
                            marginLeft: "15px",
                          }}
                          className="card"
                        >
                          <b>COVID TRACKER</b>
                        </span>
                      </a>
                      <a
                        style={{ marginLeft: "8px" }}
                        target="_blank"
                        href="https://afrijula.shop/"
                      >
                        <span
                          style={{
                            borderRadius: 15,
                            color: "#d82027",
                            padding: "10px",
                          }}
                          className="card"
                        >
                          <b>{getString().visit}</b>
                        </span>
                      </a>
                      <Link
                        style={{ marginLeft: "15px" }}
                        to={{ pathname: "/register", state: {} }}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            borderRadius: 8,
                            backgroundColor: "#d82027",
                            color: "#fff",
                            marginRight: 10,
                            padding: 10,
                          }}
                        >
                          <b>{getString().reg}</b>
                        </span>
                      </Link>
                      <Link
                        style={{ marginLeft: "5px" }}
                        to={{ pathname: "/login", state: {} }}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            borderRadius: 8,
                            backgroundColor: "#9ccb3b",
                            color: "#fff",
                            marginRight: 10,
                            padding: 10,
                          }}
                        >
                          <b>{getString().login}</b>
                        </span>
                      </Link>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div className="wrapper">
            <div className="parallax filter-gradient " data-color="blue">
              <div className="banner ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="parallax-image">
                      <SlideShow />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="my-content">
              <Link
                style={{ marginLeft: "15px" }}
                to={{ pathname: "/register", state: {} }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    borderRadius: 8,
                    backgroundColor: "#d82027",
                    color: "#fff",
                    marginRight: 10,
                    padding: 10,
                  }}
                >
                  <b>{getString().reg}</b>
                </span>
              </Link>
              <Link
                style={{ marginLeft: "5px" }}
                to={{ pathname: "/login", state: {} }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    borderRadius: 8,
                    backgroundColor: "#9ccb3b",
                    color: "#fff",
                    marginRight: 10,
                    padding: 10,
                  }}
                >
                  <b>{getString().login}</b>
                </span>
              </Link>
            </div>

            <div className="section section-features">
              <div style={{ textAlign: "centre" }} className="container">
                <h4
                  style={{ fontSize: "30px" }}
                  className="header-text text-center"
                >
                  <b>{getString().key_features}</b>
                </h4>
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <div
                        style={{ display: "inlineBlock", height: "20px" }}
                        className="text card"
                      >
                        <h3
                          style={{
                            background: "#d82027",
                            padding: 12,
                            color: "#fff",
                          }}
                        >
                          {getString().record_finances}
                        </h3>
                        <p style={{ fontFamily: "Open Sans" }}>
                          Keep track of all your sales, manage your invoices and
                          all your stock. Powerful reports and analysis help you
                          stay on top of your business from anywhere
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" card">
                      <div className="icon">
                        <i className="pe-7s-info" />
                      </div>
                      <h3
                        style={{
                          background: "#d82027",
                          padding: 12,
                          color: "#fff",
                        }}
                      >
                        {getString().sell_online}
                      </h3>
                      <p style={{ fontFamily: "Open Sans" }}>
                        Activating the E-Shop module allows our users to upload
                        their products online and sell on the Afrijula Shop,
                        having the opportunity to reach a wider audience
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="icon">
                        <i className="pe-7s-home" />
                      </div>
                      <h3
                        style={{
                          background: "#d82027",
                          padding: 12,
                          color: "#fff",
                        }}
                      >
                        {getString().apply_for_loans}
                      </h3>
                      <p style={{ fontFamily: "Open Sans" }}>
                        Become an active Afrijula user and we will guide you
                        through each step of the loan application process,
                        provided by our Financial Service Providing partners
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="text" style={{ backgroundColor: "#fbfbfb", right: 0 }}>
              <div
                style={{ backgroundColor: "#fbfbfb", right: 0 }}
                className="section container"
              >
                <div style={{ marginLeft: 10, float: "left" }}>
                  <img width="90%" src={Dash} />
                </div>
                <div
                  className="margins"
                  style={{ textAlign: "center", fontSize: "21pt" }}
                >
                  <b style={{ color: "#424242" }}>
                    The Afrijula Basic Plan is available for FREE to all users
                    for an unlimited period!
                  </b>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#424242",
                      fontSize: "12pt",
                      fontFamily: "CALIBRE",
                    }}
                  >
                    For additional features, users can choose the different
                    options and pay for what they need
                  </p>
                </div>
              </div>
            </div>

            <div id="texts" style={{ backgroundColor: "#fbfbfb", right: 0 }}>
              <div
                style={{ backgroundColor: "#fbfbfb", right: 0 }}
                className="section section-features container"
              >
                <div style={{ marginLeft: 10 }}>
                  <img width="90%" src={Dash} />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    textAlign: "center",
                    fontSize: "21pt",
                  }}
                >
                  <b style={{ color: "#424242" }}>
                    The Afrijula Basic Plan is available for FREE to all users
                    for an unlimited period!
                  </b>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#424242",
                      fontSize: "12pt",
                      fontFamily: "CALIBRE",
                    }}
                  >
                    For additional features, users can choose the different
                    options and pay for what they need
                  </p>
                </div>
              </div>
            </div>

            <div className="container" id="contactForm">
              <Contact />
            </div>
            <footer className="footer fit">
              <div className="contact">
                <div className="row">
                  <div className="col-md-6">
                    <div className="description">
                      <div className="buttons bottom">
                        <p
                          className="our-locations container"
                          style={{
                            color: "#fff",
                            marginBottom: 25,
                            fontWeight: "bold",
                            fontSize: 30,
                          }}
                        >
                          {getString().our_location}
                        </p>
                        <a
                          className="spacing"
                          onClick={() => this.switchMap(true)}
                          style={{
                            cursor: "pointer",
                            borderRadius: 25,
                            backgroundColor: this.state.kairaba
                              ? "#ccc"
                              : "#fff",
                            color: "#000",
                            marginRight: 10,
                            padding: 10,
                          }}
                        >
                          {getString().kairaba}
                        </a>
                        <a
                          className="spacing"
                          onClick={() => this.switchMap(false)}
                          style={{
                            cursor: "pointer",
                            borderRadius: 25,
                            backgroundColor: !this.state.kairaba
                              ? "#ccc"
                              : "#fff",
                            color: "#000",
                            marginRight: 10,
                            padding: 10,
                          }}
                        >
                          {getString().bijilo}
                        </a>
                      </div>
                    </div>
                    {this.state.kairaba ? (
                      <p className="contact1 container">
                        46A KAIRABA AVENUE, THE GAMBIA
                        <br />
                        Support:{" "}
                        <a style={{ color: "white" }} href="tel:+2207111229">
                          {" "}
                          (+220) 711 1229
                        </a>
                        <br />
                        Email:{" "}
                        <a
                          style={{ color: "white" }}
                          href="mailto:support@afrijula.gm"
                        >
                          info@afrijula.gm
                        </a>
                        <br />
                        InSIST Global
                      </p>
                    ) : (
                      <p className="contact1 container">
                        Bertil Harding Highway, Bijilo | Alvihag Building, 2nd
                        Floor
                        <br />
                        Support:{" "}
                        <a style={{ color: "white" }} href="tel:+2207111229">
                          {" "}
                          (+220) 711 1229
                        </a>
                        <br />
                        Email:{" "}
                        <a
                          style={{ color: "white" }}
                          href="mailto:support@afrijula.gm"
                        >
                          support@afrijula.gm
                        </a>
                        <br />
                        InSIST Global
                      </p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="location-map">{locationMap}</div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div
                  style={{ marginTop: "28px" }}
                  className="social-area pull-left"
                >
                  <ul>
                    <span style={{ color: "#000", display: "inlineBlock" }}>
                      <b>CONNECT WITH US</b>
                    </span>
                    <span style={{ padding: "5px" }}>
                      <a
                        className="iconFriends"
                        target="_blank"
                        href="https://www.facebook.com/Afrijula/"
                        style={iconStyle}
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </span>
                    <span style={{ paddingRight: "10px" }}>
                      <a
                        className="iconFriends"
                        target="_blank"
                        href="https://www.instagram.com/afrijula1/"
                        style={iconStyle}
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </span>
                    <span style={{ paddingRight: "10px" }}>
                      <a
                        target="_blank"
                        className="iconFriends"
                        style={iconStyle}
                        href="https://twitter.com/Afrijula1"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </span>
                    <span style={{ paddingRight: "10px" }}>
                      <a
                        className="iconFriends"
                        target="_blank"
                        href="https://www.linkedin.com/company/afrijula/?viewAsMember=true"
                        style={iconStyle}
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </span>
                    <span>
                      {" "}
                      <a
                        className="iconFriends"
                        target="_blank"
                        href="https://youtube.com/channel/UCQVUb46m-1r8EqVJ5SkDHyw"
                        style={iconStyle}
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </span>
                  </ul>
                </div>

                <div className="social-area pull-right pull-rights">
                  <div className="buttons">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.insist_global.afrijulaflutterapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                      <img
                        className="myPlayButton"
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </div>
                </div>

                <div
                  style={{ marginTop: "40px" }}
                  className="copyright pull-lefts"
                >
                  &copy; 2022{" "}
                  <a href="https://insistglobal.com/">InSIST GLOBAL</a>
                </div>
              </div>
            </footer>
          </div>

          <ChangeLocale
            selectLocale={this.state.selectLocale}
            close={this.close.bind(this)}
          />
        </div>
      );
    }
  }
}
