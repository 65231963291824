import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import ProductTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import ProductDetails from "components/afrijula/AllDetails/ProductDetails.jsx";
import NewProduct from "components/afrijula/AddForm/AddProduct.jsx";
import {
  showReportUtility,
  currencyFormat,
  dateFormat,
  compileQuery,
  applyPackaging,
  getString,
} from "assets/js/utils/utils.js";
import AddMenu from "components/afrijula/Products/Menu.jsx";

import $ from "jquery";

import { completedTasksChart } from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import WindowSizeListener from "react-window-size-listener";

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    stockdetails: false,
    add: false,
    productData: null,
    winSize: window.innerWidth,

    data: [],
    header: [
      {
        title: getString()._name,
        field: "name",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().category,
        field: "category",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().unit_price,
        field: "unit_price",
        options: { filter: false },
        render: (rowData) => currencyFormat(rowData.unit_price),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().discontinued,
        field: "discontinue",
        render: (rowData) => {
          return rowData.discontinue ? "yes" : "no";
        },
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],

    poorItemHeader: [
      {
        title: getString()._name,
        field: "name",
        cellStyle: { padding: "4px 14px" },
        headerStyle: { padding: "4px 14px" },
      },
      {
        title: getString().quantity,
        field: "quantity",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],
    lastPage: null,
    lastSize: null,
    recalledPage: null,
    recalledPageSize: null,
    listData: {
      poorItems: [],
    },
    chartData: {
      productSales: completedTasksChart,
    },
    testing: null,
    addProduct: false,
    addMenu: false,
  };

  addProduct = () => {
    const props = this.getProps();

    if (props.updateMe) props.updateMe(false);
    this.setState({ addProduct: !this.state.addProduct });
  };

  addMenu = () => {
    const props = this.getProps();
    if (props.updateMe) props.updateMe(false);
    this.setState({ addMenu: true });
  };

  loadDashData = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: {
        count: [],
        chart: [{ product_sales: ["month", "day"] }],
        list: [{ poor_products: [5] }],
      },
      success: function(data) {
        let chartData = _this.state.chartData;
        let listData = _this.state.listData;

        chartData.productSales.data = data.charts.product_sales;
        listData.poorItems = data.lists.poor_products;

        _this.setState({ listData: listData, chartData: chartData });
      }.bind(this),
    });
  };

  loadProduct = (id) => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/products/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        const props = _this.getProps();
        if (props.updateMe) props.updateMe(false);
        _this.setState({ productData: result });
      },
    });
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    this.loadProduct(id);
  };

  loadData = (query, resolve, reject) => {
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/sales/products",
      ["id", "name", "category", "unit_price", "discontinue"],
      [{ by: "asc", attr: "name" }, { by: "asc", attr: "discontinue" }],
      "Afrijula::Sales::Product",
      null,
      null
    );
    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;
    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  componentDidMount() {
    const props = this.getProps();
    this.loadDashData();
    if (props.updateMe) props.updateMe(true);
  }

  onSearchChange = (searchText) => {
    this.tableChangeQuery(baseUrl, searchText);
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.productData) {
      let data = this.state.productData;
      this.state.productData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <ProductDetails
          callerProps={this.getProps()}
          callerState={this.state}
          productData={data}
          callerName={Product}
        />
      );
    } else if (this.state.addProduct) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <NewProduct
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Product}
          editProduct={false}
          saveProductCback={this.loadProduct.bind(this)}
        />
      );
    } else if (this.state.addMenu) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <AddMenu
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Product}
        />
      );
    } else {
      return (
        <WindowSizeListener
          onResize={(size) => {
            this.setState({ winSize: size.windowWidth });
          }}
        >
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="primary"
                  onClick={this.addProduct}
                  style={{
                    backgroundColor: "#9c27b0",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">add</i>
                  {getString().add_product}
                </Button>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="info"
                  onClick={this.addMenu.bind(this)}
                  style={{
                    backgroundColor: "#00acc1",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">book</i>
                  {getString().menu}
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="danger">
                    <h4 className={classes.cardTitleWhite}>
                      {getString().poor_items}
                    </h4>
                  </CardHeader>
                  <Table
                    columns={this.state.poorItemHeader}
                    data={this.state.listData.poorItems}
                    title={getString().poor_items}
                    options={{
                      columnsButton: true,
                      sorting: true,
                      toolbar: false,
                      paging: false,
                    }}
                  />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card chart>
                  <CardHeader color="success">
                    <ChartistGraph
                      className="ct-chart"
                      data={this.state.chartData.productSales.data}
                      type="Line"
                      options={() => {
                        let opts = this.state.chartData.productSales.options;
                        opts.high = this.state.chartData.productSales.data.max;
                        return opts;
                      }}
                      listener={this.state.chartData.productSales.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <div className={classes.stats} style={{ float: "right" }}>
                      <span
                        onClick={() => {
                          let end_date = new Date();
                          let start_date = new Date(
                            end_date.getYear(),
                            end_date.getMonth() - 1,
                            end_date.getDate()
                          );
                          let params = {
                            organization_id: cookies.load("orgId"),
                            org_name: cookies.load("orgName"),
                            start_date: start_date,
                            end_date: end_date,
                          };
                          let inject = {
                            images: [
                              {
                                name: "image",
                                attr: "image",
                                id: cookies.load("orgId"),
                                model: "Organization",
                              },
                            ],
                          };
                          showReportUtility(
                            "Best Selling Products",
                            baseUrl +
                              "/reports/1?url=/afrijula/*&profile=" +
                              cookies.load("profileId"),
                            {
                              params: params,
                              sub: "&report=best_selling_products&dynamic=true",
                              inject: inject,
                            },
                            cookies,
                            [
                              {
                                type: "date",
                                default: start_date,
                                label: "From",
                                name: "start_date",
                              },
                              {
                                type: "date",
                                default: end_date,
                                label: "To",
                                name: "end_date",
                              },
                            ]
                          );
                        }}
                        style={{ cursor: "pointer", color: "#800080" }}
                      >
                        {getString().details}
                      </span>
                    </div>
                    <h4 className={classes.cardTitle}>
                      {getString().popular_items}
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ProductTable
                  tableRef={this.tableRef}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let page = this.recallPage();
                      if (page) {
                        query.page = page;
                      }

                      this.loadData(query, resolve, reject);
                    })
                  }
                  columns={this.state.header}
                  onRowClick={this.rowSelect}
                  options={{
                    exportButton: true,
                    filtering: true,
                    grouping: true,
                    sorting: true,
                    selection: true,
                    showTitle: false,
                    pageSize: this.state.recalledPageSize || 15,
                  }}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh",
                      isFreeAction: true,
                      onClick: () =>
                        this.tableRef.current &&
                        this.tableRef.current.onQueryChange(),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </WindowSizeListener>
      );
    }
  }
}

Product.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(dashboardStyle)(Product);
