import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";

class Bill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: '1'
        };
      }

    render() {
        return (<FormControl component="fieldset">
            <RadioGroup
              row={true} 
              aria-label="bill" 
              name="bill"
              className="genre-wrapper"
              value={this.props.value}
              onChange={this.props.onChangeValue}
            >
            { (this.props.charge === 'month' || this.props.charge === 'quarter' || this.props.charge === 'year')? (<FormControlLabel disabled value="week" control={<Radio />} label={getString().weekly} />):
            (<FormControlLabel value="week" control={<Radio />} label={getString().weekly} />)}
            { (this.props.charge === 'quarter' || this.props.charge === 'year')? (<FormControlLabel disabled value="month" control={<Radio />} label={getString().monthly} />):
            (<FormControlLabel value="month" control={<Radio />} label={getString().monthly} />)}
            { (this.props.charge === 'year')? (<FormControlLabel value="quarter"  disabled control={<Radio />} label={getString().quarterly} />):
            (<FormControlLabel value="quarter" control={<Radio />} label={getString().quarterly} />)}
            <FormControlLabel value="year" control={<Radio />} label={getString().annually} />
            </RadioGroup>
          </FormControl>);
    }
}

export default Bill
