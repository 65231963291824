import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

import AfrijulaLogo from "assets/img/AfrijulaLogo-transparent.png";

const getLink = (menu, classes, key, listItemClasses, whiteFontClasses) => {
  return (
    <NavLink
      to={menu.path}
      className={classes.item}
      activeClassName="active"
      key={key}
    >
      <ListItem className={classes.itemLink + listItemClasses}>
        <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
          {typeof menu.icon === "string" ? (
            <Icon>{menu.icon}</Icon>
          ) : (
            <menu.icon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={menu.sidebarName}
          className={classes.itemText + whiteFontClasses}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  );
};

const Sidebar = ({ ...props }) => {
  const [open, setCollapse] = useState(false);

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  const { classes, color, logo, image, logoText, routes } = props;
  var links = (
    <List className={`${classes.list}`}>
      {routes.map((menu, key) => {
        if (menu.redirect) return null;
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(menu.path),
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(menu.path),
        });
        return menu.children ? (
          <ListItem className={classes.itemLink + listItemClasses}>
            <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
              {typeof menu.icon === "string" ? (
                <Icon>{menu.icon}</Icon>
              ) : (
                <menu.icon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={menu.sidebarName}
              className={classes.itemText + whiteFontClasses}
              disableTypography={true}
            />
            {menu.children.map((subMenu, subKey) => {
              {
                return getLink(
                  subMenu,
                  classes,
                  subKey,
                  listItemClasses,
                  whiteFontClasses
                );
              }
            })}
          </ListItem>
        ) : (
          getLink(menu, classes, key, listItemClasses, whiteFontClasses)
        );
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <Link to="/dashboard" className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={AfrijulaLogo} alt={logoText} className={classes.img} />
        </div>
      </Link>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}

          {/* side bar for mobile view */}
          <div
            className={classes.sidebarWrapper}
            style={{
              overflowY: "scroll",
              maxHeight: "77vh",
              paddingBottom: "10px",
              marginBottom: "15px",
            }}
          >
            {links}
          </div>
          {/* side bar for mobile view */}

          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          {/* side bar for full screen */}
          <div className={classes.sidebarWrapper}>{links}</div>
          {/* side bar for full screen */}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(sidebarStyle)(Sidebar);
