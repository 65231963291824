import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import PropTypes from "prop-types";
import ChartistGraph from "react-chartist";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import ExpenseTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {
  compileQuery,
  Country,
  currencies,
  currencyFormat,
  dateFormat,
  displayError,
  displaySuccess,
  execPrint,
  getString,
  getToday,
  showReportUtility,
} from "assets/js/utils/utils.js";
import Select, {   } from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from "react-select/async";

import $ from "jquery";
import { completedTasksChart } from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import BankAccount from "components/afrijula/AddForm/BankAccount.jsx";
import { currencyValue } from "../../../assets/js/utils/utils";
import HelpUtil from "../Utils/HelpUtil";
import "./rodal.css";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },

  table: {
    minWidth: 700,
  },
};

class ExpenseManager extends React.Component {
  state = this.props.state || {
    data: [],
    header: [
      {
        title: getString().date,
        field: "date",
        render: (rowData) => dateFormat(rowData.date),
      },
      { title: getString().category, field: "category", defaultGroupOrder: 0 },
      { title: getString().location, field: "location" },
      { title: getString().purpose, field: "purpose" },
      {
        title: getString().amount,
        field: "amount",
        render: (rowData) => currencyFormat(rowData.amount),
      },
    ],

    chartData: {
      expenses: completedTasksChart,
    },

    purposeOptions: [
      {
        label: getString().asset,
        value: "asset",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        label: getString().expense,
        value: "expense",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        label: getString().payment,
        value: "payment",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        label: getString().supply,
        value: "supply",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],

    expense_methods: [
      {
        label: getString().bank,
        value: "bank",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        label: getString().cash,
        value: "cash",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        label: getString().cheque,
        value: "cheque",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],

    countryName: null,
    categories: [],
    locations: [],
    amount: 0.0,
    date: "",
    bank_name: "",
    cheque_number: "",
    category: {},
    location: {},
    voucher_number: null,
    username: "",
    summary: "",
    purpose: {},
    exchange_rate: 1,
    check_number: null,
    bank: {},
    expenseData: {},
    expenseId: "",
    Currency: {
      currency: "GMD",
    },
    addAccount: false,
    newAccountName: "",
    banks: [],
    cashbookActivated: false,
    deletedExpense: null,
    method: {},
    lastSize: null,
    recalledPageSize: null,
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  loadDashData = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { count: [], chart: [{ expense: ["month", "week"] }] },
      success: function(data) {
        let chartData = _this.state.chartData;
        let listData = _this.state.listData;

        chartData.expenses.data = data.charts.expense;

        _this.setState({ listData: listData, chartData: chartData });
      }.bind(this),
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/expenses/expense/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ expenseData: result, expenseId: id });
      },
    });
  };

  loadData = (query, resolve, reject) => {
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/expenses/expense",
      [
        "_id",
        "name",
        "category",
        "location",
        "purpose",
        "reference_number",
        "check_number",
        "bank",
        "currency",
        "reconcile",
        "exchange_rate",
        "amount",
        "date",
        "expense_method",
      ],
      [{ by: "asc", attr: "date" }],
      "Afrijula::Expenses::Expense",
      null,
      null,
      null
    );

    this.state.lastSize = query.pageSize;

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  locationsOptions = (name) => {
    const url =
      baseUrl +
      '/afrijula/locations/location?attrs=["name"]&model=Afrijula::Locations::Location&unique=name&match=' +
      name;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;

        for (; i < json.length; i++) {
          opts[i] = { label: json[i], value: json[i] };
        }
        let val = opts[0];
        this.setState({ location: val });
        return opts;
      })
      .catch((err) => {});
  };

  loadBankNames = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/expenses/expense?attrs=["bank"]&model=Afrijula::Expenses::Expense&unique=bank&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let opts = [];
        let i = 0;
        for (; i < data.length; i++) {
          opts[i] = { label: data[i], value: data[i] };
        }
        this.setState({
          bank: opts[0],
        });
        return opts;
      })
      .catch((err) => {
        displayError(err.responseText);
      });
  };

  loadBankAccounts = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/cashbook/bank_account?attrs=["id","name","number","bank","currency","balance","local_balance","status"]&model=Afrijula::Cashbook::BankAccount&to=name&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let opts = [];
        $.map(data, function(data) {
          opts.push({
            label: data.name,
            value: data._id.$oid,
            status: data.status,
            local_balance: data.local_balance,
            number: data.number,
            bank: data.bank,
            currency: data.currency,
            balance: data.balance,
          });
        });
        this.setState({
          bank: opts[0],
        });
        return opts;
      })
      .catch((err) => {
        displayError(err.responseText);
      });
  };

  bankOptions = (inputValue) => {
    return this.state.cashbookActivated === "true"
      ? this.loadBankAccounts(inputValue)
      : this.loadBankNames(inputValue);
  };

  categoryOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/expenses/expense?attrs=["category"]&model=Afrijula::Expenses::Expense&unique=category&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;
        for (; i < json.length; i++) {
          opts[i] = { label: json[i], value: json[i] };
        }
        this.setState({ category: opts[0] });
        return opts;
      })
      .catch((err) => {});
  };

  handleCurrency = (value) => {
    let curr = Country.search(value["value"]);
    if (curr && curr[0]) {
      let _currency = this.state.Currency;
      _currency.currency = curr[0].currency.currencyCode;
      let _name = curr[0].name + "(" + _currency.currency + ")";
      this.setState({
        Currency: _currency,
        countryName: _name,
      });
    }
  };

  handleSelectValue = (opt, attr) => {
    let val = { value: opt.value, label: opt.label };
    this.setState({ [attr]: val });
  };

  getCurrency = () => {
    let defaultCurrency = cookies.load("defaultCurrency");

    let _currency = this.state.Currency;
    _currency.currency = defaultCurrency;
    let curr = Country.search(defaultCurrency);
    let _countryName = curr[0].name + " (" + defaultCurrency + ")";
    this.setState({
      Currency: _currency,
      countryName: _countryName,
    });
  };

  selectedAccount = (opt, type) => {
    if (opt.status === "blocked") {
      displayError(getString().disabled_account_trans);
      return;
    }
    let val =
      this.state.cashbookActivated === "true"
        ? opt
        : type === "new"
          ? {
              value: opt,
              label: opt,
            }
          : { value: opt.value, label: opt.label };
    this.setState({
      bank: val,
    });
  };

  addAccountView = (opt) => {
    this.state.cashbookActivated === "true"
      ? this.setState({
          newAccountName: opt,
          addAccount: true,
        })
      : this.selectedAccount(opt, "new");
  };

  updateSelectField = (account) => {
    let _bank = {
      label: account.name,
      value: account._id.$oid,
      status: account.status,
      local_balance: account.local_balance,
      number: account.number,
      bank: account.bank,
      currency: account.currency,
      balance: account.balance,
    };
    this.setState({
      bank: _bank,
    });
  };

  renderExpenseForm = () => {
    const { classes } = this.props;
    let exp_method = this.state.method.value;
    return (
      <div>
        <CardBody xs={12} sm={12} md={12}>
          <form className={classes.container} autoComplete="on">
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <span>{getString().category}</span>
                <AsyncCreatableSelect
                  defaultOptions
                  loadOptions={this.categoryOptions}
                  name="category"
                  id="category"
                  value={this.state.category}
                  onChange={(opt, evt) => {
                    this.handleSelectValue(opt, "category");
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <span>{getString().purpose}</span>
                <Select
                  options={this.state.purposeOptions}
                  value={this.state.purpose}
                  name="purpose"
                  id="purpose"
                  onChange={(opt, evt) => {
                    this.handleSelectValue(opt, "purpose");
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} style={{ marginTop: "-15px" }}>
                <TextField
                  required
                  id="amount"
                  name="amount"
                  inputProps={{
                    style: { padding: "3px", height: "2em", minWidth: "100%" },
                  }}
                  value={this.state.amount}
                  label={getString().amount}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <span>{getString().location}</span>
                <AsyncSelect
                  loadOptions={this.locationsOptions}
                  value={this.state.location}
                  defaultOptions
                  name="location"
                  id="location"
                  onChange={(opt, evt) => {
                    this.handleSelectValue(opt, "location");
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <span>{getString().currency}</span>
                <Select
                  options={currencies}
                  placeholder={this.state.countryName}
                  name="currency"
                  id="currency"
                  value={this.state.Currency.currency}
                  onChange={this.handleCurrency}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} style={{ marginTop: "-15px" }}>
                <TextField
                  required
                  id="date"
                  name="date"
                  inputProps={{ style: { padding: "2px", height: "2em" } }}
                  value={this.state.date}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  type="date"
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <span>
                  {getString().expense_method}
                  <HelpUtil reference={"purchase_expense_methods"} />
                </span>
                <Select
                  options={this.state.expense_methods}
                  value={this.state.method}
                  name="method"
                  id="method"
                  onChange={(opt, evt) => {
                    this.handleSelectValue(opt, "method");
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <span>
                  {this.state.cashbookActivated === "true"
                    ? getString().account
                    : getString().bank}
                </span>
                <AsyncCreatableSelect
                  defaultOptions
                  loadOptions={this.bankOptions}
                  name="bank"
                  id="bank"
                  placeholder={getString().bank}
                  value={this.state.bank}
                  onChange={(opt, evt) => {
                    this.selectedAccount(opt);
                  }}
                  onCreateOption={(e) => [this.addAccountView(e)]}
                />
              </GridItem>
              {exp_method === "cash" ? null : (
                <GridItem xs={12} sm={4} md={4}>
                  <TextField
                    daisabled
                    id="check_number"
                    name="check_number"
                    label={
                      exp_method === "bank"
                        ? getString().refNo
                        : getString().cheque_number
                    }
                    value={this.state.check_number}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    inputProps={{ style: { padding: "2px", height: "2em" } }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridContainer>
              {exp_method === "cash" || exp_method === "cheque" ? null : (
                <GridItem xs={12} sm={4} md={4}>
                  <TextField
                    disabled
                    id="exchange_rate"
                    name="exchange_rate"
                    label={getString().exchange_rate}
                    inputProps={{ style: { padding: "2px", height: "2em" } }}
                    value={this.state.exchange_rate}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={8}>
                <TextField
                  id="summary"
                  name="summary"
                  label={getString().summary}
                  multiline
                  rows="4"
                  stype={{ width: "100%" }}
                  value={this.state.summary}
                  className={classes.textField}
                  inputProps={{
                    style: { padding: "2px", height: "1em", fullWidth: true },
                  }}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </div>
    );
  };

  printExpense = () => {
    execPrint(document.getElementById("printableView"));
    this.clearForm();
  };

  saveExpense = () => {
    let date = this.state.date;
    let purpose = this.state.purpose.value;
    let expense_method = this.state.method.value;
    let amount = this.state.amount;
    let location = this.state.location.label;
    let category = this.state.category.label;
    let check_number = this.state.check_number;
    let summary = this.state.summary;
    let currency = this.state.Currency.currency;
    let exchange_rate = this.state.exchange_rate;
    let bank = this.state.bank ? this.state.bank.label : null;

    let _this = this;

    let decimal = /^[-+]?[0-9]+\.?[0-9]{0,2}$/;
    if (decimal.test(amount)) {
      amount = Number(amount);
    } else {
      displayError(getString().valid_amount);
      document.getElementById("amount").focus();
      return;
    }

    let withdrawnFromPrevBal = null;
    let withdrawnFromPrevLocBal = null;
    if (this.state.cashbookActivated === "true") {
      withdrawnFromPrevBal = currencyValue(this.state.bank.balance, false);
      withdrawnFromPrevLocBal = currencyValue(
        this.state.bank.local_balance,
        false
      );
    }

    if (this.state.cashbookActivated !== "true" && expense_method === "cash") {
      bank += "_pettyCash";
    }

    let temp = {
      date: date,
      purpose: purpose,
      amount: amount,
      location: location,
      cheque_number: check_number,
      summary: summary,
      category: category,
      currency: currency,
      exchange_rate: exchange_rate,
      bank: bank,
      expense_method: expense_method,
      from_prev_balance: withdrawnFromPrevBal,
      from_prev_loc_balance: withdrawnFromPrevLocBal,
    };

    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/expenses/expense",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: temp,

      success: function(data) {
        let d = _this.state.expenseData;
        Object.keys(data.expense).forEach(function(key) {
          d[key] = data.expense[key];
        });
        _this.setState({
          expenseData: d,
        });
        this.tableRef.current.onQueryChange();
      }.bind(this),
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  clearForm = () => {
    this.setState({
      amount: 0.0,
      summary: "",
      check_number: "",
      purpose: {},
      location: {},
      bank: {},
      category: {},
    });
    this.getCurrency();
  };

  hideExpense = () => {
    this.setState({ expenseData: {} });
    this.clearForm();
  };

  componentWillMount() {
    this.setState({
      cashbookActivated: cookies.load("cashbookActivated"),
    });
  }

  componentDidMount() {
    this.loadDashData();
    this.getCurrency();
    let _purpose = this.state.purposeOptions[0];
    let _method = this.state.expense_methods[0];

    this.setState({
      date: getToday(),
      purpose: _purpose,
      method: _method,
    });
  }

  deleteData = (data) => {
    let _this = this;
    if (data.length != 1) {
      displayError(getString().only_one);
      return;
    }

    if (window.confirm(getString().delete_expense)) {
      $.ajax({
        method: "DELETE",
        url: baseUrl + "/afrijula/expenses/expense/" + data[0]._id.$oid,
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(resp) {
          displaySuccess(resp.success);
          _this.tableRef.current.onQueryChange();
        },
        error: function(error) {
          displayError(error.responseText);
        },
      });
    }
  };

  closeAccountRodal = (e) => {
    this.setState({
      addAccount: false,
    });
  };

  showAccountForm = () => {
    return (
      <BankAccount
        bankName={this.state.newAccountName}
        closeExpenseRodal={this.closeAccountRodal}
        updateSelectField={(opt) => this.updateSelectField(opt)}
      />
    );
  };

  render() {
    const { classes } = this.getProps();

    return (
      <div style={{ marginTop: -50 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4
                  className={classes.cardTitleWhite}
                  style={{ float: "left" }}
                >
                  {getString().purchase_manager}{" "}
                </h4>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="success"
                  size={"sm"}
                  onClick={this.saveExpense}
                  style={{
                    backgroundColor: "#4caf50",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    float: "right",
                    padding: "5px 10px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                >
                  {getString().save}
                </Button>
              </CardHeader>
              {this.renderExpenseForm()}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="danger">
                <ChartistGraph
                  className="ct-chart"
                  data={this.state.chartData.expenses.data}
                  type="Line"
                  options={() => {
                    let opts = this.state.chartData.expenses.options;
                    opts.high = this.state.chartData.expenses.data.max;
                    return opts;
                  }}
                  listener={this.state.chartData.expenses.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>
                  {getString().monthly_purchase}
                </h4>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <span
                    onClick={() => {
                      let end_date = new Date();
                      let start_date = new Date(
                        end_date.getFullYear(),
                        end_date.getMonth() - 1,
                        end_date.getDate()
                      );
                      let params = {
                        organization_id: cookies.load("orgId"),
                        org_name: cookies.load("orgName"),
                        start_date: start_date,
                        end_date: end_date,
                      };
                      let inject = {
                        images: [
                          {
                            name: "image",
                            attr: "image",
                            id: cookies.load("orgId"),
                            model: "Organization",
                          },
                        ],
                      };

                      showReportUtility(
                        "Daily Expense",
                        baseUrl +
                          "/reports/1?url=/afrijula/*&profile=" +
                          cookies.load("profileId"),
                        {
                          params: params,
                          sub: "&report=expense_report&dynamic=true",
                          inject: inject,
                        },
                        cookies,
                        [
                          {
                            type: "date",
                            default: start_date,
                            label: "From",
                            name: "start_date",
                          },
                          {
                            type: "date",
                            default: end_date,
                            label: "To",
                            name: "end_date",
                          },
                        ]
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#800080",
                    }}
                  >
                    {getString().details}
                  </span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h3 className={classes.cardTitleWhite}>
                  {getString().purchase_table}
                </h3>
              </CardHeader>
              <ExpenseTable
                tableRef={this.tableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.loadData(query, resolve, reject);
                  })
                }
                columns={this.state.header}
                onRowClick={this.rowSelect}
                options={{
                  exportButton: true,
                  filtering: true,
                  grouping: true,
                  sorting: true,
                  selection: true,
                  showTitle: false,
                  debounceInterval: 1000,
                  pageSize: this.state.recalledPageSize || 15,
                }}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh",
                    isFreeAction: true,
                    onClick: () =>
                      this.tableRef.current &&
                      this.tableRef.current.onQueryChange(),
                  },
                  {
                    tooltip: "Remove Selected expense",
                    icon: "delete",
                    onClick: (evt, data) => {
                      this.deleteData(data);
                    },
                  },
                ]}
              />
            </Card>
          </GridItem>
        </GridContainer>
        <Rodal
          visible={Object.keys(this.state.expenseData).length !== 0}
          height={window.innerHeight >= 630 ? 630 : "90vh"}
          width={window.innerWidth >= 800 ? 800 : "90vw"}
          showMask={false}
          onClose={this.hideExpense.bind(this)}
        >
          <div>
            <Button
              onClick={this.hideExpense.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="danger"
              style={{
                backgroundColor: "#f44336",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().close}
            </Button>
            <Button
              onClick={this.printExpense.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="success"
              style={{
                backgroundColor: "#4caf50",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().to_print}
            </Button>
          </div>
          <br />
          <div
            id={"printableView"}
            style={
              window.innerWidth >= 800
                ? {
                    overflow: "scroll",
                    zIndex: "10000",
                    width: 780,
                    height: 580,
                  }
                : {
                    overflow: "scroll",
                    zIndex: "10000",
                    width: "90vw",
                    height: "90vh",
                  }
            }
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Paper>
                  <h2>{getString().purchase}</h2>
                  <span>
                    {getString().refNo}:{" "}
                    {this.state.expenseData.reference_number}
                  </span>
                  <br />
                  <span>
                    {getString().location}: {this.state.expenseData.location}
                  </span>
                  <Table
                    style={styles.table}
                    style={
                      window.innerWidth >= 800
                        ? {
                            overflow: "scroll",
                            zIndex: "10000",
                            width: 780,
                            height: 580,
                          }
                        : {
                            overflow: "scroll",
                            zIndex: "10000",
                            width: "90vw",
                            height: "90vh",
                          }
                    }
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{getString().amount}</TableCell>
                        <TableCell>
                          {currencyFormat(this.state.expenseData.amount)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{getString().date}</TableCell>
                        <TableCell>
                          {dateFormat(this.state.expenseData.date)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.state.cashbookActivated === "true"
                            ? getString().account
                            : getString().bank}
                        </TableCell>
                        <TableCell>{this.state.expenseData.bank}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="right">
                          <h3>{getString().details}</h3>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          {getString().bank +
                          ": " +
                          (this.state.bank === undefined)
                            ? ""
                            : this.state.bank.bank}
                        </TableCell>
                        <TableCell>
                          {getString().refNo + ": "}
                          {this.state.expenseData.check_number === null
                            ? "-"
                            : this.state.expenseData.check_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          {getString().category +
                            ": " +
                            this.state.expenseData.category}
                        </TableCell>
                        <TableCell>
                          {getString().purpose +
                            ": " +
                            this.state.expenseData.purpose}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          {getString().summary +
                            ": " +
                            this.state.expenseData.summary}
                        </TableCell>
                        <TableCell>
                          {getString().payee + ": ________________"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {getString().paid_by + ": __________________"}
                        </TableCell>
                        <TableCell>
                          {getString().signature + ":___________________"}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <br />
                        </TableCell>
                        <TableCell>
                          <br />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </GridItem>
            </GridContainer>
          </div>
          <>
            <br />
            <br />
            <br />
          </>
        </Rodal>
        <>
          <br />
          <br />
          <br />
        </>
        <Rodal
          visible={this.state.addAccount}
          height={420}
          width={800}
          showMask={false}
          onClose={this.closeAccountRodal.bind(this)}
        >
          {this.state.addAccount ? this.showAccountForm() : null}
        </Rodal>
      </div>
    );
  }
}

ExpenseManager.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(ExpenseManager);
