import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table'
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import ShowDetails from "components/afrijula/AllDetails/ReceiptTemplate.jsx"
import NewContract from 'components/afrijula/Contracts/NewContract.jsx';
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardContent from "components/common/Card/CardBody.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import {canSeeDash, compileQuery, currencyFormat, dateFormat, getString, numberFormat} from "assets/js/utils/utils.js";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Icon from "@material-ui/core/Icon/Icon";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="React.Fragment"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Contracts extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.tablePopRef = React.createRef();

        this.state = this.props.state || {
            value: 0,
            appBar: true,
            lastPage: null,
            lastSize: null,
            lastSort: null,
            showList: false,
            reportFilter: '',
            newContract: false,
            globalFilter: 'none',
            salesData: null,
            data: null,
            recalledPage: '',
            recalledPageSize: null,
            all: [],
            back: false,
            tilesData: {
                closed_contracts: 0.0,
                stale_contracts: 0.0,
                stopped_contracts: 0.0,
                two_months_old: 0.0,
            },
        };
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    handleExport = function () {
        let includes = {"include": "{customer: {only: :name},invoice: {only: [:status,:balance,:contract_end_date,:installments]}}"};
        let enhanceSearch = [{model: 'Afrijula::Sales::Customer', assoc: 'customer_id'}];
        let filter = "tran_type:'contract', reversed: {'$ne':true}, contract_closed: {'$ne': true}";

        if (this.state.reportFilter === 'unreniewed') {
            filter += ", 'invoice.contract_end_date': {'$lte': DateTime.now}"
        } else if (this.state.reportFilter === 'stopped') {
            filter += ", 'invoice.status':{'$eq':'stopped'}"
        } else if (this.state.reportFilter === 'non_payment') {
            filter += ", 'invoice.installments.balance.cents':{'$gt':0}, 'invoice.installments.date':{'$lte':1.month.ago}"
        }

        let url = compileQuery({}, (baseUrl + "/afrijula/sales/sales"),
            ["date", "total", "id", "description", "reversed", "contract_closed", "tran_type"],
            ([{"by": "asc", "attr": "date"}]),
            "Afrijula::Sales::Sale", includes, filter,
            enhanceSearch);
        url += '&download_as=true';

        fetch(url, {
            dataType: 'blob',
            headers: {
                'Accept': 'application/json',
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.blob()
            })
            .then(result => {
                var windowUrl = window.URL || window.webkitURL;
                var url = windowUrl.createObjectURL(result);
                var anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'contract data.xls';
                anchor.click();
                if (anchor.parentNode)
                    anchor.parentNode.removeChild(anchor);
                windowUrl.revokeObjectURL(url);
            });

    };

    loadContractsTable = (query, resolve, reject) => {
        let includes = {"include": "{customer: {only: :name},invoice: {only: [:status,:balance,:contract_end_date,:installments]}}"};
        let enhanceSearch = [{model: 'Afrijula::Sales::Customer', assoc: 'customer_id'}];
        let filter = "tran_type:'contract', reversed: {'$ne':true}, contract_closed: {'$ne': true}";

        if (this.state.reportFilter === 'unreniewed') {
            filter += ", 'invoice.contract_end_date': {'$lte': DateTime.now}"
        } else if (this.state.reportFilter === 'stopped') {
            filter += ", 'invoice.status':{'$eq':'stopped'}"
        } else if (this.state.reportFilter === 'non_payment') {
            filter += ", 'invoice.installments.balance.cents':{'$gt':0}, 'invoice.installments.date':{'$lte':1.month.ago}"
        }

        let url = compileQuery(query, (baseUrl + "/afrijula/sales/sales"),
            ["date", "total", "tran_type", "id", "description", "labels", "reversed", "contract_closed", "tran_type"],
            ([{"by": "asc", "attr": "date"}]),
            "Afrijula::Sales::Sale", includes, filter,
            enhanceSearch);

        fetch(url, {
            dataType: "json",
            headers: {
                'Accept': 'application/json',
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {

                let data = (result.data !== undefined) ?
                    result.data.map((data) => {
                        return ({
                            customer: data.customer.name,
                            description: data.description,
                            date: dateFormat(data.date),
                            balance: currencyFormat(data.invoice ? data.invoice.balance : 0.0),
                            status: data.invoice.status,
                            _id: data._id,
                            end_date: data.invoice ? dateFormat(data.invoice.contract_end_date) : ''
                        })
                    }) : [];
                resolve({
                    data: data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadSalesTable = (query, resolve, reject, tab) => {

        let includes = {"include": "{customer: {only: :name},invoice: {only: [:status,:balance,:contract_end_date]}}"};
        let enhanceSearch = [{model: 'Afrijula::Sales::Customer', assoc: 'customer_id'}];
        let filter = "tran_type:'contract'";
        if (tab === 'active') {
            filter += ", reversed: {'$ne':true}, contract_closed: {'$ne': true}";
        } else if (tab === 'close') {
            filter += ", '$or': [{contract_closed: {'$eq': true}}, {reversed: {'$eq':true}}]";
        }
        if (this.state.globalFilter === 'outstanding') {
            filter += ", 'invoice.balance.cents': {'$gt': 0}"
        } else if (this.state.globalFilter === 'negative') {
            filter += ", 'invoice.balance.cents': {'$lt': 0}"
        }

        let url = compileQuery(query, (baseUrl + "/afrijula/sales/sales"),
            ["date", "total", "tran_type", "id", "description", "labels", "reversed", "contract_closed", "tran_type"],
            ([{"by": "asc", "attr": "date"}]),
            "Afrijula::Sales::Sale", includes, filter,
            enhanceSearch);
        this.state.lastPage = query.page;
        this.state.lastSize = query.pageSize;
        this.state.lastSort = query.orderBy;

        fetch(url, {
            dataType: "json",
            headers: {
                'Accept': 'application/json',
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                if (result.data !== undefined) {
                    this.state.all = result.data.map((data) => {
                        return ({
                            customer: data.customer ? data.customer.name : 'N/A',
                            description: data.description,
                            date: dateFormat(data.date),
                            balance: currencyFormat(data.invoice ? data.invoice.balance : 0.0),
                            status: data.invoice.status,
                            _id: data._id,
                            end_date: data.invoice ? dateFormat(data.invoice.contract_end_date) : ''
                        })
                    });
                }
                resolve({
                    data: this.state.all,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    rowSelect = (event, colData) => {
        let id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/sales/sales/" + id,
            data: {filter: 'installments'},
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                if (_this.props.updateMe)
                    _this.props.updateMe(false);
                _this.setState({salesData: result});
            }
        });
    };

    recallPage = () => {
        const page = this.state.recalledPage;
        this.state.recalledPage = null;
        return page;
    };

    recallSort = () => {
        const order = this.state.lastSort;
        this.state.lastSort = null;
        return order;
    };

    tableRefresh = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    loadTilesData = (page) => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/dashboard",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {count: ['closed_contracts', 'stale_contracts', 'stopped_contracts', 'two_months_old'], chart: []},

            success: function (result) {
                let temp = _this.state.tilesData;
                let data = result;
                temp.closed_contracts = data.counts.closed_contracts;
                temp.stale_contracts = data.counts.stale_contracts;
                temp.stopped_contracts = data.counts.stopped_contracts;
                temp.two_months_old = data.counts.two_months_old;

                this.setState({tilesData: temp});
            }.bind(this)
        });
    };

    showDash = () => {
        const {classes} = this.getProps();
        return (canSeeDash()) ? <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="success" stats icon>
                        <CardIcon color="success" className="small-icon">
                            <Icon>money</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>{getString().number_of_closed}</p>
                    </CardHeader>
                    <CardContent>
                        <GridContainer>
                            <GridItem>
                                <h5 className={classes.cardTitle}>
                                    {numberFormat(this.state.tilesData.closed_contracts)}
                                </h5>
                            </GridItem>

                        </GridContainer>
                    </CardContent>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="warning" className="small-icon">
                            <Icon>money</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>{getString().unrenewed_contracts}</p>
                    </CardHeader>
                    <CardContent>
                        <GridContainer>
                            <GridItem>
                                <h5 className={classes.cardTitle}>{numberFormat(this.state.tilesData.stale_contracts)}</h5>
                            </GridItem>
                            <GridItem>
                                <div className={classes.stats}>
                                        <span onClick={() => {
                                            this.setState({showList: true, reportFilter: 'unreniewed'});
                                        }} style={{cursor: 'pointer', color: '#800080'}}>{getString().details}</span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardContent>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="danger" stats icon>
                        <CardIcon color="danger" className="small-icon">
                            <Icon>money</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>{getString().stopped_contracts}</p>
                    </CardHeader>
                    <CardContent>
                        <GridContainer>
                            <GridItem>
                                <h5 className={classes.cardTitle}>{numberFormat(this.state.tilesData.stopped_contracts)}</h5>
                            </GridItem>
                            <GridItem>
                                <div className={classes.stats}>
                                        <span onClick={() => {
                                            this.setState({showList: true, reportFilter: 'stopped'});
                                        }} style={{cursor: 'pointer', color: '#800080'}}>{getString().details}</span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardContent>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="info" stats icon>
                        <CardIcon color="info" className="small-icon">
                            <Icon>notes</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>{getString().two_months_old}</p>
                    </CardHeader>
                    <CardContent>
                        <GridContainer>
                            <GridItem>
                                <h5 className={classes.cardTitle}>{currencyFormat(this.state.tilesData.two_months_old)}</h5>
                            </GridItem>
                            <GridItem>
                                <div className={classes.stats}>
                                        <span onClick={() => {
                                            this.setState({showList: true, reportFilter: 'non_payment'})

                                        }} style={{cursor: 'pointer', color: '#800080'}}>{getString().details}</span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardContent>
                </Card>
            </GridItem>
        </GridContainer> : null;
    };

    showPanel = (panel, test) => {
        return <div>
            {this.showDash()}
            {(panel === 'active') ? <div style={{paddingBottom: '5px'}}>
                <Button xs={12} sm={12} md={2} color="blue" onClick={this.createNewContract}
                        style={{
                            backgroundColor: "#00acc1",
                            margin: ".3125rem 1px",
                            minWidth: "auto",
                            minHeight: "auto",
                            fontSize: "12px",
                            color: "#ffffff",
                            padding: "10px 30px",
                            borderRadius: "3px",
                            verticalAlign: "middle",
                            float: 'right'
                        }}
                ><i className="material-icons">add</i>
                    {getString().New}
                </Button>
                <FormControl>
                    <FormLabel>{getString().filter}</FormLabel>
                    <RadioGroup row value={this.state.globalFilter} name="globalFilter" onChange={e => {
                        this.setState({globalFilter: e.target.value});
                        this.tableRefresh();
                    }}>
                        <FormControlLabel label={getString().all}
                                          value="none"
                                          control={<Radio/>}/>
                        <FormControlLabel label={getString().outstanding_balance}
                                          value="outstanding"
                                          control={<Radio/>}/>
                        <FormControlLabel
                            control={<Radio/>}
                            value="negative"
                            label={getString().negative_balance}/>
                    </RadioGroup>
                </FormControl>
            </div> : null}
            <div style={{maxWidth: '100%'}}>
                <MaterialTable
                    tableRef={this.tableRef}
                    data={query => new Promise((resolve, reject) => {
                        let page = this.recallPage();
                        let tab = panel;
                        if (page) {
                            query.page = page
                        }
                        let sort = this.recallSort();
                        if (sort)
                            query.orderBy = sort;
                        this.loadSalesTable(query, resolve, reject, tab);
                    })}
                    columns={[
                        {
                            title: getString().customer,
                            field: 'customer',
                            cellStyle: {padding: '4px'},
                            headerStyle: {padding: '4px'}
                        },
                        {
                            title: getString().description,
                            field: 'description',
                            cellStyle: {padding: '4px'},
                            headerStyle: {padding: '4px'}
                        },
                        {
                            title: getString().date,
                            field: 'date',
                            cellStyle: {padding: '4px'},
                            headerStyle: {padding: '4px'}
                        },
                        {
                            title: getString().end_date,
                            field: 'end_date',
                            cellStyle: {padding: '4px'},
                            headerStyle: {padding: '4px'}
                        },
                        {
                            title: getString().balance,
                            field: 'balance',
                            cellStyle: {padding: '4px'},
                            headerStyle: {padding: '4px'}
                        },
                        {
                            title: getString().status,
                            field: 'status',
                            cellStyle: {padding: '4px'},
                            headerStyle: {padding: '4px'}
                        }
                    ]}
                    onRowClick={this.rowSelect}
                    options={{
                        exportButton: true, filtering: true,
                        debounceInterval: 1000,
                        grouping: true, sorting: true,
                        search: true,
                        paging: true,
                        selection: true, showTitle: false,
                        pageSize: this.state.recalledPageSize || 10, rowStyle: {height: '8px'}
                    }}
                    actions={[
                        {
                            icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                            onClick: () => {
                                this.tableRefresh()
                            },
                        }
                    ]}
                    title="Contracts"
                />
            </div>
        </div>;
    };

    createNewContract = (event) => {
        this.setState({newContract: true});
    };

    componentDidMount() {
        this.loadTilesData();
    }

    render() {

        const value = this.state.value;
        if (this.state.salesData) {
            let data = this.state.salesData;
            this.state.salesData = null;
            this.state.recalledPage = this.state.lastPage;
            this.state.recalledPageSize = this.state.lastSize;
            return <ShowDetails callerProps={this.getProps()} tag={"contract"} callerState={this.state} salesData={data}
                                callerName={Contracts}/>;
        } else if (this.state.newContract) {
            this.state.recalledPage = this.state.lastPage;
            this.state.recalledPageSize = this.state.lastSize;
            this.state.newContract = false;
            return (<NewContract callerName={Contracts} callerProps={this.getProps()} callerState={this.state}
                                 setCustomerDetails={true}/>);
        } else {
            return (
                <div>
                    <AppBar position="static">
                        <Tabs value={value} onChange={this.handleChange} aria-label="simple tabs example"
                              style={{backgroundColor: "#9c27b0"}}>
                            <Tab label={getString().active_contracts}/>
                            <Tab label={getString().closed_contracts}/>
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} style={{padding: '0px', margin: '0px'}}>
                        {value === 0 ? this.showPanel('active', value) : null}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {value === 1 ? this.showPanel('close', value) : null}
                    </TabPanel>
                    {(this.state.showList === true) ? <Dialog open={this.state.showList} onClose={() => {
                        this.setState({showList: false})
                    }} style={{zIndex: 100000}}>
                        <DialogContent>
                            <MaterialTable
                                tableRef={this.tablePopRef}
                                data={query => new Promise((resolve, reject) => {

                                    this.loadContractsTable(query, resolve, reject);
                                })}
                                columns={[
                                    {
                                        title: getString().customer,
                                        field: 'customer',
                                        cellStyle: {padding: '2px'},
                                        headerStyle: {padding: '2px'}
                                    },
                                    {
                                        title: getString().date,
                                        field: 'date',
                                        cellStyle: {padding: '2px'},
                                        headerStyle: {padding: '2px'}
                                    },
                                    {
                                        title: getString().end_date,
                                        field: 'end_date',
                                        cellStyle: {padding: '2px'},
                                        headerStyle: {padding: '2px'}
                                    },
                                    {
                                        title: getString().balance,
                                        field: 'balance',
                                        cellStyle: {padding: '2px'},
                                        headerStyle: {padding: '2px'}
                                    },
                                    {
                                        title: getString().status,
                                        field: 'status',
                                        cellStyle: {padding: '2px'},
                                        headerStyle: {padding: '2px'}
                                    },

                                ]}
                                onRowClick={this.rowSelect}
                                options={{
                                    filtering: true,
                                    debounceInterval: 1000,
                                    grouping: false, sorting: true,
                                    paging: true,
                                    selection: true, showTitle: true,
                                    pageSize: 20, rowStyle: {height: '4px'}
                                }}
                                actions={[
                                    {
                                        icon: 'cloud_download', tooltip: 'Download', isFreeAction: true,
                                        onClick: () => {
                                            this.handleExport()
                                        },
                                    },
                                    {
                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                        onClick: () => {
                                            this.tablePopRef.current && this.tablePopRef.current.onQueryChange()
                                        },
                                    }
                                ]}
                                title="Contracts"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({showList: false})
                            }} color="warning">
                                {getString().close}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}
                </div>
            );
        }
    }
}

export default withStyles(dashboardStyle)(Contracts)
