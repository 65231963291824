import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";


class Charge extends Component {

    render() {

        return (
        <>
        <FormControl component="fieldset">
            <RadioGroup
              row={true} 
              aria-label="Charge" 
              name="charge"
              className="genre-wrapper"
              value={this.props.value}
              onChange={this.props.onChangeValue}
            >
            <FormControlLabel value="week" control={<Radio />} label={getString().weekly} />
            <FormControlLabel value="month" control={<Radio />} label={getString().monthly} />
            <FormControlLabel value="quarter" control={<Radio />} label={getString().quarterly} />
            <FormControlLabel value="year" control={<Radio />} label={getString().annually} />
            </RadioGroup>
          </FormControl>
          </>
          );
    }
}

export default Charge
