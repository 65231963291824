import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import ProductTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import NewProduct from "components/afrijula/AddForm/AddProduct.jsx";
import {
  numberFormat,
  currencyFormat,
  displayError,
  displaySuccess,
  compileQuery,
  applyPackaging,
  getString,
} from "assets/js/utils/utils.js";
import CardBody from "components/common/Card/CardBody.jsx";
import $ from "jquery";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import Rodal from "rodal";
import { Async } from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';

import TextField from "@material-ui/core/TextField";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.partsTableRef = React.createRef();
  }

  state = this.props.state || {
    productData: {
      id: null,
      name: null,
      number: null,
      category: null,
      unit_price: null,
    },
    editproduct: false,
    addProduct: false,
    editPart: false,
    back: false,
    header: [
      {
        title: getString().product,
        field: "name",
        grouping: false,
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().category,
        field: "category",
        defaultGroupOrder: 0,
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().unit_price,
        field: "unit_price",
        grouping: false,
        options: { filter: false },
        render: (rowData) => currencyFormat(rowData.unit_price),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],
    headerIngredient: [
      {
        title: getString().ingredients,
        field: "description",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().quantity,
        field: "quantity",
        options: { filter: false },
        render: (rowData) => numberFormat(rowData.quantity),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],
    callerName: this.props.callerName,
    callerState: this.props.callerState,
    callerProps: this.props.callerProps,

    //justifiy the rest
    showIngredientsForm: false, //show form to add new ingredient
    ingredients: [], //load data for ingredients table
    currentParts: {}, //store partdata
  };

  getIngredItem = (opt) => {
    let part = this.state.currentParts;
    part.description = opt.label;
    part.number = opt.number || opt.description;
    this.setState({ currentParts: part });
  };

  viewIngredientForm = () => {
    return (
      <div style={{ backgroundColor: "white", borderColor: "blue" }}>
        <h5>Item</h5>
        <p>Please add item this part of this product</p>
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <AsyncCreatableSelect
                autoFocus
                defaultOptions
                loadOptions={this.stockOptions}
                name="part_name"
                id="part_name"
                placeholder={getString().select_item}
                value={{
                  label: this.state.currentParts.description,
                  value: this.state.currentParts._id
                    ? this.state.currentParts._id.$oid
                    : "",
                }}
                onChange={(opt) => {
                  this.getIngredItem(opt);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                id="quantity"
                name="quantity"
                label={getString().quantity}
                type="number"
                value={this.state.currentParts.quantity}
                onChange={(e) => {
                  this.handlePartChange(e);
                }}
                margin="normal"
                variant="outlined"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button onClick={this.hideParts.bind(this)} color="danger">
                {getString().cancel}
              </Button>
              <Button onClick={this.saveParts.bind(this)} color="success">
                {getString().save}
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    if (id) {
      this.loadComposition(id);
    }
    let data = this.state.productData;
    data._id = colData._id;
    data.name = colData.name;
    data.number = colData.number;
    data.unit_price = colData.unit_price;
    data.category = colData.category;

    this.setState({
      productData: data,
    });
  };

  showDetails = (classes) => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h5 className={classes.cardTitleWhite} style={{ float: "left" }}>
                {getString().details}{" "}
              </h5>
              <Button
                xs={12}
                sm={12}
                md={2}
                color="success"
                size={"sm"}
                onClick={this.editProduct.bind(this)}
                style={{
                  backgroundColor: "#4caf50",
                  margin: ".3125rem 1px",
                  minWidth: "auto",
                  minHeight: "auto",
                  fontSize: "12px",
                  color: "#ffffff",
                  float: "right",
                  padding: "5px 10px",
                  borderRadius: "3px",
                  verticalAlign: "middle",
                }}
              >
                <i className="material-icons">edit</i>
                {getString().edit}
              </Button>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <b>{getString().product}: </b>
                        <b>{this.state.productData.name}</b>
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <b>{getString().category}: </b>{" "}
                        <b>{this.state.productData.category}</b>
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <b>{getString().number}: </b>{" "}
                        <b>{this.state.productData.number}</b>
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <b>{getString().unit_price}: </b>{" "}
                        <b>
                          {currencyFormat(this.state.productData.unit_price)}
                        </b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  refreshParts = () => {
    this.loadComposition();
  };

  loadComposition = (id) => {
    let url = baseUrl + "/afrijula/sales/product_parts";
    let data = { id: id || this.state.productData._id.$oid };
    url += "?" + $.param(data);

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ ingredients: result.data });
      });
  };

  loadProduct = (id) => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/products" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        // _this.setState({productData: result})/;
      },
    });
  };

  stockOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/sales/sales/sellable?stockOnly=true&attrs=["id","name","unit_price","number"]&to=name&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let opts = [];
        let i = 0;
        for (; i < data.length; i++) {
          opts[i] = {
            label: data[i].name,
            value: data[i]._id.$oid,
            number: data[i].number,
            type: data[i].type,
          };
        }
        return opts;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadData = (query, resolve, reject) => {
    let _array = [];
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/sales/products",
      ["id", "name", "number", "category", "unit_price"],
      [{ by: "asc", attr: "name" }],
      "Afrijula::Sales::Product",
      null,
      null
    );

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        let i;
        for (i = 0; i < result.data.length; i++) {
          _array.push(result.data);
        }
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  hideParts = () => {
    this.setState({ showIngredientsForm: false });
  };

  saveParts = () => {
    let method = this.state.currentParts._id ? "PUT" : "POST";
    let url = baseUrl + "/afrijula/sales/product_parts/";
    if (method === "PUT") url += this.state.currentParts._id.$oid;

    let data = this.state.currentParts;
    let product_number = this.state.productData.number;

    if (data.description === "") {
      displayError("Please select description");
      document.getElementById("part_name").focus();
      return;
    }

    if (data.quantity === undefined || data.quantity === 0) {
      displayError("Please set quantity greater than 0");
      document.getElementById("quantity").focus();
      return;
    }
    let _this = this;
    $.ajax({
      method: method,
      url: url,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { parts: [data], product_number: product_number },

      success: function(data) {
        {
          displaySuccess("added product sub item.");
        }
        _this.hideIngredForm();
        _this.refreshParts();
      },
      error: function(response) {
        {
          displayError(response.responseText);
        }
      },
    });
  };

  hideIngredForm = () => {
    this.setState({ showIngredientsForm: false });
  };

  addMenuForm = () => {
    let prodData = this.state.productData;

    prodData._id = null;
    prodData.name = null;
    prodData.unit_price = null;
    prodData.category = null;
    prodData.number = null;

    this.setState({
      addProduct: true,
      productData: prodData,
    });
  };

  editProduct = () => {
    this.setState({
      editproduct: true,
    });
  };

  closeEdit = () => {
    this.setState({
      editproduct: false,
    });
  };

  closeMenuForm = () => {
    this.setState({
      addProduct: false,
    });
  };

  handlePartChange = (e) => {
    let parts = this.state.currentParts;
    parts[e.target.name] = e.target.value;
    this.setState({ currentParts: parts });
  };

  showIngredForm = () => {
    this.setState({ showIngredientsForm: true });
  };

  rowSelectPart = (event, colData) => {
    let part_id = colData._id.$oid;
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/product_parts/" + part_id,
      data: { product_id: this.state.productData._id.$oid },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let data = _this.state.currentParts;
        data.description = result.description;
        data.number = result.number;
        data.quantity = result.quantity;
        data._id = result._id;
        _this.setState({
          currentParts: data,
          showIngredientsForm: true,
        });
      },
    });
  };

  refreshMenu = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  updateProduct = (id, data) => {
    if (data) {
      let d = this.state.productData;
      d.category = data.category;
      d.billable = data.billable;
      d.product = data.product;
      d.product_number = data.product_number;
      d.unit_price = data.unit_price;
      this.setState({ productData: d });
    }
  };

  editProductView = () => {
    return (
      <NewProduct
        callerProps={this.getProps()}
        productData={this.state.productData ? this.state.productData : {}}
        callerState={this.state}
        callerName={Menu}
        noBillable={true}
        refreshMenu={this.refreshMenu.bind(this)}
        closeEdit={this.closeEdit.bind(this)}
        noBack={true}
        editProduct={true}
        saveProductCback={this.updateProduct.bind(this)}
      />
    );
  };

  addProductView = () => {
    return (
      <NewProduct
        callerProps={this.getProps()}
        callerState={this.state}
        callerName={Menu}
        noBillable={true}
        refreshMenu={this.refreshMenu.bind(this)}
        closeMenuForm={this.closeMenuForm.bind(this)}
        noBack={true}
        saveProductCback={this.loadProduct.bind(this)}
      />
    );
  };

  removePart = (data) => {
    let _this = this;
    $.ajax({
      method: "DELETE",
      url:
        baseUrl +
        "/afrijula/sales/product_parts/" +
        this.state.productData._id.$oid,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: {
        items: data.map((item) => {
          return item._id.$oid;
        }),
      },

      success: function(data) {
        {
          displaySuccess("remove item");
        }
        _this.refreshParts();
      },
      error: function(response) {
        {
          displayError(response.responseText);
        }
      },
    });
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let caller_state = this.state.callerState;
      caller_state.addMenu = false;

      return (
        <ChildComponent
          state={caller_state}
          returningProps={this.state.callerProps}
        />
      );
    } else {
      return (
        <div style={{ marginTop: -35 }}>
          <GridContainer>
            <GridItem xs={6} />
            <GridItem xs={6}>
              <Button
                style={{ float: "right" }}
                color="danger"
                onClick={() => {
                  this.setState({ back: true });
                }}
                style={{
                  backgroundColor: "#f44336",
                  margin: ".3125rem 1px",
                  minWidth: "auto",
                  minHeight: "auto",
                  fontSize: "12px",
                  float: "right",
                  color: "#ffffff",
                  padding: "12px 30px",
                  borderRadius: "3px",
                  verticalAlign: "middle",
                }}
              >
                {getString().back}
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="warning">
                  <h5 className={classes.cardTitleWhite}>
                    {getString().items}
                  </h5>
                </CardHeader>
                <ProductTable
                  tableRef={this.tableRef}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      this.loadData(query, resolve, reject);
                    })
                  }
                  columns={this.state.header}
                  onRowClick={this.rowSelect}
                  options={{
                    exportButton: false,
                    filtering: false,
                    grouping: true,
                    sorting: true,
                    search: true,
                    selection: true,
                    showTitle: false,
                    pageSize: 5,
                  }}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh",
                      isFreeAction: true,
                      onClick: () =>
                        this.tableRef.current &&
                        this.tableRef.current.onQueryChange(),
                    },
                    {
                      tooltip: "Edit Selected item",
                      icon: "edit",
                      onClick: (evt, data) => {
                        this.editMenuItem(data);
                      },
                    },
                    {
                      tooltip: "Remove Selected item",
                      icon: "delete",
                      onClick: (evt, data) => {
                        this.removePart(data);
                      },
                    },
                    {
                      tooltip: "add product composition",
                      icon: "add",
                      isFreeAction: true,
                      onClick: (evt, data) => {
                        this.addMenuForm(evt, data);
                      },
                    },
                  ]}
                />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.addProduct ? this.addProductView() : null}
                {this.state.editproduct ? this.editProductView() : null}
                {this.state.productData._id ? this.showDetails(classes) : null}
              </GridItem>
              {this.state.productData._id ? (
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <ProductTable
                      title={getString().ingredients}
                      data={this.state.ingredients}
                      columns={this.state.headerIngredient}
                      onRowClick={this.rowSelectPart}
                      options={{
                        exportButton: false,
                        filtering: false,
                        grouping: false,
                        sorting: true,
                        search: false,
                        selection: true,
                        showTitle: true,
                        pageSize: 5,
                      }}
                      actions={[
                        {
                          icon: "refresh",
                          tooltip: "Refresh",
                          isFreeAction: true,
                          onClick: () => this.refreshParts(),
                        },
                        {
                          tooltip: "Remove Selected sale",
                          icon: "delete",
                          onClick: (evt, data) => {
                            this.removePart(data);
                          },
                        },
                        {
                          tooltip: "add product composition",
                          icon: "add",
                          isFreeAction: true,
                          onClick: (evt, data) => {
                            this.showIngredForm(evt, data);
                          },
                        },
                      ]}
                    />
                  </Card>
                </GridItem>
              ) : null}
            </GridItem>
          </GridContainer>
          <Rodal
            visible={this.state.showIngredientsForm}
            width={450}
            showMask={false}
            onClose={this.hideIngredForm.bind(this)}
          >
            {this.viewIngredientForm()}
          </Rodal>
        </div>
      );
    }
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Menu);
