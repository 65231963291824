import React from "react";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import Creatable from "react-select/creatable";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import {
  numberFormat,
  currencyFormat,
  dateFormat,
  displayError,
  displaySuccess,
  getString,
  validateNumberField,
  currencyValue,
} from "assets/js/utils/utils.js";
import OpeningBalances from "components/afrijula/Stocks/OpeningBalances.jsx";

import $ from "jquery";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class Add_Stock_Product extends React.Component {
  state = {
    product: "",
    unit_price: "",
    quantity: 1,
    callerName: this.props.callerName,
    callerState: this.props.callerState,
    callerProps: this.props.callerProps,
    back: false,
    checkedInventory: false,
    checkedProduct: false,
    prodType: "one-off",
    newStockData: null,
    oneTimeSaleItem: null,

    product_number: "",
    category: "other",
    categories: [],
    isBillable: false,
    locations: [],
    selectedLocation: null,
    stockData: {
      product: "",
      product_number: "",
      category: "",
      wholesale_metric: "",
      retail_metric: "",
      sellable: true,
      reorder_levels: "",
      sellable_units: 1,
      unit_price: "",
      opening_balance: 0,
      opening_balances: [],
    },
  };

  validateInput = (e) => {
    if (e.target.name === "retail_metric" && e.target.value.length > 0) {
      if (/^[a-zA-Z_]+$/.test(e.target.value.replace(" ", ""))) return true;
      else {
        alert(
          "You must specify a valid value for " + getString().retail_metric
        );
        return false;
      }
    }
    return true;
  };

  categoryOptions = (inputValue) => {
    const url =
      baseUrl +
      "/afrijula/sales/sales/sellable?category_only=true&match=" +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;

        for (; i < json.length; i++) {
          opts[i] = { label: json[i], value: json[i] };
        }
        return opts;
      }) // my option list array?
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeStock = (e) => {
    let data = this.state.stockData;
    if (!this.validateInput(e)) {
      data[e.target.name] = "";
    } else {
      data[e.target.name] = e.target.value;
      if (e.target.name === "retail_metric" && data.sellable_units === 1) {
        data.sellable_units = 1;
        data.wholesale_metric = e.target.value;
      }
    }

    this.setState({
      stockData: data,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  cancelFunct = () => {
    this.setState({
      quantity: 1,
      back: true,
    });
  };

  saveData = () => {
    if (this.state.prodType === "checkedInventory") {
      this.addStock();
      //   this.setState({ back: true });
    } else if (this.state.prodType === "checkedProduct") {
      this.saveProduct();
      //   this.setState({ back: true });
    } else {
      this.saveOneTimeProduct();
      //   this.setState({ back: true });
    }
  };

  handleCategoryChange = (opt) => {
    this.setState({ category: opt.value });
  };

  handleChangeRadioBtn = (e) => {
    if (e.target.value === "checkedInventory") {
      this.setState({
        prodType: e.target.value,
      });
    } else if (e.target.value === "checkedProduct") {
      this.setState({
        prodType: e.target.value,
      });
    } else {
      this.setState({
        prodType: e.target.value,
      });
    }
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  clearForm = () => {
    let data = this.state.stockData;
    data.opening_balances.splice(0, data.opening_balances.length);
    data.product = "";
    data.product_number = "";
    data.category = "";
    data.sellable = true;
    data.wholesale_metric = "";
    data.retail_metric = "";
    data.sellable_units = 1;
    data.unit_price = 0;

    this.setState({ stockData: data, whowWholesale: false });
  };

  clearFormProduct = () => {
    this.setState({
      product: "",
      product_number: "",
      category: "",
      unit_price: "",
    });
  };

  saveProduct = () => {
    let data = {};
    data.name = this.state.product;
    data.number = this.state.product_number;
    data.category = this.state.category;
    data.unit_price = this.state.unit_price;
    data.billable = this.state.isBillable;
    let decimal_number = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

    if (data.unit_price === "" || !decimal_number.test(data.unit_price)) {
      displayError("Please enter the unit price");
      document.getElementById("unit_price").focus();
      return;
    }
    let _this = this;

    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/sales/products",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",

      data: data,

      success: function(data) {
        {
          displaySuccess("Product successfully saved.");
        }
        _this.findAddedProduct(data._id.$oid);
        _this.clearFormProduct();
      },
      error: function(response) {
        {
          displayError(response.responseText);
        }
      },
    });
    // this.setState({ back: true });
  };

    saveOneTimeProduct = () => {
        let _data = {};
        _data.name = this.state.product;
        if(this.state.unit_price === "" || !validateNumberField(this.state.unit_price.trim())){
            displayError("Please enter a valid unit price", );
            document.getElementById("unit_price").focus();
            return;
        }

        _data.unit_price = {fractional: Number(this.state.unit_price)*100.0};
        _data.type = "one-off";
        _data.category = this.state.category;
        _data.quantity = parseInt(this.state.quantity);

    let decimal_number = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

    if (_data.category === "") {
      displayError("Please select category");
      document.getElementById("category").focus();
      return;
    }
    if (this.props.saveProductCback) {
      this.props.saveProductCback(_data, "one-off");
      this.setState({ back: true });
    }
  };

  addStock = () => {
    let method = "POST";
    let url = baseUrl + "/afrijula/stocks/stock";

    let stock = this.state.stockData;
    stock.name = this.state.product;
    stock.unit_price = this.state.unit_price;
    stock.quantity = parseInt(this.state.quantity);
    stock.category = this.state.category;

    let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;
    let decimal_number = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

    if (stock.name === "" || !reg.test(stock.name)) {
      displayError("Please enter a valid product name");
      document.getElementById("product").focus();
      return;
    }
    if (stock.number === "") {
      displayError("Please enter a valid product number");
      document.getElementById("product_number").focus();
      return;
    }

    if (
      stock.retail_metric !== stock.wholesale_metric &&
      stock.wholesale_metric
    ) {
      if (!stock.sellable_units || stock.sellable_units <= 1) {
        displayError("You must have a sellable unit greater than 1");
        document.getElementById("sellable_units").focus();
        return;
      }
    }
    if (stock.retail_metric === "") {
      displayError("Please enter retail metric");
      document.getElementById("retail_metric").focus();
      return;
    }
    if (
      stock.unit_price === "" ||
      !decimal_number.test(stock.unit_price.trim())
    ) {
      displayError("Please enter the unit price");
      document.getElementById("unit_price").focus();
      return;
    }
    if (stock.category === "") {
      displayError("Please select category");
      document.getElementById("category").focus();
      return;
    }
    if (stock.location === "") {
      displayError("Please select location");
      return;
    }

    let _this = this;
    $.ajax({
      method: method,
      url: url,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",

      data: stock,

      success: function(data) {
        {
          displaySuccess("Stock successfully saved.");
        }
        _this.findAddedStock(data.stock_id.$oid);
        _this.clearForm();
        _this.setState({ back: true });
      },
      error: function(response) {
        {
          displayError(response.responseText);
        }
      },
    });
  };

  findAddedProduct = (id) => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/products/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        result.quantity = parseInt(_this.state.quantity);
        if (_this.props.saveProductCback)
          _this.props.saveProductCback(result, "product");
        _this.cancelFunct();
      },
    });
  };

  findAddedStock = (id) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/stocks/stock/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        result.quantity = parseInt(_this.state.quantity);
        if (_this.props.saveProductCback)
          _this.props.saveProductCback(result, "stock");
        _this.cancelFunct();
      },
    });
  };

  updateOpeningBalances = (item) => {
    let data = this.state.stockData;
    data.opening_balances = item;
    this.setState({ stockData: data });
  };
  componentDidMount() {
    this.categoryOptions("").then((categories) =>
      this.setState({ categories: categories })
    );
    this.setState({
      product: this.props.productName,
    });
  }

  AddStock = (classes) => {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4>{getString().add_stock}</h4>
            <form className={classes.container} autoComplete="off">
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <TextField
                    required
                    id="product_number"
                    name="number"
                    label={getString().product_number}
                    type="text"
                    value={this.state.stockData.number}
                    onChange={(e) => {
                      this.handleChangeStock(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <TextField
                    required
                    id="retail_metric"
                    name="retail_metric"
                    label={getString().retail_metric}
                    type="text"
                    value={this.state.stockData.retail_metric}
                    onChange={(e) => {
                      this.handleChangeStock(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <span>
                    <p>{getString().sellable}: </p>
                  </span>
                  <RadioGroup
                    row
                    aria-label={getString().sellable}
                    name="sellable"
                    className={classes.group}
                    value={this.state.stockData.sellable ? "yes" : "no"}
                    onChange={(e) => {
                      let data = this.state.stockData;
                      data.sellable = e.target.value == "yes" ? true : false;
                      this.setState({ stockData: data });
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={getString().yes}
                      style={{ display: "inline" }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      style={{ display: "inline" }}
                      label={getString().no}
                    />
                  </RadioGroup>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showWholesale}
                      onChange={(e) => {
                        this.setState({
                          showWholesale: !this.state.showWholesale,
                        });
                      }}
                      value={this.state.showWholesale}
                    />
                  }
                  label={getString().wholesale}
                />
                <GridItem
                  xs={12}
                  sm={4}
                  md={4}
                  style={{
                    display: this.state.showWholesale ? "flex" : "none",
                  }}
                >
                  <TextField
                    id="sellable_units"
                    name="sellable_units"
                    label={getString().sellable_units}
                    type="text"
                    value={this.state.stockData.sellable_units}
                    onChange={(e) => {
                      this.handleChangeStock(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={4}
                  md={4}
                  style={{
                    display: this.state.showWholesale ? "flex" : "none",
                  }}
                >
                  <TextField
                    id="wholesale_metric"
                    name="wholesale_metric"
                    label={getString().wholesale_mteric}
                    type="text"
                    value={currencyValue(
                      this.state.stockData.wholesale_metric,
                      true
                    )}
                    onChange={(e) => {
                      this.handleChangeStock(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer />
              {!this.state.editStock ? (
                <OpeningBalances
                  updateChanges={this.updateOpeningBalances.bind(this)}
                />
              ) : null}
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  AddProduct = (classes) => {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form className={classes.container} autoComplete="off">
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <TextField
                    required
                    id="number"
                    name="product_number"
                    label={getString().product_number}
                    type="text"
                    value={this.state.product_number}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
              {this.props.noBillable !== true ? (
                <GridContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isBillable}
                        onChange={(e) => {
                          this.setState({ isBillable: !this.state.isBillable });
                        }}
                        value={this.state.isBillable}
                      />
                    }
                    label={getString().billable}
                  />
                </GridContainer>
              ) : null}
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  addForm = (prodType, classes) => {
    if (prodType === "checkedInventory") {
      return this.AddStock(classes);
    } else if (prodType === "checkedProduct") {
      return this.AddProduct(classes);
    } else {
      return <div>{getString().one_of_product}</div>;
      return <div>{getString().one_of_product}</div>;
    }
  };

  render() {
    const { classes } = this.props;
    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let caller_state = this.state.callerState;
      caller_state.addProduct = false;

      return (
        <ChildComponent
          state={caller_state}
          returningProps={this.state.callerProps}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card className={"lecketInlineItems"}>
                <CardHeader color="info">
                  <h5>
                    {getString().add_product + " or " + getString().add_stock}
                  </h5>
                </CardHeader>

                <CardBody>
                  <form className={classes.container} autoComplete="off">
                    <GridContainer>
                      <GridItem xs={6} sm={12} md={12}>
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                          <FormLabel
                              component="legend"
                              style={{ marginTop: 5 + "px" }}
                          >
                            {getString().add_to}
                          </FormLabel>
                          <RadioGroup
                              aria-label={getString().add_to}
                              name="prodType"
                              className={classes.group}
                              value={this.state.prodType}
                              onChange={(e) => {
                                this.handleChangeRadioBtn(e);
                              }}
                              row
                          >
                            <FormControlLabel
                                value="one-off"
                                control={<Radio />}
                                label={getString().one_time_sale}
                            />
                            <FormControlLabel
                                value="checkedInventory"
                                control={<Radio />}
                                label={getString().inventory}
                            />
                            <FormControlLabel
                                value="checkedProduct"
                                control={<Radio />}
                                label={getString().products}
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={3}>
                        <TextField
                          required
                          id="product"
                          name="product"
                          label={getString().product}
                          type="text"
                          value={this.state.product}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={3}>
                        <TextField
                          required
                          id="unit_price"
                          name="unit_price"
                          label={getString().unit_price}
                          type="text"
                          value={this.state.unit_price}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={3}>
                        <TextField
                          required
                          id="quantity"
                          name="quantity"
                          label={getString().quantity}
                          type="number"
                          value={this.state.quantity}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={3}>
                        <div style={{ marginTop: -9 + "px" }}>
                          <label>{getString().category}*</label>
                          <Creatable
                            defaultOptions
                            name="category"
                            id="category"
                            value={{
                              label: this.state.category,
                              value: this.state.category,
                            }}
                            onChange={(opt) => this.handleCategoryChange(opt)}
                            options={this.state.categories}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={6} sm={12} md={12}>
                        {this.addForm(this.state.prodType, classes)}
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
                <CardFooter>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      xs={12}
                      sm={6}
                      md={2}
                      color="danger"
                      onClick={this.cancelFunct.bind(this)}
                      style={{
                        backgroundColor: "#f44336",
                        margin: ".3125rem 1px",
                        minWidth: "auto",
                        minHeight: "auto",
                        fontSize: "12px",
                        color: "#ffffff",
                        padding: "12px 30px",
                        borderRadius: "3px",
                        verticalAlign: "middle",
                      }}
                    >
                      {getString().cancel}
                    </Button>
                    <Button
                      xs={12}
                      sm={6}
                      md={2}
                      color="success"
                      onClick={this.saveData.bind(this)}
                      style={{
                        backgroundColor: "#4caf50",
                        margin: ".3125rem 1px",
                        minWidth: "auto",
                        minHeight: "auto",
                        fontSize: "12px",
                        color: "#ffffff",
                        padding: "12px 30px",
                        borderRadius: "3px",
                        verticalAlign: "middle",
                      }}
                    >
                      {getString().add}
                    </Button>
                  </GridItem>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

Add_Stock_Product.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Add_Stock_Product);
