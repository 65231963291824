import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import StockTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import StockDetails from "components/afrijula/AllDetails/StockDetails.jsx";
import Stock from "components/afrijula/Stocks/Stock.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import {numberFormat, currencyFormat, dateFormat,compileQuery, applyPackaging,getString} from "assets/js/utils/utils.js";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";


class FastMovingStock extends React.Component {

   

    state = this.props.state || {
        value: 0,
        stockdetails: false,
        addstock: false,

        transferstock: false,

        openFirst:false,

        open: true,

        receivedStock:this.props.rowData,

        stockData:[],

        data: [],
        header:  [
            {title: getString().name, field:'name'},
            {title: getString().category,field:'category'},
            {title: getString().quantity,field:'quantity'},
            {title: getString().unit_price,field:'unit_price',options: {filter: false},render: rowData => currencyFormat(rowData.unit_price)},
            {title: getString().sold, field:'amount_sold'}
        ],
        backButton: false,

    };

    loadData = (query,resolve,reject) => {
        let url = compileQuery(query, (baseUrl + "/afrijula/stocks/stock/fast"),
            [{"by":"asc","attr":"name"}],
            "Afrijula::Stocks::Stock",null,null);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    getProps = () =>{
        return this.props.returningProps || this.props;
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    addStock = () => {
        this.setState({addstock: !this.state.addstock});
    };

    update = () => {
        let data = this.state.receivedStock;
        let thedata = this.state.data;
        thedata.push(    { name: data.name , category: data.category, location: data.location, quantitystock: data.quantitystock, reorderlevel: data.reorderlevel, quantityperunit: data.quantityperunit , unitprice: data.unitprice }
        );
        this.setState({data: thedata});
    };

    stocktransfer = ()=>{
        this.setState({transferstock: true});
    };

    rowSelect = (event, colData)  => {

        let id = colData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/stocks/stock/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({stockData: result,stockdetails:true});
            }
        });
    };

    stockBack = () => {
        this.setState({backButton: true})
    };

    render() {
        const { classes } = this.getProps();


        if(this.state.stockdetails){
            this.state.stockdetails = false;
            let data = this.state.stockData;
            this.state.stockData= [];
            return <StockDetails stockData={data} callerProps = {this.getProps()} callerState = {this.state} callerName = {FastMovingStock} editStock={false}/>;
        }
        if(this.state.backButton){
            return <Stock />
        }

        else{
            return (
                <div style={{marginTop:-50}}>
                    {this.update}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={3}>
                                        </GridItem>
                                        <GridItem xs={3}>
                                        </GridItem>
                                        <GridItem xs={3}>
                                        </GridItem>
                                        <GridItem xs={3} >
                                            <Button color="danger"   style={{float: 'right'}} onClick={this.stockBack} >{getString().back}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="warning">
                                    <h3 className={classes.cardTitleWhite}>{getString().stock}</h3>

                                </CardHeader>
                                <StockTable
                                    tableRef={this.tableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadData(query,resolve,reject);
                                    })}
                                    columns={this.state.header}
                                    onRowClick={this.rowSelect}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:15
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        },
                                        {
                                            tooltip: 'Remove Selected sale',icon: 'delete',
                                            onClick: (row, rows) => alert('This si to be implemented ' + rows.length + ' rows')
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

FastMovingStock.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(FastMovingStock);
