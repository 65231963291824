import React from "react";
import GridItem from "components/common/Grid/GridItem.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import DownloadButton from '@material-ui/core/Button';
import MyButton from '@material-ui/core/Button';
import {currencyFormat} from "assets/js/utils/utils.js";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../common/Grid/GridContainer";
import SaveButton from "../CustomComponents/SaveButton";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "../../common/Card/Card";
import UploadButton from "../CustomComponents/UploadButton";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const";
import $ from "jquery";
import {displayError, displaySuccess, getString, getToday} from "../../../assets/js/utils/utils";
import CardHeader from "../../common/Card/CardHeader";
import CardBody from "../../common/Card/CardBody";
import Paper from "@material-ui/core/Paper/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import ReportsTable from '@material-ui/core/Table';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

class LoanRequirements extends React.Component {

    // state = this.props.state || {
    state = {
        saveDisabled: false,
        applyDisabled: false,
        addBankAccount: false,
        backToLoans: false,
        callerName: this.props.callerName,
        callerState: this.props.callerState,
        nameData: null,
        callerProps: this.props.callerProps,
        requirements: {},
        clonedRequirements: [],
        product: {},
        uploadedFiles: [],
        reports: [],
        application_form_refNo: '',
        guarantor_form_refNo: '',
        reqDocs: null,
        reportList: [
            {
                name: "Profit and Loss",
                start_date: getToday(),
                end_date: getToday(),
                report: 'income_vs_expense',
                orgId: cookies.load("orgId"),
                profileId: cookies.load('profileId'),
                selected: false
            },
            {
                name: "Asset Register",
                start_date: getToday(),
                end_date: getToday(),
                report: 'assets_report',
                orgId: cookies.load("orgId"),
                profileId: cookies.load('profileId'),
                selected: false
            },
            {
                name: "Cash book",
                start_date: getToday(),
                end_date: getToday(),
                report: 'cashbook_report',
                orgId: cookies.load("orgId"),
                profileId: cookies.load('profileId'),
                selected: false
            }
        ],
        requiredFilesCount: 0,
        selectedRequiredFiles: 0,
        requiredFiles: [],

    };

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    addUploadedFile = (name, reference, file_name) => {
        let temp = [...this.state.uploadedFiles];
        temp.push({name: name, file_reference: reference, file_name: file_name});

        this.setState({uploadedFiles: temp});
    };

    getButton(name, fileName = null, refNo = null) {
        return (
            <UploadButton requiredDoc={name} refNo={refNo} uploadedFile={fileName}
                          setUploadedFile={this.addUploadedFile} removeUpload={this.removeUpload}/>
        )
    };

    removeUpload = (doc) => {
        let _this = this;
        let file = this.state.uploadedFiles.filter(f => f.name === doc)[0];
        $.ajax({
            method: "DELETE",
            url: baseUrl + "/afrijula/loans/loan_documents/" + file.file_reference,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            data: {reference: file.file_reference},
            success: function (result) {
                displaySuccess(result.success);
                let temp = [..._this.state.uploadedFiles];
                let _temp = temp.filter(file => file.name !== doc);
                this.setState({
                    uploadedFiles: _temp
                })
            }.bind(this),
            error: function (error) {
                displayError(error.responseText);
            }
        });
    };

    handleChange = (e) => {
        let reports = this.state.reports;
        reports[e.target.name] = e.target.value;
        this.setState({
            reports: reports
        });
    };

    handleChangeLoan = (e) => {
        let _product = {...this.state.product};
        _product[e.target.name] = e.target.value;
        this.setState({
            product: _product
        });
    };

    getAmount = (amount) => {
        return amount.cents / 100;
    };

    isQualified = () => {
        let qualified = [];
        this.state.clonedRequirements.length > 0 && this.state.clonedRequirements.map(r => {
            if (r.selected === false && r.isRequired === "Required")
                qualified.push({...r});
        });
        return qualified;
    };

    saveValidation = (type) => {
        let _product = {...this.state.product};
        if(parseFloat(_product.loan_amount) < parseFloat(this.getAmount(_product.minimum_amount)) || _product.loan_amount > parseFloat(this.getAmount(_product.maximum_amount))) {
            displayError("Please specify a valid amount, amount should be between the minimum and the maximum amount");
            document.getElementById("loan_amount").focus();
            return false;
        } else if (type !== "Unfinished") {
            if (!_product.loan_amount) {
                displayError("Please specify the loan amount");
                document.getElementById("loan_amount").focus();
                return false;
            } else if (!_product.applicant_age) {
                displayError("Please specify the applicant's age");
                document.getElementById("applicant_age").focus();
                return false;
            } else if (!_product.age_of_business) {
                displayError("Please specify the age of your business");
                document.getElementById("age_of_business").focus();
                return false;
            } else if (this.isQualified().length > 0) {
                // this.state.uploadedFiles.length < this.state.callerState.selectedProduct.required_documents.length){
                // displayError("Please upload all required documents for this loan.");
                displayError("We are sorry! You do not have all the required documents to qualify for this loan application.");
                return false;
            } else if (this.state.reports.length <= 0) {
                displayError("Please specify reports to send");
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    handleDisable = (type) =>{
        if (type === "Unfinished") {
            this.setState({saveDisabled: true});
        } else {
            this.setState({applyDisabled: true});
        }
    };

    handleEnable = (type) =>{
        if (type === "Unfinished") {
            this.setState({saveDisabled: false,});
        } else {
            this.setState({applyDisabled: false});
        }
    };

    saveApplication = (type) => {
        this.handleDisable(type);
        let correctVal = this.saveValidation(type);
        if (correctVal) {
            let _this = this;
            let _product = {...this.state.product};
            let temp = [];
            // let isQualified = this.state.clonedRequirements.filter(r => r.selected === false && r.isRequired === true);
            // let isQualified = [];
            // this.state.clonedRequirements.map(r => {
            //     if(r.selected === false && r.isRequired === "Required")
            //         isQualified.push({...r});
            // });

            if (!(type !== "Unfinished" && this.state.reports.length <= 0)) {
                let _temp = this.state.reports.filter(rep => rep.selected !== false);
                _temp.forEach(rep => temp.push({
                    report: rep.report,
                    name: rep.name,
                    model: 'Organization',
                    profileId: cookies.load('profileId'),
                    organization_id: cookies.load("orgId"),
                    start_date: rep.start_date,
                    end_date: rep.end_date,
                    orgName: cookies.load('orgName'),
                }));
            }
            for (let i = 0; i < temp.length - 1; i++) {
                for (let j = i + 1; j < temp.length; j++) {
                    if (temp[i].name === temp[j].name) {
                        temp.splice(i, 1);
                    }
                }
            }

            let data = {
                productId: this.state.callerState.selectedProduct._id.$oid,
                reports: [...new Set(temp)],
                docs: this.state.clonedRequirements,
                loanAmount: _product.loan_amount,
                fsp: _product.fsp,
                ageOfBusiness: _product.age_of_business,
                applicantAge: _product.applicant_age,
                orgName: cookies.load('orgName'),
                orgContact: cookies.load('orgContact'),
                orgEmail: cookies.load('orgEmail'),
                orgId: cookies.load('orgId'),
                status: type,
                id: _product.loan_id,
                request: type === "Unfinished" ? "saved" : "submitted"
            };

            let msg = type === "Unfinished" ? "You have successfully saved the loan application" : "You have successfully applied for this loan product";
            let _method = _product.loan_id ? "PUT" : "POST";
            let id = _product.loan_id ? _product.loan_id : '';
            let url = baseUrl + "/afrijula/loans/loans/" + id;

            $.ajax({
                method: _method,
                url: url,
                dataType: "json",
                headers: {
                    "Authorization": "token " + cookies.load("token"),
                    "UserProfile": cookies.load("profile"),
                    "UserKey": cookies.load('User-Key'),
                },
                data: data,
                success: function (result) {
                    displaySuccess(msg);
                    _this.handleEnable(type);
                    _this.close();
                    // if(_method === "POST" && type === "Pending"){
                    //     _this.sendEmail();
                    // }
                }.bind(this),
                error: function (error) {
                    displayError(error.responseText);
                    _this.handleEnable(type);
                }
            });

        } else {
            this.handleEnable(type);
        }
    };

    back = () => {
        this.setState({backToLoans: true});
    };

    close = () => {
        this.setState({
            id: null,
            backToLoans: true
        });
        if (this.props.closeInvoice) {
            this.props.closeInvoice()
        }
        if (this.props.closeEdit) {
            this.props.closeEdit()
        }
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    selectReport = (report) => {
        let reps = [...this.state.reports];
        if (report.selected === false) {
            report.selected = true;
            reps.push(report);
        } else {
            report.selected = false;
            let index = reps.indexOf(report);
            if (index !== -1) {
                reps[index] = report
            }
        }
        let temp = reps.filter(rep => rep.selected !== false);
        this.setState({reports: temp});
    };

    downloadDoc = (type) => {
        let ref_number = type === "application" ? this.state.application_form_refNo : this.state.guarantor_form_refNo;
        let fileName = null;
        if (ref_number) {
            fetch(baseUrl + "/afrijula/loans/loan_documents/download?ref_number=" + ref_number, {
                method: 'GET',
                headers: {
                    "Authorization": "token " + cookies.load("token"),
                    "UserProfile": cookies.load("profile"),
                    "UserKey": cookies.load('User-Key'),
                },
            })
                .then((response) => {
                    fileName = response.headers.get("File-Name");
                    return response.blob()
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${fileName}`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        } else {
            displayError("The form does not exist");
        }
    };

    getUploadedDocs = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loan_documents",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            data: {loan_id: this.state.callerState.loan._id.$oid},
            success: function (result) {
                let product = _this.state.callerState.selectedProduct;
                if (_this.state.callerState.loan) {
                    product.loan_id = _this.state.callerState.loan._id.$oid;
                    product.loan_amount = _this.state.callerState.loan.amount ? _this.state.callerState.loan.amount.cents / 100 : '';
                    product.applicant_age = _this.state.callerState.loan.applicant_age;
                    product.age_of_business = _this.state.callerState.loan.age_of_business;
                }
                let reqs = [];
                let _uploadedFiles = [];
                let required_docs = [..._this.state.callerState.selectedProduct.required_documents];
                required_docs.forEach(doc => {
                    for (let i = 0; i < result.loan_docs.length; i++) {
                        if (result.loan_docs[i].name === doc) {
                            reqs.push({
                                id: result.loan_docs[i]._id,
                                name: doc,
                                upload: _this.getButton(doc, result.loan_docs[i].doc_name, result.loan_docs[i].reference_number)
                            });
                            _uploadedFiles.push({
                                name: result.loan_docs[i].name,
                                file_reference: result.loan_docs[i].reference_number,
                                file_name: result.loan_docs[i].doc_name
                            });
                        }
                    }
                });
                for (let i = 0; i < required_docs.length; i++) {
                    let exist = false;
                    let j = 0;
                    for (; j < _uploadedFiles.length; j++) {
                        if (_uploadedFiles[j].name === required_docs[i]) {
                            exist = true;
                        }
                    }
                    if (!exist) {
                        reqs.push({id: i, name: required_docs[i], upload: _this.getButton(required_docs[i])});
                    }
                }

                if (result.loan_reports.length > 0) {
                    result.loan_reports.forEach(rep => rep.selected = true);
                }
                let requirements = {
                    loan_name: product.name,
                    reports: result.loan_reports.length > 0 ? result.loan_reports : _this.state.reportList,
                    reqs: reqs
                };

                let temp = [];
                for (let i = 0; i < _this.state.reportList.length; i++) {
                    let exist = false;
                    let j = 0;
                    for (; j < result.loan_reports.length; j++) {
                        if (result.loan_reports[j].name === _this.state.reportList[i].name) {
                            exist = true;
                        }
                    }
                    if (!exist) {
                        temp.push(_this.state.reportList[i]);
                    }
                }
                if (temp.length > 0) {
                    for (let i = 0; i < temp.length; i++) {
                        temp[i].selected = false;
                        result.loan_reports.push(temp[i]);
                    }
                }

                this.setState({
                    requirements: requirements,
                    product: product,
                    reports: result.loan_reports.length > 0 ? result.loan_reports : [],
                    uploadedFiles: _uploadedFiles
                })
            }.bind(this),
            error: function (error) {

            }
        });
    };

    getForms = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loan_documents/" + this.state.callerState.selectedProduct._id.$oid,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            success: function (result) {
                if (result.loan_forms.length > 0 && (result.loan_forms[0].name === "application_form" || result.loan_forms[0].name === "guarantor_form")) {
                    this.setState({
                        application_form_refNo: result.loan_forms.filter(f => f.name === "application_form")[0].reference_number,
                        guarantor_form_refNo: result.loan_forms.filter(f => f.name === "guarantor_form")[0].reference_number
                    })
                }
            }.bind(this),
            error: function (error) {
                displayError(error.responseText);
            }
        });
    };

    _isObject = data => typeof data === "object";

    sendEmail = () => {
        let _body = `Dear ${this.state.product.fsp} \n` +
            `You have a new loan application from ${cookies.load("orgName")}, applying for the ${this.state.product.name} loan.\n` +
            `Please login to your account to review application.\n` +
            `Thank you.`;
        let _subject = "New Loan Application";
        // let _recipient = this.state.product.fsp_email;
        let _recipient = "anget@insistglobal.com";

        $.ajax({
            method: "POST",
            // url: baseUrl + "/mail_v2",
            url: baseUrl + "https://api.lecket.gm/mail_v2",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {to: _recipient, subject: _subject, body: _body, domain: 'afrijula.gm'},

            success: function (result) {

            }, error: function (resp) {
                displayError(resp.responseText);
            }
        })
    };

    handleChangeDate = (e, name) => {
        let _requirements = {...this.state.requirements};
        let temp = [..._requirements.reports];

        let report = temp.filter(rep => rep.name === name)[0];
        report[e.target.name] = e.target.value;
        let index = temp.indexOf(report);
        if (index !== -1) {
            temp[index] = report
        }
        _requirements.reports = temp;

        this.setState({requirements: _requirements});
    };

    componentDidMount() {
        this.getForms(); // Loan Product Application forms

        if (this.state.callerState.loan || this.state.callerState.selectedProduct.required_documents) {
            let reqs = [];
            let i = 1;
            let _requiredCount = 0;
            let product = this.state.callerState.loan ? this.state.callerState.loan : this.state.callerState.selectedProduct;
            product.required_documents.map(doc => {
                if (doc.status === "Required") {
                    _requiredCount++;
                }
                let _selected = false;
                if (this.state.callerState.loan && doc.selected === "true") {
                    _selected = true
                }
                reqs.push({id: i, name: doc.doc_name, isRequired: doc.status, selected: _selected});
                i = i + 1;
            });

            let requirements = {
                loan_name: product.name,
                reports: this.state.reportList,
                reqs: reqs
            };

            let _clonedRequirements = [...reqs];
            let _selectedRequiredFiles = null;
            if (this.state.callerState.loan) {
                _selectedRequiredFiles = reqs.filter(v => v.selected === true && v.isRequired === "Required").length;
                product.loan_id = this.state.callerState.loan._id.$oid;
            }
            this.setState({
                requirements: requirements,
                product: this.state.callerState.loan ? {...product, loan_amount: product.amount? product.amount.cents / 100 : null} : product,
                clonedRequirements: _clonedRequirements,
                requiredFilesCount: _requiredCount,
                selectedRequiredFiles: _selectedRequiredFiles
            })
        }
    };

    handleChangeRequirements = (name, id) => {
        let _selectedRequiredFiles = this.state.selectedRequiredFiles;
        let index = id - 1;
        let _reqs = [...this.state.clonedRequirements];
        let req = _reqs[index];
        req.selected = !req.selected;
        if (req.isRequired === "Required" && req.selected === true) {
            _selectedRequiredFiles += 1;
        } else if (req.isRequired === "Required" && req.selected === false) {
            _selectedRequiredFiles -= 1;
        }

        _reqs[index] = req;
        this.setState({clonedRequirements: _reqs, selectedRequiredFiles: _selectedRequiredFiles});
    };

    render() {
        const {classes} = this.props;
        let caller_state = this.state.callerState;

        if (this.state.backToLoans) {
            let ChildComponent = this.state.callerName;
            caller_state.applyLoan = false;
            return <ChildComponent state={caller_state} returningProps={this.state.callerProps}/>
        }

        let collMsg = "";
        let coll = this.state.requirements.reqs && this.state.requirements.reqs.filter(d => d.name === "Collateral and collateral substitutes")[0];
        collMsg = coll && coll.isRequired === "Required" ? "THIS LOAN REQUIRES YOU TO PROVIDE A COLLATERAL" : "";
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                    <h4>Loan Requirements</h4>
                    <div><i className="fa fa-cloud-download"></i>
                        <DownloadButton color="primary" onClick={() => this.downloadDoc("application")}>download
                            application form</DownloadButton>
                    </div>
                    <div><i className="fa fa-cloud-download"></i>
                        <DownloadButton color="primary" onClick={() => this.downloadDoc("guarantor")}>download guarantor
                            form</DownloadButton>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="success">
                            <h5>Loan Description</h5>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b> {getString()._name}:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.product.name}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Administration Fee:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{currencyFormat(this.state.product.administration_fee)}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Maximum Amount:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{currencyFormat(this.state.product.maximum_amount)}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Minimum Amount:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{currencyFormat(this.state.product.minimum_amount)}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Interest Rate:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.product.interest_rate} (%) {this.state.product.interest_rate_frequency}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Penalty:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.product.penalty} (%)</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Processing Time:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.product.processing_time} (hrs)</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Repayment Frequency:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.product.repayment_frequency}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Loan Tenor:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.product.tenor} (Months)</i></p>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="warning">
                            <h5>Eligibility Criteria</h5>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Business Location:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.callerState.selectedProduct.business_location.join()}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Legal Status:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.callerState.selectedProduct.legal_status.join()}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Nationality:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}><p>
                                        <i>{this.state.callerState.selectedProduct.nationality}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Minimum Age:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}><p>
                                        <i>{this.state.callerState.selectedProduct.minimum_age}(Years)</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Payment Start Date:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.callerState.selectedProduct.payment_start_date}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><b>Loan Purpose:</b></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p><i>{this.state.callerState.selectedProduct.purpose.join()}</i></p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p><b>{collMsg}*</b></p>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem sm={12} md={12}>
                    <Card style={{padding: 7}}>
                        <table style={{fontFamily: "arial, sansSerif", borderCollapse: "collapse", width: "100%",}}>
                            <tbody>
                            <tr>
                                <td>
                                    <TextField
                                        id="loan_amount"
                                        name="loan_amount"
                                        label="Loan Amount"
                                        className={classes.textField}
                                        value={this.state.product.loan_amount}
                                        onChange={(e) => {
                                            this.handleChangeLoan(e)
                                        }}
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        id="applicant_age"
                                        name="applicant_age"
                                        label="Age of Applicant"
                                        className={classes.textField}
                                        value={this.state.product.applicant_age}
                                        onChange={(e) => {
                                            this.handleChangeLoan(e)
                                        }}
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        id="age_of_business"
                                        name="age_of_business"
                                        label="Age of Business"
                                        className={classes.textField}
                                        value={this.state.product.age_of_business}
                                        onChange={(e) => {
                                            this.handleChangeLoan(e)
                                        }}
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={8}>
                    <Card style={{padding: 7}}>
                       {/* <p>You must provide one of the following</p>
                        {
                            this.state.requirements.reqs && this.state.requirements.reqs.map(d => {
                                if (d.name === "Business Registration" || d.name === "Trade License" || d.name === "Duty Receipts"){
                                    return (
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={4}>
                                                {d.name}
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <Checkbox key={d.id} name={d.name} onChange={() =>this.handleChangeRequirements(d.name, d.id)} checked={this.state.clonedRequirements.filter( v => v.name === d.name)[0].selected}/>
                                            </GridItem>
                                        </GridContainer>
                                    );
                                }

                            })
                        }
                        <hr/>*/}
                        <p>Which of the following required documents do you have?</p><br /><br />
                        {
                            this.state.requirements.reqs && this.state.requirements.reqs.map(d => {
                                // if (d.name !== "Business Registration" && d.name !== "Trade License" && d.name !== "Duty Receipts" && d.name !== "Collateral" && d.name !== "Collateral and collateral substitutes") {
                                    return (
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={4}>
                                                {d.name}
                                            </GridItem>
                                            <GridItem xs={12} sm={6}
                                                      md={4}><span>{d.isRequired === "Required" ? `${d.isRequired}*` : d.isRequired}</span></GridItem>
                                            <GridItem xs={12} sm={6} md={4}>
                                                <Checkbox key={d.id} name={d.name}
                                                          onChange={() => this.handleChangeRequirements(d.name, d.id)}
                                                          checked={this.state.clonedRequirements.filter(v => v.name === d.name)[0].selected}/>
                                            </GridItem>
                                        </GridContainer>
                                    );
                                // }
                            })
                        }
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card style={{padding: 7}}>
                        <p style={{color: "blue"}}>{`${this.state.selectedRequiredFiles} out of ${this.state.requiredFilesCount} requirements selected`}</p>
                        <p style={{color: this.state.selectedRequiredFiles < this.state.requiredFilesCount ? "red" : "green"}}>{this.state.selectedRequiredFiles < this.state.requiredFilesCount ? "We are sorry, you are not Qualified to apply for this loan!" : "You are Qualified to apply for this loan!"}</p>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Paper className={classes.paper}>
                        <ReportsTable className={classes.table} size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{getString().afrijula_reports}</TableCell>
                                    <TableCell>{getString().start_date}</TableCell>
                                    <TableCell>{getString().end_date}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.requirements.reports && this.state.requirements.reports.map(report => {
                                        return (
                                            <TableRow key={report.name}
                                                      style={{backgroundColor: report.selected === true ? "#ccc" : ""}}>
                                                <TableCell>{report.name}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        required
                                                        id="start_date"
                                                        name="start_date"
                                                        title={getString().date}
                                                        className={classes.textField}
                                                        value={report.start_date}
                                                        onChange={(e) => {
                                                            this.handleChangeDate(e, report.name)
                                                        }}
                                                        type="date"
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        required
                                                        id="end_date"
                                                        name="end_date"
                                                        title={getString().date}
                                                        className={classes.textField}
                                                        value={report.end_date}
                                                        onChange={(e) => {
                                                            this.handleChangeDate(e, report.name)
                                                        }}
                                                        type="date"
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <MyButton
                                                        color={(report.selected) ? "secondary" : "primary"}
                                                        variant="contained"
                                                        style={{textTransform: 'none'}}
                                                        size="small"
                                                        onClick={() => this.selectReport(report)}
                                                    >
                                                        {(report.selected) ? "Remove" : "Add"}
                                                    </MyButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </ReportsTable>
                    </Paper>
                </GridItem>
                <Button xs={12} sm={6} md={2} color="danger" onClick={this.close.bind(this)}
                        style={{
                            backgroundColor: "#f44336",
                            marginLeft: "120px",
                            margin: ".3125rem 1px",
                            minWidth: "auto",
                            minHeight: "auto",
                            fontSize: "12px",
                            color: "#ffffff",
                            padding: "12px 30px",
                            borderRadius: "3px",
                            verticalAlign: "middle"
                        }}
                >CANCEL
                </Button>
                <Button xs={12} sm={6} md={2} color="info" onClick={() => this.saveApplication("Unfinished")}
                        disabled={this.state.saveDisabled}
                        style={{backgroundColor: "#00acc1", marginLeft: "120px", margin: ".3125rem 1px",
                            minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff",
                            padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                        }}>Save
                </Button>
                <Button xs={12} sm={6} md={2} color="success" onClick={() => this.saveApplication("Pending")}
                        disabled={this.state.applyDisabled || this.isQualified().length > 0}
                        style={{backgroundColor: "#4caf50", marginLeft: "120px", margin: ".3125rem 1px",
                            minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff",
                            padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                        }}>Apply
                </Button>
                {/*<SaveButton style={{backgroundColor: "#f44336 !important"}}*/}
                {/*            method={() => this.saveApplication("Unfinished")}*/}
                {/*            title="Save" saveProcess={true}/>*/}
                {/*<SaveButton style={{backgroundColor: "#f44336 !important"}}*/}
                {/*            method={() => this.saveApplication("Pending")}*/}
                {/*            title="Apply" isValid={this.isQualified().length > 0}/>*/}
            </GridContainer>
        );

        document.getElementById('download').click();
    }
}

export default withStyles(dashboardStyle)(LoanRequirements);
