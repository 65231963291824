import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import AddUser from "components/afrijula/Settings/Add_User.jsx"

import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import Table from "material-table";
import {getString} from "assets/js/utils/utils.js";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class Add_User extends React.Component {
    constructor(props){
        super(props);
        this.isValidated = this.isValidated.bind(this);
        this.tableRef = React.createRef();
    }

    isValidated =() =>  {
        return false; // make it return false. just in case. lol
    };

   

    state = {

        header:  [
            {title: getString().first_name, field: "first_name"},
            {title: getString().last_name, field: "last_name"},
            {title: getString().email , field: "email"},
            {title: getString().type , field: "type", render: rowData => rowData.type.substring(20)},
        ],
        account_lists: [],
    };

    saveBack= () => {
        this.refresh();
    };

    loadUsers = (query,resolve,reject) => {

        fetch(baseUrl + "/account/users/", {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result,
                    page: query.page,
                    totalCount: result.count
                })
            });
    };

    refresh = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    render() {
        const { classes } = this.props;



        return (

            <div>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <AddUser saveCback={this.saveBack}></AddUser>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <Table
                                tableRef={this.tableRef}
                                title={getString().user_acc}
                                data={query=>new Promise((resolve,reject) => {
                                    this.loadUsers(query,resolve,reject);
                                })}
                                columns={this.state.header}
                                options={{paging:false}}
                                actions={[
                                    {
                                        icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                        onClick: () => this.refresh(),
                                    }
                                ]

                                }/>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

Add_User.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object,
    validate: PropTypes.func,
    isValid: PropTypes.func,
    handleValidation: PropTypes.func,
    getValidationMessages: PropTypes.func,
    clearValidations: PropTypes.func,
    getStore: PropTypes.func,
    updateStore: PropTypes.func
};

export default withStyles(styles)(Add_User);
