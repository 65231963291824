import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardContent from "components/common/Card/CardBody.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import ShowDetails from "components/afrijula/AllDetails/ReceiptTemplate.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {
  currencyFormat,
  dateFormat,
  compileQuery,
  showReportUtility,
  getString,
  canSeeDash,
  displaySuccess,
  displayError,
} from "assets/js/utils/utils.js";
import DeleteRequests from "components/afrijula/Sales/DeleteRequests";
import Rodal from "rodal";
import Link from "@material-ui/core/Link";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FormControl from "@material-ui/core/FormControl/FormControl";
import HelpUtil from "components/afrijula/Utils/HelpUtil";
import ProposedSalesReturn from "./ProposedSalesReturn";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardCategory: {
    color: "#000",
    margin: "0px",
    fontSize: "14px",
    fontWeight: "bolder",
    paddingTop: "4px",
  },
});

class Sales extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    tag: this.props.tag,
    show_details: false,

    salesData: null,

    lastPage: null,
    recalledPage: null,
    lastSort: null,
    showDailySales: false,
    showDeleteReqs: false,
    showReversalReqs: false,
    showWeeklySales: false,
    showMonthlySales: false,
    showDailyInvoice: false,

    tilesData: {
      daily_sales: 0.0,
      weekly_sales: 0.0,
      monthly_sales: 0.0,
      daily_invoice: 0.0,
    },

    payment: true,
    globalFilter: "none",
    reversalRequestData: [],
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  format = (num) => {
    return `${num.toFixed(2)}`;
  };

  rowSelect = async (event, colData) => {
    let id = colData._id.$oid;

    let _this = this;

    await $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/sales/" + id,
      data: { filter: "installments" },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        if (_this.props.updateMe) _this.props.updateMe(false);
        _this.setState({ salesData: result });
      },
    });
  };

  recallSort = () => {
    const order = this.state.lastSort;
    this.state.lastSort = null;
    return order;
  };

  removeProcessedRequest = (id) => {
      let prev = this.state.reversalRequestData;
      let newData = prev.filter((temp) => {return temp._id.$oid !== id;});
      this.setState({ reversalRequestData: newData });
  };

  rejectReversalOfSale = (saleId) => {
        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/sales/sales/update/",
            dataType: "json",
            headers: {
                Authorization: "token " + cookies.load("token"),
                UserProfile: cookies.load("profile"),
                UserKey: cookies.load("User-Key"),
            },
            data: { id: saleId, reverse_rejected: true },

            success: function(result) {
                displaySuccess(result.success, "success");
                _this.removeProcessedRequest(saleId);
                _this.setState({ showReversalReqs: false });
            },
            error: function(error) {
                displayError(error.responseText);
            },
        });
    };

  loadReversalRequests = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/sales/sales/sales_reversal_requests",
            dataType: "json",
            headers: {
                Authorization: "token " + cookies.load("token"),
                UserProfile: cookies.load("profile"),
                UserKey: cookies.load("User-Key"),
            },
            success: function(result) {
                _this.setState({ reversalRequestData: result.data });
            },
            error: function(error) {
                displayError(error.responseText);
            },
        });
    };

  acceptReversalOfSale = (saleId) => {
    let _this = this;
    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/sales/sales/update/",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { id: saleId, reverse: true },

      success: function(result) {
        displaySuccess(result.success, "success");
        _this.removeProcessedRequest(saleId);
        _this.setState({ showReversalReqs: false });
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  loadTilesData = (page) => {
    let _this = this;
    page |= 1;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        count: [
          "daily_sales",
          "weekly_sales",
          "monthly_sales",
          "daily_invoice",
        ],
        chart: [],
      },

      success: function(result) {
        let temp = _this.state.tilesData;
        let data = result;
        temp.daily_sales = data.counts.daily_sales;
        temp.weekly_sales = data.counts.weekly_sales;
        temp.monthly_sales = data.counts.monthly_sales;
        temp.daily_invoice = data.counts.daily_invoice;

        this.setState({ tilesData: temp });
      }.bind(this),
    });
  };

  loadSalesTable = (query, resolve, reject) => {
    let includes =
      this.state.tag === "contract" || this.state.tag == "invoice"
        ? {
            include:
              "{customer: {only: :name},invoice: {only: [:status,:balance]}}",
          }
        : null;
    let enhanceSearch =
      this.state.tag === "contract" || this.state.tag === "invoice"
        ? [{ model: "Afrijula::Sales::Customer", assoc: "customer_id" }]
        : null;
    let filter = "tran_type: '" + this.state.tag + "', reversed: {'$ne':true}";
    if (this.state.globalFilter === "outstanding") {
      filter += ", 'invoice.balance.cents': {'$gt': 0}";
    } else if (this.state.globalFilter === "negative") {
      filter += ", 'invoice.balance.cents': {'$lt': 0}";
    }
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/sales/sales",
      [
        "date",
        "total",
        "tran_type",
        "id",
        "description",
        "labels",
        "reversed",
        "contract_closed",
        "tran_type",
      ],
      this.state.tag === "contract"
        ? [{ by: "asc", attr: "date" }]
        : [{ by: "desc", attr: "date" }],
      "Afrijula::Sales::Sale",
      includes,
      filter,
      enhanceSearch
    );
    this.state.lastPage = query.page;
    this.state.lastSort = query.orderBy;

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  componentDidUpdate() {
    if (this.state.tag !== (this.props.tag || this.props.state.tag)) {
      this.state.tag = this.props.tag || this.props.state.tag;
      this.loadTilesData();
      if (this.tableRef.current) this.tableRef.current.onQueryChange();
    }
  }

  tableRefresh = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  componentDidMount() {
    const props = this.getProps();

    if (props.tag) this.state.tag = props.tag;

    if (canSeeDash()) this.loadTilesData();
    if (props.updateMe) props.updateMe(true);

    // get user's type
    const userData = JSON.parse(localStorage.getItem("afrijula.insist.state"));
    const userType = userData.user.user_type;
    this.setState({ userType });
    this.loadReversalRequests();
  }

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  getHeader = () => {
    const props = this.getProps();

    let header = [
      {
        title: getString().type,
        field: "tran_type",
        render: (rowData) => {
          return rowData.tran_type.replace("_", " ");
        },
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().description,
        field: "description",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().date,
        field: "date",
        render: (rowData) => dateFormat(rowData.date),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ];
    if (this.props.returningProps === undefined) {
      if (props.tag === "contract" || props.tag === "invoice")
        header.splice(0, 1, {
          title: getString().customer,
          field: "customer",
          render: (rowData) => {
            return rowData.customer ? rowData.customer.name : "none";
          },
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        });
      if (props.tag !== "contract") {
        header.splice(header.length, 1, {
          title: getString().total,
          field: "total",
          render: (rowData) => currencyFormat(rowData.total),
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        });
      }
      if (props.tag === "contract" || props.tag === "invoice") {
        header.splice(header.length, 1, {
          title: getString().balance,
          field: "balance",
          render: (rowData) =>
            currencyFormat(rowData.invoice ? rowData.invoice.balance : 0.0),
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        });
      }
      if (props.tag === "contract") {
        header.splice(header.length, 1, {
          title: getString().status,
          field: "status",
          render: (rowData) => {
            return rowData.invoice ? rowData.invoice.status : "";
          },
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        });
      }
      header.splice(header.length, 1, {
        title: "",
        field: "labels",
        render: (rowData) => {
          return rowData.labels
            ? rowData.labels.map((label) => {
                return (
                  <div
                    style={{
                      backgroundColor: "#FFC300",
                      borderRadius: "5px",
                      color: "black",
                    }}
                  >
                    {label}
                  </div>
                );
              })
            : null;
        },
        filtering: false,
        cellStyle: { padding: "4px", width: "100px" },
        headerStyle: { padding: "4px" },
      });
    }
    return header;
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.salesData) {
      let data = this.state.salesData;
      this.state.salesData = null;
      this.state.recalledPage = this.state.lastPage;
      return (
        <ShowDetails
          callerProps={this.getProps()}
          callerState={this.state}
          salesData={data}
          callerName={Sales}
          loadReversalRequests={this.loadReversalRequests}
        />
      );
    } else {
      let header = this.getHeader();
      return (
        <div className={classes.root}>
          {canSeeDash() ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success" className="small-icon">
                      <Icon>money</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().daily_sales}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <GridContainer>
                      <GridItem>
                        <h5 className={classes.cardTitle}>
                          {currencyFormat(this.state.tilesData.daily_sales)}
                        </h5>
                      </GridItem>
                      <GridItem>
                        <div className={classes.stats}>
                          <span
                            onClick={() => {
                              let end_date = new Date();
                              let start_date = new Date(
                                end_date.getTime() - 60 * 60 * 24 * 1 * 1000
                              );
                              let params = {
                                organization_id: cookies.load("orgId"),
                                org_name: cookies.load("orgName"),
                                start_date: start_date,
                                end_date: end_date,
                              };
                              let inject = {
                                images: [
                                  {
                                    name: "image",
                                    attr: "image",
                                    id: cookies.load("orgId"),
                                    model: "Organization",
                                  },
                                ],
                              };
                              showReportUtility(
                                "Monthly Sales",
                                baseUrl +
                                  "/reports/1?url=/afrijula/*&profile=" +
                                  cookies.load("profileId"),
                                {
                                  params: params,
                                  sub:
                                    "&report=daily_sales_report&dynamic=true",
                                  inject: inject,
                                },
                                cookies,
                                [
                                  {
                                    type: "date",
                                    default: start_date,
                                    label: "From",
                                    name: "start_date",
                                  },
                                  {
                                    type: "date",
                                    default: end_date,
                                    label: "To",
                                    name: "end_date",
                                  },
                                ]
                              );
                            }}
                            style={{ cursor: "pointer", color: "#800080" }}
                          >
                            {getString().details}
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning" className="small-icon">
                      <Icon>money</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().weekly_sales}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <GridContainer>
                      <GridItem>
                        <h5 className={classes.cardTitle}>
                          {currencyFormat(this.state.tilesData.weekly_sales)}
                        </h5>
                      </GridItem>
                      <GridItem>
                        <div className={classes.stats}>
                          <span
                            onClick={() => {
                              let end_date = new Date();
                              let start_date = new Date(
                                end_date.getTime() - 60 * 60 * 24 * 7 * 1000
                              );
                              let params = {
                                organization_id: cookies.load("orgId"),
                                org_name: cookies.load("orgName"),
                                start_date: start_date,
                                end_date: end_date,
                              };
                              let inject = {
                                images: [
                                  {
                                    name: "image",
                                    attr: "image",
                                    id: cookies.load("orgId"),
                                    model: "Organization",
                                  },
                                ],
                              };
                              showReportUtility(
                                "Monthly Sales",
                                baseUrl +
                                  "/reports/1?url=/afrijula/*&profile=" +
                                  cookies.load("profileId"),
                                {
                                  params: params,
                                  sub:
                                    "&report=daily_sales_report&dynamic=true",
                                  inject: inject,
                                },
                                cookies,
                                [
                                  {
                                    type: "date",
                                    default: start_date,
                                    label: "From",
                                    name: "start_date",
                                  },
                                  {
                                    type: "date",
                                    default: end_date,
                                    label: "To",
                                    name: "end_date",
                                  },
                                ]
                              );
                            }}
                            style={{ cursor: "pointer", color: "#800080" }}
                          >
                            {getString().details}
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger" className="small-icon">
                      <Icon>money</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().monthly_sales}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <GridContainer>
                      <GridItem>
                        <h5 className={classes.cardTitle}>
                          {currencyFormat(this.state.tilesData.monthly_sales)}
                        </h5>
                      </GridItem>
                      <GridItem>
                        <div className={classes.stats}>
                          <span
                            onClick={() => {
                              let end_date = new Date();
                              let start_date = new Date(
                                end_date.getFullYear(),
                                end_date.getMonth() - 1,
                                end_date.getDate()
                              );
                              let params = {
                                organization_id: cookies.load("orgId"),
                                org_name: cookies.load("orgName"),
                                start_date: start_date,
                                end_date: end_date,
                              };
                              let inject = {
                                images: [
                                  {
                                    name: "image",
                                    attr: "image",
                                    id: cookies.load("orgId"),
                                    model: "Organization",
                                  },
                                ],
                              };
                              showReportUtility(
                                "Monthly Sales",
                                baseUrl +
                                  "/reports/1?url=/afrijula/*&profile=" +
                                  cookies.load("profileId"),
                                {
                                  params: params,
                                  sub:
                                    "&report=daily_sales_report&dynamic=true",
                                  inject: inject,
                                },
                                cookies,
                                [
                                  {
                                    type: "date",
                                    default: start_date,
                                    label: "From",
                                    name: "start_date",
                                  },
                                  {
                                    type: "date",
                                    default: end_date,
                                    label: "To",
                                    name: "end_date",
                                  },
                                ]
                              );
                            }}
                            style={{ cursor: "pointer", color: "#800080" }}
                          >
                            {getString().details}
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info" className="small-icon">
                      <Icon>notes</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().daily_invoice}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <GridContainer>
                      <GridItem>
                        <h5 className={classes.cardTitle}>
                          {currencyFormat(this.state.tilesData.daily_invoice)}
                        </h5>
                      </GridItem>
                      <GridItem>
                        <div className={classes.stats}>
                          <span
                            onClick={() => {
                              let end_date = new Date();
                              let start_date = new Date(
                                end_date.getFullYear(),
                                end_date.getMonth(),
                                end_date.getDate()
                              );
                              let params = {
                                organization_id: cookies.load("orgId"),
                                org_name: cookies.load("orgName"),
                                start_date: start_date,
                                end_date: end_date,
                              };
                              let inject = {
                                images: [
                                  {
                                    name: "image",
                                    attr: "image",
                                    id: cookies.load("orgId"),
                                    model: "Organization",
                                  },
                                ],
                              };

                              showReportUtility(
                                "Monthly Sales",
                                baseUrl +
                                  "/reports/1?url=/afrijula/*&profile=" +
                                  cookies.load("profileId"),
                                {
                                  params: params,
                                  sub: "&report=daily_invoice&dynamic=true",
                                  inject: inject,
                                },
                                cookies,
                                [
                                  {
                                    type: "date",
                                    default: start_date,
                                    label: "From",
                                    name: "start_date",
                                  },
                                  {
                                    type: "date",
                                    default: end_date,
                                    label: "To",
                                    name: "end_date",
                                  },
                                ]
                              );
                            }}
                            style={{ cursor: "pointer", color: "#800080" }}
                          >
                            {getString().details}
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem>
                <span style={{ display: "inline-block" }}>
                  {this.props.tag == "invoice" ? (
                    <FormControl>
                      <FormLabel>{getString().filter}</FormLabel>
                      <RadioGroup
                        row
                        value={this.state.globalFilter}
                        name="globalFilter"
                        onChange={(e) => {
                          this.setState({ globalFilter: e.target.value });
                          this.tableRefresh();
                        }}
                      >
                        <FormControlLabel
                          label={getString().all}
                          value="none"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={getString().outstanding_balance}
                          value="outstanding"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="negative"
                          label={getString().negative_balance}
                        />
                        <HelpUtil reference={"sell_invoice"} />
                      </RadioGroup>
                    </FormControl>
                  ) : null}
                </span>
                <span style={{ display: "inline-block" }}>
                  {canSeeDash() ? (
                    <div>
                      <Link
                        onClick={() => {
                          this.setState({ showDeleteReqs: true });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {getString().delete_requests}
                      </Link>
                      <Rodal
                        visible={this.state.showDeleteReqs}
                        width={window.innerWidth < 350 ? 350 : 450}
                        showMask={false}
                        onClose={() => {
                          this.setState({ showDeleteReqs: false });
                        }}
                      >
                        <DeleteRequests showCustomer={true} />
                      </Rodal>
                      <HelpUtil reference={"delete_request"} />
                    </div>
                  ) : null}
                </span>

                {this.state.userType !== "Cashier" ? (
                  <span style={{ display: "inline-block" }}>
                    {canSeeDash() ? (
                      <div>
                        <Link
                          onClick={() => {
                            this.setState({ showReversalReqs: true });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Sales Reversal Requests
                        </Link>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 100000000,
                            overflowY: "scroll",
                          }}
                        >
                          <Rodal
                            visible={this.state.showReversalReqs}
                            width={window.innerWidth < 350 ? 750 : 750}
                            height={window.innerHeight < 350 ? 350 : 450}
                            showMask={false}
                            onClose={() => {
                              this.setState({ showReversalReqs: false });
                            }}
                            customStyles={{
                              overflowY: "scroll",
                            }}
                          >
                            <ProposedSalesReturn
                              reversalRequestData={this.state.reversalRequestData}
                              rejectReversalOfSale={this.rejectReversalOfSale}
                              acceptReversalOfSale={this.acceptReversalOfSale}
                            />
                          </Rodal>
                        </div>
                        {/* <HelpUtil reference={"delete_request"} /> */}
                      </div>
                    ) : null}
                  </span>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>
          ) : null}

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Table
                tableRef={this.tableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let page = this.recallPage();
                    if (page) {
                      query.page = page;
                    }
                    let sort = this.recallSort();
                    if (sort) query.orderBy = sort;
                    this.loadSalesTable(query, resolve, reject);
                  })
                }
                columns={header}
                onRowClick={this.rowSelect}
                options={{
                  exportButton: false,
                  filtering: true,
                  debounceInterval: 1000,
                  grouping: true,
                  sorting: true,
                  search: true,
                  paging: true,
                  selection: true,
                  showTitle: false,
                  pageSize: 10,
                  rowStyle: { height: "8px" },
                }}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh",
                    isFreeAction: true,
                    onClick: () => {
                      this.tableRefresh();
                    },
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

Sales.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sales);
