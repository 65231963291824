import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import {currencyFormat, removeDuplicates, displaySuccess, currencyValue, getToday, dateFormat, displayError, getString} from "assets/js/utils/utils.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DetailsTable from '@material-ui/core/Table';
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import CardFooter from "components/common/Card/CardFooter.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import AsyncCreatableSelect from 'react-select/async-creatable';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class AssetItemDetails extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

        this.state = this.props.state || {

            back: false,
            data: [],
            callerName: this.props.callerName,
            callerProps: this.props.callerProps,
            callerState: this.props.callerState,

            assetData: this.props.assetData,
            assetEdit: false,
            currentValue: null,
            age: null,
            residual_val: null,
            assetItem: {
                description: '',
                batch_number: '',
                category: {},
                purchase_date: '',
                serial_number: '',
                tag: '',
                location: '',
                department: '',
                designated_to: '',
            }
        };
    };

    close = () => {
        this.setState({back: true})
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    editAsset = () => {
        this.setState({
            assetEdit: true
        });
    };

    closeEdit = () => {
        this.setState({
            assetEdit: false
        });
    };

    updateMe = (data) =>{
        let tempData = {...this.state.assetData};
        tempData.serial_number = data.serial_number;
        tempData.department = data.department;
        tempData.tag = data.tag;
        tempData.designated_to = data.designated_to;
        tempData.location = data.location;
        tempData.description = data.description;
        tempData.batch_number = data.batch_number;
        tempData.purchase_date = data.purchase_date;
        tempData.category = data.category;
        this.setState({
            assetData: tempData
        });
    };

    addEditedAssetItems = () => {
        let _assetItem = this.state.assetItem;
        let id = this.props.assetData._id.$oid;
        let _this = this;
        let _data = {
                serial_number: _assetItem.serial_number,
                tag: _assetItem.tag,
                location: _assetItem.location,
                department: _assetItem.department,
                description: _assetItem.description,
                batch_number: _assetItem.batch_number,
                purchase_date: _assetItem.purchase_date,
                designated_to: _assetItem.designated_to,
                category: _assetItem.category.value,
                editItem: true,
                prev_serial_number: this.state.assetData.serial_number
            };
        $.ajax({
            method: "PUT",
            url: baseUrl + "/afrijula/asset_register/assets/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: _data,
            success: function (result) {
                displaySuccess(getString().edit_asset_success);
                _this.updateMe(_data);
                _this.closeEdit();
            }.bind(this),
            error: function (response) {
                {
                    displayError(response.responseText);
                }
            }
        });
    };

    handleChangeItem = (e) => {
        let _assetItem = this.state.assetItem;
        _assetItem[e.target.name] = e.target.value;
        this.setState({
            assetItem: _assetItem
        });
    };

    generateBatchNumber = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/asset_register/assets/generate_batch_number",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            dataType: "json",
            data:{generating: true},
            success: function (data){
                {displaySuccess(getString().batch_num_generated);}
                let _assetItem = _this.state.assetItem;
                _assetItem.batch_number = data.number;
                _this.setState({
                    assetItem: _assetItem
                });
            },
            error: function (response) {
                {displayError(response.responseText);}
            }
        });
    };

    categoryOptions = (category) => {
        let _this = this;
        const url = baseUrl + '/afrijula/asset_register/assets?attrs=["category"]&model=Afrijula::AssetRegister::Asset&unique=category' + '&match=' + category;
        return fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for (; i < json.length; i++) {
                    opts[i] = {label: json[i], value: json[i]};
                }

                let arrFiltered = removeDuplicates(opts);
                return arrFiltered;
            })
            .catch(err => {

            });
    };

    handleSelectValue = (opt) => {
        let val = {value: opt.value, label: opt.label};
        let _assetItem = this.state.assetItem;
        _assetItem.category = val;
        this.setState({
            assetItem: _assetItem
        });
    };

    editItem = (classes) => {
        let _assetItem = this.state.assetItem;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4>{getString().edit_asset_item}</h4>
                        </CardHeader>
                        <CardBody>
                            <form className={classes.container} autoComplete="off">
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="description"
                                            name="description"
                                            label={getString().description}
                                            className={classes.textField}
                                            value={_assetItem.description}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>{getString().category}</span>
                                        <AsyncCreatableSelect defaultOptions loadOptions={this.categoryOptions}
                                                              name="category"
                                                              id="category" value={_assetItem.category}
                                                              onChange={(opt, evt) => {
                                                            this.handleSelectValue(opt)
                                                        }}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            required
                                            id="batch_number"
                                            name="batch_number"
                                            label={getString().batch_number}
                                            className={classes.textField}
                                            value={_assetItem.batch_number}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        <Button xs={12} sm={2} md={2} color="success" onClick={this.generateBatchNumber.bind(this)}
                                                style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",marginTop:"24px",
                                                    minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                        >{getString().generate}</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            label={getString().purchase_date}
                                            required
                                            id="purchase_date"
                                            name="purchase_date"
                                            inputProps={{style: {padding: "2px", height: "3em"}}}
                                            value={_assetItem.purchase_date}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            type="date"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="serial_number"
                                            name="serial_number"
                                            label={getString().serial_number}
                                            className={classes.textField}
                                            value={_assetItem.serial_number}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="tag"
                                            name="tag"
                                            label={getString().tag}
                                            className={classes.textField}
                                            value={_assetItem.tag}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="location"
                                            name="location"
                                            label={getString().location}
                                            className={classes.textField}
                                            value={_assetItem.location}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="designated_to"
                                            name="designated_to"
                                            label={getString().designated}
                                            className={classes.textField}
                                            value={_assetItem.designated_to}
                                            onChange={(e) => {
                                                this.handleChangeItem(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter>
                            <GridItem xs={12} sm={12} md={6}>
                                <Button xs={12} sm={6} md={2} color="danger" onClick={this.closeEdit.bind(this)}
                                        style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                        }}>{getString().cancel}</Button>
                                <Button xs={12} sm={6} md={2} color="success" onClick={this.addEditedAssetItems.bind(this)}
                                        style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                        }}>{getString().edit}</Button>
                            </GridItem>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    };

    getValueAge = (values) => {
        let sd = getToday();
        return values.map(value => {
            if (sd >= value.start_date.substring(0, 10) && sd <= value.end_date.substring(0, 10)) {
                let data = [];
                data.push({currentValue: value.current_value, year: value.year});
                return data;
            }
        })
    };

    getDepreciationAmt = (cost) => {
        let depVal = parseInt(this.state.assetData.depreciation);
        return (depVal / 100) * cost;
    };

    getDepreciationValues = () => {
        let assetData = this.props.assetData;
        if (assetData !== undefined) {
            let depValues = assetData.depreciation_values;
            let _data = this.state.data.slice();
            let temp = assetData;
            let element1 = depValues[0];

            _data.push(
                {
                    year: element1.start_date.split('-')[0]+' - '+element1.end_date.split('-')[0],
                    opening_bal: temp.unit_cost,
                    depr_amount: (element1.accumulated_depreciation !== null) ? element1.accumulated_depreciation : this.getDepreciationAmt(currencyValue(temp.unit_cost, false)),
                    closing_bal: element1.current_value,
                    accumulated_depreciation: element1.accumulated_depreciation
                },
            );

            for (let i = 1; i < depValues.length; i++) {
                _data.push(
                    {
                        year: depValues[i].start_date.split('-')[0]+' - '+depValues[i].end_date.split('-')[0],
                        opening_bal: depValues[i - 1].current_value,
                        depr_amount: depValues[i].depreciated_amount,
                        closing_bal: depValues[i].current_value,
                        accumulated_depreciation: depValues[i].accumulated_depreciation
                    },
                );
            }

            let currentValue = this.getValueAge(depValues).filter(e => e !== undefined)[0];
            let tempAssetItem = this.state.assetItem;
            tempAssetItem.serial_number = temp.serial_number;
            tempAssetItem.description = temp.description;
            tempAssetItem.batch_number = temp.batch_number;
            tempAssetItem.purchase_date = temp.purchase_date;
            tempAssetItem.category = {label: temp.category, value: temp.category};
            tempAssetItem.tag = temp.tag;
            tempAssetItem.location = temp.location;
            tempAssetItem.designated_to = temp.designated_to;
            tempAssetItem.department = temp.department;

            this.setState({
                data: _data,
                currentValue: currentValue[0].currentValue,
                age: currentValue[0].year.split(" ")[1],
                residual_val: _data[_data.length - 1].closing_bal,
                assetItem: tempAssetItem
            });
        }
    };

    deleteAsset = () => {
        let _this = this;
        let id = this.state.assetData._id.$oid;
        $.ajax({
            method: "DELETE",
            url: baseUrl+"/afrijula/asset_register/assets/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                displaySuccess(result.success);
                _this.close();
            },error: function(error){
                displayError(error.responseText);
            }
        });
    };

    disposeAsset = (e) => {
        let _this = this;
        let id = this.state.assetData._id.$oid;
        $.ajax({
            method: "PUT",
            url: baseUrl+"/afrijula/asset_register/assets/"+id,
            dataType: "json",
            data:{disposed: true, disposed_date: getToday()},
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                displaySuccess(result.success);
                _this.close();
            },error: function(error){
                displayError(error.responseText);
            }
        });
    };

    componentDidMount() {
        this.getDepreciationValues();
    };

    render() {
        const {classes} = this.getProps();
        let assetData = this.state.assetData;
        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState} returningProps={this.state.callerProps}/>

        }
        if (this.state.assetEdit) {
            return this.editItem(classes);
        } else {

            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={9}>
                                    <Button xs={12} sm={12} md={2} color="success" onClick={this.editAsset.bind(this)}
                                            style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">edit </i>
                                        {getString().edit}
                                    </Button>
                                    <Button xs={12} sm={12} md={2} color="danger" onClick={this.deleteAsset.bind(this)}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">delete </i>
                                        {getString().delete}
                                    </Button>
                                    <Button xs={12} sm={12} md={2} color="warning" onClick={this.disposeAsset.bind(this)}
                                            style={{backgroundColor: "#ff9800", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">close </i>
                                        {getString().dispose}
                                    </Button>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <Button color="danger" onClick={() => {this.setState({back: true})}}
                                        style={{backgroundColor: "#f44336", margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight: "auto", padding: "12px 30px", borderRadius: "3px", color: "#ffffff"}}
                                    >{getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <h4>{getString().details}</h4>
                                <CardBody>
                                    <div style={{"overflow-x": "auto"}}>
                                        <DetailsTable style={styles.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().description + ": " + assetData.description}</TableCell>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().serial_number + ": " + assetData.serial_number}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                       style={{fontSize: 15 + 'px'}}>{getString().unit_cost + ": " + currencyFormat(assetData.unit_cost)}</TableCell>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().tag + ": " + assetData.tag}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().location + ": " + assetData.location}</TableCell>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().category + ": " + assetData.category}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().model_brand + ": " + assetData.model_brand}</TableCell>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().designated + ": " + assetData.designated_to}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().batch_number + ": " + assetData.batch_number}</TableCell>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().purchase_date + ": " + dateFormat(assetData.purchase_date)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().depr_amount + ": " + assetData.depreciation + "(%)"}</TableCell>
                                                    <TableCell
                                                        style={{fontSize: 15 + 'px'}}>{getString().life_span + ": " + assetData.life_span}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </DetailsTable>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <h4>{getString().deprecs}</h4>
                                <Table
                                    tableRef={this.tableRef}
                                    data={this.state.data}
                                    onRowClick={this.rowSelect}
                                    columns={[
                                        {
                                            title: getString().year,
                                            field: 'year',
                                            filtering: false,
                                            cellStyle: {padding: '4px'},
                                            headerStyle: {padding: '4px'}
                                        },
                                        {
                                            title: getString().opening_bal,
                                            field: 'opening_bal',
                                            render: rowData => currencyFormat(rowData.opening_bal),
                                            cellStyle: {padding: '4px'},
                                            filtering: false,
                                            headerStyle: {padding: '4px'}
                                        },
                                        {
                                            title: getString().depr_amount,
                                            field: 'depr_amount',
                                            render: rowData => currencyFormat(rowData.depr_amount),
                                            cellStyle: {padding: '4px'},
                                            filtering: false,
                                            headerStyle: {padding: '4px'}
                                        },
                                        {
                                            title: "Accumulated Depreciation",
                                            field: 'accumulated_depreciation',
                                            render: rowData => currencyFormat(rowData.accumulated_depreciation),
                                            cellStyle: {padding: '4px'},
                                            filtering: false,
                                            headerStyle: {padding: '4px'}
                                        },
                                        {
                                            title: getString().closing_bal,
                                            field: 'closing_bal',
                                            render: rowData => currencyFormat(rowData.closing_bal),
                                            cellStyle: {padding: '4px'},
                                            filtering: false,
                                            headerStyle: {padding: '4px'}
                                        },
                                    ]}
                                    options={{
                                        exportButton: false, filtering: true,
                                        grouping: false, sorting: true,
                                        debounceInterval: 1000,
                                        selection: false, showTitle: false,
                                        pageSize: 10, toolbar: false
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        }
                                    ]}
                                />
                            </Card>
                            <span>{(this.state.currentValue) ? getString().currentValue + currencyFormat(this.state.currentValue) : null}</span><br/>
                            <span>{(this.state.residual_val) ? getString().residual_val + currencyFormat(this.state.residual_val) : null}</span><br/>
                            <span>{(this.state.age) ? getString().age + this.state.age + " " + getString().years : null}</span>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}


export default withStyles(styles)(AssetItemDetails);
