import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {
  currencyFormat,
  compileQuery,
  dateFormat,
  getToday,
  getString,
  currencyValue,
  execPrint,
  displaySuccess,
  displayError,
} from "assets/js/utils/utils.js";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Rodal from "rodal";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableDepositComposition from "@material-ui/core/Table";
import SaveButton from "../CustomComponents/SaveButton";
import Card from "../../common/Card/Card";
import { getLocale, getLocaleString } from "../../../assets/js/utils/utils";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  tablecell: {
    size: "small",
    paddingTop: "10px",
    paddingBottom: "0px",
  },
});

class AddDeposit extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    tag: this.props.tag,
    show_details: false,

    header: [
      {
        title: getString().date,
        field: "date",
        render: (rowData) => dateFormat(rowData.date),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().amount,
        field: "amount",
        render: (rowData) => currencyFormat(rowData.amount),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().deposited_amount,
        field: "deposited_amount",
        render: (rowData) =>
          rowData.deposited_amount === undefined
            ? currencyFormat(0, cookies.load("defaultCurrency"))
            : currencyFormat(rowData.deposited_amount),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().name,
        field: "customer.name",
        render: (rowData) =>
          rowData.customer !== undefined ? rowData.customer.name : "No name",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],
    depositsHeader: [
      {
        title: getString().date,
        field: "date",
        render: (rowData) => dateFormat(rowData.date),
        cellStyle: { padding: "3px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().amount,
        field: "amount",
        render: (rowData) => currencyFormat(rowData.amount),
        cellStyle: { padding: "3px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().name,
        field: "customer.name",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],

    depositData: {
      refNumber: "",
      date: "",
      account_name: "",
      account_number: "",
      amount: 0.0,
      payments: [],
    },

    payment: true,
    payment_option: { value: "cash", label: "Cash" },
    payment_types: [
      { value: "cash", label: "Cash" },
      { value: "cheque", label: "Cheque" },
      { value: "bank", label: "Bank" },
    ],
    back: false,

    callerName: this.props.callerName,
    callerProps: this.props.callerProps,
    callerState: this.props.callerState,
    deposit: false,
    accountData: this.props.bankAccount,
    depositedPayments: [],
    show_composition: false,
    splitAmount: false,
    reducedAmount: "",
    splitIndex: "",
    accounts: [],
    acc_local_balance: 0.0,
    acc_balance: 0.0,
    splitDataId: "",
    prevAmount: "",
    accountId: "",
    email: false,
    emailRecipient: "",
    emailSubject: "Your deposit slip",
    orgEmail: cookies.load("orgEmail"),
    printerType: cookies.load("printer_type"),
    cashBookData: null,
    tempPayments: [],
    tempData: [],
    location: "",
    locations: [],
    disbaled_account: false,
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  updateAccount = (opt) => {
    if (opt.status === "blocked") {
      displayError(getString().disabled_account_depo);
      this.setState({
        disbaled_account: true,
      });
      return;
    } else {
      let deposit_data = this.state.depositData;
      deposit_data.account_name = opt.label;
      this.getAccountBalance(opt.value);
      this.setState({
        depositData: deposit_data,
        accountId: opt.value,
      });
    }
  };

  handleChange = (e) => {
    let deposit = this.state.depositData;
    deposit[e.target.name] = e.target.value;

    this.setState({
      depositData: deposit,
    });
  };

  closeRodal = () => {
    this.setState({
      splitAmount: false,
    });
  };

  handleChangeSplit = (e) => {
    this.setState({
      reducedAmount: e.target.value,
    });
  };

  updatePaymentsAfterSave = () => {
    this.state.tempData.map((payment) => {
      let amount = currencyValue(payment.amount, true);
      let deposited_amount;
      if (payment.deposited_amount !== undefined) {
        deposited_amount =
          currencyValue(payment.deposited_amount, true) + amount;
      } else {
        deposited_amount = amount;
      }
      $.ajax({
        method: "PUT",
        url: baseUrl + "/afrijula/sales/payments/" + payment._id.$oid,
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        data: { deposited_amount: deposited_amount / 100, is_deposit: true },
        success: function(result) {},
        error: function(req, status, error) {
          console.log(req.responseText);
          displayError(req.responseText);
        },
      });
    });
  };

  updateSplitAmount = () => {
    let _amount = this.state.reducedAmount;
    let tot = this.state.depositData;

    let _tempData = this.state.tempData.splice(0, this.state.tempData.length);
    let index = parseInt(this.state.splitIndex);

    if (_amount * 100 > _tempData[index].amount.cents) {
      displayError(getString().err_split_amount);
      document.getElementById("reducedAmount").focus();
      return;
    } else {
      let difference = currencyValue(_tempData[index].amount, false) - _amount;
      _tempData[index].amount.cents = _amount * 100;
      tot.amount -= difference;
    }
    this.setState({
      depositData: tot,
      tempData: _tempData,
      splitAmount: false,
      reducedAmount: "",
    });
  };

  getSplitAmount = (classes) => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <TextField
                required
                id="reducedAmount"
                name="reducedAmount"
                label={getString().amount}
                className={classes.textField}
                value={this.state.reducedAmount}
                onChange={(e) => {
                  this.handleChangeSplit(e);
                }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
              />
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              xs={12}
              sm={6}
              md={2}
              color="danger"
              onClick={this.closeRodal.bind(this)}
              style={{
                backgroundColor: "#f44336",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "12px 30px",
                borderRadius: "3px",
                verticalAlign: "middle",
              }}
            >
              {getString().cancel}
            </Button>
            <Button
              xs={12}
              sm={6}
              md={2}
              color="success"
              onClick={this.updateSplitAmount.bind(this)}
              style={{
                backgroundColor: "#4caf50",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "12px 30px",
                borderRadius: "3px",
                verticalAlign: "middle",
              }}
            >
              {getString().update}
            </Button>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  };

  getDepositDetails = (classes) => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <TextField
                required
                id="refNumber"
                name="refNumber"
                label={getString().refNo}
                className={classes.textField}
                value={this.state.depositData.refNumber}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <TextField
                required
                id="date"
                name="date"
                label={getString().date}
                className={classes.textField}
                value={this.state.depositData.date}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                margin="normal"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <div style={{ marginTop: 15, marginLeft: 25 }} id="amount">
                {getString().total}:{" "}
                {currencyFormat(this.state.depositData.amount)}
              </div>
            </GridItem>
            {this.props.bankAccount ? null : (
              <GridItem
                xs={12}
                sm={6}
                md={8}
                style={{ marginLeft: 9 + "px", marginTop: 10 + "px" }}
              >
                <span>{getString().select_acc}</span>
                <Card style={{ zIndex: 60 }}>
                  <Select
                    options={this.state.accounts}
                    placeholder={getString().select_acc}
                    name="account"
                    id="account"
                    defaultValue={this.state.depositData.account_name}
                    onChange={(opt) => {
                      this.updateAccount(opt);
                    }}
                  />
                </Card>
              </GridItem>
            )}
            {this.state.deposit ? this.showDepositComposeTable() : null}
          </GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              xs={12}
              sm={6}
              md={2}
              color="danger"
              onClick={this.close.bind(this)}
              style={{
                backgroundColor: "#f44336",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "12px 30px",
                borderRadius: "3px",
                verticalAlign: "middle",
              }}
            >
              {getString().close}
            </Button>
            <SaveButton method={this.saveDeposit} title={getString().deposit} />
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  };

  addToAccount = (event, colData) => {
    let _data = this.state.tempData.splice(0, this.state.tempData.length);
    let originalData = JSON.parse(JSON.stringify(colData));
    if (this.state.deposit) {
      originalData.map((payment) => {
        payment.tableData.checked = false;
        let i;
        for (i = 0; i < _data.length; i++) {
          if (_data[i]._id.$oid === payment._id.$oid) {
            displayError(getString().deposit_err);
            return;
          }
        }

        if (
          payment.deposited_amount &&
          currencyValue(payment.deposited_amount, true) ===
            currencyValue(payment.amount, true)
        ) {
          displayError(getString().no_amount_deposit_err);
          return;
        } else {
          payment.deposited_amount !== undefined
            ? (payment.amount.cents -= currencyValue(
                payment.deposited_amount,
                true
              ))
            : (payment.amount.cents -= 0);
          _data.push(payment);
        }
      });
    } else {
      let temp = [];
      originalData.map((payment) => {
        if (
          payment.deposited_amount &&
          currencyValue(payment.deposited_amount, true) ===
            currencyValue(payment.amount, true)
        ) {
          displayError(getString().no_amount_deposit_err);
          return;
        } else {
          payment.deposited_amount !== undefined
            ? (payment.amount.cents -= currencyValue(
                payment.deposited_amount,
                true
              ))
            : (payment.amount.cents -= 0);
          temp.push(payment);
        }
      });
      _data = temp;
    }

    let totalAmount = 0;
    _data.map((payment) => {
      totalAmount += currencyValue(payment.amount, false);
    });

    let depo = this.state.depositData;
    depo.amount = totalAmount;
    this.setState({
      deposit: true,
      tempData: _data,
      depositData: depo,
    });
  };

  loadPayments = (query, resolve, reject) => {
    if (
      this.state.location === "" ||
      this.state.location === null ||
      this.state.location === undefined
    )
      throw "invalid location";
    let filter =
      "payment_option: '" +
      this.state.payment_option.value +
      "', 'sale.reversed': {'$ne':true}, 'sale.location': {'$eq':'" +
      this.state.location.label +
      "'}";

    filter += "";

    let includes = { include: "{sale: {only: [:location,:reversed]}}" };
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/sales/payments",
      [
        "date",
        "balance",
        "amount",
        "deposited_amount",
        "receipt_number",
        "payment_option",
        "reversed",
      ],
      [{ by: "asc", attr: "date" }],
      "Afrijula::Sales::Payment",
      includes,
      filter,
      null
    );

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  close = () => {
    this.setState({ back: true });
  };

  print = () => {
    execPrint(document.getElementById("printableView"));
    this.closeComposition();
  };

  getAccountBalance = (id) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/cashbook/bank_account/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let deposit_data = _this.state.depositData;
        deposit_data.account_number = result.number;
        _this.setState({
          acc_local_balance: result.balance.cents,
          acc_balance: result.local_balance.cents,
          depositData: deposit_data,
        });
      },
    });
  };

  scrubPayments = () => {
    return this.state.tempData.map(
      (item) =>
        item.amount.cents === 0 ? 0 : currencyValue(item.amount, false)
    );
  };

  saveDeposit = () => {
    let depo = this.state.depositData;

    if (depo.refNumber === "") {
      displayError(getString().err_deposit_refNo);
      document.getElementById("refNumber").focus();
      return;
    }
    if (depo.account_name === "") {
      displayError(getString().err_deposit_accName);
      document.getElementById("account").focus();
      return;
    }
    if (this.state.tempData.length === 0) {
      displayError(getString().err_deposit_payments);
      return;
    }

    let _this = this;
    let _category = this.state.payment_option.value;
    let prevLocalBalance = this.state.acc_local_balance / 100;
    let prevBalance = this.state.acc_balance / 100;

    let newBalance = prevBalance + depo.amount;
    let newLocalBalance = prevLocalBalance + depo.amount;

    let _data = { newBal: newBalance, newLocBal: newLocalBalance };

    if (this.state.disbaled_account) {
      displayError(getString().disabled_account_depo);
      return;
    } else {
      $.ajax({
        method: "POST",
        url: baseUrl + "/afrijula/cashbook/deposits",
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        data: {
          reference_number: depo.refNumber,
          category: _category,
          date: depo.date,
          account_number: depo.account_number,
          account_name: depo.account_name,
          amount: depo.amount,
          new_balance: newBalance,
          new_local_balance: newLocalBalance,
          prev_local_balance: prevLocalBalance,
          prev_balance: prevBalance,
          payments: this.scrubPayments(),
        },
        success: function(result) {
          let lang = getLocale();
          if (lang == "en-GB" || lang == "en-US") {
            displaySuccess(
              `${depo.amount} has been deposited to ${depo.account_name} on ${
                depo.date
              }`
            );
          } else {
            displaySuccess(getString().deposit_success);
          }
          _this.setState({
            show_composition: true,
          });
          if (this.props.updateMe) {
            _this.props.updateMe(_data);
          }
          _this.updatePaymentsAfterSave();
        }.bind(this),
        error: function(e) {
          displayError(e.responseText);
        },
      });
    }
  };

  showDepositComposeTable = () => {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Table
          tableDepRef={this.tableRef}
          data={this.state.tempData}
          columns={this.state.depositsHeader}
          pageSize={10}
          title={getString().deposits}
          options={{
            exportButton: false,
            filtering: false,
            debounceInterval: 1000,
            grouping: false,
            sorting: false,
            search: false,
            selection: false,
            showTitle: false,
            toolbar: false,
            pageSize: 5,
            actionsColumnIndex: -1,
            padding: "dense",
          }}
          actions={
            this.state.payment_option.value === "cash"
              ? [
                  {
                    tooltip: "Split amount",
                    icon: "check",
                    onClick: (evt, data) => {
                      this.splitDeposit(evt, data);
                    },
                  },
                ]
              : null
          }
          components={{
            Action: (props) => (
              <Button
                onClick={(event) => props.action.onClick(event, props.data)}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#9c27b0",
                  margin: ".3125rem 1px",
                  minWidth: "auto",
                  minHeight: "auto",
                  fontSize: "10px",
                  color: "#ffffff",
                  padding: "10px 20px",
                  borderRadius: "3px",
                  verticalAlign: "middle",
                }}
                size="small"
              >
                {getString().split}
              </Button>
            ),
          }}
        />
      </GridItem>
    );
  };

  showDepositCompositionBefore = () => {
    return (
      <div>
        <GridContainer>
          <GridItem>
            <Button
              onClick={this.closeComposition.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="danger"
              style={{
                backgroundColor: "#f44336",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().close}
            </Button>
            <Button
              onClick={this.print.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="success"
              style={{
                backgroundColor: "#4caf50",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().to_print}
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer id="printableView">
          <GridItem xs={12} sm={12} md={12}>
            <h5 style={{ marginRight: 5 }}>{getString().depo_details}</h5>
            <TableDepositComposition style={styles.table}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().refNo +
                      ": " +
                      this.state.depositData.refNumber}
                  </TableCell>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().date +
                      ": " +
                      dateFormat(this.state.depositData.date)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().acc_name +
                      ": " +
                      this.state.depositData.account_name}
                  </TableCell>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().acc_number +
                      ": " +
                      this.state.depositData.account_number}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().amount +
                      ": " +
                      currencyFormat(this.state.depositData.amount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    <strong>{getString().date}</strong>
                  </TableCell>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    <strong>{getString().amount}</strong>
                  </TableCell>
                </TableRow>
                {this.state.tempData
                  .map((deposit) => {
                    return {
                      _id: deposit._id,
                      type: deposit.payment_option,
                      date: dateFormat(deposit.date),
                      amount: currencyFormat(deposit.amount),
                    };
                  })
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                        {row.date}
                      </TableCell>
                      <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                        {row.amount}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </TableDepositComposition>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  splitDeposit = (evt, coldData) => {
    coldData.tableData.checked = false;
    const prevAmount = coldData.amount.cents;
    this.setState({
      splitAmount: true,
      splitIndex: coldData.tableData.id,
      splitDataId: coldData._id.$oid,
      prevAmount: prevAmount,
    });
  };

  accountOptions = () => {
    let the_data = [];
    let filter = "status: {'$ne':'blocked'}";

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/cashbook/bank_account",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        attrs: ["name", "id", "status"],
        order: [{ by: "desc", attr: "name" }],
        model: "Afrijula::Cashbook::BankAccount",
      },

      success: function(data) {
        $.map(data, function(data) {
          the_data.push({
            label: data.name,
            value: data._id.$oid,
            status: data.status,
          });
        });
        this.setState({
          accounts: the_data,
        });
      }.bind(this),
    });
  };

  loadLocations = (callback) => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/locations/location",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        attrs: ["name", "id"],
        order: [{ by: "dsc", attr: "name" }],
        model: "Afrijula::Locations::Location",
      },

      success: function(data) {
        let locations = _this.state.locations;
        while (locations.length > 0) {
          locations.pop();
        }
        data.forEach((item) => {
          locations.push({ label: item.name, value: item.name });
        });
        _this.setState({
          locations: locations,
          location: locations[0],
        });
        callback();
      },
    });
  };

  closeComposition = () => {
    let _deposit = this.state.depositData;
    _deposit.account_name = "";
    _deposit.account_number = "";
    _deposit.amount = "";
    _deposit.refNumber = "";

    this.setState({
      show_composition: false,
      depositData: _deposit,
      deposit: false,
    });
  };

  updatePaymentType = (event) => {
    let payment_option = { value: event.value, label: event.value };
    this.setState({ payment_option: payment_option }, () => {
      this.refreshTable();
    });
  };

  refreshTable = () => {
    if (this.tableRef.current) this.tableRef.current.onQueryChange();
  };

  updateLocation = (event) => {
    let loc = { value: event.value, label: event.label };
    this.setState({ location: loc }, () => {
      this.refreshTable();
    });
  };

  componentDidMount() {
    const props = this.getProps();

    if (props.bankAccount) {
      let depositDetails = this.state.depositData;
      let data = this.state.accountData;
      depositDetails.account_name = data.name;
      depositDetails.account_number = data.number;

      this.setState({
        depositData: depositDetails,
        acc_local_balance: this.state.accountData.local_balance.cents,
        acc_balance: this.state.accountData.balance.cents,
        accountId: this.state.accountData._id.$oid,
      });
    }
    let depDetails = this.state.depositData;
    depDetails.date = getToday();
    this.setState({
      depositData: depDetails,
    });
    this.accountOptions();
    this.loadLocations(() => {
      this.refreshTable();
    });
  }

  render() {
    const { classes } = this.getProps();

    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let _callerState = this.state.callerState;
      _callerState.add_deposit = false;
      return (
        <ChildComponent
          state={_callerState}
          returningProps={this.state.callerProps}
          updatedData={this.state.cashBookData}
        />
      );
    } else {
      return (
        <div className={classes.root}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <p>{getString().income_type}</p>
              <Card style={{ zIndex: 60 }}>
                <Select
                  options={this.state.payment_types}
                  placeholder={getString().saleType}
                  defaultValue={this.state.payment_types[0]}
                  onChange={(event) => {
                    this.updatePaymentType(event);
                  }}
                />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <p>{getString().select_location}</p>
              {this.state.locations.length === 0 ? null : (
                <Card style={{ zIndex: 60 }}>
                  <Select
                    options={this.state.locations}
                    placeholder={getString().location}
                    defaultValue={this.state.location}
                    onChange={(event) => {
                      this.updateLocation(event);
                    }}
                  />
                </Card>
              )}
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Table
                tableRef={this.tableRef}
                data={
                  this.state.location !== "" &&
                  Object.keys(this.state.location).length > 0
                    ? (query) =>
                        new Promise((resolve, reject) => {
                          this.loadPayments(query, resolve, reject);
                        })
                    : []
                }
                columns={this.state.header}
                title={<div>{getString().income}</div>}
                options={{
                  exportButton: false,
                  filtering: false,
                  debounceInterval: 1000,
                  grouping: false,
                  sorting: true,
                  search: false,
                  selection: true,
                  showTitle: true,
                  rowStyle: { height: "8px" },
                  pageSize: 10,
                }}
                actions={[
                  {
                    tooltip: getString().dep_to_acc,
                    icon: "check",
                    onClick: (evt, data) => {
                      this.addToAccount(evt, data);
                    },
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {this.getDepositDetails(classes)}
            </GridItem>
          </GridContainer>
          <Rodal
            visible={this.state.splitAmount}
            width={400}
            height={140}
            showMask={false}
            onClose={this.closeRodal.bind(this)}
          >
            {this.getSplitAmount(classes)}
          </Rodal>
          <Rodal
            visible={this.state.show_composition}
            width={600}
            height={510}
            showMask={false}
            onClose={this.closeComposition.bind(this)}
          >
            {this.showDepositCompositionBefore()}
          </Rodal>
        </div>
      );
    }
  }
}

AddDeposit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddDeposit);
