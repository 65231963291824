import React, { useEffect, useState } from "react";
import PincodeInput from "pincode-input";
import Axios from "axios";
import baseUrl from "assets/js/config/const.js";
import SignIn from "../../afrijula/Login/LogIn";
import Reset from "../../afrijula/Register/Reset";
import {
  displayError,
  displaySuccess,
  getString,
} from "assets/js/utils/utils.js";
import WindowSizeListener from "react-window-size-listener";
import Spinner from "../Loader/paying.gif";
import Logo from "./logo.png";
import "pincode-input/dist/pincode-input.min.css";
import "./style.css";

const ResetPasswordPhone = (props) => {
  // states
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [code, setCode] = useState("");
  const [pinInit, setPinInit] = useState(false);
  const [sending, setSending] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [sent, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(
    () => {
      // redirect to login page if phone number is not attached to props
      if (!props.phone) {
        setShowLogin(true);
      }
      // should only run upon initialization
      if (!pinInit) {
        // initialize pin
        setPinInit(true);
        new PincodeInput(".pincode-container", {
          // build up code on input change
          onInput: (value) => {
            setCode(value.toString().trim());
          },
          count: 5, // TODO: should be prop so it can be a reusable component
        });
        const inputFields = document.querySelectorAll(".pincode-input");
        // adding a "-" placeholder, since it is difficult to implement via HTML
        inputFields.forEach((curr) => {
          curr.setAttribute("placeholder", "-");
        });

        // set the first input field to autofocus
        document.querySelector(".pincode-input").focus();
      }

      if (code.trim().length == 5) {
        setTimeout(() => {
          setSending(true);
          console.log("Should check code now");
          // run verify function
          verifyCode(code);
        }, 500);
      }
    },
    [code]
  );

  // verify code inserted by the user
  const verifyCode = async (code) => {
    try {
      console.log(props.phone);
      const response = await Axios({
        method: "post",
        url: `${baseUrl}/account/authenticate_v2/confirm_sms_code`,
        data: {
          phone_number: props.phone.trim(),
          code,
        },
      });
      setSent(true);
      setSending(false);
      disableInput();
      console.log(response.data);
      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else if (response.data.success) {
        setErrorMessage(null);
        setToken(response.data.token);
        setTimeout(() => {
          setSent(false);
          setShowReset(true);
        }, 2000);
      }
    } catch (err) {
      setErrorMessage("Invalid Code");
      setSent(true);
      setSending(false);
      disableInput();
    }
  };

  // disable all input fields function
  const disableInput = () => {
    const inputFields = document.querySelectorAll(".pincode-input");
    if (sending) {
      inputFields.forEach((curr) => {
        curr.setAttribute("disabled", true);
      });
    } else {
      inputFields.forEach((curr) => {
        curr.removeAttribute("disabled");
      });
      // clear input fields
      clearFields();
    }
  };

  // clear input fields
  const clearFields = () => {
    const inputFields = document.querySelectorAll(".pincode-input");
    inputFields.forEach((curr) => {
      curr.value = "";
      // remove "pincode-input--filled" class
      curr.className = "pincode-input";
    });
  };

  // show toast function
  const showToast = (msg = null) => {
    return (
      <div
        style={{
          padding: "10px",
          background: "#3cfac4",
          marginTop: "5px",
          textAlign: "center",
          borderRadius: "5px",
        }}
      >
        {`${
          msg ? msg : "Verified successfully. You can now reset your password."
        }`}
      </div>
    );
  };

  // show spinner function
  const showSpinner = () => {
    if (sending) {
      disableInput();
      return (
        <WindowSizeListener
          onResize={(size) => setWindowSize(size.windowWidth)}
        >
          {windowSize >= 800 ? (
            // tablet, laptop and desktop view
            <div
              id="show-spinner"
              style={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "10000000",
                display: "block",
              }}
            >
              <img src={Spinner} width="100px" height="100px" />
            </div>
          ) : (
            // code
            // phone_number
            // mobile view
            <div
              id="show-spinner"
              style={{
                position: "absolute",
                top: "60%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "10000000",
                display: "block",
              }}
            >
              <img src={Spinner} width="100px" height="100px" />
            </div>
          )}
        </WindowSizeListener>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {showReset ? (
        <Reset phone={props.phone} type="phone" token={token} />
      ) : showLogin ? (
        <SignIn />
      ) : (
        <WindowSizeListener
          onResize={(size) => setWindowSize(size.windowWidth)}
        >
          <div>
            {showSpinner()}
            {sent ? showToast(errorMessage) : ""}
            <div className="phone-resetcustom">
              <img
                src={Logo}
                width={windowSize <= "800px" ? "150px" : "100%"}
                height={windowSize <= "800px" ? "150px" : "100%"}
              />
            </div>
            <h2 className="h2custom animateText">Enter the 5 digit code</h2>
            <div className="pincode-container" />
            <div className="text-center">
              <button
                className="btn btn-primary m-2"
                onClick={(e) => {
                  setShowLogin(true);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </WindowSizeListener>
      )}
    </>
  );
};

export default ResetPasswordPhone;
