import React,{Component} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import cookies from "react-cookies";
import {currencyFormat,getString} from "assets/js/utils/utils.js";
import Grid from '@material-ui/core/Grid';
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import Charge from './Charge';
import Bill from './Bill';
import ContractLength from './ContractLength';
import Autorenew from './Autorenew';

const gridItem = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '6px',
  fontSize: '10px',
  textAlign: 'center'
}

function getSteps() {
  return ['How do you want to charge the customer?', 'When will you bill the customer?', 'How long is the contract?','Do you want this contract to automatically renew when expired?'];
}

class ContractQustonnaire extends Component {
      constructor(props) {
        super(props);
        this.state = {
            taxPercentage: this.props.callerState.taxPercentage,//@ams-> check
            prevActiveStep: 0,
            charge: 'month',
            bill: '',
            term: 'year',
            length: 1,
            autorenew: '',
            discountAmount: this.props.callerState.discountAmount,
            flushValue: false,
            disable: false
        };
        this.handleChangeValue = this.handleChangeValue.bind(this);

      }

       getStepContent = (step) => {
        switch (step) {
          case 0:
              return (
                <Charge value={this.state.charge} nextStep={this.state.prevActiveStep}
                onChangeValue={this.handleChangeValue}/>
              );
          case 1:
              return (
                <Bill value={this.state.bill} nextStep={this.state.prevActiveStep}
                   charge={this.state.charge} onChangeValue={this.handleChangeValue}/>
                 );
          case 2:
              return (
                <ContractLength value={this.state.term} length={this.state.length} charge={this.state.charge}
                    bill={this.state.bill} onChangeValue={this.handleChangeValue} nextStep={this.state.prevActiveStep} />
              );
          case 3:
            return (
                <Autorenew value={this.state.autorenew} nextStep={this.state.prevActiveStep}
                 onChangeValue={this.handleChangeValue}/>
              );
          default:
            return 'Unknown step';
        }
      }

handleChangeValue = (e) =>{
  this.setState({
    [e.target.name]: e.target.value
  })
  this.updateSideInfo(e);
  this.updateNextInfos(e);
}

updateNextInfos = (e) =>{
  if(e.target.name === 'charge'){
    if(e.target.value ==='quarter'){
      this.setState({ bill: 'quarter', term: 'quarter'})
    }else if(e.target.value ==='year'){
      this.setState({ bill: 'year', term: 'year'})
    }else{
      this.setState({ term: 'month'})
    }
  }else if(e.target.name === 'bill'){
      if(e.target.value === 'week'){
        let day = new Date().getDay();
        if(day != 0){  // if not sunday
          window.confirm(`${getString().alert_prorate_week}`)? this.setState({ flushValue: true}) : alert(`${getString().alert_ch_start_today}`);
        }
      }else if(e.target.value === 'month'){
        let dayOfMonth = new Date().getDate();
        if(dayOfMonth > 1){  // if after 1st
          window.confirm(`${getString().alert_prorate_month}`)? this.setState({ flushValue: true}) : alert(`${getString().alert_ch_start_today}`);
        }
      }else if(e.target.value === 'quarter'){
        let Month = new Date().getMonth();
        let dayOfMonth = new Date().getDate();
        if(dayOfMonth === 1 && ((Month+3)%3 === 0 )){ // if on 1st of jan,Apr,Jul,Oct, no need to prorate
          this.setState({term: 'quarter'});
        }else{
          window.confirm(`${getString().alert_prorate_quarter}`)? this.setState({ flushValue: true, term: 'quarter' }) : this.setState({term: 'quarter'});
        }
      }else if(e.target.value === 'year'){
        let Month = new Date().getMonth();
        let dayOfMonth = new Date().getDate();
        if(Month !== 0 && dayOfMonth !== 1){
          window.confirm(`${getString().alert_prorate_year}`)? this.setState({ flushValue: true, term: 'year' }) : this.setState({term: 'year'});
        }else{
          this.setState({term: 'year'});
        }
      }
  }else{ return;
  }
}

  handleNext = () => {
     let steps = getSteps();
     let response = this.validate();
     if(this.state.prevActiveStep == steps.length-1)
     {
      if(response === true){
          let res = window.confirm(`${getString().alert_confirm_contract_creation}`);
          if(res === true){
              this.setState({
                  disable: true
              });
              this.props.createContract(this.state.charge,this.state.bill,this.state.term,
                  this.state.length,this.state.flushValue,this.state.autorenew);
          }else{
              this.props.handleBack('backToContractsUI');
          }
      // window.confirm(`${getString().alert_confirm_contract_creation}`)? :
      }else if(response == 'charge'){
        alert(`${getString().alert_provide_charge}`);
        return;
      }else if(response == 'bill'){
        alert(`${getString().alert_provide_bill}`);
        return;
      }else if(response == 'term'){
        alert(`${getString().alert_provide_term}`);
        return;
      }else if(response == 'zero'){
        alert(`${getString().alert_provide_zero}`);
        return;
      }else if(response == 'autorenew'){
        alert(`${getString().alert_provide_autorenew}`);
        return;
     }
    }else{
         this.nextStep();
     }

  };

  validate = () => {
    if(this.state.charge == ''){
      return 'charge';
    }else if(this.state.bill == ''){
      return 'bill';
    }else if(this.state.term == ''){
      return 'term';
    }else if(this.state.length == 0){
      return 'zero';
    }else if(this.state.autorenew == ''){
        return 'autorenew';
    }else{
        return true;
    }
  };
  nextStep = () =>{
      let val  = this.state.prevActiveStep + 1
      this.setState({
          prevActiveStep : val
      });
  }

  handleBack = () => {
    if(this.state.prevActiveStep !== 0){
        let val = this.state.prevActiveStep - 1
        this.setState({
           prevActiveStep : val
        });
    }
  };

  back = () => {
    this.props.handleBack('EnterProducts');
  };

  backToContracts = () => {
    this.props.handleBack('backToContractsUI');
  };

  updateSideInfo = (e) =>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

render() {
  const {saleItems, total, discountSymbol, taxDue, customerName, ...other} = this.props.callerState;

  const summaryTotal = total;
  const summarytax = taxDue;
  const listItems = saleItems.map((item) =>
  <li style={{ listStyleType: "none" }}><span style={gridItem}>{item.quantity}x</span><span style={gridItem}>{item.item}</span><span style={gridItem}>{currencyFormat(item.total)}</span></li>

  );
    let steps = getSteps();

    return (
        <Grid container xs={12} sm={12} md={12}>
            <Card>
                <CardHeader color="primary">
                <h4 style={{textAlign:'center'}}>{getString().contract_agreement} {cookies.load('orgName')} {getString().and} {customerName?customerName:null}</h4>
                </CardHeader>
            </Card>

        <Grid item xs={9} sm={9} md={9}>

            <Stepper activeStep={this.state.prevActiveStep} orientation="vertical">
            {steps.map((label, index) => (
                <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                    <Typography>{this.getStepContent(index)}</Typography>
                    <div>
                    <div>
                        <Button
                        disabled={this.prevActiveStep === 0}
                        onClick={this.handleBack.bind(this)}
                        >
                        {getString().back}
                        </Button>
                        <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.disable}
                        onClick={this.handleNext.bind(this)}
                        >
                        {this.state.prevActiveStep === steps.length - 1 ? `${getString().Finish}` : `${getString().next}`}
                        </Button>
                    </div>
                    </div>
                </StepContent>
                </Step>
            ))}
            </Stepper>
        </Grid>
        <Grid item xs={3} sm={3} md={3} border={1} style={{padding:'20px',lineHeight:"20px", border:"2px solid #ab47bc" ,borderColor:"primary"}}>
        <TextField
        disabled
        fullWidth
        id="standard-disabled"
        label= {getString().charge}
        value={this.state.charge}
        />
        <TextField
        disabled
        fullWidth
        id="standard-disabled"
        label= {getString().bill}
        value={this.state.bill}
        />
        <TextField
        disabled
        fullWidth
        id="standard-disabled"
        label= {getString().contract_length}
        value={this.state.length+" "+this.state.term}
        />
        <TextField
        disabled
        fullWidth
        id="standard-disabled"
        label= {getString().auto_renew}
        value={this.state.autorenew}
        />
        <h4 style={{textAlign: 'center', marginTop:'10'}}>{getString().summary}</h4>
         <div>
         <p style={{textAlign: 'center'}}>{customerName?customerName:null}</p>
         <div style={{textAlign: 'center'}}>{listItems}</div>
         <div style={{textAlign: 'center'}}>{summarytax?`${getString().tax_amount}`+": "+currencyFormat(summarytax):null}</div>
         <div style={{textAlign: 'center'}}>{summaryTotal?`${getString().total}`+": "+currencyFormat(summaryTotal):null}</div>
         </div>
        </Grid>
        <Grid  item={8} sm={8} md={8} style={{color: "white"}} >
            <Button xs={12} sm={4} md={4} color="danger" onClick={this.back.bind(this)}
            style={{backgroundColor: "#f44336", right: '0px', minWidth: "auto", verticalAlign:'center',float: 'left' ,
            minHeight:"auto",fontSize: "12px",borderRadius: "3px",margin:'20px', color: 'rgb(255,255,255)'}}
            >{getString().Back_to_products}</Button>
            <Button xs={12} sm={4} md={4} color="danger" onClick={this.backToContracts.bind(this)}
            style={{backgroundColor: "#ff9800", right: '0px', minWidth: "auto", verticalAlign:'center',float: 'left' ,
            minHeight:"auto",fontSize: "12px",borderRadius: "3px",margin:'20px', color: 'rgb(255,255,255)'}}
            >{getString().Cancel_contract}</Button>
        </Grid>
  </Grid>

    );
}
}

export default ContractQustonnaire
