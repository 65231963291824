import LocalizedStrings from 'react-localization';
import React from "react";



const strings = new LocalizedStrings({

    en:{
        //for landing page
        subjects:"Select a subject",
        google_play:"GET IT ON Google Play",
        contact_us:"CONTACT US",
        visit:"Visit Afrijula.shop",
        our_location: "OUR LOCATIONS",
        kairaba:"KAIRABA AVENUE",
        bijilo:"BIJILO",
        //for dashboard
        customers: "Customers",
        low_products: "Low Products",
        daily_sales: "Daily Sales",
        sale_text:{"one_time":"Cash Sales",'invoice':"Invoices",'quote':'Quotes','contract':'Contracts'},
        daily_invoice: "Daily Invoice",
        daily_revenue: "Daily Revenue",
        revenue: "Revenue",
        details: "Details",
        monthly_purchase: "Monthly Purchase",
        stock: "Stock / Inventory",
        apply_for_loans:"APPLY FOR LOANS",
        products: 'Products',
        quote: "Quote",
        due_invoices: "Due Invoices",
        increase_sales: "Increase in today sales.",
        remove: "Remove",
        discontinue: 'Discontinue ',
        discontinued: 'Discontinued',
        continue: 'Continue ',
        requested_by: 'Requested By',
        add_remove: 'Add/Remove',
        labels: "Labels",
        merge: "Merge",
        record_monthly_expense: " Record of Monthly Expense",
        best_selling: "Best Selling",
        category: "Category",
        sale_type: "Sale type",
        reason: "Reason",
        edit: "Edit",
        enable_shopping: 'E-Shop',
        disable_shopping: 'E-Shop',
        new_payment: "New Payment",
        deposit_amount: "Deposit",
        deposit: "Deposit",
        start: "Start",
        extend: "Extend",
        payment_type: "Payment type",
        income_type: "Income type",
        initial_deposit: "Initial Deposit",
        payment_method: "Payment method",
        amount_to_pay: "Amount to Pay",
        installments: "Installments",
        installment_period: "Installment period",
        start_on: "Start on",
        setLocale: "Please select your language",
        invoice_notice_frequency: 'Frequency of Invoice Reminders',
        overdue: 'Overdue',
        ontime: 'Ontime',
        voucher: "Voucher",
        number_installments: "Number of Installments",
        installment_type: "Installment type",
        count: "Count",
        period: "Period",
        cash_payment: "Cash",
        cheque_payment: "Cheque",
        bank_payment: "Bank ",
        balance_due:"Balance Due",
        credit_balance: 'Credit Balance',
        last_updated: 'Last updated',
        last_sent: 'Last sent',
        billable: "Billable",
        add_customer_sale: "New sale",
        for:"For",
        periodic_payments: 'Periodic Payments schedule',
        status: 'Status',
        filter: "Filter",
        outstanding_balance: 'Oustanding balance',
        negative_balance: 'Negative balance',
        all: "All",
        //Contracts
        active_contracts: 'Active Contracts',
        closed_contracts: 'Closed Contracts',
        New: 'NEW',
        next: 'Next',
        Finish: 'Finish',
        alert_sel_one_product: 'You must select at least one product',
        alert_cust_addr: 'Customer address cannot be empty',
        alert_cust_email: 'Please enter a valid email',
        alert_cust_name: 'Customer name cannot be empty',
        alert_cust_phone: 'Please enter a valid phone number',
        alert_ch_start_today: 'Charges will start from today',
        alert_confirm_contract_creation: 'Are you sure you want to create this contract?',
        alert_prorate_day: 'Do you want to prorate this day?',
        alert_prorate_week: 'Do you want to prorate this week?',
        alert_prorate_month: 'Do you want to prorate this month?',
        alert_prorate_quarter: 'Do you want to prorate this quarter?',
        alert_prorate_year: 'Do you want to prorate this year?',
        Update_product: 'Update product',
        ch_products: 'Choose Products',
        Select_a_product: 'Select a product',
        and: 'and',
        contract_agreement: 'Contract agreement between',
        contract_length: 'Contract length',
        Back_to_products: 'Back to products',
        Cancel_contract: 'Cancel Contract',
        Weeks: 'Week(s)',
        Months: 'Month(s)',
        Quarters: 'Quarter(s)',
        Years: 'Year(s)',
        discount_100: 'Discount cannot be 100%',
        alert_provide_charge: 'Please specify the charge cycle',
        alert_provide_bill: 'Please specify the bill period',
        alert_provide_term: 'Please specify the term length',
        alert_provide_zero: 'Please specify the contract length',
        alert_provide_autorenew: 'Please specify if to auto renew or not',

        // products
        add_product: 'Add Product',
        new_menu: 'Add Menu',
        poor_items: 'Slow Products',
        popular_items: 'Popular Items',

        //for stocks
        low_stock: "Low Stock",
        //stock: "Stock",
        fast_moving_stock: "Fast Moving Stock",
        transfer_stock: 'Transfer Stock',
        add_stock: 'New Stock',
        qty_per_unit: "Quantity Per Unit",
        stock_hist: "Stock History",
        transfer_qty: "Enter transfer quantity",
        submit: "Submit",
        stock_qty: "Stock quantity",

        //for stock transfer
        save: "Save",
        cancel: "Cancel",
        select_source: "Select Source",
        select_destination: "Select Destination",
        source: "Source",
        destination: "Destination",
        received_by: "Received by",
        table_name: "Name",
        table_quantity: "Quantity",
        back: "Back",
        received_qty: "Received Qty",

        //for add stock
        metric: 'Metric',
        product: "Product",
        product_number: "Number",
        wholesale_mteric: "Wholesale Metric",
        wholesale_price: "Wholesale Price/Unit",
        retail_metric: "Retail Metric",
        reorder_level: "Reorder Level",
        sellable_units: "Sellable Units",
        sellable: "Sellable",
        unit_price: "Unit Price",
        sold: 'Sold',
        opening_balance: "Opening Balance",
        select_category: "Select Category",
        select_location: "Location",
        //add_stock: "Add Stock",
        stock_sales: "Stock Sales",
        stock_purchase: "Stock Purchase",
        sales_return: "Sales Return",
        sales_items: "Sales Items",
        //for customer
        total_customer: "Total Customers",
        // due_invoices: "Due Invoice",
        list_customers: 'List of Customers',
        aging_debtors: "Aging Debtors",

        //for Customer Details
        profile: "Profile",
        name: "Customer Name",
        address: "Address",
        contact_person: 'Contact Person',
        made_by: "Paid by",
        phone: "Phone",
        phone_number: "Phone Number",
        email: "Email",
        balance: "Balance",
        currency: 'Currency',
        account_summary: "Account Summary",
        yearly_turnover: "Yearly TurnOver",
        last_date: "Last Transaction Date",
        purchase_history: "Purchase History",
        add_customer: "New Customer",

        //for supplier
        payables: "Payables",
        //aging_debtors: "Aging Debtor",
        list_suppliers: "List of Suppliers",
        billing_addr: "Billing Address",
        shipping_addr: "Shipping Address",

        //for  spplier details
        date: "Date",
        sub_total: "Subtotal",
        total_amount: "Total Amount",
        total: "Total",
        add_supplier: "New Supplier",
        pay_supplier: "Pay Supplier",
        supplier_account: "Supplier Account",
        supply_history: "Supply History",
        tax_amount: "Tax Amount",
        tax_percentage: "Tax %",
        shipping_address:'Shipping Address',
        billing_address: 'Billing Address',
        item: "Item",
        amount: "Amount",
        due: "Invoice Due",
        due_for_period: "Invoice for Period",
        items: "Items",
        paid: "Paid",
        add_supp_invoice: "Add Supplier Invoice",
        add_supplierInv:"Supplier Invoice",


        //FOR INVOICE
        monthly_invoice: "Monthly Invoice",
        customer: "Customer",
        dailyInvoiceName: "Daily Invoice",

        //for invoice details
        bill_to: "Bill To",
        date_issued: "Date Issued",
        due_date: "Due Date",
        invoice_no: "Invoice N°",
        customer_id: "Customer ID",
        description: "Description",
        pricing:"Pricing",
        unit_cost: "Unit Cost",
        quantity: "Qty",
        estimate: 'Estimate',
        // total: "Total",
        payment_history: "Payment History",
        payment_plan: "Payment Plan",
        invoice: "Invoice",
        contract: 'Contract',
        to_print: "Print",
        close: "Close",
        to_pdf: "Download",
        to_edit:"Edit",
        to_email: "Send email",

        //for SideBar (routes)
        dashboard: "Dashboard",
        sell: "Sell",
        cash_sales: "Cash Sales",
        invoices: "Invoices",
        sell_online: "SELL ONLINE",
        contracts: "Contracts",
        //stock: "Stock",
        purchases: "Purchases",
        income: "Income",
        reports: "Reports",
        loans: "Loans",
        user_profile: "User Profile",
        settings: "Settings",
        suppliers: "Suppliers",

        available_packages: "Available packages",

        //for cash sales
        type: "Type",
        weekly_sales: "Weekly Sales",
        monthly_sales: "Monthly Sales",
        discount: "Discount",
        sales: "Sales",
        record_finances: "RECORD FINANCES",

        //for sell component
        discount_type: 'Discount Type',
        discount_amount: "Discount Amount",
        retail: "Retail",
        wholesale: "Wholesale",
        percentage: "Percentage",
        fixed: "Fixed",
        metric_units:"Unit",
        select_item: "Select Product",
        select_customer: "Select Customer",
        total_tax: "Total Tax",
        cash: "Cash",
        quotes: "Quotes",
        cheque: "Cheque",
        bank: "Bank",
        choose_category: "Choose category",

        sale_item: "Sold Items",

        //for settings page
        //settings: "Settings",
        company_name: "Company Name",
        telephone: "Telephone",
        location: "Location",
        edit_settings: "Edit",
        add_more: "Add New",
        save_settings: "Save",
        add_user: "New User",
        add_location: "New Location",
        gen_info:"General Info",
        billing_info: "Billing",
        user_acc: "User Accounts",
        current_version: "Current Version",
        version: "Version",
        upgrade: "Upgrade",
        upgrade_version: "Upgrade Version",
        pay_bill: "Pay Bill",
        account_billing:"Account & Billing",
        select_version: "Please select package",
        pay: "Pay",
        delete_requests: "Payment Delete Requests",
        manager: "Manager",
        insert_logo: "Insert logo",
        first_name: "First Name",
        last_name: "Last Name",
        user_name: "Username",
        user_type: "User type",
        password: "Password",
        verify_pwd: "Verify Password",
        download: "Windows POS",
        logout: "Logout",
        login: "Login",
        entre_msg:"Please enter your message",
        business_location: "Business location",
        register_mandinka: "How To Register On Afrijula (Mandinka)",
        register_wollof: "How To Register On Afrijula (Wollof)",
        register_english: "How To Register On Afrijula (English)",
        powerful: "Powerful",
        store_name: "Store Name",
        user_friendly: "User friendly",
        african: "African",
        key_features: "Key Features",
        //for cashButton
        change: "Change",
        changes: "Changes",
        contract_change: "Contract changed successfully",
        cheque_no: "Cheque Number",
        bank_credit_card: "Bank / Credit Card",
        mobile_money: "Mobile Money",
        kodo_wallet: "Kodo Wallet",
        reversed: "Reversed",
        bank_no: "Bank Number",
        payment: "Payment",
        activate: "Activate",
        deactivate: "Deactivate",
        delete: "Delete",
        return_quantity: 'Returned Quantity',
        new_quantity: 'New Quantity ',
        register: "Registration",
        reg: "Register",
        complete: "Business Account Activation",
        complete_reset: " Account Password Reset",
        business_name: "Business Name",
        card_number: "Card Number",
        card_code: 'CVV Security Code',
        card_expiry: 'Card Expiry Date',
        holder_name: 'Card Holder Name',
        street_address: "Street Address",
        city_address: "City",
        country_address: 'Country',
        sale_success: "Succesfully saved sales",
        invoice_info:"Additional Invoice Info",
        transaction_number: 'Transaction number',

        //for reports component
        dailysales_report: "Daily Sales Report",
        expense_eport: "Expense Report",
        stock_report: "Stock Report",
        monthlysales_report: "Monthly Sales Report",

        //Quotation
        price: "Price",
        item_code: "Item Code",
        code: "Code",
        default: "Default",

        locale: "Locale",
        package: "Package",
        term: "Term",
        term_charge: "Term Charge",
        max_users: "Max Users",
        default_currency: "Default Currency",
        country: "Country",
        packages:"Packages",
        yes: "Yes",
        no: "No",
        default_chooser:"Make default location",
        id:"Id",
        org_info: "Organisation details",

        sale_date: "Sale Date",
        delivery_date: "Delivery Date",
        // due_date: "Due Date",
        inv_number: "Invoice No",
        payment_term: "Payment Terms",
        last_payment: "Last Payment",
        vat: "VAT",
        other_tax: "Other tax",
        number: "Number",
        quantities: "Quantities",
        tax: "Tax",
        value: "Value",

        supplier_invoice: "Supplier Invoice",
        stock_level: "Stock Level",
        enable_notification: "Enable Notification",

        //for purchase_manager
        bank_name: "Bank Name",
        cheque_number: "Cheque Number",
        purpose: "Purpose",
        expense: "Expense",
        purchase: "Purchase",
        purchase_table: "History of Purchases",
        supply: "Supply",
        asset: "Asset",
        voucher_number: "Voucher",
        summary: "Summary",
        purchase_manager: "Add Purchase",
        exchange_rate: "Exch Rate",
        paid_by: "Paid by",
        approved_by: "Approved by",
        signature: "Signature",
        refNo: "Reference Number",
        payee: "Payee",
        checkNum: "Cheque #",
        exchange: "Exchange",
        is_default: "Default",
        sms: "SMS",
        add_stock_inv: "Add Stock Invoice",
        select_supplier: "Select Supplier",
        select_country: "Select country",
        new_invoice: " New Invoice",
        pay_inoices: "Pay Invoices",
        update_qty: "Update Qty",
        damange_expiries: "Damages/Expiries",
        payment_summary: "Payment summary",
        unable_to_complete_reg: "Unable to complete registration",
        account_activation:"Account activation in progress...",
        variance: "Variance",
        variable: "Variable",
        profit_loss: "Profit & Loss",
        high_expenses: "High expenses",
        annually: "Annually",
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        quarterly: "Quarterly",
        bill: "Bill",
        charge: "Charge",
        print: "Print",
        day: "Day",
        week: "Week",
        month: "Month",
        quarter: 'Quarter',
        year: "Year",

        //Account and Billing
        package_name: "Package name",
        monthly_price: "Monthly price",
        last_paid: "Last paid",
        payment_mthd: "Payment method",
        charges: "Charges",

        //Super Admin
        bills: "Unpaid bills",
        users_login: "Users login",
        accounts: "Accounts",
        message: "Message",
        users:"Users",
        num_users: "Number of users",
        num_allowed_users: "Allowed users",
        num_transaction: "Number of transactions",
        allowed_transaction: "Allowed transactions",
        active_users: "Active users",
        billing: "Billing",
        payments: "Payments",
        weekly_account: "Weekly account turnover",
        open_logs: "Open logs",
        sale_stats: "Sale statistics",
        organisation: "Organisation",
        ip:"IP address",
        duration: " Duration",
        logs: "Logs",
        users_list: "List of Users",
        usage_params: "Usage parameters",
        subject: "Subject",
        previous_logs:"Previous logs",

        current_pwd: "Current Password",
        new_pwd: "New Password",
        verify_new_pwd: "Confirm Password",
        one_time: "One time",
        start_date: "Start Date",
        end_date:"End Date",
        subscriptions: "Subscriptions",
        new_sub: "New Subscription",

        select_duration: "Please select duration",
        block_acc: "Block account",
        bloc_user: "Block user",
        business_type: "Business type",
        reset_password:"Reset Password",
        block: "Block",
        reset: "Reset",
        parameters: "Parameters",
        old_password: "Old password",
        new_password: "New password",
        confirm_password: "Confirm password",
        active:"Active",
        general_info: "General",
        import_export: "Import / Export",
        acc_bill: "Account & Billing",
        user_account: "Users",
        send_to: "Send To",
        email_report: "Email Report",
        send: "Send",
        send_msg: "SEND MESSAGE",
        slide1:"Afrijula is a powerful accounting tool that allows small and micro businesses to structure and manage their accounts for Free",
        slide2:"Become an active Afrijula user with FREE training and support",
        slide3:"Afrijula provides support for loan applications all while giving the user access to a fully integrated e-commerce marketplace, offering digital payment services",
        slide4:"We make Afrijula work within our African context",
        repeat:"Repeat",
        every: "Every",
        on: "On",
        after: "After",
        never: "Never",
        stop:"Stop",
        schedule: "Schedule",
        numTimes: "After tries",
        schedule_reports: 'Scheduled Reports',
        cash_amount:"Please Enter Cash Amount",
        receipt: "Receipt",
        printer: "Printer Type",
        thermal_printer:"Thermal printer",
        large: "A4 Paper",
        picture:"Picture",
        upload_pic:"Upload picture",
        logo: "Logo",
        upload_logo:"Upload logo",
        menu: "Menu",
        add_menu:"Add menu",
        ingredients: "Ingredients",
        low_stock_threshold: "Low stock threshold",
        amt_to_pay:"Amount to be Paid",
        deposit_type:"Deposit Type",
        deposit_method:"Method of Deposit",
        inventoryProducts: "Inventory & Products",
        inventory: "Inventory",
        editQty: "Edit Quantity",
        checkBoxStartTerm: "Start at the beginning of term",
        content: "Content",
        to: "To",
        email_receipt: "Email Receipt",
        add: "Add Item",
        auto_renew: "Auto Renew",
        add_to: "Add to",
        token: "Token",
        tokens: "Tokens",
        last_used_date: "Last used date",
        delete_token: "Delete token",
        copy_token: "Copy token",
        returned_sales: "Returned Sales",
        returned_sale: "Returned Sale!",
        returned_date: "Returned Date",
        reverse_sale: "Reverse Sale",
        quote_to_sale: "Quote to Sale",
        one_time_sale: "One of product",
        one_of_product: "To add inventory or product, select inventory or product. Else one of product will be added.",
        changePswd:'Change Password',
        chaangeUserPswd: "User's password will be reset!",
        stock_removal: "Stock Removal",
        statement: "statement",
        _name: "Name",
        retMetricDef:"*Selling measurement. Eg packet or kilo",
        cash_book: "Cash Book",
        acc_name:"Account Name",
        acc_number: "Account Number",
        list_accounts: "Accounts",
        add_expense: "Add Expense",
        account: "Account",
        particulars: "particulars",
        folio: "Folio",
        account_history: "Account History",
        transfer_balance: "Transfer",
        select_bank: "Select Bank",
        local_balance: "Local Balance",
        debit_credit_balance: "Debit/Credit Balance",
        debit_credit_amount: "Debit/Credit Amount",
        payment_to: "Payment to",
        payment_from: "Payment from",
        pvoucher: "Payment Voucher",
        receipt_no: "Receipt No",
        transaction: "Transaction",
        add_income: "Add Income",
        deposits: "Deposits",
        add_deposit: "Deposit",
        undeposited: "Undeposited Amount",
        pending_depo: "Pending Deposit",
        bill_items: "Billable Items",
        select_from_acc: "Select account to transfer from",
        select_to_acc: "Select account to transfer to",
        update: "Update",
        add_bank_acc: "Account",
        err_acc_name: "Enter account's name please",
        err_acc_number: "Please enter a account number",
        err_bank_name: "Enter a bank name",
        err_acc_success: "Account successfully added!",
        err_deposit_refNo: "You must specify a reference number",
        err_deposit_accName: "You must specify an account",
        err_deposit_payments: "You must specify a payment to deposit",
        deposit_success: "Deposit successful!",
        err_split_amount: "Amount entered cannot be greater than the current amount",
        err_amount: "Amount entered is greater than the current balance, enter another amount",
        will_be_sent: " Will be transferred from ",
        _to: " to ",
        trans_success: "Transfer successful",
        current_bal: "Current Balance is ",
        confirm_deactivate_acc: "Are you sure you want to block the selected account(s)?",
        confirm_activate_acc: "Are you sure you want to unblock the selected account(s)?",
        delete_acc: "Block selected account(s)",
        disabled_account: "You clicked on a disabled account, activate the account to be able to view it's details.",
        disabled_account_trans: "The account you selected is disbaled, please activate it to make a transfer",
        same_acc_err: "You cannot choose the same account for a transfer!",
        select_acc: "Select account",
        disabled_account_depo: "The account you selected is disbaled, please activate it to make a deposit",
        select_pymnt_type: "Payment type",
        no_customer: "No customer name",
        transfers: "Transfers",
        to_account: "To account",
        from_account: "From account",
        depo_details: "Deposit details",
        split: "Split",
        reverse_transfer: "Reverse",
        reverse_success: "Transfer successfully reversed!",
        reversed_err_msg: "This transfer has already been reversed!",
        phone_num_err: "Please enter a valid phone number",
        recapcha_err: "Please check the reCAPTCHA box",
        fname_lname_err: "Please enter a first and last name",
        email_err: "Invalid Email, please enter a valid email address",
        pwd_length_err: "You must enter a password with least 6 digits",
        verify_pwd_err: "The passwords you entered do no match",
        register_succ_mess: "Your  registration is successful. Please log in to  your email to activate your account.",
        deposit_err: "You cannot deposit the same payment twice with the same reference number.",
        undeposited_amount: "Undeposited Amount",
        dep_to_acc: "Deposit to account",
        no_amount_deposit_err: "There is no amount to deposit for this payment. Please select another payment",
        deposited_amount: "Deposited Amount",
        session_out: "Your session has expired, please login to continue.",
        zero_reverse_err: "Your attempt to reverse a transfer of 0 amount has failed.",
        errInvalid_bankName: "Invalid bank account",
        err_invalid_checkNo: "Invalid check number",
        err_bban: "Invalid bank number",
        add_product_item : "Please add item part of this product",
        changed_prod_status: "changed product status.",
        are_you_sure: "Are you sure you want to ",
        added_subItem: "Added product sub item.",
        qtygtzero: "Please set quantity greater than 0",
        select_description: "Please select description",
        item_removed: "Item removed",
        add_composition: "Add product composition",
        refresh_table: "Refresh",
        composition: "Composition",
        remove_item: "Remove selected item",
        customer_added: "Successfully saved customer",
        valid_amount: "Please enter a valid amount, amount must be greater than zero",
        shipping_cost: "Shipping Cost",
        err_customer_name: "Invalid customer name",
        withdraw: "Withdraw",
        online_shopping: 'Prepare for shop',
        recipient: "Recipient",
        will_be_withdrawn: " will be withdrawn from ",
        withdraw_amount: "Make a withdrawal",
        select_withd_from_acc: "Select account to withdraw from",
        withdraw_success: "Withdrawal successful",
        prev_balance: "Old Balance",
        prev_local_balance: "Old Local Balance",
        new_balance: "New Balance",
        new_local_balance: "New Local Balance",
        transactions: "Transactions",
        err_withdraw_amount: "Amount cannot be more than your current balance",
        withdrawnAmount: "Withdrawn Amount",
        create: "Create",
        createAccNames: "Please create accounts for the following bank names",
        automateRecon: "Automate account reconciliation",
        withdraw_method: "Withdrawal method",
        reconcile_expense: "Reconcile Expense",
        reconcile_income: "Reconcile Income",
        reconcile_success: "Expense successfully reconciled",
        payment_deleted: "Payment successfully deleted",
        unblock_acc: "Unblock selected account(s)",
        no_invoice_payment: "No payments made for this invoice yet",
        only_one: "You can only delete one item at a time.",
        delete_expense: "Are you sure you want to delete this expense?",
        later: "Later",
        upgrade_before_use: "You must upgrade to Premium to use this feature!",
        activate_before_use: "You must activate the Cashbook before using it!",
        other_reports: "Additional Reports",
        choose_location: "Choose Location",
        load_report: "Load Report",
        stock_number_taken: "Stock number already exist",
        product_number_taken: "Product number already exist",
        stk_num_generated: "Stock number successfully generated",
        prod_num_generated: "Product number successfully generated",
        generate: "Generate",
        aging_receivables: "Aging Receivables",
        asset_register: "Asset Register",
        model_brand: "Model/Brand",
        purchase_date: "Purchased Date",
        opening_bal: "Opening Balance",
        depr_amount: "Depreciation",
        closing_bal: "Closing Balance",
        serial_number: "Serial Number",
        designated: "Designated To",
        tag: "Tag",
        dept: "Department",
        currentValue: "Current Value: ",
        age: "Age: ",
        years: "years",
        add_asset: "Add Asset",
        new_asset: "New Asset",
        edit_asset: "Edit Asset",
        deprecs: "Depreciations",
        tot_curr_val: "Current Value",
        total_cost: "Total Cost",
        residual_val: "Residual value: ",
        life_span: "Life span",
        batch_number: "Batch Number",
        edit_asset_success: "Asset Successfully edited",
        asset_success: "Asset Successfully added",
        exact_qty_err: "The number of specific assets must match the quantity!",
        total_assets: "Total Assets",
        total_current_val: "Total Asset Value",
        asset_items: "Asset Items",
        edit_asset_item: "Edit Asset item",
        specified_qty: "You can only enter the specified quantity!",
        err_serialNumber: "You must enter the serial number",
        no_asset_error: "You must specify assets to add!",
        noDefaultLocation: "You must set a default location so as to make sales!",
        individual_assets:"Specify Individual Assets",
        batch_num_generated: "Batch number successfully generated",
        err_asset_name: "Specify name of asset",
        err_asset_number: "Specify batch number",
        err_unit_cost: "Specify unit cost of the asset",
        err_life_span: "Specify the life span of the asset",
        dispose: "Dispose",
        review_settlements: 'Settlements',
        update_shop: 'Update Shop',
        automate_help: "Check the automate checkbox to have all your bank account transactions like expenses etc, reconciliations done automatically, else you have to do them manually!",
        petty_cash_account:"Create a petty cash account",
        petty_cash_text: "A petty cash account will be created for you, please give it a name",
        bank_acc_bals: "Bank Account Balances",
        stock_sales_by_loc: "Stock Sales by location",
        cash_flow_rpt: "Cash flow report",
        cashbook_rpt: "Cash Book report",
        monthly_product_sales: "Product sales by month",
        expense_method: "Expense Method",
        confirm: "Confirm",
        active_assets: "Active Assets",
        disposed_assets: "Disposed Assets",
        disposed_date: "Disposed Date",
        err_qty: "Specify the quantity of asset",
        err_asset_description: "Specify description of asset(s)",
        asset_categories: "Specify Categories",
        err_tag: "Please specify the Asset's Tag",
        reject: "Reject",
        err_description: "Specify description of the asset!",
        err_valid_amount: "Please enter valid amount",
        stock_saved: "Stock successfully saved",
        product_saved: "Product successfully saved",
        invalid_unit_price: "The unit price you entered is invalid",
        select_cat: "Please select category",
        select_loc: "Please select location",
        select_rmetric: "Please enter retail metric",
        invalid_sellable_unit: "You must have a sellable unit greater than 1",
        invalid_prodName: "Please enter a valid product name",
        not_specified: "Not specified",
    //Shoppping
        video_link: "Video Link",
        model: 'Model',
        weight: 'Weight',
        min_order_limit: 'Minimum Order Limit',
        request_settlement: 'Request Settlement',
        pending_settlement: 'Pending Settlements',
        query_issue: 'Report Issue',
        check: 'Check',
        transfer: 'Transfer',
        proceed: 'Proceed',
        select: 'Select',
        more: 'More',
        stopped_contracts: 'Stopped Contracts',
        two_months_old: 'Non Payment',
        unrenewed_contracts: 'Unrenewed Contracts',
        number_of_closed: 'Closed Contracts',
        afrijula_reports: 'Afrijula Reports',
    }

});
export default strings;

// strings.setLanguage('fr');
const lang = strings.getLanguage();
