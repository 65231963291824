import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";

import CustomerDetails from "components/afrijula/AllDetails/CustomerDetails.jsx";
import AddCustomer from "components/afrijula/AddForm/AddingCustomer.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import $ from "jquery";
import {
  numberFormat,
  currencyFormat,
  getString,
  compileQuery,
  showReportUtility,
  canSeeDash,
} from "assets/js/utils/utils.js";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import HelpUtil from "../Utils/HelpUtil";
import WindowSizeListener from "react-window-size-listener";

class Customer extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    value: 0,
    customerdetails: false,
    winSize: window.innerWidth,

    customerData: null,
    customerCount: 0,
    lastPage: null,
    recalledPage: null,
    lastSize: null,
    recalledPageSize: null,
    tilesData: {
      tot_customers: "",
      agingdebotrs: "",
      tot_due_invoice: "",
      lastSort: null,
    },

    addcustomer: false,
  };

  addCustomer = () => {
    this.setState({ addcustomer: true });
  };

  loadCustomer = (id) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/sales/customers/" + id,
      data: {
        include: [
          "customer_balance",
          "annual_turnover",
          "last_transaction_date",
        ],
      },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ customerData: result });
      },
    });
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    this.loadCustomer(id);
  };

  recallSort = () => {
    const order = this.state.lastSort;
    this.state.lastSort = null;
    return order;
  };

  loadData = (query, resolve, reject) => {
    let filter = null;

    let url = compileQuery(
      query,
      baseUrl + "/afrijula/sales/customers",
      ["id", "name", "address", "phone_number", "email", "number"],
      [{ by: "asc", attr: "name" }],
      "Afrijula::Sales::Customer",
      { methods: "[:customer_balance]" },
      null,
      null,
      filter,
      null
    );

    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;
    this.state.lastSort = query.orderBy;

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  loadTilesData = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { count: ["due_invoice", "aging_debtors", "customers"], chart: [] },
      success: function(result) {
        let temp = _this.state.tilesData;

        temp.tot_customers = result.counts.customers;
        temp.tot_due_invoice = result.counts.due_invoice;
        temp.agingdebotrs = result.counts.aging_debtors;

        _this.setState({ tilesData: temp });
      }.bind(this),
    });
  };

  componentDidMount() {
    if (canSeeDash()) {
      this.loadTilesData();
    }
  }

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.customerData) {
      let data = this.state.customerData;
      this.state.customerData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <CustomerDetails
          callerProps={this.getProps()}
          callerState={this.state}
          customerData={data}
          callerName={Customer}
        />
      );
    }
    if (this.state.addcustomer) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <AddCustomer
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Customer}
          saveCustomerCback={this.loadCustomer.bind(this)}
        />
      );
    } else {
      return (
        <WindowSizeListener
          onResize={(size) => {
            this.setState({ winSize: size.windowWidth });
          }}
        >
          <div style={{ marginTop: -40 }}>
            {canSeeDash() ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        <Icon>people</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        {getString().total_customer}
                      </p>
                      <h4 className={classes.cardTitle}>
                        {numberFormat(this.state.tilesData.tot_customers)}
                      </h4>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <span
                          onClick={() => {
                            let params = {
                              organization_id: cookies.load("orgId"),
                              org_name: cookies.load("orgName"),
                            };
                            let inject = {
                              images: [
                                {
                                  name: "image",
                                  attr: "image",
                                  id: cookies.load("orgId"),
                                  model: "Organization",
                                },
                              ],
                            };

                            showReportUtility(
                              "Customer Turnover",
                              baseUrl +
                                "/reports/1?url=/afrijula/*&profile=" +
                                cookies.load("profileId"),
                              {
                                params: params,
                                sub:
                                  "&report=customer_turnover_report&dynamic=true",
                                inject: inject,
                              },
                              cookies,
                              []
                            );
                          }}
                          style={{ cursor: "pointer", color: "#800080" }}
                        >
                          {getString().details}
                        </span>
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="warning">
                        <Icon>money</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        {getString().aging_debtors}
                      </p>
                      <h4 className={classes.cardTitle}>
                        {currencyFormat(this.state.tilesData.agingdebotrs)}
                      </h4>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <span
                          onClick={() => {
                            let end_date = new Date();
                            let start_date = new Date(
                              end_date.getYear(),
                              end_date.getMonth() - 1,
                              end_date.getDate()
                            );
                            let params = {
                              organization_id: cookies.load("orgId"),
                              org_name: cookies.load("orgName"),
                              start_date: start_date,
                              end_date: end_date,
                            };
                            let inject = {
                              images: [
                                {
                                  name: "image",
                                  attr: "image",
                                  id: cookies.load("orgId"),
                                  model: "Organization",
                                },
                              ],
                            };
                            showReportUtility(
                              "Aging Debtors",
                              baseUrl +
                                "/reports/1?url=/afrijula/*&profile=" +
                                cookies.load("profileId"),
                              {
                                params: params,
                                sub: "&report=aging_debtors&dynamic=true",
                                inject: inject,
                              },
                              cookies,
                              [
                                {
                                  type: "date",
                                  default: start_date,
                                  label: "From",
                                  name: "start_date",
                                },
                                {
                                  type: "date",
                                  default: end_date,
                                  label: "To",
                                  name: "end_date",
                                },
                              ]
                            );
                          }}
                          style={{ cursor: "pointer", color: "#800080" }}
                        >
                          {getString().details}
                        </span>
                      </div>
                      <HelpUtil reference={"customers_aging_debtors"} />
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card>
                    <CardHeader color="danger" stats icon>
                      <CardIcon color="danger">
                        <Icon>bookmark</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        {getString().due_invoices}
                      </p>
                      <h4 className={classes.cardTitle}>
                        {currencyFormat(this.state.tilesData.tot_due_invoice)}
                      </h4>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <span
                          onClick={() => {
                            let end_date = new Date();
                            let start_date = new Date(
                              end_date.getYear(),
                              end_date.getMonth() - 1,
                              end_date.getDate()
                            );
                            let params = {
                              organization_id: cookies.load("orgId"),
                              org_name: cookies.load("orgName"),
                              start_date: start_date,
                              end_date: end_date,
                            };

                            let inject = {
                              images: [
                                {
                                  name: "image",
                                  attr: "image",
                                  id: cookies.load("orgId"),
                                  model: "Organization",
                                },
                              ],
                            };
                            showReportUtility(
                              "Over Due Invoices",
                              baseUrl +
                                "/reports/1?url=/afrijula/*&profile=" +
                                cookies.load("profileId"),
                              {
                                params: params,
                                sub: "&report=due_invoices_report&dynamic=true",
                                inject: inject,
                              },
                              cookies,
                              [
                                {
                                  type: "date",
                                  default: start_date,
                                  label: "From",
                                  name: "start_date",
                                },
                                {
                                  type: "date",
                                  default: end_date,
                                  label: "To",
                                  name: "end_date",
                                },
                              ]
                            );
                          }}
                          style={{ cursor: "pointer", color: "#800080" }}
                        >
                          {getString().details}
                        </span>
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
            ) : null}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="info"
                  onClick={this.addCustomer.bind(this)}
                  style={{
                    backgroundColor: "#00acc1",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">add</i>
                  {getString().add_customer}
                </Button>
              </GridItem>
            </GridContainer>

            <GridContainer style={{ marginTop: -10 }}>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="warning">
                    <h4 className={classes.cardTitleWhite}>
                      {getString().list_customers}
                    </h4>
                  </CardHeader>
                  <Table
                    tableRef={this.tableRef}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let page = this.recallPage();
                        if (page) {
                          query.page = page;
                        }
                        let sort = this.recallSort();
                        if (sort) query.orderBy = sort;
                        this.loadData(query, resolve, reject);
                      })
                    }
                    columns={[
                      {
                        title: getString().name,
                        field: "name",
                        cellStyle: { padding: "4px" },
                        headerStyle: { padding: "4px" },
                      },
                      {
                        title: getString().address,
                        field: "address",
                        cellStyle: { padding: "4px" },
                        headerStyle: { padding: "4px" },
                      },
                      {
                        title: getString().phone,
                        field: "phone_number",
                        cellStyle: { padding: "4px" },
                        headerStyle: { padding: "4px" },
                      },
                      {
                        title: getString().customer_id,
                        field: "number",
                        cellStyle: { padding: "4px" },
                        headerStyle: { padding: "4px" },
                      },
                      {
                        title: getString().balance,
                        field: "customer_balance",
                        render: (rowData) =>
                          currencyFormat(rowData.customer_balance),
                        cellStyle: { padding: "4px" },
                        headerStyle: { padding: "4px" },
                      },
                    ]}
                    onRowClick={this.rowSelect}
                    options={{
                      exportButton: false,
                      filtering: true,
                      grouping: true,
                      sorting: true,
                      debounceInterval: 1000,
                      selection: true,
                      showTitle: false,
                      pageSize: this.state.recalledPageSize || 20,
                    }}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh",
                        isFreeAction: true,
                        onClick: () =>
                          this.tableRef.current &&
                          this.tableRef.current.onQueryChange(),
                      },
                    ]}
                  />
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </WindowSizeListener>
      );
    }
  }
}

Customer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Customer);
