import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import $ from "jquery";
import cookies from "react-cookies";

import baseUrl from "assets/js/config/const.js";
import {
  currencyFormat,
  dateFormat,
  validateEmail,
  getString,
  execPrint,
  thermalPrinterFunct,
  displaySuccess,
  displayError,
  showReportUtility,
} from "assets/js/utils/utils.js";
import TextField from "@material-ui/core/TextField/TextField";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardCategory: {
    color: "#000",
    margin: "0px",
    fontSize: "14px",
    fontWeight: "bolder",
    paddingTop: "4px",
  },
});

class Receipt extends React.Component {
    state = {
        salesItems: this.props.items,
        toPrintData: this.props.toPrintData,
        total: this.props.total,
        subTotal: this.props.subtotal,
        location: this.props.location,
        discount: this.props.discount,
        tax: this.props.tax,
        customer: this.props.customer || {},
        payment: this.props.payment,
        invoice: this.props.invoice,
        description: this.props.description,
        quantity: this.props.quantity,
        amount_received: this.props.amount_received,
        email: false,
        labels: this.props.labels,
        emailRecipient: (this.props.customer) ? this.props.customer.email : '',
        emailSubject: "Your receipt",
        orgEmail: cookies.load('orgEmail'),
        orgContact: cookies.load('orgContact'),
        orgAddress: cookies.load('orgAddress'),
        printerType: cookies.load("printer_type"),
        invoicing_info: ''

    };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  emailReceipt = () => {
    this.setState({
      email: true,
    });
  };

  closeSendEmail = () => {
    this.setState({
      email: false,
    });
  };

  autoEmail = () => {
    if (this.props.autoSend) {
      this.sendEmail();
    }
  };

  sendEmail = () => {
    let _this = this;

    try {
      let _body = document.getElementById("printableView").outerHTML;
      let _subject = this.state.emailSubject;
      let _recipient = this.state.emailRecipient;

      if (_body == null || _body == undefined) {
        return;
      }

      if (_recipient === "" || !validateEmail(_recipient)) {
        displayError("Please enter a valid email address");
        if (document.getElementById("recipient"))
          document.getElementById("recipient").focus();
        return;
      }

      $.ajax({
        method: "POST",
        url: baseUrl + "/mail_v2",
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        data: {
          to: _recipient,
          subject: _subject,
          body: _body,
          domain: "afrijula.gm",
        },

        success: function(result) {
          displaySuccess("Email successfully sent");
          _this.closeSendEmail();
        },
        error: function(resp) {
          displayError(resp.responseText);
        },
      });
    } catch (err) {}
  };

  print = () => {
    this.state.printerType === "thermal"
      ? execPrint(document.getElementById("invoice-POS"))
      : execPrint(document.getElementById("printableView"));
    this.closePrint();
  };


    handleChangeEmailDetails = (e) => {
        let _data = this.state.customer;
        _data.customer_email = e.target.value;
        this.setState({
            customer: _data
        })
    };

    emailReceiptForm = (classes) => {
        return (
            <Card>
                <CardBody>
                    <GridItem xs={12}>
                        <form className={classes.container} autoComplete="off">
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        required
                                        id="recipient"
                                        name="recipient"
                                        label={getString().to}
                                        className={classes.textField}
                                        value={this.state.customer.customer_email}
                                        onChange={(e) => {
                                            this.setState({emailRecipient: e.target.value})
                                        }}
                                        type="email"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        required
                                        id="subject"
                                        name="subject"
                                        label={getString().subject}
                                        className={classes.textField}
                                        value={this.state.emailSubject}
                                        onChange={(e) => {
                                            this.setState({emailSubject: e.target.value})
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                        </form>

            <GridItem xs={12} sm={12} md={6}>
              <Button
                xs={12}
                sm={6}
                md={2}
                color="danger"
                onClick={this.closeSendEmail.bind(this)}
              >
                {getString().cancel}
              </Button>
              <Button
                xs={12}
                sm={6}
                md={2}
                color="success"
                onClick={this.sendEmail.bind(this)}
              >
                {getString().send}
              </Button>
            </GridItem>
          </GridItem>
        </CardBody>
      </Card>
    );
  };

  closePrint = () => {
    this.props.closePrint();
  };

  getInvoiceInfo = (page) => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/account/settings/-1",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { attrs: ["additional_invoice_info"] },

      success: function(result) {
        _this.setState({
          invoicing_info: result.organization.additional_invoice_info,
        });
      }.bind(this),
    });
  };

  componentDidMount() {
    setTimeout(() => {
      this.autoEmail();
    }, 500);
    this.getInvoiceInfo();
  }

  render() {
    const { classes } = this.getProps();
    return (
      <div
        style={{
          width: "700px",
          height: "780px",
          animationDuration: "300ms",
        }}
      >
        <GridContainer>
          <GridItem>
            <Button
              onClick={this.closePrint.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="danger"
              style={{
                backgroundColor: "#f44336",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().close}
            </Button>
            <Button
              onClick={this.print.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="success"
              style={{
                backgroundColor: "#4caf50",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().to_print}
            </Button>
            <Button
              onClick={this.emailReceipt.bind(this)}
              xs={12}
              sm={12}
              md={2}
              color="info"
              style={{
                backgroundColor: "#00acc1",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "5px 20px",
                borderRadius: "2px",
                verticalAlign: "middle",
              }}
            >
              {getString().email}
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {this.state.email ? this.emailReceiptForm(classes) : <div />}
        </GridContainer>
        <div
          id={"printableView"}
          style={{
            zIndex: "10000",
            width: "680px",
            height: 720,
          }}
        >
          {this.state.printerType.toLowerCase() === "a4" ||
          this.state.printerType.toLowerCase() === "a4 paper" ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <table
                  style={{
                    // width: "100%",
                    fontWeight: "bold",
                    background: "#F5F5F5",
                    borderCollapse: "collapse",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "62.5em",
                      padding: "auto",
                      margin: "auto",
                      // zIndex: "100000",
                    }}
                  >
                    <div
                      xs={12}
                      sm={6}
                      md={3}
                      class="floatLeft"
                      style={{
                        width: "3.33%",
                        padding: "1em",
                        float: "left",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        <img
                          alt="logo"
                          src={
                            baseUrl +
                            "/picture/" +
                            cookies.load("orgId") +
                            "?tag=image&model=Afrijula::Account&r=" +
                            this.state.picture
                          }
                        />
                      </div>
                      <h2
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <span>Name: </span>
                        {cookies.load("orgName")}
                      </h2>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        <span>Location: </span>
                        {this.state.orgAddress === "null"
                          ? ""
                          : this.state.orgAddress}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          paddingTop: "1px",
                          fontWeight: "bold",
                        }}
                      >
                        <span>Email:</span>
                        {this.state.orgEmail === "null"
                          ? ""
                          : this.state.orgEmail}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          paddingTop: "1px",
                          fontWeight: "bold",
                        }}
                      >
                        <span>Contact: </span>
                        {this.state.orgContact === "null"
                          ? ""
                          : this.state.orgContact}
                      </p>
                    </div>
                    <div
                      xs={12}
                      sm={6}
                      md={3}
                      class="floatRight"
                      style={{
                        width: "33.33%",
                        float: "right",
                        paddingTop: "55px",
                      }}
                    >
                      <td>
                        <h2
                          style={{
                            fontSize: "30px",
                            margin: "10px 25px",
                            textAlign: "right",
                            textTransform: "uppercase",
                          }}
                        >
                          {getString().receipt}
                          <h3
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                              fontWeight: "bold",
                              color: " #072f5f",
                              textTransform: "uppercase",
                            }}
                          >
                            {getString().date}:{" "}
                            {dateFormat(this.state.payment.date)}
                          </h3>
                          <h3
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                              fontWeight: "bold",
                              color: " #072f5f",
                              textTransform: "uppercase",
                            }}
                          >
                            {getString().receipt_no}:{" "}
                            {this.state.payment.receipt_number}
                          </h3>
                        </h2>
                      </td>
                    </div>
                  </div>
                  <div>
                    <div>
                      {this.state.labels
                        ? this.state.labels.map((label) => (
                            <label
                              style={{
                                marginLeft: "2px",
                                padding: "2px",
                                color: "black",
                                borderColor: "grey",
                                border: "1px",
                                backgroundColor: "#FFC300",
                                borderRadius: "5px",
                              }}
                            >
                              {label}
                            </label>
                          ))
                        : null}
                    </div>
                    <td />
                  </div>
                </table>
                <table
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    margin: "15px 5px",
                    padding: "5px 10px",
                    borderCollapse: "collapse",
                    zIndex: "100000",
                  }}
                >
                  <h6
                    style={{
                      textTransform: "uppercase",
                      color: "blue",
                    }}
                  >
                    Bill to
                  </h6>
                  <tr>
                    <td style={{ width: "40%" }}>
                      {getString().name}:{" "}
                      {this.state.customer ? this.state.customer.name : "none"}
                    </td>
                    <td
                      style={{
                        width: "40%",
                      }}
                    >
                      {getString().received_by}:{" "}
                      {this.state.payment.user_name
                        ? this.state.payment.user_name
                        : "none"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "40%",
                      }}
                    >
                      {getString().email}:{" "}
                      {this.state.customer ? this.state.customer.email : "none"}
                    </td>
                    <td style={{ width: "40%" }}>
                      {getString().paid_by}:{" "}
                      {this.state.payment.details
                        ? this.state.payment.details.paid_by
                        : "none"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%" }}>
                      {getString().payment_method}:{" "}
                      {this.state.payment.payment_option}
                    </td>
                    <td style={{ width: "40%" }}>
                      {getString().location} : {this.state.customer.address}
                    </td>
                  </tr>
                  {this.state.payment.payment_option !== "cash" ? (
                    <tr>
                      <td
                        style={{
                          width: "40%",
                        }}
                      >
                        {getString().bank_payment}:{" "}
                        {this.state.payment.details.bank}
                      </td>
                    </tr>
                  ) : null}
                  {this.state.payment.payment_option !== "cash" ? (
                    <tr>
                      <td />
                      <td
                        style={{
                          width: "40%",
                        }}
                      >
                        {getString().refNo}:{" "}
                        {this.state.payment.details.bank_number}
                      </td>
                    </tr>
                  ) : null}
                </table>
                <table
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    border: "1px solid #000",
                    margin: "10px 5px",
                    padding: "5px 10px",
                    borderCollapse: "collapse",
                    zIndex: "100000",
                  }}
                >
                  <thead
                    style={{
                      fontWeight: "bold",
                      border: "1px solid #000",
                      textAlign: "left",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr style={{ background: "#FF6700" }}>
                      <th style={{ color: "#FFffff" }}>Description</th>
                      <th style={{ color: "#FFffff" }}>Quantity</th>
                      <th style={{ color: "#FFffff" }}>Unit Price</th>
                      <th
                        style={{
                          paddingLeft: "90px",
                          color: "#FFffff",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.toPrintData.map((item) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>
                            <span>{item.quantity}</span>
                          </td>
                          <td>{currencyFormat(item.unit_price)}</td>
                          <td
                            style={{
                              paddingLeft: "90px",
                            }}
                          >
                            {currencyFormat(item.total)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  style={{
                    marginLeft: "auto",
                    display: "grid",
                    gridTemplateColumns: "repeat(8, 1fr)",
                    gap: "10px",
                    gridAutoRows: "100px",
                    marginRight: "auto",
                    zIndex: "100000",
                  }}
                >
                  <div
                    xs={12}
                    sm={6}
                    md={3}
                    class="floatLeft"
                    style={{ textAlign: "center", padding: 15 }}
                  >
                    <div
                      style={{
                        width: "200px",
                        height: "70px",
                        Align: "left",
                        border: "3px solid #000000",
                        zIndex: "100000",
                      }}
                    >
                      {this.state.invoicing_info}
                    </div>
                  </div>
                  <div
                    xs={12}
                    sm={6}
                    md={9}
                    class="floatRight"
                    dataSpan={3}
                    style={{ padding: "0px 0px 0px 100px" }}
                  >
                    <table>
                      <tr>
                        <th style={{ fontWeight: "bold", paddingLeft: "90px" }}>
                          <span>Subtotal</span>
                        </th>
                        <td style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                          <span>{currencyFormat(this.state.subTotal)}</span>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold", paddingLeft: "90px" }}>
                          <span>Discount</span>
                        </th>
                        <td style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                          <span>{currencyFormat(this.state.discount)}</span>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold", paddingLeft: "25px" }}>
                          <span>Subtotal less disct</span>
                        </th>
                        <td style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                          <span>
                            {currencyFormat(
                              this.state.subTotal - this.state.discount
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "90px",
                          }}
                        >
                          <span>Tax</span>
                        </th>
                        <td
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "50px",
                          }}
                        >
                          <span>{currencyFormat(this.state.tax)}</span>
                        </td>
                      </tr>
                      {this.state.payment.balance ? (
                        <>
                          <tr>
                            <th
                              style={{
                                fontWeight: "bold",
                                paddingLeft: "15px",
                              }}
                            >
                              <span>Previous Payments</span>
                            </th>
                            <td
                              style={{
                                fontWeight: "bold",
                                paddingLeft: "50px",
                              }}
                            >
                              <span>
                                {currencyFormat(
                                  (this.state.total.cents -
                                    (this.state.payment.balance.cents +
                                      this.state.payment.amount.cents)) /
                                    100
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                fontWeight: "bold",
                                paddingLeft: "90px",
                              }}
                            >
                              <span>Total</span>
                            </th>
                            <td
                              style={{
                                fontWeight: "bold",
                                paddingLeft: "50px",
                              }}
                            >
                              <span>
                                {currencyFormat(
                                  (this.state.payment.balance.cents +
                                    this.state.payment.amount.cents) /
                                    100
                                )}
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <th
                            style={{ fontWeight: "bold", paddingLeft: "90px" }}
                          >
                            <span>Total</span>
                          </th>
                          <td
                            style={{ fontWeight: "bold", paddingLeft: "50px" }}
                          >
                            <span>{currencyFormat(this.state.total)}</span>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <th style={{ fontWeight: "bold", paddingLeft: "60px" }}>
                          <span>Amount Paid</span>
                        </th>
                        <td style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                          <span>
                            {currencyFormat(this.state.payment.amount)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold", paddingLeft: "90px" }}>
                          <span>Balance</span>
                        </th>
                        <td style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                          <span>
                            {this.state.payment.balance
                              ? currencyFormat(
                                  this.state.payment.balance.cents / 100
                                )
                              : currencyFormat(
                                  this.state.total -
                                    this.state.payment.amount.cents / 100
                                )}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <br />
                  <br />
                </div>
                <br />
                <br />
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <div id="invoice-POS">
                <center id="top">
                  <div className="info">
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <table
                          style={{
                            width: "100%",
                            fontWeight: "bold",
                            margin: "10px 5px",
                            padding: "5px 10px",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tr>
                            <td>
                              {getString().refNo}:{" "}
                              {this.state.payment.receipt_number}
                            </td>
                            <td>
                              <h2
                                style={{
                                  fontSize: "30px",
                                  margin: "10px 5px",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                {cookies.load("orgName")}
                              </h2>
                              <h3
                                style={{
                                  fontSize: "20px",
                                  margin: "10px 5px",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                {cookies.load("orgAddress")}
                              </h3>
                              <h3
                                style={{
                                  fontSize: "20px",
                                  margin: "10px 5px",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                {cookies.load("orgContact")}
                              </h3>
                            </td>
                            <td>
                              <img
                                alt="logo"
                                src={
                                  baseUrl +
                                  "/picture/" +
                                  cookies.load("orgId") +
                                  "?tag=image&model=Afrijula::Account&r=" +
                                  this.state.picture
                                }
                              />
                            </td>
                          </tr>
                        </table>
                      </GridItem>
                    </GridContainer>
                  </div>
                </center>
                <div id="mid">
                  <div className="info">
                    <table
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        margin: "10px 5px",
                        padding: "5px 10px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tr>
                        <td>
                          {getString().name}:{" "}
                          {this.state.customer
                            ? this.state.customer.name
                            : "none"}
                        </td>
                        <td>
                          {getString().date}:{" "}
                          {dateFormat(this.state.payment.date)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {getString().email}:{" "}
                          {this.state.customer
                            ? this.state.customer.email
                            : "none"}
                        </td>
                        <td>
                          Method of Payment: {this.state.payment.payment_option}
                        </td>
                      </tr>
                    </table>
                    <table
                      style={{
                        fontWeight: "bold",
                        margin: "10px 5px",
                        padding: "5px 10px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tr>
                        <td>
                          {getString().invoice_no} :{" "}
                          {this.state.invoice
                            ? this.state.invoice.number
                            : "one off"}
                        </td>
                        <td>
                          {getString().received_by}:{" "}
                          {this.state.payment.details
                            ? this.state.payment.details.paid_by
                            : "none"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {getString().for}: {this.state.description}
                        </td>
                        <td />
                      </tr>
                    </table>
                  </div>
                </div>
                <div id="bot">
                  <div id="table">
                    <table
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        border: "1px solid #000",
                        margin: "10px 5px",
                        padding: "5px 10px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              border: "1px solid #000",
                              textAlign: "left",
                            }}
                          >
                            {"Amount Paid"}
                          </td>

                          <td
                            style={{
                              fontWeight: "bold",
                              border: "1px solid #000",
                              textAlign: "right",
                            }}
                          >
                            {currencyFormat(this.state.payment.amount)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              border: "1px solid #000",
                              textAlign: "left",
                            }}
                          >
                            {"Balance"}
                          </td>

                          <td
                            style={{
                              fontWeight: "bold",
                              border: "1px solid #000",
                              textAlign: "right",
                            }}
                          >
                            {currencyFormat(this.state.payment.balance)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h2
                      style={{
                        margin: "10px 5px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      Thank You!
                    </h2>
                  </div>
                </div>
              </div>
            </GridContainer>
          )}
        </div>
        <br />
        <br />
      </div>
    );
  }
}

Receipt.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Receipt);
