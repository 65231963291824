import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Iframe from "react-iframe";
import Menu from "@material-ui/core/Menu/Menu";
import Rodal from "rodal";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import cookies from "react-cookies";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {setGlobal,execPrint,applyPackaging,displayError,displaySuccess,getReportUrl,loadReport,getString} from "assets/js/utils/utils.js";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Select from "react-select";


class ReportView extends React.Component {

    state = {
        periods: [
            {
                value: 'day',
                label: getString().day,
            },
            {
                value: 'week',
                label: getString().week,
            },
            {
                value: 'month',
                label: getString().month,
            },
            {
                value: 'year',
                label: getString().year,
            },

        ],
             report: {view:false, content:null,name:null, params:null,baseUrl:null,parts:null},
             pages: [],
             currentPage:null,
             openEmail:false,
             scheduleEmail: false,
             repeatPeriod: 'day',
             repeatCount: 1,
             stopAfter: 'never',
             stopDate:'',
             nbTry:'',
             stopAfterValue:'',
    };

    componentDidMount() {
        setGlobal(this);
    }
    componentDidUpdate(){
        applyPackaging(this);
    };

    hideReport = () => {
        this.setState({report: {view: false, name:null, url:null},currentPage:null});
    };

    downloadURL = (url) => {
        if( $('#idown').length ){
            $('#idown').attr('src',url);
        }else{
            $('<iframe>', { id:'idown', src:url }).hide().appendTo('body');
        }
    };

    getReportUrl= () => {
      return getReportUrl(this.state.report.baseUrl, this.state.report.parts);
    };

    toPdf = () => {
        this.downloadURL(this.getReportUrl()+'&format=application/pdf&name='+this.state.report.name)
    };

    toWord = () => {
        this.downloadURL(this.getReportUrl()+'&format=application/vnd.openxmlformats-officedocument.wordprocessingml.document&name='+this.state.report.name)
    };

    toXsl = () => {
        this.downloadURL(this.getReportUrl()+'&format=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet&name='+this.state.report.name)
    };

    toEmail = () => {
        this.setState({openEmail: true});
    };

    getType = (params,attr) => {
        for (var i = 0; i < params.length; i++){
            if(params[i].name == attr && params[i].type == 'date')
                return true;
        }
        return false;
    };

    handleReportChange =(e) => {
        let params = this.state.report;

        if(this.getType(params.params,e.target.name)){
            params.parts.params[e.target.name] = new Date(Date.parse(e.target.value));
        }else{
            params.parts.params[e.target.name] = e.target.value;
        }
        loadReport(params.baseUrl,params.parts,params.params);
    };

    loadParams = () => {

        const getDefault = (pa) => {
            if(pa.type == 'date'){
                return pa.default.toISOString().substr(0,10);
            }else{
                return pa.default;
            }
        };

        const params = this.state.report.params;
        if (params) {
            const _this= this;
            return <div>
                {params.map(function (param) {
                    return <FormControlLabel control={<TextField
                        required
                        id={param.name}
                        name={param.name}
                        inputProps={{style: {padding: "4px", height: "1.5em"}}}
                        defaultValue={getDefault(param)}
                        onChange={(e) => {
                            _this.handleReportChange(e);
                        }}
                        margin="none"
                        type={param.type}
                        variant="outlined"
                    />} label={param.label} labelPlacement={'start'}/>

                })}
            </div>
        }else{
                return <div/>
            }
    }

    validate = (params) => {
        let errors = [];
        if(!params.to){
            errors.push("You must specify and email address");
            return errors;
        }
        let parts = params.to.split(',');
        let valid = true;
        let invalidEmail = null;
        parts.forEach(function(item){
            if(valid && /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(item))
                valid = true;
            else{
                valid = false;
                invalidEmail = item;
            }
        });
        if(!valid){
            errors.push("Invalid email format "+invalidEmail);
        }
        if(!params.subject){
            errors.push("You must specify a valid subject");
        }
        if(!params.body){
            errors.push("You must specify a detailed message")
        }
        return errors;
    };

    sendEmail = () => {

        let schedule = null;
        if(this.state.scheduleEmail){
            let val = null;
            if (this.state.stopAfter == 'givenDate')
                val = this.state.stopDate;
            else if(this.state.stopAfter == 'numberOfTries')
                val = this.state.nbTry;

            schedule = JSON.stringify({count: this.state.repeatCount,period:this.state.repeatPeriod,after:{when:this.state.stopAfter,if:val}})
        }

        let params = {to: $('#emailsToSend')[0].value, subject: $('#emailSubject')[0].value, body:$('#emailBody')[0].value,from:'no_reply@afrijula.gm'};
        let p = this.validate(params);
        if(p.length > 0){
            alert(p[0]);
            return;
        }
        this.handleClose();
        let urls = this.getReportUrl();
        let url = urls.split('?')[1];

        let urlToSend = baseUrl+'/reports/email/email?'+url+'&format=application/pdf'+'&email='+JSON.stringify(params);

        if(schedule){
            urlToSend += '&schedule='+schedule;
        }
        $.ajax({
            url: urlToSend,
            type: 'GET',
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: 'json',
            success: function (data) {
                displaySuccess(data.success);
            },
            error: function (err) {
                alert(err.responseText);
            }
        });

        this.setState({scheduleEmail: false});


    };

    handleClose = () => {
        this.setState({openEmail:false,scheduleEmail: false});
    };

    toPrint = () => {
        let _this = this;
        var item = $('#reportFrame').contents().find('html');
        if(item.lenth == 0){
            alert("The report has not fully loaded");
            return;
        }
        $('#reportFrame').contents().find('.jrPage').each(function(idx,y){
            if($(this)[0] !== _this.state.currentPage[0]){
                $(_this.state.pages[idx]).show();
            }
        });
        execPrint(item[0]);
        $('#reportFrame').contents().find('.jrPage').each(function(idx){
            if($(this)[0] !== _this.state.currentPage[0]){
                $(_this.state.pages[idx]).hide();
            }
        });
    };

    loadOptions = ()=>{
        let _this = this;
        return this.state.pages.map((page,idx)=>{
            return <MenuItem value={idx} onClick={(item)=> {
                _this.state.currentPage.hide();
                _this.state.currentPage = _this.state.pages[item.currentTarget.value];
                _this.state.currentPage.show();
                _this.setState({anchorEl:null});
            }}>{this.state.pageList[idx]}</MenuItem>
        });

    };

    handleStop = (e)=>{
        let stop="";
        let stopValue ='';
        switch (e.target.value ) {
            case "never":{
                stop = e.target.value;
                stopValue = e.target.value;
                break;
            }
            case "givenDate":{
                stop = e.target.value;
                stopValue = this.state.stopDate;
                break;
            }
            case "numberOfTries":{
                stop = e.target.value;
                stopValue = this.state.nbTry;
                break;
            }
        }
        this.setState({
            stopAfter: stop,
            stopAfterValue:stopValue
        });
    };

    toSchedule = (classes) =>{
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader >
                            <h4 className={classes.cardTitleWhite} style={{color:'#000'}}>{getString().schedule}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <div>{getString().repeat}</div>
                                <div>
                                    <TextField
                                        autoFocus
                                        id="repeatCount"
                                        label={getString().every}
                                        name="repeatCount"
                                        className={classes.textField}
                                        value={this.state.repeatCount}
                                        onChange={e => {this.handleChange(e)}}
                                        margin="normal"
                                        type="number"
                                        variant="outlined"
                                        InputProps={{ inputProps: { min: 1, max: 31 } }}
                                    />
                                </div>
                                <div>
                                    <Select options={this.state.periods} placeholder={getString().every} name="repeatPeriod" id="repeatPeriod"
                                            defaultValue={this.state.periods[0]} onChange={opt => {this.setState({repeatPeriod: opt.label})}}  />
                                </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3} style={{marginTop:"25px"}}>
                                    <span>{getString().stop}</span>
                                    <RadioGroup aria-label="position" name="position" value={this.state.stopAfter} onChange={(e)=>{this.handleStop(e)}} >
                                        <FormControlLabel
                                            value="never"
                                            control={<Radio color="primary" />}
                                            label={getString().never}
                                            labelPlacement="end"
                                            style={{color:'#000'}}
                                        />
                                        <FormControlLabel
                                            value="givenDate"
                                            control={<Radio color="primary" />}
                                            label={
                                                <TextField
                                                    id="stopDate"
                                                    name="stopDate"
                                                    label="On"
                                                    className={classes.textField}
                                                    value={this.state.stopDate}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    type="date"
                                                    variant="outlined"
                                                    style={{zIndex: '999'}}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            value="numberOfTries"
                                            control={<Radio color="primary" />}
                                            label={
                                                <TextField
                                                    id="nbTry"
                                                    label={getString().numTimes}
                                                    name="nbTry"
                                                    className={classes.textField}
                                                    value={this.state.nbTry}
                                                    onChange={e => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                    style={{zIndex: '999'}}
                                                />
                                            }
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value}
            );

    };

    handleSchedule = (e)=>{
        let schedule = (e.target.value === 'yes' )? true : false;
        this.setState({
            scheduleEmail: schedule,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Rodal height={"auto"} customStyles={{top: 130}} width={"auto"} className={"largeRodal"} visible={this.state.report.view} showMask={false} onClose={this.hideReport.bind(this)}>
                    <div style={{height:"100%", width:"100%",position:'relative'}}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <Button xs={12} sm={12} md={2} size={"sm"} color="primary" onClick={this.toPdf}><i className="material-icons">picture_as_pdf</i></Button>
                                                <Button xs={12} sm={12} md={2} size={"sm"} color="primary" onClick={this.toWord}>Word</Button>
                                                <Button xs={12} sm={12} md={2} size={"sm"} color="primary" onClick={this.toXsl}>Excel</Button>
                                                <Button xs={12} sm={12} md={2} size={"sm"} color="success" onClick={this.toEmail}><i className="material-icons">email</i></Button>
                                                <Button xs={12} sm={12} md={2} size={"sm"} color="warning" onClick={this.toPrint}><i className="material-icons">print</i></Button>
                                                <Button style={{float:'right'}}xs={12} sm={12} md={2} size={"sm"} color="info" aria-owns={this.state.anchorEl ? 'simple-menu' : undefined} aria-haspopup={true} onClick={event =>{
                                                    this.setState({anchorEl:event.currentTarget});
                                                }}>Pages</Button>
                                                <Menu id="simple-menu" style={{zIndex:200000000}} anchorEl={this.state.anchorEl} open={this.state.anchorEl ? true : false}
                                                      onClose={event=>{this.setState({enchorEl: null})}}>
                                                    {this.loadOptions()}
                                                </Menu>


                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={5}>
                                                {this.loadParams()}
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <div style={{position:'absolute',top:'150px',bottom:'0',overflow:'hidden',left:'0',right:'0'}}>
                            <LinearProgress className={classes.progress} style={{display: (this.state.currentPage) ? 'none' :'block'}}/>
                            <LinearProgress className={classes.progress} style={{display: (this.state.currentPage) ? 'none' :'block'}} color="secondary" />

                            <Iframe frameBorder={0}
                                    loading={"auto"}
                                    width={'100%'}
                                    height={'100%'}
                                    id={"reportFrame"}
                                    display={"initial"}
                                    position={"relative"}/>
                        </div>
                    </div>
                </Rodal>
                <Dialog open={this.state.openEmail} onClose={this.handleClose} style={{zIndex:100000}}>
                    <DialogContent>
                        <DialogContentText>
                            {getString().email_report}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="emailsToSend"
                            name="emailsToSend"
                            label={getString().send_to}
                            onChange={e => {this.handleChange(e)}}
                            type="email"
                            fullWidth
                        />
                        <TextField
                        autoFocus
                        margin="dense"
                        id="emailSubject"
                        name="emailSubject"
                        label={getString().subject}
                        onChange={e => {this.handleChange(e)}}
                        type="text"
                        fullWidth
                        />
                        <TextField
                        autoFocus
                        margin="dense"
                        id="emailBody"
                        name="emailBody"
                        label={getString().message}
                        onChange={e => {this.handleChange(e)}}
                        type="text"
                        fullWidth
                        />
                        <span>{getString().schedule}</span>
                        <RadioGroup
                            aria-label="Gender"
                            name="enable_notifications"
                            className={classes.group}
                            value={(this.state.scheduleEmail) ? 'yes' : 'no'}
                            onChange={e => this.handleSchedule(e)}
                            row={true}
                        >
                            <FormControlLabel value="no" control={<Radio />} label="no" />
                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                        </RadioGroup>
                        </DialogContent>
                    {(this.state.scheduleEmail) ? this.toSchedule(classes) : null}
                        <DialogActions>
                            <Button onClick={this.handleClose} color="warning">
                                {getString().cancel}
                            </Button>
                            <Button onClick={this.sendEmail} color="primary">
                                {getString().send}
                            </Button>
                    </DialogActions>
                </Dialog>

            </div>
    );
    }
}

ReportView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(ReportView);
