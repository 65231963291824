import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import {
  displayError,
  displaySuccess,
  getString,
  phonenumber,
  validateEmail,
} from "assets/js/utils/utils.js";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../common/CustomButtons/Button";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  select: {
    control: (base) => ({
      ...base,
      height: 135,
      minHeight: 135,
    }),
  },
});
const recaptchaRef = React.createRef();

class Contact extends React.Component {
  state = {
    contactData: {
      name: "",
      phone_number: "",
      business_location: "",
      email: "",
      message: "",
      last_name: "",
      subject: "",
    },
    recapterValue: "",
    disable: false,
    subjects: [
      { label: "Support", value: "Support" },
      { label: "Enquiry", value: "Enquiry" },
    ],
  };

  onChange = (value) => {
    this.setState({ recapterValue: value });
  };

  handleChange = (e) => {
    let contact = this.state.contactData;
    contact[e.target.name] = e.target.value;
    this.setState({ contactData: contact });
  };

  clearForm = () => {
    let contactForm = { ...this.state.contactData };
    contactForm.name = "";
    contactForm.business_location = "";
    contactForm.last_name = "";
    contactForm.phone_number = "";
    contactForm.message = "";
    contactForm.subject = "";
    contactForm.email = "";

    this.setState({ contactData: contactForm });
  };

  updateBtn = () => {
    this.setState({ disable: !this.state.disable });
  };

  newContact = () => {
    let _this = this;

    let contact = {
      name: this.state.contactData.name.trim(),
      business_location: this.state.contactData.business_location.trim(),
      last_name: this.state.contactData.last_name.trim(),
      email: this.state.contactData.email.trim(),
      phone_number: this.state.contactData.phone_number.trim(),
      message: this.state.contactData.message.trim(),
      subject: this.state.contactData.subject,
    };

    let reg = /^[A-zÀ-ú._]+(?:[\s'-][A-zÀ-ú._]+)*$/;
    if (!this.state.recapterValue) {
      displayError(getString().recapcha_err);
      return;
    }
    if (contact.name === "" || !reg.test(contact.name)) {
      displayError("Please enter your first name");
      document.getElementById("name").focus();
      return;
    }
    if (contact.last_name === "") {
      displayError("Please enter your last name");
      document.getElementById("last_name").focus();
      return;
    }
    if (contact.phone_number === "" || !phonenumber(contact.phone_number)) {
      displayError("Please enter telephone number");
      document.getElementById("phone_number").focus();
      return;
    }
    if (contact.email !== "" && !validateEmail(contact.email)) {
      displayError("Please enter a valid email address");
      document.getElementById("email").focus();
      return;
    }
    if (
      contact.business_location === "" ||
      !reg.test(contact.business_location)
    ) {
      displayError("Please enter contact person's name");
      document.getElementById("business_location").focus();
      return;
    }

    _this.updateBtn();
    let _body = `
            <h2>Dear Afrijula Support Team</h2>
            <p>${contact.message}</p>
            <h5>From: ${contact.name} ${contact.last_name}</h5>
            <h5>Phone: ${contact.phone_number}</h5>
            <h5>Email: ${contact.email}</h5>
            <h5>Location: ${contact.business_location}</h5>
        `;

    let _subject = `Afrijula ${contact.subject} email from ${contact.name} ${
      contact.last_name
    }`;

    let r = Math.random().toString(36);
    cookies.save("User-Key", r);
    $.ajax({
      method: "POST",
      url: baseUrl + "/mail_v2",
      dataType: "json",
      headers: { UserKey: r },
      data: {
        to: "info@afrijula.gm",
        from: contact.email,
        reply_name: `${contact.name} ${contact.last_name}`,
        sent_by: "no_reply@afrijula.gm",
        subject: _subject,
        body: _body,
        domain: "afrijula.gm",
        from_landing: true,
      },

      success: function(result) {},
      error: function(resp) {
        _this.updateBtn();
      },
    });

    $.ajax({
      method: "POST",
      url: baseUrl + "/mail_v2",
      dataType: "json",
      headers: { UserKey: r },
      data: {
        to: "support@insistglobal.com",
        from: contact.email,
        reply_name: `${contact.name} ${contact.last_name}`,
        sent_by: "no_reply@afrijula.gm",
        subject: _subject,
        body: _body,
        domain: "afrijula.gm",
        from_landing: true,
      },

      success: function(result) {
        displaySuccess(result.msg);
        _this.clearForm();
        _this.updateBtn();
      },
      error: function(resp) {
        displayError(resp.responseText);
        _this.updateBtn();
      },
    });
  };

  back = () => {
    this.setState({ backToContact: true });
  };

  close = () => {
    this.setState({
      id: null,
      backToContact: true,
    });
    if (this.props.closeInvoice) {
      this.props.closeInvoice();
    }
    if (this.props.closeEdit) {
      this.props.closeEdit();
    }
  };

  render() {
    if (this.state.backToContact) {
      let ChildComponent = this.state.callerName;
      let caller_state = this.state.callerState;
      caller_state.addsupplier = false;
      return (
        <ChildComponent
          state={caller_state}
          returningProps={this.state.callerProps}
        />
      );
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <Card style={{ backgroundColor: "#cecece" }}>
            <CardHeader className="zoo">
              {this.props.editSupplier ? (
                <h4>{getString().edit}</h4>
              ) : (
                <h2>
                  <b>{getString().contact_us}</b>
                </h2>
              )}
            </CardHeader>
            <CardBody>
              <div className="col-md-6">
                <TextField
                  required
                  id="name"
                  name="name"
                  fullWidth="100%"
                  label={getString()._name}
                  className="cheese"
                  value={this.state.contactData.name}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  required
                  id="phone_number"
                  name="phone_number"
                  fullWidth="100%"
                  label={getString().telephone}
                  className="cheese"
                  value={this.state.contactData.phone_number}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="business_location"
                  name="business_location"
                  fullWidth="100%"
                  label={getString().business_location}
                  className="cheese"
                  value={this.state.contactData.business_location}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  required
                  id="last_name"
                  name="last_name"
                  fullWidth="100%"
                  label={getString().last_name}
                  className="cheese"
                  value={this.state.contactData.last_name}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="email"
                  name="email"
                  fullWidth="100%"
                  label={getString().email}
                  className="cheese"
                  value={this.state.contactData.email}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  type="email"
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="subject"
                  name="subject"
                  label={getString().subjects}
                  className="cheese"
                  fullWidth="100%"
                  select
                  value={this.state.contactData.subject}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {this.state.subjects.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div style={{ margin: "15px" }}>
                <textarea
                  className="col-md-12"
                  name="message"
                  id="message"
                  value={this.state.contactData.message}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  style={{ marginBottom: 10 + "px" }}
                  placeholder="Please enter your message"
                  rows="10"
                />
              </div>
              <div style={{ textAlign: "center" }}>
                {/* <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6Ld4gKQUAAAAAMq7CZ7cl6n7ElPxwDkxw7pMP9j3"
                                    onChange={this.onChange}
                                    style={{display: 'inline-block'}}
                                /> */}
                <GoogleReCaptchaProvider reCaptchaKey="6LfgkUwbAAAAAPuRhn1Jpy5ARdilCPrthGFhs7Mi">
                  <GoogleReCaptcha
                    onVerify={(token) => {
                      this.setState({ recapterValue: token });
                    }}
                  />
                </GoogleReCaptchaProvider>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  sm={6}
                  md={2}
                  color="success"
                  onClick={this.newContact}
                  disabled={this.state.disable}
                  style={{
                    backgroundColor: "#9ccb3b !important",
                    marginLeft: "40px !important",
                  }}
                >
                  {<b>SEND MESSAGE</b>}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
