import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "react-select";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Button from "components/common/CustomButtons/Button.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  displayError,
  getString,
  displaySuccess,
  getToday,
  currencyFormat,
  currencyValue,
} from "assets/js/utils/utils.js";

import $ from "jquery";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import TextField from "@material-ui/core/TextField";
import SaveButton from "../CustomComponents/SaveButton";
import { getLocale } from "../../../assets/js/utils/utils";

class Withdraw extends React.Component {
  state = {
    withdraw_methods: [
      { label: getString().cheque, value: "cheque" },
      { label: getString().bank_trans, value: "bank_transfer" },
      { label: getString().mobile_money, value: "mobile_money" },
      { label: getString().other, value: "other" },
    ],
    back: false,
    banks: [],
    callerName: this.props.callerName,
    callerState: this.props.callerState,
    callerProps: this.props.callerProps,
    showBalance: false,
    withdrawData: {
      fromAccount: "",
      fromAccountNumber: "",
      fromAccountId: "",
      date: "",
      amount: "",
      refNumber: "",
      recipient: "",
      withdrawMethod: {},
    },
    fromAccountBal: {},
    senderId: "",
  };

  submit = () => {
    let data = this.state.withdrawData;
    let balance = currencyValue(this.state.fromAccountBal.balance, false);
    if (data.amount > balance) {
      displayError(getString().err_withdraw_amount);
      document.getElementById("amount").focus();
      return;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <p>
              {currencyFormat(data.amount) +
                getString().will_be_withdrawn +
                data.fromAccount}
            </p>
            <button onClick={onClose}>{getString().cancel}</button>
            <button
              onClick={() => {
                this.sendData();
                onClose();
                this.clearFields();
              }}
            >
              {getString().yes}
            </button>
          </div>
        );
      },
    });
  };

  sendData = () => {
    let _withdrawData = this.state.withdrawData;
    let fromAccount = _withdrawData.fromAccount;
    let fromAccountNumber = _withdrawData.fromAccountNumber;
    let amount = _withdrawData.amount;
    let date = _withdrawData.date;
    let refNumber = _withdrawData.refNumber;
    let recipient = _withdrawData.recipient;
    let withdrawMethod = _withdrawData.withdrawMethod.value;

    let withdrawnFromPrevBal = currencyValue(
      this.state.fromAccountBal.balance,
      false
    );
    let withdrawnFromPrevLocBal = currencyValue(
      this.state.fromAccountBal.local_balance,
      false
    );
    let newWithdrawnFromBal = withdrawnFromPrevBal - amount;
    let newWithdrawnFromLocBal = withdrawnFromPrevLocBal - amount;
    let _data = {
      newBal: newWithdrawnFromBal,
      newLocBal: newWithdrawnFromLocBal,
    };
    let _this = this;

    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/cashbook/withdrawals",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        from_account: fromAccount,
        account_number: fromAccountNumber,
        date: date,
        amount: amount,
        reference_number: refNumber,
        recipient: recipient,
        withdraw_method: withdrawMethod,
      },
      success: function(result) {
        let lang = getLocale();
        if (lang == "en-GB" || lang == "en-US") {
          displaySuccess(
            `${amount} has been withdrawn from ${fromAccount} on ${date}`
          );
        } else {
          displaySuccess(getString().withdraw_success);
        }
        if (_this.props.updateMe) {
          _this.props.updateMe(_data);
        }
        _this.close();
      }.bind(this),
      error: function(req, status, error) {
        displayError(req.responseText);
      },
    });
  };

  clearFields = () => {
    let withdrawDetails = this.state.withdrawData;
    withdrawDetails.fromAccount = "";
    withdrawDetails.fromAccountNumber = "";
    withdrawDetails.amount = "";
    withdrawDetails.cheque_number = "";
    withdrawDetails.recipient = "";
    withdrawDetails.withdrawMethod = {};
    withdrawDetails.date = getToday();

    this.setState({
      withdrawData: withdrawDetails,
      showBalance: false,
    });
  };

  updateSrc = (event) => {
    if (event.status === "blocked") {
      displayError(getString().disabled_account_trans);
      this.setState({
        showBalance: false,
      });
      return;
    } else {
      let withdrawDetails = this.state.withdrawData;
      withdrawDetails.fromAccount = event.label;
      withdrawDetails.fromAccountNumber = event.number;
      withdrawDetails.fromAccountId = event.value;

      this.setState({
        fromAccountBal: event,
        withdrawData: withdrawDetails,
        showBalance: true,
        senderId: event.value,
      });
    }
  };

  handleChange = (event) => {
    let withdrawDetails = this.state.withdrawData;
    withdrawDetails[event.target.name] = event.target.value;
    this.setState({
      withdrawData: withdrawDetails,
    });
  };

  accountOptions = () => {
    let the_data = [];
    const filter = "status:blocked";
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/cashbook/bank_account",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        attrs: [
          "id",
          "name",
          "number",
          "bank",
          "currency",
          "balance",
          "local_balance",
          "status",
        ],
        order: [{ by: "desc", attr: "name" }],
        model: "Afrijula::Cashbook::BankAccount",
        filter,
      },

      success: function(data) {
        $.map(data, function(data) {
          the_data.push({
            label: data.name,
            value: data._id.$oid,
            bank: data.bank,
            currency: data.currency,
            number: data.number,
            balance: data.balance,
            local_balance: data.local_balance,
            status: data.status,
          });
        });
        this.setState({
          banks: the_data,
        });
      }.bind(this),
    });
  };

  handleWithdrawMthd = (e) => {
    let withdrawDetails = this.state.withdrawData;
    withdrawDetails.withdrawMethod = e;
    this.setState({
      withdrawData: withdrawDetails,
    });
  };

  close = () => {
    this.setState({
      back: true,
    });
  };

  componentWillMount() {
    let withdrawDetails = this.state.withdrawData;
    withdrawDetails.date = getToday();
    withdrawDetails.withdrawMethod = this.state.withdraw_methods[0];
    this.setState({
      withdrawData: withdrawDetails,
    });
  }

  componentDidMount() {
    if (this.props.fromAccount) {
      let fromAccount = this.props.fromAccount;
      let withdrawDetails = this.state.withdrawData;
      withdrawDetails.fromAccount = fromAccount.name;
      withdrawDetails.fromAccountNumber = fromAccount.number;
      withdrawDetails.fromAccountId = fromAccount._id.$oid;

      this.setState({
        withdrawData: withdrawDetails,
        fromAccountBal: fromAccount,
      });
    }
    this.accountOptions();
  }

  render() {
    const { classes } = this.props;

    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let caller_state = this.state.callerState;
      caller_state.add_withdraw = false;
      return (
        <ChildComponent
          state={caller_state}
          returningProps={this.state.callerProps}
        />
      );
    } else {
      return (
        <div style={{ marginTop: -40 }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <h4>{getString().withdraw_amount}</h4>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    {!this.props.fromAccount ? (
                      <GridItem xs={12} sm={6} md={4} style={{ marginTop: 15 }}>
                        <Select
                          options={this.state.banks}
                          defaultOptions
                          placeholder={getString().select_withd_from_acc}
                          defaultValue={this.state.withdrawData.fromAccount}
                          onChange={(event) => {
                            this.updateSrc(event);
                          }}
                        />
                        {this.state.showBalance ? (
                          <span>
                            {getString().current_bal +
                              currencyFormat(this.state.fromAccountBal.balance)}
                          </span>
                        ) : null}
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={6} md={4}>
                        <span>
                          {getString().account +
                            ": " +
                            this.props.fromAccount.name}
                        </span>
                        <br />
                        <span>
                          {getString().current_bal +
                            currencyFormat(this.props.fromAccount.balance)}
                        </span>
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="date"
                        name="date"
                        label={getString().date}
                        className={classes.textField}
                        value={this.state.withdrawData.date}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        type="date"
                        inputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        id="recipient"
                        name="recipient"
                        label={getString().recipient}
                        className={classes.textField}
                        value={this.state.withdrawData.recipient}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        inputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="amount"
                        name="amount"
                        label={getString().amount}
                        className={classes.textField}
                        value={this.state.withdrawData.amount}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        inputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <span>{getString().withdraw_method}</span>
                      <Select
                        id="withdrawMethod"
                        name="withdrawMethod"
                        options={this.state.withdraw_methods}
                        defaultValue={this.state.withdrawData.withdrawMethod}
                        onChange={(e) => {
                          this.handleWithdrawMthd(e);
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="refNumber"
                        name="refNumber"
                        label={getString().refNo}
                        className={classes.textField}
                        value={this.state.withdrawData.refNumber}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                        inputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      xs={12}
                      sm={6}
                      md={2}
                      color="danger"
                      onClick={this.close.bind(this)}
                      style={{
                        backgroundColor: "#f44336",
                        margin: ".3125rem 1px",
                        minWidth: "auto",
                        minHeight: "auto",
                        fontSize: "12px",
                        color: "#ffffff",
                        padding: "12px 30px",
                        borderRadius: "3px",
                        verticalAlign: "middle",
                      }}
                    >
                      {getString().cancel}
                    </Button>
                    <SaveButton
                      method={this.submit}
                      title={getString().withdraw}
                    />
                  </GridItem>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

Withdraw.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Withdraw);
