import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

// core components
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Button from "components/common/CustomButtons/Button.jsx";
import $ from "jquery";
import cookies from "react-cookies";

import baseUrl from "assets/js/config/const.js";
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, execPrint,
    thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";
import TextField from "@material-ui/core/TextField/TextField";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper/Paper";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    cardCategory:{
        color: "#000",
        margin: "0px",
        fontSize: "14px",
        fontWeight: "bolder",
        paddingTop: "4px",
    }

});

class DeleteRequests extends React.Component {
    constructor(props){
        super(props);

        this.requestTableRef = React.createRef();

    }

    state = {
        requestHeader:  [
            {title: getString().description, field:'reason', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
            {title: getString().date, field:'created_at', render: rowData => dateFormat(rowData.created_at), cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
            {title: getString().requested_by,field:'requested_by', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},

        ],

        salesData:this.props.salesData,
        deleteReason: null,
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    componentWillMount(){
        if(this.props.showCustomer && this.state.requestHeader.length == 3){
            this.state.requestHeader.push({title: getString().customer, field:'customer', cellStyle:{padding:'2px', headerStyle:{padding:'2px'}}});
        }else if(!this.props.showCustomer && this.state.requestHeader.length == 3){
            this.state.requestHeader.push({title: getString().status,field:'status', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}});
        }
    }

    requestDelete = () => {
        const id = this.props.deletePayment;
        const reason = this.state.deleteReason;
        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/afrijula/sales/payments/" + id,
            data: {is_request:true, reason:reason},
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                _this.setState({deleteReason:null});
                _this.props.closeDelete();
                if(_this.requestTableRef.current)
                    _this.requestTableRef.current.onQueryChange();
                displaySuccess(result.success);
            }, error: function (error) {
                displayError(error.responseText);
            }
        });
    };

    approveRequests = (ids, type) => {
        let _this = this;
        if(ids.length > 1){
            displayError("You can only approve one request at a time")
            return;
        }
        $.ajax({
            method: "PUT",
            url: baseUrl + "/afrijula/sales/payments/" + ids[0],
            data: {is_approval:type},
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {

                if(_this.props.updateState)
                _this.props.updateState(result);

                if(_this.requestTableRef.current)
                    _this.requestTableRef.current.onQueryChange();
                displaySuccess(result.success);
            }, error: function (error) {
                displayError(error.responseText);
            }
        });
    };

    loadRequests = (query,resolve,reject) => {
        let url = null;
        if(this.state.salesData) {
            const model = (this.state.salesData.invoice) ? 'invoice' : 'sales';
            const id = (this.state.salesData.invoice) ? this.state.salesData.invoice._id.$oid : this.state.salesData._id.$oid;
            url = baseUrl+"/afrijula/sales/payments/reversal_requests?model=" + model + "&id=" + id;
        }else{
            url = baseUrl+"/afrijula/sales/payments/reversal_requests?pending_only=true"
        }
        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    render() {
        const {classes} = this.getProps();

            return (<div><Paper className={classes.paper}>
                    <MaterialTable
                        tableRef={this.requestTableRef}
                        title={getString().delete_requests}
                        data={query => new Promise((resolve, reject) => {
                            this.loadRequests(query, resolve, reject);
                        })}
                        columns={this.state.requestHeader}
                        options={{
                            exportButton: false, filtering: false,
                            sorting: false, search: false,
                            selection: true,
                            paging: false

                        }}
                        actions={[
                            {
                                tooltip: 'approve', icon: 'check',
                                onClick: (row, rows) => {
                                    let ids = rows.map((item) => {
                                        return item._id.$oid
                                    })
                                    this.approveRequests(ids, 'approve');
                                }
                            },
                            {
                                tooltip: 'reject', icon: 'clear',
                                onClick: (row, rows) => {
                                    let ids = rows.map((item) => {
                                        return item._id.$oid
                                    })
                                    this.approveRequests(ids, 'reject');
                                }
                            }
                        ]}/>
                </Paper>
                <Dialog
                    open={this.props.deletePayment}
                    aria-labelledby="form-dialog  -title"
                    maxWidth={'sm'}

                >
                    <DialogTitle id="form-dialog-title">{getString().reason}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="deleteReason"
                            label="Reason to delete payment"
                            value={this.state.deleteReason}
                            onChange={(e) => {
                                this.setState({deleteReason: e.target.value})
                            }}

                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.props.closeDelete();
                        }} color="danger">
                            {getString().cancel}
                        </Button>
                        <Button onClick={() => {
                            this.requestDelete()
                        }} color="info">
                            {getString().submit}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }
}

DeleteRequests.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeleteRequests);