import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import StockTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import StockDetails from "components/afrijula/AllDetails/StockDetails.jsx";
import NewStock from "components/afrijula/AddForm/AddStock.jsx";
import StockTransfer from "components/afrijula/Stocks/StockTransfer.jsx";
import FastMovingStock from "components/afrijula/FastMovingStock/FastMovingStock.jsx";
import {
  numberFormat,
  currencyFormat,
  dateFormat,
  compileQuery,
  applyPackaging,
  getString,
} from "assets/js/utils/utils.js";
import SupplierInvoice from "components/afrijula/AddForm/SupplierInvoice_v2.jsx";
import UpdateStockQty from "components/afrijula/Stocks/UpdateStockQty.jsx";
import StockPurchase from "components/afrijula/AllDetails/SupplierHistory.jsx";

import $ from "jquery";

import { completedTasksChart } from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import OutgoingStock from "components/afrijula/Stocks/OutgoingStock.jsx";
import WindowSizeListener from "react-window-size-listener";

class Stock extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    stockdetails: false,
    addstock: false,
    transferstock: false,
    stockData: null,

    data: [],

    stockInvoiceData: null,
    location: null,
    listData: {
      lowStock: [],
    },
    chartData: {
      stockSales: completedTasksChart,
    },
    testing: null,
    lastPage: null,
    lastSize: null,
    recalledPage: null,
    recalledPageSize: null,
    fastMoving: false,
    addSuppInv: false,
    updateQty: false,
    damages: false,
    winSize: window.innerWidth,
  };

  addStock = () => {
    const props = this.getProps();
    if (props.updateMe) props.updateMe(false);
    this.setState({ addstock: !this.state.addstock });
  };

  stocktransfer = () => {
    const props = this.getProps();
    if (props.updateMe) props.updateMe(false);
    this.setState({ transferstock: true });
  };

  addSupplierInv = () => {
    const props = this.getProps();
    if (props.updateMe) props.updateMe(false);
    this.setState({ addSuppInv: true });
  };

  updateQty = () => {
    const props = this.getProps();
    if (props.updateMe) props.updateMe(false);
    this.setState({ updateQty: true });
  };
  damages = () => {
    const props = this.getProps();
    if (props.updateMe) props.updateMe(false);
    this.setState({ damages: true });
  };

  loadDashData = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: {
        count: [],
        chart: [{ stock_sales: ["month", "day"] }],
        list: [{ low_stock: [5] }],
      },
      success: function(data) {
        let chartData = this.state.chartData;
        let listData = this.state.listData;

        chartData.stockSales.data = data.charts.stock_sales;
        listData.lowStock = data.lists.low_stock;

        _this.setState({ listData: listData, chartData: chartData });
      }.bind(this),
    });
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    this.loadStock(id);
  };

  loadStock = (id) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/stocks/stock/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        const props = _this.getProps();
        if (props.updateMe) props.updateMe(false);
        _this.setState({ stockData: result });
      },
    });
  };

  loadData = (query, resolve, reject) => {
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/stocks/stock",
      ["id", "name", "category", "quantity", "unit_price", "id", "number"],
      [{ by: "asc", attr: "name" }],
      "Afrijula::Stocks::Stock",
      null,
      null
    );
    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;
    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  componentDidMount() {
    const props = this.getProps();
    this.loadDashData();
    if (props.updateMe) props.updateMe(true);
  }

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  onSearchChange = (searchText) => {
    this.tableChangeQuery(baseUrl, searchText);
  };

  loadInvoice = (invoiceId) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/suppliers/invoice/" + invoiceId,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ stockInvoiceData: result });
      },
    });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  closeQtyUpdate = () => {
    this.setState({ updateQty: false });
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.stockInvoiceData) {
      let data = this.state.stockInvoiceData;
      this.state.stockInvoiceData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <StockPurchase
          invoiceData={data}
          callerName={Stock}
          callerProps={this.getProps()}
          callerState={this.state}
        />
      );
    } else if (this.state.stockData) {
      let data = this.state.stockData;
      this.state.stockData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <StockDetails
          callerProps={this.getProps()}
          callerState={this.state}
          stockData={data}
          callerName={Stock}
        />
      );
    } else if (this.state.addstock) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <NewStock
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Stock}
          editStock={false}
          saveStockCback={this.loadStock.bind(this)}
        />
      );
    } else if (this.state.addSuppInv) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <SupplierInvoice
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Stock}
          loadInvoice={this.loadInvoice.bind(this)}
        />
      );
    } else if (this.state.updateQty) {
      this.state.updateQty = false;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <UpdateStockQty
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Stock}
          loadInvoice={this.loadInvoice.bind(this)}
        />
      );
    } else if (this.state.damages) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <OutgoingStock
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Stock}
        />
      );
    } else if (this.state.transferstock) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <StockTransfer
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={Stock}
        />
      );
    } else if (this.state.fastMoving) {
      return <FastMovingStock />;
    } else {
      const header = [
        {
          title: getString()._name,
          field: "name",
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        },
        {
          title: getString().number,
          field: "number",
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        },
        {
          title: getString().category,
          field: "category",
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        },
        {
          title: getString().quantity,
          field: "quantity",
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        },
        {
          title: getString().unit_price,
          field: "unit_price",
          options: { filter: false },
          render: (rowData) => currencyFormat(rowData.unit_price),
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        },
      ];
      const lowHeader = [
        {
          title: getString()._name,
          field: "name",
          cellStyle: { padding: "4px 12px" },
          headerStyle: { padding: "4px 12px" },
        },
        {
          title: getString().quantity,
          field: "quantity",
          cellStyle: { padding: "4px" },
          headerStyle: { padding: "4px" },
        },
      ];
      return (
        <WindowSizeListener
          onResize={(size) => {
            this.setState({ winSize: size.windowWidth });
          }}
        >
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  onClick={this.addStock}
                  style={{
                    backgroundColor: "#9c27b0",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">add</i>
                  {getString().add_stock}
                </Button>
                <Button
                  disabled={applyPackaging("stocks_mgnt")}
                  xs={12}
                  sm={12}
                  md={2}
                  color="success"
                  onClick={this.stocktransfer}
                  style={{
                    backgroundColor: "#4caf50",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">swap_horiz</i>
                  {getString().transfer_stock}
                </Button>
                <Button
                  disabled={applyPackaging("stocks_mgnt")}
                  xs={12}
                  sm={12}
                  md={2}
                  color="info"
                  onClick={this.addSupplierInv.bind(this)}
                  style={{
                    backgroundColor: "#00acc1",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">book</i>
                  {getString().new_invoice}
                </Button>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="warning"
                  onClick={this.updateQty.bind(this)}
                  style={{
                    backgroundColor: "#ff9800",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">arrow_upward</i>
                  {getString().update_qty}
                </Button>

                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="info"
                  onClick={this.damages.bind(this)}
                  style={{
                    backgroundColor: "#00acc1",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">arrow_downward</i>
                  {getString().remove}
                </Button>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="danger">
                    <h4 className={classes.cardTitleWhite}>
                      {getString().low_stock}
                    </h4>
                  </CardHeader>
                  <Table
                    columns={lowHeader}
                    data={this.state.listData.lowStock}
                    title={getString().low_stock}
                    options={{
                      columnsButton: true,
                      sorting: true,
                      toolbar: false,
                      paging: false,
                      cellStyle: { padding: "2px" },
                      headerStyle: { padding: "2px" },
                    }}
                  />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card chart>
                  <CardHeader color="success">
                    <ChartistGraph
                      className="ct-chart"
                      data={this.state.chartData.stockSales.data}
                      type="Line"
                      options={() => {
                        let opts = this.state.chartData.stockSales.options;
                        opts.high = this.state.chartData.stockSales.data.max;
                        return opts;
                      }}
                      listener={this.state.chartData.stockSales.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <div className={classes.stats} style={{ float: "right" }}>
                      <span
                        onClick={() => {
                          this.setState({ fastMoving: true });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#800080",
                        }}
                      >
                        {getString().details}
                      </span>
                    </div>
                    <h4 className={classes.cardTitle}>
                      {getString().fast_moving_stock}
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <StockTable
                  tableRef={this.tableRef}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let page = this.recallPage();
                      if (page) {
                        query.page = page;
                      }

                      this.loadData(query, resolve, reject);
                    })
                  }
                  columns={header}
                  onRowClick={this.rowSelect}
                  options={{
                    exportButton: true,
                    filtering: true,
                    grouping: true,
                    sorting: true,
                    debounceInterval: 1000,
                    selection: true,
                    showTitle: false,
                    pageSize: this.state.recalledPageSize || 15,
                  }}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh",
                      isFreeAction: true,
                      onClick: () =>
                        this.tableRef.current &&
                        this.tableRef.current.onQueryChange(),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </WindowSizeListener>
      );
    }
  }
}

Stock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Stock);
