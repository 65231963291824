import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {
  getToday,
  getString,
  currencyFormat,
  displayError,
  validateNumberField,
} from "assets/js/utils/utils.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import SaveButton from "../CustomComponents/SaveButton";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class GenericPayment extends React.Component {
  state = {
    amount: 0,
    change: 0,
    date: "",
    made_by: "",
    onClose: this.props.onClose,
    onSave: this.props.onSave,
    total: 0,
    payment_option: "cash",
    bank: "",
    bank_no: "",
    cheque_no: "",

    caller: this.props.caller,
    callerProps: this.props.callerProps,
    callerState: this.props.callerState,
  };
  getTotal = () => {
    return this.props.totalAmount();
  };
  handleChange = (e) => {
    if (e.target.name === "amount") {
      let _change = parseFloat(e.target.value) - this.getTotal();
      this.setState({
        [e.target.name]: e.target.value,
        change: _change,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  cleanForm = () => {
    this.setState({
      made_by: "",
      bank: "",
      bank_no: "",
      cheque_no: "",
      amount: 0,
      change: 0,
    });
  };

  handlePaymentOptions = (e) => {
    let paymentOption = e.target.value;
    this.setState({
      payment_option: paymentOption,
    });
    if(this.props.updateMe){
        this.props.updateMe(paymentOption);
    }
  };

    sendPayment = () => {
        if(this.state.amount <= 0) {
            displayError(getString().err_valid_amount);
            return 
        }
        let amount = this.state.amount;
        if(this.state.caller === 'sell') {
            if (this.state.change < 0 && this.state.payment_option === "cash" ) {
                displayError("You must enter total amount due");
                return 
            }

            if (this.state.change > 0 && this.state.payment_option !== 'cash') {
                displayError("For check or bank payments you must enter the exact amount due.");
                return;
            }
            if(this.state.change > 0){
                amount = this.getTotal();
            }
        }
        if((this.state.payment_option === "cheque" || this.state.payment_option === "bank") && (this.state.bank.trim() ==="" || validateNumberField(this.state.bank))){
            displayError(getString().errInvalid_bankName);
            return
        }
        if((this.state.payment_option === "cheque" && (this.state.cheque_no.trim() ==="" || !validateNumberField(this.state.cheque_no.trim())))){
            displayError(getString().err_invalid_checkNo);
            return;
        }
        //sbensouda: bank number should not be a requirement. I may not have it.
        else {
            return this.props.onSave(this.state.payment_option, amount,this.state.amount,
                this.state.date, this.state.made_by, this.state.bank,
                this.state.payment_option === "cheque" ? this.state.cheque_no : this.state.bank_no,
                this.cleanForm.bind(this))
        }
    };

  componentDidMount() {
    this.setState({
      date: getToday(),
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      return (
        <ChildComponent
          state={this.state.callerState}
          returningProps={this.state.callerProps}
        />
      );
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form className={classes.container} autoComplete="off">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={12}>
                      <h4>{getString().payment_method}</h4>

                      <RadioGroup
                        style={{
                          width: "auto",
                          height: "auto",
                          display: "flex",
                          flexWrap: "nowrap",
                          flexDirection: "row",
                        }}
                        aria-label="Enable Notification"
                        name="payment_options"
                        className={classes.group}
                        value={this.state.payment_option}
                        onChange={(e) => this.handlePaymentOptions(e)}
                        row={true}
                      >
                        <FormControlLabel
                          value="cash"
                          control={<Radio />}
                          label={getString().cash_payment}
                        />
                        <FormControlLabel
                          value="cheque"
                          control={<Radio />}
                          label={getString().cheque_payment}
                        />
                        <FormControlLabel
                          value="bank"
                          control={<Radio />}
                          label={getString().bank_payment}
                        />
                      </RadioGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        {getString().amt_to_pay +
                          " : " +
                          currencyFormat(this.getTotal())}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="amount"
                        name="amount"
                        label={getString().amount}
                        className={classes.textField}
                        value={this.state.amount}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </GridItem>
                    {this.state.payment_option === "cash" &&
                    this.state.caller === "sell" ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          id="change"
                          name="change"
                          className={classes.textField}
                          value={this.state.change}
                          label={getString().change}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </GridItem>
                    ) : null}

                    {this.state.payment_option === "cheque" ||
                    this.state.payment_option === "bank" ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          id="bank"
                          name="bank"
                          className={classes.textField}
                          label={getString().bank_name}
                          value={this.state.bank}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </GridItem>
                    ) : null}
                    {this.state.payment_option === "cheque" ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          id="cheque_no"
                          name="cheque_no"
                          className={classes.textField}
                          label={getString().cheque_no}
                          value={this.state.cheque_no}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </GridItem>
                    ) : null}
                    {this.state.payment_option === "bank" ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          id="bank_no"
                          name="bank_no"
                          className={classes.textField}
                          label={getString().transaction_number}
                          value={this.state.bank_no}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="made_by"
                    name="made_by"
                    label={getString().made_by}
                    className={classes.textField}
                    value={this.state.made_by}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required
                    id="date"
                    name="date"
                    className={classes.textField}
                    value={this.state.date}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    type="date"
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <SaveButton
                    method={this.sendPayment}
                    title={getString().pay}
                  />
                  <Button color="danger" onClick={this.state.onClose}>
                    {getString().cancel}
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

GenericPayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenericPayment);
