import MainLayout from "layouts/afrijula/MainLayout.jsx";
import LandingPage from "components/afrijula/LandingPage/LandingPage.jsx";
import Login from "components/afrijula/Login/LogIn.jsx";
import Register from "components/afrijula/Register/Register.jsx";
import Activate from "components/afrijula/Register/Activate.jsx"
import Reset from "components/afrijula/Register/Reset.jsx"
import Privacy_Policy from "components/afrijula/Privacy_Policy_&_Terms/Privacy_Policy.jsx"
import Terms_Condition from "components/afrijula/Privacy_Policy_&_Terms/Terms_Condition.jsx"

const indexRoutes = [
    { path: "/register", component: Register},
    { path: "/activate", component: Activate},
    { path: "/reset", component: Reset},
    { path: "/login", component: Login},
    { path: "/terms_conditions", component: Terms_Condition},
    { path: "/privacy_policy", component: Privacy_Policy},
    { path: "/landing", component: LandingPage},
    { path: "/",component: MainLayout}

    ];

export default indexRoutes;
