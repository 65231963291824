import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {validateEmail,getToday,getString,phonenumber} from "assets/js/utils/utils.js";

class NewCustomerProductDetails extends Component {
    constructor(props) {
        super(props);
};

   componentDidMount() {
    this.setState({
        date: getToday()
    });
   }
    back = () => {
        this.props.handleBack('backToContractsUI');
    };

    selectProducts = () =>{
        let response = this.validate();
        if(response === true) this.props.handleNext('selectProductUI');
        else if(response === 'email') alert(`${getString().alert_cust_email}`);
        else if(response === 'name') alert(`${getString().alert_cust_name}`);
        else if(response === 'phone') alert(`${getString().alert_cust_phone}`);
        else if(response === 'address') alert(`${getString().alert_cust_addr}`);
    };

    validate = () => {
        const { customerName, customerEmail, customerPhone, customerAddress} = this.props.callerState;
        if (customerName === '') return 'name';
        else if(customerEmail === null || (customerEmail.trim() !== '' && !validateEmail(customerEmail))) return 'email';
        else if(customerPhone === '' || !phonenumber(customerPhone)) return 'phone';
        else if(customerAddress === '') return 'address';
        else return true;
    };

    handleCustomer = (opt) =>{
          this.props.handleCustomer(opt);
    };

    customerOptions = (inputValue,callback) => {
        const url = baseUrl + '/afrijula/sales/customers?attrs=["name","id","address","phone_number","email"]&model=Afrijula::Sales::Customer&to=name&match='+inputValue;
        return fetch(url,{dataType:"json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for(; i< json.length;i++){
                    opts[i] = {label:json[i].name, value:json[i]._id.$oid,address:json[i].address,email:json[i].email,phone_number:json[i].phone_number};
                }
                return opts;
            })
            .catch(err => {

            });
    };

    handleChange =(e) =>{
        this.props.handleChange(e);
    };

    render() {
         const {customerId,customerName,customerAddress,customerPhone,customerEmail,taxPercentage,date} = this.props.callerState;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                               <h4 style={{'textAlign':'center'}} > {getString().details}</h4>
                            </CardHeader>

                            <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <span><b>{getString().select_customer}</b></span>
                                            <Card style={{zIndex:10, marginTop:2}}>
                                                <AsyncCreatableSelect
                                                    defaultOptions
                                                    loadOptions={this.customerOptions}
                                                    placeholder={getString().select_customer}
                                                    name="customer"
                                                    id="customer"
                                                    isClearable
                                                    defaultValue={{
                                                        value: customerId,
                                                        label: customerName
                                                    }}
                                                    onChange={opt => {
                                                        this.handleCustomer(opt);
                                                    }}
                                                />
                                            </Card>
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="customerAddress"
                                                label= {getString().address}
                                                name="customerAddress"
                                                value= {customerAddress}
                                                onChange={(e) => {this.handleChange(e)}}
                                                />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="customerPhone"
                                                label={getString().phone}
                                                name="customerPhone"
                                                value= {customerPhone}
                                                onChange={(e) => {this.handleChange(e)}}
                                                />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="customerEmail"
                                                name="customerEmail"
                                                label= {getString().email}
                                                value= {customerEmail}
                                                onChange={(e) => {this.handleChange(e)}}
                                                type="email"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} style={{marginTop: "15px"}}>
                                                    <TextField
                                                    id="tax-number"
                                                    label= {getString().tax_percentage}
                                                    type="number"
                                                    name="taxPercentage"
                                                    inputProps={{ min: "0"}}
                                                    value={taxPercentage}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => {this.handleChange(e)}}
                                                    margin="normal"
                                                    variant="outlined"
                                                    />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} style={{marginTop: "15px"}}>
                                            <TextField
                                                id="date"
                                                name="date"
                                                type="date"
                                                value={date}
                                                onChange={(e) => {this.handleChange(e)}}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </GridItem>
                                    </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12}>
                                    <Button xs={12} sm={6} md={2} color="danger"  onClick={this.back.bind(this)}
                                            style={{backgroundColor: "#f44336", margin: ".3125rem 1px",float: 'left', minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    >{getString().back}</Button>
                                    <Button xs={12} sm={6} md={2} color="success" onClick={this.selectProducts}
                                            style={{backgroundColor: "#4caf50", right: '0px', minWidth: "auto", verticalAlign:'center',float: 'right' ,
                                                minHeight:"auto",fontSize: "12px",borderRadius: "3px"}}
                                    >{getString().next}</Button>
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

}


export default NewCustomerProductDetails
