import React from "react";

import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Person from "@material-ui/icons/Person";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import MaskedInput from "react-text-mask";
import {
  displayError,
  displaySuccess,
  getString,
  phonenumber,
} from "assets/js/utils/utils.js";
import Link from "@material-ui/core/Link";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});
const recaptchaRef = React.createRef();

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "+",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Registration extends React.Component {
  onChange = (value) => {
    this.setState({ recapterValue: value });
  };

  state = {
    business_name: null,
    email: null,
    password: null,
    verify_password: null,
    name: null,
    recapterValue: "",
    telephone: "+(   )   -       ",
    howHear: "Social",
  };

  cancel = () => {
    this.props.history.push({
      pathname: "/landing",
      state: { locale: this.state.locale },
    });
  };

  validateEmail = (email) => {
    email = email.trim();
    if (!email || email.length == 0) return false;
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  getNames = () => {
    let names = this.state.name.trim().split(" ");
    return names;
  };

  validation = () => {
    let _this = this;
    let business_name = this.state.business_name;
    let email = this.state.email;
    let password = this.state.password;
    let verify_password = this.state.verify_password;
    let phone = this.state.telephone;

    if (!this.state && this.state.name.split(" ").length < 2) {
      displayError(getString().fname_lname_err);
      document.getElementById("name").focus();
      return;
    }

    if (!this.state.recapterValue) {
      displayError(getString().recapcha_err);
      return;
    }

    if ((!phone || phone == "") && (!email || email == "")) {
      displayError("You must specify a phone number or email to register");
      document.getElementById("email").focus();
      return;
    }

    if (phone && phone.length > 0 && !phonenumber(phone.trim())) {
      displayError(getString().phone_num_err);
      document.getElementById("telephone").focus();
      return;
    } else {
      phone = phone.trim();
    }

    if (email && email.length > 0 && !this.validateEmail(email)) {
      displayError(getString().email_err);
      document.getElementById("email").focus();
      return;
    }

    if (!password || password.length < 5) {
      displayError(getString().pwd_length_err);
      document.getElementById("password").focus();
      return;
    }

    if (!verify_password || verify_password !== password) {
      displayError(getString().verify_pwd_err);
      document.getElementById("verify_password").focus();
      return;
    }

    let names = this.getNames();
    let first_name = names[0];
    let middle_names = [];
    let last_name = names[names.length - 1];
    if (names.length > 2) {
      middle_names = names.splice(1, names.length - 2);
    }

    $.ajax({
      method: "POST",
      url: baseUrl + "/account/authenticate_v2/register",
      dataType: "json",

      data: {
        email: email,
        password: password,
        verify_password: verify_password,
        user_type: "admin",
        phone: phone,
        first_name: first_name,
        middle_name: middle_names.join(" "),
        last_name: last_name,
        influence: "afrijula",
        locale: this.state.locale,
        // passing in the how hear key:value here
        howHear: this.state.howHear,
      },

      success: function(data) {
        if (data.activate == true) {
          displaySuccess("Your account has been created. Lets activate!");
          setTimeout(function() {
            _this.props.history.push(
              "/activate?token=" +
                data.token +
                "&influence=afrijula&phone=" +
                data.phone_number
            );
          }, 1000);
        } else {
          displaySuccess(
            `Hi ${first_name} thank you for registering on afrijula to complete your registration, please check your email in order to verify your email address.`
          );
          setTimeout(function() {
            _this.props.history.push("/login");
          }, 5000);
        }
      },
      error: function(error) {
        displayError(error.responseText);
        _this.setState({ saveButton: true });
      },
    });
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Person />
          </Avatar>
          <Typography component="h1" variant="h5">
            {getString().register}
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">{getString().email}</InputLabel>
              <Input
                name="email"
                id="email"
                autoComplete="email"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="telephone">
                {getString().telephone}
              </InputLabel>
              <Input
                name="telephone"
                id="telephone"
                autoComplete="telephone"
                onChange={(e) => {
                  this.setState({ telephone: e.target.value });
                }}
                inputComponent={TextMaskCustom}
                value={this.state.telephone}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">{getString()._name}</InputLabel>
              <Input
                name="name"
                id="name"
                autoComplete="name"
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">{getString().password}</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                }}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="verify_password">
                {getString().verify_pwd}
              </InputLabel>
              <Input
                name="verify_password"
                type="password"
                id="verify_password"
                onChange={(e) => {
                  this.setState({ verify_password: e.target.value });
                }}
              />
            </FormControl>

            {/* Adding How did you hear field */}
            <FormControl margin="normal" required fullWidth>
              <InputLabel id="howHear">How did you hear about us?</InputLabel>
              <Select
                id="howHear"
                name="howHear"
                onChange={(e) => {
                  this.setState({ howHear: e.target.value });
                }}
                defaultValue={this.state.howHear}
              >
                <MenuItem value={"Social"}>Social Media</MenuItem>
                <MenuItem value={"TV"}>Television</MenuItem>
                <MenuItem value={"Radio"}>Radio</MenuItem>
                <MenuItem value={"Billboard"}>Billboard</MenuItem>
                <MenuItem value={"Referral"}>Referral</MenuItem>
                <MenuItem value={"Insist-staff"}>InSIST Staff</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>

            {/* Adding How did you hear field */}

            {/* <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Ld4gKQUAAAAAMq7CZ7cl6n7ElPxwDkxw7pMP9j3"
              // sitekey="6LfihEwbAAAAADiZ7fG71iN8lve0FtB7b6sJ_ugD"
              onChange={this.onChange}
            /> */}

            <GoogleReCaptchaProvider reCaptchaKey="6LfgkUwbAAAAAPuRhn1Jpy5ARdilCPrthGFhs7Mi">
              <GoogleReCaptcha
                onVerify={(token) => {
                  this.setState({ recapterValue: token });
                }}
              />
            </GoogleReCaptchaProvider>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.validation}
            >
              {getString().reg}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="danger"
              className={classes.submit}
              onClick={this.cancel}
            >
              {getString().cancel}
            </Button>
            <Link
              href={baseUrl + "/privacy_policy_afrijula.html"}
              target="_blank"
            >
              Privacy Policy
            </Link>
          </form>
        </Paper>
      </div>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Registration);
