
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, { Component } from "react";
import Button from "components/common/CustomButtons/Button.jsx";
import {getString} from "../../../assets/js/utils/utils";

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        const lat = props.lat;
        const lng = props.long;
        this.state = {
            showingInfoWindow: false,
            activeMarker: {title: 'Your store', name: 'You store', position: {lat, lng}},
            selectedPlace: {}
        };
        this.onClick = this.onClick.bind(this);
    }

    onMarkerClick = (x,y) => {
        console.log(x)
        console.log(y)
    };

    onClick = (t, map,cord) => {
        const {latLng} = cord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        this.setState({
            activeMarker:
                {
                    title: "Your new location",
                    name: "New Location",
                    position: {lat, lng}
                }
        });
    };

    render() {
        return (
            <div style={{
                position: "relative",
                height: "calc(100vh - 20px)"
            }}>
                {(this.props.choose) ? <Button color="info" link={true}onClick={()=> {this.props.updateCallback(this.state.activeMarker.position.lat, this.state.activeMarker.position.lng);}} justIcon={true}
                                               style={{backgroundColor: "#00acc1", margin: ".3125rem 1px", minWidth: "auto",
                                                   minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                >{getString().update}</Button> : null}
                <Map
                google={this.props.google}
                zoom={14}
                zoomControl={true}
                style={{
                    width: '100%',
                    height: '100%'
                }}
                onClick={this.onClick}
                initialCenter={{
                    lat: this.props.lat,
                    lng: this.props.long
                }}
            >
                    <Marker
                        title={this.state.activeMarker.title}
                        name={this.state.activeMarker.name}
                        position={this.state.activeMarker.position}
                    />

            </Map></div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyBJUJxZEjLREPQrl-5pFf17lvNIflI7LWY')
})(MapContainer)