import React from "react";
import PropTypes from "prop-types";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import {
  compileQuery,
  showReportUtility,
  currencyFormat,
  getToday,
  dateFormat,
  getString,
} from "assets/js/utils/utils.js";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import $ from "jquery";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/common/CustomButtons/Button.jsx";
import AddAsset from "components/afrijula/AddForm/AddAsset.jsx";
import AssetItemDetails from "components/afrijula/AllDetails/AssetItemDetails.jsx";
import Icon from "@material-ui/core/Icon";
import CardFooter from "components/common/Card/CardFooter.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import {
  applyPackaging,
  displayError,
  displaySuccess,
} from "../../../assets/js/utils/utils";
import HelpUtil from "../Utils/HelpUtil";
import DisabledUI from "../../common/Templates/DisabledUI/DisabledUI";

class AssetSummary extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = this.props.state || {
      assetData: null,
      newAsset: false,
      newCategory: false,
      editCategory: false,
      editIndex: null,
      assetItemData: null,
      assetDataUnit: null,
      assetSummaryData: [],
      assets: [],
      total_current_value: null,
      total_assets: null,
      globalFilter: "active",
      recalledPageSize: "",
      lastSize: null,
      loading: false,
      package: null,
    };
  }

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    this.loadAsset(id);
  };

  loadAsset = (id) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/asset_register/assets/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({
          assetData: result,
        });
      },
    });
  };

  getCurrentValue = (depreciation_values) => {
    let sd = getToday();
    if (depreciation_values) {
      let i = depreciation_values.findIndex((dv) => {
        return (
          sd >= dv.start_date.substring(0, 10) &&
          sd <= dv.end_date.substring(0, 10)
        );
      });
      return i === -1
        ? 0.0
        : currencyFormat(depreciation_values[i].current_value);
    } else return 0.0;
  };

  loadData = (query, resolve, reject) => {
    let filter = null;

    if (this.state.globalFilter === "active") {
      filter = "disposed: {'$ne':true}";
    } else if (this.state.globalFilter === "disposed") {
      filter = "disposed: {'$ne':false}";
    }

    let url = compileQuery(
      query,
      baseUrl + "/afrijula/asset_register/assets",
      [
        "_id",
        "description",
        "category",
        "model_brand",
        "location",
        "designated_to",
        "tag",
        "life_span",
        "asset_items",
        "unit_cost",
        "disposed",
        "disposed_date",
        "serial_number",
        "purchase_date",
        "depreciation_values",
        "batch_number",
        "depreciation",
        "department",
      ],
      [{ by: "asc", attr: "purchase_date" }],
      "Afrijula::AssetRegister::Asset",
      null,
      filter,
      null
    );

    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;
    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      })
      .catch((e) => {});
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  addAsset = () => {
    this.setState({
      newAsset: true,
    });
  };

  updateAssetTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  loadTilesData = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      dataType: "json",
      data: { count: ["total_assets"] },
      success: function(result) {
        let _data = result.counts.total_assets;
        _this.setState({
          total_assets: _data[0].total,
          total_current_value: _data[1].total_asset_value,
        });
      }.bind(this),
    });
  };

  setGlobalFilter = (e) => {
    this.setState({ globalFilter: e.target.value });
    this.updateAssetTable();
  };

  renderCurrentVal = (rowData) => {
    return rowData.disposed === true
      ? currencyFormat(0)
      : this.getCurrentValue(rowData.depreciation_values);
  };

  loadOptions = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/packages/",
      data: { localize: true },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ package: result.assets });
      },
    }).catch((e) => {
      displayError("Assets data could not be loaded. Try again!");
    });
  };

  componentDidMount() {
    let _globalFilter = this.state.globalFilter;
    if (_globalFilter !== "disposed" || _globalFilter !== "none") {
      this.loadTilesData();
    }

    this.loadOptions();
  }

  enableAssetsRegistry = () => {
    this.setState({ loading: !this.state.loading });
    if (this.state.package) {
      let _this = this;
      $.ajax({
        method: "POST",
        url: baseUrl + "/afrijula/afrijula/update_package/",
        data: {
          name: "assets",
          status: this.state.package.status === false,
          exception: true,
        },
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(result) {
          displaySuccess("Options updated");
          _this.setState({ loading: !_this.state.loading });
        },
        error: function(error) {
          displayError(error.responseText);
          _this.setState({ loading: !_this.state.loading });
        },
      });
    } else {
      displayError("Assets data could not be loaded. Try again!");
      this.setState({ loading: !this.state.loading });
    }
  };

  render() {
    const { classes } = this.getProps();
    if (applyPackaging("assets")) {
      return (
        <DisabledUI
          feature="Assets Register"
          method={this.enableAssetsRegistry}
          loading={this.state.loading}
          about={
            "Assets registration helps you manage your business assets. Assets depreciations are also tracked and managed properly."
          }
        />
      );
    }
    if (this.state.assetData) {
      let data = this.state.assetData;
      this.state.assetData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <AssetItemDetails
          callerProps={this.getProps()}
          callerState={this.state}
          assetData={data}
          callerName={AssetSummary}
        />
      );
    }
    if (this.state.newAsset) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <AddAsset
          callerProps={this.getProps()}
          callerState={this.state}
          updateAssetTable={this.updateAssetTable.bind(this)}
          callerName={AssetSummary}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>store</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>
                    {getString().total_assets}
                  </p>
                  <h4 className={classes.cardTitle}>
                    {this.state.total_assets}
                  </h4>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <span
                      onClick={() => {
                        let end_date = new Date();
                        let start_date = new Date(
                          end_date.getFullYear(),
                          end_date.getMonth() - 1,
                          end_date.getDate()
                        );
                        let params = {
                          organization_id: cookies.load("orgId"),
                          org_name: cookies.load("orgName"),
                          start_date: start_date,
                          end_date: end_date,
                        };
                        let inject = {
                          images: [
                            {
                              name: "image",
                              attr: "image",
                              id: cookies.load("orgId"),
                              model: "Organization",
                            },
                          ],
                        };
                        showReportUtility(
                          "Assets  Report",
                          baseUrl +
                            "/reports/1?url=/afrijula/*&profile=" +
                            cookies.load("profileId"),
                          {
                            params: params,
                            sub: "&report=assets_report&dynamic=true",
                            inject: inject,
                          },
                          cookies,
                          null
                        );
                      }}
                      style={{ cursor: "pointer", color: "#800080" }}
                    >
                      {getString().details}
                    </span>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>money</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>
                    {getString().total_current_val}
                  </p>
                  <h4 className={classes.cardTitle}>
                    {currencyFormat(this.state.total_current_value)}
                  </h4>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <span
                      onClick={() => {
                        let start_date = new Date();
                        let end_date = new Date(
                          start_date.getFullYear() + 1,
                          start_date.getMonth(),
                          start_date.getDate()
                        );
                        let params = {
                          organization_id: cookies.load("orgId"),
                          org_name: cookies.load("orgName"),
                          start_date: start_date,
                          end_date: end_date,
                        };
                        let inject = {
                          images: [
                            {
                              name: "image",
                              attr: "image",
                              id: cookies.load("orgId"),
                              model: "Organization",
                            },
                          ],
                        };
                        showReportUtility(
                          "Assets Current Value Report",
                          baseUrl +
                            "/reports/1?url=/afrijula/*&profile=" +
                            cookies.load("profileId"),
                          {
                            params: params,
                            sub:
                              "&report=assets_current_value_report&dynamic=true",
                            inject: inject,
                          },
                          cookies,
                          null
                        );
                      }}
                      style={{ cursor: "pointer", color: "#800080" }}
                    >
                      {getString().details}
                    </span>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer />
          <div style={{ paddingBottom: "5px" }}>
            <Button
              xs={12}
              sm={12}
              md={2}
              color="info"
              onClick={this.addAsset}
              style={{
                backgroundColor: "#00acc1",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "10px 30px",
                borderRadius: "3px",
                verticalAlign: "middle",
                float: "right",
              }}
            >
              <i className="material-icons">add</i>
              {getString().add_asset}
            </Button>
            <FormControl>
              <FormLabel>{getString().filter}</FormLabel>
              <RadioGroup
                row
                value={this.state.globalFilter}
                name="globalFilter"
                onChange={(e) => this.setGlobalFilter(e)}
              >
                <FormControlLabel
                  label={getString().active_assets}
                  value="active"
                  control={<Radio />}
                />
                <HelpUtil reference={"asset_register_active_assets"} />
                <FormControlLabel
                  label={getString().disposed_assets}
                  value="disposed"
                  control={<Radio />}
                />
                <HelpUtil reference={"asset_register_disposed_assets"} />
                <FormControlLabel
                  label={getString().all}
                  value="none"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={this.state.summLen}>
              <Card>
                <Table
                  tableRef={this.tableRef}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      this.loadData(query, resolve, reject);
                    })
                  }
                  columns={[
                    {
                      title: getString().description,
                      field: "description",
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                    },
                    {
                      title: getString().batch_number,
                      field: "batch_number",
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                    },
                    {
                      title: getString().category,
                      field: "category",
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                    },
                    {
                      title: getString().serial_number,
                      field: "serial_number",
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                    },
                    {
                      title: getString().tag,
                      field: "tag",
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                    },
                    {
                      title: getString().purchase_date,
                      field: "purchase_date",
                      render: (rowData) => dateFormat(rowData.purchase_date),
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                      filtering: false,
                    },
                    {
                      title: getString().unit_cost,
                      field: "unit_cost",
                      render: (rowData) => currencyFormat(rowData.unit_cost),
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                      filtering: false,
                    },
                    {
                      title: getString().tot_curr_val,
                      field: "unit_cost",
                      render: this.renderCurrentVal,
                      cellStyle: { padding: "4px" },
                      headerStyle: { padding: "4px" },
                      filtering: false,
                    },
                  ]}
                  onRowClick={this.rowSelect}
                  options={{
                    exportButton: true,
                    filtering: true,
                    grouping: true,
                    sorting: true,
                    debounceInterval: 1000,
                    selection: false,
                    showTitle: false,
                    pageSize: this.state.recalledPageSize || 20,
                    rowStyle: (rowData) => ({
                      textDecoration:
                        rowData.disposed === true &&
                        this.state.globalFilter === "none"
                          ? "line-through"
                          : "none",
                      color:
                        rowData.disposed === true &&
                        this.state.globalFilter === "none"
                          ? "#999"
                          : "#000",
                    }),
                  }}
                />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

AssetSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(AssetSummary);
