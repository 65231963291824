import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {getString, displayError, displaySuccess} from "assets/js/utils/utils.js";
import MaterialTable from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import Select from "react-select";
import TextField from '@material-ui/core/TextField';
import AssetRegister from "components/afrijula/Asset_Register/AssetSummary.jsx";
import SaveButton from "../CustomComponents/SaveButton";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class AssetItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            back: false,

            callerName: this.props.callerName,
            callerProps: this.props.callerProps,
            callerState: this.props.callerState,
            globalAssetData: this.props.globalAssetData,
            quantity: '',
            tempQty: 0,
            itemsHeader: [
                {
                    title: getString().model_brand,
                    field: 'model_brand',
                    cellStyle: {padding: '1px', width: '160px'},
                    headerStyle: {padding: '1px', width: '160px'},
                },
                {
                    title: getString().serial_number,
                    field: 'serial_number',
                    cellStyle: {padding: '1px', width: '160px'},
                    headerStyle: {padding: '1px', width: '160px'},
                },
                {
                    title: getString().tag,
                    field: 'tag',
                    cellStyle: {padding: '1px', width: '160px'},
                    headerStyle: {padding: '1px', width: '160px'},
                },
                {
                    title: getString().location,
                    field: 'location',
                    cellStyle: {padding: '1px', width: '160px'},
                    headerStyle: {padding: '1px', width: '160px'},
                },
                {
                    title: getString().designated,
                    field: 'designated_to',
                    cellStyle: {padding: '10px', width: '100px'},
                    headerStyle: {padding: '10px', width: '100px'}
                }
            ],
            showAssetsForm: false,
            locations: [],
            location:{},
            itemsData: [],
            tag: '',
            designated_to: '',
            serial_number: '',
            rowData: null,
            tableWidth: 12,
            formWidth: 5,
            assetData: null,
            closeAdding: false,
        };
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    clearFields = () =>{
        this.setState({
            tag: '',
            designated_to: '',
            serial_number: ''
        })
    };

    addAssetItems = () => {
        let _assetData = this.props.callerState.assetData;

        if (_assetData.serial_number === "") {
            displayError(getString().err_serialNumber);
            document.getElementById("serial_number").focus();
            return;
        }
        let leng = this.props.callerState.asset_items.length;
        let qty = parseInt(this.props.callerState.assetData.quantity);
        if(leng === qty){
            displayError(getString().exact_qty_err);
            return;
        }else{
            this.props.addAssetItems();
        }
    };

    goBack = () =>{
        this.setState({
            back: true
        });
    };

    handleSelectLocation = (opt) => {
        this.setState({
            location: {value: opt.value, label: opt.label}
        });
    };

    saveAsset = () => {
        let _globalAssetData = this.state.globalAssetData;
        _globalAssetData.category = _globalAssetData.category.label;
        _globalAssetData.asset_items = this.state.itemsData;

        let _this = this;
        return $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/asset_register/assets",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: _globalAssetData,
            success: function (result) {
                _this.setState({
                    closeAdding: true
                });
                displaySuccess(getString().asset_success);
                return result;
            }.bind(this),
            error: function (response) {
                displayError(response.responseText);
            }
        });
    };

    loadLocations = (page) => {
        let the_data = [];
        page |= 1;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"filter": ":location.gte => Date.today","attrs":["name","id"], "page":page, "size":10,
                "order": [{"by":"desc","attr":"name"}],"model":"Afrijula::Locations::Location"},

            success: function(data){
                $.map(data,function (data) {
                    the_data.push({label:data.name, value:data._id.$oid});
                });
                this.setState({
                    locations: the_data,
                    location: data[0]
                })
            }.bind(this)
        });
    };

    cancelUpdateAssetItem = () =>{
        this.setState({
            rowData: null,
            tableWidth: 12
        });
    };

    editFields = (classes) =>{
        return(
            <GridItem xs={12} sm={12} md={this.state.formWidth}>
                <Card>
                    <CardHeader color="warning">
                        <h4>Specify other deatails</h4>
                    </CardHeader>
                    <form className={classes.container} autoComplete="off">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    required
                                    id="serial_number"
                                    name="serial_number"
                                    label={getString().serial_number}
                                    className={classes.textField}
                                    value={this.state.serial_number}
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    id="tag"
                                    name="tag"
                                    label={getString().tag}
                                    className={classes.textField}
                                    value={this.state.tag}
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <span>{getString().location}</span>
                                <Select options={this.state.locations}
                                        defaultValue={this.state.location}
                                        onChange = {(e) => {this.handleSelectLocation(e)}}
                                        style={{width: '10px'}}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    required
                                    id="designated_to"
                                    name="designated_to"
                                    label={getString().designated}
                                    className={classes.textField}
                                    value={this.state.designated_to}
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                    <GridItem xs={12} sm={12} md={6}>
                        <Button xs={12} sm={6} md={2} color="danger" onClick={this.cancelUpdateAssetItem.bind(this)}
                                style={{backgroundColor: "#f44336", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                }}>{getString().cancel}</Button>
                        <Button xs={12} sm={6} md={2} color="success" onClick={this.updateAssetItem.bind(this)}
                                style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto", fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                                }}>{getString().update}</Button>
                    </GridItem>
                </Card>
            </GridItem>
        );
    };

    editDetails = (event, row) =>{
        this.setState({
            rowData: row,
            tableWidth: 7
        });
    };

    updateAssetItem = () =>{
        const data = [...this.state.itemsData];
        let newData = {...this.state.rowData};

        const index = newData.tableData.id;
        newData.location = this.state.location.label;
        newData.serial_number = this.state.serial_number;
        newData.tag = this.state.tag;
        newData.designated_to = this.state.designated_to;

        if(newData.serial_number === ''){
            displayError(getString().err_serialNumber);
            document.getElementById("serial_number");
            return;
        }
        else{
            data[index] = newData;
            this.setState({ itemsData: data, rowData: null, tableWidth: 12});
            this.clearFields();
        }
    };

    getTableData = () => {
        let tableData = [...this.state.itemsData];
        let _data = this.props.categoryData;
        _data.map(asset =>{
            let qty = parseInt(asset.quantity);
            for(let i = 0; i < qty;i++){
                let item = {
                    description: asset.description,
                    model_brand: asset.model_brand,
                    unit_cost: asset.unit_cost,
                    life_span: asset.life_span,
                    depreciation: asset.depreciation,
                    start_month: parseInt(this.state.globalAssetData.purchase_date.split('-')[1]),
                    serial_number: '',
                    tag: '',
                    location: '',
                    designated_to: '',
                };
                tableData.push(item)
            }
        });
        this.setState({
            itemsData: tableData
        });
    };

    componentDidMount() {
        this.getTableData();
        this.loadLocations();
    };

    render() {
        const {classes} = this.props;

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            let caller_state = this.state.callerState;
            caller_state.showAssetsSummary = false;
            return <ChildComponent state={caller_state} returningProps={this.state.callerProps}
                    
                    />
        }
        if(this.state.closeAdding){
            return <AssetRegister/>
        }
        else{
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={this.state.tableWidth}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4>{getString().individual_assets}</h4>
                                </CardHeader>
                                <CardBody>
                                    <p>Please edit each asset to specify individual details for tracking purpose.</p>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <MaterialTable
                                                columns={this.state.itemsHeader}
                                                data={this.state.itemsData}
                                                localization={{header:{
                                                        actions: ""
                                                    }}}
                                                actions={[
                                                    {
                                                        icon: 'edit',
                                                        iconProps: {fontSize: 'small'},
                                                        onClick: (event, row) => {
                                                            this.editDetails(event, row);
                                                        },
                                                    },

                                                ]}
                                                options={{
                                                    search: false,
                                                    paging: false,
                                                    toolbar: false,
                                                    showEmptyDataSourceMessage: false,
                                                    showTitle: false
                                                }}
                                            />

                                        </GridItem>
                                </CardBody>
                                <CardFooter>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button xs={12} sm={6} md={2} color="danger" onClick={this.goBack}
                                                style={{
                                                    backgroundColor: "#f44336",
                                                    margin: ".3125rem 1px",
                                                    minWidth: "auto",
                                                    minHeight: "auto",
                                                    fontSize: "12px",
                                                    color: "#ffffff",
                                                    padding: "12px 30px",
                                                    borderRadius: "3px",
                                                    verticalAlign: "middle"
                                                }}>{getString().back}</Button>
                                        <SaveButton method={this.saveAsset} title={getString().save} />
                                    </GridItem>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        {(this.state.rowData) ? this.editFields(classes) : null}
                    </GridContainer>
                </div>
            );
        }
    }
}

AssetItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetItem);