import React from "react";
import Button from "components/common/CustomButtons/Button.jsx";


class SaveButton extends React.Component{
    state = {
        disable: false
    };

    handleOnClick = async () => {
        this.handleDisable();
        try {
            let res = await this.props.method();
            //we do not care what is returned. Only that we wait for it before we re-enable.
            this.handleDisable();
        }catch (e){
            this.handleDisable();
        }
    };

    handleDisable = () =>{
        let currentState = this.state.disable;
        this.setState({
            disable: !currentState
        });
    };

    render(){
        const _color = this.props.saveProcess ? "info" : "success";
        const bgColor = this.props.saveProcess ? "#00acc1" : "#4caf50";
        return(
            <Button xs={12} sm={6} md={2} color={_color} onClick={this.handleOnClick}
                    disabled={this.state.disable || this.props.isValid}
                    style={{backgroundColor: {bgColor}, margin: ".3125rem 1px", minWidth: "auto", minHeight: "auto",
                        fontSize: "12px", color: "#ffffff", padding: "12px 30px", borderRadius: "3px", verticalAlign: "middle"
                    }}>{this.props.title}
            </Button>
        );
    }
}
export default SaveButton;
