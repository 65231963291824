import LocalizedStrings from 'react-localization';


const strings = new LocalizedStrings({

    fr: {
        //for landing
        our_location:"",
        kairaba:"",
        bijilo:"",
        subjects:"",

        //for dashboard
        customers: "Les clients",
        low_products: "Produits bas",
        daily_sales: "Ventes Quotidiennes",
        daily_invoice: "Factures Quotidiennes",
        daily_revenue: "Revenu Quotidien",
        revenue: "Revenu",
        details: "Détails",
        sale_text:{"cash":"Ventes en espèces",'invoice':"Factures",'quote':'Quotes','contract':'Des Contracts'},
        monthly_purchase: "Dépense Mensuelle", //@anget-> pls translate
        stock: "Stock",
        products: 'Produits',
        due_invoices: "Factures Attendu",
        increase_sales: "augmentation des ventes aujourd'hui.",
        record_monthly_expense: "Enregistrement des dépenses mensuelles",
        best_selling: "Meilleure vente",
        category: "Catégorie",
        sale_type: "Type de vente",
        deposit_amount: "Montant deposé",
        payment_type: "Type de paiment ",
        income_type: "Type de paiment ", //Ala to translate
        initial_deposit: "Dépôt initial",
        deposit: "Dépôt",
        billable: "Facturable",
        labels: "Labels",
        merge: "Merge",
        number_installments: "Nombre de versement",
        installment_period: "Période de versement",
        installments: "Versements",
        start_on: "Démarrer",
        count: "Compter",
        period: "Période",
        add_customer_sale: "Nouvelle vente",
        for:"Pour",
        periodic_payments: 'Calendrier des paiements périodiques',

        //Contracts TODO-> anget
        active_contracts: 'Active Contracts',
        closed_contracts: 'Closed Contracts',
        New: 'NEW',
        next: 'Next',
        Finish: 'Finish',
        alert_sel_one_product: 'You must select at least one product',
        alert_cust_addr: 'Customer address cannot be empty',
        alert_cust_email: 'Please enter a valid email',
        alert_cust_name: 'Customer name cannot be empty',
        alert_cust_phone: 'Please enter a valid phone number',
        alert_ch_start_today: 'Charges will start from today',
        alert_confirm_contract_creation: 'Are you sure you want to create this contract?',
        alert_prorate_day: 'Do you want to prorate this day?',
        alert_prorate_week: 'Do you want to prorate this week?',
        alert_prorate_month: 'Do you want to prorate this month?',
        alert_prorate_quarter: 'Do you want to prorate this quarter?',
        alert_prorate_year: 'Do you want to prorate this year?',
        Update_product: 'Update product',
        ch_products: 'Choose Products',
        Select_a_product: 'Select a product',
        and: 'and',
        contract_agreement: 'Contract agreement between',
        contract_length: 'Contract length',
        Back_to_products: 'Back to products',
        Cancel_contract: 'Cancel Contract',
        Weeks: 'Week(s)',
        Months: 'Month(s)',
        Quarters: 'Quarter(s)',
        Years: 'Year(s)',
        discount_100: 'Discount cannot be 100%',
        alert_provide_charge: 'Please specify the charge cycle',
        alert_provide_bill: 'Please specify the bill period',
        alert_provide_term: 'Please specify the term length',
        alert_provide_zero: 'Please specify the contract length',
        alert_provide_autorenew: 'Please specify if to auto renew or not',

        //TODO agnet translate please
        //products
        add_product: 'Ajouter Produit',
        new_menu: 'Ajouter un menu',
        poor_items: 'Produits lents',
        popular_items: 'Articles populaires',

        //for stock
        low_stock: "Stock Faible",
        //stock: "Stock",
        fast_moving_stock: "Stock Rapide",
        transfer_stock: 'Transferer Stock',
        add_stock: 'Nouveau Stock',
        qty_per_unit: "Quantité Per Unitaire",
        stock_hist: "Historique du Stock",
        transfer_qty: "Entrer quantité à transférer ",
        submit : "Envoyer",
        stock_qty: "Quantité du stock",
        sale_item: "Articles vendus",

        //for stock transfer
        save: "Sauver",
        cancel: "Annuler",
        select_source: "Sélectionner Source",
        select_destination: "Sélectionner Destination",
        source: "Source",
        destination: "Destination",
        received_by: "Reçu by",
        table_name: "Nom du produit",
        table_quantity: "Quantité",
        back: "Retourner",
        received_qty: "Quantité reçue",
        stock_sales: "Ventes de Stock",
        stock_purchase: "Achat de Stock",
        //for add stock
        metric: 'Metric', //@anget-> pls translate
        // save: "Sauver",
        // cancel: "Annuler",
        // back: "Retourner",
        product: "Produit",
        product_number: "Numéro",
        wholesale_mteric: "Métrique de Gros",
        retail_metric: "Métrique de Détail",
        reorder_level: "Niveau de Restauration",
        sellable_units: "Unités Vendables",
        sellable: "Vendable",
        unit_price: "Prix Unitaire",
        opening_balance: "Solde d'ouverture",
        select_category: "Choisir une catégorie",
        select_location: "Choisir une location",
        // add_stock: "Ajouter Stock",

        //for customer
        total_customer: "Clients Total",
        // due_invoices: "Due Invoice",
        list_customers: 'Liste de Clients',
        aging_debtors: "Débiteur âgé",
        choose_category: "Choisir la catégorie",

        //for Customer Details
        profile: "Profil",
        name: "Nom complet",
        address: "Adresse",
        phone: "Téléphone",
        phone_number: "Numéro de Téléphone",
        email: "Email",
        contact_person: 'Contact',
        shipping_address:'Shipping Address',
        billing_address: 'Billing Address',
        balance: "Solde",
        credit_balance: 'Credit Balance',
        currency: 'Devise',
        account_summary: "Relevé de Compte",
        yearly_turnover: "Chiffre d'affaires Annuel",
        last_date: "Date de la dernière transaction",
        purchase_history: "Historique d'achat",
        add_customer: "Nouveau Client",

        //for supplier
        payables: "Payables",
        //aging_debtors: "Aging Debtor",
        list_suppliers: "Liste de Fournisseurs",
        billing_addr: "Adresse de facturation",
        shipping_addr: "Adresse de livraison",


        //for  spplier details
        date: "Date",
        sub_total: "Sous-Total",
        total_amount: "Montant Total",
        total: "Total",
        add_supplier: "Nouveau Fournisseur",
        pay_supplier: "Payer Fournisseur",
        supplier_account: "Compte Fournisseur",
        supply_history: "Historique de provision",
        tax_amount: "Montant de la taxe",
        item: "Produit",
        items: "Produits",
        amount: "Montant",
        due: "Facture Due",
        due_for_period: "Période de facturation",
        contract_change: "Le contrat a été modifié avec succès",
        paid: "Payé",
        add_supp_invoice: "Ajouter facture du fournisseur",
        add_supplierInv:"Facture du fournisseur",
        bank_credit_card: "Bank / Credit Card",
        mobile_money: "Mobile Money",
        kodo_wallet: "Kodo Wallet",
        card_number: "Card Number",
        card_code: 'CCV Code',
        card_expiry: 'Card Expiry Date',
        holder_name: 'Card Holder Name',
        street_address: "Street Address",
        last_updated: 'Last updated',
        last_sent: 'Last sent',
        city_address: "City",
        country_address: 'Country',
        sale_success: "Ventes enregistrées avec succès",
        //FOR INVOICE
        monthly_invoice: "Facture Mensuelle",
        customer: "Client",
        dailyInvoiceName: "Factures Quotidiennes",
        invoice_info:"Additional Invoice Info",

        //for invoice details
        bill_to: "Facturer",
        date_issued: "Date d'émission",
        due_date: "Date d'échéance",
        invoice_no: "N° de facture",
        customer_id: "N° de client",
        description: "Description",
        unit_cost: "Coût unitaire",
        quantity: "Qté",
        pricing: "Tarification",
        // total: "Total",
        payment_history: "Historique de paiement",
        payment_plan: "Plan de paiement",
        invoice: "Facture",
        contract: "Contrat",
        to_print: "Imprimer",
        close: "Fermer",
        to_pdf: "Télécharger",
        to_edit: "Editer",
        to_email: "Envoyer par email",
        setLocale: "S'il vous plaît sélectionnez votre langue",

        //for SideBar (routes)
        dashboard: "Tableau de bord",
        sell: "Vendre",
        cash_sales: "Ventes en espèces",
        invoices: "Factures",
        contracts: "Les contrats",
        //stock: "Stock",
        purchases: "Purchases", //@anget->pls translate
        income: "Revenu",
        reports: "Rapports",
        loans:"Prêts",
        user_profile: "Profil de l'utilisateur",
        settings: "Paramètres",
        suppliers: "Fournisseurs",

        available_packages: "Forfaits disponibles",


        //for cash sales
        type: "Type",
        weekly_sales: "Ventes hebdomadaires",
        monthly_sales: "Ventes Mensuelles",
        discount: "Remise",
        sales: "Ventes",
        record_finances: "",
        sell_online: "SELL ONLINE",
        apply_for_loans:"APPLY FOR LOANS",
        //for sell component
        discount_type: 'Type de remise',
        retail: "Détail",
        wholesale: "Gros",
        percentage: "Pourcentage",
        fixed: "Fixé",
        select_item: "Selectionner Produit",
        select_customer: "Selectionner Client",
        total_tax: "Taxe total",
        cash: "Espèces",
        quotes: "Quotes",
        quote:"Quote",
        cheque: "Chéquier",
        bank: "Banque",
        last_payment: "Last Payment",

        //for Landing page
        login: "Connecter",
        powerful: "Puissante",
        user_friendly: "Conviviale",
        african: "Africaine",
        key_features: "Principales caractéristiques",

        //settings page
        //settings: "Réglages",
        company_name: "Nom de l'Entreprise",
        telephone: "Téléphone",
        // address: "Adresse",
        location: "Location",
        // email: "Email",
        edit_settings: "Modifier",
        save_settings: "Sauver",
        add_user: "Nouveau Utilisateur",
        add_location: "Nouveau Location",
        gen_info:"Information",
        billing_info: "Facturation",
        user_acc: "Compte Utilisateurs",
        current_version: "Version Actuelle",
        upgrade: "Améliorer",
        upgrade_version: "Améliorer la version",
        pay_bill: "Payer facture",
        account_billing:"Compte & Facturation",
        select_version: "Selectionner la version",
        version: "Version",
        pay: "Payer",
        delete_requests: "Payment Delete Requests",
        manager: "L'administrateur",
        insert_logo: "Inserer logo",
        first_name: "Prénom",
        last_name: "Nom",
        user_name: "Nom d'utilisateur",
        user_type: "Type d'utilisateur",
        password: "Mot de passe",
        download: "Windows POS",
        verify_pwd: "Vérifier mot de passe",
        logout: "",
        change: "Change",
        changes: "Changes",
        payment: "Paiement",
        reversed: "Reversed",
        activate: "Activer",
        deactivate: "Deactiver",
        delete: "Supprimer",

        register: "S'inscrire",
        reg: "s'inscrire",
        complete: "Complete",
        business_name: "Nom de l'entreprise",

        //for reports component
        dailysales_report: "Rapport de vente quotidien",
        expense_eport: "Rapport de dépenses",
        stock_report: "Rapport de stock",
        monthlysales_report: "Rapport de vente mensuel",

        //Quotation
        price: "Prix",
        item_code: "Code du produit",
        code: "Code",
        default: "Défaut",


        locale: "Locale",
        package: "Paquet",
        term: "Terme",
        term_charge: "Coût du terme",
        max_users: "Max utilisateurs",
        default_currency: "Devise par défaut",
        country: "Pays",
        yes: "Oui",
        no: "Non",
        default_chooser:"Location par défaut",
        id: "Id",
        org_info: "Détails d'organisation",
        number:"Numéro",
        quantities: "Quantités",
        tax: "Impôt",
        value: "Valeur",
        supplier_invoice: "Facture du fournisseur",
        inv_number: "No facture",
        sale_date: "Date de vente",
        delivery_date: "date de livraison",
        other_tax: "Autre tax",
        vat: "T.V.A",
        payment_term: "Détails de paiement",
        stock_level: "Niveau de stock",
        enable_notification: "Activer la notification",

        //for purchase_manager @anget-> pls translate. This is now purchase
        bank_name: "Nom de banque",
        cheque_number: "Numéro du chèque",
        purpose: "Objectif",
        expense: "Frais",
        purchase: "Purchase", //@anget-> pls translate
        purchase_table: "Histoire des dépenses", //@anget-> pls translate
        supply: "Fournir",
        asset: "Les biens",
        voucher_number: "Bon",
       // voucher: "Bon",
        summary: "Résumé",
        purchase_manager: "Ajouter dépenses", //@anget-> pls translate
        exchange_rate: "Taux",
        add_stock_inv: "Facture du Stock",
        select_supplier: "Selectionner Fournisseur",
        select_country: "Selectionner pays",
        new_invoice: " Nouvelle Facture",
        paiy_invoices: "Factures Payées",
        estimate: 'Estimation',
        payment_summary: "Payment summary",
        unable_to_complete_reg: "Impossible de terminer l'inscription",
        account_activation:"Activation du compte en cours ...",
        variance: "Variance",
        sold: 'Vendu',
        variable: "Variable",
        high_expenses: "Dépenses élevées",
        profit_loss: "Perte de profit",
        annually: "Annuel",
        daily: "Quotidien",
        weekly: "Hebdomadaire",
        monthly: "Mensuel",
        quarterly: "Trimestriel",
        bill: "Bill",
        charge: "Charge",
        day: "Jour",
        week: "Semaine",
        month: "Mois",
        quarter: 'Trimestrie',
        year: "Année",
        print: "Print",
        transaction_number: 'Transaction number', //@anget-> pls translate

        //Account and Billing
        package_name: "Nom du paquet",
        monthly_price: "Prix mensuel",
        last_paid: "Dernier payé",
        payment_mthd: "Mode de paiement",
        charges: "Des charges",

        //Super Admin
        bills: "Facture non payées",
        users_login: "Identificateur d'Utilisateur",
        accounts: "Comptes",
        requested_by: 'Requested By',
        message: "Message",
        amt_to_pay:"Amount to be Paid",
        users: "Utilisateurs",
        num_users: "Nombre d'utilisateur",
        num_transaction: "Nombre de transaction",
        num_allowed_users: "Utilisateurs permise",
        allowed_transaction: "Transactions permise",
        active_users:"Utilisateurs actifs",
        billing: "Facturation",
        payments: "Payements",
        weekly_account: "Compte hebdomadaire",
        open_logs: "Journaux ouverts",
        sale_stats: "Statistiques de vente",
        organisation: "Organisation",
        ip:"Adresse IP",
        duration: " Durée",
        add_remove: 'Add/Remove',
        logs: "Les journaux",
        users_list: "Liste des utilisateurs",
        usage_params: "Paramètres d'utilisation",
        subject: "Sujet",
        remove: "Remove",
        previous_logs:"Journaux précédents",
        reason: "Reason",
        current_pwd: "Current Password",
        new_pwd: "New Password",
        verify_new_pwd: "Confirm Password",
        discontinue: "Cesser",
        discontinued: "Abandonnée",
        continue: 'Continuez',
        one_time: "Une fois",
        start_date: "Date de début",
        start: "Début",
        extend: "Étendre",
        end_date:"Date de fin",
        subscriptions: "Abonnements",
        new_sub: "Nouvel abonnement",
        select_duration: "Selectionner la durée",
        block_acc: "Bloquer compte",
        bloc_user: "Bloquer utilisateur",
        business_type: "Type d'entreprise",
        reset_password:"Réinitialiser mot de passe",
        block: "Bloquer",
        reset: "Réinitialiser",
        parameters: "paramètres",
        old_password: "Ancien mot de passe",
        new_password: "Nouveau mot de passe",
        confirm_password: "Confirmez le mot de passe",
        active:"Actif",
        general_info: "Générales",
        acc_bill: "Compte et facturation",
        import_export: "Import / Export",
        user_account: "Utilisateurs",
        send_to: "Envoyer à",
        email_report: "Envoyer rapport par email",
        send: "Envoyer",
        repeat:"Répéter",
        every: "Chaque",
        on: "Le",
        after: "Après",
        never: "jamais",
        stop:"Arrêtez",
        schedule: "Programme",
        numTimes: "Après nombre de fois",
        schedule_reports: 'Planifier Les Rapport',
        cash_amount:"Entrez le montant s'il vous plaît",
        receipt: "Reçu",
        printer: "Imprimante",
        thermal_printer:"Imprimante thermique",
        large: "Grande",
        picture:"Photo",
        upload_pic:"Charger une photo",
        logo: "Logo",
        upload_logo:"Télécharger le logo",
        menu: "Menu",
        add_menu:"Ajouter menu",
        ingredients: "Ingrédients",
        low_stock_threshold: "Seuil de stock bas",
        inventoryProducts: "Inventaire et produits",
        inventory: "Inventaire",
        editQty: "modifier la quantité",
        checkBoxStartTerm: "Commence au début du trimestre",
        content: "Contenu",
        to: "À",
        email_receipt: "E-mail reçu",
        add: "Ajouter",
        auto_renew: "Auto Renew",
        add_to: "Ajouter à",

        token: "Jeton",
        tokens: "Jetons",
        last_used_date: "Date de dernière utilisation",
        delete_token: "Supprimer jeton",
        copy_token: "Copier jeton",
        returned_sales: "Ventes retournées",
        returned_sale: "Vente retournée!",
        returned_date: "Date de retour",
        reverse_sale: "Vente inversée",
        quote_to_sale: "Quote to Sale",
        one_time_sale: "Vente ponctuelle",
        one_of_product: "Pour ajouter un inventaire ou un produit, sélectionnez inventaire ou produit. Sinon, un des produits sera ajouté.",
        changePswd:'Changer mot de passe',
        chaangeUserPswd: "Le mot de passe de l'utilisateur sera réinitialisé!",
        stock_removal: "Enlèvement des stocks",
        statement: "déclaration",
        _name: "Nom",
        gender: "Genre",
        owner_gender: "Sexe du propriétaire",
        owner_age: "Âge du propriétaire",
        business_size: "Type d'entreprise",
        region: 'Région',
        retMetricDef:"*Mesure de vente. par exemple, un paquet ou un kilo",
        cash_book: "Livre de caisse",
        acc_name:"Account Name",
        acc_number: "Numéro de compte",
        list_accounts: "Les Comptes",
        add_expense: "Ajouter une dépense",
        account: "Compte",
        particulars: "les détails",
        enable_shopping: 'l\'e-shop',
        disable_shopping: 'l\'e-shop',
        folio: "Folio",
        account_history: "Historique du compte",
        transfer_balance: "Transferer",
        select_bank: "Sélectionnez la banque",
        local_balance: "Balance locale",
        debit_credit_balance: "Solde débiteur / créditeur",
        debit_credit_amount: "Montant débiteur / créditeur",
        payment_to: "Paiement à",
        payment_from: "Paiement de",
        pvoucher: "Bon de paiement",
        receipt_no: "Numéro de reçu",
        transaction: "La transaction",
        add_income: "Ajouter un revenu",
        deposits: "Dépôts",
        add_deposit: "Un dépôt",
        undeposited: "Montant non déposé",
        pending_depo: "Dépôt en attente",
        bill_items: "Articles facturables",
        select_from_acc: "Sélectionnez le compte à transférer",
        select_to_acc: "Select account to transfer to",
        update: "Mise à jour",
        add_bank_acc: "Compte",
        online_shopping: 'Prepare for shop',
        err_acc_name: "Saisi le nom du compte s'il vous plaît",
        err_acc_number:  "Saisi le numéro du compte s'il vous plaît",
        err_bank_name: "Saisi le nom de la banque s'il vous plaît",
        err_deposit_refNo: "Vous devez spécifier un numéro de référence",
        err_deposit_accName: "Vous devez spécifier un compte",
        err_deposit_payments: "Vous devez spécifier un paiement à déposer",
        deposit_success: "Dépôt réussi!",
        err_split_amount: "Le montant saisi ne peut pas être supérieur au montant actuel",
        err_amount: "Le montant saisi est supérieur au solde actuel, entrez un autre montant",
        will_be_sent: " Sera transféré de ",
        _to: " à ",
        filter: "Filtre",
        all: 'Tout',
        outstanding_balance: 'Solde impayé',
        negative_balance: 'Solde négatif',
        trans_success: "Transfert réussi",
        current_bal: "Le solde actuel est ",
        confirm_deactivate_acc: "Voulez-vous vraiment bloquer le ou les comptes sélectionnés",
        confirm_activate_acc: "Voulez-vous vraiment débloquer le ou les comptes sélectionnés",
        delete_acc: "Bloquer le ou les comptes sélectionnés",
        disabled_account: "Vous avez cliqué sur un compte désactivé, activez le compte pour pouvoir voir ses détails.",
        disabled_account_trans: "Le compte que vous avez sélectionné est désactivé, veuillez l'activer pour effectuer un virement",
        same_acc_err: "Vous ne pouvez pas effectuer de virement sur le même compte!",
        select_acc: "Sélectionnez un compte",
        disabled_account_depo: "Le compte que vous avez sélectionné est désactivé, veuillez l'activer pour effectuer un dépôt",
        select_pymnt_type: "Type de paiement",
        no_customer: "Pas de nom de client",
        transfers: "Transferts",
        to_account: "à compte",
        from_account: "du compte",
        depo_details: "Détails du dépôt",
        split: "Divisé",
        reverse_transfer: "Inverser",
        reverse_success: "Transfert annulé avec succès!",
        reversed_err_msg: "Ce transfert a déjà été annulé!",
        phone_num_err: "S'il vous plaît entrer un numéro de téléphone valide",
        recapcha_err: "Veuillez cocher la case reCAPTCHA",
        fname_lname_err: "Veuillez saisir un prénom et un nom",
        email_err: "Email invalide, veuillez entrer une adresse email valide",
        pwd_length_err: "Vous devez entrer un mot de passe avec au moins 6 chiffres",
        ontime: 'À temps',
        overdue: 'En retard',
        verify_pwd_err: "Les mots de passe que vous avez entrés ne correspondent pas",
        register_succ_mess: "Votre inscription est réussie. Veuillez vous connecter à votre e-mail pour activer votre compte.",
        deposit_err: "Vous ne pouvez pas déposer deux fois le même paiement avec le même numéro de référence.",
        undeposited_amount: "Montant non déposé",
        dep_to_acc: "Dépôt sur le compte",
        no_amount_deposit_err: "Il n'y a aucun montant à déposer pour ce paiement. Veuillez sélectionner un autre paiement",
        deposited_amount: "Montant déposé",
        zero_reverse_err: "Votre tentative d'annulation d'un transfert de 0 montant a échoué.",
        session_out: "Votre session a expiré, veuillez vous connecter pour continuer.",
        errInvalid_bankName: "Invalid bank account",
        err_invalid_checkNo: "Invalid check number",
        err_bban: "Invalid bank number",
        add_product_item : "Veuillez ajouter un élément de ce produit",
        changed_prod_status: "état du produit modifié.",
        are_you_sure: "Êtes-vous sûr de vouloir ",
        added_subItem: "Sous-article du produit ajouté.",
        qtygtzero: "Veuillez définir une quantité supérieure à 0",
        select_description: "Veuillez sélectionner une description",
        item_removed: "Élément supprimé",
        invoice_notice_frequency: 'Fréquence des rappels de factures',
        add_composition: "Ajouter la composition du produit",
        remove_composition: "Supprimer la composition sélectionnée",
        refresh: "Rafraîchir",
        composition: "Composition",
        remove_item: "Supprimer l'élément sélectionné",
        customer_added:"Client enregistré avec succès",
        valid_amount: "Veuillez saisir un montant valide, le montant doit être supérieur à zéro",
        shipping_cost: "Frais de port",
        withdraw: "Le Retrait",
        recipient: "Bénéficiaire",
        will_be_withdrawn: " sera retiré de ",
        withdraw_amount: "Faire un retrait",
        select_withd_from_acc: "Sélectionnez le compte à retirer",
        withdraw_success: "Retrait réussi",
        prev_balance: "Solde Ancien",
        prev_local_balance: "Solde Ancien local",
        new_balance: "Nouveau solde",
        new_local_balance: "Nouveau solde local",
        transactions: "Les transactions",
        err_withdraw_amount: "Le montant ne peut pas être supérieur à votre solde actuel",
        withdrawnAmount: "Montant retiré",
        create: "Créer",
        createAccNames: "Veuillez créer des comptes pour les noms de banque suivants",
        automateRecon: "Automatisez le rapprochement des comptes",
        withdraw_method: "Méthode de retrait",
        reconcile_expense: "Rapprocher les dépenses",
        reconcile_income: "Rapprocher les dépenses", //Ala to translate in French
        reconcile_success: "Rapprochement réussi des dépenses",
        payment_deleted: "Le paiement a bien été supprimé",
        unblock_acc: "Débloquer le ou les comptes sélectionnés",
        no_invoice_payment: "Aucun paiement n'a encore été effectué pour cette facture",
        only_one: "Vous ne pouvez supprimer qu'un seul élément à la fois.",
        delete_expense: "Voulez-vous supprimer cette dépense?",
        err_customer_name: "Invalid customer name",
        later: "Plus tard",
        sms: "SMS",
        upgrade_before_use: "Vous devez passer à Premium pour utiliser cette fonctionnalité!",
        activate_before_use: "Vous devez activer le Livre de caisse avant de l'utiliser!",
        other_reports: "Rapports Supplémentaires",
        choose_location: "Choisissez une location",
        load: "Charger le rapport",
        stock_number_taken: "Le numéro de stock existe déjà",
        product_number_taken: "Le numéro de produit existe déjà",
        stk_num_generated: "Numéro de stock généré avec succès",
        prod_num_generated: "Numéro de produit généré avec succès",
        generate: "Générez",
        aging_receivables: "Créances vieillissantes",
        asset_register: "Registre des actifs",
        model_brand: "Modèle / Marque",
        purchase_date: "Date d'achat",
        opening_bal: "Solde d'ouverture",
        depr_amount: "Amortissement",
        closing_bal: "Solde de clôture",
        serial_number: "Numéro de série",
        designated: "Désigné pour",
        tag: "Étiquette",
        dept: "Département",
        currentValue: "Valeur actuelle: ",
        age: "Âge: ",
        add_asset: "Nouveau Atout",
        years: "ans",
        new_asset: "Nouveau Atout",
        edit_asset: "Editer l'atout",
        deprecs: "Amortissements",
        tot_curr_val: "Valeur actuelle",
        total_cost: "Coût total",
        residual_val: "Valeur résiduelle: ",
        life_span: "Durée de vie",
        batch_number: "Numéro de lot",
        exact_qty_err: "Le nombre d'actifs spécifiques doit correspondre à la quantité!",
        total_assets: "Valeur totale des atouts",
        total_current_val: "Valeur actuelle totale",
        asset_items: "Les atouts",
        edit_asset_item: "Modifier l'actif",
        specified_qty: "Vous ne pouvez entrer que la quantité spécifiée!",
        err_serialNumber: "Vous devez saisir le numéro de série",
        no_asset_error: "Vous devez spécifier les actifs à ajouter!",
        noDefaultLocation: "Vous devez définir un emplacement par défaut afin de réaliser des ventes!",
        individual_assets:"Spécifiez les différent actifs",
        batch_num_generated: "Numéro de lot généré avec succès",
        err_asset_name: "Spécifiez le nom de l'actif",
        err_asset_number: "Spécifiez le numéro de lot",
        err_unit_cost: "Spécifiez le coût unitaire de l'actif",
        err_life_span: "Spécifiez la durée de vie de l'actif",
        dispose: "Disposer",
        automate_help: "Cochez la case automatiser pour que toutes vos transactions de compte bancaire comme les dépenses, etc, les rapprochements se fassent automatiquement, sinon vous devez les faire manuellement!",
        petty_cash_account:"Créer un compte de petite caisse",
        petty_cash_text: "Un compte de petite caisse sera créé pour vous, pouvez-vous lui donner un nom",
        bank_acc_bals: "Bank Account Balances",
        stock_sales_by_loc: "Ventes de stock par emplacement",
        cash_flow_rpt: "Rapport sur les flux de trésorerie",
        cashbook_rpt: "Rapport du livre de caisse",
        monthly_product_sales: "Ventes de produits par mois",
        expense_method: "Méthode de dépenses",
        active_assets: "Les atouts actifs",
        confirm: "Confirm",
        reject: "Reject",
        disposed_assets: "Les atouts disposée",
        disposed_date: "Date d'élimination",
        err_qty: "Spécifiez la quantité d'actif",
        err_asset_description: "Précisez la description des actifs",
        asset_categories: "Spécifiez les catégories",
        err_tag: "Veuillez spécifier l'identifiant de l'actif",
        err_description: "Veuillez préciser la description de l'actif!",
        err_valid_amount: "Veuillez saisir un montant valide",
        stock_saved: "Le stock est enregistré avec succès",
        product_saved: "Le produit est enregistré avec succès",
        invalid_unit_price: "Le prix unitaire que vous avez entré n'est pas valide",
        select_cat: "Veuillez sélectionner une catégorie",
        select_loc: "Veuillez sélectionner une location",
        select_rmetric: "Veuillez saisir la statistique de détail",
        invalid_sellable_unit: "Vous devez avoir une unité vendable supérieure à 1",
        invalid_prodName: "Veuillez saisir un nom de produit valide",
        not_specified: "Non précisé",
        //Shoppping
        video_link: "Lien vidéo",
        model: 'Modèle',
        weight: 'Poids',
        min_order_limit: 'Limite minimum de commande',
        review_settlements: 'Settlements',
        update_shop: 'Update Shop',
        request_settlement: 'Request Settlement',
        pending_settlement: 'Pending Settlements',
        query_issue: 'Report Issue',
        check: 'Check',
        transfer: 'Transfer',
        proceed: 'Proceed',
        select: 'Sélectionner',
        more: 'More',
        stopped_contracts: 'Stopped Contracts',
        two_months_old: 'Non Payment',
        unrenewed_contracts: 'Unrenewed Contracts',
        number_of_closed: 'Closed Contracts',
        afrijula_reports: 'Rapports Afrijula'
    },

});
export default strings;

//strings.setLanguage('fr');
const lang = strings.getLanguage();
