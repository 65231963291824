import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import Image from "components/afrijula/Utils/ImageUtil.jsx";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import countryList from "react-select-country-list";
import {
  onlyLetters,
  validateEmail,
  phonenumber,
  displayError,
  displaySuccess,
  getString,
} from "assets/js/utils/utils.js";
import SaveButton from "../CustomComponents/SaveButton";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});
const countries = countryList().getData();

class AddDetails extends React.Component {
  state = {
    callerName: this.props.callerName,
    callerProps: this.props.callerProps,
    callerState: this.props.callerState,
    _id: "",
    back: false,
    description: "",
    min_order_limit: "",
    product_weight: "",
    model: "",
    type: this.props.objectType,
    video_link: "",
    picture: "",
    name: "",
    weight_unit: "kg",
    weight_units: [
      { label: "kilo-grams", value: "kg" },
      { label: "grams", value: "g" },
    ],
  };

  getFillableData = () => {
    if (this.props.shoppableData) {
      let data = this.props.shoppableData;
      this.setState({
        description: data.description,
        product_weight: data.product_weight,
        video_link: data.video_link,
        model: data.model,
        name: data.name,
        min_order_limit: data.min_order_limit,
        weight_unit: data.product_weight_unit,
        _id: data._id,
      });
    }
  };

  backFillData = () => {
    if (this.props.backFill)
      this.props.backFill({
        description: this.state.description,
        weight: this.state.product_weight,
        update: true,
        video_link: this.state.video_link,
        model: this.state.model,
        type: this.state.type,
        name: this.state.name,
        min_order_limit: this.state.min_order_limit,
        product_weight_unit: this.state.weight_unit,
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  back = () => {
    this.setState({ back: true });
  };

  close = () => {
    this.setState({
      id: null,
      back: true,
    });
  };

  updateProduct = () => {
    if (!this.state.description || this.state.description == "") {
      displayError(
        "Please describe your product. This is important so customers understand what the are buying"
      );
      document.getElementById("description").focus();
      return;
    }
    if (!this.state.product_weight || this.state.product_weight == "") {
      displayError(
        "Please enter weight so shipping cost can be calculated. Call us if you need help"
      );
      document.getElementById("product_weight").focus();
      return;
    }
    let _this = this;

    return $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/shop/sync/" + this.state._id.$oid,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        description: this.state.description,
        weight: this.state.product_weight,
        update: true,
        video_link: this.state.video_link,
        model: this.state.model,
        type: this.state.type,
        name: this.state.name,
        min_order_limit: this.state.min_order_limit,
        product_weight_unit: this.state.weight_unit,
      },
      success: function(result) {
        displaySuccess("updated product for online sales");
        _this.backFillData();
        _this.setState({ back: true });
      }.bind(this),
      error: function(e) {
        displayError(e.responseText);
      },
    });
  };

  handleOptionChange = (opt) => {
    this.setState({ weight_unit: opt.value });
  };

  componentWillMount() {
    this.getFillableData();
  }

  render() {
    const { classes } = this.props;
    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      let caller_state = this.state.callerState;

      return (
        <ChildComponent
          state={caller_state}
          returningProps={this.state.callerProps}
        />
      );
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="name"
                      name="name"
                      label={getString()._name}
                      className={classes.textField}
                      value={this.state.name}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="min_order_limit"
                      name="min_order_limit"
                      label={getString().min_order_limit}
                      className={classes.textField}
                      value={this.state.min_order_limit}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      id="model"
                      name="model"
                      label={getString().model}
                      className={classes.textField}
                      value={this.state.model}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="product_weight"
                      name="product_weight"
                      label={getString().weight}
                      className={classes.textField}
                      value={this.state.product_weight}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <InputLabel id="weight_units_label">
                      Weight Units
                    </InputLabel>
                    <Select
                      options={this.state.weight_units}
                      id="model"
                      name="model"
                      labelId="weight_units_label"
                      className={classes.textField}
                      defaultValue={{
                        value: this.state.weight_unit,
                        label: this.state.weight_unit,
                      }}
                      onChange={(e) => {
                        this.handleOptionChange(e);
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <TextField
                      id="video_link"
                      name="video_link"
                      label={getString().video_link}
                      className={classes.textField}
                      value={this.state.video_link}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      multiline={true}
                      rows={2}
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={3}>
                        <strong>Primary</strong>
                        <Image
                          height="60px"
                          width="80px"
                          style={{ height: "1px", width: "5px" }}
                          model={this.state.type}
                          label={getString().picture}
                          upSize={500}
                          id={this.state._id.$oid}
                          callerName={"AddDetails"}
                          tag="picture"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <strong>(1)</strong>
                        <Image
                          model={this.state.type}
                          id={this.state._id.$oid}
                          style={{ height: 1 + "px", weight: 5 + "px" }}
                          callerName={"AddDetails"}
                          tag="picture_1"
                          label={getString().upload_pic}
                          upSize={900}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <strong>(2)</strong>
                        <Image
                          model={this.state.type}
                          id={this.state._id.$oid}
                          style={{ height: 1 + "px", weight: 5 + "px" }}
                          callerName={"AddDetails"}
                          tag="picture_2"
                          label={getString().upload_pic}
                          upSize={900}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <strong>(3)</strong>
                        <Image
                          model={this.state.type}
                          id={this.state._id.$oid}
                          style={{ height: 1 + "px", weight: 5 + "px" }}
                          callerName={"AddDetails"}
                          tag="picture_3"
                          label={getString().upload_pic}
                          upSize={900}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <strong>(4)</strong>
                        <Image
                          model={this.state.type}
                          id={this.state._id.$oid}
                          style={{ height: 1 + "px", weight: 5 + "px" }}
                          callerName={"AddDetails"}
                          tag="picture_4"
                          label={getString().upload_pic}
                          upSize={900}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth={true}
                      id="description"
                      name="description"
                      label={getString().description}
                      className={classes.textField}
                      value={this.state.description}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      margin="normal"
                      multiline={true}
                      rows={4}
                      variant="outlined"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    xs={12}
                    sm={6}
                    md={2}
                    color="danger"
                    onClick={this.close.bind(this)}
                    style={{
                      backgroundColor: "#f44336",
                      margin: ".3125rem 1px",
                      minWidth: "auto",
                      minHeight: "auto",
                      fontSize: "12px",
                      color: "#ffffff",
                      padding: "12px 30px",
                      borderRadius: "3px",
                      verticalAlign: "middle",
                    }}
                  >
                    {getString().cancel}
                  </Button>
                  <SaveButton
                    method={this.updateProduct}
                    title={getString().save}
                  />
                </GridItem>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddDetails);
