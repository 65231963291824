import React from "react";
import { useEffect } from "react";

import "./style.css";
import Android from "./android.svg";

function AndroidApp(props) {
  useEffect(() => {
    if (props.hasSaved()) {
      document.getElementById("myModal").style.display = "none";
    }
  }, []);

  const closeDialog = () => {
    props.saveClick();
    document.getElementById("myModal").style.display = "none";
  };

  return (
    <div
      id="myModal"
      onClick={(e) => {
        // making sure we can only close dialog using the backdrop
        if (e.target.id === "myModal") {
          closeDialog();
        }
      }}
      className="modal"
    >
      <div className="modal-content">
        <span className="close" onClick={() => closeDialog()}>
          &times;
        </span>
        <br />
        <div className="center">
          <p>Download mobile app for better experience</p>
          <a
            onClick={() => {
              closeDialog();
            }}
            className="button"
            href="https://play.google.com/store/apps/details?id=com.insist_global.afrijulaflutterapp"
            target="_blank"
          >
            Get the App{" "}
            <img
              src={Android}
              style={{
                width: "30px",
                height: "30px",
              }}
              alt="android image"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default AndroidApp;
