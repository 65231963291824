import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {getString, currencyFormat, dateFormat,compileQuery,showReportUtility,displayError} from "assets/js/utils/utils.js";
import AddDeposit from "components/afrijula/CashBook/AddDeposit.jsx";
import TransferBalance from "components/afrijula/CashBook/TransferBalance.jsx";
import DepositDetails from "components/afrijula/AllDetails/DepositDetails.jsx";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DetailsTable from '@material-ui/core/Table';
import Withdraw from "components/afrijula/CashBook/Withdraw.jsx";
import ReconcileAccount from "components/afrijula/CashBook/ReconcileAccount.jsx";
import TransactionDetail from "components/afrijula/AllDetails/TransactionDetails.jsx";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { green,red } from '@material-ui/core/colors';



const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class CashBookDetails extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    state = this.props.state || {

        back: false,

        accountHistoryHeader:  [
            {title: getString().date, field:'date', render: rowData => dateFormat(rowData.date),filtering: false,cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().type,field:'_type',render: rowData => rowData._type.substring(20), cellStyle:{padding:'4px'}, filtering: false, headerStyle:{padding:'4px'}},
            {title: getString().amount,field:'amount', render: rowData => currencyFormat(rowData.amount),cellStyle:{padding:'4px'},filtering: false, headerStyle:{padding:'4px'}},
            {title: getString().prev_balance,field:'prev_balance',render: rowData => currencyFormat(rowData.prev_balance),cellStyle:{padding:'4px'}, filtering: false,headerStyle:{padding:'4px'}},
            {title: "",field:'category',render: rowData => (rowData._type.substring(20) === "Deposit") ? <ArrowBackIcon style={{ color: green[500] }}/> : <ArrowForwardIcon style={{ color: red[500] }}/>,filtering: false, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: getString().new_balance,field:'new_balance',render: rowData => currencyFormat(rowData.new_balance), filtering: false,cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,

        cashBookData: this.props.cashBookData,
        add_deposit: false,
        transerBalance: false,
        depositData: null,
        add_withdraw: false,
        reconcile: false,
        transactionData: null

    };

    close = () => {
        this.setState({back: true})
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    addDeposit = () =>{
        if(this.state.cashBookData.status === "blocked"){
            displayError(getString().disabled_account_depo);
            return;
        }else{
            this.setState({
                add_deposit: true
            })
        }

    };

    rowSelect = (event, colData)  => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/cashbook/transactions/"+colData._id.$oid,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                result._type = colData._type;
                _this.setState({transactionData: result});
            }
        });
    };

    updateDetailsData = (data) =>{
        let _cashBook = this.state.cashBookData;
        _cashBook.balance = data.newBal;
        _cashBook.local_balance = data.newLocBal;
        this.setState({
            cashBookData: _cashBook
        })
    };

    transferBalance = () =>{
        if(this.state.cashBookData.status === "blocked"){
            displayError(getString().disabled_account_depo);
            return;
        }else{
            this.setState({
                transerBalance: true
            })
        }
    };

    withdrawBalance = () =>{
        if(this.state.cashBookData.status === "blocked"){
            displayError(getString().disabled_account_depo);
            return;
        }else{
            this.setState({
                add_withdraw: true
            })
        }
    };

    reconcileAccount = () =>{
        this.setState({
            reconcile: true
        })
    };

    showReport = (name,baseUrl, parts,params) => {
        showReportUtility(name,baseUrl, parts,cookies, params);
    };

    loadData = (query,resolve,reject) => {
        let filter = "bank_account_id: '"+this.state.cashBookData._id.$oid+"'";
        let url = compileQuery(query, (baseUrl + "/afrijula/cashbook/transactions"),
            ["id","_type","reference_number","new_balance","new_local_balance","account_name",
                "date","amount","bank_account_id","account_number","prev_balance","prev_local_balance"],
            [{"by":"asc","attr":"date"}], "Afrijula::Cashbook::Transaction",null,filter);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    render() {
        const {classes} = this.getProps();
        if(this.state.back){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state = {this.state.callerState} returningProps = {this.state.callerProps} />

        }
        if(this.state.add_deposit){
            return <AddDeposit callerProps={this.getProps()} updateMe={this.updateDetailsData.bind(this)}  bankAccount={this.state.cashBookData} callerState = {this.state} callerName={CashBookDetails} />;
        }
        if(this.state.transerBalance){
            return <TransferBalance callerProps={this.getProps()} callerState = {this.state} updateMe={this.updateDetailsData.bind(this)} fromAccount={this.state.cashBookData} callerName={CashBookDetails} />;
        }
        if(this.state.add_withdraw){
            return <Withdraw callerProps={this.getProps()} callerState = {this.state} updateMe={this.updateDetailsData.bind(this)} fromAccount={this.state.cashBookData} callerName={CashBookDetails} />;
        }
        if(this.state.depositData){
            return <DepositDetails callerProps={this.getProps()} callerState = {this.state} depositData={this.state.depositData} callerName={CashBookDetails} />;
        }
        if(this.state.reconcile){
            return <ReconcileAccount callerProps={this.getProps()} callerState = {this.state} updateMe={this.updateDetailsData.bind(this)} accountData={this.state.cashBookData} callerName={CashBookDetails} />;
        }
        if(this.state.transactionData){
            let data = this.state.transactionData;
            this.state.transactionData = null;

            return <TransactionDetail callerProps={this.getProps()} callerState = {this.state} transactionData={data} callerName={CashBookDetails} />;
        }
        else{

            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={9}>
                                    <Button xs={12} sm={12} md={2} color="success" onClick={this.transferBalance.bind(this)}
                                            style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">swap_horiz</i>
                                        {getString().transfer_balance}
                                    </Button>
                                    <Button xs={12} sm={12} md={2} color="primary" onClick={this.addDeposit.bind(this)}
                                            style={{backgroundColor: "#9c27b0", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">account_balance</i>
                                        {getString().reconcile_income}
                                    </Button>
                                    <Button xs={12} sm={12} md={2} color="warning" onClick={this.withdrawBalance.bind(this)}
                                            style={{backgroundColor: "#ff9800", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">remove</i>
                                        {getString().withdraw}
                                    </Button>
                                    <Button xs={12} sm={12} md={2} color="info" onClick={this.reconcileAccount.bind(this)}
                                            style={{backgroundColor: "#00acc1", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                    ><i className="material-icons">autorenew</i>
                                        {getString().reconcile_expense}
                                    </Button>
                                    <Button xs={12} sm={12} md={2} color="primary"
                                            style={{backgroundColor: "#9c27b0", margin: ".3125rem 1px", minWidth: "auto",
                                                minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                                            onClick={()=> {
                                                let end_date = new Date();
                                                let start_date = new Date(end_date.getFullYear(),end_date.getMonth()-1,end_date.getDate());
                                                let params = {organization_id: cookies.load('orgId'), bank_account_id: this.state.cashBookData._id.$oid, start_date: start_date, end_date: end_date};
                                                let inject = {images: [{name: 'image', attr:'image', id: cookies.load('orgId'), model: 'Organization'}]};
                                                this.showReport('Account Statement', baseUrl + '/reports/1?url=/afrijula/*&profile='+cookies.load("profileId"),
                                                    {params: params, sub: '&report=account_statement_report&dynamic=true', inject: inject},
                                                    [{type: 'date', default: start_date, label: "From",name:'start_date'},{type:'date', default:end_date, label: 'To',name:'end_date'}]);
                                            }}
                                    ><i className="material-icons">receipt</i>
                                        {getString().statement}
                                    </Button>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    <Button color="danger" onClick={() => {this.setState({back: true})}}
                                            style={{backgroundColor: "#f44336",margin: ".3125rem 1px", float: 'right', minWidth: "auto", minHeight:"auto",
                                                padding: "12px 30px",borderRadius: "3px", color:"#ffffff" }}
                                    >{getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{getString().details}</h4>
                                </CardHeader>
                                <CardBody>
                                    <div style={{"overflow-x":"auto"}}>
                                        <DetailsTable style={styles.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell  colSpan={2} style={{fontSize: 15+'px'}}>{getString().acc_name+": "+ this.state.cashBookData.name}</TableCell>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().acc_number+": "+ this.state.cashBookData.number}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().bank+": "+ this.state.cashBookData.bank}</TableCell>
                                                    <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().currency+": "+ this.state.cashBookData.currency}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().balance+": "+ currencyFormat(this.state.cashBookData.balance)}</TableCell>
                                                    <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{getString().local_balance+": "+ currencyFormat(this.state.cashBookData.local_balance)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 15+'px'}}>{getString().status+": "+ this.state.cashBookData.status}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </DetailsTable>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{marginTop:-10}}>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="success">
                                    <h4 className={classes.cardTitleWhite}>{getString().transactions}</h4>

                                </CardHeader>
                                <Table
                                    tableRef={this.tableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadData(query,resolve,reject);
                                    })}
                                    onRowClick={this.rowSelect}
                                    columns={this.state.accountHistoryHeader}
                                    options={{
                                        exportButton: false,filtering: true,
                                        grouping:false,sorting:true,
                                        debounceInterval: 1000,
                                        selection:false,showTitle:false,
                                        pageSize:10
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}


export default withStyles(styles)(CashBookDetails);
