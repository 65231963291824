/* eslint-disable */
import React from "react";
import cookies from "react-cookies";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/common/Header/Header.jsx";
import Sidebar from "components/afrijula/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/afrijula/dashboard.jsx";
import cashierRoutes from "routes/afrijula/cashier.jsx";
import storeManagerRoutes from "routes/afrijula/stockmanager.jsx";
import accountantRoutes from "routes/afrijula/accountant.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/drum.jpg";
import logo from "assets/img/AfrijulaLogo-transparent.png";
import ReportView from "components/afrijula/Reports/ReportView.jsx";

const returnRoutes = (routes) => {
  let array = [];
  routes.forEach((prop, key) => {
    if (prop.redirect)
      array.push(<Redirect from={prop.path} to={prop.to} key={key} />);
    else if (prop.children)
      prop.children.forEach((childProp, childKey) => {
        array.push(
          <Route
            path={childProp.path}
            render={(props) => (
              <childProp.component {...props} tag={childProp.tag} />
            )}
            key={childKey}
          />
        );
      });
    else
      array.push(
        <Route path={prop.path} component={prop.component} key={key} />
      );
  });
  return array;
};

const switchRoutes = (routes) => {
  return <Switch>{returnRoutes(routes)}</Switch>;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.props.history.location.state;
    let user = undefined;
    if (!this.state) {
      const rehydrate = JSON.parse(
        localStorage.getItem("afrijula.insist.state")
      );
      this.setState(rehydrate);
      this.props.history.location.state = this.state = rehydrate;
    }
    if (this.state) {
      user = this.props.history.location.state.user;
    }

    this.resizeFunction = this.resizeFunction.bind(this);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  determineRoute = (user_type, _package) => {
    let routes;
    switch (user_type) {
      case "Cashier":
        routes = cashierRoutes(_package);
        break;
      case "StoreManager":
        routes = storeManagerRoutes;
        break;
      case "Accountant":
        routes = accountantRoutes;
        break;
      default:
        routes = dashboardRoutes(_package);
    }
    //routes.determinePackageRoutes("basic");
    return routes;
  };

  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    if (this.props.reload) {
      window.location.reload();
    }
    localStorage.setItem("afrijula.insist.state", JSON.stringify(this.state));

    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.refs.mainPanel) {
        this.refs.mainPanel.scrollTop = 0;
      }
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;
    if (!this.state) {
      return <Redirect to="/landing" path="/landing" reload={true} />;
    } else if (this.state.user === undefined || !cookies.load("token")) {
      return <Redirect to="/login" reload={true} />;
    }

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.determineRoute(
            this.state.user.user_type,
            this.state.user.package
          )}
          logoText={"Afrijula"}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            routes={this.determineRoute(
              this.state.user.user_type,
              this.state.user.package
            )}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes(
                  this.determineRoute(
                    this.state.user.user_type,
                    this.state.user.package
                  )
                )}
              </div>
            </div>
          }

          {/* {this.getRoute() ? <Footer /> : null}*/}
        </div>
        <ReportView />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(App);
