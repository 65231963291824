import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {AsyncCreatable } from "react-select";
import AsyncSelect  from "react-select/async";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";

import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import $ from "jquery";
import {
  getToday,
  currencyFormat,
  displaySuccess,
  displayError,
  getString,
} from "assets/js/utils/utils.js";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import TextField from "@material-ui/core/TextField";
import CardHeader from "components/common/Card/CardHeader.jsx";
import Rodal from "rodal";
import AddSupplier from "components/afrijula/AddForm/AddingSupplier.jsx";
import IncomingStock from "components/afrijula/Stocks/IncomingStock.jsx";
import "rodal/lib/rodal.css";
import SaveButton from "../CustomComponents/SaveButton";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  noPadding: {
    padding: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class SupplierInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsChanged: false,
      addSuppInv: false,
      back: false,

      tempName: "",
      paySupplier: false,
      invoiceData: this.props.invoiceData || {
        amount: 0,
        sale_date: "",
        due_date: "",
        shipping_date: "",
        stock_items: [],
        number: "",
        payment_term: "",
        vat_amount: 0,
        location: "",
        other_tax: 0,
        sub_total: 0,
        total: 0,
        supplier: "",
        shipping_cost: 0,
      },
      today: "",
      addSupplier: false,
      tag: "suplierInvoice_Stock",

      supplierDetail: {
        supplier: "",
      },

      supplierData: this.props.supplierData,
      createdSupplierId: null,

      callerName: this.props.callerName,
      callerProps: this.props.callerProps,
      callerState: this.props.callerState,
    };
  }

  rowSelect = (colData, cellMeta) => {
    var newData = this.state.historyData;
    var rowdata = this.state.data[cellMeta.dataIndex];

    let i = 0;
    for (; i < rowdata.length; i++) {
      newData[i] = rowdata[i];
    }
    this.setState({ historyData: newData });
    this.setState({ historyDetails: !this.state.historyDetails });
  };

  updateInvoice = (name, val) => {
    let data = this.state.invoiceData;
    data[name] = val;
    this.setState({ invoiceData: data });
  };

  handleChange = (e) => {
    this.updateInvoice(e.target.name, e.target.value);
  };

  back = () => {
    this.setState({ back: true });
  };

  componentDidMount() {
    this.setState({
      sale_date: getToday(),
      shipping_date: getToday(),
      due_date: getToday(),
    });
  }

  loadStock(params, callback) {
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/stocks/stock",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        attrs: ["id", "name"],
        query: "{number: " + params.number + "}",
        model: "Afrijula::Stocks::Stock",
      },

      success: function(result) {
        callback(params, result[0]);
      }.bind(this),
    });
  }

  locationOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/locations/location?attrs=["name"]&model=Afrijula::Locations::Location&unique=name&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;
        for (; i < json.length; i++) {
          opts[i] = { label: json[i], value: json[i] };
        }
        return opts;
      }) // my option list array?
      .catch((err) => {
        console.log(err);
      });
  };

  supplierOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/suppliers/supplier?attrs=["name","id"]&model=Afrijula::Suppliers::Supplier&to=name&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;
        for (; i < json.length; i++) {
          opts[i] = { label: json[i].name, value: json[i]._id.$oid };
        }
        return opts;
      }) // my option list array?
      .catch((err) => {
        console.log(err);
      });
  };

  hideSupplier = () => {
    this.setState({ addSupplier: false });
  };

  showSupplier = () => {
    this.setState({ addSupplier: true });
  };

  showSupplierList = (classes) => {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={"lecketInlineItems"}>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>{getString().add_stock_inv}</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>

              <CardBody>
                <form className={classes.container} autoComplete="off">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <AsyncSelect
                        defaultOptions={true}
                        loadOptions={this.supplierOptions}
                        placeholder={getString().select_supplier}
                        defaultOption={this.state.createdSupplierId}
                        cache={false}
                        ref={"selectSupplier"}
                        name="supplier"
                        id="supplier"
                        onChange={(opt) => {
                          this.setState({ createdSupplierId: opt.value });
                        }}
                        variant="outlined"
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      style={window.innerWidth >= 940 ? { marginTop: "-15px" } : { marginTop: "6px" }}
                    >
                      <Button
                        xs={12}
                        sm={12}
                        md={2}
                        color="success"
                        onClick={this.showSupplier.bind(this)}
                        size={"sm"}
                        style={{
                          backgroundColor: "#4caf50",
                          margin: ".3125rem 1px",
                          minWidth: "auto",
                          minHeight: "auto",
                          fontSize: "12px",
                          color: "#ffffff",
                          padding: "12px 30px",
                          borderRadius: "3px",
                          verticalAlign: "middle",
                        }}
                      >
                        <i className="material-icons">add</i>{" "}
                        {getString().add_supplier}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Rodal
          visible={this.state.addSupplier}
          height={490}
          width={400}
          showMask={false}
          onClose={this.hideSupplier.bind(this)}
        >
          <div style={{ marginTop: "35px" }}>
            <AddSupplier
              callerProps={this.getProps()}
              callerState={this.state}
              updateSupplier={this.updateSupplier.bind(this)}
              callerName={SupplierInvoice}
              closeInvoice={this.hideSupplier}
            />
          </div>
        </Rodal>
        {this.supplierInvoice()}
      </div>
    );
  };

  updateSupplier = (data) => {
    this.setState({
      createdSupplierId: { label: data.name, value: data._id.$oid },
    });
    this.refs.selectSupplier.loadOptions();
  };

  intToFloat = (num, decPlaces) => {
    return num.toFixed(decPlaces);
  };

  saveInvoice = () => {
    let data = JSON.parse(JSON.stringify(this.state.invoiceData));

    data.no_supplier = false;
    let location = data.location;
    if (location == "") {
      displayError("Please select a location");
      return;
    }

    let inv_no = data.number;
    if (inv_no == "") {
      displayError("Invoice Number cannot be empty. Please set");
      return;
    }
    data.supplier_id = this.props.supplierData
      ? this.props.supplierData._id.$oid
      : this.state.createdSupplierId;
    if (!data.supplier_id) {
      displayError("You did not specify a supplier for this invoice");
      return;
    }

    if (data.stock_items.length == 0) {
      displayError("Please enter atleast on item for the invoice");
      return;
    }

    let total = 0.0;
    data.stock_items.forEach((item) => {
      total += item.total;
    });

    data.sub_total = total;

    total = total + Number(data.vat_amount);
    data.total = total + Number(data.other_tax);
    data.total = total + Number(data.shipping_cost);

    let _this = this;
    return $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/suppliers/invoice",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: data,

      success: function(result) {
        displaySuccess("Successfully Saved Invoice");
        _this.setState({ back: true });
        if (_this.props.loadInvoice) {
          _this.props.loadInvoice(result.id);
        }
      },

      error: function(resp) {
        displayError(resp.responseText);
      },
    });
  };

  updateStockQty = (items) => {
    let inv = this.state.invoiceData;
    inv.stock_items = items;
    this.setState({ invoiceData: inv });
  };

  supplierInvoice = () => {
    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      return (
        <ChildComponent
          state={this.state.callerState}
          returningProps={this.state.callerProps}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().sale_date}: </b>
                          <TextField
                            required
                            id="sale_date"
                            name="sale_date"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.sale_date}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            type="date"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().due_date}: </b>
                          <TextField
                            required
                            id="due_date"
                            name="due_date"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.due_date}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            type="date"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().delivery_date}: </b>
                          <TextField
                            required
                            id="shipping_date"
                            name="shipping_date"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.shipping_date}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            type="date"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().location}: </b>
                          <Card style={{ marginTop: 10, zIndex: 50 }}>
                            <AsyncSelect
                              defaultOptions={true}
                              loadOptions={this.locationOptions}
                              defaultValue={{
                                label: this.state.invoiceData.location,
                                value: this.state.invoiceData.location,
                              }}
                              name="location"
                              id="location"
                              onChange={(opt) => {
                                this.updateInvoice("location", opt.value);
                              }}
                              variant="outlined"
                            />
                          </Card>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().inv_number}: </b>
                          <TextField
                            required
                            id="number"
                            name="number"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.number}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().shipping_cost}: </b>
                          <TextField
                            required
                            id="shipping_cost"
                            name="shipping_cost"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.shipping_cost}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            type="number"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().vat}: </b>
                          <TextField
                            required
                            id="vat_amount"
                            name="vat_amount"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.vat_amount}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            type="number"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <p>
                          <b>{getString().other_tax}: </b>
                          <TextField
                            required
                            id="other_tax"
                            name="other_tax"
                            type="number"
                            inputProps={{
                              style: { padding: "6px 10px", height: "1.5em" },
                            }}
                            value={this.state.invoiceData.other_tax}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            margin="none"
                            variant="outlined"
                          />
                        </p>
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <IncomingStock
                adviseChange={this.updateStockQty.bind(this)}
                callerProps={this.state.callerProps}
                callerState={this.state.callerState}
                callerName={this.state.callerName}
                parentBack={this._back.bind(this)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                xs={12}
                sm={12}
                md={4}
                color="danger"
                onClick={() => {
                  this.setState({ back: true });
                }}
                style={{
                  backgroundColor: "#f44336",
                  margin: ".3125rem 1px",
                  minWidth: "auto",
                  minHeight: "auto",
                  fontSize: "12px",
                  color: "#ffffff",
                  padding: "12px 30px",
                  borderRadius: "3px",
                  verticalAlign: "middle",
                }}
              >
                {getString().cancel}
              </Button>
              <SaveButton method={this.saveInvoice} title={getString().save} />
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  };
  getProps = () => {
    return this.props.returningProps || this.props;
  };

  componentDidUpdate(prevProps, prevState, snapshit) {}

  _back = () => {
    let ChildComponent = this.state.callerName;
    let caller_state = this.state.callerState;
    caller_state.addSuppInv = false;
    return (
      <ChildComponent
        state={this.state.callerState}
        returningProps={this.state.callerProps}
      />
    );
  };
  render() {
    const { classes } = this.getProps();

    if (this.state.back) {
      return this._back();
    }
    if (!this.state.supplierData) {
      return this.showSupplierList(classes);
    } else {
      return this.supplierInvoice();
    }
  }
}

export default withStyles(styles)(SupplierInvoice);
