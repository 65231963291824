// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Notes from "@material-ui/icons/Notes";
// import ContentPaste from "@material-ui/icons/ContentPaste";
// core components/views
import DashboardPage from "components/afrijula/Dashboard/Dashboard.jsx";
import Sales from "components/afrijula/Sales/SalesHome.jsx";
import InventoryProducts from "components/afrijula/Products/InventoryProducts.jsx";
import Supplier from "components/afrijula/Supplier/Supplier.jsx";
import Expense from "components/afrijula/ExpenseManager/ExpenseManager.jsx";
import {getString} from "assets/js/utils/utils.js";
import LocationOn from "@material-ui/icons/LocationOn";
import Report from "components/afrijula/Reports/Reports.jsx";
import Produce from "@material-ui/icons/LocalGroceryStore"
import {Book, People} from "@material-ui/icons";
import CashBook from "components/afrijula/CashBook/CashBook.jsx";
import Customer from "components/afrijula/Customer/Customer.jsx";
import Paper from "@material-ui/icons/Assignment"


const dashboardRoutes = [
    {
        path: "/dashboard",
        sidebarName: getString().dashboard,
        icon: Dashboard,
        component: DashboardPage
    },
    {
        path: "/sale",
        sidebarName: getString().sales,
        icon: Notes,
        component: Sales
    },
    {
        path: "/customer",
        sidebarName: getString().customers,
        icon: People,
        component: Customer
    },
    {
        path: "/products",
        sidebarName: getString().inventoryProducts,
        icon: Produce,
        component: InventoryProducts
    },
    {
        path: "/supplier",
        sidebarName: getString().suppliers,
        icon: Person,
        component: Supplier
    },
    {
        path: "/purchases",
        sidebarName: getString().purchases,
        icon: Book,
        component: Expense
    },
    {
        path: '/cashbook',
        sidebarName: getString().cash_book,
        icon: Paper,
        component: CashBook
    },
    {
        path: "/reports",
        sidebarName: getString().reports,
        icon: LocationOn,
        component: Report
    },

    {redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect"}
];

export default dashboardRoutes;
