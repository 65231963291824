import  React from 'react';
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input/Input";
import Select from "react-select";
import {currencies, displayError, displaySuccess, getString, getGender, getRegions, getBusinessType, getAgeBrackets} from "assets/js/utils/utils.js";

const Country = require('country-js');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});


class NewAccount extends React.Component{


    state={

    };
    handleCurrency = (value) => {
        let curr = Country.search(value['value']);
        // let _detail = this.state.details;
        if (curr && curr[0]) {
            let data = curr[0].currency.currencyCode; // _detail.default_currency = data;
            this.props.onSave({target: {name: 'currency', value: data}})
            this.props.onSave({target: {name: 'countryName', value: value['label'] + '(' + data + ')'}});
        }
    };


    render() {
        const { classes } = this.props;


        return (
            <form className="">
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="business_name">{getString().business_name}</InputLabel>
                    <Input id="business_name" name="business_name" autoComplete="business_name" autoFocus
                           onChange={(e) => {
                               this.props.onSave(e);
                           }}/>
                </FormControl>


                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="currency">{getString().currency}</InputLabel>
                    <Select options={currencies} placeholder={this.state.countryName} name="currency"
                            id="currency"
                            value={this.state.currency} onChange={this.handleCurrency}/>
                </FormControl>

                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="business_type">{getString().business_size}</InputLabel>
                    <Select options={getBusinessType()} placeholder={this.state.business_type} name="business_type"
                            id="business_type"
                            value={this.state.business_type}
                            onChange={e => this.props.onSave({target:{name: 'business_type', value: e['value']}})}/>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="region">{getString().region}</InputLabel>
                    <Select options={getRegions()} placeholder={this.state.region} name="region"
                            id="region"
                            value={this.state.region} onChange={e => this.props.onSave({target:{name: 'region', value: e['value']}})}/>
                </FormControl>
            </form>
        );
    }
}

export default withStyles(styles)(NewAccount);
