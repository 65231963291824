import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";

class ContractLength extends Component {

    render() {
        return (
        <>
       <TextField
          id="standard-number"
          label={getString().number}
          type="number"
          inputProps={{ min: "1"}}
          name="length"
          defaultValue="1"
          InputLabelProps={{
            shrink: true,
          }}
        value={this.props.length}
        onChange={this.props.onChangeValue}
        />
        <FormControl component="fieldset">
            <RadioGroup
              row={true} 
              aria-label="Contract Length" 
              name="term"
              className="genre-wrapper"
              value={this.props.value}
              onChange={this.props.onChangeValue}
            >
            { (this.props.charge === 'month' || this.props.charge === 'quarter' || this.props.charge === 'year' || this.props.bill ==='month' ||
             this.props.bill === 'quarter' || this.props.bill === 'year')? (<FormControlLabel disabled value="week" control={<Radio />} label={getString().Weeks} />):
            (<FormControlLabel value="week" control={<Radio />} label={getString().Weeks} />)}
            { (this.props.charge === 'quarter' || this.props.charge === 'year' ||
            this.props.bill === 'quarter' || this.props.bill === 'year')? (<FormControlLabel disabled value="month" control={<Radio />} label={getString().Months} />):
            (<FormControlLabel value="month" control={<Radio />} label={getString().Months} />)}
            { (this.props.charge === 'year' || this.props.bill === 'year')? (<FormControlLabel disabled value="quarter" control={<Radio />} label={getString().Quarters} />):
            (<FormControlLabel value="quarter" control={<Radio />} label={getString().Quarters} />)}
            <FormControlLabel value="year" control={<Radio />} label={getString().Years} />
            </RadioGroup>
          </FormControl>
          </>
          );
    }
}

export default ContractLength
