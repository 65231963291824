import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import cookies from "react-cookies";
import $ from "jquery";
import baseUrl from "assets/js/config/const.js";
import {
  currencyFormat,
  dateFormat,
  compileQuery,
  displaySuccess,
  displayError,
  getString,
} from "assets/js/utils/utils.js";
import MaterialTable from "material-table";
import { withRouter } from "react-router";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SalesReturns extends React.Component {
  state = {
    payment_methods: [
      { label: "cash", value: "cash" },
      { label: "bank transfer", value: "bank transfer" },
      { label: "cheque", value: "cheque" },
    ],

    header: [
      { title: getString().product, field: "name" },
      { title: getString().quantity, field: "quantity" },
      {
        title: getString().unit_price,
        field: "unit_price",
        render: (rowData) => currencyFormat(rowData.unit_price),
      },
      {
        title: getString().total,
        field: "total",
        render: (rowData) => currencyFormat(rowData.total),
      },
    ],
    back: false,
    showReversedSales: false,
    salesData: this.props.salesData,
    callerName: this.props.callerName,
    callerProps: this.props.callerProps,
    callerState: this.props.callerState,
  };

  returnItems = () => {
    const saleData = this.state.salesData;
    const saleId = saleData._id.$oid;

    let _this = this;
    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/sales/sales/update/",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { id: saleId, request_reverse: true },

      success: function(result) {
        displaySuccess(result.success, "success");
        if(_this.props.loadReversalRequests){
          _this.props.loadReversalRequests();
        }
        _this.goBack();
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  goBack = () => {
    this.setState({ back: true });
  };

  render() {
    const { classes } = this.props;

    if (this.state.back) {
      this.props.history.push("/sales");
    }
    return (
      <div style={{ width: 100 + "%", height: 100 + "%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4> {getString().sales_return} </h4>
                <span> {getString().reverse_message} </span>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    title={getString().sold_items}
                    columns={this.state.header}
                    data={this.state.salesData.sales_items}
                    options={{
                      search: false,
                      paging: false,
                      showEmptyDataSourceMessage: false,
                    }}
                  />
                </GridItem>
              </CardBody>
              <CardFooter>
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    xs={12}
                    sm={6}
                    md={2}
                    color="danger"
                    onClick={this.goBack}
                    style={{
                      backgroundColor: "#f44336",
                      margin: ".3125rem 1px",
                      minWidth: "auto",
                      minHeight: "auto",
                      fontSize: "12px",
                      color: "#ffffff",
                      padding: "12px 30px",
                      borderRadius: "3px",
                      verticalAlign: "middle",
                    }}
                  >
                    {getString().cancel}
                  </Button>
                  <Button
                    xs={12}
                    sm={6}
                    md={2}
                    color="success"
                    onClick={() => this.returnItems()}
                    style={{
                      backgroundColor: "#4caf50",
                      margin: ".3125rem 1px",
                      minWidth: "auto",
                      minHeight: "auto",
                      fontSize: "12px",
                      color: "#ffffff",
                      padding: "12px 30px",
                      borderRadius: "3px",
                      verticalAlign: "middle",
                    }}
                  >
                    {getString().save}
                  </Button>
                </GridItem>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SalesReturns.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SalesReturns));
