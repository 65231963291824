import React from "react";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Card from "../../common/Card/Card";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer";
import PropTypes from "prop-types";
import CardBody from "../../common/Card/CardBody";
import {currencyFormat} from "../../../assets/js/utils/utils";
import Button from "../../common/CustomButtons/Button";
import HelpUtil from "../Utils/HelpUtil";


class SingleLoanItem extends React.Component {
    state = this.props.state || {
        callerName: this.props.callerName,
        callerState: this.props.callerState,
    };

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    viewLoanProduct = (e) => {
        if (this.props.viewLoanProduct) {
            this.props.viewLoanProduct(this.state.fsp_name, e.target.parentElement.id);
        }
    };

    render() {
        let helplet = "";
        if ( this.props.loan.name === "DORLEL JIGEEN"){
            helplet = <HelpUtil reference={"dorlel_jigeen"} />
        }else if (this.props.loan.name === "JULADING JULO"){
            helplet = <HelpUtil reference={"julading_julo"} />
        }else if (this.props.loan.name === "Julaba"){
            helplet = <HelpUtil reference={"julaba"} />
        }else if (this.props.loan.name === "STANDARD GROUP"){
            helplet = <HelpUtil reference={"standard_group"} />
        }
        return (
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="warning">
                        <h4>FSP: {this.props.loan.fsp}</h4>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <div id={this.props.loan._id.$oid}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Loan Name</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>{this.props.loan.name}</p><span className="float-right">{helplet}</span>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Purpose</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>{this.props.loan.purpose}</p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Maximum Amount</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>{currencyFormat(this.props.loan.maximum_amount)}</p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Minimum Amount</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>{currencyFormat(this.props.loan.minimum_amount)}</p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Loan Tenure (Months)</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>{this.props.loan.tenor}</p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Interest Rate (%)</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>{this.props.loan.interest_rate}</p>
                                    </GridItem>
                                </GridContainer>
                                <Button onClick={this.viewLoanProduct}
                                        id={this.props.loan._id.$oid}>view...</Button>


                            </div>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

SingleLoanItem.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(SingleLoanItem);

