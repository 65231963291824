import frStrings from  "assets/js/locales_fr.js";
import enStrings from  "assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import countryList from 'react-select-country-list';


export const Country = require('country-js');

export const countries = countryList().getData();
export const currencies = countryList().getData();

const myStyles = {
    row:{
        backgroundColor: "#DDD",
        fontSize: "14px",
    }
}

export function displaySuccess(message) {
    GlobalHeader.successToast(message)

}

export function displayError(message) {
    
    try{
        let data = JSON.parse(message)
        if(data == null)
            throw('invalid');

        if(Array.isArray(data)){
            message = data.map((m) => {
                if(m.error)
                    return m.error
                else
                    return m
            }).join('<br/>')
        }else{
            let ms = data.error
            if(typeof ms === 'string' || ms instanceof String){
                message = ms;
            }else if(Object.keys(ms).length > 0){
                message = Object.keys(ms).map((m) => {
                    return ms[m];
                }).join('<br/>');
            }else{
                message = ms
            }

        }
    }   catch (e) {

    } finally {
        GlobalHeader.errorToast(JSON.stringify(message));
    }


}
export function getFilterComparator(opsymbole){
    var x = null;
    switch(opsymbole){
        case '=':
            x = ' => ';
        case '>':
            x =  '.gt => ';
        case '<':
            x = '.lt => ';
        case '<=':
            x = '.lte => ';
        case '>=':
            x = '.gte => ';
        default:
            x = ' => '
    }
    return x;
}

export function currencyValue(cents, inCents){
    let val = (cents && cents.fractional !== undefined) ? cents.fractional : ((cents && cents.cents !== undefined) ? cents.cents : (cents || 0))
    return (val === 0) ? 0 : Number((inCents) ? val : val/100.0)
}

export function compileQuery(query, url, attrs, defaultOrder, model,defaultData,defaultFilter,searchModels,filterModels, grouping){

    let data = defaultData || {};

    data.attrs =attrs;
    if(query.page !== undefined && query.page !== null) {
        data.page = query.page + 1;
        data.size = query.pageSize;
    }
    data.new_format = true;
    data.model = model;

    if(query.orderBy){
        data.order = [{"attr":query.orderBy.field, "by": query.orderDirection}];
    }else{
        data.order = defaultOrder;
    }

    if(query.search){
        data.search = query.search;
    }

    if(grouping){
        data.group = grouping
    }

    let f = []
    if(defaultFilter) {
        f.push(defaultFilter);
    }
    if(searchModels)
        data.searchModels = searchModels
    if(query.filters){
        query.filters.forEach((item)=>{
            f.push(":"+item.column.field+getFilterComparator(item.column.tableData.operator)+'\''+item.column.tableData.filterValue+'*\'')
        })
    }
    data.query='{'+f.join(',')+'}';
    if(filterModels){

    }
    url += '?'+$.param(data);
    return url;
}

let GlobalHeader = null;
export function setGlobalHeader(self){
    GlobalHeader = self;
};
let GlobalState = null;

export function setGlobal(self){
    GlobalState = self;
}

export function getGender(){
    const genderList = [
        { value: 'Female', label: 'Female'},
        { value: 'Male', label: 'Male' }
    ]
    return genderList;
}

export function getRegions(){
    return [
        { value: 'BJL', label: 'BJL'},
        { value: 'CRR', label: 'CRR' },
        { value: 'KMC', label: 'KMC' },
        { value: 'LRR', label: 'LRR' },
        { value: 'NBR', label: 'NBR' },
        { value: 'URR', label: 'URR' },
        { value: 'WCR', label: 'WCR' }
    ];
}

export function getAgeBrackets(){
    return [
        { value: '15-24', label: '15-24'},
        { value: '25-35', label: '25-35' },
        { value: 'Over 35', label: 'Over 35' }
    ];
}

export function getBusinessType(){
    return [
        { value: 'MSME', label: 'MSME'},
        { value: 'Large Corporation', label: 'Large Corporation' }
    ];
}

function onLoadFrame(self,params){
    let pages = [];
    let pageList = [];
    let currentPage = null;

    var x = $('#reportFrame').contents().find('br').hide();
    var x = $('#reportFrame').contents().find('.jrPage');
    let domPages = $('#reportFrame').contents().find('.jrPage');
    if(domPages.length > 0) {
        domPages.each(function (idx) {
            pages[idx] = $(this);
            pageList[idx] = 'page ' + (idx + 1);
            if (idx > 0) {
                $(this).hide();
            } else {
                currentPage = $(this);
            }
        });
        let xy = $('#reportFrame').contents().find('body');
        $('#reportFrame')[0].style.height = '' + $('#reportFrame').contents().find('body').scrollHeight + 'px';
    }else{
        currentPage = true;
    }
    let domImages = $('#reportFrame').contents().find('img');
    domImages.each(function(idx){

        let img = $(this);
        if(img){
            var src = img[0].src;
            img[0].src = src.replace(/http[s]?:\/\/[\w.:\d]+/g,"https://api.lecket.gm")+"?profile_id="+cookies.load('profileId');
        }


    });
    self.setState({pageList: pageList,pages:pages,currentPage:currentPage});
};

export function getReportUrl(baseUrl, parts){
    return baseUrl+'&params=' + escape(JSON.stringify(parts.params)) +parts.sub +"&injects="+ JSON.stringify(parts.inject);
}

export function loadReport(baseUrl,parts,params){
    $.ajax({
        method: "GET",
        url: getReportUrl(baseUrl,parts)+'&format=text/html',
        type:'HTML',
        headers: {
            "Authorization": "token " + cookies.load("token"),
            "UserProfile": cookies.load("profile"),
            "UserKey": cookies.load('User-Key')
        },success: function (data) {

            $('#reportFrame').contents().find('html').html(data);
            setTimeout(function(){
                onLoadFrame(GlobalState,params);
            },1000)

        }.bind(GlobalState),
        error: function(error){
            alert(error.responseText);
        }
    });
}

export function canSeeDash(){
    const user_type = cookies.load('user_type');
    return (user_type && (user_type != 'Admin' && user_type != 'AccountAdmin' && user_type != 'Accountant')) ? false : true;
}

export function computeCurrency(by, currency, operator){
    currency = (currency === null || currency === undefined) ? 0 : currency;
    currency = (currency.cents) ? getDecimal(currency.cents) :  (currency.fractional) ? getDecimal(currency.fractional) : currency;
    switch(operator){
        case '*':
            return currency * by;
        case '/':
            return currency / by;
        case '+':
            return currency + by;
        case '-':
            return currency - by;
    }
    return null;
}


export function getItemByLocation  (arr, value,opts)  {

    var optionsMatch = (options1, options2) => {
        if(options1.length === options2.length){
            let keys = Object.keys(options1);

            for(let i = 0; i< keys.length; i++){

                if(options1[keys[i]] != options2[keys[i]])
                    return false;
            }
            return true;
        }
        return false;
    };

    for (let i=0; i < arr.length; i++) {
        if (arr[i].location === value && optionsMatch(opts, arr[i].options)) {
            return i;
        }
    }
    return null;
};

export function getLocaleString() {
    const l = getLocale();
    const hash = {'en-GB': 'English (UK)', 'en-US': 'English (US)', 'fr': 'French'};
    return hash[l];
}

export function getString(){
    var strings = enStrings;
    var lang = getLocale();
    if(lang === "fr"){
        strings = frStrings;
    }

    return strings;
};


export function showReportUtility(name,baseUrl, parts,cookies,params){
    GlobalState.setState({report: {view: true, name:name, baseUrl:baseUrl, parts:parts, params: params}});
    loadReport(baseUrl,parts,params)
}


export function execPrint(element){

    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write(element.innerHTML);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function(){
        mywindow.print();
        mywindow.close();
    }, 2000);

    return true;
};

export function getLocale (){
    return cookies.load('locale') || 'en-GB';
}

export function setLocale (locale){
    cookies.save('locale', locale);
}

export function dateFormat(n,time) {

    //sbensouda: we may have senarios where mongo send us "none" when there is no value. We
    //need to check for this and prevent it from happening.
    if (!n)
        return '';
    if(n=='none')
        return n;
    //Used for date display
    var opts = {};
    opts.day = "numeric";
    // opts.weekday = "long";
    opts.year = "numeric";
    opts.month = "numeric";
    var lang = getLocale();

    var d = n.slice(0,10);
    let _tmp = d.split('-');

    let dd = _tmp[2];
    let mm = _tmp[1]-1;
    let yyyy = _tmp[0];
    if(time){
        var t = n.split('T')[1].slice(0,9).split(':');
        var event = new Date(Date.UTC(yyyy, mm, dd, t[0], t[1], t[2]));

        return event.toLocaleDateString(lang, opts) + " " + event.toLocaleTimeString(lang);
    }else{
        var event = new Date(Date.UTC(yyyy, mm, dd));

        return event.toLocaleDateString(lang, opts);
    }
}


export function numberFormat(n) {
    //cache the formatter once
    if(window.Intl && !window.numberFormatter) window.numberFormatter = window.Intl.NumberFormat();

    if(window.numberFormatter) {
        return window.numberFormatter.format(n);
    } else {
        return n;
    }
}

function getDecimal(fig){
    return (fig == 0 || fig == undefined || fig == null) ? 0.0 : fig/100.0;
}
export function currencyFormat(n,currency) {

    var lang = getString();
    if(lang === "") lang = navigator.language;
    var opts = {};
    opts.style = "currency";
    if(n && n.currency)
        opts.currency = (n.currency) ?  (n.currency.iso_code || n.currency) : n.currency_iso;
    else {
        //    TODO sbensouda: we must include currency support from settings in server
        opts.currency = cookies.load('defaultCurrency') || "GMD";
    }
    if(!n)
        return 0.0;
    if(!n.currency && !n.currency_iso){
        if(window.Intl) {
            var formatter = new window.Intl.NumberFormat(lang, opts);
            return formatter.format(n);
        }else{
            return n;
        }
    }

    //sbensouda: all money on the server is stored in cents.
    if(window.Intl) {
        var formatter = new window.Intl.NumberFormat(lang,opts);
        return formatter.format((n.cents) ? getDecimal(n.cents) :  getDecimal(n.fractional));
    } else {
        return n/100;
    }
}


//be careful this function will return the reverse. If the package is valid it will return false and if not valid it will return true.
export function applyPackaging(pp){
    let pack = cookies.load("packages");
    if(pack === undefined)
        return true;
    if(pack.length === 1 && pack[0] === 'all')
        return false;
    let i = 0;
    for(; i < pack.length; i++){
        if(pack[i] === pp)
            break;
    }
    return (i === pack.length) ? true : false;
}

export function handleCurrency(value) {
    let curr = Country.search(value['value']);
    // let _detail = this.state.details;
    if(curr && curr[0]){
        let data = curr[0].currency.currencyCode; // _detail.default_currency = data;
        this.setState({
            default_currency: data,
            countryName: value['label']+ '('+data+')',
        });
    }
};

export function onlyLetters(str) {
    return ((str != null)
        && (!str==="")
        && (str.matches("^[a-zA-Z]+$")));

}

export function phonenumber(input){
    var phoneNumber = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[0-9]+[-\s]*[0-9]*$/;
    if(input.match(phoneNumber)) {
        return true;
    }
    else {
        return false;
    }
}


export function validateNumberField(number){
    var reg = new RegExp('^[+-]?((\\d+(\\.\\d*)?)|(\\.\\d+))$');
    return reg.test(number);
};
export function validateEmail(email){
    if(email === null || email === undefined || email.lenth == 0)
        return false;

    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
};

export function getToday (){

    let date = new Date();
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    if(dd < 10){
        dd = '0'+dd;
    }
    if(mm < 10){
        mm = '0'+mm;
    }

    return yyyy+"-"+mm+"-"+dd;

};

export function removeDuplicates (data){
    return data.reduce((prev, ele) => {
        let found = prev.find((element) => {
            return ele.label === element.label && ele.value === element.value;
        });
        if (!found) {
            prev.push(ele);
        }
        return prev;
    }, []);
};
