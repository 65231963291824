import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Table from "material-table";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import {
  displayError,
  currencyFormat,
  getToday,
  getString,
  dateFormat,
  compileQuery,
  showReportUtility,
  displaySuccess,
} from "assets/js/utils/utils.js";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import CashBookDetails from "components/afrijula/AllDetails/CashBookDetails.jsx";
import TransferBalance from "components/afrijula/CashBook/TransferBalance.jsx";
import Withdraw from "components/afrijula/CashBook/Withdraw.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import CardIcon from "components/common/Card/CardIcon.jsx";
import AddDeposit from "components/afrijula/CashBook/AddDeposit.jsx";
import BankAccount from "components/afrijula/AddForm/BankAccount.jsx";
import $ from "jquery";
import Rodal from "rodal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import Select from "react-select";
import FormHelperText from "@material-ui/core/FormHelperText";
import HelpIcon from "@material-ui/icons/Help";
import TextField from "@material-ui/core/TextField";
import { applyPackaging } from "../../../assets/js/utils/utils";
import HelpUtil from "../Utils/HelpUtil";
import WindowSizeListener from "react-window-size-listener";
import DisabledUI from "../../common/Templates/DisabledUI/DisabledUI";

//Helper icon component for reusability
const HelperTextButton = (props) => {
  return (
    <span onClick={props._action}>
      <FormHelperText>
        <HelpIcon color={props.color} />
        {props.title}
      </FormHelperText>
    </span>
  );
};

class CashBook extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = this.props.state || {
    cashBookData: null,
    winSize: window.innerWidth,
    loading: false,
    package: null,
    header: [
      {
        title: getString()._name,
        field: "name",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().date,
        field: "opening_balance_date",
        render: (rowData) => dateFormat(rowData.opening_balance_date),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().number,
        field: "number",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().bank,
        field: "bank",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().balance,
        field: "balance",
        render: (rowData) => currencyFormat(rowData.balance),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
      {
        title: getString().local_balance,
        field: "local_balance",
        render: (rowData) => currencyFormat(rowData.local_balance),
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],

    banksWithNoAccHeader: [
      {
        title: getString()._name,
        field: "name",
        cellStyle: { padding: "4px" },
        headerStyle: { padding: "4px" },
      },
    ],
    add_deposit: false,
    transerBalance: false,

    callerName: this.props.callerName,
    callerProps: this.props.callerProps,
    callerState: this.props.callerState,

    addBankAccount: false,
    totalUndepositedAmount: 0,
    totalPendingDeposit: 0,
    totalWithdrawn: 0,
    add_withdraw: false,
    cashbookActivated: false,
    settings_id: null,
    banksWithNoAccount: [],
    showBanksWithNoAccount: false,
    newAccountName: "",
    addAccount: false,
    currentIndex: null,
    automateReconciliation: null,
    cashbook_activated: false,
    myIcon: "unblocked",
    upgrade: false,
    packages: [
      { label: "Basic", value: "basic" },
      { label: "Standard", value: "standard" },
      { label: "Premium", value: "premium" },
      { label: "Premium Plus", value: "premium_plus" },
    ],
    currentPackage: "",
    newReportsHeader: [
      { title: "", field: "name" },
      { title: "", field: "tag", hidden: true },
    ],
    newReportsData: [
      { name: "Bank Account Balances", tag: "account_balances" },
      { name: "CashBook report", tag: "cash_flow" },
      { name: "Cash flow report", tag: "cashbook_report" },
    ],
    createCashAccount: true,
    accountData: {
      name: "Petty Cash",
      accNumber: "",
      bank: "",
      currency: "",
      balance: 0,
      local_balance: 0,
      date: "",
    },
    lastSize: null,
    recalledPage: "",
    recalledPageSize: null,
    lastPage: null,
    showHelperText: false,
  };

  rowSelect = (event, colData) => {
    let id = colData._id.$oid;
    this.loadAccount(id);
  };

  loadAccount = (id) => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/cashbook/bank_account/" + id,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ cashBookData: result });
      },
    });
  };

  loadData = (query, resolve, reject) => {
    let url = compileQuery(
      query,
      baseUrl + "/afrijula/cashbook/bank_account",
      [
        "id",
        "name",
        "number",
        "bank",
        "opening_balance_date",
        "currency",
        "balance",
        "local_balance",
        "status",
      ],
      [{ by: "asc", attr: "opening_balance_date" }],
      "Afrijula::Cashbook::BankAccount",
      null,
      null
    );

    this.state.lastPage = query.page;
    this.state.lastSize = query.pageSize;

    fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  recallPage = () => {
    const page = this.state.recalledPage;
    this.state.recalledPage = null;
    return page;
  };

  deleteAccount = (data) => {
    let confirm_message =
      data[0].status === "blocked"
        ? getString().confirm_activate_acc
        : getString().confirm_deactivate_acc;
    let response = window.confirm(confirm_message);
    if (response) {
      let _this = this;
      data.map((account) => {
        let account_state =
          data[0].status === "unblocked" ? "blocked" : "unblocked";
        $.ajax({
          method: "PUT",
          url: baseUrl + "/afrijula/cashbook/bank_account/" + account._id.$oid,
          dataType: "json",
          headers: {
            Authorization: "token " + cookies.load("token"),
            UserProfile: cookies.load("profile"),
            UserKey: cookies.load("User-Key"),
          },
          data: { status: account_state },
          success: function(result) {
            _this.updateTable();
          },
          error: function(e) {
            displayError(e.responseText);
          },
        });
      });
    }
  };

  updateTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  transferBalance = () => {
    this.setState({
      transerBalance: true,
    });
  };

  addDeposit = () => {
    this.setState({
      add_deposit: true,
    });
  };

  withdraw = () => {
    this.setState({
      add_withdraw: true,
    });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  addBankAccount = () => {
    this.setState({
      addBankAccount: true,
    });
  };

  loadTilesData = () => {
    let _this = this;

    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/dashboard",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { count: ["undeposited_amount", "withdrawn_amount"], chart: [] },

      success: function(result) {
        let amount = result.counts.undeposited_amount;
        let withdrawn_amount = result.counts.withdrawn_amount;
        _this.setState({
          totalUndepositedAmount: amount,
          totalWithdrawn: withdrawn_amount,
        });
      }.bind(this),
    });
  };

  loadSettings = () => {
    $.ajax({
      method: "GET",
      url: baseUrl + "/account/settings/-1",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: { attrs: ["cashbook_activated", "automate_reconciliation", "_id"] },

      success: function(result) {
        this.setState({
          automateReconciliation: result.automate_reconciliation,
          settings_id: result._id.$oid,
        });
      }.bind(this),
    });
  };

  loadBankAccounts = () => {
    let item = "bank";
    const url =
      baseUrl +
      '/afrijula/expenses/expense?attrs=["' +
      item +
      '"]&model=Afrijula::Expenses::Expense&match=&unique=' +
      item;

    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let opts = [];
        let i = 0;

        for (; i < data.length; i++) {
          opts[i] = { name: data[i], id: data[i], createdFor: false };
        }
        this.setState({
          banksWithNoAccount: opts,
        });
      })
      .catch((err) => {
        displayError(err.responseText);
      });
  };

  activateCashBook = () => {
    let _isActivated = true;
    let _automateReconciliation = this.state.automateReconciliation;

    let id = this.state.settings_id;
    let _this = this;

    let reg = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;
    if (
      this.state.accountData.name === "" ||
      !reg.test(this.state.accountData.name)
    ) {
      displayError(getString().err_acc_name);
      if (this.state.createCashAccount) {
        document.getElementById("name").focus();
      }
      return;
    }

    $.ajax({
      method: "PUT",
      url: baseUrl + "/account/settings/" + id,
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        cashbook_activated: _isActivated,
        automate_reconciliation: _automateReconciliation,
      },

      success: function(result) {
        displaySuccess("Cashbook successfully activated");
        cookies.save("cashbookActivated", _isActivated);
        cookies.save("automateReconciliation", _automateReconciliation);
        _this.loadSettings();

        let active =
          cookies.load("cashbookActivated") === "false" ? false : true;
        _this.setState({
          showBanksWithNoAccount: true,
          cashbookActivated: active,
        });
        _this.createCashAccount();
      }.bind(this),
    });
  };

  updateBankList = () => {
    let bankTable = this.state.banksWithNoAccount.slice(
      0,
      this.state.banksWithNoAccount.length
    );
    let item = bankTable[this.state.currentIndex];
    bankTable.splice(this.state.currentIndex, 1);
    this.setState({
      banksWithNoAccount: bankTable,
    });
  };

  closeAccountRodal = (e) => {
    this.setState({
      addAccount: false,
    });
  };

  showAccountForm = () => {
    return (
      <BankAccount
        bankName={this.state.newAccountName}
        closeCashbookRodal={this.closeAccountRodal}
        updateList={(opt) => this.updateBankList(opt)}
      />
    );
  };

  createAccountFor = (data) => {
    this.setState({
      currentIndex: data.tableData.id,
      newAccountName: data.name,
      addAccount: true,
    });
  };

  createLater = () => {
    this.setState({ showBanksWithNoAccount: false });
  };

  noAccountBanks = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            color="danger"
            onClick={this.createLater.bind(this)}
            style={{
              backgroundColor: "#f44336",
              margin: ".3125rem 1px",
              float: "right",
              minWidth: "auto",
              minHeight: "auto",
              padding: "12px 30px",
              borderRadius: "3px",
              color: "#ffffff",
            }}
          >
            {getString().later}
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <span>
            <strong>{getString().createAccNames}</strong>
          </span>
          <Card>
            <Table
              tableRef={this.tableRef}
              data={this.state.banksWithNoAccount}
              columns={this.state.banksWithNoAccHeader}
              localization={{
                header: {
                  actions: "",
                },
              }}
              style={{
                width: 350,
              }}
              options={{
                exportButton: false,
                filtering: false,
                grouping: false,
                sorting: false,
                debounceInterval: 1000,
                toolbar: false,
                selection: false,
                showTitle: true,
                actionsColumnIndex: -1,
                pageSize: 10,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.createdFor === true ? "#e6732b" : "#FFF",
                }),
              }}
              actions={[
                {
                  tooltip: getString().create,
                  icon: "create",
                  onClick: (evt, data) => this.createAccountFor(data),
                },
              ]}
              components={{
                Action: (props) => (
                  <Button
                    onClick={(event) => props.action.onClick(event, props.data)}
                    color="info"
                    variant="contained"
                    style={{
                      backgroundColor: "#00acc1",
                      margin: ".3125rem 1px",
                      minWidth: "auto",
                      minHeight: "auto",
                      fontSize: "10px",
                      color: "#ffffff",
                      padding: "10px 20px",
                      borderRadius: "3px",
                      verticalAlign: "middle",
                    }}
                    size="small"
                  >
                    {getString().create}
                  </Button>
                ),
              }}
            />
          </Card>
        </GridItem>
        <Rodal
          visible={this.state.addAccount}
          height={420}
          width={800}
          showMask={false}
          onClose={this.closeAccountRodal.bind(this)}
        >
          {this.state.addAccount ? this.showAccountForm() : null}
        </Rodal>
      </GridContainer>
    );
  };

  handleChange = (e) => {
    let account = this.state.accountData;
    account[e.target.name] = e.target.value;
    if (e.target.name === "balance") {
      account.local_balance = e.target.value;
    }
    this.setState({
      accountData: account,
    });
  };

  createCashAccount = () => {
    let accountData = this.state.accountData;
    let account_name = accountData.name;
    let account_number = accountData.accNumber;
    let currency = accountData.currency;
    let balance = parseFloat(accountData.balance);
    let local_balance = parseFloat(accountData.local_balance);

    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/cashbook/bank_account",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: {
        account_name: account_name,
        balance: balance,
        bank: account_name,
        account_number: account_number,
        local_balance: local_balance,
        opening_balance_date: getToday(),
        currency: currency,
        petty_cash: true,
      },
      success: function(result) {
        displaySuccess(getString().err_acc_success);
      }.bind(this),
      error: function(response) {
        {
          displayError(response.responseText);
        }
      },
    });
  };

  displayHelper = () => {
    this.setState({ showHelperText: !this.state.showHelperText });
  };

  showActivationButton = (classes) => {
    return (
      <div>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <span>{getString().activate_before_use}</span>
            <Button
              xs={12}
              sm={6}
              md={2}
              color="success"
              onClick={this.activateCashBook.bind(this)}
              style={{
                backgroundColor: "#4caf50",
                margin: ".3125rem 1px",
                minWidth: "auto",
                minHeight: "auto",
                fontSize: "12px",
                color: "#ffffff",
                padding: "12px 30px",
                borderRadius: "3px",
                verticalAlign: "middle",
              }}
            >
              {getString().activate}
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.automateReconciliation}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      automateReconciliation: !this.state
                        .automateReconciliation,
                    });
                  }}
                  value={this.state.automateReconciliation}
                />
              }
              label={getString().automateRecon}
            />
            {
              <HelperTextButton
                color="primary"
                _action={this.displayHelper}
                title={
                  this.state.showHelperText ? getString().automate_help : null
                }
              />
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.createCashAccount}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      createCashAccount: !this.state.createCashAccount,
                    });
                  }}
                  value={this.state.createCashAccount}
                />
              }
              label={getString().petty_cash_account}
            />
          </GridItem>
          {this.state.createCashAccount && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label={getString().acc_name}
                  className={classes.textField}
                  value={this.state.accountData.name}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                  helperText={getString().petty_cash_text}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  id="balance"
                  name="balance"
                  label={getString().balance}
                  className={classes.textField}
                  value={this.state.accountData.balance}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
          )}
        </GridItem>
      </div>
    );
  };

  handleChangePck = (event) => {
    this.setState({
      currentPackage: event,
    });
  };

  displayReport = (event, data) => {
    this.getAdditionalReport(data.tag);
  };

  getAdditionalReport = (tag) => {
    let report = "";
    let reportName = "";
    switch (tag) {
      case "account_balances":
        report = "bank_account_balancesjrxml";
        reportName = "Account Balances";
        break;
      case "cashbook_report":
        report = "cashbook_report";
        reportName = "Cashbook Report";
        break;
      case "cash_flow":
        report = "cash_flow_report";
        reportName = "Cash Flow";
        break;
      default:
        report = "";
        reportName = "";
    }

    let end_date = new Date();
    let start_date = new Date(
      end_date.getFullYear(),
      end_date.getMonth() - 1,
      end_date.getDate()
    );
    let params = {
      start_date: start_date,
      end_date: end_date,
      organization_id: cookies.load("orgId"),
      org_name: cookies.load("orgName"),
    };
    let inject = {
      images: [
        {
          name: "image",
          attr: "image",
          id: cookies.load("orgId"),
          model: "Organization",
        },
      ],
    };
    this.showReport(
      reportName,
      baseUrl +
        "/reports/1?url=/afrijula/*&profile=" +
        cookies.load("profileId"),
      {
        params: params,
        sub: "&report=" + report + "&dynamic=true",
        inject: inject,
      },
      [
        {
          type: "date",
          default: start_date,
          label: "From",
          name: "start_date",
        },
        {
          type: "date",
          default: end_date,
          label: "To",
          name: "end_date",
        },
      ]
    );
  };

  showReport = (name, baseUrl, parts, params) => {
    showReportUtility(name, baseUrl, parts, cookies, params);
  };

  getNewReportList = () => {
    return (
      <Card>
        <CardHeader color="success">
          <h4>{getString().reports}</h4>
        </CardHeader>
        <Table
          columns={this.state.newReportsHeader}
          data={this.state.newReportsData}
          onRowClick={this.displayReport}
          options={{
            columnsButton: true,
            sorting: true,
            toolbar: false,
            paging: false,
            cellStyle: { padding: "2px" },
            headerStyle: { padding: "2px" },
          }}
        />
      </Card>
    );
  };

  loadOptions = () => {
    let _this = this;
    $.ajax({
      method: "GET",
      url: baseUrl + "/afrijula/afrijula/packages/",
      data: { localize: true },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ package: result.cashbook });
      },
    }).catch((e) => {
      displayError("Cashbook data could not be loaded. Try again!");
    });
  };

  componentDidMount() {
    this.loadSettings();
    this.loadTilesData();
    this.loadBankAccounts();
    this.loadOptions();
  }

  componentWillMount() {
    let active = cookies.load("cashbookActivated") === "false" ? false : true;
    this.setState({
      cashbookActivated: active,
    });
  }

  upgrade = () => {
    let upgradeDetails = { package: this.state.currentPackage.value };
    let _this = this;

    $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/afrijula/update_package",
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      data: upgradeDetails,

      success: function(result) {
        cookies.save("package", _this.state.currentPackage);
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  enableCashbook = () => {
    this.setState({ loading: true });
    this.loadOptions();
    if (this.state.package) {
      let _this = this;
      $.ajax({
        method: "POST",
        url: baseUrl + "/afrijula/afrijula/update_package/",
        data: {
          name: "cashbook",
          status: this.state.package.status === false ? true : false,
          exception: true,
        },
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(result) {
          displaySuccess("Options updated");
          console.log(result);
          _this.setState({ loading: false });
        },
      }).catch((e) => {
        displayError(e.responseText);
        this.setState({ loading: false });
      });
    } else {
      displayError("Cashbook data could not be loaded. Try again.");
      this.setState({ loading: false });
    }
  };

  upgradePackage = () => {
    return (
      <DisabledUI
        feature="Cash Book"
        method={this.enableCashbook}
        loading={this.state.loading}
        about={
          "Cash book keeps track of your accounts. You can see your expenses and easily manage your business."
        }
      />
    );
  };

  handleSelectionChange = (data) => {
    let icon = data[0] === undefined ? "unblocked" : data[0].status;
    this.setState({
      myIcon: icon,
    });
  };

  render() {
    const { classes } = this.getProps();

    if (this.state.cashbookActivated === false && applyPackaging("cashbook")) {
      return this.showActivationButton(classes);
    }

    if (applyPackaging("cashbook")) {
      return this.upgradePackage();
    }

    if (
      this.state.showBanksWithNoAccount &&
      this.state.banksWithNoAccount.length > 0
    ) {
      return this.noAccountBanks();
    }

    if (this.state.addBankAccount) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <BankAccount
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={CashBook}
        />
      );
    }
    if (this.state.cashBookData) {
      let data = this.state.cashBookData;
      this.state.cashBookData = null;
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <CashBookDetails
          callerProps={this.getProps()}
          callerState={this.state}
          cashBookData={data}
          callerName={CashBook}
        />
      );
    }
    if (this.state.transerBalance) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <TransferBalance
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={CashBook}
        />
      );
    }
    if (this.state.add_deposit) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <AddDeposit
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={CashBook}
        />
      );
    }
    if (this.state.add_withdraw) {
      this.state.recalledPage = this.state.lastPage;
      this.state.recalledPageSize = this.state.lastSize;
      return (
        <Withdraw
          callerProps={this.getProps()}
          callerState={this.state}
          callerName={CashBook}
        />
      );
    } else {
      return (
        <WindowSizeListener
          onResize={(size) => this.setState({ winSize: size.windowWidth })}
        >
          <div>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <Icon>attach_money</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().undeposited}
                    </p>
                    <h4 className={classes.cardTitle}>
                      {currencyFormat(this.state.totalUndepositedAmount)}
                    </h4>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <span
                        onClick={() => {
                          let end_date = new Date();
                          let start_date = new Date(
                            end_date.getFullYear(),
                            end_date.getMonth() - 1,
                            end_date.getDate()
                          );
                          let params = {
                            organization_id: cookies.load("orgId"),
                            org_name: cookies.load("orgName"),
                            start_date: start_date,
                            end_date: end_date,
                          };
                          let inject = {
                            images: [
                              {
                                name: "image",
                                attr: "image",
                                id: cookies.load("orgId"),
                                model: "Organization",
                              },
                            ],
                          };
                          showReportUtility(
                            "Undeposited totalAmount",
                            baseUrl +
                              "/reports/1?url=/afrijula/*&profile=" +
                              cookies.load("profileId"),
                            {
                              params: params,
                              sub:
                                "&report=customer_turnover_report&dynamic=true",
                              inject: inject,
                            },
                            cookies,
                            [
                              {
                                type: "date",
                                default: start_date,
                                label: "From",
                                name: "start_date",
                              },
                              {
                                type: "date",
                                default: end_date,
                                label: "To",
                                name: "end_date",
                              },
                            ]
                          );
                        }}
                        style={{ cursor: "pointer", color: "#800080" }}
                      >
                        {getString().details}
                      </span>
                    </div>
                    <HelpUtil reference={"cashbook_undeposited_amount"} />
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon>money</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().pending_depo}
                    </p>
                    {/*TODO: Alagie- This amount will be 0 for now untill the withdrawal or cancellation of a deposit is implemented*/}
                    <h4 className={classes.cardTitle}>
                      {currencyFormat(this.state.totalPendingDeposit)}
                    </h4>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <span
                        onClick={() => {
                          let end_date = new Date();
                          let start_date = new Date(
                            end_date.getFullYear(),
                            end_date.getMonth() - 1,
                            end_date.getDate()
                          );
                          let params = {
                            organization_id: cookies.load("orgId"),
                            org_name: cookies.load("orgName"),
                            start_date: start_date,
                            end_date: end_date,
                          };
                          let inject = {
                            images: [
                              {
                                name: "image",
                                attr: "image",
                                id: cookies.load("orgId"),
                                model: "Organization",
                              },
                            ],
                          };
                          showReportUtility(
                            "Pending Deposits",
                            baseUrl +
                              "/reports/1?url=/afrijula/*&profile=" +
                              cookies.load("profileId"),
                            {
                              params: params,
                              sub: "&report=aging_debptors_report&dynamic=true",
                              inject: inject,
                            },
                            cookies,
                            [
                              {
                                type: "date",
                                default: start_date,
                                label: "From",
                                name: "start_date",
                              },
                              {
                                type: "date",
                                default: end_date,
                                label: "To",
                                name: "end_date",
                              },
                            ]
                          );
                        }}
                        style={{ cursor: "pointer", color: "#800080" }}
                      >
                        {getString().details}
                      </span>
                    </div>
                    <HelpUtil reference={"cashbook_pending_deposits"} />
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Icon>money_off</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {getString().withdrawnAmount}
                    </p>
                    <h4 className={classes.cardTitle}>
                      {currencyFormat(this.state.totalWithdrawn)}
                    </h4>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <span
                        onClick={() => {
                          let end_date = new Date();
                          let start_date = new Date(
                            end_date.getFullYear(),
                            end_date.getMonth() - 1,
                            end_date.getDate()
                          );
                          let params = {
                            organization_id: cookies.load("orgId"),
                            org_name: cookies.load("orgName"),
                            start_date: start_date,
                            end_date: end_date,
                          };
                          let inject = {
                            images: [
                              {
                                name: "image",
                                attr: "image",
                                id: cookies.load("orgId"),
                                model: "Organization",
                              },
                            ],
                          };
                          showReportUtility(
                            "Undeposited totalAmount",
                            baseUrl +
                              "/reports/1?url=/afrijula/*&profile=" +
                              cookies.load("profileId"),
                            {
                              params: params,
                              sub:
                                "&report=customer_turnover_report&dynamic=true",
                              inject: inject,
                            },
                            cookies,
                            [
                              {
                                type: "date",
                                default: start_date,
                                label: "From",
                                name: "start_date",
                              },
                              {
                                type: "date",
                                default: end_date,
                                label: "To",
                                name: "end_date",
                              },
                            ]
                          );
                        }}
                        style={{ cursor: "pointer", color: "#800080" }}
                      >
                        {getString().details}
                      </span>
                    </div>
                    <HelpUtil reference={"cashbook_withdrawn amount"} />
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                {this.getNewReportList()}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={9}>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="info"
                  onClick={this.addBankAccount.bind(this)}
                  style={{
                    backgroundColor: "#00acc1",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">add</i>
                  {getString().add_bank_acc}
                </Button>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="success"
                  onClick={this.transferBalance.bind(this)}
                  style={{
                    backgroundColor: "#4caf50",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">swap_horiz</i>
                  {getString().transfer_balance}
                </Button>
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="primary"
                  onClick={this.addDeposit.bind(this)}
                  style={{
                    backgroundColor: "#9c27b0",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">account_balance</i>
                  {getString().reconcile_income}
                </Button>
                {this.state.winSize <= 600 ? (
                  ""
                ) : (
                  <HelpUtil reference={"cashbook_reconcile_income"} />
                )}
                <Button
                  xs={12}
                  sm={12}
                  md={2}
                  color="warning"
                  onClick={this.withdraw.bind(this)}
                  style={{
                    backgroundColor: "#ff9800",
                    margin: ".3125rem 1px",
                    minWidth: "auto",
                    minHeight: "auto",
                    fontSize: "12px",
                    color: "#ffffff",
                    padding: "12px 30px",
                    borderRadius: "3px",
                    verticalAlign: "middle",
                  }}
                  style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                >
                  <i className="material-icons">remove</i>
                  {getString().withdraw}
                </Button>
              </GridItem>

              <GridItem xs={12} sm={12} md={3} />
            </GridContainer>
            <GridContainer style={{ marginTop: -10 }}>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <Table
                    tableRef={this.tableRef}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let page = this.recallPage();
                        if (page) {
                          query.page = page;
                        }
                        this.loadData(query, resolve, reject);
                      })
                    }
                    columns={this.state.header}
                    onRowClick={this.rowSelect}
                    title={getString().accounts}
                    onSelectionChange={this.handleSelectionChange}
                    options={{
                      exportButton: false,
                      filtering: false,
                      grouping: false,
                      sorting: true,
                      debounceInterval: 1000,
                      selection: true,
                      showTitle: true,
                      pageSize: this.state.recalledPageSize || 20,
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.status === "blocked" ? "#e6732b" : "#FFF",
                      }),
                    }}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh",
                        isFreeAction: true,
                        onClick: () =>
                          this.tableRef.current &&
                          this.tableRef.current.onQueryChange(),
                      },
                      {
                        tooltip: "",
                        icon: () =>
                          this.state.myIcon === "unblocked" ? (
                            <LockIcon />
                          ) : (
                            <LockOpenIcon />
                          ),
                        onClick: (evt, data) => this.deleteAccount(data),
                      },
                    ]}
                  />
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </WindowSizeListener>
      );
    }
  }
}

CashBook.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(CashBook);
