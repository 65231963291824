import React from "react";
import Rodal from "rodal";

import AsyncCreatableSelect from 'react-select/async-creatable';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import baseUrl from "assets/js/config/const.js";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import SellPage from "components/afrijula/Sell/NewSell.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AsyncSelect from 'react-select/async';
import $ from "jquery";
import cookies from "react-cookies";
import {
  numberFormat,
  getString,
  displayError,
  displaySuccess,
  currencyFormat,
  dateFormat,
  execPrint,
  applyPackaging,
  showReportUtility,
  computeCurrency,
  currencyValue,
} from "assets/js/utils/utils.js";
import GenericPayment from "components/afrijula/AddForm/GenericPayment.jsx";
import SalesReturns from "components/afrijula/Sales/SalesReturns";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import MaterialTable from "material-table";
import Receipt from "components/afrijula/Sales/Receipt";
import DeleteRequests from "components/afrijula/Sales/DeleteRequests";
import Link from "@material-ui/core/Link/Link";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import CustomerNoteDetails from "components/afrijula/AllDetails/CustomerNoteDetails";
import NotificationDetails from "components/afrijula/AllDetails/NotificationDetails";
import ContractReceipt from "../Contracts/ContractReceipt";
import WindowSizeListener from "react-window-size-listener";
import MobileReceipt from "../../common/MobileReceipt/MobileReceipt";
import CustomLoaderGif from "../../common/Loader/paying.gif";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    width: "100%",
  },
  menu: {
    width: 200,
  },
  paper: {
    width: "100%",
    overflowX: "auto",
  },
  table: {},
  tablecell: {
    size: "small",
    paddingTop: "10px",
    paddingBottom: "0px",
  },
});

class ReceiptTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.changeTableRef = React.createRef();
  }

  state = this.props.state || {
    cycles: [
      {
        value: "day",
        label: getString().daily,
      },
      {
        value: "week",
        label: getString().weekly,
      },
      {
        value: "month",
        label: getString().monthly,
      },
      {
        value: "quarter",
        label: getString().quarterly,
      },
      {
        value: "year",
        label: getString().annually,
      },
    ],
    newProduct: {
      name: null,
      total: null,
      number: null,
      quantity: 1,
      unit_price: null,
    },
    showProdParts: false,
    back: false,
    sellQuote: false,
    salesData: this.props.salesData,
    showPaymentRodal: false,
    showSaleReturn: false,
    callerName: this.props.callerName,
    callerState: this.props.callerState,
    callerProps: this.props.callerProps,
    askDate: false,
    askChange: false,
    askTerm: false,
    askDiscount: false,
    billingPeriod: "",
    continueAction: null,
    exitPrint: false,
    autoSend: true,
    trnsValue: new Date(),
    billables: [],
    discountAmount: null,
    activePayment: null,
    deletePayment: null,
    deleteReason: null,
    changeLocation: false,
    location: null,
    winSize: window.innerWidth,
    paymentRodalHeight: 500
  };

  onChange = (e) => {
    alert(e.target.value);
  };

  goBack = () => {
    this.setState({ back: true });
  };

  getProps = () => {
    return this.props.returningProps || this.props;
  };

  createRow = (id, name, quantity, unit_cost, unit_price, total) => {
    return { id, name, quantity, unit_cost, unit_price, total };
  };

  createPaymentRow = (id, date, amount, status) => {
    return { id, date, amount, status };
  };

  createRowInst = (id, start, date, amount, balance) => {
    return { id, start, date, amount, balance };
  };

  paymentPlanRow = (id, count, period, amount, due_date) => {
    return { id, count, period, amount, due_date };
  };

  insertContract = (classes) => {
    if (this.state.salesData.tran_type !== "contract") return;
    return (
      <Card>
        <CardHeader color="success">
          <h5 className={classes.cardTitleWhite}>{getString().contract}</h5>
        </CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Paper className={classes.paper}>
              <Table className={classes.table} size="small">
                <TableRow>
                  <TableCell>{getString().end_date}: </TableCell>
                  <TableCell>
                    <b>
                      {dateFormat(
                        this.state.salesData.invoice.contract_end_date
                      )}
                    </b>
                  </TableCell>
                  <TableCell>{getString().billing}:</TableCell>
                  <TableCell>
                    <Link link={true} onClick={this.changeBilling}>
                      <b>every {this.state.salesData.invoice.payment_period}</b>
                    </Link>
                  </TableCell>
                  <TableCell>{getString().tax}</TableCell>
                  <TableCell>
                    <Link link={true} onClick={this.changeTax}>
                      {this.state.salesData.tax_percentage} %
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{getString().charge}: </TableCell>
                  <TableCell>
                    <b>every {this.state.salesData.invoice.cycle}</b>
                  </TableCell>
                  <TableCell>{getString().discount}</TableCell>
                  <TableCell>
                    <b>
                      <Link link={true} onClick={this.changeDiscount}>
                        {this.state.salesData.payment_plan.discount_amount} (
                        {this.state.salesData.payment_plan.discount_type})
                      </Link>
                    </b>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </Table>
            </Paper>
          </GridItem>
        </GridContainer>
      </Card>
    );
  };

  invoice = () => {
    //if there is no invoice we should fail
    if (!this.state.salesData.invoice) return;
    const row2 = this.state.salesData.invoice.installments
      ? this.state.salesData.invoice.installments.map((row, id) =>
          this.createRowInst(
            row._id,
            row.start,
            row.date,
            row.amount,
            row.balance
          )
        )
      : [];
    const { classes } = this.getProps();
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {this.insertContract(classes)}
          <Card>
            <CardHeader color="success">
              <h5 className={classes.cardTitleWhite}>{getString().invoice}</h5>
            </CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{getString().date}</TableCell>
                        <TableCell>
                          <b>{getString().number}</b>
                        </TableCell>
                        <TableCell>{getString().amount}</TableCell>
                        <TableCell>
                          <b>{getString().balance}</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {dateFormat(this.state.salesData.invoice.date)}
                        </TableCell>
                        <TableCell>
                          {this.state.salesData.invoice.number}
                        </TableCell>
                        <TableCell>
                          {currencyFormat(this.state.salesData.invoice.amount)}
                        </TableCell>
                        <TableCell>
                          {currencyFormat(this.state.salesData.invoice.balance)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <h5 style={{ textAlign: "left" }}>
                    {getString().installments}
                  </h5>
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Paper className={classes.paper}>
                      <Table className={classes.table} size="small">
                        <TableHead>
                          <TableRow>
                            {this.state.salesData.tran_type === "contract" ? (
                              <TableCell>{getString().start}</TableCell>
                            ) : null}
                            <TableCell>{getString().due_date}</TableCell>
                            <TableCell>{getString().amount}</TableCell>
                            <TableCell>{getString().balance}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row2.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                {this.state.salesData.tran_type ===
                                "contract" ? (
                                  <TableCell>{dateFormat(row.start)}</TableCell>
                                ) : null}
                                <TableCell>{dateFormat(row.date)}</TableCell>
                                <TableCell>
                                  {currencyFormat(row.amount)}
                                </TableCell>
                                <TableCell>
                                  {currencyFormat(row.balance)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  closePrint = () => {
    this.setState({
      exitPrint: false,
      autoSend: false,
      activePayment: null,
    });
  };

  paymentPlan = () => {
    if (!this.state.salesData.payment_plan) return;
    const row2 = this.state.salesData.payment_plan.installments.map((row, id) =>
      this.paymentPlanRow(
        row._id,
        row.count,
        row.period,
        row.amount,
        row.due_date
      )
    );
    const { classes } = this.getProps();
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h5 className={classes.cardTitleWhite}>
                {getString().payment_plan}
              </h5>
            </CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{getString().amount}</TableCell>
                        <TableCell>
                          <b>{getString().deposit_amount}</b>
                        </TableCell>
                        <TableCell>{getString().installment_type}</TableCell>
                        <TableCell>
                          <b>{getString().payment_type}</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {currencyFormat(
                            this.state.salesData.payment_plan.amount
                          )}
                        </TableCell>
                        <TableCell>
                          {currencyFormat(
                            this.state.salesData.payment_plan.deposit_amount
                          )}
                        </TableCell>
                        <TableCell>
                          {this.state.salesData.payment_plan.installment_type}
                        </TableCell>
                        <TableCell>
                          {this.state.salesData.payment_plan.payment_type}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <h5 style={{ textAlign: "left" }}>
                    {getString().installments}
                  </h5>
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Paper className={classes.paper}>
                      <Table className={classes.table} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{getString().count}</TableCell>
                            <TableCell>{getString().period}</TableCell>
                            <TableCell>{getString().amount}</TableCell>
                            <TableCell>{getString().due_date}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row2.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{numberFormat(row.count)}</TableCell>
                              <TableCell>{row.period}</TableCell>
                              <TableCell>
                                {currencyFormat(Number(row.amount))}
                              </TableCell>
                              <TableCell>{dateFormat(row.due_date)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateInvoice = (attrs) => {
    let data = this.state.salesData;
    let keys = Object.keys(attrs);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      data.invoice[key] = attrs[key];
    }
  };

  savePayment = async (payment_method, amount, amount_received, date, paid_by, bank, bank_no, callBack) => {
    document.getElementById("show-spinner").style.display = "block";
    document.getElementById("cast").style.display = "block";
    let _id = this.state.salesData._id.$oid;
    let _this = this;
    let customer_id = _this.state.salesData.customer_id.$oid;
    await $.ajax({
      method: "POST",
      url: baseUrl + "/afrijula/sales/payments/",
      dataType: "json",
      headers: {
        "Authorization": "token " + cookies.load("token"),
        "UserProfile": cookies.load("profile"),
        "UserKey": cookies.load('User-Key')
      },
      data: {
        "id": _id, "payment_type": payment_method, "amount": amount, "date": date,
        "paid_by": paid_by, "customer_id": customer_id, "bank": bank, "bank_number": bank_no
      },

      success: function (result) {
        document.getElementById("show-spinner").style.display = "none";
        document.getElementById("cast").style.display = "none";
        displaySuccess(result.success);
        _this.updateInvoice({balance: result.balance});
        _this.hidePaymentRodal();
        if (callBack)
          callBack();
        let data = _this.state.salesData;
        if (result.invoice)
          data.invoice = result.invoice;
        data.payments.push(result.payment);
        _this.setState({salesData: data});
        _this.print(result.payment._id.$oid, true);
      },
      error: function (response) {
        document.getElementById("show-spinner").style.display = "none";
        document.getElementById("cast").style.display = "none";
        displayError(response.responseText);

      }

    });
  };

  showPaymentForm = () => {
    this.setState({ showPaymentRodal: true });
  };

  hidePaymentRodal = () => {
    this.setState({ showPaymentRodal: false });
  };

  showSaleReturn = () => {
    if (this.state.salesData.reversed) {
      displayError(getString().already_reversed);
    } else {
      this.setState({ showSaleReturn: true });
    }
  };

  loadChanges = (query, resolve, reject) => {
    if (this.state.salesData.tran_type !== "contract") return;

    const id = this.state.salesData.invoice._id.$oid;

    fetch(
      baseUrl +
        "/afrijula/sales/invoice/changes?id=" +
        id +
        "&page=" +
        query.page +
        "&size=" +
        query.pageSize,
      {
        dataType: "json",
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.total,
        });
      });
  };

  removeChange = (ids) => {
    let _this = this;
    if (ids.length > 1) {
      displayError(getString().remove_one_change);
      return;
    }
    if (this.state.salesData.tran_type !== "contract") {
      displayError(getString().use_cahnge);
      return;
    }
    $.ajax({
      method: "PUT",
      url:
        baseUrl +
        "/afrijula/sales/invoice/" +
        this.state.salesData.invoice._id.$oid,
      data: { task_id: ids[0], task: "revert" },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let data = _this.state.salesData;
        data.invoice = result.contract;
        if (result.plan && data.payment_plan) {
          data.payment_plan = result.plan;
        }
        if (result.sales_items) {
          data.sales_items = result.sales_items;
        }
        if (result.description) data.description = result.description;
        _this.setState({ salesData: data });
        if (_this.changeTableRef.current)
          _this.changeTableRef.current.onQueryChange();

        displaySuccess(result.success);
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  updateState = (result) => {
    let data = this.state.salesData;
    if (result.invoice) data.invoice = result.invoice;
    if (result.payments)
      data.payments = result.payments.map((payment) => {
        return payment;
      });
    if (result.balance) this.updateInvoice({ balance: result.balance });

    this.setState({ salesData: data });
  };

  closeDelete = () => {
    this.setState({ deletePayment: null });
  };

  payment = () => {
    const rows = this.state.salesData.payments.map((row, id) =>
      this.createPaymentRow(row._id, row.date, row.amount, row.status)
    );
    const { classes } = this.getProps();

    let _this = this;

    function showPayments() {
      return (
        <Card>
          <CardHeader color="warning">
            <h5 className={classes.cardTitleWhite}>{getString().payment}</h5>
          </CardHeader>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Paper className={classes.paper}>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{getString().date}</TableCell>
                      <TableCell>
                        <b>{getString().amount}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return (
                        <TableRow
                          key={row.id}
                          style={
                            row.status === "removed"
                              ? { textDecoration: "line-through" }
                              : {}
                          }
                        >
                          <TableCell
                            keyId={row.id.$oid}
                            onClick={(e) => {
                              _this.print(row.id.$oid, false);
                            }}
                          >
                            {dateFormat(row.date)}
                          </TableCell>
                          <TableCell
                            keyId={row.id.$oid}
                            onClick={() => {
                              _this.print(row.id.$oid, false);
                            }}
                          >
                            {currencyFormat(row.amount)}
                          </TableCell>
                          {_this.state.salesData.tran_type === "contract" ||
                          _this.state.salesData.tran_type === "invoice" ? (
                            <TableCell>
                              {
                                <span
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={() => {
                                    _this.setState({
                                      deletePayment: row.id.$oid,
                                    });
                                  }}
                                >
                                  delete
                                </span>
                              }
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </GridItem>
          </GridContainer>
        </Card>
      );
    }

    if (
      this.state.salesData.tran_type === "contract" ||
      this.state.salesData.tran_type === "invoice"
    ) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={7} md={7}>
            {showPayments()}
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <GridContainer>
              <GridItem xs={12}>
                <DeleteRequests
                  updateState={this.updateState.bind(this)}
                  closeDelete={this.closeDelete.bind(this)}
                  salesData={this.state.salesData}
                  deletePayment={this.state.deletePayment}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={12}>{showPayments()}</GridItem>
        </GridContainer>
      );
    }
  };

  loadCustomer = () => {
    if (this.state.salesData.customer_id) {
      let id = this.state.salesData.customer_id.$oid;

      let _this = this;

      $.ajax({
        method: "GET",
        url: baseUrl + "/afrijula/sales/customers/" + id,
        dataType: "json",
        data: { include: "customer_balance" },
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(result) {
          _this.setState({ customer: result });
        },
      });
    }
  };

  componentDidMount() {
    this.loadCustomer();
    if (this.state.salesData.tran_type === "contract") {
      let data = this.state.billables;
      data = this.state.salesData.sales_items
        .filter((item) => {
          return item.billable === true;
        })
        .map((item) => {
          return {
            name: item.name,
            number: item.number,
            unit_cost: currencyValue(item.unit_price, false),
            total: currencyValue(item.total, false),
            quantity: item.quantity,
          };
        });
      this.setState({ billables: data });
      this.setState({ payMethod: "cash" });
    }
  }

  getPaymentInfo = () => {
    return {
      payment: this.state.activePayment,
      items: this.state.salesData.sales_items,
      salesData: this.state.salesData,
      labels: this.state.salesData.labels,
      customer: this.state.salesData.customer,
      invoice: this.state.salesData.invoice,
      amount_received: this.state.amount_received,
      description: this.state.salesData.description,
    };
  };

  getContractPaymentInfo = () => {
    return {
      payment: this.state.activePayment,
      items: this.state.salesData.sales_items,
      salesData: this.state.salesData,
      labels: this.state.salesData.labels,
      customer: this.state.salesData.customer,
      invoice: this.state.salesData.invoice,
      description: this.state.salesData.description,
    };
  };

  print = (id, autoSend) => {
    let i = 0;
    for (; i < this.state.salesData.payments.length; i++) {
      if (this.state.salesData.payments[i]._id.$oid === id) break;
    }
    if (i < this.state.salesData.payments.length) {
      this.setState({
        exitPrint: true,
        autoSend: autoSend,
        activePayment: this.state.salesData.payments[i],
      });
    } else {
      displayError("Invalid receipt. Please contact support.");
    }
  };

  startOrStopOrChange = (close, change) => {
    let task = "";
    const id = this.state.salesData.invoice._id.$oid;
    let date = this.state.trnsValue;
    if (!change) {
      const status = this.state.salesData.invoice.status;
      if (status === "stopped") task = "start";
      else if (status === "closed") task = "start";
      else if (status === "open") {
        task = close ? "close" : "stop";
      }
      if (!date) {
        displayError(getString().invalid_date);
        return;
      }
    } else {
      task = change;
    }
    let _this = this;
    $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/sales/invoice/" + id,
      dataType: "json",
      data: {
        task: task,
        date: date,
        billables: this.state.billables,
        discount: this.state.discountAmount,
        tax: this.state.taxAmount,
        billing: this.state.billingPeriod,
      },
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let data = _this.state.salesData;
        data.invoice = result.contract;
        data.balance = result.balance;
        if (result.plan && data.payment_plan) {
          data.payment_plan.discount_amount = result.plan.discount_amount;
          data.payment_plan.discount_type = result.plan.discount_type;
        }
        data.description = result.description;
        if (result.sales_items) {
          data.sales_items = result.sales_items;
        }
        _this.setState({
          salesData: data,
          askDate: false,
          askChange: false,
          continueAction: null,
          askDiscount: false,
          askTax: false,
        });
        displaySuccess(getString().contract_change);
        if (_this.changeTableRef.current)
          _this.changeTableRef.current.onQueryChange();
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  sellableOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/sales/sales/sellable?productOnly=true&attrs=["id","name","unit_price","number"]&to=name&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let opts = [];
        let i = 0;
        for (; i < data.length; i++) {
          if (data[i].billable === true)
            opts[i] = {
              label: data[i].name,
              value: data[i]._id.$oid,
              unit_price: data[i].unit_price,
              number: data[i].number,
              type: data[i].type,
              billable: data[i].billable,
            };
        }
        return opts;
      })
      .catch((err) => {
        displayError(err.responseText);
      });
  };

  getItem = (opt, quantity, type) => {
    let newItem = this.state.newProduct;
    if (type === "name" && opt) {
      newItem.name = opt.label;
      newItem.number = opt.number;
      newItem.unit_price = currencyValue(opt.unit_price, false);
    }
    if (type === "qty") {
      newItem.quantity = quantity;
    }
    if (newItem.quantity && newItem.unit_price)
      newItem.total = newItem.quantity * newItem.unit_price;
    this.setState({
      newProduct: newItem,
    });
  };

  insertToTable = () => {
    let index = 0;
    if (!this.state.newProduct.number || !this.state.newProduct.quantity) {
      displayError(getString().no_item_or_qty);
      return;
    }
    for (; index < this.state.billables.length; index++) {
      if (this.state.billables[index].number === this.state.newProduct.number)
        break;
    }
    if (index < this.state.billables.length) {
      let item = this.state.billables[index];
      item.quantity += this.state.newProduct.quantity;
      item.total += this.state.newProduct.total;
    } else {
      let item = {};
      item.name = this.state.newProduct.name;
      item.number = this.state.newProduct.number;
      item.quantity = this.state.newProduct.quantity;
      item.total = this.state.newProduct.total;
      item.unit_cost = this.state.newProduct.unit_price;
      this.state.billables.push(item);
    }
    let newProd = this.state.newProduct;
    newProd.number = null;
    newProd.total = null;
    newProd.quantity = 1;
    newProd.unit_price = null;
    newProd.name = null;
    this.setState({
      newProduct: newProd,
    });
    this.hideParts();
  };

  removePart = (data) => {
    let list = this.state.billables;
    for (let index = 0; index < list.length; index++) {
      if (list[index].number === data.number) {
        list.splice(index, 1);
        break;
      }
    }
    this.setState({ billables: list });
  };

  _change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showReceipt = (data, autoSend) => {
    return this.state.activePayment ? (
      <WindowSizeListener
        onResize={(size) => {
          this.setState({ winSize: size.windowWidth });
        }}
      >
        {this.state.winSize <= 800 ? (
          <MobileReceipt
            items={data.items}
            total={data.salesData.total}
            tax={data.salesData.total_tax}
            subtotal={data.salesData.sub_total}
            location={data.salesData.location}
            amout_received={data.amount_received}
            discount={data.salesData.discount}
            customer={data.customer}
            autoSend={autoSend}
            payment={data.payment}
            labels={data.labels}
            quantity={data.quantity}
            description={data.description}
            invoice={data.invoice}
            closePrint={this.closePrint.bind(this)}
            activePayment={this.state.activePayment}
          />
        ) : (
          <Receipt
            items={data.items}
            total={data.salesData.total}
            tax={data.salesData.total_tax}
            subtotal={data.salesData.sub_total}
            location={data.salesData.location}
            amout_received={data.amount_received}
            discount={data.salesData.discount}
            customer={data.customer}
            autoSend={autoSend}
            payment={data.payment}
            labels={data.labels}
            quantity={data.quantity}
            description={data.description}
            invoice={data.invoice}
            closePrint={this.closePrint.bind(this)}
            activePayment={this.state.activePayment}
            toPrintData={data.items}
          />
        )}
      </WindowSizeListener>
    ) : (
      <div />
    );
  };

  showContractReceipt = (data, autoSend) => {
    return this.state.activePayment ? (
      <WindowSizeListener
        onResize={(size) => {
          this.setState({ winSize: size.windowWidth });
        }}
      >
        {this.state.winSize <= 800 ? (
          <MobileReceipt
            items={data.items}
            total={data.salesData.total}
            tax={data.salesData.total_tax}
            subtotal={data.salesData.sub_total}
            location={data.salesData.location}
            amout_received={data.amount_received}
            discount={data.salesData.discount}
            customer={data.customer}
            autoSend={autoSend}
            payment={data.payment}
            labels={data.labels}
            quantity={data.quantity}
            description={data.description}
            invoice={data.invoice}
            closePrint={this.closePrint.bind(this)}
            activePayment={this.state.activePayment}
          />
        ) : (
          <ContractReceipt
            items={data.items}
            salesData={data.salesData}
            customer={data.customer}
            autoSend={autoSend}
            payment={data.payment}
            labels={data.labels}
            invoice={data.invoice}
            closePrint={this.closePrint.bind(this)}
          />
        )}
      </WindowSizeListener>
    ) : (
      <div />
    );
  };

  viewFormParts = () => {
    return (
      <div style={{ backgroundColor: "white", borderColor: "blue" }}>
        <h5>Item</h5>
        <p>Please add item this part of this product</p>
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <AsyncCreatableSelect
                autoFocus
                defaultOptions
                loadOptions={this.sellableOptions}
                name="part_name"
                id="part_name"
                placeholder={getString().select_item}
                value={{
                  label: this.state.newProduct.name,
                  value: this.state.newProduct.name,
                }}
                onChange={(opt) => {
                  this.getItem(opt, null, "name");
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                id="quantity"
                name="quantity"
                label={getString().quantity}
                type="number"
                value={this.state.newProduct.quantity}
                onChange={(e) => {
                  this.getItem(null, e.target.value, "qty");
                }}
                margin="normal"
                variant="outlined"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button onClick={this.hideParts.bind(this)} color="danger">
                {getString().cancel}
              </Button>
              <Button onClick={this.insertToTable.bind(this)} color="success">
                {getString().save}
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  };

  hideParts = () => {
    this.setState({ showProdParts: false });
  };

  showParts = () => {
    this.setState({ showProdParts: true });
  };

  askDate = () => {
    const { classes } = this.getProps();
    const header = [
      {
        title: getString().product,
        field: "name",
        cellStyle: { padding: "1px", width: "160px" },
        headerStyle: { padding: "1px", width: "160px" },
      },
      {
        title: getString().number,
        field: "number",
        type: "numeric",
        cellStyle: { padding: "10px", width: "100px" },
        headerStyle: { padding: "10px", width: "100px" },
      },
      {
        title: getString().quantity,
        field: "quantity",
        type: "numeric",
        cellStyle: { padding: "10px", width: "100px" },
        headerStyle: { padding: "10px", width: "100px" },
      },
      {
        title: getString().unit_price,
        field: "unit_cost",
        render: (rowData) => {
          return currencyFormat(rowData.unit_cost);
        },
        cellStyle: { padding: "1px", width: "100px" },
        headerStyle: { padding: "1px", width: "100px" },
      },
      {
        title: getString().total,
        field: "total",
        readonly: true,
        render: (rowData) => {
          return currencyFormat(rowData.total);
        },
        cellStyle: { padding: "1px", width: "70px" },
        headerStyle: { padding: "1px", with: "100px" },
      },
    ];

    return (
      <div>
        <Dialog
          open={this.state.askDate}
          aria-labelledby="form-dialog  -title"
          maxWidth={"lg"}
        >
          <Rodal
            visible={this.state.showProdParts}
            width={450}
            showMask={false}
            onClose={this.hideParts.bind(this)}
          >
            {this.viewFormParts()}
          </Rodal>
          <DialogTitle id="form-dialog-title">{getString().change}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="transferqty"
              label="Date to perform action"
              type="date"
              value={this.state.trnsValue}
              onChange={(e) => {
                this.setState({ trnsValue: e.target.value });
              }}
            />
            <br />
            {this.state.askDiscount ? (
              <GridContainer>
                <TextField
                  id="discountAmount"
                  name="discountAmount"
                  label={getString().discount_amount}
                  value={this.state.discountAmount}
                  onChange={(e) => {
                    this.setState({ discountAmount: e.target.value });
                  }}
                  helperText={getString().discount_amount}
                  margin="normal"
                  variant="outlined"
                />
              </GridContainer>
            ) : null}
            {this.state.askTax ? (
              <GridContainer>
                <TextField
                  id="taxAmount"
                  name="taxAmount"
                  label={getString().tax_percentage}
                  value={this.state.taxAmount}
                  onChange={(e) => {
                    this.setState({ taxAmount: e.target.value });
                  }}
                  helperText={getString().tax_percentage}
                  margin="normal"
                  variant="outlined"
                />
              </GridContainer>
            ) : null}
            {this.state.askTerm ? (
              <GridContainer>
                <TextField
                  id="billingPeriod"
                  name="billingPeriod"
                  label={getString().billing}
                  value={this.state.billingPeriod}
                  select
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  onChange={(e) => {
                    this.setState({ billingPeriod: e.target.value });
                  }}
                  helperText={getString().billing_period}
                  margin="normal"
                  variant="outlined"
                >
                  {this.state.cycles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </GridContainer>
            ) : null}
            {this.state.askChange ? (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    title={getString().items}
                    columns={header}
                    data={this.state.billables}
                    options={{
                      search: false,
                      paging: false,
                      showEmptyDataSourceMessage: false,
                    }}
                    actions={[
                      {
                        tooltip: getString().remove_sale,
                        icon: "delete",
                        onClick: (evt, data) => {
                          this.removePart(data);
                        },
                      },
                      {
                        tooltip: getString().add_composition,
                        icon: "add",
                        isFreeAction: true,
                        onClick: (evt, data) => {
                          this.showParts(evt, data);
                        },
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              {getString().cancel}
            </Button>
            <Button
              onClick={() => {
                this.handleClose(true);
              }}
              color="info"
            >
              {getString().submit}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  locationOptions = (inputValue) => {
    const url =
      baseUrl +
      '/afrijula/locations/location?attrs=["name","id"]&model=Afrijula::Locations::Location&match=' +
      inputValue;
    return fetch(url, {
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let opts = [];
        let i = 0;
        for (; i < json.length; i++) {
          opts[i] = { label: json[i].name, value: json[i]._id.$oid };
        }
        return opts;
      }) // my option list array?
      .catch((err) => {
        console.log(err);
      });
  };

  saveLocation = () => {
    let _this = this;
    $.ajax({
      method: "PUT",
      url: baseUrl + "/afrijula/sales/sales/" + this.state.salesData._id.$oid,
      data: { location: this.state.location },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let saleData = _this.state.salesData;
        saleData.location = _this.state.location;
        _this.setState({ salesData: saleData });
        displaySuccess("updated sale location");
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  askLocation = () => {
    return this.state.changeLocation ? (
      <Dialog
        open={this.state.changeLocation}
        aria-labelledby="form-dialog  -title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">{getString().location}</DialogTitle>
        <DialogContent style={{ overflowY: "visible", minHeight: 200 }}>
          <div style={{ zIndex: 10000, width: 200 }}>
            <AsyncSelect
              defaultOptions={true}
              loadOptions={this.locationOptions}
              name="location"
              id="location"
              onChange={(opt) => {
                this.setState({ location: opt.label });
              }}
              variant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ changeLocation: false });
            }}
            color="danger"
          >
            {getString().cancel}
          </Button>
          <Button
            onClick={() => {
              this.saveLocation();
              this.setState({ changeLocation: false });
            }}
            color="info"
          >
            {getString().submit}
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  };

  handleClose = (submit) => {
    if (submit === true) this.state.continueAction();
    else {
      this.setState({
        askDate: false,
        askChange: false,
        askDiscount: false,
        askTax: false,
      });
    }
  };

  getTotal = () => {
    /*#bdrammeh I'm using computeCurrency to convert the object to a raw value which is being used in GenericPayment,
        * when you're making a direct sales payment the value is okay but when making payments from invoices the value is an object hence the conversion*/
    return this.state.salesData.invoice
      ? computeCurrency(1, this.state.salesData.invoice.balance, "*")
      : computeCurrency(1, this.state.salesData.total, "*");
  };

  updateDate = () => {
    if (this.state.salesData.tran_type === "contract") {
      this.setState({
        askDate: true,
        continueAction: () => {
          this.startOrStopOrChange(false, "start_date");
        },
      });
    }
  };

  changeBilling = () => {
    if (this.state.salesData.tran_type === "contract") {
      this.setState({
        askDate: true,
        billingPeriod: this.state.salesData.invoice.payment_period,
        askTerm: true,
        continueAction: () => {
          this.startOrStopOrChange(false, "term");
        },
      });
    }
  };

  changeDiscount = () => {
    if (this.state.salesData.tran_type === "contract") {
      this.setState({
        askDate: true,
        askDiscount: true,
        continueAction: () => {
          this.startOrStopOrChange(false, "discount");
        },
      });
    }
  };

  changeTax = () => {
    if (this.state.salesData.tran_type === "contract") {
      this.setState({
        askDate: true,
        askTax: true,
        continueAction: () => {
          this.startOrStopOrChange(false, "tax");
        },
      });
    }
  };

  viewInvoice = (prev) => {
    let inject = {
      images: [
        {
          name: "image",
          attr: "image",
          id: cookies.load("orgId"),
          model: "Organization",
        },
      ],
      values: [
        {
          name: "org_address",
          attr: "address",
          id: cookies.load("orgId"),
          model: "Organization",
        },
        {
          name: "org_contact",
          attr: "contact_email",
          id: cookies.load("orgId"),
          model: "Organization",
        },
        {
          name: "additional_invoice_info",
          attr: "additional_invoice_info",
          id: cookies.load("orgId"),
          model: "Organization",
        },
      ],
    };
    let params = {
      organization_id: cookies.load("orgId"),
      org_name: cookies.load("orgName"),
      invoice_id: this.state.salesData.invoice._id.$oid,
      org_address: cookies.load("orgAddress"),
      org_contact: cookies.load("orgContact"),
    };
    let term = [];
    let name = "Invoice";

    if (prev) {
      let end_date = new Date();
      params.start_date = new Date(
        end_date.getFullYear(),
        end_date.getMonth() - 1,
        end_date.getDate()
      );
      params.end_date = end_date;
      term = [
        {
          type: "date",
          default: params.start_date,
          label: "From",
          name: "start_date",
        },
        {
          type: "date",
          default: end_date,
          label: "To",
          name: "end_date",
        },
      ];
      name = "Invoice_period";
    } else {
      params.date = new Date();
    }

    showReportUtility(
      "Invoice for Period",
      baseUrl +
        "/reports/1?url=/afrijula/*&profile=" +
        cookies.load("profileId"),
      {
        params: params,
        sub: "&report=" + name + "&dynamic=true",
        inject: inject,
      },
      cookies,
      term
    );
  };

  updateLocation = () => {
    this.setState({ changeLocation: true });
  };

  emailQuote = () => {
    alert("coming soon");
  };

  deleteQuote = () => {
    let _this = this;
    $.ajax({
      method: "DELETE",
      url: baseUrl + "/afrijula/sales/sales/" + this.state.salesData._id.$oid,
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        _this.setState({ back: true });
        displaySuccess("updated sale location");
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  componentWillUnmount() {
    if (this.state.callerProps.updateMe) {
      this.state.callerProps.updateMe(true);
    }
  }

  getMainView = (classes, row) => {
    let x = this.state.salesData.sales_items;
    let y = this.state.salesData;
    return (
      <div id="toPrint">
        <Paper>
          <Table style={styles.table}>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().sale_type +
                    " : " +
                    this.state.salesData.tran_type.replace("_", " ")}
                </TableCell>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().customer +
                    ": " +
                    (this.state.salesData.customer
                      ? this.state.salesData.customer.name
                      : "no customer")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} style={{ fontSize: 15 + "px" }}>
                  {getString().description +
                    ": " +
                    this.state.salesData.description}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().email +
                    ": " +
                    (this.state.salesData.customer
                      ? this.state.salesData.customer.email ||
                        "no customer email"
                      : "no customer")}
                </TableCell>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().phone_number +
                    ": " +
                    (this.state.salesData.customer
                      ? this.state.salesData.customer.phone_number ||
                        "no customer phone number"
                      : "no customer")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().refNo + ": " + this.state.salesData.number}
                </TableCell>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().invoice +
                    " #: " +
                    (this.state.salesData.invoice
                      ? this.state.salesData.invoice.number
                      : "")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().date + ": "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={this.updateDate.bind(this)}
                  >
                    {dateFormat(
                      this.state.salesData.tran_type === "contract"
                        ? this.state.salesData.invoice.date
                        : this.state.salesData.date
                    )}
                  </Link>
                </TableCell>
                <TableCell style={{ fontSize: 15 + "px" }}>
                  {getString().balance +
                    ": " +
                    currencyFormat(
                      this.state.salesData.invoice
                        ? this.state.salesData.invoice.balance
                        : 0.0
                    )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().discount_type +
                    ": " +
                    this.state.salesData.discount_type}
                </TableCell>
                <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                  {getString().location + ": "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={this.updateLocation.bind(this)}
                  >
                    {" "}
                    {this.state.salesData.location}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                {this.state.salesData.tran_type === "contract" ? (
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().status +
                      ": " +
                      this.state.salesData.invoice.status}
                  </TableCell>
                ) : null}
                {this.state.salesData.reversed ? (
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().returned_date +
                      ": " +
                      dateFormat(this.state.salesData.updated_at.slice(0, 10))}
                  </TableCell>
                ) : null}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        {this.state.salesData.tran_type === "contract" ? (
          <Paper>
            <h5>{getString().bill_items}</h5>
            <Table style={styles.table}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().product}
                  </TableCell>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().quantity}
                  </TableCell>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().unit_price}
                  </TableCell>
                  <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                    {getString().total}
                  </TableCell>
                </TableRow>
                {this.state.salesData.sales_items
                  .filter((item) => {
                    return item.billable === true;
                  })
                  .map((item) => {
                    return {
                      name: item.name,
                      number: item.number,
                      unit_price: currencyValue(item.unit_price, false),
                      total: currencyValue(item.total, false),
                      quantity: item.quantity,
                    };
                  })
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                        {row.name}
                      </TableCell>
                      <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                        {row.quantity}
                      </TableCell>
                      <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                        {row.unit_price}
                      </TableCell>
                      <TableCell colSpan={2} style={{ fontSize: 15 + "px" }}>
                        {row.total}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        ) : null}
        {this.state.salesData.total &&
        this.state.salesData.total.cents > 0.0 ? (
          <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{getString().product}</TableCell>
                  <TableCell align="right">
                    <b>{getString().unit_price}</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>{getString().quantity}</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>{getString().total}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row
                  .filter((r) => {
                    return row.billable !== true;
                  })
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.unit_price)}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(row.quantity)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.total)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell dataSpan={3} />
                  <TableCell colSpan={2}>{getString().sub_total}</TableCell>
                  <TableCell align="right">
                    {currencyFormat(this.state.salesData.sub_total)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell datapan={3} />
                  <TableCell colSpan={2}>{getString().discount}</TableCell>
                  <TableCell align="right">
                    {currencyFormat(this.state.salesData.discount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell datapan={3} />
                  <TableCell colSpan={2}>{getString().total_tax}</TableCell>
                  <TableCell align="right">
                    {currencyFormat(this.state.salesData.total_tax)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell datapan={3} />
                  <TableCell colSpan={2}>{getString().total}</TableCell>
                  <TableCell align="right">
                    {currencyFormat(this.state.salesData.total)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <div />
        )}
      </div>
    );
  };

  viewQuote = () => {
    execPrint(document.getElementById("toPrint"));
  };

  saveLabels = (labels, to_server) => {
    if (to_server) {
      let sale_id = this.state.salesData._id.$oid;
      let _this = this;
      $.ajax({
        method: "PUT",
        url: baseUrl + "/afrijula/sales/sales/" + sale_id,
        dataType: "json",
        data: { labels: this.state.salesData.labels },
        headers: {
          Authorization: "token " + cookies.load("token"),
          UserProfile: cookies.load("profile"),
          UserKey: cookies.load("User-Key"),
        },
        success: function(result) {
          _this.setState({ addRemoveLabels: false });
          displaySuccess(getString().update_labels);
        },
        error: function(error) {
          displayError(error.responseText);
        },
      });
    } else {
      let reg = /^[`_\s\w,]*\w*$/;
      if (!reg.test(labels.trim())) {
        displayError(getString().invalid_label);
        return;
      } else {
        let data = this.state.salesData;
        data.labels = labels.split(",");
        this.setState({ salesData: data });
      }
    }
  };

  updateContract = () => {
    let _this = this;
    $.ajax({
      method: "PUT",
      url:
        baseUrl +
        "/afrijula/sales/invoice/" +
        this.state.salesData.invoice._id.$oid,
      data: { update: true },
      dataType: "json",
      headers: {
        Authorization: "token " + cookies.load("token"),
        UserProfile: cookies.load("profile"),
        UserKey: cookies.load("User-Key"),
      },
      success: function(result) {
        let data = _this.state.salesData;
        data.invoice = result.contract;
        if (result.plan && data.payment_plan) {
          data.payment_plan = result.plan;
        }
        if (result.sales_items) {
          data.sales_items = result.sales_items;
        }
        if (result.description) data.description = result.description;
        if (result.balance) data.balance = result.balance;

        _this.setState({ salesData: data });
        if (_this.changeTableRef.current)
          _this.changeTableRef.current.onQueryChange();

        displaySuccess(result.success);
      },
      error: function(error) {
        displayError(error.responseText);
      },
    });
  };

  updateHeight = (h) => {
    this.setState({paymentRodalHeight: h});
  };

  updateMe = (paymentOption) => {
    paymentOption === "cash" ? this.updateHeight(500) : this.updateHeight(660);
  };

  render() {
    const row = this.state.salesData.sales_items.map((row, id) =>
      this.createRow(
        row._id,
        row.name,
        row.quantity,
        row.unit_cost,
        row.unit_price,
        row.total
      )
    );
    const { classes } = this.getProps();
    const callerStateTag = this.state.callerState.tag;
    const tagName = this.state.callerProps.tag || this.props.tag;
    const tran_type = this.state.salesData.tran_type;
    const changeHeader = [
      {
        title: getString().type,
        field: "type",
        cellStyle: { padding: "1px" },
        headerStyle: { padding: "1px" },
      },
      {
        title: getString().date,
        field: "date",
        render: (rowData) => dateFormat(rowData.date),
        cellStyle: { padding: "1px" },
        headerStyle: { padding: "1px" },
      },
      {
        title: getString().reversed,
        field: "cancelled",
        render: (rowData) => {
          return rowData.cancelled === true ? "yes" : "no";
        },
        cellStyle: { padding: "1px" },
        headerStyle: { padding: "1px" },
      },
    ];

    // keep updating state to the latest version of payment_method
    setInterval(() => {
      this.setState({ payMethod: localStorage.getItem("payment_method") });
    }, 1);

    if (this.state.back) {
      let ChildComponent = this.state.callerName;
      this.state.back = false;

      return (
        <ChildComponent
          state={this.state.callerState}
          returningProps={this.state.callerProps}
        />
      );
    }
    if (this.state.showSaleReturn) {
      this.state.showSaleReturn = true;
      return (
        <SalesReturns
          callerProps={this.getProps()}
          salesData={this.state.salesData}
          callerState={this.state}
          callerName={ReceiptTemplate}
          loadReversalRequests={this.props.loadReversalRequests}
        />
      );
    }
    if (this.state.sellQuote) {
      this.state.sellQuote = false;
      return (
        <SellPage
          callerProps={this.state.callerProps}
          salesData={this.state.salesData}
          callerState={this.state.callerState}
          callerName={ReceiptTemplate}
        />
      );
    }

    return (
      <WindowSizeListener
        onResize={(size) => this.setState({ winSize: size.windowWidth })}
      >
        <div>
          <div style={{ marginTop: 40 }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12}>
                    {tagName === "returnedSales" ||
                    tagName === "one_time" ? null : tagName === "quote" ? (
                      <Button
                        disable={applyPackaging("quotations")}
                        color="primary"
                        onClick={this.viewQuote}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().print}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.viewInvoice(false);
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().due}
                      </Button>
                    )}
                    {tagName === "returnedSales" ||
                    tagName === "one_time" ? null : tagName ===
                    "quote" ? null : (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.viewInvoice(true);
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().due_for_period}
                      </Button>
                    )}
                    {tagName === "one_time" ? (
                      <Button
                        color="info"
                        onClick={() => {
                          this.print(
                            this.state.salesData.payments[0]._id.$oid,
                            false
                          );
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().to_print}
                      </Button>
                    ) : null}

                    {tagName === "one_time" || tagName === "invoice" ? (
                      <Button
                        color="info"
                        onClick={this.showSaleReturn}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().reverse_sale}
                      </Button>
                    ) : null}
                    {tagName === "quote" ? (
                      <Button
                        color="info"
                        onClick={() => {
                          this.setState({ sellQuote: true });
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().quote_to_sale}
                      </Button>
                    ) : null}
                    {tagName === "invoice" || tagName === "contract" ? (
                      <Button
                        color="info"
                        onClick={this.showPaymentForm}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {" "}
                        {getString().pay}
                      </Button>
                    ) : null}
                    {tagName === "contract" ? (
                      <Button
                        color="info"
                        onClick={() => {
                          this.setState({
                            continueAction: () => {
                              this.startOrStopOrChange(false, null);
                            },
                            askDate: true,
                          });
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {this.state.salesData.tran_type === "contract" &&
                        this.state.salesData.invoice.status === "stopped"
                          ? getString().start
                          : getString().stop}
                      </Button>
                    ) : null}
                    {tagName === "contract" &&
                    this.state.salesData.invoice.status === "open" ? (
                      <Button
                        color="warning"
                        onClick={() => {
                          this.setState({
                            continueAction: () => {
                              this.startOrStopOrChange(true);
                            },
                            askDate: true,
                          });
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().close}
                      </Button>
                    ) : null}
                    {tagName === "contract" &&
                    this.state.salesData.invoice.status === "open" ? (
                      <Button
                        color="success"
                        onClick={() => {
                          this.setState({
                            continueAction: () => {
                              this.startOrStopOrChange(false, "extend");
                            },
                            askDate: true,
                            askChange: false,
                          });
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().extend}
                      </Button>
                    ) : null}
                    {tagName === "contract" &&
                    this.state.salesData.invoice.status === "open" ? (
                      <Button
                        color="info"
                        onClick={() => {
                          this.setState({
                            continueAction: () => {
                              this.startOrStopOrChange(false, "change");
                            },
                            askDate: true,
                            askChange: true,
                          });
                        }}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().change}
                      </Button>
                    ) : null}
                    {tagName === "contract" &&
                    this.state.salesData.invoice.status === "open" ? (
                      <Button
                        color="info"
                        onClick={this.updateContract.bind(this)}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().update}
                      </Button>
                    ) : null}
                    {tagName === "quote" ? (
                      <Button color="info" onClick={this.emailQuote.bind(this)}>
                        {getString().email}
                      </Button>
                    ) : null}
                    {tagName === "quote" ? (
                      <Button
                        color="danger"
                        onClick={this.deleteQuote.bind(this)}
                        style={
                          this.state.winSize <= 600 ? { width: "100%" } : {}
                        }
                      >
                        {getString().delete}
                      </Button>
                    ) : null}
                  </GridItem>
                  <GridItem
                    sm={12}
                    style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                  >
                    <Button
                      color="danger"
                      onClick={this.goBack}
                      style={this.state.winSize <= 600 ? { width: "100%" } : {}}
                    >
                      {getString().back}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <CardBody style={{ paddingBottom: "3px" }}>
                    <Link
                      style={{ float: "right" }}
                      onClick={() => {
                        this.setState({ addRemoveLabels: true });
                      }}
                    >
                      {getString().add_remove}
                    </Link>
                    <span>{getString().labels}:</span>
                    <span>
                      {this.state.salesData.labels
                        ? this.state.salesData.labels.map((label) => (
                            <label
                              style={{
                                marginLeft: "2px",
                                padding: "2px",
                                color: "black",
                                borderColor: "grey",
                                border: "1px",
                                backgroundColor: "#FFC300",
                                borderRadius: "5px",
                              }}
                            >
                              {label}
                            </label>
                          ))
                        : null}
                    </span>

                    <Rodal
                      visible={this.state.addRemoveLabels}
                      width={window.innerWidth < 350 ? 350 : 450}
                      showMask={false}
                      onClose={() => {
                        this.setState({ addRemoveLabels: false });
                      }}
                    >
                      <label>Please enter comma separated list</label>
                      <TextField
                        required
                        id="labels"
                        name="labels"
                        label={getString().labels}
                        value={this.state.salesData.labels.join(",")}
                        onChange={(e) => {
                          this.saveLabels(e.target.value, false);
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      <Button
                        onClick={() => {
                          this.saveLabels(null, true);
                        }}
                      >
                        {getString().save}
                      </Button>
                    </Rodal>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader color="primary">
                    <span style={{ float: "right" }}>
                      {this.state.salesData.invoice &&
                      this.state.salesData.invoice.status
                        ? this.state.salesData.invoice.status
                        : ""}
                    </span>
                    <h4>
                      {"(" +
                        this.state.salesData.tran_type.replace("_", " ") +
                        ") " +
                        getString().sales}{" "}
                    </h4>
                    {this.state.salesData.reversed ? (
                      <h5>{getString().returned_sale}</h5>
                    ) : (
                      ""
                    )}
                  </CardHeader>
                  <CardBody>
                    {this.state.salesData.tran_type === "contract" ? (
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          id="mainContentContainer"
                        >
                          {this.getMainView(classes, row)}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                          <GridContainer>
                            <GridItem xs={12} sm={6}>
                              <MaterialTable
                                tableRef={this.changeTableRef}
                                title={
                                  <h5
                                    style={{
                                      fontSize: "15px",
                                      margin: 0,
                                    }}
                                  >
                                    {getString().changes}
                                  </h5>
                                }
                                data={(query) =>
                                  new Promise((resolve, reject) => {
                                    this.loadChanges(query, resolve, reject);
                                  })
                                }
                                columns={changeHeader}
                                options={{
                                  exportButton: false,
                                  filtering: false,
                                  sorting: false,
                                  search: false,
                                  selection: true,
                                  pageSize: 5,
                                }}
                                actions={[
                                  {
                                    tooltip: getString().delete_change,
                                    icon: "clear",
                                    onClick: (row, rows) => {
                                      let ids = rows.map((item) => {
                                        return item._id.$oid;
                                      });
                                      this.removeChange(ids);
                                    },
                                  },
                                ]}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={8}>
                              <CustomerNoteDetails
                                customerId={null}
                                saleId={this.state.salesData._id.$oid}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                              <NotificationDetails
                                height={175}
                                orgId={cookies.load("orgId")}
                                invoiceId={
                                  this.state.salesData.invoice._id.$oid
                                }
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={8}
                          id="mainContentContainer"
                        >
                          {this.getMainView(classes, row)}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <GridContainer>
                            <GridItem xs={12} sm={4} md={9}>
                              <CustomerNoteDetails
                                customerId={null}
                                saleId={this.state.salesData._id.$oid}
                              />
                            </GridItem>
                            {tran_type === "invoice" &&
                            this.state.salesData.invoice ? (
                              <GridItem xs={12} sm={6}>
                                <NotificationDetails
                                  orgId={cookies.load("orgId")}
                                  height={250}
                                  invoiceId={
                                    this.state.salesData.invoice._id.$oid
                                  }
                                />
                              </GridItem>
                            ) : null}
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            {tagName === "invoice" ||
            tagName === "contract" ||
            callerStateTag === "customerDetails" ||
            tran_type === "invoice" ||
            tran_type === "contract"
              ? this.invoice()
              : null}
            {tagName === "invoice" ||
            tagName === "contract" ||
            callerStateTag === "customerDetails" ||
            tran_type === "invoice" ||
            tran_type === "contract"
              ? this.paymentPlan()
              : null}
            {tagName === "one_time" ||
            tagName === "invoice" ||
            tagName === "contract" ||
            callerStateTag === "customerDetails" ||
            tran_type === "invoice" ||
            tran_type === "one_time" ||
            tran_type === "contract"
              ? this.payment()
              : null}
            {this.askDate()}
            {this.askLocation()}

            <div
              id="show-spinner"
              style={{
                position: "absolute",
                top: "50%",
                left: "35%",
                transform: "translate(-50%, -50%)",
                zIndex: "10000000",
                display: "none",
              }}
            >
              <img src={CustomLoaderGif} width="100px" height="100px" />
            </div>
            <div
              style={{
                display: "none",
                position: "fixed",
                width: "100vw",
                height: "100vh",
                zIndex: "10000003",
                opacity: 0.4,
                top: 0,
              }}
              id="cast"
            />
            <div style={{ zIndex: 1000000, position: "fixed"}}>
              <Rodal
                visible={this.state.showPaymentRodal}
                height={this.state.paymentRodalHeight}
                width={300}
                showMask={true}
                onClose={this.hidePaymentRodal.bind(this)}
              >
                <div>
                  {/*caller is either from the sell page or from invoice or contract. we use this to show or hide the change field when recording payment:= specifically #cash payment*/}

                  <GenericPayment
                    onSave={this.savePayment.bind(this)}
                    onClose={this.hidePaymentRodal.bind(this)}
                    totalAmount={this.getTotal.bind(this)}
                    caller="no_sell"
                    updateMe={this.updateMe}
                  />
                </div>
              </Rodal>
            </div>

            <WindowSizeListener
              onResize={(size) => this.setState({ winSize: size.windowWidth })}
            >
              {this.state.winSize >= 801 ? (
                <div style={{ display: "fixed", zIndex: 10000000 }}>
                  <Rodal
                    visible={this.state.exitPrint}
                    height={780}
                    width={$(document).width() <= 600 ? 700 : 700}
                    showMask={false}
                    onClose={this.closePrint.bind(this)}
                    customStyles={{
                      marginTop: $(document).height() === 1366 ? 125 : 98,
                      zIndex: 1000000,
                      // overflow: "scroll",
                    }}
                  >
                    {this.state.salesData.tran_type === "contract"
                      ? this.showContractReceipt(
                          this.getContractPaymentInfo(),
                          this.state.autoSend
                        )
                      : this.showReceipt(
                          this.getPaymentInfo(),
                          this.state.autoSend
                        )}
                  </Rodal>
                </div>
              ) : (
                <>
                  {this.state.salesData.tran_type === "contract"
                    ? this.showContractReceipt(
                        this.getContractPaymentInfo(),
                        this.state.autoSend
                      )
                    : this.showReceipt(
                        this.getPaymentInfo(),
                        this.state.autoSend
                      )}
                </>
              )}
            </WindowSizeListener>
          </div>
        </div>
      </WindowSizeListener>
    );
  }
}

export default withStyles(styles)(ReceiptTemplate);
