import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/common/CustomButtons/Button.jsx";
import { displaySuccess, displayError, getString, dateFormat} from "assets/js/utils/utils.js";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography/Typography";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FormControl from "@material-ui/core/FormControl/FormControl";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class NotificationDetails extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

        this.state = {

            messages: [],
            message: {},
            viewMessage: false,
            addNotice: false
        };
        this.baseUrl = 'https://api.afrijula.gm/notifier'
    };

    addNotification = () => {
        this.setState({addNotice: true});
    };

    completeNotice = () => {
        let _this = this;
        $.ajax({
            method:"POST",
            url: baseUrl+"/afrijula/sales/invoice/"+this.props.invoiceId+"/schedule",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {for: this.state.messageType,from: null},

            success: function (result) {
                displaySuccess(result.success);
                _this.setState({addNotice: false});
                setTimeout(()=> {
                    _this.loadNotifications();
                }, 3000);
            },
            error: function (resp) {
                displayError(resp.responseText);
            }
        });
    };

    loadNotifications = () => {
        let _this = this;
        let reference = this.props.orgId + "-afrijula-invoice-"+this.props.invoiceId;
        $.ajax({
            method: "GET",
            url: this.baseUrl + "/application",
            data:{"attrs":["id","text",'send_to','send_to_name',"subject",'status','type','sent_on','created_at'],
                "query": "{'owner_reference': '"+reference+"'}",
                "model":"Notification"},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({messages: result});
            }
        });
    };

    viewMessage = (id) => {
        let message = null;
        for(let i = 0; i < this.state.messages.length; i++){
            if(this.state.messages[i]._id.$oid === id)
                message = this.state.messages[i];
        }
        if(message !== null){
            let msg = this.state.message;
            Object.keys(message).forEach((key)=>{
                msg[key] = message[key];
            });
            this.setState({viewMessage: true, message: msg});
        }
    }

    componentDidMount() {
        this.loadNotifications();
    };

    render() {
            return (
                <div>

                            <span><h5 style={{display:'inline'}}> Messages</h5><Button block={false} justIcon={true} size={'sm'} color="success" onClick={this.addNotification.bind(this)}
                                                                   style={{backgroundColor: "#4caf50", margin: ".3125rem 1px", minWidth: "auto",
                                                                       minHeight:"auto",fontSize: "12px",color: "#ffffff",  padding: "12px 30px",borderRadius: "3px", verticalAlign: "middle"}}
                            ><i className="material-icons">add</i>
                            </Button><Button justIcon={true} size={'sm'} color="success" onClick={()=>{this.loadNotifications();}}><i className="material-icons">refresh</i></Button></span>


                            <List style={{height: this.props.height, overflowY:'auto'}}>
                                {this.state.messages.map((item) => {
                                    return <ListItem style={{cursor: 'pointer'}} onClick={()=>{this.viewMessage(item._id.$oid)}}>
                                        <ListItemText primary={dateFormat(item.created_at)} secondary={<React.Fragment>
                                            <Typography component="div" variant="body2" color="textPrimary">
                                                {item.status}
                                            </Typography>
                                        </React.Fragment>}/>
                                    </ListItem>;
                                })}
                            </List>
                    <Dialog
                        open={this.state.viewMessage}
                        onClose={()=>{this.setState({viewMessage: false})}}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogContent>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{getString().type}:</TableCell>
                                        <TableCell>{this.state.message.type}</TableCell>
                                        <TableCell>{getString().status}:</TableCell>
                                        <TableCell>{this.state.message.status}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell >{getString().subject}: </TableCell>
                                        <TableCell >{this.state.message.subject} </TableCell>
                                        <TableCell >{getString().date}: </TableCell>
                                        <TableCell >{dateFormat(this.state.message.created_at)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell >{(this.state.message.type === 'email') ? getString().email : getString().phone}: </TableCell>
                                        <TableCell>{this.state.message.send_to}</TableCell>
                                        <TableCell >{getString().name}: </TableCell>
                                        <TableCell>{this.state.message.send_to_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{getString().message}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4}><div dangerouslySetInnerHTML={{__html: this.state.message.text}}/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({viewMessage: false})}} color="error">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.addNotice}
                        onClose={()=>{this.setState({addNode: false})}}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogContent>
                            <FormControl>
                                <FormLabel >{getString().type}</FormLabel>
                                <RadioGroup row value={this.state.messageType} name="globalFilter" onChange={e => {
                                    this.setState({messageType: e.target.value});
                                }}>
                                    <FormControlLabel label={getString().email}
                                                      value="email"
                                                      control={<Radio />} />
                                    <FormControlLabel label={getString().sms}
                                                      value="sms"
                                                      control={<Radio />} />
                                </RadioGroup>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({addNotice: false})}} color="error">
                                Cancel
                            </Button>
                            <Button onClick={this.completeNotice} color="success">
                                Send
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            );
    }
}


export default withStyles(styles)(NotificationDetails);
