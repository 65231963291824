// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Store from "@material-ui/icons/Store";


// core components/views

import Stock from "components/afrijula/Stocks/Stock.jsx";
import Supplier from "components/afrijula/Supplier/Supplier.jsx";
import LocationOn from "@material-ui/icons/LocationOn";
import Report from "components/afrijula/Reports/Reports.jsx";




const dashboardRoutes = [
   {
        path: "/stock",
        sidebarName: "Stock",
        navbarName: "Stock",
        icon: Store,
        component: Stock
    },
   {
       path: "/supplier",
       sidebarName: "Supplier",
       navbarName: "Supplier",
       icon: Person,
       component: Supplier
   },
     {
         path: "/reports",
         sidebarName: "Reports",
         navbarName: "Reports",
         icon: LocationOn,
         component: Report
     },

  { redirect: true, path: "/", to: "/stock", navbarName: "Redirect" }
];

export default dashboardRoutes;
