import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {currencyFormat,numberFormat,dateFormat,validateEmail, applyPackaging,getToday,getString, thermalPrinterFunct,showReportUtility,displaySuccess,displayError} from "assets/js/utils/utils.js";

class Autorenew extends Component {

    render() {

        return (
        <FormControl component="fieldset">
            <RadioGroup
              row={true} 
              name="autorenew"
              className="genre-wrapper"
              value={this.props.value}
              onChange={this.props.onChangeValue}
            >
            <FormControlLabel value="Yes" control={<Radio />} label={getString().yes} />
            <FormControlLabel value="No" control={<Radio />} label={getString().no} />
            </RadioGroup>
          </FormControl>
          );
    }
}

export default Autorenew