import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import { getString } from "assets/js/utils/utils.js";
import Sales from "components/afrijula/Sales/Sales.jsx";
import ReturnedSales from "components/afrijula/Sales/ReturnedSales.jsx";

function TabContainer1(props) {
  return (
    <Typography
      component={Sales}
      style={{ padding: 8 * 3 }}
      tag="one_time"
      updateMe={props.updateAppBar}
    />
  );
}

function TabContainer2(props) {
  return (
    <Typography
      component={Sales}
      style={{ padding: 8 * 3 }}
      tag="invoice"
      updateMe={props.updateAppBar}
    />
  );
}

function TabContainer3(props) {
  return (
    <Typography
      component={Sales}
      style={{ padding: 8 * 3 }}
      tag="quote"
      updateMe={props.updateAppBar}
    />
  );
}

function TabContainer4(props) {
  return (
    <Typography
      component={ReturnedSales}
      style={{ padding: 8 * 3 }}
      tag="returnedSales"
      updateMe={props.updateAppBar}
    />
  );
}

TabContainer1.propTypes = {
  children: PropTypes.node.isRequired,
};
TabContainer2.propTypes = {
  children: PropTypes.node.isRequired,
};
TabContainer3.propTypes = {
  children: PropTypes.node.isRequired,
};
TabContainer4.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

class Settings extends React.Component {
  state = {
    value: 0,
    appBar: true,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  updateAppBar = (toggle) => {
    this.setState({ appBar: toggle });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div
        className={classes.root}
        style={{
          marginTop: "-30px",
          overflowY: "scroll",
        }}
      >
        <AppBar
          position="static"
          // fixed overlapping app bar with language change including invoice not clickable in mobile device
          style={{
            zIndex: 10008,
            display: this.state.appBar ? "block" : "none",
            backgroundColor: "#9c27b0",
            color: "#fff",
            marginBottom: "8px",
            overflowY: "scroll !important",
          }}
          id="appBar"
        >
          <Tabs
            value={value}
            onChange={this.handleChange}
            style={{
              overflow: "scroll !important",
            }}
          >
            <Tab label={getString().cash_sales} className={"appBar-1"} />
            <Tab label={getString().invoices} className={"appBar-2"} />
            <Tab label={getString().quotes} className={"appBar-3"} />
            <Tab label={getString().returned_sales} className={"appBar-4"} />
            <br />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer1 updateAppBar={this.updateAppBar.bind(this)} />
        )}
        {value === 1 && (
          <TabContainer2 updateAppBar={this.updateAppBar.bind(this)} />
        )}
        {value === 2 && (
          <TabContainer3 updateAppBar={this.updateAppBar.bind(this)} />
        )}
        {value === 3 && (
          <TabContainer4 updateAppBar={this.updateAppBar.bind(this)} />
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
