import React from "react";
import Select, {AsyncCreatable} from 'react-select';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from 'prop-types';

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import {numberFormat, currencyFormat, dateFormat,compileQuery,displayError,displaySuccess,getString} from "assets/js/utils/utils.js";

import NewStock from "components/afrijula/AddForm/AddStock.jsx";
import StockTable from "material-table";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class AddStock extends React.Component{
    constructor(props){
        super(props);
        this.tableRef = React.createRef();
        this.isValidated = this.isValidated.bind(this);
    }

    isValidated = () =>  {
        return false; // make it return false. just in case. lol
    };

  

    state = {

        data: [],
        header:  [
            {title: getString().name, field:'name'},
            {title: getString().category,field:'category'},
            {title: getString().quantity,field:'quantity'},
            {title: getString().unit_price,field:'unit_price',options: {filter: false},render: rowData => currencyFormat(rowData.unit_price)},
        ],


    };
    loadData = (query,resolve,reject) => {
        let url = compileQuery(query, (baseUrl + "/afrijula/stocks/stock"),
            ["id","name","category","quantity","unit_price","id"], [{"by":"asc","attr":"name"}],
            "Afrijula::Stocks::Stock",null,null);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    };

    onCellClick = (colData,cellMeta) =>{
        this.setState({location_quantity: colData.props.control.props.value});
    };

    componentDidMount() {

    }

    reloadStockList=() =>{
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    render() {
        const { classes } = this.props;


            return (
                <div>

                     <GridContainer>
                         <GridItem xs={12} xm={12} md={12}>
                             <NewStock noBack={true} noCancel={true} saveStockCback={this.reloadStockList}/>
                         </GridItem>
                     </GridContainer>

                     <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                                    <h5><a>Import</a></h5>
                                    <form className={classes.container}  autoComplete="off">

                                            <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} style={{marginTop: 15+'px'}}>
                                                <StockTable
                                                    tableRef={this.tableRef}
                                                    data={query=>new Promise((resolve,reject) => {
                                                        this.loadData(query,resolve,reject);
                                                    })}
                                                    columns={this.state.header}
                                                    onRowClick={this.rowSelect}
                                                    options={{
                                                        exportButton: true,filtering: true,
                                                        grouping:true,sorting:true,
                                                        selection:true,showTitle:false,
                                                        pageSize:15
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                                        },
                                                        {
                                                            tooltip: 'Remove Selected sale',icon: 'delete',
                                                            onClick: (row, rows) => alert('This si to be implemented ' + rows.length + ' rows')
                                                        }
                                                    ]}
                                                />
                                            </GridItem>
                                        </GridContainer>

                                    </form>
                        </GridItem>
                    </GridContainer>
                </div>
            );

    }
}

AddStock.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object,
    validate: PropTypes.func,
    isValid: PropTypes.func,
    handleValidation: PropTypes.func,
    getValidationMessages: PropTypes.func,
    clearValidations: PropTypes.func,
    getStore: PropTypes.func,
    updateStore: PropTypes.func
};
export default withStyles(styles)(AddStock);
